import { useCallback, useState } from 'react';
import { ESnackbarStyle, PlatformSummary, UserStat, UserStatsApiResponse } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { getPlatformSummary, getUserStatistics } from 'services/api/adminService';
import { useAppDispatch } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { PLATFORM_SUMMARY } from 'shared/constants/statistics';

type Result = {
  fetchPlatformSummary: () => Promise<void>;
  fetchUserStatistics: () => Promise<void>;
  userStatistics: UserStat[];
  isStatisticsLoading: boolean;
  setSearchValue: (value: string) => void;
  setOrderBy: (value: 'status' | 'created_at') => void;
  setCurrentPage: (page: number) => void;
  totalItems: number;
  currentPage: number;
  platformSummary: PlatformSummary;
};

const useAdminData = (): Result => {
  const LIMIT = 10;
  const dispatch = useAppDispatch();
  const [userStatistics, setUserStatistics] = useState<UserStat[]>([]);
  const [platformSummary, setPlatformSummary] = useState<PlatformSummary>(PLATFORM_SUMMARY);
  const [isStatisticsLoading, setIsStatisticsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchUserStatistics = useCallback(async (): Promise<void> => {
    setIsStatisticsLoading(true);
    try {
      const { items, pagination }: UserStatsApiResponse = (
        await getUserStatistics(LIMIT, currentPage, encodeURIComponent(searchValue), orderBy)
      ).data;
      setUserStatistics(items);
      setTotalItems(pagination.totalItemsCount);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      setIsStatisticsLoading(false);
    }
  }, [currentPage, searchValue, orderBy]);

  const fetchPlatformSummary = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      const response: PlatformSummary = (await getPlatformSummary()).data;
      setPlatformSummary(response);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch]);

  return {
    fetchPlatformSummary,
    fetchUserStatistics,
    userStatistics,
    isStatisticsLoading,
    setSearchValue,
    setOrderBy,
    setCurrentPage,
    totalItems,
    currentPage,
    platformSummary
  };
};

export default useAdminData;
