import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import { ErrorMessage, PageContainer, PageTitle } from 'shared/styles';
import { ModalCreateTeamCheckout, TeamSubscriptions } from 'shared/components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setMembersCount, setTeamName } from 'services/store/reducers/createTeamReducer';
import {
  CreateTeamState,
  EConfirmModalHeader,
  EPaymentType,
  ESnackbarStyle,
  SubscriptionsState
} from 'shared/types';
import { MAX_TEAM_NAME_LENGTH } from 'shared/constants/limits';
import { useHistory, useLocation } from 'react-router-dom';
import { openNotification } from 'utils/notification-utils';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { clearTeamDataLocalStorage, getTeamDataFromLocalStorage } from 'utils/storage-utils';

const CreateTeamPage = (): JSX.Element => {
  const location = useLocation();
  const params = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search]
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { subscriptionPlans } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const { teamName } = useAppSelector((store): CreateTeamState => store.createTeam);
  const [teamNameError, setTeamNameError] = useState<string>('');

  const clearParams = useCallback(
      (paramItems: string[]): void => {
        paramItems.forEach((item): void => params.delete(item));
        history.replace({ search: params.toString() });
      },
      [history, params]
  );

  useEffect((): void => {
    const paymentRedirectStatus = params.get('redirect_status');
    const setupIntentClientSecret = params.get('setup_intent_client_secret');
    if (!!paymentRedirectStatus) {
      clearParams(['redirect_status', 'setup_intent', 'setup_intent_client_secret']);
    }
    if (paymentRedirectStatus === 'failed') {
      openNotification(ESnackbarStyle.ERROR, 'Payment failed');
      return;
    }
    if (paymentRedirectStatus === 'succeeded' && !!setupIntentClientSecret) {
      const { teamName, planId, isBusiness, isMonth, membersCount } = getTeamDataFromLocalStorage();
      const plan = subscriptionPlans.find((plan): boolean => plan.id === planId);
      dispatch(setTeamName(teamName));
      dispatch(setMembersCount(membersCount));
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: (
            <ModalCreateTeamCheckout
              isMonthlyType={isMonth}
              plan={plan!}
              setupIntentClientSecret={setupIntentClientSecret}
              isBusinessPayment={isBusiness}
            />
          )
        })
      );
      clearTeamDataLocalStorage();
      openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully saved');
    }
  }, [clearParams, dispatch, params, subscriptionPlans]);

  useEffect((): void => {
    if (teamName.length > MAX_TEAM_NAME_LENGTH) {
      setTeamNameError(`Only ${MAX_TEAM_NAME_LENGTH} symbols allowed`);
      return;
    }
    setTeamNameError('');
  }, [teamName]);

  const handleWorkspaceNameBlur = (): void => {
    if (!teamName) {
      setTeamNameError('Required field');
    }
  };

  const handleWorkspaceNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    dispatch(setTeamName(value.trimStart()));
  };

  return (
    <PageContainer>
      <Styled.PageHeader>
        <PageTitle>Create a team</PageTitle>
        <Styled.PageSubtitle>
          In team spaces, models and their usage limits are shared across all users in a single
          space.
        </Styled.PageSubtitle>
      </Styled.PageHeader>
      <Styled.PageContent>
        <Styled.LeftSide>
          <Styled.WorkspaceNameField>
            <Styled.NameFieldLabel>Workspace name</Styled.NameFieldLabel>
            <Styled.NameFieldInputContainer>
              <Styled.NameFieldInput
                isError={!!teamNameError}
                value={teamName}
                onChange={handleWorkspaceNameChange}
                onBlur={handleWorkspaceNameBlur}
                placeholder='Enter team workspace name'
                autoFocus={true}
              />
              {!!teamNameError && <ErrorMessage>{teamNameError}</ErrorMessage>}
            </Styled.NameFieldInputContainer>
          </Styled.WorkspaceNameField>
          <TeamSubscriptions paymentType={EPaymentType.CREATE_TEAM_PLAN} withCounter />
        </Styled.LeftSide>
      </Styled.PageContent>
    </PageContainer>
  );
};

export default CreateTeamPage;
