import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  EFeatureId,
  EModelAccessLevel,
  EModelType,
  ESnackbarStyle,
  ETeamRole,
  Model,
  ModelFeatureAccessState,
  PlanFeature
} from 'shared/types';
import { startLoader, stopLoader } from './loaderReducer';
import { AppDispatch, RootState } from '..';
import { openNotification } from 'utils/notification-utils';
import { checkAllModelFeatures } from 'services/api/modelService';
import { getTeamMember } from 'services/api/teamService';
import { API_REQUEST_CANCELED_CODE } from 'shared/constants/errors';
import { getModelType } from 'utils/model-utils';

const initialState: ModelFeatureAccessState = {
  hasLightingAccess: false,
  hasEnvironmentAccess: false,
  hasCubemapAccess: false,
  hasEditAccess: false,
  hasArViewAccess: false,
  hasCommentAccess: false,
  hasCustomBrandingAccess: false,
  hasCustomEnvironmentAccess: false
};

// Actions

export const setModelFeatureAccess = createAction<ModelFeatureAccessState>(
  'modelFeatureAccess/setModelFeatureAccess'
);
export const clearModelFeatureAccess = createAction('modelFeatureAccess/clearModelFeatureAccess');

// Reducer

export const modelFeatureAccessReducer = createReducer(initialState, (builder): void => {
  builder.addCase(setModelFeatureAccess, (_, { payload }): ModelFeatureAccessState => payload);
  builder.addCase(clearModelFeatureAccess, (): ModelFeatureAccessState => initialState);
});

// Thunks

export const fetchModelFeatureAccess = createAsyncThunk<
  Promise<void>,
  { model: Model; signal?: AbortSignal },
  { dispatch: AppDispatch; state: RootState }
>(
  'modelFeatureAccess/fetchModelFeatureAccess',
  async ({ model, signal }, { dispatch, getState }): Promise<void> => {
    const { features: userFeatures, user } = getState().auth;
    const { modelAccessLevel: currentModelAccessLevel } = getState().viewerData;
    const modelType = getModelType(model);
    const modelAccessLevel = currentModelAccessLevel || model.accessLevel;
    const isModelOwner = model.ownerId === user?.id;
    const features: PlanFeature[] =
      modelType === EModelType.TEAM || modelType === EModelType.SHARED
        ? (await checkAllModelFeatures(model.id, signal)).data
        : userFeatures;

    const checkFeature = (featureId: EFeatureId): boolean =>
      !!features?.find((item): boolean => item.id === featureId || item.feature === featureId)
        ?.enabled;

    dispatch(startLoader());
    try {
      switch (modelType) {
        case EModelType.OWNER:
          dispatch(
            setModelFeatureAccess({
              hasLightingAccess: checkFeature(EFeatureId.LIGHT_OPTIONS),
              hasEnvironmentAccess: checkFeature(EFeatureId.ENVIRONMENT_OPTIONS),
              hasCubemapAccess: checkFeature(EFeatureId.CUBEMAP_OPTIONS),
              hasEditAccess: true,
              hasArViewAccess: checkFeature(EFeatureId.WEB_AR_VIEWING_MODE),
              hasCommentAccess: checkFeature(EFeatureId.MAKE_3D_NOTES),
              hasCustomBrandingAccess: checkFeature(EFeatureId.CUSTOM_UI_BRANDING),
              hasCustomEnvironmentAccess: checkFeature(EFeatureId.CUSTOM_3D_ENVIRONMENT)
            })
          );
          break;
        case EModelType.ANONYMOUS:
          dispatch(
            setModelFeatureAccess({
              hasLightingAccess: false,
              hasEnvironmentAccess: false,
              hasCubemapAccess: false,
              hasEditAccess: false,
              hasArViewAccess: false,
              hasCommentAccess: false,
              hasCustomBrandingAccess: false,
              hasCustomEnvironmentAccess: false
            })
          );
          break;
        case EModelType.SAMPLE:
          dispatch(
            setModelFeatureAccess({
              hasLightingAccess: true,
              hasEnvironmentAccess: true,
              hasCubemapAccess: true,
              hasEditAccess: false,
              hasArViewAccess: true,
              hasCommentAccess: false,
              hasCustomBrandingAccess: false,
              hasCustomEnvironmentAccess: false
            })
          );
          break;
        case EModelType.QUICK_VIEW:
          dispatch(
            setModelFeatureAccess({
              hasLightingAccess: checkFeature(EFeatureId.LIGHT_OPTIONS),
              hasEnvironmentAccess: checkFeature(EFeatureId.ENVIRONMENT_OPTIONS),
              hasCubemapAccess: checkFeature(EFeatureId.CUBEMAP_OPTIONS),
              hasEditAccess: false,
              hasArViewAccess: checkFeature(EFeatureId.WEB_AR_VIEWING_MODE),
              hasCommentAccess: false,
              hasCustomBrandingAccess: false,
              hasCustomEnvironmentAccess: false
            })
          );
          break;
        case EModelType.TEAM:
          const teamRole: ETeamRole = !!model?.teamId
            ? (await getTeamMember(model.teamId)).data.role
            : ETeamRole.VIEWER;
          const isViewerRole = teamRole === ETeamRole.VIEWER;
          dispatch(
            setModelFeatureAccess({
              hasLightingAccess: checkFeature(EFeatureId.LIGHT_OPTIONS),
              hasEnvironmentAccess: checkFeature(EFeatureId.ENVIRONMENT_OPTIONS),
              hasCubemapAccess: checkFeature(EFeatureId.CUBEMAP_OPTIONS),
              hasEditAccess:
                !isViewerRole &&
                (isModelOwner || modelAccessLevel.includes(EModelAccessLevel.EDIT_ACCESS)),
              hasArViewAccess: checkFeature(EFeatureId.WEB_AR_VIEWING_MODE),
              hasCommentAccess: checkFeature(EFeatureId.MAKE_3D_NOTES),
              hasCustomBrandingAccess: !isViewerRole && checkFeature(EFeatureId.CUSTOM_UI_BRANDING),
              hasCustomEnvironmentAccess: checkFeature(EFeatureId.CUSTOM_3D_ENVIRONMENT)
            })
          );
          break;
        case EModelType.SHARED:
          dispatch(
            setModelFeatureAccess({
              hasLightingAccess: checkFeature(EFeatureId.LIGHT_OPTIONS),
              hasEnvironmentAccess: checkFeature(EFeatureId.ENVIRONMENT_OPTIONS),
              hasCubemapAccess: checkFeature(EFeatureId.CUBEMAP_OPTIONS),
              hasEditAccess: modelAccessLevel.includes(EModelAccessLevel.EDIT_ACCESS),
              hasArViewAccess: checkFeature(EFeatureId.WEB_AR_VIEWING_MODE),
              hasCommentAccess: checkFeature(EFeatureId.MAKE_3D_NOTES),
              hasCustomBrandingAccess:
                checkFeature(EFeatureId.CUSTOM_UI_BRANDING) &&
                modelAccessLevel.includes(EModelAccessLevel.EDIT_ACCESS),
              hasCustomEnvironmentAccess: checkFeature(EFeatureId.CUSTOM_3D_ENVIRONMENT)
            })
          );
          break;
        default:
          dispatch(setModelFeatureAccess(initialState));
      }
    } catch (e) {
      if (e.code === API_REQUEST_CANCELED_CODE) return;
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }
);
