import React, { useLayoutEffect, useRef } from 'react';
import * as Styled from './styles';
import { Subscriptions } from 'shared/components';

type Props = {
  title: string;
  subtitle: string;
};

const ModalLimit: React.FC<Props> = ({ title, subtitle }): JSX.Element => {
  const subtitleRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect((): void => {
    if (subtitleRef.current && !!subtitle) {
      subtitleRef.current.innerHTML = subtitle.replace(
        'upgrade your account',
        '<span style="color: #FFE406">upgrade your account</span>'
      );
    }
  }, [subtitle]);

  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalContent>
        <Styled.AlertText>Hold up!</Styled.AlertText>
        <Styled.ModalTitle>{title}</Styled.ModalTitle>
        <Styled.ModalSubtitle ref={subtitleRef} />
        <Styled.SubscriptionsContainer>
          <Subscriptions isModal />
        </Styled.SubscriptionsContainer>
      </Styled.ModalContent>
    </Styled.ModalWindowContainer>
  );
};

export default ModalLimit;
