import React from 'react';
import * as Styled from './styles';
import LogoLogin from 'assets/images/logo-icon-login.png';
import { LoginForm } from 'shared/components';
import { ModalCopyright } from 'shared/styles';
import moment from 'moment';

type Props = {
  email?: string;
};

const ModalLogin: React.FC<Props> = ({ email }): JSX.Element => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.LiftSideContent>
          <LoginForm email={email} />
        </Styled.LiftSideContent>
        <ModalCopyright>
          <span>{moment().year()} © glbee</span>
        </ModalCopyright>
      </Styled.LeftSide>
      <Styled.RightSide>
        <img src={LogoLogin} alt='Logo Icon' />
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default ModalLogin;
