import React, { useEffect, useState } from 'react';
import * as Styled from './styles';

type Props = {
  action: ((value: boolean) => void) | (() => void);
  isActive: boolean;
  switcherId: string;
  isDisabled?: boolean;
  onClickIfDisabled?: () => void;
  isSmall?: boolean;
  withoutCustomStyle?: boolean;
};

const Switcher: React.FC<Props> = ({
  action,
  isActive,
  switcherId,
  isSmall,
  isDisabled = false,
  onClickIfDisabled,
  withoutCustomStyle
}): JSX.Element => {
  const [value, setValue] = useState<boolean>(isActive);

  useEffect((): void => {
    if (!isDisabled) {
      setValue(isActive);
    }
  }, [isActive, isDisabled]);

  const handleSwitcherClick = (): void => {
    if (!isDisabled) {
      action(!value);
    } else if (isDisabled && onClickIfDisabled) {
      onClickIfDisabled();
    }
  };

  return (
    <Styled.SwitcherContainer
      onClick={handleSwitcherClick}
      isActive={value}
      isDisabled={isDisabled}
      isSmall={!!isSmall}
      withoutCustomStyle={!!withoutCustomStyle}
      id={switcherId}
    >
      <div className='switcher-wheel' />
    </Styled.SwitcherContainer>
  );
};

export default Switcher;
