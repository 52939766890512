import { EPremiumFeature } from 'shared/types';
import CommentsPreview from 'assets/gifs/comments-feature-preview.gif';
import ArPreview from 'assets/gifs/ar-feature-preview.gif';

type Features = {
  [key in EPremiumFeature]: {
    title: string;
    description: string;
    preview: string;
  };
};

export const FEATURES: Features = {
  [EPremiumFeature.AR]: {
    title: 'View in AR',
    description:
      'View your 3D models in Augmented Reality. Using the View in AR premium feature, you can tap to view any of your 3D models in Web-based Augmented Reality instantly. No app download required, simply tap the ‘AR’ feature button to launch the AR viewing mode and see your models realistically augmented into your own environment.',
    preview: ArPreview
  },
  [EPremiumFeature.COMMENTS]: {
    title: 'Comments',
    description:
      'Comments take collaboration to the next level. Use comments to respond to feedback and collaborate in real-time on your 3D models. Add a comment to a pinned location on your model and the camera position is automatically saved, enabling others to tap to view the comment and watch as the camera automatically moves to its original position!',
    preview: CommentsPreview
  }
};
