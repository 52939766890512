export const throttle = async (delay: number): Promise<void> =>
  new Promise((resolve): void => {
    setTimeout(resolve, delay);
  });

export const debounce = (fn: (...params: any[]) => void, ms=300): (this: any, ...args: any[]) => void => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout((): void => fn.apply(this, args), ms);
  };
};
