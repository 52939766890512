import React from 'react';
import * as Styled from './styles';
import Spinner from 'assets/images/spinner.svg';

const FullScreenLoader = (): JSX.Element => {
  return (
    <Styled.FullScreenContainer>
      <img className='spinner' src={Spinner} alt='Loading' />
    </Styled.FullScreenContainer>
  );
};

export default FullScreenLoader;
