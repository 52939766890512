import { EQuotaName } from 'shared/types';

export const VIEWS_LIMIT = 'You’ve reached your model view limit';
export const AR_VIEWS_LIMIT = 'You’ve reached your model AR view limit';
export const MODELS_LIMIT = 'You’ve reached your account model limit';
export const TOO_LARGE_FILE = 'File size too large';
export const VIEWS_LIMIT_DESCRIPTION =
  'You have reached your model view limit for this month. Either upgrade your account or wait until your view limit is reset.';
export const AR_VIEWS_LIMIT_DESCRIPTION =
  'You have reached your model AR view limit for this month. Either upgrade your account or wait until your view limit is reset.';
export const MODELS_LIMIT_DESCRIPTION =
  'Either upgrade your account below or delete models to free up space';
export const TOO_LARGE_FILE_DESCRIPTION =
  'This model file size too large. Please upgrade your account or reduce the size of the file before uploading.';

export const PROMPTS = {
  [EQuotaName.ACTIVE_MODELS_LIMIT]: {
    title: MODELS_LIMIT,
    subtitle: MODELS_LIMIT_DESCRIPTION
  },
  [EQuotaName.MODEL_SIZE_LIMIT]: {
    title: TOO_LARGE_FILE,
    subtitle: TOO_LARGE_FILE_DESCRIPTION
  },
  [EQuotaName.MODEL_VIEWS_LIMIT]: {
    title: VIEWS_LIMIT,
    subtitle: VIEWS_LIMIT_DESCRIPTION
  },
  [EQuotaName.MONTHLY_AR_VIEWS_LIMIT]: {
    title: AR_VIEWS_LIMIT,
    subtitle: AR_VIEWS_LIMIT_DESCRIPTION
  },
  [EQuotaName.IFRAME_CUSTOM_BUTTONS]: {
    title: '',
    subtitle: ''
  }
};
