import { createAction, createReducer } from '@reduxjs/toolkit';
import { CreateTeamState } from 'shared/types';
import { MIN_TEAM_MEMBER_COUNT } from 'shared/constants/limits';

const initialState: CreateTeamState = {
  teamName: '',
  membersCount: MIN_TEAM_MEMBER_COUNT
};

// Actions

export const setTeamName = createAction<string>('createTeam/setTeamName');
export const setMembersCount = createAction<number>('createTeam/setMembersCount');
export const clearCreateTeamData = createAction('createTeam/clearCreateTeamData');

// Reducer

export const createTeamReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setTeamName, (state, { payload }): void => {
      state.teamName = payload;
    })
    .addCase(setMembersCount, (state, { payload }): void => {
      state.membersCount = payload;
    })
    .addCase(clearCreateTeamData, (): CreateTeamState => initialState);
});
