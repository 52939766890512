import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PlanIcon = styled.img<{ isModal?: boolean }>`
  height: ${({ isModal }): string => (isModal ? '40px' : '70px')};
  margin-top: ${({ isModal }): string => (isModal ? '20px' : '30px')};
`;

export const PlanWrapper = styled.div<{ isModal?: boolean }>`
  width: 100%;
  min-width: ${({ isModal }): string => (isModal ? 'initial' : '200px')};
  max-width: 400px;
  height: max-content;
  padding: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.mobile} {
    width: 100%;
    max-width: initial;
    margin-top: 0;
    padding: 0;
  }
`;

export const MostPopularLabel = styled.div<{ isModal?: boolean }>`
  height: ${({ isModal }): string => (isModal ? '35px' : '45px')};
  width: 100%;
  background: ${COLORS.turbo};
  margin-bottom: -10px;
  padding-bottom: 10px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }

  span {
    margin-top: 3px;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    color: ${COLORS.black};
  }

  @media ${DEVICE.desktop} {
    display: none;
  }
`;

export const PlanContainer = styled.div<{ isMostPopular: boolean; isModal?: boolean }>`
  width: 100%;
  padding-bottom: ${({ isModal }): string => (isModal ? '10px' : '20px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(352.13deg, #1b1a1a 3.17%, #464646 103.41%);
  border-radius: 8px;
  color: ${COLORS.white};
  position: relative;
  margin-top: ${({ isMostPopular, isModal }): string =>
    isMostPopular ? '0' : isModal ? '25px' : '35px'};

  @media ${DEVICE.desktop} {
    margin-top: 0;
  }

  @media ${DEVICE.mobile} {
    border-radius: 0 0 8px 8px;
  }
`;

export const PlanIconContainer = styled.div<{ isModal?: boolean }>`
  height: ${({ isModal }): string => (isModal ? '40px' : '80px')};
`;

export const PlanIconShadow = styled.div<{ isModal?: boolean }>`
  position: absolute;
  top: ${({ isModal }): string => (isModal ? '10px' : '20px')};
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background: rgba(117, 117, 117, 0.59);
  opacity: 0.8;
  filter: blur(60px);
  border-radius: 50%;
`;

export const PlanTitle = styled.span<{ isModal?: boolean }>`
  margin-top: 40px;
  font-weight: 600;
  font-size: ${({ isModal }): string => (isModal ? '20px' : '24px')};
  line-height: ${({ isModal }): string => (isModal ? '22px' : '26px')};
`;

export const PlanSubtitle = styled.span<{ isModal?: boolean }>`
  margin-top: 6px;
  font-weight: 400;
  font-size: ${({ isModal }): string => (isModal ? '16px' : '22px')};
  line-height: ${({ isModal }): string => (isModal ? '18px' : '24px')};
`;

export const PaymentDescription = styled.span<{ isModal?: boolean }>`
  margin-top: 6px;
  font-weight: 400;
  font-size: ${({ isModal }): string => (isModal ? '10px' : '13px')};
  line-height: ${({ isModal }): string => (isModal ? '12px' : '15px')};
  text-align: center;
`;

export const PriceSection = styled.div<{ isModal?: boolean }>`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: ${({ isModal }): string => (isModal ? '56px' : '70px')};
`;

export const CustomPrice = styled.span<{ isModal?: boolean }>`
  font-weight: 600;
  font-size: ${({ isModal }): string => (isModal ? '28px' : '32px')};
  line-height: ${({ isModal }): string => (isModal ? '30px' : '35px')};
  text-align: center;
  align-self: center;
  padding: 0 30px;
`;

export const Currency = styled.span`
  margin-top: -10px;
  font-size: 16px;
  line-height: 18px;
`;

export const Price = styled.span<{ isModal?: boolean }>`
  font-weight: 600;
  font-size: ${({ isModal }): string => (isModal ? '60px' : '85px')};
  line-height: ${({ isModal }): string => (isModal ? '62px' : '70px')};
`;

export const Duration = styled.span<{ isModal?: boolean }>`
  font-size: ${({ isModal }): string => (isModal ? '12px' : '16px')};
  line-height: ${({ isModal }): string => (isModal ? '12px' : '18px')};
  margin-top: auto;
`;

export const PlanDescription = styled.div<{ isModal?: boolean }>`
  margin-top: ${({ isModal }): string => (isModal ? '20px' : '30px')};
  display: flex;
  flex-direction: column;
  font-size: ${({ isModal }): string => (isModal ? '11px' : '13px')};
  line-height: ${({ isModal }): string => (isModal ? '16px' : '19px')};
`;

export const SelectButton = styled.button<{ isActive: boolean; isModal?: boolean }>`
  margin-top: ${({ isModal }): string => (isModal ? '20px' : '30px')};
  width: ${({ isModal }): string => (isModal ? '140px' : '160px')};
  height: ${({ isModal }): string => (isModal ? '30px' : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-size: ${({ isModal }): string => (isModal ? '12px' : '14px')};
  line-height: ${({ isModal }): string => (isModal ? '14px' : '15px')};
  background: ${({ isActive }): string => (isActive ? COLORS.doveGray : COLORS.corn)};
  font-family: ${FONTS.interRegular};
  color: ${COLORS.black};
  cursor: ${({ isActive }): string => (isActive ? 'initial' : 'pointer')};

  &:hover {
    background: ${({ isActive }): string => (isActive ? COLORS.doveGray : COLORS.turbo)};
  }
`;

export const AvailableFeatures = styled.div<{ isModal?: boolean }>`
  margin-top: ${({ isModal }): string => (isModal ? '10px' : '20px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const FeatureItemContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);

  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.19);
  }
`;

export const FeatureItem = styled.div<{ isAvailable: boolean; isModal?: boolean }>`
  width: ${({ isModal }): string => (isModal ? '180px' : '210px')};
  display: flex;
  align-items: center;
  font-size: ${({ isModal }): string => (isModal ? '11px' : '13px')};
  line-height: ${({ isModal }): string => (isModal ? '13px' : '15px')};
  color: ${({ isAvailable }): string => (isAvailable ? COLORS.white : '#504E4E')};

  img {
    width: 10px;
    height: 8px;
    margin-right: 10px;
  }

  span {
    word-break: break-word;
  }
`;
