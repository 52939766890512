import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const NotificationsSettings = styled.div`
  width: 100%;
  background-color: ${COLORS.black};
  border-radius: 5px;
  overflow: hidden;
`;

export const NotificationsSettingsHeader = styled.div`
  position: relative;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  color: ${COLORS.white};
  border-bottom: 1px solid ${COLORS.white};
  padding: 0 18px;
  height: 35px;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 8px;

  img {
    width: 8px;
    height: 8px;
  }
`;

export const NotificationsSettingsField = styled.div<{ isActive: boolean }>`
  padding: 0 18px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isActive }): string =>
    isActive ? 'var(--theme-secondary-color)' : COLORS.black};

  .field-icon {
    height: 12px;
    width: 12px;
    color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.white)};
  }

  .all-notifications {
    color: ${({ isActive }): string =>
      isActive ? COLORS.black : 'var(--theme-secondary-color)'};
  }

  span {
    margin-left: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.white)};
  }

  &:hover {
    background-color: var(--theme-secondary-color);

    .field-icon {
      color: ${COLORS.black};
    }

    span {
      color: ${COLORS.black};
    }
  }
`;
