import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';

export const NoDataContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
`;

export const NoDataIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 70px;
    height: 70px;
    margin-right: 5px;
  }

  span {
    margin-top: 10px;
    color: ${COLORS.mischka};
    font-size: 18px;
    font-family: ${FONTS.interRegular};
  }
`;
