import React, { useEffect, useState } from 'react';
import { SelectOption } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down.svg';
import { useHandleClickOutside } from 'shared/hooks';

type Props = {
  activeValue: any;
  action: (value: SelectOption) => void;
  options: SelectOption[];
  disabled?: boolean;
};

const Select: React.FC<Props> = ({
  activeValue,
  action,
  options,
  disabled = false
}): React.ReactElement => {
  const [activeOption, setActiveOption] = useState<SelectOption>(options[0]);
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  useEffect((): void => {
    const activeOption = options.find((option): boolean => option.value === activeValue);
    setActiveOption(activeOption || options[0]);
  }, [activeValue, options]);

  const handleOptionClick =
    (option: SelectOption): (() => void) =>
    (): void => {
      setIsMenuActive(false);
      if (option.title !== activeOption.title) {
        action(option);
      }
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer isMenuActive={isMenuActive} disabled={disabled} ref={ref}>
      <Styled.MainField isMenuActive={isMenuActive} onClick={toggleMenuActivity}>
        <div>{activeOption.title}</div>
        <img src={ArrowDownIcon} alt='Arrow' />
      </Styled.MainField>
      <Styled.SelectMenu isMenuActive={isMenuActive}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem
              onClick={handleOptionClick(option)}
              isActive={option.title === activeOption.title}
              key={option.title}
            >
              <span>{option.title}</span>
            </Styled.OptionItem>
          )
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default Select;
