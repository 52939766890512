import React from 'react';
import * as Styled from './styles';
import { EPremiumFeature } from 'shared/types';
import LogoLogin from 'assets/images/logo-icon-login.png';
import { FeatureDescription, ModalCopyright } from 'shared/styles';
import moment from 'moment';
import Lock from 'assets/images/lock-icon.svg';
import { FEATURES } from 'shared/constants/features';
import { LoginForm } from 'shared/components';

type Props = {
  feature: EPremiumFeature;
};

const ModalFeatureSignedOut: React.FC<Props> = ({ feature }): JSX.Element => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.ModalTitleContainer>
          <Styled.ModalTitle>{FEATURES[feature].title}</Styled.ModalTitle>
          <Styled.Lock>
            <img src={Lock} alt='Lock' />
          </Styled.Lock>
        </Styled.ModalTitleContainer>
        <FeatureDescription>{FEATURES[feature].description}</FeatureDescription>
        <Styled.FeaturePreview src={FEATURES[feature].preview} alt={FEATURES[feature].title} />
        <ModalCopyright>
          <span>{moment().year()} © glbee</span>
        </ModalCopyright>
      </Styled.LeftSide>
      <Styled.SeparateLine />
      <Styled.RightSide>
        <Styled.LoginFormContainer>
          <LoginForm />
        </Styled.LoginFormContainer>
        <Styled.SectionIcon src={LogoLogin} alt='Logo' />
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default ModalFeatureSignedOut;
