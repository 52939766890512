import React from 'react';
import * as Styled from './styles';
import NoDataImage from 'assets/images/no-data.png';

const NoData = (): JSX.Element => {
  return (
    <Styled.NoDataContainer>
      <Styled.NoDataIcon>
        <img src={NoDataImage} alt='No data' />
        <span>No data</span>
      </Styled.NoDataIcon>
    </Styled.NoDataContainer>
  );
};

export default NoData;
