export const convertFileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): any => resolve(reader.result as string);
    reader.onerror = (error): void => reject(error);
  });
};

export const convertDataURLtoFile = (dataUrl: string, filename: string): File => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const convertFileToFormData = (file: File, fileHint?: string, animations?: string[]): FormData => {
  const formData = new FormData();
  formData.append('file', file);

  if (fileHint !== undefined) {
    formData.append('fileHint', fileHint);
  }

  if (animations !== undefined) {
    formData.append('animations', JSON.stringify(animations));
  }

  return formData;
};

export const convertDataURLtoFormData = (dataUrl: string, filename: string): FormData => {
  const file = convertDataURLtoFile(dataUrl, filename);
  return convertFileToFormData(file);
};

export const convertDataToFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData();
  const dataArray = Object.entries(data);
  for (const item of dataArray) {
    formData.append(item[0], item[1]);
  }
  return formData;
};
