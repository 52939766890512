import React from 'react';
import * as Styled from './styles';
import { Subscriptions } from 'shared/components';

const SubscriptionTab = (): JSX.Element => {
  return (
    <Styled.PageContent>
      <Subscriptions withCustomPlan />
    </Styled.PageContent>
  );
};

export default SubscriptionTab;
