import React, { useEffect, useState } from 'react';

type Props = {
  startValue: number;
};

const Timer: React.FC<Props> = ({ startValue }): JSX.Element => {
  const [timerValue, setTimerValue] = useState<number>(startValue);

  useEffect((): (() => void) => {
    const intervalHandler = setInterval((): void => {
      setTimerValue((prev): number => (prev > 0 ? --prev : 0));
    }, 1000);

    return (): void => {
      clearInterval(intervalHandler);
    };
  }, []);

  return <span>{timerValue}</span>;
};

export default Timer;
