import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1150px;
  max-height: 700px;
  width: 90vw;
  height: 90vh;
  color: ${COLORS.white};
  position: relative;
  overflow-x: hidden;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.tablet} {
    flex-direction: column;
    width: 75vw;
  }

  @media${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const LeftSide = styled.section`
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  padding: 6% 4% 6% 5%;

  @media${DEVICE.tablet} {
    order: 1;
    padding-top: 30px;
    width: 100%;
  }
`;

export const SharingOptionsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  padding: 15px 20px;
  background: rgba(83, 83, 83, 0.4);
  border-radius: 2px;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s;

  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const SharingOptionsTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

export const SharingOptionsContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  flex-wrap: wrap;
`;

export const QrCodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-bottom: 20px;
`;

export const FeatureLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const QrCodeContainer = styled.div`
  margin: 15px 0 15px -8px;
  position: relative;
  width: max-content;

  span {
    position: absolute;
    color: ${COLORS.white};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-35deg);
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
  }
`;

export const QrCode = styled.img`
  width: 134px;
  height: 134px;
`;

export const DownloadButtonGroup = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const DownloadButton = styled.div`
  background: ${COLORS.turbo};
  border-radius: 2px;
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.codGray};
  margin-right: 5px;

  @media${DEVICE.mobile} {
    font-size: 12px;
  }
`;

export const FeaturesBlock = styled.div`
  width: 170px;
  display: flex;
  flex-direction: column;
`;

export const ShortLinkField = styled.div`
  margin: 15px 0;
  width: 100%;
  min-width: 162px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  background: ${COLORS.corn};
  padding: 10px;
`;

export const LinkIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ShortLink = styled.span`
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${COLORS.codGray};
  border: none;
  background: ${COLORS.corn};
  padding-right: 6px;
  width: 100%;
`;

export const CopyLinkButton = styled.div`
  background: ${COLORS.mineShaftGradient};
  border-radius: 2px;
  width: 50px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const DownloadModelButton = styled.div`
  margin-top: 15px;
  width: 100%;
  min-width: 160px;
  height: 48px;
  padding: 0 12px;
  background: ${COLORS.corn};
  border: none;
  border-radius: 2px;
  display: flex;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.codGray};
  }

  img {
    margin-left: auto;
    width: 15px;
    min-width: 15px;
    height: 15px;
  }
`;

export const ModalTitle = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
  padding: 15px 0;
`;

export const RightSide = styled.section`
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  padding: 6% 5% 6% 4%;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const LoginFormContainer = styled.div`
  @media${DEVICE.tablet} {
    padding-right: 60px;
  }

  @media${DEVICE.mobile} {
    padding-right: 35px;
  }
`;

export const SectionIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 137px;
  height: 203px;
  z-index: -1;

  @media${DEVICE.tablet} {
    bottom: initial;
    top: 50%;
    transform: translate(40%, -40%);
  }

  @media${DEVICE.mobile} {
    width: 120px;
    height: 160px;
  }
`;

export const Lock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-left: 30px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const SeparateLine = styled.div`
  height: 70%;
  width: 1px;
  background: #262626;
  align-self: center;
`;

export const ModalCopyrightContainer = styled.div`
  margin-top: auto;

  @media${DEVICE.desktop} {
    padding-bottom: 30px;
  }
`;
