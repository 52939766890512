import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  SubscriptionsState,
  CurrentCard,
  CurrentSubscriptionProduct,
  SubscriptionProduct,
  ESnackbarStyle
} from 'shared/types';
import { AppDispatch, RootState } from '../index';
import {
  getCurrentUserCards,
  getCurrentUserSubscription,
  getSubscriptionProducts
} from 'services/api/subscriptionsService';
import { openNotification } from 'utils/notification-utils';

const initialState: SubscriptionsState = {
  subscriptionPlans: [],
  currentSubscriptionPlan: null,
  currentUserCard: null,
  planLevels: null
};

// Actions

export const setSubscriptionPlans = createAction<SubscriptionProduct[]>(
  'subscriptions/setSubscriptionPlans'
);
export const setPlanLevels = createAction<Map<SubscriptionProduct, number> | null>(
  'subscriptions/setPlanLevels'
);
export const setCurrentSubscriptionPlan = createAction<CurrentSubscriptionProduct>(
  'subscriptions/setCurrentSubscriptionPlan'
);
export const setCurrentUserCards = createAction<CurrentCard[] | null>(
  'subscriptions/setCurrentUserCard'
);
export const clearSubscriptionsData = createAction('subscriptions/clearSubscriptionsData');

// Reducer

export const subscriptionsReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setSubscriptionPlans, (state, action): void => {
      state.subscriptionPlans = action.payload;
    })
    .addCase(setPlanLevels, (state, action): void => {
      state.planLevels = action.payload;
    })
    .addCase(setCurrentSubscriptionPlan, (state, action): void => {
      state.currentSubscriptionPlan = action.payload;
    })
    .addCase(setCurrentUserCards, (state, { payload }): void => {
      state.currentUserCard = !!payload?.length ? payload[0] : null;
    })
    .addCase(clearSubscriptionsData, (): SubscriptionsState => initialState);
});

// Thanks

export const fetchSubscriptions = createAsyncThunk<
  Promise<void>,
  void,
  { dispatch: AppDispatch; state: RootState }
>('subscriptions/fetchSubscriptions', async (_, { dispatch }): Promise<void> => {
  try {
    const subscriptionPlans = (await getSubscriptionProducts()).data;
    dispatch(setSubscriptionPlans(subscriptionPlans));
    const planLevels = new Map(
      subscriptionPlans.map((plan, index): [SubscriptionProduct, number] => [plan, index + 1])
    );
    dispatch(setPlanLevels(planLevels));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
  }
});

export const fetchCurrentUserCard = createAsyncThunk<
  Promise<void>,
  void,
  { dispatch: AppDispatch; state: RootState }
>('subscriptions/fetchCurrentUserCard', async (_, { dispatch }): Promise<void> => {
  try {
    const currentUserCards = (await getCurrentUserCards()).data;
    dispatch(setCurrentUserCards(currentUserCards));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
  }
});

export const fetchCurrentSubscription = createAsyncThunk<
  Promise<void>,
  void,
  { dispatch: AppDispatch; state: RootState }
>('subscriptions/fetchCurrentSubscription', async (_, { dispatch }): Promise<void> => {
  try {
    const currentUserSubscription = (await getCurrentUserSubscription()).data;
    dispatch(setCurrentSubscriptionPlan(currentUserSubscription));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
  }
});
