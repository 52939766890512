import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const PageContent = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

export const BillingSettingsContainer = styled.section`
  width: 70%;
  max-width: 700px;
  min-width: 300px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const PaymentBlock = styled.div`
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 8px;
  padding: 25px;

  @media ${DEVICE.mobile} {
    padding: 20px 10px;
  }
`;

export const CardContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border-radius: 4px;
  padding: 8px 6px;
`;

export const CurrentCard = styled.div`
  width: 100%;
  height: 19px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  color: #aab7c4;
`;

export const PaymentName = styled.span`
  margin-left: 20px;
  color: #aab7c4;
`;

export const PaymentBlockFooter = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardFormContainer = styled.div`
  margin-top: 20px;
`;

export const ActionButton = styled.button`
  width: 118px;
  height: 33px;
  background: ${COLORS.corn};
  border: none;
  border-radius: 4px;
  color: ${COLORS.black};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.turbo};
  }
`;

export const FormTitle = styled.span`
  font-size: 18px;
  color: ${COLORS.mischka};
  font-weight: 700;
`;

export const PaymentHistoryContainer = styled.div`
  margin-top: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background: ${COLORS.mineShaft};
  border-radius: 8px;

  @media ${DEVICE.mobile} {
    padding: 20px 10px;
  }
`;

export const PaymentHistoryList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const HistoryItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
  color: ${COLORS.mischka};

  span {
    padding: 0 10px;
  }

  span:first-child {
    padding-left: 0;
  }

  @media ${DEVICE.tablet} {
    flex-direction: column;
    align-items: flex-start;

    span {
      padding: 5px 0;
    }
  }
`;

export const DownloadButton = styled.button`
  background: ${COLORS.corn};
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: all 0.1s;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    transform: scale(1.1);
    background: ${COLORS.turbo};
  }

  @media ${DEVICE.tablet} {
    margin: 5px 0;
  }
`;

export const LoadMoreButton = styled.div`
  font-size: 18px;
  color: ${COLORS.mischka};
  margin: 30px auto 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const NoTransactionsText = styled.span`
  margin: 0 auto;
  padding: 10px;
  font-size: 16px;
  color: ${COLORS.mischka};
`;
