import { AxiosResponse } from 'axios';
import ApiService from './api';
import { CustomCubemap, CustomEnvironment, CustomTheme } from 'shared/types';

// Custom Branding

export const uploadUserCustomTheme = async (
  userId: number,
  formData: FormData
): Promise<AxiosResponse<CustomTheme>> =>
  await ApiService.post<FormData, CustomTheme>(`/users/${userId}/branding`, formData);

export const getUserCustomThemes = async (userId: number): Promise<AxiosResponse<CustomTheme[]>> =>
  await ApiService.get<CustomTheme[]>(`/users/${userId}/branding`);

export const uploadTeamCustomTheme = async (
  teamId: number,
  formData: FormData
): Promise<AxiosResponse<CustomTheme>> =>
  await ApiService.post<FormData, CustomTheme>(`/teams/${teamId}/branding`, formData);

export const getTeamCustomThemes = async (teamId: number): Promise<AxiosResponse<CustomTheme[]>> =>
  await ApiService.get<CustomTheme[]>(`/teams/${teamId}/branding`);

export const updateCustomTheme = async (
  brandingId: number,
  formData: FormData
): Promise<AxiosResponse<CustomTheme>> =>
  await ApiService.patch<FormData, CustomTheme>(`/branding/${brandingId}`, formData);

export const deleteCustomTheme = async (brandingId: number): Promise<AxiosResponse<void>> =>
  await ApiService.delete(`/branding/${brandingId}`);

export const deleteCustomThemeLogo = async (
  brandingId: number
): Promise<AxiosResponse<CustomTheme>> =>
  await ApiService.delete<CustomTheme>(`/branding/${brandingId}/logo`);

// Custom Environments

export const getUserCustomEnvironments = async (
  userId: number
): Promise<AxiosResponse<CustomEnvironment[]>> =>
  await ApiService.get<CustomEnvironment[]>(`/users/${userId}/custom-environments`);

export const uploadUserCustomEnvironment = async (
  userId: number,
  formData: FormData
): Promise<AxiosResponse<CustomEnvironment>> =>
  await ApiService.post<FormData, CustomEnvironment>(
    `/users/${userId}/custom-environments`,
    formData
  );

export const getTeamCustomEnvironments = async (
  teamId: number
): Promise<AxiosResponse<CustomEnvironment[]>> =>
  await ApiService.get<CustomEnvironment[]>(`/teams/${teamId}/custom-environments`);

export const uploadTeamCustomEnvironment = async (
  teamId: number,
  formData: FormData
): Promise<AxiosResponse<CustomEnvironment>> =>
  await ApiService.post<FormData, CustomEnvironment>(
    `/teams/${teamId}/custom-environments`,
    formData
  );

export const updateCustomEnvironment = async (
  environmentId: number,
  formData: FormData
): Promise<AxiosResponse<CustomEnvironment>> =>
  await ApiService.patch<FormData, CustomEnvironment>(
    `/custom-environments/${environmentId}`,
    formData
  );

export const deleteCustomEnvironment = async (
  environmentId: number
): Promise<AxiosResponse<void>> => await ApiService.delete(`/custom-environments/${environmentId}`);

export const uploadEnvironmentThumbnail = async (
  environmentId: number,
  formData: FormData
): Promise<AxiosResponse<void>> =>
  await ApiService.patch<FormData, void>(
    `/custom-environments/${environmentId}/thumbnail`,
    formData
  );

// Custom cubemaps

export const getUserCustomCubemaps = async (
  userId: number
): Promise<AxiosResponse<CustomCubemap[]>> =>
  await ApiService.get<CustomCubemap[]>(`/users/${userId}/custom-cubemaps`);

export const uploadUserCustomCubemap = async (
  userId: number,
  formData: FormData
): Promise<AxiosResponse<CustomCubemap>> =>
  await ApiService.post<FormData, CustomCubemap>(`/users/${userId}/custom-cubemaps`, formData);

export const getTeamCustomCubemaps = async (
  teamId: number
): Promise<AxiosResponse<CustomCubemap[]>> =>
  await ApiService.get<CustomCubemap[]>(`/teams/${teamId}/custom-cubemaps`);

export const uploadTeamCustomCubemap = async (
  teamId: number,
  formData: FormData
): Promise<AxiosResponse<CustomCubemap>> =>
  await ApiService.post<FormData, CustomCubemap>(`/teams/${teamId}/custom-cubemaps`, formData);

export const updateCustomCubemap = async (
  cubemapId: number,
  formData: FormData
): Promise<AxiosResponse<CustomCubemap>> =>
  await ApiService.patch<FormData, CustomCubemap>(`/custom-cubemaps/${cubemapId}`, formData);

export const deleteCustomCubemap = async (cubemapId: number): Promise<AxiosResponse<void>> =>
  await ApiService.delete(`/custom-cubemaps/${cubemapId}`);
