export const PLATFORM_SUMMARY = {
  users: {
    active: 0,
    created: 0,
    deleted: 0,
    total: 0
  },
  totalUploadedModels: 0,
  totalModelsSize: '0',
  totalPlan: {
    totalSubscriptions: 0,
    billingPlans: [
      {
        name: 'Hex / Basic Plan',
        count: 0
      },
      {
        name: 'Mesh / Pro Plan',
        count: 0
      },
      {
        name: 'Flight / Expert Plan',
        count: 0
      },
      {
        name: 'Custom',
        count: 0
      }
    ]
  }
};
