import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { useHistory, useLocation } from 'react-router';
import { getCurrentUser } from 'services/api/authService';
import { loginUser, logoutUser } from 'services/store/reducers/authReducer';
import { Route } from 'react-router-dom';
import { FullPageContainer } from 'shared/styles';
import Spinner from 'assets/images/spinner.svg';
import { checkIsIFrame } from 'utils/helper-utils';

type LocationState = {
  from: {
    pathname: string;
  };
};

const NotGuardedRoute = ({ ...routeProps }): JSX.Element => {
  const isAuth = useAppSelector((state): boolean => state.auth.isAuth);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect((): void => {
    const token = checkIsIFrame() ? '' : localStorage.getItem('token');

    const fetchUser = async (): Promise<void> => {
      try {
        const response = await getCurrentUser();
        await dispatch(loginUser({ user: response.data }));
        setIsChecked(true);
      } catch (e) {
        dispatch(logoutUser());
        history.push(location.pathname);
      }
    };

    !!token && !isAuth ? fetchUser() : setIsChecked(true);
  }, [dispatch, history, isAuth, location]);

  return isChecked ? (
    <Route {...routeProps} />
  ) : (
    <FullPageContainer>
      <img src={Spinner} className='spinner' alt='Loading' />
    </FullPageContainer>
  );
};

export default NotGuardedRoute;
