import styled from 'styled-components';
import { FONTS } from 'shared/constants/fonts';

export const List = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  left: auto;
  bottom: auto;
  width: calc(100% - 26px - 26px);
  max-height: 15vh;
  padding: 0;
  background-color: #000000;
  overflow: auto;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(214, 215, 227, 0.08);
  }
`;

export const Photo = styled.img`
  width: 18px;
  height: auto;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Credentials = styled.div`
  margin: 0;
  padding: 0;
  font-family: ${FONTS.interRegular}, serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Email = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${FONTS.interRegular}, serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
