import React, { JSX, RefObject } from 'react';
import * as Styled from './styles';

interface Props {
  tooltipBlockRef: RefObject<HTMLDivElement>;
}

export const ViewerSceneTooltipBlock: React.FC<Props> = ({tooltipBlockRef}): JSX.Element => {

  return (
    <Styled.TooltipBlock ref={tooltipBlockRef}>
      <Styled.TooltipContainer>
        <Styled.TooltipItem />

        <Styled.TooltipItem />
      </Styled.TooltipContainer>
    </Styled.TooltipBlock>
  );
};
