import { EModelAccessLevel } from 'shared/types';

export const MODEL_ACCESSES = [
  {
    title: 'View Model',
    access: EModelAccessLevel.VIEW_ACCESS,
    isAvailable: true
  },
  {
    title: 'View AR',
    access: EModelAccessLevel.VIEW_AR_ACCESS,
    isAvailable: true
  },
  {
    title: 'Comment',
    access: EModelAccessLevel.COMMENT_ACCESS,
    isAvailable: true
  },
  {
    title: 'Edit',
    access: EModelAccessLevel.EDIT_ACCESS,
    isAvailable: true
  }
];
