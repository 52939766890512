import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const PromptWrapper = styled.section`
  position: fixed;
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.deepBlack};
  z-index: 99999;
`;

export const PromptContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 720px;
  max-height: 620px;
  display: flex;
  flex-direction: column;
  padding: 80px 60px;
  position: relative;
  overflow: hidden;

  @media (max-height: 640px), (max-width: 640px) {
    padding: 40px 30px;
  }

  @media (max-height: 420px), (max-width: 360px) {
    align-items: center;
    justify-content: center;
  }
`;

export const PromptHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PromptTitle = styled.h1`
  display: flex;
  align-items: center;

  .prompt-icon {
    font-size: 80px;
    color: ${COLORS.turbo};
  }

  .title {
    font-size: 72px;
    font-weight: 500;
    line-height: 87px;
    color: ${COLORS.white};
    margin: 0 0 0 20px;
  }

  @media (max-height: 640px), (max-width: 640px) {
    .prompt-icon {
      font-size: 50px;
    }

    .title {
      font-size: 50px;
      line-height: 54px;
    }
  }

  @media (max-height: 420px), (max-width: 360px) {
    .prompt-icon {
      font-size: 30px;
    }

    .title {
      font-size: 30px;
      line-height: 34px;
    }
  }
`;

export const PromptContent = styled.div`
  margin-top: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.white};
  text-align: center;

  @media (max-height: 640px), (max-width: 640px) {
    margin-top: 30px;
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-height: 420px), (max-width: 360px) {
    margin-top: 20px;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const PromptCubeIcon = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 340px;
  height: 380px;
  transform: translate(-50%, 30%) rotate(90deg);

  @media (max-height: 640px), (max-width: 640px) {
    width: 280px;
    height: 300px;
  }

  @media (max-height: 540px), (max-width: 440px) {
    width: 230px;
    height: 250px;
  }

  @media (max-height: 420px), (max-width: 360px) {
    display: none;
  }
`;
