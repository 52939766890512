import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import * as Styled from './styles';
import Logo from 'assets/images/logo.png';
import Delete from 'assets/images/delete.svg';
import Download from 'assets/images/download.svg';
import AR from 'assets/images/view-ar.svg';
import Link from 'assets/images/link.svg';
import Warning from 'assets/images/warning.svg';
import QuickShare from 'assets/images/quick-share.svg';
import NewTab from 'assets/images/new-tab-icon.svg';
import LogoAR from 'assets/images/logo-ar.png';
import Spinner from 'assets/images/spinner.svg';
import {
  EConfirmModalHeader,
  EModelAccessLevel,
  EQuotaName,
  ESharingStatus,
  ESnackbarStyle,
  Model
} from 'shared/types';
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
  useHandleClickOutside,
  useFeatureAccess,
  useQrCodeGenerator
} from 'shared/hooks';
import { deleteModel, downloadModel } from 'services/api/modelService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { DELETE_BRACKETS_REGEXP } from 'shared/constants/regexps';
import { getPlainNotesList } from 'services/api/notesService';
import { checkQuota, convertBytesToMegabytes, getModelMetadata } from 'utils/model-utils';
import { check8thWallCompatibility, loadArScripts } from 'utils/ar-utils';
import { closeConfirmModal, showConfirmModal } from 'services/store/reducers/modalReducer';
import { ModalDeleteModel, CustomTooltip } from 'shared/components';
import { MODEL_DELETED, MODEL_DOWNLOADING } from 'shared/constants/notifications';
import { updateModelPublicAccessLevel } from 'services/api/permissionsService';
import { openNotification } from 'utils/notification-utils';
import { RootState } from 'services/store';

type Props = {
  item: Model;
  fetchModelsList: () => Promise<void>;
  showMyModelsLabel?: boolean;
  isTeamPage?: boolean;
};

const ModelCard: React.FC<Props> = ({
  item: model,
  fetchModelsList,
  showMyModelsLabel,
  isTeamPage
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const store = useAppSelector((store): RootState => store);
  const { user } = store.auth;
  const { activeTeam } = store.teams;
  const [isArQrCodeActive, setIsArQrCodeActive] = useState<boolean>(false);
  const [hasArViewAccess, setHasArViewAccess] = useState<boolean>(false);
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(false);
  const isShareQRCodeCreated = useRef(false);
  const shareLink = (model.shortLinkUrl || model.id).replace('https://', '');
  const shareLinkWithQueryParams = `${model.shortLinkUrl}?ar=1`;
  const { coverImageUrl, modelName, modelSize } = model;
  const isPublicModel = model.status === ESharingStatus.PUBLISHED;
  const isModelOwner = user?.id === model.ownerId;
  const hasDeleteAccess = !!activeTeam ? hasEditAccess : isModelOwner;
  const { geometry, details, textures, litValues } = getModelMetadata(model);
  const { copyToClipboard } = useCopyToClipboard();
  const { checkArViewAccess, checkEditAccess } = useFeatureAccess(model, true);
  const {
    isQrCodeLoading: isArQrLoading,
    createQRCode: createArQRCode,
    qrCodeURL: arQRCodeURL
  } = useQrCodeGenerator(shareLinkWithQueryParams, LogoAR);
  const {
    isQrCodeLoading: isShareQrLoading,
    createQRCode: createShareQRCode,
    qrCodeURL: shareQRCodeURL
  } = useQrCodeGenerator(model.shortLinkUrl);
  const [totalNotes, setTotalNotes] = useState<number>(0);

  useEffect((): void => {
    if ((isModelOwner || isTeamPage) && isPublicModel && !isShareQRCodeCreated.current) {
      createShareQRCode();
      isShareQRCodeCreated.current = true;
    }
  }, [createShareQRCode, isModelOwner, isPublicModel, isTeamPage]);

  useEffect((): void => {
    if (isArQrCodeActive) {
      createArQRCode();
    }
  }, [isArQrCodeActive, createArQRCode]);

  useEffect((): void => {
    (async (): Promise<void> => {
      const hasArViewAccess = await checkArViewAccess();
      const hasEditAccess = await checkEditAccess();
      setHasArViewAccess(hasArViewAccess);
      setHasEditAccess(hasEditAccess);
    })();
  }, [checkArViewAccess, checkEditAccess]);

  useEffect((): void => {
    (async (): Promise<void> => {
      const total = (await getPlainNotesList(model.id)).data.length;
      setTotalNotes(total);
    })();
  }, [model]);

  const handleCopyLinkButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    await copyToClipboard(model.shortLinkUrl || model.id);
  };

  const handleDownloadButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(startLoader());
    try {
      openNotification(ESnackbarStyle.SUCCESS, MODEL_DOWNLOADING);
      const response = await downloadModel(model.id);
      const type = response.data.type;
      const modelName = response.headers['content-disposition'].split('filename=')[1].split('"')[1];
      const blob = new Blob([response.data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = modelName;
      a.click();
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const openModelInNewTab = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    window.open(`/${model.shortCode || model.id}`, '_blank');
  };

  const handleArButtonClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    const isDeviceBrowserCompatible = await check8thWallCompatibility();
    if (!isDeviceBrowserCompatible) {
      setIsArQrCodeActive((prevState): boolean => !prevState);
    } else {
      await loadArScripts(dispatch);
      history.push(`/${model.shortCode || model.id}`, { isArModeClickAction: true });
    }
  };

  const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    const action = async (): Promise<void> => {
      try {
        dispatch(startLoader());
        await deleteModel(model!.id);
        await fetchModelsList();
        dispatch(closeConfirmModal());
        openNotification(ESnackbarStyle.SUCCESS, MODEL_DELETED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: <ModalDeleteModel model={model} action={action} />
      })
    );
  };

  const handleQuickShareButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(startLoader());
    try {
      await updateModelPublicAccessLevel(model.id, [EModelAccessLevel.VIEW_ACCESS]);
      await checkQuota(model.id, EQuotaName.MODEL_VIEWS_LIMIT);
      await fetchModelsList();
      await copyToClipboard(shareLink);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const getQuickShareTooltip = (): JSX.Element => (
    <>
      <div>
        Quick share - make model <i>Public</i> & copy sharing link
      </div>
      <br />
      <span>
        Note: this sharing link is set to public by default. To change this to <i>invite-only</i> or
        <i>Private</i>, change the Sharing Permissions settings within the model viewer
      </span>
    </>
  );

  const qrCodeContainerRef = useHandleClickOutside((): void => setIsArQrCodeActive(false));

  const getModelOwnerName = (): string => model.ownerName.replace(DELETE_BRACKETS_REGEXP, '');

  return (
    <Styled.ModelCardWrapper>
      <NavLink to={`/${model.shortCode || model.id}`} data-cy='model'>
        <Styled.ModelCardContainer>
          {(isModelOwner || isTeamPage) && (
            <Styled.LabelStatus isPublic={isPublicModel}>
              {isPublicModel ? 'Public' : 'Private'}
            </Styled.LabelStatus>
          )}
          {showMyModelsLabel && isModelOwner && (
            <Styled.LabelStatus className='my-models-label'>My model</Styled.LabelStatus>
          )}
          <Styled.NewTabIconContainer onClick={openModelInNewTab}>
            <Styled.NewTabIcon src={NewTab} />
          </Styled.NewTabIconContainer>
          {!!totalNotes && (
            <Styled.NoteCounter>
              <span>{totalNotes}</span>
            </Styled.NoteCounter>
          )}
          {(isModelOwner || isTeamPage) && isPublicModel && (
            <Styled.ShareQrCodeContainer isLoading={isShareQrLoading}>
              {isShareQrLoading && <Styled.Spinner src={Spinner} alt='Loading' />}
              {!!shareQRCodeURL && <img src={shareQRCodeURL} alt='qr-code' />}
            </Styled.ShareQrCodeContainer>
          )}
          {!!coverImageUrl ? (
            <Styled.ModelImage
              onError={({ currentTarget }): void => {
                currentTarget.src = Logo;
                currentTarget.style.objectFit = 'scale-down';
              }}
              src={coverImageUrl}
              alt='Model Image'
            />
          ) : (
            <Styled.DefaultImage src={Logo} alt='Model Image' />
          )}
          <Styled.ModelDescriptionContainer>
            <Styled.ModelTitle>
              <CustomTooltip content={modelName} maxWidth={355}>
                <span>{modelName}</span>
              </CustomTooltip>
            </Styled.ModelTitle>
            <Styled.ModelDescription>
              <Styled.DescriptionBlock>
                <Styled.DescriptionBlockTitle>Geometry</Styled.DescriptionBlockTitle>
                {geometry.map(
                  ({ title, value }): JSX.Element => (
                    <Styled.DescriptionItem key={title}>
                      <span>{title}</span>
                      <span>{value}</span>
                    </Styled.DescriptionItem>
                  )
                )}
              </Styled.DescriptionBlock>

              <Styled.DescriptionBlock>
                <Styled.DescriptionBlockTitle>Textures</Styled.DescriptionBlockTitle>
                {textures.map(
                  ({ title, value }): JSX.Element => (
                    <Styled.DescriptionItem key={title}>
                      <span>{title}</span>
                      {title === 'NPOT' && <img src={Warning} alt='NPOT' />}
                      <span>{value}</span>
                    </Styled.DescriptionItem>
                  )
                )}
              </Styled.DescriptionBlock>

              <Styled.DescriptionBlock>
                <Styled.DescriptionBlockTitle>Details</Styled.DescriptionBlockTitle>
                {details.map(
                  ({ title, value }): JSX.Element => (
                    <React.Fragment key={title}>
                      <Styled.DescriptionItem>
                        <span>{title}</span>
                        <span>{value}</span>
                      </Styled.DescriptionItem>
                      {title === 'Materials' && (
                        <Styled.DescriptionItemLit>
                          <Styled.LitItem isActive={litValues.isLit}>Lit</Styled.LitItem>
                          <Styled.LitItem isActive={litValues.isUnlit}>Unlit</Styled.LitItem>
                        </Styled.DescriptionItemLit>
                      )}
                    </React.Fragment>
                  )
                )}
                <Styled.DescriptionItem>
                  <span>Size</span>
                  <span>{convertBytesToMegabytes(modelSize)}Mb</span>
                </Styled.DescriptionItem>
                <Styled.DescriptionItem>
                  <span>Format</span>
                  <span>*{model.metadata.format}</span>
                </Styled.DescriptionItem>
              </Styled.DescriptionBlock>
            </Styled.ModelDescription>
            <Styled.DescriptionFooter>
              <Styled.ModelOwner>
                <span>Owner:</span>
                <span>{isModelOwner ? 'Me' : getModelOwnerName()}</span>
              </Styled.ModelOwner>

              <Styled.ModelActions>
                {hasEditAccess && (
                  <>
                    {isPublicModel ? (
                      <CustomTooltip content={'Copy sharing link for model'}>
                        <Styled.ActionButton
                          onClick={handleCopyLinkButtonClick}
                          id='model-card-copy-link-button'
                        >
                          <img src={Link} alt='Link' />
                        </Styled.ActionButton>
                      </CustomTooltip>
                    ) : (
                      <CustomTooltip content={getQuickShareTooltip()}>
                        <Styled.ActionButton
                          onClick={handleQuickShareButtonClick}
                          className='quick-sharing'
                          id='model-card-quick-sharing-button'
                        >
                          <img src={QuickShare} alt='Quick Sharing' />
                        </Styled.ActionButton>
                      </CustomTooltip>
                    )}
                  </>
                )}

                {hasArViewAccess && (
                  <Styled.ArButtonContainer>
                    {isArQrCodeActive && (
                      <Styled.QrCodeContainer ref={qrCodeContainerRef}>
                        <Styled.QrCodeWrapper>
                          <span>Scan to activate AR</span>
                          <Styled.QrCode>
                            {isArQrLoading && <Styled.Spinner src={Spinner} alt='Loading' />}
                            <img src={arQRCodeURL} alt='qr-code' />
                          </Styled.QrCode>
                          <Styled.QrCodeArrow />
                        </Styled.QrCodeWrapper>
                      </Styled.QrCodeContainer>
                    )}
                    <CustomTooltip content={'View the model in AR Mode'}>
                      <Styled.ActionButton
                        onClick={handleArButtonClick}
                        className='ar-button'
                        id='model-card-ar-button'
                      >
                        <img src={AR} alt='AR' />
                      </Styled.ActionButton>
                    </CustomTooltip>
                  </Styled.ArButtonContainer>
                )}
                <CustomTooltip content={'Download the model'}>
                  <Styled.ActionButton
                    onClick={handleDownloadButtonClick}
                    id='model-card-download-model-button'
                  >
                    <img src={Download} alt='Download' />
                  </Styled.ActionButton>
                </CustomTooltip>
                {hasDeleteAccess && (
                  <CustomTooltip content={'Delete your model'}>
                    <Styled.ActionButton
                      onClick={handleDeleteButtonClick}
                      id='model-card-delete-model-button'
                    >
                      <img src={Delete} alt='Delete' />
                    </Styled.ActionButton>
                  </CustomTooltip>
                )}
              </Styled.ModelActions>
            </Styled.DescriptionFooter>
          </Styled.ModelDescriptionContainer>
        </Styled.ModelCardContainer>
      </NavLink>
    </Styled.ModelCardWrapper>
  );
};

export default ModelCard;
