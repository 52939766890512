import { ITexture2DTileStats } from '../interfaces/model-parse/model-stats';
import { Texture2DImageStats } from './texture-2d-image-stats';

export class Texture2DTileStats implements ITexture2DTileStats {
  public totalCount: number = 0;

  public jpeg: Texture2DImageStats = new Texture2DImageStats();
  public png: Texture2DImageStats = new Texture2DImageStats();

  public update2DTileStats(mimeType: string, fileSize: number | null): void {
    if (mimeType !== 'image/png' && mimeType !== 'image/jpeg' && mimeType !== 'image/jpg') {
      return;
    }
    if (fileSize) {
      if (mimeType === 'image/png') {
        this.png.updateImage2DStats(fileSize);
      }

      if (mimeType === 'image/jpeg' || mimeType === 'image/jpg') {
        this.jpeg.updateImage2DStats(fileSize);
      }

      this.totalCount++;
    }
  }

  public plainObject(): ITexture2DTileStats {
    return {
      totalCount: this.totalCount,
      png: this.png.plainObject(),
      jpeg: this.jpeg.plainObject()
    };
  }
}
