import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 90vw;
  color: ${COLORS.white};
  padding: 48px 33px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media ${DEVICE.tablet} {
    padding: 30px 20px;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const ModalSubtitle = styled.h2`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const AddPeopleFieldContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
`;

export const AddPeopleField = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  @media${DEVICE.mobile} {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  height: 70px;
  width: 100%;

  @media${DEVICE.mobile} {
    margin-top: 10px;
    height: min-content;
  }
`;

export const AddPeopleInput = styled.input`
  height: 39px;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${COLORS.turbo};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 4px 4px 0 0;
  padding: 13px 11px;
`;

export const InputFooter = styled.div`
  display: flex;
  flex-direction: column;

  .hint {
    margin-top: 6px;
    margin-left: auto;
    font-size: 10px;
    line-height: 11px;

    @media${DEVICE.tabletLarge} {
      display: none;
    }
  }
`;

export const AddUserButton = styled.button`
  width: 152px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.codGray};
  margin-left: 8px;
  border-radius: 2px;

  &:hover {
    background: ${COLORS.gorse};
  }

  @media ${DEVICE.mobile} {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;
