import { ETeamRole } from 'shared/types';

export const PERMISSIONS_LIST = [
  {
    title: 'Admin',
    value: ETeamRole.ADMIN,
    tooltip: 'Can create models, manage members, change billing, and adjust settings'
  },
  {
    title: 'Manager',
    value: ETeamRole.MANAGER,
    tooltip: 'Can create models plus add or remove members'
  },
  {
    title: 'Designer',
    value: ETeamRole.DESIGNER,
    tooltip: 'Can view and create models in the workspace'
  },
  {
    title: 'Viewer',
    value: ETeamRole.VIEWER,
    tooltip: 'Can view all models in the workspace'
  }
];

export const PERMISSIONS = {
  [ETeamRole.OWNER]: PERMISSIONS_LIST,
  [ETeamRole.ADMIN]: PERMISSIONS_LIST.slice(1),
  [ETeamRole.MANAGER]: PERMISSIONS_LIST.slice(2),
  [ETeamRole.DESIGNER]: [],
  [ETeamRole.VIEWER]: []
}
