import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const Comment = styled.li<{
  isHighlighted: boolean;
  isBlink: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 26px 14px 22px;
  width: 100%;
  background-color: ${({ isHighlighted }): string =>
  isHighlighted ? 'rgba(214, 215, 227, 0.08)' : 'initial'};

  ${({ isBlink }): string =>
  isBlink
    ? `
    animation: blinker 1s linear infinite;

    @keyframes blinker {
      0% {
        background-color: rgba(214, 215, 227, 0.2);
      }
    }
    ` : ''}
`;

export const OptionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`;

export const InfoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const infoItem = styled.span<{
  hasMargin?: boolean;
}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;

  ${({hasMargin}): string => hasMargin ? `
    margin-right: 36px;

    @media (max-width: 1365px) {
      margin-right: 12px;
    }
  ` : ''}
`;

export const CommentButtonsWrapper = styled.div`
  justify-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CommentsButton = styled.div<{
  isEditButton?: boolean;
}>`
  margin-right: ${({isEditButton}): string => isEditButton ? '10px' : '0'};
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
`;

export const CommentHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
`;

export const CommentHeaderImage = styled.img`
  margin-right: 13px;
  width: 15px;
  height: auto;
`

export const CommentAuthor = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CommentPassed = styled.div`
  margin-left: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
`;

export const CommentText = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  word-break: break-word;
  hyphens: auto;
  color: #ffffff;
  user-select: text;

  & > .mention {
    font-family: inherit;
    color: var(--theme-secondary-color);
    -webkit-user-modify: read-only;
    -webkit-user-select: text;
  }
`;

export const CommentMention = styled.span`
  font-family: inherit;
  color: ${COLORS.turbo};
  -webkit-user-modify: read-only;
  -webkit-user-select: text;
`;
