import React, { useEffect } from 'react';
import * as Styled from './styles';
import Download from 'assets/images/download.svg';
import { TeamsState } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { useTeamTransactionsData } from 'shared/hooks';
import moment from 'moment';
import { TeamCardForm } from 'shared/components';

const BillingTab = (): JSX.Element => {
  const ONE_USD = 100;
  const ONE_SECOND = 1000;
  const dispatch = useAppDispatch();
  const { activeTeam, teamCard } = useAppSelector((store): TeamsState => store.teams);
  const { transactions, fetchTransactions, hasMore } = useTeamTransactionsData();
  const LAST_4 = !!teamCard?.card ? teamCard?.card.last4 : teamCard?.sepa_debit?.last4;

  useEffect((): void => {
    if (!!activeTeam) {
      fetchTransactions(activeTeam.id);
    }
  }, [activeTeam, fetchTransactions]);

  const handleDownloadButtonClick = (url: string, filename: string): void => {
    dispatch(startLoader());
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    dispatch(stopLoader());
  };

  const handleLoadMoreClick = async (): Promise<void> => {
    if (!!activeTeam) {
      await fetchTransactions(activeTeam.id);
    }
  };

  return (
    <Styled.PageContent>
      <Styled.BillingSettingsContainer>
        {!!teamCard && (
          <Styled.PaymentBlock>
            <Styled.FormTitle>Current card</Styled.FormTitle>
            <Styled.CardContainer>
              {!!LAST_4 && (
                <Styled.CurrentCard>
                  <span>**** **** **** {LAST_4}</span>
                  {!!teamCard.card && (
                    <span>{`${teamCard.card.exp_month} / ${teamCard.card.exp_year}`}</span>
                  )}
                </Styled.CurrentCard>
              )}
              {teamCard.type === 'paypal' && <Styled.PaymentName>Paypal</Styled.PaymentName>}
            </Styled.CardContainer>
          </Styled.PaymentBlock>
        )}
        <Styled.CardFormContainer>
          <TeamCardForm teamId={activeTeam!.id} />
        </Styled.CardFormContainer>
        <Styled.PaymentHistoryContainer>
          <Styled.FormTitle>Payment History</Styled.FormTitle>
          {!!transactions.length ? (
            <Styled.PaymentHistoryList>
              {transactions.map(({ total, number, created, invoice_pdf, id }): JSX.Element => {
                const totalValue = total / ONE_USD;
                const payValue = totalValue < 0 ? `-$${-totalValue}` : `$${totalValue}`;
                return (
                  <Styled.HistoryItem key={id}>
                    <span className='transaction-number'>Transaction number #{number}</span>
                    <span className='transaction-pay'>{payValue}</span>
                    <span className='transaction-date'>
                      {moment(created * ONE_SECOND).format('DD MMM YYYY')}
                    </span>
                    <Styled.DownloadButton
                      onClick={(): void => handleDownloadButtonClick(invoice_pdf, number)}
                    >
                      <img src={Download} alt='Download' />
                    </Styled.DownloadButton>
                  </Styled.HistoryItem>
                );
              })}
            </Styled.PaymentHistoryList>
          ) : (
            <Styled.NoTransactionsText>No transactions</Styled.NoTransactionsText>
          )}

          {hasMore && (
            <Styled.LoadMoreButton onClick={handleLoadMoreClick}>Load more</Styled.LoadMoreButton>
          )}
        </Styled.PaymentHistoryContainer>
      </Styled.BillingSettingsContainer>
    </Styled.PageContent>
  );
};

export default BillingTab;
