import * as THREE from 'three';
import { BaseLoadModelStrategy } from './base-load-model.strategy';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';
import { allowed3dModelAssetsTypes } from 'shared/constants/allowed-3d-model-assets-types';

export class ObjLoadModelStrategy extends BaseLoadModelStrategy {
  private mtlLoader: MTLLoader;
  private objLoader: OBJLoader;
  private textureLoader: THREE.TextureLoader;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    super(renderer, onModelResourcesLoaded);

    this.mtlLoader = new MTLLoader(this.loadingManager).setCrossOrigin('anonymous');
    this.objLoader = new OBJLoader(this.loadingManager).setCrossOrigin('anonymous');
    this.textureLoader = new THREE.TextureLoader(this.loadingManager).setCrossOrigin('anonymous');
  }

  private async loadMtlForObj(files: string[]): Promise<void> {
    for (const file of files) {
      const materials = await this.mtlLoader.loadAsync(file);

      materials.preload();
      this.objLoader.setMaterials(materials);
    }
  }

  public async loadModel({ path, mtls, assets }: LoadModelData): Promise<LoadModelResult> {
    this.setLoadingContent(assets);

    if (mtls.length) {
      await this.loadMtlForObj(mtls);
    }

    const model = await this.objLoader.loadAsync(path);
    const textureContent = assets.filter((item): boolean =>
      allowed3dModelAssetsTypes.textures.includes(item.ext.split('.').pop()!.toLowerCase())
    );

    if (textureContent.length === 1) {
      const texture = await this.textureLoader.loadAsync(textureContent[0].publicUrl);

      model.traverse((object): void => {
        if (object instanceof THREE.Mesh<any>) {
          object.material.map = texture;
          object.geometry.computeVertexNormals();
        }
      });
    }

    this.setLoadingContent([]);

    return {
      gltf: null,
      model
    };
  }
}
