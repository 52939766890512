import React, { useEffect, useMemo, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GuardedRoute, NotGuardedRoute } from 'routes';
import {
  ConfirmModalWindow,
  Footer,
  Header,
  ModalWindow,
  PageLoader,
  Sidebar,
  EmbeddedModelPrompt,
  FullScreenLoader
} from 'shared/components';
import { ERouteType, RouteItem } from 'shared/types';
import { ROUTES } from './routes';
import { RootContainer } from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks/store-hooks';
import { RootState } from 'services/store';
import { useLocation } from 'react-router';
import { fetchSubscriptions } from 'services/store/reducers/subscriptionsReducer';
import { usePageTopPositionContext } from 'shared/providers';
import { elements } from 'utils/notification-utils';
import { setIsBrandingLoading } from 'services/store/reducers/brandingReducer';
import { useURLParamsHandling } from 'shared/hooks';
import { checkIsIFrame, checkIsModelPathname } from 'utils/helper-utils';
import { showEmbeddedModelPrompt } from 'services/store/reducers/modalReducer';
import { NO_MODEL } from 'shared/constants/notifications';
import { Userpilot } from 'userpilot';
import {
  setIsEmbeddedModelMode,
  setIsTransparentBackground
} from 'services/store/reducers/viewerDataReducer';

const MainRoutes = (): React.ReactElement => {
  const isScheduledMaintenance = false;
  useURLParamsHandling();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isEmbeddedModel = params.get('embed');
  const transparentParam = params.get('transparent');
  const isTransparentBackground = !!transparentParam && !!+transparentParam;
  const store = useAppSelector((store): RootState => store);
  const isUserpilotInitialized = useRef<boolean>(true);
  const { isAuth, user } = store.auth;
  const { isLoaderActive, isFullScreenLoaderActive } = store.loader;
  const { isModalOpened, isConfirmModalOpened, isEmbeddedModelPromptActive } = store.modal;
  const { isArMode } = store.arMode;
  const { isPageWithScene } = store.sidebar;
  const { isViewerPage, isZenMode, model, isEmbeddedModelMode } = store.viewerData;
  const { subscriptionPlans } = store.subscriptions;
  const { isBrandingLoading } = store.branding;
  const { pageTop } = usePageTopPositionContext();
  const isModelPage = useMemo((): boolean => checkIsModelPathname(location), [location]);

  useEffect((): void => {
    if (isEmbeddedModelPromptActive) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('no-scroll');
    }
  }, [isEmbeddedModelPromptActive]);

  useEffect((): void => {
    const antNotifications = elements('ant-notification');

    if (!!antNotifications.length) {
      for (const element of antNotifications) {
        !!model
          ? element.classList.add('scene-notification')
          : element.classList.remove('scene-notification');
      }
    }
  }, [model]);

  useEffect((): void => {
    if (!!isEmbeddedModel) {
      dispatch(setIsEmbeddedModelMode(true));
      if (isTransparentBackground) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('with-transparent-background');
        dispatch(setIsTransparentBackground(true));
      }
    }
  }, [dispatch, isEmbeddedModel, isTransparentBackground]);

  useEffect((): void => {
    if (isModelPage) {
      if (!window.userpilot || !window.userpilot.destroy || !isUserpilotInitialized) return;

      isUserpilotInitialized.current = false;

      Userpilot.destroy();
    } else {
      if (!isUserpilotInitialized.current) {
        isUserpilotInitialized.current = true;

        Userpilot.initialize(process.env.REACT_APP_USER_PILOT_ID || ``);
      }

      if (!!user) {
        Userpilot.identify(`${user.id}`, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          created_at: user.createdAt
        });
      } else {
        // Userpilot.anonymous(); // Temporarily stop tracking anonymous users
      }
    }
  }, [isModelPage, user]);

  useEffect((): void => {
    if (!isModelPage) {
      dispatch(setIsBrandingLoading(false));
    }
  }, [dispatch, isModelPage]);

  useEffect((): void => {
    if (!checkIsIFrame()) {
      pageTop?.current?.scrollIntoView({ behavior: 'auto' });
    }
  }, [pageTop, location.pathname, isEmbeddedModelMode]);

  useEffect((): void => {
    if (!subscriptionPlans.length) {
      dispatch(fetchSubscriptions());
    }
  }, [dispatch, isAuth, subscriptionPlans]);

  useEffect((): void => {
    const feedbackButton = document.getElementById('userback_button_container');
    if (!!feedbackButton) {
      feedbackButton.style.display = isZenMode ? 'none' : 'block';
    }
  }, [isZenMode]);

  const getRoute = (route: RouteItem, key: number): React.ReactElement => {
    switch (route.type) {
      case ERouteType.GUARDED:
        return <GuardedRoute exact path={route.path} component={route.component} key={key} />;
      case ERouteType.NOT_GUARDED:
        return <NotGuardedRoute exact path={route.path} component={route.component} key={key} />;
      default:
        return <Route exact path={route.path} component={route.component} key={key} />;
    }
  };

  if (checkIsIFrame() && !isModelPage) {
    dispatch(showEmbeddedModelPrompt({ promptContent: NO_MODEL }));
    return <EmbeddedModelPrompt />;
  }

  return (
    <>
      {isFullScreenLoaderActive && <FullScreenLoader />}
      {isEmbeddedModelPromptActive && <EmbeddedModelPrompt />}
      {isModalOpened && <ModalWindow />}
      {isConfirmModalOpened && <ConfirmModalWindow />}
      {!isBrandingLoading && !isZenMode && !isEmbeddedModelMode && <Header />}
      <RootContainer
        ref={pageTop}
        isPageWithScene={isPageWithScene}
        isAuth={isAuth}
        isArMode={isArMode}
        isViewerPage={isViewerPage}
        isZenMode={isZenMode}
        isEmbeddedModelMode={isEmbeddedModelMode}
        isScheduledMaintenance={isScheduledMaintenance}
      >
        {isAuth && !isBrandingLoading && !isArMode && !isZenMode && !isEmbeddedModelMode && (
          <Sidebar />
        )}
        {isLoaderActive && <PageLoader />}
        <Switch>
          {ROUTES.map((item, index): React.ReactElement => getRoute(item, index))}
          <Route path='*'>
            <Redirect to='/not-found' />
          </Route>
        </Switch>
      </RootContainer>
      {!isBrandingLoading && !isArMode && !isEmbeddedModelMode && <Footer />}
    </>
  );
};

export default MainRoutes;
