import * as THREE from 'three';
import { ECubeZones } from '../enums/ECubeZones';

// region object names
export const INTERNAL_OBJECT_WRAPPER_NAME = 'internalObjectWrapper';
// endregion

// region cube zones
const faceFr = ['212', '214', '216', '213', '215', '217', '222', '224', '226', '223', '225', '227', '232', '234', '236', '233', '235', '237'];
const faceBk = ['262', '264', '266', '263', '265', '267', '272', '274', '276', '273', '275', '277', '282', '284', '286', '283', '285', '287'];
const faceLf = ['62', '64', '66', '63', '65', '67', '72', '74', '76', '73', '75', '77', '82', '84', '86', '83', '85', '87'];
const faceRg = ['12', '14', '16', '13', '15', '17', '22', '24', '26', '23', '25', '27', '32', '34', '36', '33', '35', '37'];
const faceTp = ['112', '114', '116', '113', '115', '117', '122', '124', '126', '123', '125', '127', '132', '134', '136', '133', '135', '137'];
const faceBt = ['162', '164', '166', '163', '165', '167', '172', '174', '176', '173', '175', '177', '182', '184', '186', '183', '185', '187'];

const edgeFL = ['68', '210', '69', '211', '78', '220', '79', '221', '88', '230', '89', '231'];
const edgeFR = ['218', '10', '219', '11', '228', '20', '229', '21', '238', '30', '239', '31'];
const edgeFT = ['142', '144', '146', '143', '145', '147', '202', '204', '206', '203', '205', '207'];
const edgeFB = ['242', '244', '246', '243', '245', '247', '152', '154', '156', '153', '155', '157'];

const edgeBL = ['268', '60', '269', '61', '278', '70', '279', '71', '288', '80', '289', '81'];
const edgeBR = ['260', '18', '261', '19', '270', '28', '271', '29', '280', '38', '281', '39'];
const edgeBT = ['102', '104', '106', '103', '105', '107', '252', '254', '256', '253', '255', '257'];
const edgeBB = ['192', '194', '196', '193', '195', '197', '292', '294', '296', '293', '295', '297'];

const edgeLT = ['110', '111', '120', '121', '130', '131', '52', '54', '56', '53', '55', '57'];
const edgeLB = ['160', '161', '170', '171', '180', '181', '92', '94', '96', '93', '95', '97'];
const edgeRT = ['118', '119', '128', '129', '138', '139', '2', '4', '6', '3', '5', '7'];
const edgeRB = ['168', '169', '178', '179', '188', '189', '42', '44', '46', '43', '45', '47'];

const angleFLT = ['140', '141', '58', '200', '59', '201'];
const angleFRT = ['148', '149', '208', '0', '209', '1'];
const angleFLB = ['98', '240', '99', '241', '150', '151'];
const angleFRB = ['248', '40', '249', '41', '158', '159'];

const angleBLT = ['100', '101', '258', '50', '259', '51'];
const angleBRT = ['108', '109', '8', '9', '250', '251'];
const angleBLB = ['190', '191', '298', '90', '299', '91'];
const angleBRB = ['198', '199', '48', '49', '290', '291'];

export const CUBE_ZONES: Record<
  ECubeZones,
  { zoneName: ECubeZones; faces: string[]; camPos: THREE.Vector3 }
> = {
  [ECubeZones.faceFr]: {
    zoneName: ECubeZones.faceFr,
    faces: faceFr,
    camPos: new THREE.Vector3(0, 0, 1)
  },
  [ECubeZones.faceBk]: {
    zoneName: ECubeZones.faceBk,
    faces: faceBk,
    camPos: new THREE.Vector3(0, 0, -1)
  },
  [ECubeZones.faceLf]: {
    zoneName: ECubeZones.faceLf,
    faces: faceLf,
    camPos: new THREE.Vector3(-1, 0, 0)
  },
  [ECubeZones.faceRg]: {
    zoneName: ECubeZones.faceRg,
    faces: faceRg,
    camPos: new THREE.Vector3(1, 0, 0)
  },
  [ECubeZones.faceTp]: {
    zoneName: ECubeZones.faceTp,
    faces: faceTp,
    camPos: new THREE.Vector3(0, 1, 0)
  },
  [ECubeZones.faceBt]: {
    zoneName: ECubeZones.faceBt,
    faces: faceBt,
    camPos: new THREE.Vector3(0, -1, 0)
  },
  [ECubeZones.edgeFL]: {
    zoneName: ECubeZones.edgeFL,
    faces: edgeFL,
    camPos: new THREE.Vector3(-1, 0, 1)
  },
  [ECubeZones.edgeFR]: {
    zoneName: ECubeZones.edgeFR,
    faces: edgeFR,
    camPos: new THREE.Vector3(1, 0, 1)
  },
  [ECubeZones.edgeFT]: {
    zoneName: ECubeZones.edgeFT,
    faces: edgeFT,
    camPos: new THREE.Vector3(0, 1, 1)
  },
  [ECubeZones.edgeFB]: {
    zoneName: ECubeZones.edgeFB,
    faces: edgeFB,
    camPos: new THREE.Vector3(0, -1, 1)
  },
  [ECubeZones.edgeBL]: {
    zoneName: ECubeZones.edgeBL,
    faces: edgeBL,
    camPos: new THREE.Vector3(-1, 0, -1)
  },
  [ECubeZones.edgeBR]: {
    zoneName: ECubeZones.edgeBR,
    faces: edgeBR,
    camPos: new THREE.Vector3(1, 0, -1)
  },
  [ECubeZones.edgeBT]: {
    zoneName: ECubeZones.edgeBT,
    faces: edgeBT,
    camPos: new THREE.Vector3(0, 1, -1)
  },
  [ECubeZones.edgeBB]: {
    zoneName: ECubeZones.edgeBB,
    faces: edgeBB,
    camPos: new THREE.Vector3(0, -1, -1)
  },
  [ECubeZones.edgeLT]: {
    zoneName: ECubeZones.edgeLT,
    faces: edgeLT,
    camPos: new THREE.Vector3(-1, 1, 0)
  },
  [ECubeZones.edgeLB]: {
    zoneName: ECubeZones.edgeLB,
    faces: edgeLB,
    camPos: new THREE.Vector3(-1, -1, 0)
  },
  [ECubeZones.edgeRT]: {
    zoneName: ECubeZones.edgeRT,
    faces: edgeRT,
    camPos: new THREE.Vector3(1, 1, 0)
  },
  [ECubeZones.edgeRB]: {
    zoneName: ECubeZones.edgeRB,
    faces: edgeRB,
    camPos: new THREE.Vector3(1, -1, 0)
  },
  [ECubeZones.angleFLT]: {
    zoneName: ECubeZones.angleFLT,
    faces: angleFLT,
    camPos: new THREE.Vector3(-1, 1, 1)
  },
  [ECubeZones.angleFRT]: {
    zoneName: ECubeZones.angleFRT,
    faces: angleFRT,
    camPos: new THREE.Vector3(1, 1, 1)
  },
  [ECubeZones.angleFLB]: {
    zoneName: ECubeZones.angleFLB,
    faces: angleFLB,
    camPos: new THREE.Vector3(-1, -1, 1)
  },
  [ECubeZones.angleFRB]: {
    zoneName: ECubeZones.angleFRB,
    faces: angleFRB,
    camPos: new THREE.Vector3(1, -1, 1)
  },
  [ECubeZones.angleBLT]: {
    zoneName: ECubeZones.angleBLT,
    faces: angleBLT,
    camPos: new THREE.Vector3(-1, 1, -1)
  },
  [ECubeZones.angleBRT]: {
    zoneName: ECubeZones.angleBRT,
    faces: angleBRT,
    camPos: new THREE.Vector3(1, 1, -1)
  },
  [ECubeZones.angleBLB]: {
    zoneName: ECubeZones.angleBLB,
    faces: angleBLB,
    camPos: new THREE.Vector3(-1, -1, -1)
  },
  [ECubeZones.angleBRB]: {
    zoneName: ECubeZones.angleBRB,
    faces: angleBRB,
    camPos: new THREE.Vector3(1, -1, -1)
  }
};
// endregion
