import { useCallback, useState } from 'react';
import {
  deleteWhitelistDomain,
  getDomainsWhitelist,
  getEmbeddedModelCustomButtons,
  getEmbeddedModelSettings,
  postEmbeddedModelCustomButton,
  postEmbeddedModelSettings,
  postWhitelistDomain
} from 'services/api/embeddedSettingsService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { useAppDispatch } from 'shared/hooks';
import {
  EmbeddedCustomButton,
  EmbeddedModelSettings,
  ESnackbarStyle,
  WhitelistDomain
} from 'shared/types';
import { openNotification } from 'utils/notification-utils';

type Result = {
  fetchEmbeddedModelSettings: () => Promise<void>;
  fetchEmbeddedCustomButtons: () => Promise<void>;
  fetchDomainWhitelist: () => Promise<boolean | undefined>;
  setEmbeddedModelSettings: (settings: EmbeddedModelSettings) => Promise<void>;
  setEmbeddedCustomButton: (button: EmbeddedCustomButton) => Promise<void>;
  addWhitelistDomain: (domain: string) => Promise<void>;
  removeWhitelistDomain: (domainId: number) => Promise<void>;
  isSettingsLoading: boolean;
  embeddedSettings: EmbeddedModelSettings | null;
  savedCustomButtons: EmbeddedCustomButton[];
  domainsWhitelist: WhitelistDomain[];
};

const useEmbeddedSettingsData = (modelId: string): Result => {
  const dispatch = useAppDispatch();
  const [embeddedSettings, setEmbeddedSettings] = useState<EmbeddedModelSettings | null>(null);
  const [savedCustomButtons, setSavedCustomButtons] = useState<EmbeddedCustomButton[]>([]);
  const [isSettingsLoading, setIsSettingsLoading] = useState<boolean>(true);
  const [domainsWhitelist, setDomainsWhitelist] = useState<WhitelistDomain[]>([]);
  const EVERYONE_SYMBOL = '*';

  const fetchEmbeddedModelSettings = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      const settings = (await getEmbeddedModelSettings(modelId)).data;
      setEmbeddedSettings(settings);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
      setIsSettingsLoading(false);
    }
  }, [dispatch, modelId]);

  const setEmbeddedModelSettings = useCallback(
    async (settings: EmbeddedModelSettings): Promise<void> => {
      try {
        const data = (await postEmbeddedModelSettings(modelId, settings)).data;
        setEmbeddedSettings(data);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    },
    [modelId]
  );

  const fetchEmbeddedCustomButtons = useCallback(async (): Promise<void> => {
    try {
      const data = (await getEmbeddedModelCustomButtons(modelId)).data;
      setSavedCustomButtons(data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  }, [modelId]);

  const setEmbeddedCustomButton = useCallback(
    async (button: EmbeddedCustomButton): Promise<void> => {
      try {
        const data = (await postEmbeddedModelCustomButton(modelId, button)).data;
        setSavedCustomButtons((prev): EmbeddedCustomButton[] => [...prev, data]);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    },
    [modelId]
  );

  const fetchDomainWhitelist = useCallback(async (): Promise<boolean | undefined> => {
    dispatch(startLoader());
    try {
      const data = (await getDomainsWhitelist(modelId)).data;
      if (!data.length) {
        const domain = (await postWhitelistDomain(modelId, EVERYONE_SYMBOL)).data;
        setDomainsWhitelist([domain]);
        return true;
      }
      setDomainsWhitelist(data);
      return data.some((domain): boolean => domain.domainName === EVERYONE_SYMBOL);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch, modelId]);

  const addWhitelistDomain = useCallback(
    async (newDomainValue: string): Promise<void> => {
      dispatch(startLoader());
      try {
        await postWhitelistDomain(modelId, newDomainValue);
        await fetchDomainWhitelist();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, fetchDomainWhitelist, modelId]
  );

  const removeWhitelistDomain = useCallback(
    async (domainId: number): Promise<void> => {
      dispatch(startLoader());
      try {
        await deleteWhitelistDomain(modelId, domainId);
        await fetchDomainWhitelist();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, fetchDomainWhitelist, modelId]
  );

  return {
    fetchEmbeddedModelSettings,
    fetchEmbeddedCustomButtons,
    fetchDomainWhitelist,
    setEmbeddedModelSettings,
    setEmbeddedCustomButton,
    addWhitelistDomain,
    removeWhitelistDomain,
    isSettingsLoading,
    embeddedSettings,
    savedCustomButtons,
    domainsWhitelist
  };
};

export default useEmbeddedSettingsData;
