import React from 'react';

const BellRingingIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        viewBox='0 0 12 12'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.9091 9.71429V10.2857H1.09092V9.71429L2.18183 8.57143V5.14286C2.18183 3.37143 3.2891 1.81143 4.9091 1.30857V1.14286C4.9091 0.839753 5.02403 0.549062 5.22862 0.334735C5.4332 0.120408 5.71068 0 6.00001 0C6.28933 0 6.56681 0.120408 6.77139 0.334735C6.97598 0.549062 7.09091 0.839753 7.09091 1.14286V1.30857C8.71091 1.81143 9.81818 3.37143 9.81818 5.14286V8.57143L10.9091 9.71429ZM7.09091 10.8571C7.09091 11.1602 6.97598 11.4509 6.77139 11.6653C6.56681 11.8796 6.28933 12 6.00001 12C5.71068 12 5.4332 11.8796 5.22862 11.6653C5.02403 11.4509 4.9091 11.1602 4.9091 10.8571M10.2273 0.68L9.45273 1.49143C9.91406 1.96881 10.2803 2.53738 10.5303 3.16419C10.7803 3.79099 10.9091 4.46354 10.9091 5.14286H12C12 3.46857 11.3673 1.85714 10.2273 0.68ZM1.03615e-05 5.14286H1.09092C1.09092 3.77143 1.61455 2.45714 2.54728 1.49143L1.77274 0.68C1.20976 1.26375 0.763139 1.9589 0.458815 2.72503C0.154491 3.49117 -0.00146435 4.31303 1.03615e-05 5.14286Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default BellRingingIcon;
