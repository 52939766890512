import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  max-width: 600px;
  width: 100%;
  color: ${COLORS.white};
  background: ${COLORS.mineShaft};
  padding: 48px 33px 25px;
  border-radius: 7px;
  position: relative;

  @media ${DEVICE.tablet} {
    padding: 30px 20px;
  }
`;

export const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const FormSubtitle = styled.span`
  margin-top: 20px;
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
`;

export const NotificationContainer = styled.div`
  display: flex;
  padding: 24px 14px;
  background: rgba(255, 228, 6, 0.1);
  margin-top: 40px;
`;

export const NotificationIcon = styled.div`
  height: 100%;

  img {
    width: 40px;
    min-width: 30px;
  }
`;

export const Notification = styled.div`
  padding-left: 14px;
  color: ${COLORS.white};
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  @media ${DEVICE.mobile} {
    justify-content: center;
  }
`;

export const FormButton = styled.button`
  width: 140px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border-radius: 2px;

  &.undo-button {
    background: ${COLORS.codGray};
    color: ${COLORS.white};
  }

  &.cancel-button {
    background: ${COLORS.turbo};
    color: ${COLORS.codGray};
    margin-left: 8px;
  }

  &:hover {
    color: ${COLORS.codGray};
    background: ${COLORS.gorse};
  }

  &:disabled {
    background: ${COLORS.doveGray};
  }
`;

export const CloseButton = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
