import React from 'react';

const UsageIcon: React.FC = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 18 18'
        fill='currentColor'
        preserveAspectRatio='xMaxYMax meet'
      >
        <path
          d='M9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9.00005C0 6.68338 0.762334 4.67905 2.287 2.98705C3.81233 1.29572 5.71667 0.316716 8 0.0500488V1.27505C6.05 1.54172 4.43333 2.40405 3.15 3.86205C1.86667 5.32072 1.225 7.03338 1.225 9.00005C1.225 11.1667 1.97933 13.004 3.488 14.512C4.996 16.0207 6.83333 16.775 9 16.775C10.2167 16.775 11.371 16.5124 12.463 15.987C13.5543 15.4624 14.4667 14.7167 15.2 13.75L16.25 14.4C15.3833 15.55 14.3167 16.4374 13.05 17.062C11.7833 17.6874 10.4333 18 9 18ZM17.275 12.55L16.2 11.925C16.4167 11.4584 16.5667 10.9834 16.65 10.5C16.7333 10.0167 16.775 9.51672 16.775 9.00005C16.775 7.03338 16.1333 5.31672 14.85 3.85005C13.5667 2.38338 11.95 1.52505 10 1.27505V0.0500488C12.3 0.266715 14.2083 1.23338 15.725 2.95005C17.2417 4.66672 18 6.68338 18 9.00005C18 9.61672 17.9457 10.225 17.837 10.825C17.729 11.425 17.5417 12 17.275 12.55Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default UsageIcon;
