import React from 'react';
import { NavLink } from 'react-router-dom';
import FastClick from 'fastclick';
import { useAppDispatch, useAppSelector, useBrowserStore } from 'shared/hooks';
import * as Styled from './styles';
import { closeSidebar } from 'services/store/reducers/sidebarReducer';
import { SidebarItem } from 'shared/types';
import { ModalLogout, ProjectLogo } from 'shared/components';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { convertStringToButtonId } from 'utils/form-utils';
import { RootState } from 'services/store';
import {
  AdminSettingsIcon,
  CreateTeamIcon,
  HistoryIcon,
  HomeIcon,
  LogoutIcon,
  MyModelsIcon,
  SettingsIcon,
  TeamIcon,
  SharedWithMeIcon
} from 'assets/dynamic-icons';
import { clearViewerDataState } from 'services/store/reducers/viewerDataReducer';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { CustomTooltip } from 'shared/components';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';

const Sidebar = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { isSidebarOpened, isPageWithScene } = store.sidebar;
  const { isMasterAdmin } = store.auth;
  const { teams } = store.teams;
  const { removeFilesFromBrowserStore } = useBrowserStore();

  const closeSidebarMenu = (): void => {
    dispatch(closeSidebar());
  };

  const logOut = (): void => {
    dispatch(showConfirmModal({ header: null, content: <ModalLogout /> }));
  };

  const getSidebarItem = ({ title, icon: Icon, link, isTeam }: SidebarItem): React.ReactElement => {
    const maxWidth = window.innerWidth <= DEVICE_SIZES.tabletLarge ? 216 : 160;

    const sidebarItem: React.ReactElement = (
      <CustomTooltip content={title} maxWidth={maxWidth}>
        <Styled.SidebarItemContainer id={!isTeam ? convertStringToButtonId(title) : undefined}>
          <Styled.SidebarItemIcon>
            <Icon />
          </Styled.SidebarItemIcon>
          <Styled.SidebarItemTitle isPageWithScene={isPageWithScene}>
            {title}
          </Styled.SidebarItemTitle>
        </Styled.SidebarItemContainer>
      </CustomTooltip>
    );

    return !!link ? (
      <NavLink isActive={(match): boolean => !!match?.isExact} activeClassName='active' to={link}>
        {sidebarItem}
      </NavLink>
    ) : (
      sidebarItem
    );
  };

  const TEAMS_ITEMS = teams.map(
    ({ id, teamName }): SidebarItem => ({
      title: teamName,
      link: `/workspace/${id}`,
      icon: TeamIcon,
      clickAction: closeSidebarMenu,
      isTeam: true
    })
  );

  const DEFAULT_SIDEBAR_ITEMS: SidebarItem[][] = [
    [
      {
        title: 'Home',
        link: '/',
        icon: HomeIcon,
        clickAction: (): void => {
          dispatch(clearViewerDataState());
          removeFilesFromBrowserStore();
          clearModelLocalStorage();
          closeSidebarMenu();
        }
      }
    ],
    [
      {
        title: 'My workspace',
        link: '/my-workspace',
        icon: MyModelsIcon,
        clickAction: closeSidebarMenu
      },
      ...TEAMS_ITEMS,
      {
        title: 'Create Team Space',
        link: '/create-team',
        icon: CreateTeamIcon,
        clickAction: closeSidebarMenu
      }
    ],
    [
      {
        title: 'Recents',
        link: '/recents',
        icon: HistoryIcon,
        clickAction: closeSidebarMenu
      },
      {
        title: 'Shared with me',
        link: '/shared-with-me',
        icon: SharedWithMeIcon,
        clickAction: closeSidebarMenu
      }
    ],
    [
      {
        title: 'Account settings',
        link: '/account-settings',
        icon: SettingsIcon,
        clickAction: closeSidebarMenu
      }
    ],
    [
      {
        title: 'Log Out',
        icon: LogoutIcon,
        clickAction: logOut
      }
    ]
  ];

  const adminPanelItem: SidebarItem[] = [
    {
      title: 'Admin Panel',
      link: '/admin-panel',
      icon: AdminSettingsIcon,
      clickAction: closeSidebarMenu
    }
  ];

  const sidebarItems = isMasterAdmin
    ? [
        ...DEFAULT_SIDEBAR_ITEMS.slice(0, -1),
        adminPanelItem,
        DEFAULT_SIDEBAR_ITEMS[DEFAULT_SIDEBAR_ITEMS.length - 1]
      ]
    : DEFAULT_SIDEBAR_ITEMS;

  return (
    <>
      <Styled.SidebarContainer isSidebarOpened={isSidebarOpened} isPageWithScene={isPageWithScene}>
        <Styled.SidebarContent
          ref={(element): void => {
            if (element) {
              // @ts-ignore
              FastClick.attach(element);
            }
          }}
        >
          <Styled.ProjectLogoContainer isSidebarOpened={isSidebarOpened}>
            <ProjectLogo />
          </Styled.ProjectLogoContainer>
          <Styled.SidebarItems>
            {sidebarItems.map(
              (item, index): React.ReactElement => (
                <Styled.SidebarMenuBlock key={Date.now() * Math.random()}>
                  {item.map(
                    (sidebarItem): React.ReactElement => (
                      <Styled.SidebarItem key={Date.now() * Math.random()} onClick={sidebarItem.clickAction}>
                        {getSidebarItem(sidebarItem)}
                      </Styled.SidebarItem>
                    )
                  )}
                </Styled.SidebarMenuBlock>
              )
            )}
          </Styled.SidebarItems>
        </Styled.SidebarContent>
      </Styled.SidebarContainer>
      <Styled.Overlay isSidebarOpened={isSidebarOpened} onClick={closeSidebarMenu} />
    </>
  );
};

export default Sidebar;
