import React, { useCallback, useLayoutEffect, useState } from 'react';
import * as Styled from './styles';
import { withElements } from 'shared/hocs/withElements';
import { PaymentElement, AddressElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Stripe from 'assets/images/stripe-label-white.svg';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { AuthState, ESnackbarStyle, SubscriptionProduct, SubscriptionsState } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { getCardPaymentIntent, setupUserPaymentMethod } from 'services/api/subscriptionsService';
import { setCurrentUserCards } from 'services/store/reducers/subscriptionsReducer';
import { getLocationStateFromSessionStorage } from 'utils/storage-utils';
import { checkIsStripeTestCardError } from 'utils/error-utils';

type Props = {
  isCheckout?: boolean;
  plan?: SubscriptionProduct;
  isMonthlyType?: boolean;
};

const CardForm: React.FC<Props> = ({ isCheckout, plan, isMonthlyType }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { currentUserCard } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const { user } = useAppSelector((store): AuthState => store.auth);
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');

  const getClientSecret = useCallback(async (): Promise<void> => {
    try {
      const { client_secret } = (await getCardPaymentIntent()).data;
      setClientSecret(client_secret);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  }, []);

  useLayoutEffect((): void => {
    getClientSecret();
  }, [getClientSecret]);

  const handleSaveClick = async (): Promise<void> => {
    if (!!stripe && !!elements) {
      try {
        dispatch(startLoader());
        const { error: submitError } = await elements.submit();
        if (!!submitError) throw new Error(checkIsStripeTestCardError(submitError));
        const { current } = getLocationStateFromSessionStorage();

        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${process.env.REACT_APP_URL}${current || ''}${
              isCheckout ? `?checkout=1&plan=${plan?.id}&monthly=${isMonthlyType ? 1 : 0}` : ''
            }`
          },
          redirect: 'if_required'
        });
        if (!!error) throw new Error(checkIsStripeTestCardError(error));

        const newUserCard = (await setupUserPaymentMethod(setupIntent?.id)).data;
        dispatch(setCurrentUserCards([newUserCard]));
        elements.getElement('payment')?.clear();
        elements.getElement(AddressElement)?.clear();
        openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully added');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        await getClientSecret();
        dispatch(stopLoader());
      }
    }
  };

  return (
    <Styled.CardFormContainer>
      <Styled.FormTitle>
        {!!currentUserCard ? 'Change current card' : 'Add new card'}
      </Styled.FormTitle>
      <Styled.CardContainer>
        <PaymentElement
          options={{ layout: 'tabs', wallets: { applePay: 'auto', googlePay: 'auto' } }}
        />
        {!!user?.vatRegistered && (
          <AddressElement
            options={{
              mode: 'billing',
              defaultValues: { name: `${user?.firstName} ${user?.lastName}` }
            }}
          />
        )}
      </Styled.CardContainer>
      <Styled.CardFormFooter>
        <Styled.ActionButton onClick={handleSaveClick} id='save-card-button'>
          Save
        </Styled.ActionButton>
        <Styled.StripeLabel src={Stripe} />
      </Styled.CardFormFooter>
    </Styled.CardFormContainer>
  );
};

export default withElements(CardForm);
