import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
  width: 90vw;
  max-height: 90vh;
  color: ${COLORS.white};
  position: relative;
  padding: 40px 0 0 40px;
  border-radius: 7px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media ${DEVICE.mobile} {
    padding: 20px;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  color: ${COLORS.white};
  margin-bottom: 0;

  @media${DEVICE.tablet} {
    font-size: 30px;
    line-height: 32px;
  }

  @media ${DEVICE.mobile} {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const LimitsList = styled.ul`
  padding: 0 40px 0 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.white};
  margin: 30px 0 0;

  & > li:not(:first-child) {
    margin-top: 6px;
  }
`;

export const ModalFooter = styled.div`
  padding-right: 20px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`;

export const LogoCube = styled.img`
  width: 120px;
  height: 120px;
  transform: rotate(90deg);
`;
