import {useCallback} from 'react';

type Result = {
  setFilesToBrowserStore: (files: File[]) => void;
  getFilesFromBrowserStore: (action: (files: File[] | null) => void) => void;
  removeFilesFromBrowserStore: () => void;
};

const useBrowserStore = (): Result => {
  const dbError = (): void => console.error('Unable to open database.');
  const error = (): void => console.error('Error storing data');

  const openBrowserStore = useCallback(
    (storeAction: (transaction: IDBTransaction) => void): void => {
      const openRequest = indexedDB.open('store', 1);
      openRequest.onsuccess = (e): void => {
        const database = (e.target as IDBOpenDBRequest).result;
        const transaction = database.transaction(['model'], 'readwrite');
        storeAction(transaction);
      };
      openRequest.onupgradeneeded = (e): void => {
        const database = (e.target as IDBOpenDBRequest).result;
        database.createObjectStore('model', { keyPath: 'id', autoIncrement: true });
      };
      openRequest.onerror = dbError;
    },
    []
  );

  const setFilesToBrowserStore = useCallback(
    (files: File[]): void => {
      const storeAction = (transaction: IDBTransaction): void => {
        const request = transaction.objectStore('model').clear();
        request.onsuccess = (): void => {
          const addRequest = transaction.objectStore('model').add(files);
          addRequest.onerror = error;
        };
      };

      openBrowserStore(storeAction);
    },
    [openBrowserStore]
  );

  const getFilesFromBrowserStore = useCallback(
    (action: (files: File[] | null) => void): void => {
      const storeAction = (transaction: IDBTransaction): void => {
        const request = transaction.objectStore('model').getAll();
        request.onsuccess = (e): void => {
          const files: any = (e.target as IDBOpenDBRequest).result;
          action(files[0] || null);
        };
      };

      openBrowserStore(storeAction);
    },
    [openBrowserStore]
  );

  const removeFilesFromBrowserStore = useCallback((): void => {
    const storeAction = (transaction: IDBTransaction): void => {
      transaction.objectStore('model').clear();
    };

    openBrowserStore(storeAction);
  }, [openBrowserStore]);

  return {
    setFilesToBrowserStore,
    getFilesFromBrowserStore,
    removeFilesFromBrowserStore
  };
};

export default useBrowserStore;
