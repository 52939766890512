import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { HexColorPicker } from 'react-colorful';
import * as Styled from './styles';
import { Select } from '../select';
import { ModalLogoFileLimits } from 'shared/components';
import {
  useAppDispatch,
  useAppSelector,
  useCustomizationData,
  useHandleClickOutside
} from 'shared/hooks';
import EditIcon from 'assets/images/edit-theme-name.svg';
import TrashIcon from 'assets/images/remove.svg';
import UploadFileIcon from 'assets/images/upload-file.svg';
import HintIcon from 'assets/images/hint.svg';
import CloseIcon from 'assets/images/close.svg';
import ErrorIcon from 'assets/images/error-icon.svg';
import { CustomTheme, CustomThemeUploadData, ESnackbarStyle } from 'shared/types';
import { getFieldError } from 'utils/form-utils';
import { showModal } from 'services/store/reducers/modalReducer';
import { validation } from 'services/validation';
import {
  setButtonsColor,
  setButtonTextColor,
  setHeaderColor,
  setLogoPath,
  setSiteSubtitle,
  setSiteTitle,
  setTextColor,
  setThemeSettings
} from 'services/store/reducers/customizationReducer';
import { MAX_LOGO_FILE_SIZE } from 'shared/constants/limits';
import { COLORS } from 'shared/constants/colors';
import { COLOR_HEX_REGEXP, NUMBER_AND_LETTERS_REGEXP } from 'shared/constants/regexps';
import { openNotification } from 'utils/notification-utils';
import {
  CREATE_NEW_ID,
  DEFAULT_PICKER_COLORS,
  DEFAULT_THEME_SETTINGS
} from 'shared/constants/customization';
import {
  deleteCustomTheme,
  deleteCustomThemeLogo,
  updateCustomTheme
} from 'services/api/customizationService';
import { RootState } from 'services/store';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { convertDataToFormData } from 'utils/convert-file-utils';
import { CustomTooltip } from 'shared/components';

type ThemeSelectOption = {
  title: string;
  value: CustomTheme;
};

type InitialValues = {
  brandName: string;
  siteTitle: string;
  siteSubtitle: string;
};

const ThemeSettings = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const MAX_NAME_LENGTH = 32;
  const store = useAppSelector((store): RootState => store);
  const { brandColors, brandLogoUrl } = store.customization;
  const { headerTextColor, normalTextColor, buttonBackgroundColor, buttonTextColor } = brandColors;
  const { fetchCustomThemes, customThemes, uploadCustomTheme } = useCustomizationData();
  const [isEditNameInputActive, setIsEditNameInputActive] = useState<boolean>(false);
  const [activePaletteColor, setActivePaletteColor] = useState<string | null>(null);
  const [uploadedLogoFile, setUploadedLogoFile] = useState<File | null>(null);
  const [activeTheme, setActiveTheme] = useState<CustomTheme>(DEFAULT_THEME_SETTINGS);
  const THEMES = useMemo(
    (): CustomTheme[] => [DEFAULT_THEME_SETTINGS, ...customThemes],
    [customThemes]
  );

  useEffect((): void => {
    fetchCustomThemes();
  }, [fetchCustomThemes]);

  const initialValues: InitialValues = {
    brandName: DEFAULT_THEME_SETTINGS.brandName,
    siteTitle: DEFAULT_THEME_SETTINGS.siteTitle,
    siteSubtitle: DEFAULT_THEME_SETTINGS.siteSubtitle
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ brandName, siteTitle, siteSubtitle }): Promise<void> => {
      dispatch(startLoader());
      try {
        const isEveryColorValid = Object.values(brandColors).every((hex): boolean =>
          COLOR_HEX_REGEXP.test(hex)
        );
        if (!isEveryColorValid) {
          openNotification(ESnackbarStyle.ERROR, 'Invalid HEX color code!');
          return;
        }
        const colors = JSON.stringify({
          buttonBackgroundColor,
          normalTextColor,
          headerTextColor,
          buttonTextColor
        });
        const defaultData = { brandName, siteTitle, siteSubtitle, colors };
        const data: CustomThemeUploadData = !!uploadedLogoFile
          ? { ...defaultData, logo: uploadedLogoFile }
          : defaultData;
        const formData = convertDataToFormData(data);
        const newTheme =
          activeTheme.id === CREATE_NEW_ID
            ? await uploadCustomTheme(formData)
            : (await updateCustomTheme(activeTheme.id, formData)).data;
        if (!newTheme) return;
        await fetchCustomThemes();
        setActiveTheme(newTheme);
        setIsEditNameInputActive(false);
        openNotification(
          ESnackbarStyle.SUCCESS,
          activeTheme.id === CREATE_NEW_ID
            ? 'Your theme has been successfully uploaded'
            : 'Your theme has been successfully updated'
        );
        setActivePaletteColor(null);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.THEME_CUSTOMIZATION
  });

  const { setValues, handleSubmit, values, handleBlur, handleChange } = formik;

  useEffect((): void => {
    const { brandName, siteTitle, siteSubtitle, brandLogoUrl, brandColors } = activeTheme;
    setValues({ brandName, siteTitle, siteSubtitle });
    dispatch(setThemeSettings({ siteTitle, siteSubtitle, brandLogoUrl, brandColors }));
    setUploadedLogoFile(null);
  }, [activeTheme, dispatch, setValues]);

  const handleThemeSelectChange = ({ value }: ThemeSelectOption): void => {
    setActiveTheme(value);
    if (value.id === CREATE_NEW_ID) {
      setIsEditNameInputActive(true);
    }
  };

  const { brandName, siteSubtitle, siteTitle } = values;

  useEffect((): void => {
    dispatch(setSiteTitle(siteTitle));
  }, [dispatch, siteTitle]);

  useEffect((): void => {
    dispatch(setSiteSubtitle(siteSubtitle));
  }, [dispatch, siteSubtitle]);

  const handleEditThemeNameClick = (): void => {
    setIsEditNameInputActive(true);
  };

  const handleCloseEditModeClick = (): void => {
    setIsEditNameInputActive(false);
  };

  const handleESCKeyOnThemeNameInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Escape') {
      setIsEditNameInputActive(false);
    }
  };

  const handleCompanyLogoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files![0];
    const logoBlobUrl = URL.createObjectURL(file);
    event.target.value = '';
    if (file.size > MAX_LOGO_FILE_SIZE) {
      openNotification(
        ESnackbarStyle.HOLD_UP,
        'Logo size exceeds the allowed limit. 0.25MB maximum file size'
      );
      return;
    }
    dispatch(setLogoPath(logoBlobUrl));
    setUploadedLogoFile(file);
  };

  const handleRemoveLogoClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    dispatch(startLoader());
    try {
      if (activeTheme.id !== CREATE_NEW_ID && !!activeTheme.brandLogoUrl) {
        await deleteCustomThemeLogo(activeTheme.id);
        await fetchCustomThemes();
        openNotification(ESnackbarStyle.SUCCESS, 'Theme logo has been successfully removed');
      }
      setUploadedLogoFile(null);
      dispatch(setLogoPath(DEFAULT_THEME_SETTINGS.brandLogoUrl));
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleRemoveThemeClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteCustomTheme(activeTheme.id);
      await fetchCustomThemes();
      setUploadedLogoFile(null);
      setActiveTheme(DEFAULT_THEME_SETTINGS);
      openNotification(ESnackbarStyle.SUCCESS, 'Your theme has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleThemeOptionRemove = async (themeId: number): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteCustomTheme(themeId);
      await fetchCustomThemes();
      openNotification(ESnackbarStyle.SUCCESS, 'Your theme has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleLogoFileLimitsClick = (): void => {
    dispatch(showModal(<ModalLogoFileLimits />));
  };

  const setColor = (fieldName: string, hex: string): void => {
    const { headerTextColor, normalTextColor, buttonBackgroundColor, buttonTextColor } =
      DEFAULT_THEME_SETTINGS.brandColors;
    switch (fieldName) {
      case 'buttons-color':
        dispatch(setButtonsColor(hex || buttonBackgroundColor));
        break;
      case 'header-color':
        dispatch(setHeaderColor(hex || headerTextColor));
        break;
      case 'text-color':
        dispatch(setTextColor(hex || normalTextColor));
        break;
      case 'button-text-color':
        dispatch(setButtonTextColor(hex || buttonTextColor));
    }
  };

  const handleColorHexInputChange =
    (fieldName: string): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
    (event): void => {
      const inputValue = event.target.value.trim();
      const valueWithoutHash = inputValue[0] === '#' ? inputValue.slice(1) : inputValue;
      const filteredValue = valueWithoutHash
        .split('')
        .filter((symbol): boolean => NUMBER_AND_LETTERS_REGEXP.test(symbol))
        .join('');
      const hex = `#${filteredValue}`;
      setColor(fieldName, hex);
    };

  const handleColorHexInputBlur =
    (fieldName: string): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
    (event): void => {
      const { value } = event.target;
      if (!COLOR_HEX_REGEXP.test(value.trim())) {
        openNotification(ESnackbarStyle.HOLD_UP, 'Invalid HEX color code!');
        setColor(fieldName, '');
      }
    };

  const handleDefaultColorClick =
    (fieldName: string, hex: string): (() => void) =>
    (): void => {
      setColor(fieldName, hex);
    };

  const handleColorPickerChange =
    (fieldName: string): ((hex: string) => void) =>
    (hex): void => {
      setColor(fieldName, hex);
    };

  const handlePaletteColorClick =
    (fieldName: string): (() => void) =>
    (): void => {
      setTimeout((): void => setActivePaletteColor(fieldName), 0);
    };

  const PALETTE_COLORS = [
    { label: 'Header', color: headerTextColor, fieldName: 'header-color' },
    { label: 'Text', color: normalTextColor, fieldName: 'text-color' },
    { label: 'Buttons', color: buttonBackgroundColor, fieldName: 'buttons-color' },
    { label: 'Button Text', color: buttonTextColor, fieldName: 'button-text-color' }
  ];

  const handleClickOutsideColorPicker = (): void => {
    setActivePaletteColor(null);
  };

  const colorPickerRef = useHandleClickOutside(handleClickOutsideColorPicker);

  const THEME_SELECT_OPTIONS = useMemo(
    (): ThemeSelectOption[] =>
      THEMES.map(
        (theme): ThemeSelectOption => ({
          title: theme.id === CREATE_NEW_ID ? 'Create new theme' : theme.brandName,
          value: theme
        })
      ),
    [THEMES]
  );

  const LOGO_FILE_NAME = useMemo((): string => {
    if (!!uploadedLogoFile) {
      return uploadedLogoFile.name.split('.')[0];
    }
    if (!!brandLogoUrl) {
      return brandLogoUrl.split('/').reverse()[0].split('.')[0];
    }
    return 'Your company logo';
  }, [brandLogoUrl, uploadedLogoFile]);

  return (
    <Styled.SettingsSection>
      <Styled.SettingsSectionTitleContainer>
        <Styled.SettingsSectionTitle>Custom Branding</Styled.SettingsSectionTitle>
        <CustomTooltip content='Customize the colors and logo of the interface surrounding your models'>
          <Styled.SettingsSectionTitleHint src={HintIcon} alt='Hint Icon' />
        </CustomTooltip>
      </Styled.SettingsSectionTitleContainer>
      <Styled.ThemeSettingsForm onSubmit={handleSubmit}>
        <Styled.SettingsField>
          <Styled.FieldLabel>Name</Styled.FieldLabel>
          <Styled.FieldInputContainer>
            {isEditNameInputActive ? (
              <Styled.FieldInput
                name={'brandName'}
                value={brandName}
                onChange={handleChange}
                onKeyDown={handleESCKeyOnThemeNameInput}
                onBlur={handleBlur}
                autoFocus={true}
                maxLength={MAX_NAME_LENGTH}
                placeholder='Your theme name'
              />
            ) : (
              <Select
                activeOption={{ title: brandName, value: activeTheme }}
                options={THEME_SELECT_OPTIONS}
                onChangeOption={handleThemeSelectChange}
                onRemoveOption={handleThemeOptionRemove}
                placeholder={'Your theme name'}
              />
            )}
            {isEditNameInputActive ? (
              <Styled.CloseButton type='button' onClick={handleCloseEditModeClick}>
                <img src={CloseIcon} alt='Close edit mode' />
              </Styled.CloseButton>
            ) : (
              <>
                <Styled.EditButton
                  type='button'
                  isNewThemeField={activeTheme.id === CREATE_NEW_ID}
                  onClick={handleEditThemeNameClick}
                >
                  <img src={EditIcon} alt='Edit theme name' />
                </Styled.EditButton>
                {activeTheme.id !== CREATE_NEW_ID && (
                  <Styled.RemoveButton
                    type='button'
                    onClick={handleRemoveThemeClick}
                    isInputActive={isEditNameInputActive}
                  >
                    <img src={TrashIcon} alt='Remove theme' />
                  </Styled.RemoveButton>
                )}
              </>
            )}
            {getFieldError(formik, 'brandName')}
          </Styled.FieldInputContainer>
        </Styled.SettingsField>

        <Styled.SettingsField>
          <Styled.FieldLabel>Site title</Styled.FieldLabel>
          <Styled.FieldInputContainer>
            <Styled.FieldInput
              name={'siteTitle'}
              value={siteTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={MAX_NAME_LENGTH}
              placeholder='Your site title'
            />
            {getFieldError(formik, 'siteTitle')}
          </Styled.FieldInputContainer>
        </Styled.SettingsField>
        <Styled.SettingsField>
          <Styled.FieldLabel>Subtitle</Styled.FieldLabel>
          <Styled.FieldInputContainer>
            <Styled.FieldInput
              className='select-input'
              name={'siteSubtitle'}
              value={siteSubtitle}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={MAX_NAME_LENGTH}
              placeholder='Your subtitle'
            />
            {getFieldError(formik, 'siteSubtitle')}
          </Styled.FieldInputContainer>
        </Styled.SettingsField>
        <Styled.UploadLogoFieldContainer htmlFor='upload-logo-button'>
          <Styled.UploadLogoButtonContainer>
            <Styled.UploadLogoButton>
              <input
                type='file'
                accept='image/*'
                id='upload-logo-button'
                onChange={handleCompanyLogoChange}
              />
              <img src={UploadFileIcon} alt='Upload logo button' />
            </Styled.UploadLogoButton>
          </Styled.UploadLogoButtonContainer>
          <Styled.FieldInputContainer className='upload-logo-field'>
            {(!!brandLogoUrl || !!uploadedLogoFile) && (
              <Styled.RemoveButton
                type='button'
                className='remove-logo'
                onClick={handleRemoveLogoClick}
              >
                <img src={TrashIcon} alt='Remove' />
              </Styled.RemoveButton>
            )}
            <Styled.FileNameField>
              <span>{LOGO_FILE_NAME}</span>
            </Styled.FileNameField>
          </Styled.FieldInputContainer>
        </Styled.UploadLogoFieldContainer>
        <Styled.SettingsLimits onClick={handleLogoFileLimitsClick}>
          Logo File Limits
        </Styled.SettingsLimits>
        <Styled.ColorPaletteField>
          <Styled.PaletteFieldLabel>Colors</Styled.PaletteFieldLabel>
          <Styled.ColorSettingsList>
            {PALETTE_COLORS.map(
              ({ label, color, fieldName }): JSX.Element => (
                <Styled.CustomColorContainer key={fieldName}>
                  <Styled.CustomColorWrapper
                    style={{
                      border: `1px solid ${
                        activePaletteColor === fieldName ? color : 'transparent'
                      }`
                    }}
                    onClick={handlePaletteColorClick(fieldName)}
                  >
                    <Styled.CustomColor
                      style={{
                        background: `${color}`,
                        border: `1px solid ${
                          color === COLORS.mineShaft ? COLORS.doveGray : 'transparent'
                        }`
                      }}
                    />
                  </Styled.CustomColorWrapper>
                  <Styled.CustomColorTitle>{label}</Styled.CustomColorTitle>
                  {activePaletteColor === fieldName && (
                    <Styled.ColorPickerContainer className={fieldName} ref={colorPickerRef}>
                      <HexColorPicker color={color} onChange={handleColorPickerChange(fieldName)} />
                      <Styled.ResultColorField>
                        <Styled.ResultColorContainer>
                          <Styled.ResultColor style={{ background: `${color}` }} />
                          <Styled.ColorHexInputContainer
                            isHexCodeError={!COLOR_HEX_REGEXP.test(color)}
                          >
                            <Styled.ColorHexInput
                              value={color}
                              onChange={handleColorHexInputChange(fieldName)}
                              onBlur={handleColorHexInputBlur(fieldName)}
                            />
                            <div className='bottom-line' />
                          </Styled.ColorHexInputContainer>
                        </Styled.ResultColorContainer>
                        {!COLOR_HEX_REGEXP.test(color) && (
                          <CustomTooltip content='Invalid HEX color code!'>
                            <Styled.HexError src={ErrorIcon} />
                          </CustomTooltip>
                        )}
                      </Styled.ResultColorField>
                      <Styled.DefaultColorsContainer>
                        <Styled.DefaultColorsLabel>Default Colors</Styled.DefaultColorsLabel>
                        <Styled.DefaultColorsList>
                          {DEFAULT_PICKER_COLORS.map(
                            (hex, index): JSX.Element => (
                              <Styled.DefaultColor
                                style={{ background: `${hex}` }}
                                onClick={handleDefaultColorClick(fieldName, hex)}
                                key={index}
                              />
                            )
                          )}
                        </Styled.DefaultColorsList>
                      </Styled.DefaultColorsContainer>
                    </Styled.ColorPickerContainer>
                  )}
                </Styled.CustomColorContainer>
              )
            )}
          </Styled.ColorSettingsList>
        </Styled.ColorPaletteField>
        <Styled.SaveButton type='submit'>Save</Styled.SaveButton>
      </Styled.ThemeSettingsForm>
    </Styled.SettingsSection>
  );
};

export default ThemeSettings;
