import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import { CreateScreenshotData, CSInitializationData } from 'shared/interfaces';

export class SceneDefaultCiStrategy extends BaseSsStrategy {
  constructor(private initializationData: CSInitializationData) {
    super(initializationData);
  }

  public async createScreenshot({ cubemapController }: CreateScreenshotData): Promise<string> {
    const { screenshotRenderer, screenshotCamera, scene } = this.mainScene;

    this.setupRenderer();
    this.setupCamera();
    const restoreCubemap = this.cheatCubemap(cubemapController);

    screenshotRenderer.render(scene, screenshotCamera);

    restoreCubemap();

    return screenshotRenderer.domElement.toDataURL('image/jpeg');
  }
}
