import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import {
  CreateScreenshotData,
  CSPrevElementsStateData,
  CSInitializationData
} from 'shared/interfaces';
import html2canvas from 'html2canvas';
import { COMMENT_TOGGLE_SIZE } from 'shared/constants/comments-defaults';

export class SceneWithCommentSsStrategy extends BaseSsStrategy {
  private prevElementsState: CSPrevElementsStateData | undefined;
  private labelRendererParent: HTMLElement | undefined;
  private temporaryEditorClone: Node | undefined;
  private container: HTMLDivElement = document.createElement('div');

  constructor(private initializationData: CSInitializationData) {
    super(initializationData);
  }

  private prepareLabelRenderer(editor: HTMLElement): void {
    const { screenshotCamera, scene, css2DRenderer } = this.mainScene;
    const { width, height } = css2DRenderer.getSize();
    const [toggle, pointer, bubble] = editor.childNodes[0].childNodes! as unknown as HTMLElement[];

    this.prevElementsState = {
      toggleHidden: toggle.hidden,
      pointerHidden: pointer.hidden,
      bubbleHidden: bubble.hidden,
      toggleWidth: toggle.clientWidth,
      toggleHeight: toggle.clientHeight,
      labelRendererWidth: width,
      labelRendererHeight: height
    };
    this.labelRendererParent = css2DRenderer.domElement.parentElement!;
    this.temporaryEditorClone = css2DRenderer.domElement.cloneNode(true);

    this.labelRendererParent.appendChild(this.temporaryEditorClone);
    this.container.style.position = 'relative';
    document.body.appendChild(this.container);
    this.container.appendChild(css2DRenderer.domElement);

    css2DRenderer.setSize(this.coverImageWidth, this.coverImageHeight);
    css2DRenderer.render(scene, screenshotCamera);
    css2DRenderer.domElement.childNodes.forEach((node): void => {
      (node as HTMLDivElement).hidden = node !== editor;
    });

    toggle.hidden = false;
    toggle.style.transform = 'translate(0, 0)';
    toggle.style.width = COMMENT_TOGGLE_SIZE;
    toggle.style.height = COMMENT_TOGGLE_SIZE;
    pointer.hidden = true;
    bubble.hidden = true;
  }

  private restoreLabelRendererSettings(editor: HTMLElement): void {
    if (!this.prevElementsState || !this.labelRendererParent) return;

    const { css2DRenderer } = this.mainScene;

    css2DRenderer.domElement.childNodes.forEach((node): void => {
      (node as HTMLDivElement).hidden = false;
    });

    const {
      toggleHidden,
      pointerHidden,
      bubbleHidden,
      toggleWidth,
      toggleHeight,
      labelRendererWidth,
      labelRendererHeight
    } = this.prevElementsState;
    const [toggle, pointer, bubble] = editor.childNodes[0].childNodes! as unknown as HTMLElement[];

    toggle.hidden = toggleHidden;
    toggle.style.transform = 'unset';
    toggle.style.width = toggleWidth.toString();
    toggle.style.height = toggleHeight.toString();
    pointer.hidden = pointerHidden;
    bubble.hidden = bubbleHidden;

    css2DRenderer.setSize(labelRendererWidth, labelRendererHeight);
    this.labelRendererParent.appendChild(css2DRenderer.domElement);
    this.labelRendererParent.removeChild(this.temporaryEditorClone!);
    document.body.removeChild(this.container);

    this.prevElementsState = undefined;
    this.labelRendererParent = undefined;
    this.temporaryEditorClone = undefined;
  }

  public async createScreenshot({
    editor,
    cubemapController
  }: CreateScreenshotData): Promise<string> {
    if (!editor) throw new Error('Has no comment data were provided.');

    const { screenshotRenderer, screenshotCamera, scene, css2DRenderer } = this.mainScene;

    this.setupCamera();
    this.setupRenderer();
    this.setupCanvasTDO();
    const restoreCubemap = this.cheatCubemap(cubemapController);

    if (!this.canvasTDO || !this.contextTDO) return '';

    screenshotRenderer.render(scene, screenshotCamera);
    this.contextTDO.drawImage(
      screenshotRenderer.domElement!,
      0,
      0,
      this.coverImageWidth,
      this.coverImageHeight
    );

    this.prepareLabelRenderer(editor);

    const canvasWithPointer = await html2canvas(css2DRenderer.domElement, {
      logging: false,
      backgroundColor: null
    });

    this.contextTDO.drawImage(
      canvasWithPointer,
      -2,
      -3,
      this.coverImageWidth,
      this.coverImageHeight
    );

    this.restoreLabelRendererSettings(editor);
    restoreCubemap();

    return this.canvasTDO.toDataURL('image/png');
  }
}
