import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { BaseScene, MainScene } from 'shared/webgl/scenes';

export class AnimationController {
  private model: GLTF | THREE.Group | undefined;
  private mixer: THREE.AnimationMixer | undefined;
  private clips: THREE.AnimationAction[] = [];
  private clock: THREE.Clock = new THREE.Clock();
  private _activeAnimation: number = -1;

  public set activeAnimation(v: number) {
    this._activeAnimation = v;

    if (this._activeAnimation !== -1) {
      this.reset();
      this.play();
    } else {
      this.reset();
    }
  }

  constructor(mainScene: MainScene, arScene?: BaseScene) {
    mainScene.renderCallbackStack.push(this.update.bind(this));
    arScene?.renderCallbackStack?.push(this.update.bind(this));
  }

  private reset(): void {
    if (!this.mixer) {
      return;
    }

    for (const clip of this.clips) {
      clip.stop();
      clip.reset();
      clip.clampWhenFinished = true;
      clip.setLoop(THREE.LoopOnce, 1);
    }
  }

  private update(): void {
    if (this.mixer) {
      this.mixer.update(this.clock.getDelta());
    }
  }

  public setUpObject(object: GLTF | THREE.Group): void {
    this.model = object;

    if (this.model instanceof THREE.Group) {
      if (!this.model.children.length) return;

      this.mixer = new THREE.AnimationMixer(this.model);
    } else {
      if (!this.model.scene || !this.model.scenes.length) return;

      this.mixer = new THREE.AnimationMixer(this.model.scene || this.model.scenes[0]);
    }

    this.clips = this.model.animations.map((anim): THREE.AnimationAction => {
      return this.mixer!.clipAction(anim);
    });

    this.reset();
  }

  private play(): void {
    if (!this.clips.length) {
      return;
    }

    if (!this.mixer) {
      return;
    }

    this.clips[this._activeAnimation]?.setLoop(THREE.LoopRepeat, Infinity);
    this.clips[this._activeAnimation]?.play();
  }
}
