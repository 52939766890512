import { BindingState } from 'shared/types/reducers';
import { DEFAULT_KEY_BINDINGS_MAP } from 'shared/constants/default-key-bindings';
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { EKeyBindingsKeys, EKeyPriorities } from 'shared/enums/EKeyBindingsKeys';
import { KeyBindingsMap } from 'shared/types/key-binding';
import { AppDispatch, RootState } from '../index';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { deleteKeyBindings, getKeyBindings, updateKeyBindings, uploadKeyBindings } from '../../api/keyBindingsService';
import { KEY_BINDINGS_CHANGED, KEY_BINDINGS_DELETED } from 'shared/constants/notifications';

const initialState: BindingState = {
  keyBindings: DEFAULT_KEY_BINDINGS_MAP,
  isDefaultBindings: true,
  isKeyBindingsUnsynced: false
};

export const setKeyBindings = createAction<KeyBindingsMap>('keyBindings/setKeyBindings');
export const changeKeyBinding = createAction<{
  action: EKeyBindingsKeys;
  code: string;
  type: EKeyPriorities;
}>('keyBindings/changeKeyBinding');
export const removeKeyBinding = createAction<{ action: EKeyBindingsKeys; type: EKeyPriorities }>(
  'keyBindings/removeKeyBinding'
);
export const setIsKeyBindingsUnsynced = createAction<boolean>(
  'keyBindings/setIsKeyBindingsUnsynced'
);
export const resetKeyBindings = createAction('keyBindings/resetKeyBindings');

export const keyBindingsReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setKeyBindings, (state, { payload }): void => {
      state.keyBindings = payload;
      state.isDefaultBindings = false;
      state.isKeyBindingsUnsynced = false;
    })
    .addCase(changeKeyBinding, (state, { payload: { action, code, type } }): void => {
      if (type === EKeyPriorities.primary) {
        state.keyBindings[action].primaryKey = code;
      } else {
        state.keyBindings[action].secondaryKey = code;
      }

      state.isKeyBindingsUnsynced = true;
    })
    .addCase(removeKeyBinding, (state, { payload: { action, type } }): void => {
      if (type === EKeyPriorities.primary) {
        state.keyBindings[action].primaryKey = '';
      } else {
        state.keyBindings[action].secondaryKey = '';
      }

      state.isKeyBindingsUnsynced = true;
    })
    .addCase(setIsKeyBindingsUnsynced, (state, { payload }): void => {
      state.isKeyBindingsUnsynced = payload;
    })
    .addCase(resetKeyBindings, (state): void => {
      state.keyBindings = DEFAULT_KEY_BINDINGS_MAP;
      state.isDefaultBindings = true;
      state.isKeyBindingsUnsynced = false;
    });
});

// region async Thunks
export const fetchKeyBindings = createAsyncThunk<
  Promise<void>,
  { userId?: string },
  { dispatch: AppDispatch; state: RootState }
>('keyBindings/fetchUserBindings', async ({ userId }, { dispatch }): Promise<void> => {
  const keybindings = (await getKeyBindings()).data.bindings;

  dispatch(setKeyBindings(keybindings));
});

export const syncKeyBindings = createAsyncThunk<
  Promise<void>,
  { userId?: string },
  { dispatch: AppDispatch; state: RootState }
>('keyBindings/fetchUserBindings', async ({ userId }, { dispatch, getState }): Promise<void> => {
  try {
    let updatedKeyBindings: KeyBindingsMap;
    const { keyBindings, isDefaultBindings } = getState().keyBindings;

    if (isDefaultBindings) {
      updatedKeyBindings = (await uploadKeyBindings(keyBindings)).data.bindings;
    } else {
      updatedKeyBindings = (await updateKeyBindings(keyBindings)).data.bindings;
    }

    dispatch(setKeyBindings(updatedKeyBindings));
    dispatch(setIsKeyBindingsUnsynced(false));
    openNotification(ESnackbarStyle.SUCCESS, KEY_BINDINGS_CHANGED);
  } catch (e) {
    openNotification(ESnackbarStyle.HOLD_UP, e?.message);
  }
});

export const removeKeyBindings = createAsyncThunk<
  Promise<void>,
  { userId?: string },
  { dispatch: AppDispatch; state: RootState }
>('keyBindings/fetchUserBindings', async ({ userId }, { dispatch, getState }): Promise<void> => {
  const { isDefaultBindings } = getState().keyBindings;

  try {
    if (!isDefaultBindings) {
      await deleteKeyBindings();
    }

    dispatch(resetKeyBindings());

    openNotification(ESnackbarStyle.SUCCESS, KEY_BINDINGS_DELETED);
  } catch (e) {
    openNotification(ESnackbarStyle.HOLD_UP, e?.message);
  }
});
// endregion
