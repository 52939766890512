import { assert } from './assert';

function padToNBytes(byteLength: number, padding: number): number {
  // tslint:disable-next-line:no-bitwise
  return (byteLength + (padding - 1)) & ~(padding - 1);
}

export const bytes = {

  bytesCountFromString(stringCount: string): number {
    let bytesCount = 0;

    if (typeof stringCount === 'number') {
      return (stringCount as number);
    }

    if (typeof stringCount === 'string' && /^[0-9]*$/.test(stringCount)) {
      return parseFloat(stringCount);
    }

    if (stringCount == null || typeof stringCount !== 'string') {
      return 0;
    }

    // Gigs => Megs
    if (/^([0-9]+[.]?[0-9]*)(\s*)(G|[Gg][Bb])$/.test(stringCount)) {
      bytesCount = parseFloat(stringCount);
      bytesCount = (bytesCount * 1024);
    }

    if (/^([0-9]+[.]?[0-9]*)(\s*)(M|[Mm][Bb])$/.test(stringCount)) {
      bytesCount = parseFloat(stringCount);
    }

    // Megs => kbytes
    bytesCount = (bytesCount * 1024);

    if (/^([0-9]+[.]?[0-9]*)(\s*)([Kk][Bb])$/.test(stringCount)) {
      bytesCount = parseFloat(stringCount);
    }

    // kbytes => bytes
    bytesCount = (bytesCount * 1024);

    if (/^([0-9]+[.]?[0-9]*)(\s*)([Bb]|[Bb]ytes)?$/.test(stringCount)) {
      bytesCount = parseFloat(stringCount);
    }

    return Math.round(bytesCount);
  },

  /**
   * Calculate new size of an arrayBuffer to be aligned to an n-byte boundary
   * This function increases `byteLength` by the minimum delta,
   * allowing the total length to be divided by `padding`
   * @param byteLength
   * @param padding
   */
  padToNBytes(byteLength: number, padding: number): number {
    assert(byteLength >= 0); // `Incorrect 'byteLength' value: ${byteLength}`
    assert(padding > 0);     // `Incorrect 'padding' value: ${padding}`
    return padToNBytes(byteLength, padding);
  },

  copyToArray(source: ArrayBuffer | any, target: any, targetOffset: number): number {
    let sourceArray;

    if (source instanceof ArrayBuffer) {
      sourceArray = new Uint8Array(source);
    } else {
      // Pack buffer onto the big target array

      // 'source.data.buffer' could be a view onto a larger buffer.
      // We MUST use this constructor to ensure the byteOffset and byteLength is
      // set to correct values from 'source.data' and not the underlying
      // buffer for target.set() to work properly.
      const srcByteOffset = source.byteOffset;
      const srcByteLength = source.byteLength;

      // In gltf parser it is set as "arrayBuffer" instead of "buffer"
      // https://github.com/visgl/loaders.gl/blob/1e3a82a0a65d7b6a67b1e60633453e5edda2960a/modules/gltf/src/lib/parse-gltf.js#L85
      sourceArray = new Uint8Array(source.buffer || source.arrayBuffer, srcByteOffset, srcByteLength);
    }

    // Pack buffer onto the big target array
    target.set(sourceArray, targetOffset);

    return targetOffset + padToNBytes(sourceArray.byteLength, 4);
  },

  packObj(_obj:any): any {
    let _len = 0;
    let _pos: number = 0;

    return Object.keys(_obj).map((_k): Buffer => {
      const _v = _obj[_k];

      if (Number.isInteger(_v)) {
        if (_v <= 0x0000ff) { _len += 2; return this.writeUInt8(null, _v);  }
        if (_v <= 0x00ffff) { _len += 3; return this.writeUInt16(null, _v); }
        if (_v <= 0xffffff) { _len += 5; return this.writeUInt32(null, _v); }
      }

      const _buf = Buffer.from(_v);
      _len += (_buf.length + 1);

      return _buf;
    }).reduce((buf, val): Buffer => {
      if (val.length) {
        this.writeUInt8(buf, val.length, _pos++);
        _pos += val.copy(buf, _pos);
      }
      return buf;
    }, Buffer.alloc(_len));
  },

  unpackObj(buf:any, _obj:any): any {
    let _pos: number = 0;
    Object.keys(_obj).forEach((_k): void => {
      const _l = this.readUInt8(buf, _pos++);
      if (_l === 1) { _obj[_k] = this.readUInt8(buf, _pos);  _pos += _l; }
      if (_l === 2) { _obj[_k] = this.readUInt16(buf, _pos); _pos += _l; }
      if (_l === 4) { _obj[_k] = this.readUInt32(buf, _pos); _pos += _l; }
      if (_l  >  4) { _obj[_k] = buf.slice(_pos, _pos + _l); _pos += _l; }
    });
    return _obj;
  },

  writeUInt8(buf:any, _val:any, pos: number = 0): any {
    buf = buf || Buffer.allocUnsafe(1);
    buf.writeUInt8(_val, pos);
    return buf;
  },

  readUInt8(_buf:any, pos: number = 0): any {
    return _buf.readUInt8(pos);
  },

  writeUInt16(buf:any, _val:any, pos: number = 0): any {
    buf = buf || Buffer.allocUnsafe(2);
    buf.writeUInt16LE(_val, pos);
    return buf;
  },

  readUInt16(_buf:any, pos: number = 0): any {
    return _buf.readUInt16LE(pos);
  },

  writeUInt32(buf:any, _val:any, pos: number = 0): any {
    buf = buf || Buffer.allocUnsafe(4);
    buf.writeUInt32LE(_val, pos);
    return buf;
  },

  readUInt32(_buf:any, pos: number = 0): any {
    return _buf.readUInt32LE(pos);
  }

};
