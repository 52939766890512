import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import { COLORS } from 'shared/constants/colors';

export const PageContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;

export const PageHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const PageContent = styled.main`
  padding-top: 40px;

  @media ${DEVICE.desktopLarge} {
    flex-direction: column;
  }
`;
