export enum ECubeZones {
  faceFr = 'front face',
  faceBk = 'back face',
  faceLf = 'left face',
  faceRg = 'right face',
  faceTp = 'top face',
  faceBt = 'bottom face',

  edgeFL = 'front left edge',
  edgeFR = 'front right edge',
  edgeFT = 'front top edge',
  edgeFB = 'front bottom edge',
  edgeBL = 'back left edge',
  edgeBR = 'back right edge',
  edgeBT = 'back top edge',
  edgeBB = 'back bottom edge',
  edgeLT = 'left top edge',
  edgeLB = 'left bottom edge',
  edgeRT = 'right top edge',
  edgeRB = 'right bottom edge',

  angleFLT = 'front left top angle',
  angleFRT = 'front right top angle',
  angleFLB = 'front left bottom angle',
  angleFRB = 'front right bottom angle',
  angleBLT = 'back left top angle',
  angleBRT = 'back right top angle',
  angleBLB = 'back left bottom angle',
  angleBRB = 'back right bottom angle',
}
