import type {
  IGeometry3DStats,
  IResources3DStats,
  ITexture2DStats
} from '../interfaces/model-parse/model-stats';
import { ISTLMetaData } from '../interfaces/model-parse/stl-types';

import { Texture2DStats } from '../texture-2d-stats';
import { STLParser } from './stl-parser';
import { Model3DFilePartStats } from '../interfaces/3d-models';
import { Model3DAnimationStats } from '../interfaces/3d-models/model-3d-resources.interface';
import { fetchBuffer } from '../utils/buffer';
import { Buffer } from 'buffer';

export class STLParserService {
  protected _textureStats: Texture2DStats = new Texture2DStats();
  protected _resourceStats: IResources3DStats = {
    materialsCount: 0,
    texturesCount: 0,
    animationsCount: 0,
    meshesCount: 0,
    scenesCount: 0
  };
  protected _geometryStats: IGeometry3DStats = {
    trianglesCount: 0,
    vertexesCount: 0,
    polygonsCount: 0
  };

  /** 0 - none, 1 - lit, 2 - unlit, 3 - mixed */
  protected _unlitFlag: number = 0;
  protected _basePath: string = '';
  protected _format: string = '';

  private _buffers: Buffer[] = [];

  public static canHandleFileType(ext: string): boolean {
    return this.supportedFileTypes.includes(ext);
  }

  public static get supportedFileTypes(): string[] {
    return ['stl'];
  }

  public get unlitFlag(): number {
    return this._unlitFlag;
  }

  public get format(): string{
    return this._format;
  }

  public get fileSize(): Model3DFilePartStats {
    return {
      totalSize: this.binSize,
      gltfSize: this.binSize,
      binSize: this.binSize
    };
  }

  public get vertexesCount(): number {
    return this._geometryStats.vertexesCount;
  }

  public get trianglesCount(): number {
    return this._geometryStats.trianglesCount;
  }

  public get quadsCount(): number {
    return 0;
  }

  public get polygonsCount(): number {
    return 0;
  }

  public get meshCount(): number {
    return this._resourceStats.meshesCount;
  }

  public get materialsCount(): number {
    return this._resourceStats.materialsCount;
  }

  public get scenesCount(): number {
    return this._resourceStats.scenesCount;
  }

  public get animations(): Model3DAnimationStats {
    return {
      totalCount: this._resourceStats.animationsCount,
      animations: []
    };
  }

  public get textures(): ITexture2DStats {
    return this._textureStats.plainObject();
  }

  protected get binSize(): number {
    return this._buffers.reduce((acc: number, buffer): number => {
      return acc + buffer.byteLength;
    }, 0);
  }

  protected parseSTLSync(modelFile: Buffer): ISTLMetaData {
    this._buffers.push(modelFile);
    return new STLParser(modelFile).parse();
  }

  public async parse(ext: string, formatFiles: Record<string, string>): Promise<boolean> {
    this._format = `.${ext}`;

    if (!formatFiles) {
      return false;
    }

    const mainFileKey = Object.keys(formatFiles)[0];

    const modelFile = await fetchBuffer(formatFiles[mainFileKey]);

    const stl = this.parseSTLSync(Buffer.from(modelFile));

    this._geometryStats.vertexesCount = stl.vertices;
    this._geometryStats.trianglesCount = stl.triangles;

    return true;
  }
}
