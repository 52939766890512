import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';
import KeyboardImage from 'assets/images/keyboard.svg';
import MouseImage from 'assets/images/mouse.svg';
import KeyboardArrowsImage from 'assets/images/keyboard-arrows.svg';
import { useAppDispatch } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';

const ModalQuickGuide = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleViewerSettingsClick = (): void => {
    history.push('/account-settings?camera-controls=1');
    dispatch(closeModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalTitle>Quick Help Guide</Styled.ModalTitle>
      <Styled.ModalContent>
        <Styled.TopContent>
          <Styled.InfoBlock>
            <Styled.BlockTitle>Quick View</Styled.BlockTitle>
            <Styled.InfoBlockContent>
              <Styled.ContentItem>
                Best for <span className='highlighted-text'>gauging content and quality</span> of a
                3D model before making key decisions.
              </Styled.ContentItem>
              <Styled.ContentItem>
                Files <span className='highlighted-text'>never leave your computer</span> and are
                never saved to your account.
              </Styled.ContentItem>
              <Styled.ContentItem>
                <span className='highlighted-text'>Drag & drop files</span> into the 3D viewer to
                instantly view a new model.
              </Styled.ContentItem>
            </Styled.InfoBlockContent>
          </Styled.InfoBlock>
          <Styled.InfoBlock>
            <Styled.BlockTitle>Upload for Sharing</Styled.BlockTitle>
            <Styled.InfoBlockContent>
              <Styled.ContentItem>
                Best for <span className='highlighted-text'>collaborating</span> and obtaining
                <span className='highlighted-text'> feedback</span> before further improving your 3D
                model.
              </Styled.ContentItem>
              <Styled.ContentItem>
                Files are <span className='highlighted-text'>uploaded to glbee</span> and can be
                shared for download by others.
              </Styled.ContentItem>
              <Styled.ContentItem>
                <span className='highlighted-text'>Drag & drop files</span> into your workspace to
                upload new model files.
              </Styled.ContentItem>
            </Styled.InfoBlockContent>
          </Styled.InfoBlock>
        </Styled.TopContent>
        <Styled.BottomContent>
          <Styled.BlockTitle>View and Movement Controls</Styled.BlockTitle>
          <Styled.BlockSubtitle>
            Controls can be modified in{' '}
            <span className='highlighted-text' onClick={handleViewerSettingsClick}>
              your camera control settings
            </span>
            .
          </Styled.BlockSubtitle>
          <Styled.ControlExamples>
            <Styled.KeyboardImage src={KeyboardImage} alt='Keyboard' />
            <Styled.ExamplesRightSide>
              <Styled.MouseImage src={MouseImage} alt='Mouse' />
              <Styled.KeyboardArrowsImage src={KeyboardArrowsImage} alt='Keyboard Arrows' />
            </Styled.ExamplesRightSide>
          </Styled.ControlExamples>
        </Styled.BottomContent>
      </Styled.ModalContent>
    </Styled.ModalWindowContainer>
  );
};

export default ModalQuickGuide;
