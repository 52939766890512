export enum ETouchMovementType {
  Rotation,
  Dolly,
  Pan
}

export interface SetupCameraControllerData {
  listeningBlock: HTMLElement | null;
  tooltipBlock: HTMLDivElement | null;
  cubeOrbitControllerBlock?: HTMLDivElement | null;
  onModelCameraChangedFn?: () => void;
}
