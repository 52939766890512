import React, { ChangeEvent, useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle, Model } from 'shared/types';
import { openNotification } from 'utils/notification-utils';

type Props = {
  model: Model;
  action: () => Promise<void>;
};

const ModalDeleteModel: React.FC<Props> = ({ model, action }): JSX.Element => {
  const dispatch = useAppDispatch();
  const DELETE_TEXT = model.modelName;
  const [value, setValue] = useState<string>('');

  const handleDeleteButtonClick = async (): Promise<void> => {
    if (value.trim() === DELETE_TEXT) {
      await action();
    } else {
      openNotification(ESnackbarStyle.HOLD_UP, 'The value must match the model name.');
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setValue(value);
  };

  const handleEnterClick = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      await handleDeleteButtonClick();
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer id='delete-mw'>
      <Styled.Notification>
        Are you sure you want to delete <span id='model-name-delete-mw'>{model.modelName}</span>?
      </Styled.Notification>
      <Styled.Confirmation>Confirm by typing the model name below</Styled.Confirmation>
      <Styled.ConfirmationInput
        id='model-name-input-delete-mw'
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleEnterClick}
      />
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton
          className='delete-button'
          onClick={handleDeleteButtonClick}
          id='delete-model-forever-button'
        >
          Delete Forever
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalDeleteModel;
