import { useCallback, useState } from 'react';
import { ESnackbarStyle, Model, User } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { getRecentViewedModels } from 'services/api/modelsService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getModel } from 'services/api/modelService';

type Result = {
  modelsData: Model[];
  fetchRecentViewedModels: () => Promise<void>;
  fetchModel: (modelId: string) => Promise<void>;
  isSearchMode: boolean;
  setSearch: (value: string) => void;
  setIsSearchMode: (value: boolean) => void;
};

const useRecentViewedData = (): Result => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store): User | null => store.auth.user);
  const [modelsData, setModelsData] = useState<Model[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);

  const fetchRecentViewedModels = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      if (!!user) {
        const modelsData = (await getRecentViewedModels(user.id, search)).data;
        setModelsData(modelsData);
        setIsSearchMode(!!search);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch, user, search]);

  const fetchModel = useCallback(
    async (modelId: string): Promise<void> => {
      dispatch(startLoader());
      try {
        const model = (await getModel(modelId)).data;
        setModelsData([model]);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  return {
    modelsData,
    fetchRecentViewedModels,
    fetchModel,
    isSearchMode,
    setSearch,
    setIsSearchMode
  };
};

export default useRecentViewedData;
