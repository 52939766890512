import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector, useFeatureAccess } from 'shared/hooks';
import { showModal } from 'services/store/reducers/modalReducer';
import { EPremiumFeature } from 'shared/types';
import { ModalFeatureSignedIn, ModalFeatureSignedOut } from 'shared/components';
import { toggleCommentsBar } from 'services/store/reducers/commentsReducer';
import { RootState } from 'services/store';
import { CommentIcon, ChevronIcon } from 'assets/dynamic-icons';

const FeaturesBlock: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { model } = store.viewerData;
  const { isAuth } = store.auth;
  const { plainComments, isCommentsBarActive } = store.comments;
  const { checkCommentAccess } = useFeatureAccess(model!);

  const handleAddCommentClick = async (): Promise<void> => {
    const hasCommentAccess = isCommentsBarActive || (await checkCommentAccess());
    if (hasCommentAccess) {
      dispatch(toggleCommentsBar(!isCommentsBarActive));
    } else {
      isAuth
        ? dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.COMMENTS} />))
        : dispatch(showModal(<ModalFeatureSignedOut feature={EPremiumFeature.COMMENTS} />));
    }
  };

  return (
    <Styled.FeaturesBlockContainer>
      <Styled.FeatureItem onClick={handleAddCommentClick} className='add-comment'>
        <Styled.FeatureIconContainer>
          <CommentIcon />
        </Styled.FeatureIconContainer>

        <span id='comments-button'>Comments</span>

        <span className='comments-count'>
          {!isCommentsBarActive &&
            (plainComments.length <= 99999 ? plainComments.length : '99999+')}
        </span>

        <Styled.OpenCommentsButton>
          <Styled.ChevronIconWrapper isActive={isCommentsBarActive}>
            <ChevronIcon />
          </Styled.ChevronIconWrapper>
        </Styled.OpenCommentsButton>
      </Styled.FeatureItem>
    </Styled.FeaturesBlockContainer>
  );
};

export default FeaturesBlock;
