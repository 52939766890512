import { IMTLMetaData } from '../interfaces/model-parse/obj-types';

export class MTLParser {
  constructor(buffer: Uint8Array) {
    this._buffer = new TextDecoder().decode(buffer);
  }

  private _mtlMetaData: IMTLMetaData = {
    materials: 0,
    textures: [],
    unlitFlag: 0
  };

  private _buffer: string;

  public parse(): IMTLMetaData {
    const lines = this._buffer.split('\n');

    lines.forEach((line): void => {
      const lineItems = this._stripComments(line).trim().split(' ');

      switch (lineItems[0].toLowerCase()) {
        case 'newmtl':
          this._mtlMetaData.materials += 1;
          break;
        case 'map_kd':
          this._parseTextures(lineItems);
          break;
        case 'illum':
          this._parseShading(lineItems);
          break;
      }
    });

    return this._mtlMetaData;
  }

  private _stripComments(lineString: string): string {
    const commentIndex = lineString.indexOf('#');
    if (commentIndex !== -1) {
      return lineString.substring(0, commentIndex);
    }
    return lineString;
  }

  private _parseTextures(lineItems: string[]): void {
    if (lineItems.length < 2) {
      return;
    }
    let textureName = lineItems[1];

    if (lineItems.length > 2) {
      textureName = lineItems.slice(1).join(' ').replaceAll('\\', '/');
    }
    if (!this._mtlMetaData.textures.includes(textureName)) {
      this._mtlMetaData.textures.push(textureName);
    }
  }

  private _parseShading(lineItems: string[]) {
    const illum = parseInt(lineItems[1]);
    if (illum >= 0 && typeof illum === 'number') {
      if (illum === 0 || illum === 1) {
        this._mtlMetaData.unlitFlag = 2;
      } else {
        this._mtlMetaData.unlitFlag = 1;
      }
    }
  }
}
