import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ArrowLeftContainer = styled.div`
  position: absolute;
  right: -26px;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 40px;
  border-bottom-right-radius: 12px;
  background: rgba(83, 83, 83, 0.4);
  backdrop-filter: blur(6px);
  transition: all 0.5s;
`;

export const ArrowLeft = styled.img<{ isSidebarHidden: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 2px;
  transition: all 0.5s;
  transform: rotate(${({ isSidebarHidden }): string => (isSidebarHidden ? '180deg' : '0deg')});
`;

export const SidebarContainer = styled.aside<{ isArMode: boolean; isZenMode: boolean }>`
  height: min-content;
  max-height: ${({ isArMode, isZenMode }): string =>
    isArMode ? 'calc(100vh - 88px)' : isZenMode ? 'calc(100vh - 36px)' : 'calc(100vh - 118px)'};
  overflow-y: auto;
  width: 327px;
  background: rgba(83, 83, 83, 0.4);
  transition: all 0.5s;

  &::-webkit-scrollbar {
    width: 3px;
    background: rgba(83, 83, 83, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.mischka};
  }

  @media ${DEVICE.tabletLarge} {
    max-height: ${({ isArMode, isZenMode }): string =>
      isArMode ? '90vh' : isZenMode ? 'calc(100vh - 86px)' : 'calc(100vh - 168px)'};
  }

  @media ${DEVICE.tablet} {
    width: 300px;
    max-height: ${({ isArMode, isZenMode }): string =>
      isArMode
        ? 'calc(var(--viewport-height) - 90px)'
        : isZenMode
        ? 'calc(var(--viewport-height) - 50px)'
        : 'calc(var(--viewport-height) - 88px)'};
  }
`;

export const FullModeContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px 14px 50px 16px;

  @media ${DEVICE.tablet} {
    padding: 10px 10px;
  }
`;

export const SettingsOptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const SettingsOption = styled.div`
  margin-top: 10px;
`;

export const FieldLabel = styled.span`
  font-family: ${FONTS.interRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.white};
  display: inline-block;
  user-select: none;
`;

export const SwitchersContainer = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const SwitchBlock = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  min-width: 95px;
`;

export const AnimationsBlock = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ModelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: ${COLORS.white};
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 50px;
`;

export const ModelNameInput = styled.input`
  width: 100%;
  height: 42px;
  padding: 5px 10px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: 1px solid ${COLORS.mineShaft};
  border-radius: 4px;
  color: ${COLORS.white};
  font-size: 14px;
  font-family: ${FONTS.interRegular};

  &:focus {
    border: 1px solid ${COLORS.mischka};
  }
`;

export const ActionButton = styled.button<{ activeColor: string; activeTextColor: string }>`
  margin-top: 30px;
  width: 100%;
  padding: 10px;
  color: ${COLORS.white};
  background: ${COLORS.black};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-family: ${FONTS.interRegular};
  transition: all 0.2s;
  opacity: 0.85;

  &:last-child {
    margin-top: 15px;
  }

  &.download {
    color: ${({ activeTextColor }): string => activeTextColor || COLORS.black};
    background: ${({ activeColor }): string => activeColor || COLORS.turbo};
  }

  &:hover {
    background: ${({ activeColor }): string => activeColor || COLORS.turbo};
    color: ${({ activeTextColor }): string => activeTextColor || COLORS.black};
    opacity: 1;
  }
`;

export const AutoSavingText = styled.span`
  color: ${COLORS.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 10px;
`;

export const FullModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(6px);
`;

export const SidebarHeader = styled.div`
  display: none;
  background: rgba(83, 83, 83, 0.4);
  width: 100%;
  height: 35px;

  @media ${DEVICE.tabletLarge} {
    display: flex;
  }
`;

export const SidebarHeaderItem = styled.div<{ isActive: boolean; activeColor: string }>`
  width: 50%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};
  border-bottom: 2px solid
    ${({ isActive, activeColor }): string =>
      isActive ? activeColor || COLORS.turbo : 'transparent'};
  cursor: pointer;
`;

export const SidebarHeaderItemCounter = styled.div`
  margin-left: 16px;
`;

export const Lock = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
  left: -6px;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const SaveModelBlockContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
