import * as THREE from 'three';
import { BaseLoadModelStrategy } from './base-load-model.strategy';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';

export class FbxLoadModelStrategy extends BaseLoadModelStrategy {
  private fbxLoader: FBXLoader;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    super(renderer, onModelResourcesLoaded);

    this.fbxLoader = new FBXLoader(this.loadingManager).setCrossOrigin('anonymous');
  }

  public async loadModel({ path, assets }: LoadModelData): Promise<LoadModelResult> {
    this.setLoadingContent(assets);

    const model = await this.fbxLoader.loadAsync(path);

    this.setLoadingContent([]);

    return {
      gltf: null,
      model
    };
  }
}
