import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { ResponseModel } from 'shared/types';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import {
  MAIN_SCENE_CSS2D_CONTAINER_ID,
  EMBEDDING_MODAL_MAIN_SCENE_CANVAS_ID,
  PREVIEW_MAIN_SCENE_CSS2D_CONTAINER_ID,
  MAIN_SCENE_CANVAS_ID,
  MAIN_SCENE_CSS3D_CONTAINER_ID
} from 'shared/constants/html-elements-ids';
import {
  checkFiniteness,
  getCameraAngleRelativeToObject,
  getObjectBoundingBox,
  setupRenderer,
  setupRendererDomElement,
  traverseMaterials,
  updateMatrices
} from 'utils/scenes-utils';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import {
  CreateScreenshotService,
  DisposeSceneService,
  LoadModelService
} from 'services/strategy-services';
import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';
import { BaseScene } from 'shared/webgl/scenes';
import {
  DEF_CAMERA_SETTINGS,
  DEF_RENDERER_SETTINGS,
  DEF_SS_CAMERA_SETTINGS,
  DEF_SS_RENDERER_SETTINGS
} from 'shared/constants/scene-settings-constants';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { CreateScreenshotData } from 'shared/interfaces';

export class MainScene extends DisposeSceneService implements BaseScene {
  private parent?: HTMLDivElement;
  private mounted: boolean = false;
  private filePath: string = '';
  private texturesDetails: THREE.Texture[] = [];
  private prevModelBBSize: THREE.Vector3 = new THREE.Vector3();
  private currModelBBSize: THREE.Vector3 = new THREE.Vector3();
  private readonly _modelBoundingBox: THREE.Box3 = new THREE.Box3();
  private readonly loadModelService: LoadModelService;
  private readonly createScreenshotService: CreateScreenshotService = new CreateScreenshotService({
    mainScene: this
  });
  private isPreviewActive: boolean = false;
  private contextLostErrorSent: boolean = false;

  // region development
  private helpersEnabled: boolean = this.isLocalDevelopment;
  private bbHelper: THREE.BoxHelper | undefined;
  public deepBBHelper: THREE.Box3Helper | undefined;
  // endregion

  public scene: THREE.Scene;
  public camera: THREE.PerspectiveCamera;
  public renderer: THREE.WebGLRenderer;
  public css2DRenderer: CSS2DRenderer;
  public css3DRenderer: CSS3DRenderer;
  public pmremGenerator: THREE.PMREMGenerator;
  public previewCamera: THREE.PerspectiveCamera;
  public previewRenderer: THREE.WebGLRenderer;
  public previewCSS2DRenderer: CSS2DRenderer;
  public screenshotCamera: THREE.PerspectiveCamera;
  public screenshotRenderer: THREE.WebGLRenderer;
  public screenshotPmremGenerator: THREE.PMREMGenerator;
  public canvas: HTMLCanvasElement;
  public gltf: GLTF | undefined;
  public model: THREE.Group | undefined;
  public modelBoundingBoxNeedsUpdate: boolean = false;
  public externalObjectWrapper: THREE.Object3D = new THREE.Object3D();
  public objectWrapper: THREE.Object3D = new THREE.Object3D();
  public onInitialized: (() => void) | undefined;
  public onModelResourcesLoaded: (() => void) | undefined;
  public lightWrapper: THREE.Object3D = new THREE.Object3D();
  public environmentWrapper: THREE.Object3D = new THREE.Object3D();
  public dimensionsWrapper: THREE.Object3D = new THREE.Object3D();
  public orbitControls: OrbitControls;
  public renderCallbackStack: (() => void)[] = [];
  public rotateRimLight: ((angle: number) => void) | undefined;

  constructor(private canvasId: string = MAIN_SCENE_CANVAS_ID) {
    super();

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(...DEF_CAMERA_SETTINGS);
    this.renderer = new THREE.WebGLRenderer(...DEF_RENDERER_SETTINGS);
    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.css2DRenderer = new CSS2DRenderer();
    this.css3DRenderer = new CSS3DRenderer();

    this.previewCamera = new THREE.PerspectiveCamera(...DEF_CAMERA_SETTINGS);
    this.previewRenderer = new THREE.WebGLRenderer(...DEF_RENDERER_SETTINGS);
    this.previewCSS2DRenderer = new CSS2DRenderer();

    this.screenshotCamera = new THREE.PerspectiveCamera(...DEF_SS_CAMERA_SETTINGS);
    this.screenshotRenderer = new THREE.WebGLRenderer(...DEF_SS_RENDERER_SETTINGS);
    this.screenshotPmremGenerator = new THREE.PMREMGenerator(this.screenshotRenderer);

    this.setupLayers();
    this.setupRendering();
    this.canvas = this.renderer.domElement;

    this.loadModelService = new LoadModelService({
      renderer: this.renderer,
      onModelResourcesLoaded: (): void => {
        if (this.onModelResourcesLoaded) {
          this.onModelResourcesLoaded();
        }
      }
    });
    this.orbitControls = new OrbitControls(this.camera, document.createElement('div'));

    this.pmremGenerator.compileEquirectangularShader();
    this.screenshotPmremGenerator.compileEquirectangularShader();

    this.orbitControls.enableDamping = true;

    this.startAnimationLoop();
    this.initScene();
  }

  private setupRendering(): void {
    const { innerWidth, innerHeight } = window;

    setupRenderer(this.renderer, innerWidth, innerHeight);
    setupRenderer(this.screenshotRenderer, 512, 512);
    setupRenderer(this.previewRenderer, innerWidth, innerHeight);

    this.css2DRenderer.setSize(innerWidth, innerHeight);
    this.css3DRenderer.setSize(innerWidth, innerHeight);

    setupRendererDomElement({
      element: this.renderer.domElement,
      id: this.canvasId,
      top: 0,
      left: 0
    });
    setupRendererDomElement({
      element: this.css2DRenderer.domElement,
      id: MAIN_SCENE_CSS2D_CONTAINER_ID,
      top: 0,
      left: 0,
      disablePinterEvents: true
    });
    setupRendererDomElement({
      element: this.css3DRenderer.domElement,
      id: MAIN_SCENE_CSS3D_CONTAINER_ID,
      top: 0,
      left: 0,
      disablePinterEvents: true
    });
    setupRendererDomElement({
      element: this.previewRenderer.domElement,
      id: EMBEDDING_MODAL_MAIN_SCENE_CANVAS_ID
    });
    setupRendererDomElement({
      element: this.previewCSS2DRenderer.domElement,
      id: PREVIEW_MAIN_SCENE_CSS2D_CONTAINER_ID
    });

    this.enableRefreshOnCtxLost();
  }

  private setupLayers(): void {
    this.camera.layers.enableAll();
    this.screenshotCamera.layers.enableAll();

    this.previewCamera.layers.enable(0);
    this.previewCamera.layers.enable(1);
  }

  private initScene(): void {
    this.externalObjectWrapper.add(this.objectWrapper);

    this.scene.add(this.lightWrapper);
    this.scene.add(this.externalObjectWrapper);
    this.scene.add(this.environmentWrapper);
    this.scene.add(this.dimensionsWrapper);
    this.scene.add(this.camera);
    this.scene.add(this.screenshotCamera);
    this.scene.add(this.previewCamera);

    if (this.helpersEnabled) {
      this.bbHelper = new THREE.BoxHelper(this.externalObjectWrapper, 0xffff00);
      this.deepBBHelper = new THREE.Box3Helper(
        getObjectBoundingBox(this.externalObjectWrapper),
        0xff0000
      );

      this.scene.add(this.bbHelper);
      this.scene.add(this.deepBBHelper);
      this.renderCallbackStack.push((): void => {
        if (this.model && this.bbHelper && this.deepBBHelper) {
          this.bbHelper.update();
        }
      });
    }
  }

  private updateModelBoundingBox(): void {
    if (!this.model || !this.modelBoundingBoxNeedsUpdate) return;

    updateMatrices(this.model);

    this._modelBoundingBox.getSize(this.prevModelBBSize);
    this._modelBoundingBox.makeEmpty();
    this._modelBoundingBox.setFromObject(this.model, true);
    this._modelBoundingBox.getSize(this.currModelBBSize);

    this.modelBoundingBoxNeedsUpdate = !this.prevModelBBSize.equals(this.currModelBBSize);

    if (!checkFiniteness([this._modelBoundingBox.min, this._modelBoundingBox.max]))
      throw new Error('Cannot get model bounding box.');

    if (this.deepBBHelper) {
      this.deepBBHelper.box = this._modelBoundingBox;
    }
  }

  private getTexturesDetails(): void {
    if (!this.model) return;

    this.texturesDetails = [];

    traverseMaterials(this.model, (material): void => {
      const tmp = material.map;

      if (tmp instanceof THREE.Texture && tmp.image && tmp.image.width && tmp.image.height) {
        this.texturesDetails.push(tmp);
      }
    });
  }

  private applySceneEnvironment(target: THREE.Object3D): void {
    traverseMaterials(target, (material): void => {
      if (material instanceof THREE.MeshPhysicalMaterial) {
        material.envMap = this.scene.environment;
      }
    });
  }

  protected animate(): void {
    this.renderCallbackStack.forEach((cb): void => {
      cb();
    });

    if (this.rotateRimLight) {
      getCameraAngleRelativeToObject(this.camera, this.objectWrapper, this.rotateRimLight);
    }

    this.renderer.render(this.scene, this.camera);

    if (!this.isPreviewActive) {
      this.css2DRenderer.render(this.scene, this.camera);
      this.css3DRenderer.render(this.scene, this.camera);
    }

    this.checkIfContextLost();
  }

  public async createScreenshot({
    editor,
    cubemapController
  }: CreateScreenshotData): Promise<string> {
    const has4KTextures =
      this.texturesDetails &&
      this.texturesDetails.find(
        (item): boolean => item.image.width >= 4096 || item.image.height >= 4096
      );
    const isDesktopDevice = window.innerWidth > DEVICE_SIZES.tabletLarge;

    if (!isDesktopDevice && has4KTextures) {
      return await this.createScreenshotService.createScreenShot(EScreenshotTypes.sceneSimpleCi, {
        cubemapController
      });
    } else {
      if (!this.screenshotCamera || !this.screenshotRenderer) {
        this.screenshotCamera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000);
        this.screenshotCamera.layers.enableAll();

        this.screenshotRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      }

      if (editor) {
        return await this.createScreenshotService.createScreenShot(
          EScreenshotTypes.sceneWithCommentSs,
          {
            editor,
            cubemapController
          }
        );
      }

      return await this.createScreenshotService.createScreenShot(EScreenshotTypes.sceneDefaultCi, {
        cubemapController
      });
    }
  }

  public async loadModel(
    path: string,
    type: E3DModelFileTypes,
    mtls: string[] = [],
    modelContent: ResponseModel[] = []
  ): Promise<void> {
    if (path === this.filePath || !path.length) {
      return;
    }

    this.filePath = path;
    this.gltf = undefined;
    this.model = undefined;

    const { gltf, model } = await this.loadModelService.loadModel(type, {
      path,
      mtls,
      assets: modelContent
    });
    this.gltf = gltf || undefined;
    this.model = model;

    if (type === E3DModelFileTypes.stl) {
      this.applySceneEnvironment(this.model);
    }

    requestAnimationFrame((): void => {
      if (this.onInitialized) this.onInitialized();
    });
    this.setupModel();
    this.getTexturesDetails();

    this.modelBoundingBoxNeedsUpdate = true;
  }

  public resetModelTransforms(): void {
    if (!this.model) return;

    this.model.position.set(0, 0, 0);
    this.model.rotation.set(0, 0, 0);

    this.objectWrapper.position.set(0, 0, 0);
    this.objectWrapper.rotation.set(0, 0, 0);

    this.externalObjectWrapper.position.set(0, 0, 0);
    this.externalObjectWrapper.rotation.set(0, 0, 0);
  }

  public setupModel(): void {
    if (!this.model) return;

    this.objectWrapper.add(this.model);
    this.resetModelTransforms();
    this.refreshWebGL();
  }

  public get modelBoundingBox(): THREE.Box3 {
    this.updateModelBoundingBox();

    return !this.modelBoundingBoxNeedsUpdate ? this._modelBoundingBox : this.modelBoundingBox;
  }

  public mounting({ parent }: { parent: HTMLDivElement }): void {
    if (this.mounted) return;

    this.parent = parent;

    this.parent.appendChild(this.canvas);
    this.parent.appendChild(this.css3DRenderer.domElement);
    this.parent.appendChild(this.css2DRenderer.domElement);
    this.updateViewerSize();
    this.mountStats();

    window.addEventListener('resize', (): void => {
      this.updateViewerSize();
    });

    this.mounted = true;
  }

  public updateViewerSize(): void {
    if (!this.parent) return;
    const { offsetWidth, offsetHeight } = this.parent;

    this.renderer.domElement.style.width = '100%';
    this.renderer.domElement.style.height = '100%';
    this.renderer.domElement.style.width = `${offsetWidth}`;

    this.renderer.setSize(offsetWidth, offsetHeight);
    this.css2DRenderer.setSize(offsetWidth, offsetHeight);
    this.css3DRenderer.setSize(offsetWidth, offsetHeight);

    this.camera.aspect = offsetWidth / offsetHeight;
    this.camera.updateProjectionMatrix();
  }

  public setPreviewSceneActive(active: boolean): void {
    this.isPreviewActive = active;
  }

  public enablePreviewCameraFilter(enable: boolean): void {
    enable ? this.previewCamera.layers.disable(1) : this.previewCamera.layers.enable(1);

    this.updatePreviewScene();
  }

  public mountPreviewScene(parent: HTMLDivElement): void {
    parent.appendChild(this.previewRenderer.domElement);
    parent.appendChild(this.previewCSS2DRenderer.domElement);

    this.updatePreviewSize({ width: parent.offsetWidth, height: parent.offsetHeight });

    this.previewCamera.position.copy(this.camera.position);
    this.previewCamera.rotation.copy(this.camera.rotation);

    this.updatePreviewScene();
  }

  public updatePreviewScene(): void {
    this.previewRenderer.render(this.scene, this.previewCamera);
    this.previewCSS2DRenderer.render(this.scene, this.previewCamera);
  }

  public updatePreviewSize({ width, height }: { width: number; height: number }): void {
    this.previewRenderer.domElement.style.width = '100%';
    this.previewRenderer.domElement.style.height = '100%';
    this.previewRenderer.domElement.style.width = `${width}`;

    this.previewRenderer.setSize(width, height);
    this.previewCSS2DRenderer.setSize(width, height);

    this.previewCamera.aspect = width / height;
    this.previewCamera.updateProjectionMatrix();

    this.updatePreviewScene();
  }

  public checkIfContextLost(): void {
    if (this.contextLostErrorSent) return;

    const gl = this.renderer.domElement.getContext('webgl2');

    if (!gl) return;

    if (gl.isContextLost()) {
      this.contextLostErrorSent = true;
      this.refreshWebGL();

      // openNotification(ESnackbarStyle.HOLD_UP, 'Sorry, an error occurred. Can you refresh?');
      console.warn('WebGL Context lost!');
    }
  }
}
