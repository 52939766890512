import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

export const FormTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`;

export const FormContainer = styled.div`
  background: ${COLORS.mineShaft};
  border-radius: 7px;
  padding: 25px;
  max-width: 620px;
  width: 100%;

  @media${DEVICE.tablet} {
    width: 100%;
    max-width: initial;
  }

  @media${DEVICE.mobile} {
    padding: 25px 10px;
  }
`;

export const AccountInfoForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FieldTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  width: 60%;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const EmailFieldContainer = styled.div`
  margin-top: 28px;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const ChangeNameSection = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: flex-end;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const UserNameField = styled.div`
  display: flex;
  flex-direction: column;
  width: 29%;

  &:first-child {
    margin-right: 2%;
  }

  @media${DEVICE.tablet} {
    width: 49%;
  }
`;

export const NameInput = styled.input`
  margin-top: 13px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
  font-size: 13px;
  line-height: 14px;
  padding: 0 5px;
  height: 30px;
  border-radius: 4px;
  width: 100%;
`;

export const EmailField = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const EmailInput = styled.input`
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
  font-size: 13px;
  line-height: 14px;
  padding: 0 5px;
  height: 30px;
  border-radius: 4px;
  width: 60%;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const ActionButton = styled.div`
  color: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  margin-left: 13px;
  cursor: pointer;

  &.name {
    margin-bottom: 8px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const PasswordField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

export const PasswordFieldHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangePasswordSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;

  @media${DEVICE.tablet} {
    width: calc(100% - 60px);
  }
`;

export const PasswordFormField = styled.div`
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 48%;
  min-height: 105px;

  @media${DEVICE.tablet} {
    width: 49%;
  }
`;

export const InputLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  margin-top: 13px;
`;

export const PasswordInput = styled.input`
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
  font-size: 13px;
  line-height: 14px;
  padding: 0 5px;
  height: 30px;
  border-radius: 4px;
  width: 100%;
  margin-top: 13px;
`;

export const ForgotPasswordField = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    white-space: nowrap;
    margin: 2px 0;
  }

  span:first-child {
    margin-right: 5px;
  }

  span:last-child {
    color: ${COLORS.turbo};
    cursor: pointer;
  }

  span:last-child:hover {
    text-decoration: underline;
  }
`;

export const SaveButton = styled.button`
  margin-top: 23px;
  width: 118px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.corn};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: ${COLORS.black};
  border: none;
  border-radius: 4px;

  &:hover {
    background: ${COLORS.turbo};
  }
`;

export const DeleteAccountBlock = styled.div`
  margin-top: 29px;
  display: flex;
  flex-direction: column;
`;

export const DeleteWarning = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 60%;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const DeleteButton = styled.div`
  margin-top: 15px;
  color: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
