// GLTF 1.0 EXTENSION: KHR_binary_glTF
// https://github.com/KhronosGroup/glTF/tree/master/extensions/1.0/Khronos/KHR_binary_glTF
/* eslint-disable camelcase */

import type { GLTF, GLTF_KHR_binary_glTF } from '../../interfaces/model-parse/gltf-types';

import { GLTFSceneGraph } from '../gltf-scene-graph';

const KHR_BINARY_GLTF = 'KHR_binary_glTF';

/** Extension name */
export const name = KHR_BINARY_GLTF;

export function preprocess(gltfData: {json: GLTF}): void {
  const gltfSceneGraph = new GLTFSceneGraph(gltfData);

  // Note: json.buffers.binary_glTF also needs to be replaced
  // This is currently done during gltf normalization

  // Image and shader nodes can have the extension
  // https://github.com/KhronosGroup/glTF/blob/master/extensions/1.0/Khronos/KHR_binary_glTF/schema/image.KHR_binary_glTF.schema.json
  for (const image of gltfSceneGraph.json.images || []) {
    const extension = gltfSceneGraph.getObjectExtension<GLTF_KHR_binary_glTF>(
      image,
      KHR_BINARY_GLTF
    );
    // The data in the extension is valid as glTF 2.0 data inside the object, so just copy it in
    if (extension) {
      Object.assign(image, extension);
    }
    gltfSceneGraph.removeObjectExtension(image, KHR_BINARY_GLTF);
  }

  // TODO shaders - At least traverse and throw error if used?
  // https://github.com/KhronosGroup/glTF/blob/master/extensions/1.0/Khronos/KHR_binary_glTF/schema/shader.KHR_binary_glTF.schema.json

  // glTF v1 one files have a partially formed URI field that is not expected in (and causes problems in) 2.0
  if (gltfSceneGraph.json.buffers && gltfSceneGraph.json.buffers[0]) {
    delete gltfSceneGraph.json.buffers[0].uri;
  }

  // Remove the top-level extension as it has now been removed from all nodes
  gltfSceneGraph.removeExtension(KHR_BINARY_GLTF);
}
