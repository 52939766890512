import * as THREE from 'three';
import { BaseLoadModelStrategy } from './base-load-model.strategy';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';
import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader';

export class UsdzLoadModelStrategy extends BaseLoadModelStrategy {
  private usdzLoader: USDZLoader;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    super(renderer, onModelResourcesLoaded);

    this.usdzLoader = new USDZLoader(this.loadingManager);
  }

  public async loadModel({ path, assets }: LoadModelData): Promise<LoadModelResult> {
    this.setLoadingContent(assets);

    const model = new THREE.Group();
    const modelMesh = await this.usdzLoader.loadAsync(path);

    model.add(modelMesh);

    this.setLoadingContent([]);

    return {
      gltf: null,
      model
    };
  }
}
