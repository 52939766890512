import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { AuthState, EUserRole, PlanFeature, User } from 'shared/types';
import { AppDispatch } from '../index';
import { clearModelLocalStorage, removeModelDataFromSessionStorage } from 'utils/storage-utils';
import { clearViewerDataState } from './viewerDataReducer';
import { setIsPageWithScene } from './sidebarReducer';
import { getUserFeatures } from 'services/api/subscriptionsService';
import {
  clearSubscriptionsData,
  fetchCurrentSubscription,
  fetchCurrentUserCard
} from './subscriptionsReducer';
import { clearUserTeams, fetchUserTeams } from './teamsReducer';
import { checkIsIFrame } from 'utils/helper-utils';

const initialState: AuthState = {
  isAuth: false,
  user: null,
  features: [],
  isAdmin: false,
  isMasterAdmin: false
};

// Actions

export const loginUserSuccess = createAction<User | null>('auth/loginUserSuccess');
export const setUserFeatures = createAction<PlanFeature[]>('auth/setUserFeatures');
export const clearUserData = createAction('auth/clearUserData');

// Reducer

export const authReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(loginUserSuccess, (state, { payload }): void => {
      state.isAuth = true;
      state.user = payload;
      state.isAdmin = payload?.role === EUserRole.ADMIN;
      state.isMasterAdmin = payload?.role === EUserRole.MASTER_ADMIN;
    })

    .addCase(setUserFeatures, (state, { payload }): void => {
      state.features = payload;
    })

    .addCase(clearUserData, (): AuthState => initialState);
});

// Thunks

export const loginUser = createAsyncThunk<Promise<void>, { user: User }, { dispatch: AppDispatch }>(
  'auth/loginUser',
  async ({ user }, { dispatch }): Promise<void> => {
    const isAdmin = user.role === EUserRole.ADMIN;
    const isMasterAdmin = user.role === EUserRole.MASTER_ADMIN;
    if (!isAdmin && !isMasterAdmin) {
      await dispatch(fetchCurrentSubscription());
    }
    await dispatch(fetchCurrentUserCard());
    await dispatch(fetchUserTeams());
    await dispatch(fetchUserFeatures());
    dispatch(loginUserSuccess(user));
  }
);

export const logoutUser = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  'auth/logout',
  (_, { dispatch }): void => {
    if (!checkIsIFrame()) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
    }
    dispatch(clearViewerDataState());
    dispatch(clearSubscriptionsData());
    dispatch(clearUserTeams());
    dispatch(clearUserData());
    clearModelLocalStorage();
    removeModelDataFromSessionStorage();
    dispatch(setIsPageWithScene(false));
  }
);

export const fetchUserFeatures = createAsyncThunk<Promise<void>, void, { dispatch: AppDispatch }>(
  'auth/fetchUserFeatures',
  async (_, { dispatch }): Promise<void> => {
    const userFeatures = (await getUserFeatures()).data;
    dispatch(setUserFeatures(userFeatures));
  }
);
