import React from 'react';

const ArrowDownIcon = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 10 8'
        preserveAspectRatio='xMaxYMax meet'
      >
        <path d='M5 8L-2.54292e-07 -3.97391e-07L10 4.76837e-07L5 8Z' fill='currentColor' />
      </svg>
    </div>
  );
};

export default ArrowDownIcon;
