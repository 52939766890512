import React from 'react';

const LogoutIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 10 12'
        preserveAspectRatio='xMidYMax meet'
        fill='currentColor'
      >
        <path d='M1.2 12.0002C0.866667 12.0002 0.583333 11.8835 0.35 11.6502C0.116667 11.4168 0 11.1335 0 10.8002V1.5335C0 1.20016 0.116667 0.91683 0.35 0.683496C0.583333 0.450163 0.866667 0.333496 1.2 0.333496H5.68333V1.3335H1.2C1.15556 1.3335 1.11111 1.35572 1.06667 1.40016C1.02222 1.44461 1 1.48905 1 1.5335V10.8002C1 10.8446 1.02222 10.8891 1.06667 10.9335C1.11111 10.9779 1.15556 11.0002 1.2 11.0002H5.68333V12.0002H1.2ZM8.35 9.01683L7.66667 8.2835L9.28333 6.66683H3.68333V5.66683H9.28333L7.66667 4.05016L8.35 3.31683L11.2 6.16683L8.35 9.01683Z' />
      </svg>
    </div>
  );
};

export default LogoutIcon;
