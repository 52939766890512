import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DimensionTextPlacement } from 'shared/interfaces';

const valueScale = 100;
const lineLedge = 3;
const lineSerifDown = 3;
const lineSerifUp = 5;
const linewidth = 1.2;
const textOffset = 4;
const fontSize = 10;
const textPlacement = DimensionTextPlacement.down;

const getScaledValue = (value: number): number => value * valueScale;

export const Container = styled.div`
  position: relative;
  pointer-events: none;
  user-select: none;

  &:not(:has(*)) {
    display: none;
  }
`;

export const Face = styled.div`
  position: absolute;
  width: 1px;
  height: ${getScaledValue(linewidth + lineSerifDown)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: ${getScaledValue(textOffset)}px;
  line-height: 100%; //warn: don't change
  font-family: 'Inter', sans-serif;
  font-size: ${getScaledValue(fontSize)}px;
  font-weight: 600;
  color: ${COLORS.black};
  text-align: center;
  user-select: none;
  pointer-events: none;
  overflow: visible;

  &.hidden {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
  }
`;

export const Arrow = styled.div`
  position: relative;
  top: ${getScaledValue(lineSerifUp + lineSerifDown + textOffset - linewidth * 3)}px;
  width: calc(${getScaledValue(lineLedge * 2 + linewidth)}px + 100%);
  height: 0;
  border-top: ${getScaledValue(linewidth / 2)}px solid black;
  border-bottom: ${getScaledValue(linewidth / 2)}px solid black;
  user-select: none;
  pointer-events: none;

  &::before,
  &::after {
    position: absolute;
    top: -${getScaledValue(lineSerifUp)}px;
    bottom: -${getScaledValue(lineSerifDown)}px;
    left: ${getScaledValue(lineLedge)}px;
    width: ${getScaledValue(linewidth)}px;
    content: '';
    background-color: black;
    user-select: none;
    pointer-events: none;
  }

  &::after {
    right: ${getScaledValue(lineLedge)}px;
    left: unset;
  }
`;

export const DimensionValue = styled.div`
  position: relative;
  top: ${getScaledValue(lineSerifUp + lineSerifDown + textOffset - linewidth * 3)}px;
  font-family: inherit;
  user-select: none;
  pointer-events: none;
`;
