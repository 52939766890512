import React, { useEffect, useMemo, useState } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import Spinner from 'assets/images/spinner.svg';
import { FullPageContainer } from 'shared/styles';
import { getCurrentUser } from 'services/api/authService';
import { loginUser } from 'services/store/reducers/authReducer';
import { AuthState } from 'shared/types';
import { useHistory } from 'react-router';
import {
  getLocationStateFromSessionStorage,
  setTeamInviteToLocalStorage
} from 'utils/storage-utils';
import { checkIsIFrame } from 'utils/helper-utils';

const GuardedRoute: React.FC<RouteProps> = ({ ...routeProps }): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search]
  );
  const invited = params.get('invited');
  const { isAuth } = useAppSelector((state): AuthState => state.auth);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect((): void => {
    const fetchUser = async (): Promise<void> => {
      try {
        const response = await getCurrentUser();
        await dispatch(loginUser({ user: response.data }));
      } catch (e) {
        history.push('/');
      }
    };

    if (!isAuth) {
      const token = checkIsIFrame() ? '' : localStorage.getItem('token');
      const locationState = getLocationStateFromSessionStorage();
      if (!!token) {
        fetchUser();
      } else {
        if (!!invited) {
          setTeamInviteToLocalStorage(location.pathname);
          history.push('/?teamInvite=1');
        } else {
          !!locationState.current ? history.go(-1) : history.push('/');
        }
      }
    } else {
      setIsChecked(true);
    }
  }, [dispatch, history, invited, isAuth, location]);

  return isChecked ? (
    <Route {...routeProps} />
  ) : (
    <FullPageContainer>
      <img src={Spinner} className='spinner' alt='Loading' />
    </FullPageContainer>
  );
};

export default GuardedRoute;
