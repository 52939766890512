import React from 'react';
import * as Styled from './styles';
import pkg from '../../../../package.json';
import { useAppSelector } from 'shared/hooks';
import { RootState } from 'services/store';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const Footer = (): JSX.Element => {
  const store = useAppSelector((store): RootState => store);
  const { isAuth } = store.auth;
  const { isPageWithScene } = store.sidebar;
  const { isZenMode, model } = store.viewerData;

  return (
    <Styled.FooterContainer
      isAuth={isAuth}
      isPageWithScene={isPageWithScene}
      isZenMode={isZenMode}
      isModelPage={!!model}
    >
      <Styled.FooterLeftSide>
        <Styled.Copyright>{moment().year()} © glbee</Styled.Copyright>
        <Styled.Version>{`Beta ${pkg.version}`}</Styled.Version>
      </Styled.FooterLeftSide>
      <Styled.FooterMenu>
        <a href={`${process.env.REACT_APP_URL}/blog`} rel='noreferrer' id='footer-blog-button'>
          Blog
        </a>
        <NavLink to='/pricing' id='footer-pricing-button'>
          Pricing
        </NavLink>
        <NavLink to='/contact-us' id='footer-support-button'>
          Support
        </NavLink>
        <a
          href={`${process.env.REACT_APP_URL}/privacy-policy?auth=${isAuth ? '1' : '0'}`}
          target='_blank'
          rel='noreferrer'
          id='footer-privacy-policy-button'
        >
          Privacy Policy
        </a>
        <a
          href={`${process.env.REACT_APP_URL}/terms-of-use?auth=${isAuth ? '1' : '0'}`}
          target='_blank'
          rel='noreferrer'
          id='footer-terms-button'
        >
          Terms of Use
        </a>
      </Styled.FooterMenu>
    </Styled.FooterContainer>
  );
};

export default Footer;
