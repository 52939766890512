import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ModalLogin, ModelViewer, NoData } from 'shared/components';
import { useModelData } from 'shared/hooks/data-hooks';
import { AuthState, ViewerDataState } from 'shared/types';
import { ModelPageContainer } from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks/store-hooks';
import { showEmbeddedModelPrompt, showModal } from 'services/store/reducers/modalReducer';
import {
  clearEmbeddedModelSettings,
  removeModelDataFromSessionStorage,
  setInviteToLocalStorage,
  setIsViewerClosed
} from 'utils/storage-utils';
import { setIsViewerLoading, stopLoader } from 'services/store/reducers/loaderReducer';
import { setBranding } from 'services/store/reducers/brandingReducer';
import { DEFAULT_BRANDING, DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';
import {
  clearViewerDataState,
  setIsViewerPage,
  stopZenMode
} from 'services/store/reducers/viewerDataReducer';
import { clearModelFeatureAccess } from 'services/store/reducers/modelFeatureAccessReducer';
import { setIsPageWithScene } from 'services/store/reducers/sidebarReducer';
import { setInitialPermissionsState } from 'services/store/reducers/modelPermissionsReducer';
import { stopArMode } from 'services/store/reducers/arModeReducer';
import { extractDomainFromURL } from 'utils/form-utils';
import { NO_MODEL, RESTRICT_VIEWING } from 'shared/constants/notifications';
import { getDomainsWhitelist } from 'services/api/embeddedSettingsService';
import { MODEL_NOT_FOUND } from 'shared/constants/errors';
import { checkIsIFrame } from 'utils/helper-utils';
import { setThemeCSSVariables } from 'utils/theme-utils';

type QueryParams = {
  modelId: string;
};

type LocationState = {
  isArModeClickAction?: boolean;
};

const ModelPage = (): React.ReactElement => {
  const isPageRefresh = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const params = new URLSearchParams(location.search);
  const isArModeLink = params.get('ar');
  const inviteCode = params.get('invite_code');
  const isEmbeddedModel = params.get('embed');
  const transparentParam = params.get('transparent');
  const isTransparentBackground = !!transparentParam && !!+transparentParam;
  const isArModeClickAction = location.state?.isArModeClickAction || !!isArModeLink;
  const { modelId } = useParams<QueryParams>();
  const { model, modelFileType, modelMtls } = useAppSelector(
    (store): ViewerDataState => store.viewerData
  );
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const { fetchModel, isModelLoading } = useModelData(!!isEmbeddedModel, isTransparentBackground);

  useEffect((): void => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect((): void => {
    window.onbeforeunload = (): void => {
      isPageRefresh.current = history.action !== 'POP';
    };
  }, [history]);

  useEffect((): (() => void) => {
    return (): void => {
      setIsViewerClosed(true);
      dispatch(setIsViewerLoading(false));
      dispatch(clearViewerDataState());
      dispatch(clearModelFeatureAccess());
      dispatch(setIsPageWithScene(false));
      dispatch(setIsViewerPage(false));
      dispatch(setInitialPermissionsState());
      dispatch(stopZenMode());
      dispatch(stopArMode());
      dispatch(setBranding(DEFAULT_BRANDING));
      setThemeCSSVariables(DEFAULT_THEME_CSS_VARIABLES);
      clearEmbeddedModelSettings();
    };
  }, [dispatch]);

  useEffect(
    (): (() => void) => (): void => {
      if (!isPageRefresh.current) {
        removeModelDataFromSessionStorage();
      }
      window.onbeforeunload = null;
      dispatch(stopLoader());
    },
    [dispatch]
  );

  useEffect((): void => {
    (async (): Promise<void> => {
      try {
        if (!inviteCode) {
          removeModelDataFromSessionStorage();
          if (!!isEmbeddedModel) {
            const EVERYONE_SYMBOL = '*';
            const allowedDomains = (await getDomainsWhitelist(modelId)).data;
            const originDomain = checkIsIFrame()
              ? extractDomainFromURL(document.referrer)
              : extractDomainFromURL(window.location.origin);
            const hasEveryonePoint = allowedDomains.some(
              (domain): boolean => domain.domainName === EVERYONE_SYMBOL
            );
            const isAllowed =
              hasEveryonePoint ||
              allowedDomains.some(({ domainName }): boolean => domainName === originDomain);
            if (!isAllowed) throw new Error();
          }
          await fetchModel(modelId, isArModeClickAction);
        }
      } catch (e) {
        if (!!isEmbeddedModel) {
          dispatch(
            showEmbeddedModelPrompt({
              promptContent: e?.message === MODEL_NOT_FOUND ? NO_MODEL : RESTRICT_VIEWING
            })
          );
        }
      }
    })();
  }, [fetchModel, modelId, inviteCode, isAuth, isArModeClickAction, dispatch, isEmbeddedModel]);

  useEffect((): void => {
    if (!!inviteCode) {
      setInviteToLocalStorage({ modelId, inviteCode });
      dispatch(showModal(<ModalLogin />));
      history.push('/');
    }
  }, [dispatch, history, inviteCode, modelId]);

  return (
    <ModelPageContainer>
      {!!model && !!modelFileType ? (
        <ModelViewer
          model={model}
          modelFileType={modelFileType}
          modelMtls={modelMtls}
          isArModeClickAction={isArModeClickAction}
        />
      ) : (
        <>{!isModelLoading && <NoData />}</>
      )}
    </ModelPageContainer>
  );
};

export default ModelPage;
