import React from 'react';

export class ShortClickHandlerService {
  private timeStamp: number = 0;
  private clientX: number = 0;
  private clientY: number = 0;

  public handleShortClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent,
    callback: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent) => void
  ): void {
    switch (event.type) {
      case 'mousedown':
        this.timeStamp = event.timeStamp;
        this.clientX = event.clientX;
        this.clientY = event.clientY;
        break;

      case 'mouseup':
        const isShortClick =
          event.detail === 1 &&
          event.timeStamp - this.timeStamp <= 250 &&
          event.clientX === this.clientX &&
          event.clientY === this.clientY;

        if (isShortClick) {
          callback(event);
        }
        break;

      default:
        break;
    }
  }
}
