import * as THREE from 'three';
import { ESampleModelName, Note } from '../types';
import { sampleCommentsAuthors } from './sample-comments-authors';

export const sampleComments: Record<ESampleModelName, Note[]> = {
  [ESampleModelName.car]: [
    {
      author: sampleCommentsAuthors.JamesGlbee,
      authorId: sampleCommentsAuthors.JamesGlbee.id,
      commentNum: 1,
      commentsCount: 0,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
      id: 3000,
      index: 1,
      mentions: [
        {
          modelId: '-1',
          commentId: 3000,
          personId: sampleCommentsAuthors.HaroonGlbee.id,
          seenComment: true,
          person: sampleCommentsAuthors.HaroonGlbee
        }
      ],
      threadId: null,
      title: '',
      message: `Great work on this <@${sampleCommentsAuthors.HaroonGlbee.email}>`,
      comments: [
        {
          author: sampleCommentsAuthors.HaroonGlbee,
          authorId: sampleCommentsAuthors.HaroonGlbee.id,
          commentNum: 2,
          commentsCount: 0,
          createdAt: new Date().toJSON(),
          updatedAt: new Date().toJSON(),
          id: 3001,
          index: 2,
          mentions: [
            {
              modelId: '-1',
              commentId: 3001,
              personId: sampleCommentsAuthors.JamesGlbee.id,
              seenComment: true,
              person: sampleCommentsAuthors.JamesGlbee
            }
          ],
          threadId: 3000,
          title: '',
          message: `Thanks <@${sampleCommentsAuthors.JamesGlbee.email}>, I'm really happy how this came out!`,
          comments: [],
          params: {
            parent_id: 11,
            path: '//internalObjectWrapper/Scene/car/Mesh_3',
            face: {
              a: 7428,
              b: 7430,
              c: 7431,
              normal: {
                x: -0.11129789453373383,
                y: 0.03801895992905957,
                z: -0.9930595839919528
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: -109.89248896032255,
              y: 4.886292962843755,
              z: -82.63849059411403
            },
            camera: {
              position: {
                x: -1.9121384799367547,
                y: 2.450810417531925,
                z: 1.5288082714526863
              },
              rotation: {
                x: -1.0130625327728615,
                y: -0.5847450782661059,
                z: -0.7243996097598967
              },
              target: {
                x: 0,
                y: 0,
                z: 0
              }
            }
          }
        }
      ],
      params: {
        parent_id: 11,
        path: '//internalObjectWrapper/Scene/car/Mesh_3',
        face: {
          a: 7428,
          b: 7430,
          c: 7431,
          normal: {
            x: -0.11129789453373383,
            y: 0.03801895992905957,
            z: -0.9930595839919528
          } as THREE.Vector3,
          materialIndex: 0
        },
        position: {
          x: -109.89248896032255,
          y: 4.886292962843755,
          z: -82.63849059411403
        },
        camera: {
          position: {
            x: -1.9121384799367547,
            y: 2.450810417531925,
            z: 1.5288082714526863
          },
          rotation: {
            x: -1.0130625327728615,
            y: -0.5847450782661059,
            z: -0.7243996097598967
          },
          target: {
            x: 0,
            y: 0,
            z: 0
          }
        }
      }
    },
    {
      author: sampleCommentsAuthors.JoGlbee,
      authorId: sampleCommentsAuthors.JoGlbee.id,
      commentNum: 3,
      commentsCount: 0,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
      id: 3002,
      index: 3,
      mentions: [
        {
          modelId: '-1',
          commentId: 3002,
          personId: sampleCommentsAuthors.JamesGlbee.id,
          seenComment: true,
          person: sampleCommentsAuthors.JamesGlbee
        }
      ],
      threadId: null,
      title: '',
      message: `<@${sampleCommentsAuthors.JamesGlbee.email}>, could we darken the shade of the rear view lights?`,
      comments: [
        {
          author: sampleCommentsAuthors.JamesGlbee,
          authorId: sampleCommentsAuthors.JamesGlbee.id,
          commentNum: 4,
          commentsCount: 0,
          createdAt: '',
          updatedAt: new Date().toJSON(),
          id: 3003,
          index: 4,
          mentions: [],
          threadId: 3002,
          title: '',
          message: `Sure thing, will get this done by EOP. `,
          comments: [],
          params: {
            parent_id: 11,
            path: '//internalObjectWrapper/Scene/car/Mesh_3',
            face: {
              a: 1868,
              b: 10504,
              c: 10505,
              normal: {
                x: 0.9886194877332917,
                y: -0.1445451686191588,
                z: -0.041691758212181
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: 147.09773381254612,
              y: -53.88336125928334,
              z: -53.24530891549929
            },
            camera: {
              position: {
                x: 1.5844016383916313,
                y: 0.426789384705085,
                z: 0.06373209344651248
              },
              rotation: {
                x: -0.7791844338755266,
                y: 1.2840736289787942,
                z: 0.7583570546133253
              },
              target: {
                x: -0.3055405957441172,
                y: 0.035214356781842385,
                z: -0.3327397073432996
              }
            }
          }
        }
      ],
      params: {
        parent_id: 11,
        path: '//internalObjectWrapper/Scene/car/Mesh_3',
        face: {
          a: 1868,
          b: 10504,
          c: 10505,
          normal: {
            x: 0.9886194877332917,
            y: -0.1445451686191588,
            z: -0.041691758212181
          } as THREE.Vector3,
          materialIndex: 0
        },
        position: {
          x: 147.09773381254612,
          y: -53.88336125928334,
          z: -53.24530891549929
        },
        camera: {
          position: {
            x: 1.5844016383916313,
            y: 0.426789384705085,
            z: 0.06373209344651248
          },
          rotation: {
            x: -0.7791844338755266,
            y: 1.2840736289787942,
            z: 0.7583570546133253
          },
          target: {
            x: -0.3055405957441172,
            y: 0.035214356781842385,
            z: -0.3327397073432996
          }
        }
      }
    }
  ],
  [ESampleModelName.bee]: [
    {
      author: sampleCommentsAuthors.JavierGlbee,
      authorId: sampleCommentsAuthors.JavierGlbee.id,
      commentNum: 1,
      commentsCount: 0,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
      id: 4000,
      index: 1,
      mentions: [],
      threadId: null,
      title: '',
      message: `Super cute design!`,
      comments: [],
      params: {
        parent_id: 11,
        path: '//internalObjectWrapper/Root_Scene/RootNode/Bee/fur_layers/fur8',
        face: {
          a: 153,
          b: 134,
          c: 152,
          normal: {
            x: 0.11876880917723007,
            y: 0.05161301095828767,
            z: 0.9915795818119908
          } as THREE.Vector3,
          materialIndex: 0
        },
        position: {
          x: 0.012533371720829082,
          y: 0.10271323436515578,
          z: 0.0722503746049471
        },
        camera: {
          position: {
            x: 0.7707978066728157,
            y: 0.6884229480737035,
            z: 3.3063491324713175
          },
          rotation: {
            x: -0.20527950094538658,
            y: 0.2243884248434226,
            z: 0.04629627391797871
          },
          target: {
            x: 0,
            y: 0,
            z: 0
          }
        }
      }
    },
    {
      author: sampleCommentsAuthors.PaulGlbee,
      authorId: sampleCommentsAuthors.PaulGlbee.id,
      commentNum: 2,
      commentsCount: 0,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
      id: 4001,
      index: 2,
      mentions: [
        {
          modelId: '-1',
          commentId: 4001,
          personId: sampleCommentsAuthors.JesseGlbee.id,
          seenComment: true,
          person: sampleCommentsAuthors.JesseGlbee
        }
      ],
      threadId: null,
      title: '',
      message: `Could we increase the transparency of the wings on this <@${sampleCommentsAuthors.JesseGlbee.email}>?`,
      comments: [
        {
          author: sampleCommentsAuthors.JesseGlbee,
          authorId: sampleCommentsAuthors.JesseGlbee.id,
          commentNum: 3,
          commentsCount: 0,
          createdAt: new Date().toJSON(),
          updatedAt: new Date().toJSON(),
          id: 4002,
          index: 3,
          mentions: [
            {
              modelId: '-1',
              commentId: 4002,
              personId: sampleCommentsAuthors.PaulGlbee.id,
              seenComment: true,
              person: sampleCommentsAuthors.JamesGlbee
            }
          ],
          threadId: 4001,
          title: '',
          message: `No problem <@${sampleCommentsAuthors.PaulGlbee.email}>, I've made them 25% more transparent`,
          comments: [],
          params: {
            parent_id: 20,
            path: '//internalObjectWrapper/Root_Scene/RootNode/Bee/wing_left',
            face: {
              a: 83,
              b: 77,
              c: 75,
              normal: {
                x: 0.12759171763182886,
                y: -0.032714191096694115,
                z: 0.9912871104239421
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: 0.025622135523831698,
              y: 0.21820847402263605,
              z: 0.028252170512915405
            },
            camera: {
              position: {
                x: 0.04301355984237008,
                y: 2.8163355660432026,
                z: 2.0165326213849837
              },
              rotation: {
                x: -0.9494040918485935,
                y: 0.012417264274231031,
                z: 0.017340051411867886
              },
              target: {
                x: 0,
                y: 0,
                z: 0
              }
            }
          }
        },
        {
          author: sampleCommentsAuthors.PaulGlbee,
          authorId: sampleCommentsAuthors.PaulGlbee.id,
          commentNum: 4,
          commentsCount: 0,
          createdAt: new Date().toJSON(),
          updatedAt: new Date().toJSON(),
          id: 4003,
          index: 4,
          mentions: [],
          threadId: 4001,
          title: '',
          message: `That looks much better!`,
          comments: [],
          params: {
            parent_id: 20,
            path: '//internalObjectWrapper/Root_Scene/RootNode/Bee/wing_left',
            face: {
              a: 83,
              b: 77,
              c: 75,
              normal: {
                x: 0.12759171763182886,
                y: -0.032714191096694115,
                z: 0.9912871104239421
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: 0.025622135523831698,
              y: 0.21820847402263605,
              z: 0.028252170512915405
            },
            camera: {
              position: {
                x: 0.04301355984237008,
                y: 2.8163355660432026,
                z: 2.0165326213849837
              },
              rotation: {
                x: -0.9494040918485935,
                y: 0.012417264274231031,
                z: 0.017340051411867886
              },
              target: {
                x: 0,
                y: 0,
                z: 0
              }
            }
          }
        }
      ],
      params: {
        parent_id: 20,
        path: '//internalObjectWrapper/Root_Scene/RootNode/Bee/wing_left',
        face: {
          a: 83,
          b: 77,
          c: 75,
          normal: {
            x: 0.12759171763182886,
            y: -0.032714191096694115,
            z: 0.9912871104239421
          } as THREE.Vector3,
          materialIndex: 0
        },
        position: {
          x: 0.025622135523831698,
          y: 0.21820847402263605,
          z: 0.028252170512915405
        },
        camera: {
          position: {
            x: 0.04301355984237008,
            y: 2.8163355660432026,
            z: 2.0165326213849837
          },
          rotation: {
            x: -0.9494040918485935,
            y: 0.012417264274231031,
            z: 0.017340051411867886
          },
          target: {
            x: 0,
            y: 0,
            z: 0
          }
        }
      }
    }
  ],
  [ESampleModelName.viking_scene]: [
    {
      author: sampleCommentsAuthors.GabrielaGlbee,
      authorId: sampleCommentsAuthors.GabrielaGlbee.id,
      commentNum: 1,
      commentsCount: 0,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
      id: 5000,
      index: 1,
      mentions: [
        {
          modelId: '-1',
          commentId: 5000,
          personId: sampleCommentsAuthors.AdrianGlbee.id,
          seenComment: true,
          person: sampleCommentsAuthors.AdrianGlbee
        }
      ],
      threadId: null,
      title: '',
      message: `This is going to be perfect for our new game, great work <@${sampleCommentsAuthors.AdrianGlbee.email}>`,
      comments: [
        {
          author: sampleCommentsAuthors.AdrianGlbee,
          authorId: sampleCommentsAuthors.AdrianGlbee.id,
          commentNum: 2,
          commentsCount: 0,
          createdAt: new Date().toJSON(),
          updatedAt: new Date().toJSON(),
          id: 5001,
          index: 2,
          mentions: [
            {
              modelId: '-1',
              commentId: 5001,
              personId: sampleCommentsAuthors.GabrielaGlbee.id,
              seenComment: true,
              person: sampleCommentsAuthors.GabrielaGlbee
            }
          ],
          threadId: 5000,
          title: '',
          message: `Thanks <@${sampleCommentsAuthors.GabrielaGlbee.email}>!`,
          comments: [],
          params: {
            parent_id: 5,
            path: '//internalObjectWrapper/Root_Scene/RootNode/viking_room/viking_room_1',
            face: {
              a: 3387,
              b: 3389,
              c: 3390,
              normal: {
                x: 0.06433625734302849,
                y: 0.9977903446780599,
                z: 0.01659138506364827
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: 0.010520022461954659,
              y: 0.16542467523309173,
              z: -0.050898267360435956
            },
            camera: {
              position: {
                x: 1.510999381787375,
                y: 2.5398905052515843,
                z: 1.8071627180669225
              },
              rotation: {
                x: -0.952385862163146,
                y: 0.45135801120579244,
                z: 0.5499552561638549
              },
              target: {
                x: 0,
                y: 0,
                z: 0
              }
            }
          }
        }
      ],
      params: {
        parent_id: 5,
        path: '//internalObjectWrapper/Root_Scene/RootNode/viking_room/viking_room_1',
        face: {
          a: 3387,
          b: 3389,
          c: 3390,
          normal: {
            x: 0.06433625734302849,
            y: 0.9977903446780599,
            z: 0.01659138506364827
          } as THREE.Vector3,
          materialIndex: 0
        },
        position: {
          x: 0.010520022461954659,
          y: 0.16542467523309173,
          z: -0.050898267360435956
        },
        camera: {
          position: {
            x: 1.510999381787375,
            y: 2.5398905052515843,
            z: 1.8071627180669225
          },
          rotation: {
            x: -0.952385862163146,
            y: 0.45135801120579244,
            z: 0.5499552561638549
          },
          target: {
            x: 0,
            y: 0,
            z: 0
          }
        }
      }
    },
    {
      author: sampleCommentsAuthors.LukeGlbee,
      authorId: sampleCommentsAuthors.LukeGlbee.id,
      commentNum: 3,
      commentsCount: 0,
      createdAt: new Date().toJSON(),
      updatedAt: new Date().toJSON(),
      id: 5002,
      index: 3,
      mentions: [
        {
          modelId: '-1',
          commentId: 5002,
          personId: sampleCommentsAuthors.GabrielaGlbee.id,
          seenComment: true,
          person: sampleCommentsAuthors.GabrielaGlbee
        }
      ],
      threadId: null,
      title: '',
      message: `Could you increase the size of this barrel <@${sampleCommentsAuthors.GabrielaGlbee.email}>?`,
      comments: [
        {
          author: sampleCommentsAuthors.GabrielaGlbee,
          authorId: sampleCommentsAuthors.GabrielaGlbee.id,
          commentNum: 4,
          commentsCount: 0,
          createdAt: new Date().toJSON(),
          updatedAt: new Date().toJSON(),
          id: 5003,
          index: 4,
          mentions: [],
          threadId: 5002,
          title: '',
          message: `Working on it now`,
          comments: [],
          params: {
            parent_id: 5,
            path: '//internalObjectWrapper/Root_Scene/RootNode/viking_room/viking_room_1',
            face: {
              a: 3279,
              b: 3303,
              c: 3277,
              normal: {
                x: 0.5651268137748925,
                y: 0.2976844282008953,
                z: 0.7694255425701332
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: -0.06816758076290629,
              y: 0.020187324162627866,
              z: 0.11348361640814025
            },
            camera: {
              position: {
                x: -0.0015134547338584979,
                y: 0.9164547113483283,
                z: 1.6321693427973334
              },
              rotation: {
                x: -0.5116255907466724,
                y: -0.0008085289912711406,
                z: -0.0004539847993984315
              },
              target: {
                x: 0,
                y: 0,
                z: 0
              }
            }
          }
        },
        {
          author: sampleCommentsAuthors.LukeGlbee,
          authorId: sampleCommentsAuthors.LukeGlbee.id,
          commentNum: 5,
          commentsCount: 0,
          createdAt: new Date().toJSON(),
          updatedAt: new Date().toJSON(),
          id: 5004,
          index: 5,
          mentions: [],
          threadId: 5002,
          title: '',
          message: `Thanks Gabby, can we also lighten it up a little too? It's quite dark atm.`,
          comments: [],
          params: {
            parent_id: 5,
            path: '//internalObjectWrapper/Root_Scene/RootNode/viking_room/viking_room_1',
            face: {
              a: 3279,
              b: 3303,
              c: 3277,
              normal: {
                x: 0.5651268137748925,
                y: 0.2976844282008953,
                z: 0.7694255425701332
              } as THREE.Vector3,
              materialIndex: 0
            },
            position: {
              x: -0.06816758076290629,
              y: 0.020187324162627866,
              z: 0.11348361640814025
            },
            camera: {
              position: {
                x: -0.0015134547338584979,
                y: 0.9164547113483283,
                z: 1.6321693427973334
              },
              rotation: {
                x: -0.5116255907466724,
                y: -0.0008085289912711406,
                z: -0.0004539847993984315
              },
              target: {
                x: 0,
                y: 0,
                z: 0
              }
            }
          }
        }
      ],
      params: {
        parent_id: 5,
        path: '//internalObjectWrapper/Root_Scene/RootNode/viking_room/viking_room_1',
        face: {
          a: 3279,
          b: 3303,
          c: 3277,
          normal: {
            x: 0.5651268137748925,
            y: 0.2976844282008953,
            z: 0.7694255425701332
          } as THREE.Vector3,
          materialIndex: 0
        },
        position: {
          x: -0.06816758076290629,
          y: 0.020187324162627866,
          z: 0.11348361640814025
        },
        camera: {
          position: {
            x: -0.0015134547338584979,
            y: 0.9164547113483283,
            z: 1.6321693427973334
          },
          rotation: {
            x: -0.5116255907466724,
            y: -0.0008085289912711406,
            z: -0.0004539847993984315
          },
          target: {
            x: 0,
            y: 0,
            z: 0
          }
        }
      }
    }
  ]
};
