import React from 'react';

const SettingsIcon = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 15 15'
        preserveAspectRatio='xMidYMax meet'
        fill='currentColor'
      >
        <path d='M5.78125 13.75L5.53125 11.75C5.39583 11.6979 5.26833 11.6354 5.14875 11.5625C5.02875 11.4896 4.91146 11.4115 4.79688 11.3281L2.9375 12.1094L1.21875 9.14062L2.82812 7.92188C2.81771 7.84896 2.8125 7.77854 2.8125 7.71062V7.28875C2.8125 7.22125 2.81771 7.15104 2.82812 7.07812L1.21875 5.85938L2.9375 2.89062L4.79688 3.67188C4.91146 3.58854 5.03125 3.51042 5.15625 3.4375C5.28125 3.36458 5.40625 3.30208 5.53125 3.25L5.78125 1.25H9.21875L9.46875 3.25C9.60417 3.30208 9.73187 3.36458 9.85188 3.4375C9.97146 3.51042 10.0885 3.58854 10.2031 3.67188L12.0625 2.89062L13.7812 5.85938L12.1719 7.07812C12.1823 7.15104 12.1875 7.22125 12.1875 7.28875V7.71062C12.1875 7.77854 12.1771 7.84896 12.1562 7.92188L13.7656 9.14062L12.0469 12.1094L10.2031 11.3281C10.0885 11.4115 9.96875 11.4896 9.84375 11.5625C9.71875 11.6354 9.59375 11.6979 9.46875 11.75L9.21875 13.75H5.78125ZM7.53125 9.6875C8.13542 9.6875 8.65104 9.47396 9.07812 9.04688C9.50521 8.61979 9.71875 8.10417 9.71875 7.5C9.71875 6.89583 9.50521 6.38021 9.07812 5.95312C8.65104 5.52604 8.13542 5.3125 7.53125 5.3125C6.91667 5.3125 6.39833 5.52604 5.97625 5.95312C5.55458 6.38021 5.34375 6.89583 5.34375 7.5C5.34375 8.10417 5.55458 8.61979 5.97625 9.04688C6.39833 9.47396 6.91667 9.6875 7.53125 9.6875Z' />
      </svg>
    </div>
  );
};

export default SettingsIcon;
