import ApiService from './api';
import { AxiosResponse } from 'axios';
import { ENotificationPreferenceMode, NotificationPreferences } from 'shared/types';

export const getModelNotificationPreferences = async (
  modelId: string
): Promise<AxiosResponse<NotificationPreferences>> =>
  await ApiService.get<NotificationPreferences>(`models/${modelId}/notification-preferences`);

export const patchModelNotificationPreferences = async (
  modelId: string,
  notificationPreferences: ENotificationPreferenceMode
): Promise<AxiosResponse<NotificationPreferences>> =>
  await ApiService.patch<
    { notificationPreferences: ENotificationPreferenceMode },
    NotificationPreferences
  >(`models/${modelId}/notification-preferences`, { notificationPreferences });

export const getThreadNotificationPreferences = async (
  modelId: string,
  threadId: number
): Promise<AxiosResponse<NotificationPreferences>> =>
  await ApiService.get<NotificationPreferences>(
    `models/${modelId}/notes/${threadId}/notification-preferences`
  );

export const patchThreadNotificationPreferences = async (
  modelId: string,
  threadId: number,
  notificationPreferences: ENotificationPreferenceMode
): Promise<AxiosResponse<NotificationPreferences>> =>
  await ApiService.patch<
    { notificationPreferences: ENotificationPreferenceMode },
    NotificationPreferences
  >(`models/${modelId}/notes/${threadId}/notification-preferences`, { notificationPreferences });
