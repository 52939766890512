import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import { CSInitializationData } from 'shared/interfaces';

export class SceneSimpleCiStrategy extends BaseSsStrategy {
  constructor(private initializationData: CSInitializationData) {
    super(initializationData);
  }

  public async createScreenshot(): Promise<string> {
    const { renderer, scene, camera } = this.mainScene;
    renderer.render(scene, camera);

    return renderer.domElement.toDataURL('image/jpeg');
  }
}
