import * as Yup from 'yup';
const PASSWORD = Yup.string()
  .trim()
  .min(8, 'Use 8 or more characters')
  .max(50, 'Only 50 symbols allowed')
  .required('Required field');
const CONFIRM_PASSWORD = Yup.string()
  .min(8, 'Use 8 or more characters')
  .max(50, 'Only 50 symbols allowed')
  .oneOf([Yup.ref('password')], 'Passwords must match')
  .required('Passwords must match');
const EMAIL = Yup.string().email('Please enter a valid e-mail address').required('Required field');
const STRING = Yup.string().trim().required('Required field');
const NEW_PASSWORD = Yup.string()
  .trim()
  .min(8, 'Use 8 or more characters')
  .max(50, 'Only 50 symbols allowed')
  .required('Required field')
  .notOneOf([Yup.ref('currentPassword')], 'Password must be unique');

export const validation = {
  LOG_IN: Yup.object().shape({
    email: EMAIL,
    password: PASSWORD
  }),
  REGISTRATION: Yup.object().shape({
    email: EMAIL,
    password: PASSWORD,
    confirmPassword: CONFIRM_PASSWORD,
    firstName: STRING,
    lastName: STRING
  }),
  FORGOT_PASSWORD: Yup.object().shape({
    email: EMAIL
  }),
  RESET_PASSWORD: Yup.object().shape({
    password: PASSWORD,
    confirmPassword: CONFIRM_PASSWORD
  }),
  PAYMENT: Yup.object().shape({
    cardNumber: Yup.string().min(19, 'Please enter full card number').required('Required field'),
    cardName: Yup.string().max(20, 'Only 20 symbols allowed').required('Required field'),
    expiryDate: Yup.string()
      .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Please enter correct expiry date')
      .min(5, 'Please enter full expiry date')
      .required('Required field'),
    cvv: Yup.string().min(3, 'Please enter full CVV code').required('Required field')
  }),
  MODEL_SETTINGS: Yup.object().shape({
    modelName: Yup.string().required('Required field')
  }),
  ACCOUNT_SETTINGS: Yup.object().shape({
    newPassword: NEW_PASSWORD,
    currentPassword: PASSWORD
  }),
  EMAIL_FORM: Yup.object().shape({
    email: Yup.string().email('Please enter a valid e-mail address').required('Required field')
  }),
  CONTACT_US: Yup.object().shape({
    firstName: STRING.max(255, 'Only 255 symbols allowed'),
    lastName: Yup.string().trim().max(255, 'Only 255 symbols allowed'),
    email: EMAIL,
    reason: STRING,
    text: STRING
  }),
  TEAM_NAME: Yup.object().shape({
    teamName: STRING.trim().max(32, 'Only 32 symbols allowed').required('Required field')
  }),
  THEME_CUSTOMIZATION: Yup.object().shape({
    brandName: STRING.trim().max(32, 'Only 32 symbols allowed').required('Required field'),
    siteTitle: STRING.trim().max(32, 'Only 32 symbols allowed').required('Required field'),
    siteSubtitle: STRING.trim().max(32, 'Only 32 symbols allowed').required('Required field')
  }),
  ENV_CUSTOMIZATION: Yup.object().shape({
    envName: STRING.trim()
      .min(3, 'Use 3 or more characters')
      .max(32, 'Only 32 symbols allowed')
      .required('Required field')
  }),
  CUBEMAP_CUSTOMIZATION: Yup.object().shape({
    cubemapName: STRING.trim()
      .min(3, 'Use 3 or more characters')
      .max(32, 'Only 32 symbols allowed')
      .required('Required field')
  })
};
