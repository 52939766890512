import { createAction, createReducer } from '@reduxjs/toolkit';
import { BrandingState, BrandingSettings } from 'shared/types';
import { DEFAULT_BRANDING } from 'shared/constants/customization';

const initialState: BrandingState = {
  activeBranding: DEFAULT_BRANDING,
  isBrandingLoading: true
};

// Actions

export const setBranding = createAction<BrandingSettings>('branding/setBranding');
export const setIsBrandingLoading = createAction<boolean>('branding/setIsBrandingLoading');
export const clearBrandingData = createAction('branding/clearBrandingData');

// Reducer

export const brandingReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setBranding, (state, { payload }): void => {
      state.activeBranding = payload;
    })
    .addCase(setIsBrandingLoading, (state, { payload }): void => {
      state.isBrandingLoading = payload;
    })
    .addCase(clearBrandingData, (): BrandingState => initialState);
});
