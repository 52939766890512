import {EConfirmModalHeader, ESampleModelName, ESnackbarStyle, Model, Note, User} from 'shared/types';
import React, {useRef} from 'react';
import {
  setActiveCommentId,
  setCommentsList,
  setIsCommentsLoaded,
  setIsCommentsLoading,
  setPlainCommentsList,
} from 'services/store/reducers/commentsReducer';
import {useAppDispatch, useAppSelector} from 'shared/hooks/store-hooks';
import {sampleComments} from 'shared/constants/sample-comments';
import {useModelInfoData} from '../model-info-data-hook';
import {RootState} from 'services/store';
import {openNotification} from 'utils/notification-utils';
import {startLoader, stopLoader} from 'services/store/reducers/loaderReducer';
import {closeConfirmModal, showConfirmModal} from 'services/store/reducers/modalReducer';
import {ModalDelete} from 'shared/components';
import {IViewerCommentFromServer} from 'shared/interfaces';
import {deleteNote, getNotes, updateNote, uploadNote} from 'services/api/notesService';

interface Result {
  fetchComments: ({ onLoad, forcedCommentsList }: IHandleFetchCommentsProps) => void;
  syncComment: ({ comment, isNewComment, onLoad }: ISyncCommentsProps) => Promise<void>;
  deleteComment: (comment: IViewerCommentFromServer) => Promise<void>;
  setOnCommentsFirstLoaded: (onLoadedCallback: () => void) => void;
}

interface IHandleFetchCommentsProps {
  forcedCommentsList?: Note[];
  onLoad?: (comments: Note[]) => void;
}

interface ISyncCommentsProps {
  comment: IViewerCommentFromServer;
  isNewComment: boolean;
  onLoad?: (comment: Note) => Promise<void>;
}

export const useCommentsData = (model: Model | null, user: User | null): Result => {
  const dispatch = useAppDispatch();
  const onLoadedCallback = useRef<(() => void) | null>(null);
  const store = useAppSelector((state): RootState => state);
  const { comments, plainComments, isCommentsLoaded } = store.comments;

  const { isSampleModel, isAnonymousModel, isQuickView } = useModelInfoData(model, user);

  const makePlainCommentsList = (
    comments: IViewerCommentFromServer[]
  ): IViewerCommentFromServer[] => {
    return comments.reduce((acc, item): IViewerCommentFromServer[] => {
      acc.push(item, ...(item.comments ? item.comments : []));

      return acc;
    }, [] as IViewerCommentFromServer[]);
  };

  const fetchSampleComments = (comments: Note[], onLoad?: (comments: Note[]) => void): void => {
    const plainComments = makePlainCommentsList(comments);

    dispatch(setCommentsList(JSON.parse(JSON.stringify(comments))));
    dispatch(setPlainCommentsList(JSON.parse(JSON.stringify(plainComments))));

    if (onLoad) {
      onLoad(comments);
    }
  };

  const fetchComments = async ({
    onLoad,
    forcedCommentsList
  }: IHandleFetchCommentsProps): Promise<void> => {
    if (!model || (isAnonymousModel && !isSampleModel)) return;

    switch (true) {
      case isSampleModel && !comments.length && model.modelName === ESampleModelName.car:
        fetchSampleComments(sampleComments[ESampleModelName.car], onLoad);
        break;

      case isSampleModel && !comments.length && model.modelName === ESampleModelName.bee:
        fetchSampleComments(sampleComments[ESampleModelName.bee], onLoad);
        break;

      case isSampleModel && !comments.length && model.modelName === ESampleModelName.viking_scene:
        fetchSampleComments(sampleComments[ESampleModelName.viking_scene], onLoad);
        break;

      case isSampleModel:
        fetchSampleComments(forcedCommentsList || comments, onLoad);
        break;

      case isQuickView:
        break;

      default:
        try {
          const commentsData = (await getNotes(model!.id)).data;

          if (onLoad) {
            onLoad(commentsData);
          }

          dispatch(setCommentsList(commentsData));
          dispatch(
            setPlainCommentsList(JSON.parse(JSON.stringify(makePlainCommentsList(commentsData))))
          );
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        }
        break;
    }

    if (onLoadedCallback.current && !isCommentsLoaded) {
      onLoadedCallback.current();
    }

    dispatch(setIsCommentsLoaded(true));
  };

  const syncRealComment = async ({
    comment,
    isNewComment,
    onLoad
  }: ISyncCommentsProps): Promise<void> => {
    dispatch(setIsCommentsLoading(true));

    try {
      switch (true) {
        case isNewComment:
          const commentWithPosition = { ...comment, position: comment.params.position };
          const uploadedComment = (await uploadNote(model!.id, commentWithPosition)).data;

          if (onLoad) {
            await onLoad(uploadedComment);
          }

          await fetchComments({});

          dispatch(setActiveCommentId(uploadedComment.threadId || -1));
          requestAnimationFrame((): void => {
            dispatch(setActiveCommentId(uploadedComment.id));
          });
          break;

        default:
          const updatedComment = (await updateNote(model!.id, comment.id, comment)).data;

          if (onLoad) {
            await onLoad(updatedComment);
          }

          await fetchComments({});

          dispatch(setActiveCommentId(updatedComment.threadId || -1));
          requestAnimationFrame((): void => {
            dispatch(setActiveCommentId(updatedComment.id));
          });
          break;
      }
    } catch (e) {
      openNotification(ESnackbarStyle.HOLD_UP, e.message);
    }

    dispatch(setIsCommentsLoading(false));
  };

  const syncSampleComment = async ({
    comment,
    isNewComment,
    onLoad
  }: ISyncCommentsProps): Promise<void> => {
    const date = new Date();
    const commentsCopy = JSON.parse(JSON.stringify(comments)) as Note[];
    const commentCopy = JSON.parse(JSON.stringify(comment)) as Note;

    commentCopy.updatedAt = date.toJSON();
    commentCopy.commentsCount = plainComments.length;
    commentCopy.commentNum = plainComments.length;

    if (!!comment.threadId) {
      const thread = commentsCopy.find((thread): boolean => thread.id === commentCopy.threadId);
      const target = thread!.comments?.find((comm): boolean => comm.id === commentCopy.id);

      if (!target) {
        thread!.comments?.push(commentCopy);
      }
    }

    if (isNewComment) {
      commentCopy.createdAt = date.toJSON();
      commentCopy.id = plainComments.length;
      commentCopy.mentions = [];
      commentsCopy.push(commentCopy);
    }

    if (onLoad) {
      await onLoad(commentCopy);
    }
    dispatch(setActiveCommentId(commentCopy.id));
    await fetchComments({ forcedCommentsList: commentsCopy });
  };

  const syncComment = async (props: ISyncCommentsProps): Promise<void> => {
    switch (true) {
      case isSampleModel:
        await syncSampleComment(props);
        break;

      default:
        await syncRealComment(props);
        break;
    }
  };

  const deleteRealComment = async (comment: IViewerCommentFromServer): Promise<void> => {
    dispatch(setIsCommentsLoading(true));

    const deleteAction = async (): Promise<void> => {
      dispatch(startLoader());

      try {
        await deleteNote(model!.id, comment.id);
        dispatch(setActiveCommentId(comment.threadId ? comment.threadId : -1));
        await fetchComments({});
        dispatch(closeConfirmModal());
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: React.createElement(ModalDelete, { deleteAction })
      })
    );
    dispatch(setIsCommentsLoading(false));
  };

  const deleteSampleComment = (comment: IViewerCommentFromServer): void => {
    const deleteAction = (): void => {
      const tempComments: IViewerCommentFromServer[] = comments.filter(
        (comm): boolean => comm.id !== comment.id && comm.id !== comment.threadId
      );

      if (comment.threadId) {
        const targetThread = JSON.parse(
          JSON.stringify(comments.find((comm): boolean => comm.id === comment.threadId))
        ) as IViewerCommentFromServer;

        targetThread.comments = targetThread.comments?.filter(
          (comm): boolean => comm.id === comment.id
        );
        tempComments.push(targetThread);
      }

      dispatch(setActiveCommentId(comment.threadId || -1));
      fetchComments({ forcedCommentsList: tempComments as Note[] });
      dispatch(closeConfirmModal());
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: React.createElement(ModalDelete, { deleteAction })
      })
    );
  };

  const deleteComment = async (comment: IViewerCommentFromServer): Promise<void> => {
    switch (true) {
      case isSampleModel:
        deleteSampleComment(comment);
        break;

      default:
        await deleteRealComment(comment);
        break;
    }
  };

  return {
    fetchComments,
    syncComment,
    deleteComment,
    setOnCommentsFirstLoaded: (callback): void => {
      onLoadedCallback.current = callback;
    }
  };
};
