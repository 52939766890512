export enum EKeyBindingsKeys {
  forward = 'forward',
  back = 'back',
  left = 'left',
  right = 'right',
  up = 'up',
  down = 'down',
  fastSpeed = 'fastSpeed',
  speedControl = 'speedControl',
  increaseFov = 'increaseFOV',
  decreaseFov = 'decreaseFOV',
  pan = 'pan',
  quickZoom = 'quickZoom',
  rotate = 'rotate'
}

export enum EKeyPriorities {
  primary = 'primary',
  secondary = 'secondary'
}
