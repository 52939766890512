import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  background: ${COLORS.mineShaft};
  border-radius: 7px;
  padding: 25px;
  max-width: 620px;
  width: 100%;

  @media${DEVICE.tablet} {
    width: 100%;
    max-width: initial;
  }

  @media${DEVICE.mobile} {
    padding: 25px 15px;
  }
`;

export const PreferenceItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 20px;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }
`;

export const Checkbox = styled.div`
  min-width: 17px;
  width: 17px;
  height: 17px;
  border: 1px solid ${COLORS.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    height: 8px;
  }
`;
