import React, { useEffect } from 'react';
import * as Styled from './styles';
import Star from 'assets/images/star.svg';
import Done from 'assets/images/done.svg';
import Close from 'assets/images/close.svg';
import {
  SubscriptionsState,
  SubscriptionProduct,
  AuthState,
  EConfirmModalHeader,
  Model,
  EQuotaName,
  ESnackbarStyle,
  EPaymentType
} from 'shared/types';
import { extractPlanData, getSubscriptionLimits } from 'utils/subscription-plans-utils';
import { CUSTOM_PLAN } from 'shared/constants/subscription-plans';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { useHistory } from 'react-router-dom';
import {
  closeConfirmModal,
  closeModal,
  showConfirmModal,
  showModal
} from 'services/store/reducers/modalReducer';
import { convertStringToButtonId } from 'utils/form-utils';
import {
  ModalCheckout,
  ModalExceedLimits,
  ModalLogin,
  ModalDowngradePlan
} from 'shared/components';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { getModelsOverSizeLimit } from 'services/api/modelsService';
import { getUserPlanLimit } from 'services/api/subscriptionsService';
import { openNotification } from 'utils/notification-utils';

type Props = {
  isMonthlyType: boolean;
  plan?: SubscriptionProduct;
  isCustomPlan?: boolean;
  isModal?: boolean;
};

const SubscriptionPlan: React.FC<Props> = ({
  plan,
  isMonthlyType,
  isCustomPlan,
  isModal
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isAdmin, isMasterAdmin, isAuth } = useAppSelector((state): AuthState => state.auth);
  const { currentSubscriptionPlan, subscriptionPlans, planLevels } = useAppSelector(
    (store): SubscriptionsState => store.subscriptions
  );

  useEffect((): void => {
    if (isAuth && !isAdmin && !isMasterAdmin) {
      currentSubscriptionPlan ? dispatch(stopLoader()) : dispatch(startLoader());
    }
  }, [currentSubscriptionPlan, dispatch, isAuth, isAdmin, isMasterAdmin]);

  const {
    title,
    subtitle,
    monthPrice,
    yearPrice,
    limits,
    features,
    description,
    icon,
    isMostPopular
  } = extractPlanData(plan, isCustomPlan);

  const isCurrentPlanInterval =
    !monthPrice ||
    currentSubscriptionPlan?.recurringInterval === (isMonthlyType ? 'month' : 'year');
  const isCurrentPlan =
    plan?.id === currentSubscriptionPlan?.productId && isCurrentPlanInterval && !isCustomPlan;

  const checkExceededLimits = async (
    plan: SubscriptionProduct
  ): Promise<{ modelsOverSizeLimit: Model[]; modelsOverLimit: number }> => {
    let modelsOverSizeLimit: Model[] = [];
    let modelsOverLimit: number = 0;
    dispatch(startLoader());
    try {
      const { modelSizePlanLimit, modelsPlanLimit } = getSubscriptionLimits(plan);
      modelsOverSizeLimit = (await getModelsOverSizeLimit(modelSizePlanLimit)).data;
      const { used } = (await getUserPlanLimit(EQuotaName.ACTIVE_MODELS_LIMIT)).data;
      modelsOverLimit = used - modelsPlanLimit > 0 ? used - modelsPlanLimit : 0;
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
    return { modelsOverSizeLimit, modelsOverLimit };
  };

  const handleSelectPlanAction = async (plan: SubscriptionProduct): Promise<void> => {
    const { modelsOverSizeLimit, modelsOverLimit } = await checkExceededLimits(plan);
    if (!!modelsOverSizeLimit.length || !!modelsOverLimit) {
      dispatch(
        showConfirmModal({
          header: null,
          content: (
            <ModalExceedLimits
              paymentType={EPaymentType.UPDATE_USER_PLAN}
              plan={plan}
              isMonthlyType={isMonthlyType}
              modelsOverLimit={modelsOverLimit}
              modelsOverSizeLimit={modelsOverSizeLimit}
              checkExceededLimits={checkExceededLimits}
            />
          )
        })
      );
    } else {
      dispatch(closeModal());
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: <ModalCheckout plan={plan} isMonthlyType={isMonthlyType} />
        })
      );
    }
  };

  const onSelectClick = async (): Promise<void> => {
    if (!isAuth) {
      dispatch(showModal(<ModalLogin />));
      return;
    }

    if (isCustomPlan) {
      dispatch(closeModal());
      dispatch(closeConfirmModal());
      history.push('/contact-us');
      return;
    }

    const currentPlan = subscriptionPlans.find(
      (plan): boolean => plan.id === currentSubscriptionPlan?.productId
    );

    if (!!plan && !isCurrentPlan && !!currentPlan) {
      const currentPlanLevel = planLevels?.get(currentPlan) || 0;
      const selectedPlanLevel = planLevels?.get(plan) || 0;
      if (selectedPlanLevel < currentPlanLevel) {
        dispatch(
          showConfirmModal({
            header: 'Downgrade Plan',
            content: (
              <ModalDowngradePlan
                currentPlan={currentPlan}
                selectedPlan={plan}
                handleSelectPlanAction={handleSelectPlanAction}
              />
            )
          })
        );
      } else {
        await handleSelectPlanAction(plan);
      }
    }
  };

  const getSelectButtonText = (): string => {
    switch (true) {
      case isCustomPlan && isAdmin:
        return 'Current';
      case isCurrentPlan:
        return 'Current';
      case isCustomPlan:
        return 'Contact us';
      case !monthPrice:
        return 'Try for free';
      default:
        return 'Select';
    }
  };

  return (
    <Styled.PlanWrapper isModal={isModal}>
      {isMostPopular && (
        <Styled.MostPopularLabel isModal={isModal}>
          <img src={Star} alt='Most Popular' />
          <span>MOST POPULAR</span>
        </Styled.MostPopularLabel>
      )}
      <Styled.PlanContainer isMostPopular={isMostPopular} isModal={isModal}>
        <Styled.PlanIconShadow isModal={isModal} />
        <Styled.PlanIconContainer isModal={isModal}>
          <Styled.PlanIcon src={icon} isModal={isModal} />
        </Styled.PlanIconContainer>
        <Styled.PlanTitle isModal={isModal}>{title}</Styled.PlanTitle>
        <Styled.PlanSubtitle isModal={isModal}>{subtitle}</Styled.PlanSubtitle>
        <Styled.PaymentDescription isModal={isModal}>{description}</Styled.PaymentDescription>
        <Styled.PriceSection isModal={isModal}>
          {isCustomPlan ? (
            <Styled.CustomPrice isModal={isModal}>{CUSTOM_PLAN.price}</Styled.CustomPrice>
          ) : (
            <>
              <Styled.Currency>$</Styled.Currency>
              <Styled.Price isModal={isModal}>
                {isMonthlyType ? monthPrice : yearPrice}
              </Styled.Price>
              <Styled.Duration isModal={isModal}>
                {`/ ${isMonthlyType ? 'month' : 'year'}`}
              </Styled.Duration>
            </>
          )}
        </Styled.PriceSection>
        <Styled.PlanDescription isModal={isModal}>
          {limits.map(
            (item): JSX.Element => (
              <span key={item}>{item}</span>
            )
          )}
        </Styled.PlanDescription>
        <Styled.SelectButton
          onClick={onSelectClick}
          isActive={isCurrentPlan || isMasterAdmin || isAdmin}
          disabled={isCurrentPlan || isMasterAdmin || isAdmin}
          isModal={isModal}
          id={convertStringToButtonId(subtitle)}
        >
          {getSelectButtonText()}
        </Styled.SelectButton>
        <Styled.AvailableFeatures isModal={isModal}>
          {features.map(
            ({ title, isAvailable }): JSX.Element => (
              <Styled.FeatureItemContainer key={title}>
                <Styled.FeatureItem isAvailable={isAvailable} isModal={isModal}>
                  <img src={isAvailable ? Done : Close} alt='Feature' />
                  <span>{title}</span>
                </Styled.FeatureItem>
              </Styled.FeatureItemContainer>
            )
          )}
        </Styled.AvailableFeatures>
      </Styled.PlanContainer>
    </Styled.PlanWrapper>
  );
};

export default SubscriptionPlan;
