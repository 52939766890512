import * as THREE from 'three';
import { Cubemap } from 'shared/types';
import { MainScene } from '../scenes';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { ECubemapPresets } from 'shared/enums/ECubemapPresets';

export class CubemapController {
  private prevCubemapPresetKey?: ECubemapPresets | string;

  public _currentCubemapPresetKey: ECubemapPresets | string = ECubemapPresets.Neutral;
  public scene?: THREE.Scene;

  constructor(private mainScene: MainScene) {
  }

  public setUpScene(scene: THREE.Scene): void {
    this.scene = scene;
  }

  public updateByOptions(cubemap: Cubemap, force?: boolean): void {
    if (this.prevCubemapPresetKey === cubemap.key && !force) return;

    this.setCubemapPreset(cubemap);
    this.prevCubemapPresetKey = this._currentCubemapPresetKey;
  }

  public setCubemapPreset(cubemap: Cubemap): void {
    if (!this.scene) return;

    this._currentCubemapPresetKey = cubemap.key;

    if (this._currentCubemapPresetKey === ECubemapPresets.Neutral) {
      this.setNeutralCubeMap(this.mainScene.scene, this.mainScene.pmremGenerator);
    } else {
      this.loadCubemap(cubemap);
    }
  }

  private loadCubemap({ imagePaths }: Cubemap): void {
    if (!this.scene) return;

    const { pxPlaneUrl, nxPlaneUrl, pyPlaneUrl, nyPlaneUrl, pzPlaneUrl, nzPlaneUrl } = imagePaths;

    this.scene.environment = new THREE.CubeTextureLoader().load([
      pxPlaneUrl,
      nxPlaneUrl,
      pyPlaneUrl,
      nyPlaneUrl,
      pzPlaneUrl,
      nzPlaneUrl
    ]);
  }

  public setNeutralCubeMap(scene: THREE.Scene, pmremGenerator: THREE.PMREMGenerator): void {
    scene.environment = pmremGenerator.fromScene( new RoomEnvironment() ).texture;
  }
}
