import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  max-width: 600px;
  width: 100%;
  color: ${COLORS.white};
  background: ${COLORS.mineShaft};
  padding: 48px 33px 25px;
  border-radius: 7px;
  position: relative;

  @media ${DEVICE.tablet} {
    padding: 30px 20px;
  }
`;

export const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const ChangeNameField = styled.div`
  margin-top: 30px;
  display: flex;
`;

export const InputContainer = styled.div`
  position: relative;
  height: 70px;
  width: 100%;
`;

export const ChangeNameInput = styled.input`
  height: 39px;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${COLORS.turbo};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border-radius: 4px 4px 0 0;
  padding: 13px 11px;
`;

export const InputFooter = styled.div`
  display: flex;
  flex-direction: column;

  .hint {
    margin-top: 6px;
    margin-left: auto;
    font-size: 10px;
    line-height: 11px;

    @media${DEVICE.tabletLarge} {
      display: none;
    }
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${DEVICE.mobile} {
    justify-content: center;
  }
`;

export const FormButton = styled.button`
  width: 140px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border-radius: 2px;

  &.cancel-button {
    background: ${COLORS.codGray};
    color: ${COLORS.white};
  }

  &.save-button {
    background: ${COLORS.turbo};
    color: ${COLORS.codGray};
    margin-left: 8px;
  }

  &:hover {
    color: ${COLORS.codGray};
    background: ${COLORS.gorse};
  }

  &:disabled {
    background: ${COLORS.doveGray};
  }
`;

export const CloseButton = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
