import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const EntitySettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LockIcon = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const EntityContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 44px;
  background: ${COLORS.deepBlack};
  padding: 0 10px;
  border-radius: 4px;
`;

export const EntityFields = styled.div<{ isResizeMode: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: ${({ isResizeMode }): string => (isResizeMode ? 'none' : 'initial')};
`;

export const EntityField = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldLabel = styled.div<{ isActive: boolean }>`
  font-family: ${FONTS.interRegular};
  font-size: 14px;
  line-height: 17px;
  color: ${({ isActive }): string => (isActive ? COLORS.corn : COLORS.mischka)};
  margin-right: 7px;
  cursor: pointer;
`;

export const FieldInput = styled.input`
  width: 58px;
  height: 28px;
  border-radius: 4px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  font-size: 14px;
  color: ${COLORS.mischka};
  padding: 0 5px;

  @media${DEVICE.tablet} {
    width: 50px;
  }
`;
