export enum ECubemapPresets {
  Warehouse = 0,
  EmptyRoom = 1,
  BasicWarehouse = 2,
  WoodSurface = 3,
  CityDay = 4,
  CityEvening = 5,
  LightBox = 6,
  LightRoom = 7,
  Neutral = 8,
}

