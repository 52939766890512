import { AxiosResponse } from 'axios';
import { UserStatsApiResponse, PlatformSummary } from 'shared/types';
import ApiService from './api';

export const getUserStatistics = async (
  limit: number,
  page: number,
  search: string,
  orderBy: string
): Promise<AxiosResponse<UserStatsApiResponse>> => {
  const searchQueryParams = search ? `&search=${search}` : '';
  const orderByQueryParams = orderBy ? `&orderBy=${orderBy}` : '';
  return await ApiService.get<UserStatsApiResponse>(
    `admin-dashboard/user-stats?page=${page}&limit=${limit}${searchQueryParams}${orderByQueryParams}`
  );
};

export const getPlatformSummary = async (): Promise<AxiosResponse<PlatformSummary>> =>
  await ApiService.get<PlatformSummary>(`admin-dashboard/platform-summary`);

export const resetUserViews = async (userId: number): Promise<AxiosResponse<void>> =>
    await ApiService.post(`/users/${userId}/subscriptions/active/reset-recurring-limits`, {});
