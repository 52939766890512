import { AxiosResponse } from 'axios';
import { Preference } from 'shared/types';
import ApiService from './api';

export const getUserPreferences = async (): Promise<AxiosResponse<Preference[]>> =>
  await ApiService.get<Preference[]>(`/users/me/preferences`);

export const changePreferenceValue = async (
  propertyName: string,
  value: boolean
): Promise<AxiosResponse<void>> =>
  await ApiService.patch<{ value: boolean }, void>(`/users/me/preferences/${propertyName}`, {
    value
  });

export const muteModelNotifications = async (
  modelId: string,
  mute: boolean
): Promise<AxiosResponse<void>> =>
  await ApiService.post<{ mute: boolean }, void>(`/models/${modelId}/mute-notifications`, { mute });
