import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  CurrentCard,
  CurrentSubscriptionProduct,
  ESnackbarStyle,
  ETeamRole,
  Team,
  TeamsState
} from 'shared/types';
import { AppDispatch } from '../index';
import { getTeam, getTeamMember, getTeams } from 'services/api/teamService';
import { startLoader, stopLoader } from './loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getCurrentTeamCard, getCurrentTeamSubscription } from 'services/api/subscriptionsService';

const initialState: TeamsState = {
  teams: [],
  activeTeam: null,
  teamRole: ETeamRole.VIEWER,
  teamPlan: null,
  teamCard: null,
  isTeamLoaded: false
};

// Actions

export const setUserTeams = createAction<Team[]>('teams/setUserTeams');
export const setActiveTeam = createAction<Team | null>('teams/setActiveTeam');
export const setActiveTeamUserRole = createAction<ETeamRole>('teams/setActiveTeamUserRole');
export const setActiveTeamPlan = createAction<CurrentSubscriptionProduct | null>(
  'teams/setActiveTeamPlan'
);
export const setActiveTeamCard = createAction<CurrentCard | null>('teams/setActiveTeamCard');
export const setIsTeamLoaded = createAction<boolean>('teams/setIsTeamLoaded');
export const clearActiveTeam = createAction('teams/clearActiveTeam');
export const clearUserTeams = createAction('teams/clearUserTeams');

// Reducer

export const teamsReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setUserTeams, (state, { payload }): void => {
      state.teams = payload;
    })
    .addCase(setActiveTeam, (state, { payload }): void => {
      state.activeTeam = payload;
    })
    .addCase(setActiveTeamUserRole, (state, { payload }): void => {
      state.teamRole = payload;
    })
    .addCase(setActiveTeamPlan, (state, { payload }): void => {
      state.teamPlan = payload;
    })
    .addCase(setActiveTeamCard, (state, { payload }): void => {
      state.teamCard = payload;
    })
    .addCase(setIsTeamLoaded, (state, { payload }): void => {
      state.isTeamLoaded = payload;
    })
    .addCase(clearActiveTeam, (state): void => {
      state.activeTeam = null;
      state.teamRole = ETeamRole.VIEWER;
      state.teamPlan = null;
      state.teamCard = null;
      state.isTeamLoaded = false;
    })
    .addCase(clearUserTeams, (): TeamsState => initialState);
});

// Thunks

export const fetchUserTeams = createAsyncThunk<Promise<void>, void, { dispatch: AppDispatch }>(
  'teams/fetchUserTeams',
  async (_, { dispatch }): Promise<void> => {
    const response = (await getTeams()).data;
    if (!!response.length) {
      const sortedTeams = response.sort((a, b): number => (a.createdAt > b.createdAt ? 1 : -1));
      dispatch(setUserTeams(sortedTeams));
    }
  }
);

export const fetchTeam = createAsyncThunk<
  Promise<void>,
  { teamId: number; goToNotFoundPage?: () => void },
  { dispatch: AppDispatch }
>('teams/fetchTeam', async ({ teamId, goToNotFoundPage }, { dispatch }): Promise<void> => {
  dispatch(startLoader());
  try {
    const activeTeam = (await getTeam(teamId)).data;
    dispatch(setActiveTeam(activeTeam));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
    if (!!goToNotFoundPage) goToNotFoundPage();
  } finally {
    dispatch(stopLoader());
  }
});

export const fetchTeamData = createAsyncThunk<
  Promise<void>,
  { teamId: number },
  { dispatch: AppDispatch }
>('teams/fetchTeamData', async ({ teamId }, { dispatch }): Promise<void> => {
  dispatch(startLoader());
  try {
    const member = (await getTeamMember(teamId)).data;
    dispatch(setActiveTeamUserRole(member.role));
    if (member.role === ETeamRole.OWNER || member.role === ETeamRole.ADMIN) {
      const currentPlan = (await getCurrentTeamSubscription(teamId)).data;
      if (!!currentPlan) {
        dispatch(setActiveTeamPlan(currentPlan));
      }
      const currentCard = (await getCurrentTeamCard(teamId)).data;
      dispatch(setActiveTeamCard(currentCard));
    }
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
  } finally {
    dispatch(stopLoader());
  }
});
