import React, { JSX, useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import LockIcon from 'assets/images/lock-icon.svg';
import { CommentReplyItem } from '../comment-reply-item';
import { IViewerComment, IViewerCommentFromServer } from 'shared/interfaces';
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
  useModelInfoData,
  useNotificationPreferencesData
} from 'shared/hooks';
import { RootState } from 'services/store';
import { setActiveCommentId } from 'services/store/reducers/commentsReducer';
import { MAIN_SCENE_CSS2D_CONTAINER_ID } from 'shared/constants/html-elements-ids';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { debounce } from 'utils/delay-utils';
import { openNotification } from 'utils/notification-utils';
import {
  EModelType,
  ENotificationPreferenceMode,
  EPremiumFeature,
  ESnackbarStyle,
  Note
} from 'shared/types';
import { SuggestedPeopleList } from '../suggested-people-list';
import {
  formatToDefault,
  formatToHTMLLayout,
  getUsersList,
  handleCommentReply,
  syncMentionsFromMessage,
  transformMentionFromDefaultFormat,
  transformMentionFromSimplifiedFormat
} from 'utils/comments-utils';
import { SimpleUser } from 'shared/types/user';
import {
  createMentionSpan,
  getCommentBB,
  moveCursorToEndOfContentEditable,
  pasteAsText
} from 'utils/dom-utils';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModalFeatureSignedIn, ModalFeatureSignedOut } from '../../modals';
import { RESTRICT_COMMENTS } from 'shared/constants/notifications';
import { useCommentsData } from 'shared/hooks/data-hooks/comments-data-hook/useCommentsData';
import { DEFAULT_MENTION_FORMAT_REGEXP } from 'shared/constants/regexps';
import {
  BellFilledIcon,
  ChevronIcon,
  CommentCubeIcon,
  CommentReplyIcon,
  LinkIcon
} from 'assets/dynamic-icons';
import { COMMENT_MAX_LENGTH } from 'shared/constants/comments-defaults';
import { CameraController } from 'shared/webgl/controllers';
import { MainScene } from 'shared/webgl/scenes';
import { checkIsIFrame } from 'utils/helper-utils';
import { NotificationSettingsBar } from 'shared/components';

interface Props {
  mainScene: MainScene;
  cameraController: CameraController;
  commentThread: IViewerCommentFromServer;
  isNewComment?: boolean;
  threadElementIdByThreadId: (id: IViewerComment['threadId']) => string;
  updateIndexedMap: () => void;
  createScreenshot: (editor?: HTMLDivElement) => Promise<string>;
}

enum EInputType {
  reply = 'Reply...',
  new = 'Add a comment',
  edit = 'Text...'
}

export const CommentThreadBubble: React.FC<Props> = ({
  mainScene,
  cameraController,
  commentThread,
  threadElementIdByThreadId,
  isNewComment,
  updateIndexedMap,
  createScreenshot
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const bubbleRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const pointerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const isPointerVisible = useRef<boolean>(false);
  const isOpen = useRef<boolean>(false);

  const isMobile = window.innerWidth < DEVICE_SIZES.tablet;

  const store = useAppSelector((store): RootState => store);
  const { activeCommentId } = store.comments;
  const { user, isAuth } = store.auth;
  const { model, isEmbeddedModelMode, modelType } = store.viewerData;
  const { hasCommentAccess } = store.modelFeatureAccess;
  const { isPublishedModel } = store.modelPermissions;

  const [editorClassNames, setEditorClassNames] = useState<string[]>([]);
  const [inputType, setInputType] = useState<EInputType>(EInputType.reply);
  const [inputValue, setInputValue] = useState<string>('');

  const [isToggleActive, setIsToggleActive] = useState<boolean>(false);
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [suggestedPeopleList, setSuggestedPeopleList] = useState<SimpleUser[]>([]);
  const [lastMentionStartAt, setLastMentionStartAt] = useState<number>(-1);
  const [editTarget, setEditTarget] = useState<IViewerCommentFromServer | null>(null);
  const [hasWritePermissions, setHasWritePermission] = useState<boolean>(false);
  const [hasWriteFuture, setHasWriteFuture] = useState<boolean>(false);
  const [isThreadAuthor, setIsThreadAuthor] = useState<boolean>(false);
  const [isNotificationSettingsActive, setIsNotificationSettingsActive] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const isSampleModel = modelType === EModelType.SAMPLE;

  const { isAnonymousModel, isModelOwner, isTeamModel } = useModelInfoData(
    model,
    user
  );
  const { syncComment, deleteComment } = useCommentsData(model, user);
  const { copyToClipboard } = useCopyToClipboard();
  const {
    fetchThreadNotificationPreferences,
    updateThreadNotificationPreferences,
    notificationPreferences
  } = useNotificationPreferencesData();

  useEffect((): void => {
    (async (): Promise<void> => {
      if (!!model && !!commentThread.id && isAuth && !isSampleModel) {
        await fetchThreadNotificationPreferences(model.id, commentThread.id);
      }
    })();
  }, [fetchThreadNotificationPreferences, model, commentThread, isAuth, isSampleModel]);

  useEffect((): void => {
    const selectedReply =
      commentThread.comments &&
      commentThread.comments.find((reply): boolean => reply.id === activeCommentId);

    if (commentThread.id === activeCommentId || !!selectedReply) {
      isOpen.current = true;
    } else {
      isOpen.current = false;
      setInputValue('');
    }
  }, [activeCommentId]);

  useEffect((): void => {
    setIsToggleActive(isOpen.current && !!isNewComment);
    isPointerVisible.current = isOpen.current && !isNewComment;
    setEditorClassNames([isOpen.current ? 'isOpen' : '', isNewComment ? 'isCreated' : '']);
    setInputType(isNewComment ? EInputType.new : EInputType.reply);
    setSuggestedPeopleList([]);

    if (!isOpen.current) {
      resetLayoutBias();
    }

    setTimeout((): void => {
      inputRef.current?.focus();
    }, 300);
  }, [isOpen.current, isNewComment]);

  useEffect((): void => {
    if (inputType !== EInputType.reply) return;

    setEditTarget(handleCommentReply({ user, commentThread }));
  }, [inputType]);

  useEffect((): void => {
    setHasWritePermission(!isEmbeddedModelMode && hasCommentAccess && (isAuth || isSampleModel));
    setHasWriteFuture(
      !isEmbeddedModelMode && !((!hasCommentAccess && isModelOwner) || (!isAuth && !isSampleModel))
    );
    setIsThreadAuthor(user?.id === commentThread.authorId);
  }, [
    hasCommentAccess,
    isAuth,
    isSampleModel,
    isModelOwner,
    user,
    commentThread,
    isEmbeddedModelMode
  ]);

  useEffect((): void => {
    updateIndexedMap();

    if (isMobile) {
      updateCameraBias();

      setTimeout(updateCameraBias.bind(null, updateLayoutBias), 500);
    } else {
      updateLayoutBias();
    }
  }, [isOpen.current]);

  const notifyMaxLengthLimitReached = useCallback(
    debounce((): void => {
      openNotification(
        ESnackbarStyle.HOLD_UP,
        'Maximum message length limit reached. Only 1024 symbols allowed.'
      );
    }, 800),
    []
  );

  const showPlansModalWindow = (): void => {
    if (!checkIsIFrame()) {
      if (isAuth) {
        isModelOwner
          ? dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.COMMENTS} />))
          : openNotification(ESnackbarStyle.HOLD_UP, RESTRICT_COMMENTS);
      } else {
        dispatch(showModal(<ModalFeatureSignedOut feature={EPremiumFeature.COMMENTS} />));
      }
    }
  };

  const toggleComment = (): void => {
    const selectedReply =
      commentThread.comments &&
      commentThread.comments.find((reply): boolean => reply.id === activeCommentId);

    if (commentThread.id === activeCommentId || !!selectedReply) {
      dispatch(setActiveCommentId(-1));
    } else {
      dispatch(setActiveCommentId(commentThread.id));
    }
  };

  const preventDefaults = (
    e:
      | React.KeyboardEvent<HTMLElement>
      | React.ChangeEvent<HTMLDivElement>
      | React.ClipboardEvent<HTMLDivElement>
  ): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleInput = (
    event: React.ChangeEvent<HTMLDivElement> | React.ClipboardEvent<HTMLDivElement>
  ): void => {
    preventDefaults(event);

    if (submitting || !hasWriteFuture) return;

    normalizeInputHeight();

    if (event.currentTarget.innerHTML.length > COMMENT_MAX_LENGTH) {
      setIsInputError(true);
      notifyMaxLengthLimitReached();

      event.currentTarget.innerHTML = event.currentTarget.innerHTML.slice(0, COMMENT_MAX_LENGTH);

      moveCursorToEndOfContentEditable(event.currentTarget);
    } else {
      setIsInputError(false);
      showSuggestionsForMention();
      setInputValue(event.currentTarget.innerText);
    }

    normalizeInputHeight();
  };

  const preventKeyDownOnInput = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key !== 'Enter' && e.key !== 'Escape') {
      e.stopPropagation();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
    e.preventDefault();

    if (!e.clipboardData || !hasWriteFuture) return;

    const text = e.clipboardData.getData('text/plain');

    pasteAsText(e.currentTarget, text);
    handleInput(e);
  };

  const handleKeyDownOnInput = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && inputValue.length) {
      preventDefaults(e);
      handleSyncComment();
    }

    if (e.key === 'Escape' && (!inputValue.length || commentThread.message === inputValue)) {
      preventDefaults(e);
      normalizeInputHeight();
      setInputType(isNewComment ? EInputType.new : EInputType.reply);
      dispatch(setActiveCommentId(-1));

      if (!!inputRef.current) {
        inputRef.current.innerHTML = '';
      }
    }
  };

  const handleInputBlockClick = (): void => {
    if (!hasCommentAccess && !isTeamModel && (isModelOwner || isAnonymousModel)) {
      showPlansModalWindow();
    }
  };

  const handleDeleteThreadMouseDown = (): void => {
    if (hasCommentAccess) {
      deleteComment(commentThread);
    }
  };

  const handleSyncComment = async (): Promise<void> => {
    if (submitting || !hasWriteFuture || !inputValue.length) return;

    setSubmitting(true);

    const formattedMessage = transformMentionFromSimplifiedFormat(inputValue, formatToDefault);
    const updatedComment: IViewerCommentFromServer = {
      ...(inputType === EInputType.new ? commentThread : editTarget!),
      message: formattedMessage
    };
    const onLoad = async (updatedComment: Note): Promise<void> => {
      const hasMentions = DEFAULT_MENTION_FORMAT_REGEXP.test(updatedComment.message);

      if (hasMentions && !isSampleModel) {
        const screenshot = await createScreenshot(
          bubbleRef.current!.parentElement!.parentElement! as HTMLDivElement
        );

        await syncMentionsFromMessage(
          updatedComment,
          DEFAULT_MENTION_FORMAT_REGEXP,
          screenshot,
          isPublishedModel,
          isSampleModel,
          model!
        );
      }

      if (!!inputRef.current) {
        inputRef.current.innerHTML = '';
      }

      setInputValue('');
      setInputType(EInputType.reply);
      normalizeInputHeight();
    };

    await syncComment({ comment: updatedComment, isNewComment: inputType !== EInputType.edit, onLoad });
    setSubmitting(false);
  };

  const updateLayoutBias = (): void => {
    if (!bubbleRef.current || !toggleRef.current || !pointerRef.current || !isOpen.current) return;

    const labelRendererElement = document.getElementById(MAIN_SCENE_CSS2D_CONTAINER_ID);

    if (!labelRendererElement) return;

    const canvasBoundingBox = labelRendererElement.getBoundingClientRect();
    const commentBoundingBox = getCommentBB({
      toggleElement: toggleRef.current,
      pointerElement: pointerRef.current,
      bubbleElement: bubbleRef.current,
      isNewComment,
      isMobile,
      isPointerVisible: isPointerVisible.current
    });

    if (!commentBoundingBox) return;

    if (canvasBoundingBox.width === 0 || canvasBoundingBox.height === 0) {
      return;
    }

    const bias = {
      x: 0,
      y: 0
    };

    if (commentBoundingBox.left < canvasBoundingBox.left) {
      bias.x = canvasBoundingBox.left - commentBoundingBox.left;
    }

    if (
      commentBoundingBox.top < canvasBoundingBox.top &&
      commentBoundingBox.bottom <= canvasBoundingBox.bottom
    ) {
      bias.y = canvasBoundingBox.top - commentBoundingBox.top;
    }

    if (commentBoundingBox.right > canvasBoundingBox.right) {
      bias.x = canvasBoundingBox.right - commentBoundingBox.right;
    }

    if (commentBoundingBox.bottom > canvasBoundingBox.bottom) {
      bias.y = canvasBoundingBox.bottom - commentBoundingBox.bottom;
    }

    bubbleRef.current.style.transform = `translate(${bias.x}px,${bias.y}px)`;
    toggleRef.current.style.transform = `translate(${bias.x}px,${bias.y}px)`;
    pointerRef.current.style.transform = `translate(${bias.x}px,${bias.y}px)`;

    requestAnimationFrame(updateLayoutBias);
  };

  const resetLayoutBias = (): void => {
    bubbleRef.current!.style.transform = `translate(0,0)`;
    toggleRef.current!.style.transform = `translate(0,0)`;
    pointerRef.current!.style.transform = `translate(0,0)`;
  };

  const updateCameraBias = (onComplete?: () => void): void => {
    if (!bubbleRef.current || !toggleRef.current || !pointerRef.current || !isOpen.current) return;

    const labelRendererElement = document.getElementById(MAIN_SCENE_CSS2D_CONTAINER_ID);

    if (!labelRendererElement) return;

    const step = 0.08;
    const canvasBoundingBox = labelRendererElement.getBoundingClientRect();
    const commentBoundingBox = getCommentBB({
      toggleElement: toggleRef.current,
      pointerElement: pointerRef.current,
      bubbleElement: bubbleRef.current,
      isNewComment,
      isMobile,
      isPointerVisible: isPointerVisible.current
    });

    if (!commentBoundingBox) return;

    if (
      commentBoundingBox.width > canvasBoundingBox.width ||
      commentBoundingBox.height > canvasBoundingBox.height ||
      (commentBoundingBox.left >= canvasBoundingBox.left &&
        commentBoundingBox.right <= canvasBoundingBox.right &&
        commentBoundingBox.top >= canvasBoundingBox.top &&
        commentBoundingBox.bottom <= canvasBoundingBox.bottom)
    ) {
      if (onComplete) onComplete();

      return;
    }

    if (commentBoundingBox.left < canvasBoundingBox.left) {
      cameraController.moveCamera(-step, 0);
    }

    if (commentBoundingBox.right > canvasBoundingBox.right) {
      cameraController.moveCamera(step, 0);
    }

    if (commentBoundingBox.top < canvasBoundingBox.top) {
      cameraController.moveCamera(0, step);
    }

    if (commentBoundingBox.bottom > canvasBoundingBox.bottom) {
      cameraController.moveCamera(0, -step);
    }

    requestAnimationFrame(updateCameraBias.bind(null, undefined));
  };

  const normalizeInputHeight = (): void => {
    if (!inputRef.current) return;

    const startParentPaddingBottom = 0;
    let parentPaddingBottom = 31;
    let startInputPaddingRight = 31;
    let inputPaddingRight = 12;
    let minScrollHeight = 27;

    if (isNewComment) {
      parentPaddingBottom = 53;
      startInputPaddingRight = 37;
      inputPaddingRight = 4;
      minScrollHeight = 42;
    }

    if (inputRef.current.innerHTML.length) {
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    } else {
      inputRef.current.style.height = '5px';
    }

    if (inputRef.current.scrollHeight > minScrollHeight) {
      inputRef.current.parentElement!.style.paddingBottom = parentPaddingBottom + 'px';
      inputRef.current.style.paddingRight = inputPaddingRight + 'px';
    } else {
      inputRef.current.parentElement!.style.paddingBottom = startParentPaddingBottom + 'px';
      inputRef.current.style.paddingRight = startInputPaddingRight + 'px';
    }
  };

  const handleEditButtonClick = (comment: IViewerCommentFromServer): void => {
    setInputType(EInputType.edit);
    setEditTarget(comment);
    inputRef.current!.innerHTML = transformMentionFromDefaultFormat(
      comment.message,
      formatToHTMLLayout
    );
    setInputValue(inputRef.current!.innerText);
    normalizeInputHeight();
  };

  const showSuggestionsForMention = async (): Promise<void> => {
    if (!inputRef.current) return;

    const spaceIndex = inputRef.current.innerHTML.lastIndexOf(' ');
    const startIndex =
      inputRef.current.innerHTML.lastIndexOf(' @') >= 0
        ? inputRef.current.innerHTML.lastIndexOf(' @') + 1
        : inputRef.current.innerHTML[0] === '@' && spaceIndex < 0
        ? 0
        : -1;
    setLastMentionStartAt(startIndex);

    if (
      startIndex >= 0 &&
      inputRef.current.innerHTML.length > startIndex + 1 &&
      spaceIndex <= startIndex
    ) {
      const mentionRequestText = inputRef.current.innerHTML.slice(startIndex + 1).toLowerCase();
      const usersList = await getUsersList({
        modelId: model?.id!,
        mentionRequestText,
        currentUser: user
      });

      setSuggestedPeopleList(usersList ? usersList : []);
    } else {
      setSuggestedPeopleList([]);
    }
  };

  const handleSuggestedUserClick = (user: SimpleUser): void => {
    if (!inputRef.current) return;

    const mentionSpan = createMentionSpan(`@${user.email}`);

    inputRef.current.innerHTML = inputRef.current.innerHTML.slice(0, lastMentionStartAt);
    inputRef.current.appendChild(mentionSpan);
    inputRef.current.innerHTML += ' ';
    normalizeInputHeight();

    setInputValue(inputRef.current.innerText);

    setSuggestedPeopleList([]);
    inputRef.current.focus();
    moveCursorToEndOfContentEditable(inputRef.current);
  };

  const removeFullLengthSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const selection = window.getSelection();

    if (event.detail === 1 && selection && selection.toString().length === inputValue.length) {
      selection.collapseToEnd();
    }
  };

  const handleInputClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    removeFullLengthSelection(event)
    inputRef.current?.focus();
  };

  const handleNotificationButtonClick = (): void => {
    setIsNotificationSettingsActive((prev): boolean => !prev);
  };

  const handleCloseClick = (): void => {
    setIsNotificationSettingsActive(false);
  };

  const handleCopyButtonClick = async (): Promise<void> => {
    if (!!model) {
      const url = `${model?.shortLinkUrl}?commentId=${commentThread.id}`;
      await copyToClipboard(url, 'Link to thread copied successfully!');
    }
  };

  const updateNotificationPreferences = async (
    mode: ENotificationPreferenceMode
  ): Promise<void> => {
    if (!!model) {
      await updateThreadNotificationPreferences(model?.id, commentThread.id, mode);
    }
  };

  return (
    <Styled.Editor
      id={threadElementIdByThreadId(commentThread.id)}
      className={editorClassNames.join(' ')}
      isEmbeddedMode={isEmbeddedModelMode}
      onKeyDown={handleKeyDownOnInput}
    >
      <Styled.Toggle
        ref={toggleRef}
        hidden={isPointerVisible.current}
        isActive={isToggleActive}
        onClick={toggleComment}
      >
        <CommentCubeIcon />
      </Styled.Toggle>

      <Styled.Pointer ref={pointerRef} isVisible={isPointerVisible.current} />

      <Styled.Bubble ref={bubbleRef}>
        <Styled.BubbleContainer>
          {isOpen.current && (
            <>
              {!isNewComment && (
                <>
                  <Styled.BubbleHeader justifyToEnd={!(isThreadAuthor || hasWritePermissions)}>
                    {!isEmbeddedModelMode && (isThreadAuthor || hasWritePermissions) && (
                      <Styled.DeleteThreadButton onClick={handleDeleteThreadMouseDown}>
                        Delete Thread
                      </Styled.DeleteThreadButton>
                    )}

                    <Styled.HeaderRightSide>
                      {isAuth && (
                        <Styled.ActionButton
                          type='button'
                          className='notifications-button'
                          isActive={isNotificationSettingsActive}
                          onClick={handleNotificationButtonClick}
                        >
                          <BellFilledIcon />
                        </Styled.ActionButton>
                      )}
                      <Styled.ActionButton
                        type='button'
                        className='copy-button'
                        onClick={handleCopyButtonClick}
                      >
                        <LinkIcon />
                      </Styled.ActionButton>
                      <Styled.ActionButton
                        type='button'
                        className='collapse-button'
                        onClick={toggleComment}
                      >
                        <ChevronIcon />
                      </Styled.ActionButton>
                    </Styled.HeaderRightSide>
                  </Styled.BubbleHeader>

                  {isNotificationSettingsActive && (
                    <Styled.NotificationSettings>
                      <NotificationSettingsBar
                        handleCloseClick={handleCloseClick}
                        notificationPreferences={notificationPreferences}
                        updateNotificationPreferences={updateNotificationPreferences}
                        isThread
                      />
                    </Styled.NotificationSettings>
                  )}

                  <Styled.CommentsList>
                    <CommentReplyItem
                      key={commentThread.id}
                      comment={commentThread}
                      onEditButtonClick={handleEditButtonClick}
                      onDeleteButtonClick={deleteComment}
                    />

                    {commentThread.comments?.map(
                      (comment): JSX.Element => (
                        <CommentReplyItem
                          key={comment.id}
                          comment={comment}
                          onEditButtonClick={handleEditButtonClick}
                          onDeleteButtonClick={deleteComment}
                        />
                      )
                    )}
                  </Styled.CommentsList>
                </>
              )}

              {hasWritePermissions && (
                <Styled.InputBlock onClick={handleInputBlockClick}>
                  <Styled.InputWrapper hasError={isInputError}>
                    {!hasWriteFuture && (
                      <Styled.LockWrapper>
                        <Styled.LockIcon src={LockIcon} />
                      </Styled.LockWrapper>
                    )}

                    {!inputValue.length && (
                      <Styled.InputPlaceholder>{inputType}</Styled.InputPlaceholder>
                    )}

                    <Styled.Input
                      ref={inputRef}
                      contentEditable={hasWriteFuture}
                      aria-multiline={true}
                      dir={'ltr'}
                      onInput={handleInput}
                      onPaste={handlePaste}
                      onKeyDown={preventKeyDownOnInput}
                      onClick={handleInputClick}
                    />
                  </Styled.InputWrapper>

                  <Styled.ReplyButton onClick={handleSyncComment}>
                    <CommentReplyIcon />
                  </Styled.ReplyButton>

                  {!!suggestedPeopleList.length && (
                    <SuggestedPeopleList
                      usersList={suggestedPeopleList}
                      onSelect={handleSuggestedUserClick}
                    />
                  )}
                </Styled.InputBlock>
              )}
            </>
          )}
        </Styled.BubbleContainer>
      </Styled.Bubble>
    </Styled.Editor>
  );
};
