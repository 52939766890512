import { useCallback, useRef, useState } from 'react';
import { ESnackbarStyle, Transaction } from 'shared/types';
import { useAppDispatch } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getUserTransactions } from 'services/api/subscriptionsService';

type Result = {
  transactions: Transaction[];
  fetchTransactions: () => Promise<void>;
  isTransactionsLoading: boolean;
  hasMore: boolean;
};

const useTransactionsData = (): Result => {
  const LIMIT = 10;
  const dispatch = useAppDispatch();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const lastItemId = useRef<string>('');

  const fetchTransactions = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    setIsTransactionsLoading(true);
    try {
      const response = (await getUserTransactions(LIMIT, lastItemId.current)).data;
      if (!!response.data.length) {
        lastItemId.current = response.data[response.data.length - 1].id;
        setHasMore(response.has_more);
        setTransactions((prev): Transaction[] => [...prev, ...response.data]);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      setIsTransactionsLoading(false);
      dispatch(stopLoader());
    }
  }, [dispatch]);

  return {
    transactions,
    fetchTransactions,
    isTransactionsLoading,
    hasMore
  };
};

export default useTransactionsData;
