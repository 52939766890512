import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const TooltipBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background: rgba(83, 83, 83, 0.4);
  backdrop-filter: blur(6px);
`;

export const TooltipItem = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${COLORS.white};
`;
