import React from 'react';
import * as Styled from './styles';
import { usePageTopPositionContext } from 'shared/providers';

const SeoSection = (): JSX.Element => {
  const { pageTop } = usePageTopPositionContext();

  const handleUploadButtonClick = (): void => {
    pageTop?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Styled.SeoBlockContainer>
      <Styled.SectionFreeOnlineViewer>
        <Styled.SectionFreeOnlineViewerLeftSide>
          <span className='subtitle'>GLB, gLTF, FBX, OBJ & STL Model Viewer</span>
          <h1 className='title'>The Free Online 3D Model Viewer</h1>
          <p>
            glbee is a free online 3D model viewer that makes it super simple to view, collaborate
            and share 3D models anywhere on the web. Just drag & drop your gLTF, GLB, FBX, OBJ or
            STL file to upload and view your 3D model. Sharing options are available for you to
            share models with friends or colleagues using either QR code, short link or download
            options.
          </p>
          <p>
            Features of the platform include Augmented Reality viewing mode, custom environments and
            custom branding as well as an intuitive comments feature which enables you to
            collaborate on 3D models with ease.
          </p>
          <Styled.UploadModelButton onClick={handleUploadButtonClick} id='upload-model-button'>
            Upload model
          </Styled.UploadModelButton>
        </Styled.SectionFreeOnlineViewerLeftSide>
        <img
          src={`${process.env.REACT_APP_URL}/resources/seo/macbook-pro.png`}
          className='section-image'
          alt='free 3D model viewer'
        />
        <img
          src={`${process.env.REACT_APP_URL}/resources/seo/logo-cube.png`}
          className='section-background-image'
          alt='3d model viewer'
        />
      </Styled.SectionFreeOnlineViewer>
      <Styled.SectionShareModels>
        <img
          src={`${process.env.REACT_APP_URL}/resources/seo/macbook-pro-2.png`}
          className='section-image'
          alt='best 3D viewer'
        />
        <Styled.SectionRightSide>
          <span className='subtitle'>3D Model Viewer</span>
          <h2 className='title'>View Your 3D Models & Share Instantly</h2>
          <p>
            This free 3D model viewer lets you quickly and easily view, share & collaborate on your
            3D models straight from your web browser. Options for collaboration include the ability
            to leave comments on your models within the 3D space, each with it’s own saved camera
            position.
          </p>
          <p>
            You also have a full suite of model details available at your fingertips which makes it
            convenient to view model texture, geometry and other important model information.
          </p>
          <Styled.UploadModelButton onClick={handleUploadButtonClick} id='upload-model-button-2'>
            Upload model
          </Styled.UploadModelButton>
        </Styled.SectionRightSide>
      </Styled.SectionShareModels>
      <Styled.SectionPlatformFeatures>
        <Styled.SectionPlatformFeaturesLeftSide>
          <span className='subtitle'>View, Collaborate and Share</span>
          <h2 className='title'>Platform Features</h2>
          <Styled.PlatformFeaturesList>
            <Styled.ListRow>
              <Styled.ListRowItem>
                <h4>Comments</h4>
                <p>
                  Collaborate on your 3D models in real-time with friends & colleagues using our
                  intuitive comments feature
                </p>
              </Styled.ListRowItem>
              <Styled.ListRowItem>
                <h4>View in AR</h4>
                <p>View your 3D models in Augmented Reality using our custom WebAR integration</p>
              </Styled.ListRowItem>
            </Styled.ListRow>
            <Styled.ListRow>
              <Styled.ListRowItem>
                <h4>Custom Backgrounds</h4>
                <p>Upload custom backgrounds to the platform to use when viewing your models</p>
              </Styled.ListRowItem>
              <Styled.ListRowItem>
                <h4>Custom Branding</h4>
                <p>Use custom branding to give your shared model a personalized, on-brand touch</p>
              </Styled.ListRowItem>
            </Styled.ListRow>
          </Styled.PlatformFeaturesList>
        </Styled.SectionPlatformFeaturesLeftSide>
        <img
          src={`${process.env.REACT_APP_URL}/resources/seo/macbook-pro-3.png`}
          className='section-image'
          alt='simple 3D model viewer'
        />
      </Styled.SectionPlatformFeatures>
      <Styled.SectionWhoDesignedFor>
        <Styled.SectionImageContainer>
          <Styled.IconBlur />
          <img
            src={`${process.env.REACT_APP_URL}/resources/seo/ipad-pro.png`}
            className='section-image'
            alt='online 3d viewer'
          />
        </Styled.SectionImageContainer>
        <Styled.SectionRightSide>
          <span className='subtitle'>3D Model Viewer & Sharing Tool</span>
          <h2 className='title'>Who is glbee designed for?</h2>
          <p>
            glbee is designed for 3D artists, enthusiasts, production agencies and anyone that
            would like a simple tool for viewing and sharing their 3D models with the world.
          </p>
          <p>
            Our sharing options are what make us different. Alongside the 3D model viewer, the wide
            range of editing and sharing options makes this tool super handy for anyone in the 3D
            space.
          </p>
          <Styled.UploadModelButton onClick={handleUploadButtonClick} id='get-started-button'>
            Get started
          </Styled.UploadModelButton>
        </Styled.SectionRightSide>
      </Styled.SectionWhoDesignedFor>
      <Styled.SectionWhatIsGltf>
        <img
          src={`${process.env.REACT_APP_URL}/resources/seo/logo-cube.png`}
          className='section-image'
          alt='model viewer'
        />
        <div className='section-block'>
          <h3>What filetypes do you support?</h3>
          <p>
            We currently support gLTF, GLB, FBX, OBJ and STL 3D file formats on the platform for
            viewing, sharing and collaborating.
          </p>
          <p>
            We are currently working on additional file format compatibility, as well as other
            exciting features coming soon.
          </p>
        </div>

        <div className='section-block'>
          <h3>What is a gLTF file?</h3>
          <p>
            gLTF is a 3D file type, and is often tipped to be ‘the JPEG of 3D’. gLTF uses advanced
            techniques for compressing both geometry of 3D objects and their textures.
          </p>
          <p>
            The filetype is a lightweight format that any platform or device can use, which is
            especially important for those viewing 3D models on the web using a mobile device.
          </p>
        </div>
      </Styled.SectionWhatIsGltf>
      <Styled.SectionHowShare>
        <span className='subtitle'>Free gLTF Model Viewer</span>
        <h4 className='title'>How Can I Share 3D Models?</h4>
        <p>
          That’s easy with glbee! First you need drag or select your gLTF or GLB, FBX, OBJ or STL
          file in the upload window, then wait as your 3D model is uploaded to the platform. Once
          uploaded, your 3D model appears automatically within our default scene.
        </p>
        <p>
          Using the ‘Environment’ dropdown option, you can change the scene to whichever environment
          suits your model the best. Remember that all of the changes to things like environment,
          scale and lighting are all automatically saved, and when you’re sharing your 3D model -
          those same settings changes are carried over with it, which makes this tool super handy
          when sharing 3D models with friends and colleagues.
        </p>
        <img
          src={`${process.env.REACT_APP_URL}/resources/seo/logo-cube.png`}
          className='section-image'
          alt='gltf model viewer'
        />
      </Styled.SectionHowShare>
    </Styled.SeoBlockContainer>
  );
};

export default SeoSection;
