import * as THREE from 'three';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';
import { BaseLoadEnvironmentStrategy } from './strategies/base-load-environment.strategy';
import { EmptyLoadEnvironmentStrategy } from './strategies/empty-load-environment.strategy';
import { FileLoadEnvironmentStrategy } from './strategies/file-load-environment.strategy';
import { GriddedGeneratedLoadEnvironmentStrategy } from 'services/strategy-services/load-envionment-service/strategies/gridded-generated-load-environment.strategy';
import {
  LoadEnvironmentData,
  LoadEnvironmentResult
} from 'shared/interfaces';
import {
  SimpleGeneratedLoadEnvironmentStrategy
} from 'services/strategy-services/load-envionment-service/strategies/simple-generated-load-environment.strategy';

export class LoadEnvironmentService {
  private strategies: Map<EEnvironmentTypes, BaseLoadEnvironmentStrategy>;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    this.strategies = new Map<EEnvironmentTypes, BaseLoadEnvironmentStrategy>([
      [EEnvironmentTypes.noEnvironment, new EmptyLoadEnvironmentStrategy()],
      [EEnvironmentTypes.file, new FileLoadEnvironmentStrategy(renderer, onModelResourcesLoaded)],
      [EEnvironmentTypes.griddedGenerated, new GriddedGeneratedLoadEnvironmentStrategy()],
      [EEnvironmentTypes.simpleGenerated, new SimpleGeneratedLoadEnvironmentStrategy()],
    ]);
  }

  public async changeEnvironment(
    type: EEnvironmentTypes,
    data: LoadEnvironmentData
  ): Promise<LoadEnvironmentResult> {
    return await this.strategies.get(type)!.changeEnvironment(data);
  }
}
