import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  max-width: 1000px;
  max-height: 700px;
  height: 90vh;
  width: 90vw;
  color: ${COLORS.white};
  position: relative;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const LeftSide = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 6%;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const LiftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const RightSide = styled.section`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  overflow-y: hidden;

  img {
    width: 75%;
    object-fit: cover;
    transform: translatey(-30px);
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translatey(-30px);
      }
      50% {
        transform: translatey(30px);
      }
      100% {
        transform: translatey(-30px);
      }
    }
  }

  @media${DEVICE.tablet} {
    position: fixed;
    width: 140px;
    height: 200px;
    top: 0;
    right: 0;

    img {
      animation: none;
      width: 100%;
      height: 100%;
    }
  }

  @media${DEVICE.mobile} {
    width: 130px;
    height: 180px;
  }
`;
