import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import LogoLogin from 'assets/images/logo-icon-login.png';
import { ModalCopyright, ModalSubtitle, ModalTitle } from 'shared/styles';
import moment from 'moment';
import Lock from 'assets/images/lock-icon.svg';
import { useHistory } from 'react-router-dom';
import { closeModal } from 'services/store/reducers/modalReducer';
import { Subscriptions } from 'shared/components';
import { EPremiumFeature } from 'shared/types';
import { FEATURES } from 'shared/constants/features';

type Props = {
  feature: EPremiumFeature;
};

const ModalFeatureSignedIn: React.FC<Props> = ({ feature }): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleEnterprisePlanClick = (): void => {
    dispatch(closeModal());
    history.push('/subscription');
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.ModelTitleContainer>
          <ModalTitle>{FEATURES[feature].title}</ModalTitle>
          <Styled.Lock>
            <img src={Lock} alt='Lock' />
          </Styled.Lock>
        </Styled.ModelTitleContainer>
        <ModalSubtitle>{FEATURES[feature].description}</ModalSubtitle>
        <Styled.FeatureGifContainer>
          <img src={FEATURES[feature].preview} alt={FEATURES[feature].title} />
        </Styled.FeatureGifContainer>
        <ModalCopyright>
          <span>{moment().year()} © glbee</span>
        </ModalCopyright>
      </Styled.LeftSide>
      <Styled.RightSideContainer>
        <div>
          <Styled.RightSide>
            <ModalTitle>Upgrade your account to unlock this feature</ModalTitle>
            <Subscriptions isModal />
            <Styled.EnterprisePlanLink>
              <span>Need an enterprise plan?</span>
              <span onClick={handleEnterprisePlanClick}>Click here</span>
            </Styled.EnterprisePlanLink>
            <Styled.SectionIcon src={LogoLogin} alt='Logo' />
          </Styled.RightSide>
        </div>
      </Styled.RightSideContainer>
    </Styled.ModalWindowContainer>
  );
};

export default ModalFeatureSignedIn;
