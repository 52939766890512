import React, { useEffect, useMemo } from 'react';
import { AuthState, EModelAccessLevel, ESnackbarStyle, Model, UserPermission } from 'shared/types';
import * as Styled from './styles';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { deleteUserPermission } from 'services/api/permissionsService';
import {
  RESTRICT_FEATURE,
  REVOKED_ACCESS,
  UPDATE_YOUR_ACCOUNT
} from 'shared/constants/notifications';
import Delete from 'assets/images/remove.svg';
import Checked from 'assets/images/check.svg';
import { MODEL_ACCESSES } from '../../constants';
import {
  changeUserAccessLevel,
  fetchModelPermissions
} from 'services/store/reducers/modelPermissionsReducer';
import { openNotification } from 'utils/notification-utils';
import { openAlert } from 'utils/alert-utils';

type AccessItem = {
  title: string;
  access: EModelAccessLevel;
  isAvailable: boolean;
};

type Props = {
  permission: UserPermission;
  model: Model;
  isArAvailable: boolean;
  isCommentsAvailable: boolean;
};

const UserAccess: React.FC<Props> = ({
  permission,
  model,
  isArAvailable,
  isCommentsAvailable
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const { user } = useAppSelector((store): AuthState => store.auth);
  const userEmail =
    (!!permission.user ? permission.user.email : permission.invite?.userEmail) || '';
  const isModelOwner = model?.ownerId === user?.id;

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  const handleAccessChange =
    ({ access, isAvailable }: AccessItem): (() => Promise<void>) =>
    async (): Promise<void> => {
      if (!isAvailable) {
        isModelOwner
          ? openNotification(ESnackbarStyle.HOLD_UP, UPDATE_YOUR_ACCOUNT)
          : openAlert(RESTRICT_FEATURE);
        return;
      }

      const isViewAccess = access === EModelAccessLevel.VIEW_ACCESS;
      const hasThisAccess = permission.accessLevel.includes(access);
      let resultAccessLevel: EModelAccessLevel[];
      if (hasThisAccess) {
        resultAccessLevel = isViewAccess
          ? []
          : permission.accessLevel.filter((level): boolean => level !== access);
      } else {
        resultAccessLevel = isViewAccess
          ? [EModelAccessLevel.VIEW_ACCESS]
          : Array.from(new Set([...permission.accessLevel, EModelAccessLevel.VIEW_ACCESS, access]));
      }
      await dispatch(
        changeUserAccessLevel({ model, recordId: permission.id, accessLevel: resultAccessLevel })
      );
    };

  const handleDeleteButtonClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteUserPermission(model.id, permission.id);
      await dispatch(fetchModelPermissions({ model, signal }));
      openNotification(ESnackbarStyle.SUCCESS, REVOKED_ACCESS);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const modelAccesses = MODEL_ACCESSES.map((item): AccessItem => {
    if (item.access === EModelAccessLevel.VIEW_AR_ACCESS) {
      return { ...item, isAvailable: isArAvailable };
    }
    if (item.access === EModelAccessLevel.COMMENT_ACCESS) {
      return { ...item, isAvailable: isCommentsAvailable };
    }
    return item;
  });

  return (
    <tr>
      <td>
        <Styled.DeleteButton src={Delete} onClick={handleDeleteButtonClick} alt='Delete Access' />
        <span>{userEmail}</span>
      </td>
      {modelAccesses.map(
        (item): JSX.Element => (
          <td key={item.title}>
            <Styled.AccessCheckbox
              onClick={handleAccessChange(item)}
              isAvailable={item.isAvailable}
            >
              {permission.accessLevel.includes(item.access) && <img src={Checked} alt='Checked' />}
            </Styled.AccessCheckbox>
            <Styled.AccessTitle isAvailable={item.isAvailable}>{item.title}</Styled.AccessTitle>
          </td>
        )
      )}
    </tr>
  );
};

export default UserAccess;
