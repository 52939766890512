import { GlShaderData } from 'shared/interfaces';

export const SRGBToLinearConvertorShader: GlShaderData = {
  fragmentSource: `
    precision mediump float;
    varying vec2 texUv;
    uniform sampler2D sampler;

    void main() {
      vec4 texel = texture2D(sampler, texUv);

      vec3 linearRGB = pow(texel.rgb, vec3(2.2));
      gl_FragColor = vec4(linearRGB, texel.a);
    }
  `
};
