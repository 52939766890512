import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModalWindowContainer = styled.div`
  width: 30vw;
  min-width: 420px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 30px;
  color: ${COLORS.mischka};

  @media ${DEVICE.mobile} {
    min-width: 300px;
  }
`;

export const ContentText = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  text-align: center;

  &.top {
    padding: 10px 0 30px 0;
  }

  &.bottom {
    padding: 30px 0 5px 0;
  }
`;

export const FeaturesBlock = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0;
  background: #484848;
  padding: 0 17px;
  border-radius: 6px 6px 0 0;
`;

export const BlockFeatures = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0 0 6px 6px;
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 13px;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  background: ${COLORS.mineShaft};

  &:nth-child(odd) {
    background: rgba(71, 71, 71, 0.35);
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

export const ActionButtons = styled.div`
  margin-top: 30px;
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  padding: 8px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s;

  &.downgrade-button {
    background: #484848;
    color: ${COLORS.white};
  }

  &.cancel-button {
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }

  &:hover {
    background: ${COLORS.gorse};
    color: ${COLORS.black};
  }
`;
