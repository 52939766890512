import { CommentsState, Note } from 'shared/types';
import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState: CommentsState = {
  comments: [],
  plainComments: [],
  activeCommentId: -1,
  isEmbedPreviewActive: false,
  isCommentsLoading: false,
  isCommentsVisible: false,
  isCommentsBarActive: false,
  isCommentsLoaded: false
};

export const setCommentsList = createAction<Note[]>('comments/setCommentsList');
export const setPlainCommentsList = createAction<Note[]>('comments/plainCommentsList');
export const setActiveCommentId = createAction<number>('comments/activeCommentId');
export const setIsEmbedPreviewActive = createAction<boolean>('comments/setIsEmbedPreviewActive');
export const setIsCommentsLoading = createAction<boolean>('comments/setIsCommentsLoading');
export const setIsCommentsVisible = createAction<boolean>('comments/setIsCommentsVisible');
export const toggleCommentsBar = createAction<boolean>('comments/setIsCommentsBarActive');
export const setIsCommentsLoaded = createAction<boolean>('comments/setIsCommentsLoaded');
export const clearCommentsState = createAction('comments/clearCommentsState');

export const commentsReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setIsCommentsLoading, (state, { payload }): void => {
      state.isCommentsLoading = payload;
    })
    .addCase(setIsCommentsVisible, (state, { payload }): void => {
      state.isCommentsVisible = payload;
    })
    .addCase(toggleCommentsBar, (state, { payload }): void => {
      state.isCommentsVisible = payload;
      state.isCommentsBarActive = payload;
    })
    .addCase(setIsCommentsLoaded, (state, { payload }): void => {
      state.isCommentsLoaded = payload;
    })
    .addCase(setIsEmbedPreviewActive, (state, { payload }): void => {
      state.isEmbedPreviewActive = payload;
    })
    .addCase(setActiveCommentId, (state, { payload }): void => {
      if (!state.isEmbedPreviewActive) {
        state.activeCommentId = payload;
      }
    })
    .addCase(setCommentsList, (state, { payload }): void => {
      state.comments = payload;
    })
    .addCase(setPlainCommentsList, (state, { payload }): void => {
      state.plainComments = payload;
    })
    .addCase(clearCommentsState, (): CommentsState => initialState);
});

// region Thunks
// endregion
