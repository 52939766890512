import React from 'react';
import * as Styled from './styles';
import { TeamSubscriptions } from 'shared/components';
import { EPaymentType } from 'shared/types';

const SubscriptionTab = (): React.ReactElement => {
  return (
    <Styled.TabContent>
      <Styled.ContentContainer>
        <TeamSubscriptions paymentType={EPaymentType.UPDATE_TEAM_PLAN} />
      </Styled.ContentContainer>
    </Styled.TabContent>
  );
};

export default SubscriptionTab;
