import { FormikContextType } from 'formik';
import { ErrorMessage } from 'shared/styles';
import { EXTRACT_DOMAIN } from 'shared/constants/regexps';

export const getFieldError = <T,>(
  formik: FormikContextType<T>,
  fieldName: string
): JSX.Element | undefined => {
  const meta = formik.getFieldMeta(fieldName);
  const errorText = meta.touched && meta.error ? meta.error : '';
  return !!errorText ? <ErrorMessage>{errorText}</ErrorMessage> : undefined;
};

export const capitalize = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const convertStringToButtonId = (string: string): string =>
  `${string
    .trim()
    .replace('(', '')
    .replace(')', '')
    .replace('-', ' ')
    .toLowerCase()
    .split(' ')
    .join('-')}-button`;

export const formatNumberToNumberWithCommas = (number: number): string =>
  Intl.NumberFormat('en-US').format(number);

export const extractDomainFromURL = (url: string, withProtocol: boolean = true): string => {
  const data = EXTRACT_DOMAIN.exec(url);
  return !!data ? (withProtocol ? data[0] : data[1]) : '';
};
