import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;

export const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotFoundBlockContainer = styled.section`
  position: relative;
`;

export const NotFoundBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 338px;
  height: 134px;
  padding: 0 20px;
  background: linear-gradient(
    150.59deg,
    rgba(255, 255, 255, 0.45) 17.37%,
    rgba(255, 255, 255, 0) 101.21%,
    rgba(255, 255, 255, 0) 101.21%
  );
  backdrop-filter: blur(15px);
  border-radius: 7px;
  z-index: 1;

  @media ${DEVICE.tablet} {
    width: 250px;
  }
`;

export const NotFoundCode = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  color: ${COLORS.turbo};
  margin-bottom: 4px;
`;

export const NotFoundText = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: ${COLORS.white};
  text-align: center;
`;

export const LogoCube = styled.img`
  position: absolute;

  &.left-cube {
    top: -18px;
    left: -70px;
    width: 96px;
    height: 102px;
    transform: rotate(15deg);
  }

  &.top-cube {
    top: -48px;
    left: 25%;
    width: 59px;
    height: 63px;
    transform: rotate(-15deg);
  }

  &.bottom-cube {
    bottom: -55px;
    right: -55px;
    width: 100px;
    height: 107px;
    transform: rotate(15deg);
    z-index: 2;
  }
`;

export const BackButton = styled.button`
  margin-top: 88px;
  width: 280px;
  height: 32px;
  background: ${COLORS.turbo};
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  color: ${COLORS.black};
  transition: all 0.2s;

  &:hover {
    background: ${COLORS.gorse};
  }
`;
