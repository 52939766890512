import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import LeftArrow from 'assets/images/left-arrow.svg';
import {
  CUBEMAP_OPTIONS,
  ENVIRONMENT_OPTIONS,
  LIGHTING_OPTIONS
} from 'shared/constants/model-settings';
import {
  AxisValues,
  BrandOption,
  CubemapOption,
  EConfirmModalHeader,
  EnvironmentOption,
  EPremiumFeature,
  ESnackbarStyle,
  ETransformFiledType,
  EModelType,
  LightingOption,
  Model,
  SelectOption
} from 'shared/types';
import { BrandingSelect, ModelMetadata, SettingsSelect } from './components';
import { useAppDispatch, useAppSelector, useAutoSave, useCustomBrandingData } from 'shared/hooks';
import {
  closeConfirmModal,
  showConfirmModal,
  showModal
} from 'services/store/reducers/modalReducer';
import {
  CommentsBar,
  ModalDeleteModel,
  ModalFeatureSignedOut,
  SaveModelBlock,
  Select,
  Switcher,
  TransformField
} from 'shared/components';
import { deleteModel, downloadModel } from 'services/api/modelService';
import {
  changeModelOffset,
  changeModelRotation,
  changeModelScale,
  clearViewerDataState,
  setIsSidebarHidden,
  setModelName,
  setModelSettings,
  stopZenMode
} from 'services/store/reducers/viewerDataReducer';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { MAX_MODEL_NAME_LENGTH } from 'shared/constants/limits';
import { ErrorMessage } from 'shared/styles';
import { useHistory } from 'react-router-dom';
import { RootState } from 'services/store';
import { MODEL_DELETED, MODEL_DOWNLOADING } from 'shared/constants/notifications';
import { openNotification } from 'utils/notification-utils';
import { toggleCommentsBar } from 'services/store/reducers/commentsReducer';
import { setBranding } from 'services/store/reducers/brandingReducer';
import { DEFAULT_BRANDING, DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { convertUrlToNotFromCacheUrl } from 'utils/model-utils';
import { convertBrandColorsToCSSVariables, setThemeCSSVariables } from 'utils/theme-utils';

type Props = {
  model: Model;
};

const SidebarModel: React.FC<Props> = ({ model }): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const AUTO_SAVE_TEXT = 'Changes saved automatically';
  const LOADING_TEXT = 'Saving changes...';
  const store = useAppSelector((store): RootState => store);
  const { isArMode } = store.arMode;
  const { user, isAuth } = store.auth;
  const { modelSettings, animations, isZenMode, modelType, modelName, isSidebarHidden } =
    store.viewerData;
  const { plainComments, isCommentsBarActive } = store.comments;
  const {
    hasLightingAccess,
    hasEnvironmentAccess,
    hasCubemapAccess,
    hasEditAccess,
    hasCustomBrandingAccess,
    hasCustomEnvironmentAccess
  } = store.modelFeatureAccess;
  const { activeBranding } = store.branding;
  const { isSettingsSaving } = useAutoSave();
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(true);
  const isSampleModel = modelType === EModelType.SAMPLE;
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;
  const isQuickView = modelType === EModelType.QUICK_VIEW;
  const isTeamModel = modelType === EModelType.TEAM;
  const isModelOwner = model.ownerId === user?.id;
  const {
    fetchCustomBranding,
    fetchCustomEnvironments,
    fetchCustomCubemaps,
    brands,
    environments,
    cubemaps
  } = useCustomBrandingData();

  const {
    lighting,
    environment,
    cubemap,
    autoRotate,
    animation,
    enableShadows,
    wireframe,
    showDimensions,
    arScaleInMeters
  } = modelSettings;

  const { buttonBackgroundColor, buttonTextColor } = activeBranding.brandColors;

  const BRANDING_OPTIONS = useMemo((): BrandOption[] => {
    return [
      { title: DEFAULT_BRANDING.brandName, brand: DEFAULT_BRANDING },
      ...brands.map((b): BrandOption => ({ title: b.brandName, brand: b }))
    ];
  }, [brands]);

  const CUSTOM_ENVIRONMENTS: EnvironmentOption[] = environments.map(
    ({ environmentName, publicUrl, presets, thumbnailUrl }): EnvironmentOption => {
      const fileType = publicUrl
        .split('/')
        .reverse()[0]
        .split('.')[1] as keyof typeof E3DModelFileTypes;
      return {
        title: environmentName,
        value: {
          key: environmentName,
          envPreset: environmentName,
          customEnvData: {
            type: EEnvironmentTypes.file,
            data: {
              path: publicUrl,
              fileType: E3DModelFileTypes[fileType],
              scale: presets.scale
            }
          }
        },
        preview: thumbnailUrl,
        isAvailable: false
      };
    }
  );

  const CUSTOM_CUBEMAPS: CubemapOption[] = cubemaps.map(
    ({
      cubemapName,
      nyPlaneUrl,
      nxPlaneUrl,
      pxPlaneUrl,
      nzPlaneUrl,
      pzPlaneUrl,
      pyPlaneUrl
    }): CubemapOption => ({
      title: cubemapName,
      value: {
        key: cubemapName,
        cubemapName,
        imagePaths: {
          nyPlaneUrl: convertUrlToNotFromCacheUrl(nyPlaneUrl),
          nxPlaneUrl: convertUrlToNotFromCacheUrl(nxPlaneUrl),
          pxPlaneUrl: convertUrlToNotFromCacheUrl(pxPlaneUrl),
          nzPlaneUrl: convertUrlToNotFromCacheUrl(nzPlaneUrl),
          pzPlaneUrl: convertUrlToNotFromCacheUrl(pzPlaneUrl),
          pyPlaneUrl: convertUrlToNotFromCacheUrl(pyPlaneUrl)
        }
      },
      preview: nzPlaneUrl,
      isAvailable: false
    })
  );

  useEffect((): void => {
    fetchCustomBranding(model, hasCustomBrandingAccess);
    if (hasCustomEnvironmentAccess) {
      fetchCustomEnvironments(model);
      fetchCustomCubemaps(model);
    }
  }, [
    fetchCustomBranding,
    fetchCustomEnvironments,
    fetchCustomCubemaps,
    hasCustomBrandingAccess,
    hasCustomEnvironmentAccess,
    model
  ]);

  useEffect((): void => {
    if (isArMode) {
      dispatch(stopZenMode());
      dispatch(setIsSidebarHidden(true));
    }
  }, [dispatch, isArMode]);

  useEffect((): void => {
    const isDesktopDevice = window.innerWidth > DEVICE_SIZES.tabletLarge;
    setIsSettingsActive(isDesktopDevice || !isCommentsBarActive);
  }, [isCommentsBarActive]);

  useEffect((): void => {
    window.dispatchEvent(new Event('sidebarStateChange'));
  }, [isSidebarHidden]);

  const handleOptionChange =
    (key: string): ((option: SelectOption) => void) =>
    ({ value }): void => {
      dispatch(setModelSettings({ ...modelSettings, [key]: value }));
    };

  const handleSwitcherChange =
    (key: string): ((value: boolean) => void) =>
    (value): void => {
      dispatch(setModelSettings({ ...modelSettings, [key]: value }));
    };

  const handleBrandingSelectChange = ({ brand }: BrandOption): void => {
    dispatch(setBranding(brand));
    const isDefaultTheme = !brand.brandColors.buttonBackgroundColor.length;
    isDefaultTheme
      ? setThemeCSSVariables(DEFAULT_THEME_CSS_VARIABLES)
      : convertBrandColorsToCSSVariables(brand.brandColors);
  };

  const handleDownloadButtonClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      openNotification(ESnackbarStyle.SUCCESS, MODEL_DOWNLOADING);
      const response = await downloadModel(model.id);
      const type = response.data.type;
      const modelName = response.headers['content-disposition'].split('filename=')[1].split('"')[1];
      const blob = new Blob([response.data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = modelName;
      a.click();
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleDeleteModelClick = (): void => {
    const action = async (): Promise<void> => {
      try {
        dispatch(startLoader());
        await deleteModel(model.id);
        dispatch(clearViewerDataState());
        dispatch(closeConfirmModal());
        history.push(isTeamModel ? `/workspace/${model.teamId}` : isAuth ? '/my-workspace' : '/');
        openNotification(ESnackbarStyle.SUCCESS, MODEL_DELETED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: <ModalDeleteModel model={model!} action={action} />
      })
    );
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setModelName(event.target.value));
  };

  const handleSettingsTabClick = (): void => {
    setIsSettingsActive(true);
    dispatch(toggleCommentsBar(false));
  };

  const handleCommentsTabClick = (): void => {
    if (isAnonymousModel) {
      dispatch(showModal(<ModalFeatureSignedOut feature={EPremiumFeature.COMMENTS} />));
    } else {
      setIsSettingsActive(false);
      dispatch(toggleCommentsBar(true));
    }
  };

  const handleSidebarArrowClick = (): void => {
    dispatch(setIsSidebarHidden(!isSidebarHidden));
  };

  const handleTransformFieldChange = useCallback(
    (fieldType: ETransformFiledType, values: AxisValues): void => {
      switch (fieldType) {
        case ETransformFiledType.SCALE:
          dispatch(changeModelScale(values));
          break;
        case ETransformFiledType.OFFSET:
          dispatch(changeModelOffset(values));
          break;
        case ETransformFiledType.ROTATION:
          dispatch(changeModelRotation(values));
      }
    },
    [dispatch]
  );

  const hasDeleteAccess = useMemo(
    (): boolean => (isTeamModel ? hasEditAccess : isModelOwner),
    [hasEditAccess, isModelOwner, isTeamModel]
  );

  const lightOptions = hasLightingAccess
    ? LIGHTING_OPTIONS.map((option): LightingOption => ({ ...option, isAvailable: true }))
    : LIGHTING_OPTIONS;
  const envOptions = hasEnvironmentAccess
    ? [...CUSTOM_ENVIRONMENTS, ...ENVIRONMENT_OPTIONS].map(
        (option): EnvironmentOption => ({ ...option, isAvailable: true })
      )
    : ENVIRONMENT_OPTIONS;

  const [first, ...others] = CUBEMAP_OPTIONS;
  const cubemapOptions = hasCubemapAccess
    ? [first, ...CUSTOM_CUBEMAPS, ...others].map(
        (option): CubemapOption => ({ ...option, isAvailable: true })
      )
    : CUBEMAP_OPTIONS;

  return (
    <Styled.SidebarContainer isArMode={isArMode} isZenMode={isZenMode}>
      <Styled.ArrowLeftContainer onClick={handleSidebarArrowClick}>
        <Styled.ArrowLeft src={LeftArrow} isSidebarHidden={isSidebarHidden} />
      </Styled.ArrowLeftContainer>
      <Styled.FullModeContainer>
        {!isArMode && !isQuickView && (
          <Styled.SidebarHeader>
            <Styled.SidebarHeaderItem
              activeColor={buttonBackgroundColor}
              onClick={handleSettingsTabClick}
              isActive={isSettingsActive}
            >
              Settings
            </Styled.SidebarHeaderItem>
            <Styled.SidebarHeaderItem
              activeColor={buttonBackgroundColor}
              onClick={handleCommentsTabClick}
              isActive={!isSettingsActive}
            >
              Comments
              <Styled.SidebarHeaderItemCounter>
                {plainComments.length <= 99999 ? plainComments.length : '99999+'}
              </Styled.SidebarHeaderItemCounter>
            </Styled.SidebarHeaderItem>
          </Styled.SidebarHeader>
        )}
        {isSettingsActive ? (
          <Styled.FullModeContent>
            <Styled.SettingsOptionContainer>
              <Styled.FieldLabel>Lighting</Styled.FieldLabel>
              <Styled.SettingsOption>
                <SettingsSelect
                  selectId='lighting-select'
                  options={lightOptions}
                  activeValue={lighting}
                  action={handleOptionChange('lighting')}
                  isModelOwner={isModelOwner}
                  isLighting
                />
              </Styled.SettingsOption>
            </Styled.SettingsOptionContainer>

            {!isArMode && (
              <Styled.SettingsOptionContainer>
                <Styled.FieldLabel>Environment</Styled.FieldLabel>
                <Styled.SettingsOption>
                  <SettingsSelect
                    selectId='environment-select'
                    options={envOptions}
                    activeValue={environment}
                    action={handleOptionChange('environment')}
                    isModelOwner={isModelOwner}
                  />
                </Styled.SettingsOption>
              </Styled.SettingsOptionContainer>
            )}

            <Styled.SettingsOptionContainer>
              <Styled.FieldLabel>Cubemap</Styled.FieldLabel>
              <Styled.SettingsOption>
                <SettingsSelect
                  selectId='cubemap-select'
                  options={cubemapOptions}
                  activeValue={cubemap}
                  action={handleOptionChange('cubemap')}
                  isModelOwner={isModelOwner}
                />
              </Styled.SettingsOption>
            </Styled.SettingsOptionContainer>

            {BRANDING_OPTIONS.length > 1 && (
              <Styled.SettingsOptionContainer>
                <Styled.FieldLabel>Branding</Styled.FieldLabel>
                <Styled.SettingsOption>
                  <BrandingSelect
                    activeOption={{ title: activeBranding.brandName, brand: activeBranding }}
                    onChangeOption={handleBrandingSelectChange}
                    options={BRANDING_OPTIONS}
                  />
                </Styled.SettingsOption>
              </Styled.SettingsOptionContainer>
            )}

            <Styled.SettingsOptionContainer>
              <Styled.FieldLabel>Scale</Styled.FieldLabel>
              <Styled.SettingsOption>
                <TransformField
                  action={handleTransformFieldChange}
                  initialValues={model.presets.scale}
                  fieldType={ETransformFiledType.SCALE}
                />
              </Styled.SettingsOption>
            </Styled.SettingsOptionContainer>

            <Styled.SettingsOptionContainer>
              <Styled.FieldLabel>Offset</Styled.FieldLabel>
              <Styled.SettingsOption>
                <TransformField
                  action={handleTransformFieldChange}
                  initialValues={model.presets.offset}
                  fieldType={ETransformFiledType.OFFSET}
                />
              </Styled.SettingsOption>
            </Styled.SettingsOptionContainer>

            <Styled.SettingsOptionContainer>
              <Styled.FieldLabel>Rotation</Styled.FieldLabel>
              <Styled.SettingsOption>
                <TransformField
                  action={handleTransformFieldChange}
                  initialValues={model.presets.rotation}
                  fieldType={ETransformFiledType.ROTATION}
                />
              </Styled.SettingsOption>
            </Styled.SettingsOptionContainer>

            <Styled.SwitchersContainer>
              <Styled.SwitchBlock>
                <Styled.FieldLabel>Auto-rotate</Styled.FieldLabel>
                <Styled.SettingsOption>
                  <Switcher
                    switcherId={'auto-rotate-button'}
                    action={handleSwitcherChange('autoRotate')}
                    isActive={autoRotate}
                  />
                </Styled.SettingsOption>
              </Styled.SwitchBlock>

              {!isArMode && (
                <Styled.SwitchBlock>
                  <Styled.FieldLabel>Shadows</Styled.FieldLabel>
                  <Styled.SettingsOption>
                    <Switcher
                      switcherId={'shadows-button'}
                      action={handleSwitcherChange('enableShadows')}
                      isActive={enableShadows}
                    />
                  </Styled.SettingsOption>
                </Styled.SwitchBlock>
              )}

              <Styled.SwitchBlock>
                <Styled.FieldLabel>Wireframe</Styled.FieldLabel>
                <Styled.SettingsOption>
                  <Switcher
                    switcherId={'wireframe-button'}
                    action={handleSwitcherChange('wireframe')}
                    isActive={wireframe}
                  />
                </Styled.SettingsOption>
              </Styled.SwitchBlock>

              {!isArMode && (
                <Styled.SwitchBlock>
                  <Styled.FieldLabel>Dimensions</Styled.FieldLabel>
                  <Styled.SettingsOption>
                    <Switcher
                      switcherId={'dimensions-button'}
                      action={handleSwitcherChange('showDimensions')}
                      isActive={showDimensions}
                    />
                  </Styled.SettingsOption>
                </Styled.SwitchBlock>
              )}

              {!isQuickView && (
                <Styled.SwitchBlock>
                  <Styled.FieldLabel>AR in Meters</Styled.FieldLabel>
                  <Styled.SettingsOption>
                    <Switcher
                      switcherId={'scale-in-meters-button'}
                      action={handleSwitcherChange('arScaleInMeters')}
                      isActive={arScaleInMeters}
                      isDisabled={isArMode}
                      onClickIfDisabled={(): void => {
                        openNotification(
                          ESnackbarStyle.HOLD_UP,
                          `Cannot be modified while the AR is running. First you need to stop AR.`
                        );
                      }}
                    />
                  </Styled.SettingsOption>
                </Styled.SwitchBlock>
              )}
            </Styled.SwitchersContainer>

            <Styled.AnimationsBlock>
              <Styled.FieldLabel>Play Animations</Styled.FieldLabel>
              <Styled.SettingsOption>
                <Select
                  options={animations}
                  activeValue={animation}
                  action={handleOptionChange('animation')}
                />
              </Styled.SettingsOption>
            </Styled.AnimationsBlock>

            <Styled.ModelInfoContainer>
              <Styled.FieldLabel>Model Name</Styled.FieldLabel>
              <Styled.InputContainer>
                <Styled.ModelNameInput
                  value={modelName}
                  onChange={handleNameChange}
                  placeholder='Model name'
                  readOnly={!(isAnonymousModel || hasEditAccess || !isSampleModel)}
                />
                {(hasEditAccess || isAnonymousModel) &&
                  modelName.length > MAX_MODEL_NAME_LENGTH && (
                    <ErrorMessage>Only 32 symbols allowed</ErrorMessage>
                  )}
              </Styled.InputContainer>
            </Styled.ModelInfoContainer>
            {hasEditAccess && (
              <Styled.AutoSavingText>
                {isSettingsSaving ? LOADING_TEXT : AUTO_SAVE_TEXT}
              </Styled.AutoSavingText>
            )}

            <ModelMetadata model={model} />

            {isAuth && !isSampleModel && !isQuickView && !isAnonymousModel && (
              <Styled.ActionButton
                activeColor={buttonBackgroundColor}
                activeTextColor={buttonTextColor}
                onClick={handleDownloadButtonClick}
                className='download'
                id='download-model-button'
              >
                Download Model
              </Styled.ActionButton>
            )}

            {hasDeleteAccess && !isArMode && (
              <Styled.ActionButton
                activeColor={buttonBackgroundColor}
                activeTextColor={buttonTextColor}
                onClick={handleDeleteModelClick}
                id='delete-model-button'
              >
                Delete model
              </Styled.ActionButton>
            )}

            {isAuth && isQuickView && (
              <Styled.SaveModelBlockContainer>
                <SaveModelBlock expandUp />
              </Styled.SaveModelBlockContainer>
            )}
          </Styled.FullModeContent>
        ) : (
          <CommentsBar modelId={model.id} />
        )}
      </Styled.FullModeContainer>
    </Styled.SidebarContainer>
  );
};

export default SidebarModel;
