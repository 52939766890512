import React from 'react';

const HomeIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        viewBox='0 0 10 12'
        preserveAspectRatio='xMidYMax meet'
        fill='currentColor'
      >
        <path d='M0.964456 11.1808C0.696551 11.1808 0.46894 11.0869 0.281621 10.8992C0.0938738 10.7118 0 10.4842 0 10.2163V4.4296C0 4.27957 0.0347205 4.1349 0.104161 3.99559C0.174031 3.85628 0.267904 3.74376 0.385782 3.65804L4.2436 0.764669C4.32933 0.700372 4.42042 0.652149 4.51687 0.62C4.61331 0.587851 4.71512 0.571777 4.82228 0.571777C4.92944 0.571777 5.03124 0.587851 5.12769 0.62C5.22413 0.652149 5.31522 0.700372 5.40095 0.764669L9.25877 3.65804C9.37665 3.74376 9.47031 3.85628 9.53975 3.99559C9.60962 4.1349 9.64456 4.27957 9.64456 4.4296V10.2163C9.64456 10.4842 9.55068 10.7118 9.36294 10.8992C9.17562 11.0869 8.948 11.1808 8.6801 11.1808H0.964456ZM0.964456 10.2163H8.6801V4.4296L4.82228 1.53623L0.964456 4.4296V10.2163Z' />
      </svg>
    </div>
  );
};

export default HomeIcon;
