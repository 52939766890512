export enum ESnackbarStyle {
  ERROR,
  SUCCESS,
  HOLD_UP
}

export enum ERouteType {
  GUARDED = 'GUARDED',
  NOT_GUARDED = 'NOT_GUARDED',
  DEFAULT = 'DEFAULT'
}

export enum EUserRole {
  MASTER_ADMIN = 'master_admin',
  ADMIN = 'admin',
  VIEWER = 'viewer',
  SENDER = 'sender'
}

export enum EFeatureId {
  INSTANT_3D_VIEWING = 'instant_3d_viewing',
  MAKE_SHORT_LINKS = 'make_short_links',
  QR_CODE_GENERATION = 'qr_code_generation',
  MODEL_SCALE_CONTROL = 'model_scale_control',
  MODEL_SHADOWS = 'model_shadows',
  MODEL_DOWNLOADS = 'model_downloads',
  MODEL_ANIMATIONS = 'model_animations',
  MATERIAL_BREAKDOWN = 'material_breakdown',
  GEOMETRY_BREAKDOWN = 'geometry_breakdown',
  TEXTURE_BREAKDOWN = 'texture_breakdown',
  LIGHT_OPTIONS = 'light_options',
  ENVIRONMENT_OPTIONS = 'environment_options',
  CUBEMAP_OPTIONS = 'cubemap_options',
  WEB_AR_VIEWING_MODE = 'web_ar_viewing_mode',
  MAKE_3D_NOTES = 'make_3d_notes',
  IFRAME_EMBEDDED_MODEL = 'iframe_embedded_model',
  SIMPLE_CUSTOMIZATION = 'simple_customization',
  DOMAINS_WHITELIST = 'domains_whitelist',
  IFRAME_CUSTOM_BUTTONS = 'iframe_custom_buttons',
  CUSTOM_UI_BRANDING = 'custom_ui_branding',
  CUSTOM_3D_ENVIRONMENT = 'custom_3d_environment'
}

export enum ESharingStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  RESTRICTED = 'restricted'
}

export enum EModelAccessLevel {
  VIEW_ACCESS = 'view',
  VIEW_AR_ACCESS = 'view_ar',
  COMMENT_ACCESS = 'comment',
  EDIT_ACCESS = 'edit'
}

export enum EQuotaName {
  ACTIVE_MODELS_LIMIT = 'active_models_limit',
  MODEL_SIZE_LIMIT = 'model_size_limit',
  MODEL_VIEWS_LIMIT = 'model_views_limit',
  MONTHLY_AR_VIEWS_LIMIT = 'monthly_ar_views_limit',
  IFRAME_CUSTOM_BUTTONS = 'iframe_custom_buttons'
}

export enum EPremiumFeature {
  AR,
  COMMENTS
}

export enum ETransformFiledType {
  SCALE = 'scale',
  OFFSET = 'offset',
  ROTATION = 'rotation'
}

export enum ESampleModelName {
  car = 'car',
  bee = 'bee',
  viking_scene = 'viking_scene'
}

export enum ETeamRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MANAGER = 'manager',
  VIEWER = 'viewer',
  DESIGNER = 'designer'
}

export enum EPaymentType {
  UPDATE_USER_PLAN,
  CREATE_TEAM_PLAN,
  UPDATE_TEAM_PLAN
}

export enum EModelType {
  TEAM,
  OWNER,
  ANONYMOUS,
  SAMPLE,
  QUICK_VIEW,
  SHARED
}

export enum EConfirmModalHeader {
  DELETE,
  CHECKOUT
}

export enum ELoadingState {
  START = 'Starting',
  LIMIT_VALIDATION = 'Checking permissions...',
  EXTRACTING_FILES = 'Extracting files...',
  SECURITY_CHECKING = 'Security checking...',
  MODEL_PARSING = 'Model parsing...',
  PACKING_FILES = 'Packing files...',
  LOADING_MODEL = 'Loading model...',
  SETUP_BRANDING = 'Setup branding...',
  SETUP_MODEL_SETTINGS = 'Setup model settings...',
  CHECKING_PERMISSIONS = 'Checking permissions...',
  PREPARING_SCENE = 'Preparing scene...',
  LOADED = 'Loaded'
}

export enum ENotificationPreferenceMode {
  ALL = 'all',
  MENTIONED = 'mentioned',
  MUTED = 'muted'
}

export enum MyWorkspacePageTab {
  MY_WORKSPACE,
  USAGE,
  CUSTOMIZATION,
  SUBSCRIPTION,
  BILLING
}

export enum TeamWorkspacePageTab {
  TEAM_MODELS,
  USAGE,
  MEMBERS,
  CUSTOMIZATION,
  BILLING,
  SETTINGS,
  SUBSCRIPTION
}

export enum SampleModel {
  CAR = 'car',
  BEE = 'bee',
  VIKING_SCENE = 'viking_scene'
}
