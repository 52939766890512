import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { COLORS } from 'shared/constants/colors';

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 30px;
  position: relative;

  @media ${DEVICE.tablet} {
    padding: 20px 10px;
  }
`;

export const PageContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 20px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderTopContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

export const WorkspaceName = styled.h1`
  color: ${COLORS.mischka};
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
`;

export const HeaderBottomContent = styled.div`
  display: flex;

  @media ${DEVICE.tablet} {
    align-items: center;
    flex-direction: column;
  }
`;

export const TabsContainer = styled.nav`
  display: flex;
  width: 100%;
`;

export const TabIconContainer = styled.div`
  width: 20px;
  height: 20px;
`;

export const PageTab = styled.div<{ isActiveTab: boolean }>`
  width: 22%;
  max-width: 200px;
  min-width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${({ isActiveTab }): string => (isActiveTab ? COLORS.turbo : COLORS.doveGray)};
  color: ${({ isActiveTab }): string => (isActiveTab ? COLORS.black : COLORS.white)};
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  transition: all 0.2s;

  &:not(:first-child) {
    margin-left: 9px;
  }

  &.my-workspace-tab {
    ${TabIconContainer} {
      width: 24px;
      height: 18px;
    }
  }

  @media ${DEVICE.desktop} {
    width: ${({ isActiveTab }): string => (isActiveTab ? '100%' : '30%')};
    max-width: initial;
    min-width: initial;

    &:not(:first-child) {
      margin-left: 4px;
    }

    & > span {
      display: ${({ isActiveTab }): string => (isActiveTab ? 'block' : 'none')};
    }
  }

  @media ${DEVICE.mobile} {
    & > span {
      display: none;
    }
  }
`;

export const SelectTabContainer = styled.div`
  height: 40px;
  margin-left: 9px;

  @media ${DEVICE.desktop} {
    margin-left: 4px;
  }
`;

export const SearchSelectContainer = styled.div`
  padding-left: 30px;
  margin-left: auto;
  width: 240px;
  min-width: 240px;

  @media ${DEVICE.tablet} {
    padding-left: initial;
    margin-left: initial;
    margin-top: 20px;
  }
`;

export const UploadModelOverlay = styled.div<{ isDragActive: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: ${({ isDragActive }): string => (isDragActive ? 'initial' : 'none')};
  z-index: 2000;
  background: rgba(217, 217, 217, 0.8);
  display: ${({ isDragActive }): string => (isDragActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlusIcon = styled.img`
  width: 23px;
  height: 23px;
`;

export const UploadDescription = styled.span`
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const PageFooter = styled.footer`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;
`;

export const DragDropMessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 20px 6px;
  background-color: rgba(0, 0, 0, 50%);
  backdrop-filter: blur(6px);
  border-radius: 14px 14px 0 0;
  pointer-events: initial;

  .info-icon {
    color: ${COLORS.turbo};
    font-size: 20px;
  }

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const DragDropMessage = styled.span`
  color: ${COLORS.mischka};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 0 10px;
  text-align: center;
`;

export const SecureIcon = styled.img`
  width: 26px;
  height: 26px;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    transform: scale(1.2);
  }
`;
