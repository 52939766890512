import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { ESnackbarStyle, Preference } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { changePreferenceValue, getUserPreferences } from 'services/api/preferencesService';
import Check from 'assets/images/check.svg';
import { openNotification } from 'utils/notification-utils';

const NotificationsPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [preferences, setPreferences] = useState<Preference[]>([]);

  const fetchUserPreferences = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      const response = (await getUserPreferences()).data;
      setPreferences(response);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch]);

  useEffect((): void => {
    fetchUserPreferences();
  }, [fetchUserPreferences]);

  const handleCheckboxClick =
    (preference: Preference): (() => Promise<void>) =>
      async (): Promise<void> => {
        try {
          dispatch(startLoader());
          await changePreferenceValue(preference.propertyName, !preference.propertyValue);
          await fetchUserPreferences();
          openNotification(
            ESnackbarStyle.SUCCESS,
            'Your notification subscription has been successfully changed'
          );
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      };

  return (
    <Styled.TabContent>
      {preferences.map(
        (preference): JSX.Element => (
          <Styled.PreferenceItem
            key={preference.propertyTitle}
            onClick={handleCheckboxClick(preference)}
          >
            <Styled.Checkbox>
              {preference.propertyValue && <img src={Check} alt='Check' />}
            </Styled.Checkbox>
            <span>{preference.propertyTitle}</span>
          </Styled.PreferenceItem>
        )
      )}
    </Styled.TabContent>
  );
};

export default NotificationsPage;
