import React from 'react';

const BellCrossedOutIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        viewBox='0 0 11 11'
        style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%'
        }}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11 10.2036L0.670476 0L0 0.656794L2.47238 3.09779C2.18865 3.61942 2.03934 4.20199 2.03762 4.79408V7.89704L0.99 8.93136V9.44852H8.90476L10.3348 10.8604L11 10.2036ZM3.08524 8.4142V4.79408C3.08524 4.47861 3.14286 4.16831 3.26333 3.8787L7.85714 8.4142H3.08524ZM4.65667 9.96568H6.75191C6.75191 10.24 6.64153 10.5031 6.44506 10.6971C6.2486 10.891 5.98213 11 5.70429 11C5.42644 11 5.15997 10.891 4.96351 10.6971C4.76704 10.5031 4.65667 10.24 4.65667 9.96568ZM3.76095 1.73766C4.03857 1.56182 4.34238 1.43253 4.65667 1.32393V1.17395C4.65667 0.899635 4.76704 0.636551 4.96351 0.442579C5.15997 0.248606 5.42644 0.139633 5.70429 0.139633C5.98213 0.139633 6.2486 0.248606 6.44506 0.442579C6.64153 0.636551 6.75191 0.899635 6.75191 1.17395V1.32393C8.30762 1.77903 9.37095 3.19088 9.37095 4.79408V7.27645L8.32333 6.24212V4.79408C8.32333 4.10828 8.0474 3.45057 7.55623 2.96564C7.06507 2.48071 6.3989 2.20827 5.70429 2.20827C5.29571 2.20827 4.89238 2.31171 4.53095 2.49788L3.76095 1.73766Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default BellCrossedOutIcon;
