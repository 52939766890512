import React from 'react';
import * as Styled from './styles';
import { ModalSubtitle, ModalTitle } from 'shared/styles';
import { Subscriptions } from 'shared/components';

const ModalUpgradeAccount: React.FC = (): JSX.Element => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalContent>
        <ModalSubtitle>Upgrade your account to unlock premium features</ModalSubtitle>
        <ModalTitle>Upgrade Account</ModalTitle>
        <Subscriptions isModal withCustomPlan />
      </Styled.ModalContent>
    </Styled.ModalWindowContainer>
  );
};

export default ModalUpgradeAccount;
