import React from 'react';
import * as Styled from './styles';
import Twitter from 'assets/images/twitter.svg';
import Linkedin from 'assets/images/linkedin.svg';
import Youtube from 'assets/images/youtube.svg';
import Cube from 'assets/images/logo-cube.png';
import { Select } from './components/select';
import { REASON_OPTIONS } from './constants';
import { ESnackbarStyle, SelectOption } from 'shared/types';
import { useFormik } from 'formik';
import { validation } from 'services/validation';
import { getFieldError } from 'utils/form-utils';
import { sendContactUsRequest } from 'services/api/supportService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { useAppDispatch } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';

type InitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  reason: string;
  text: string;
};

const ContactUsPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    reason: '',
    text: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values): Promise<void> => {
      dispatch(startLoader());
      try {
        await sendContactUsRequest(values);
        formik.resetForm();
        openNotification(ESnackbarStyle.SUCCESS, 'Your message has been successfully sent');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.CONTACT_US
  });

  const { values, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  const handleSelectChange = (option: SelectOption): void => {
    setFieldValue('reason', option.value);
  };

  const activeOption = REASON_OPTIONS.find((i): boolean => i.value === values.reason);

  return (
    <>
      <Styled.PageContainer>
        <Styled.PageContent>
          <Styled.CubeImage className='left' src={Cube} alt='Cube' />
          <Styled.CubeImage className='top' src={Cube} alt='Cube' />
          <Styled.CubeImage className='bottom' src={Cube} alt='Cube' />
          <Styled.ContentLeftSide>
            <Styled.PageTitle>Contact Us</Styled.PageTitle>
            <Styled.PageSubtitle>
              Fill in the form and our team will get back to you within 48 hours.
            </Styled.PageSubtitle>
            <Styled.SocialNetworks>
              <Styled.FollowUsText>Follow us</Styled.FollowUsText>
              <Styled.SocialNetworksList>
                <Styled.SocialNetworkItem href='https://twitter.com/glbee_' target='_blank'>
                  <img src={Twitter} alt='Twitter' />
                </Styled.SocialNetworkItem>
                <Styled.SocialNetworkItem href='https://linkedin.com/company/glbee' target='_blank'>
                  <img src={Linkedin} alt='Linkedin' />
                </Styled.SocialNetworkItem>
                <Styled.SocialNetworkItem
                  href='https://www.youtube.com/channel/UCQiaqtpLt_bnQLW6skR0epQ'
                  target='_blank'
                >
                  <img src={Youtube} alt='Youtube' />
                </Styled.SocialNetworkItem>
              </Styled.SocialNetworksList>
            </Styled.SocialNetworks>
          </Styled.ContentLeftSide>
          <Styled.ContentRightSide>
            <Styled.ContactUsForm onSubmit={handleSubmit}>
              <Styled.TopFormRow>
                <Styled.FieldContainer className='top-row'>
                  <Styled.FormInput
                    name='firstName'
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='First Name'
                  />
                  {getFieldError(formik, 'firstName')}
                </Styled.FieldContainer>
                <Styled.FieldContainer className='top-row'>
                  <Styled.FormInput
                    name='lastName'
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Last Name'
                  />
                  {getFieldError(formik, 'lastName')}
                </Styled.FieldContainer>
              </Styled.TopFormRow>
              <Styled.FieldContainer>
                <Styled.FormInput
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Enter your email address'
                  type='email'
                />
                {getFieldError(formik, 'email')}
              </Styled.FieldContainer>
              <Styled.SelectContainer>
                <Select
                  options={REASON_OPTIONS}
                  action={handleSelectChange}
                  activeValue={activeOption || null}
                />
                {getFieldError(formik, 'reason')}
              </Styled.SelectContainer>
              <Styled.MessageFieldContainer>
                <Styled.MessageField
                  name='text'
                  value={values.text}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Write message here'
                />
                {getFieldError(formik, 'text')}
              </Styled.MessageFieldContainer>
              <Styled.SendMessageButton type='submit' id='contact-us-form-send-message-button'>
                Send Message
              </Styled.SendMessageButton>
            </Styled.ContactUsForm>
          </Styled.ContentRightSide>
        </Styled.PageContent>
      </Styled.PageContainer>
    </>
  );
};

export default ContactUsPage;
