import React from 'react';
import * as Styled from './styles';
import {SimpleUser} from 'shared/types/user';
import CubeIcon from 'assets/images/cube.svg';

interface Props {
  usersList: SimpleUser[];
  onSelect: (user: SimpleUser) => void;
}

export const SuggestedPeopleList: React.FC<Props> = ({usersList, onSelect}): JSX.Element => {
  return (
    <Styled.List>
      {!!usersList.length && usersList.map((user): JSX.Element => (
        <Styled.Item
          key={user.email}
          onClick={(): void => {
          onSelect(user);
        }}>
          <Styled.Photo alt={'User'} src={CubeIcon} />

          <Styled.Info>
            <Styled.Credentials>{user.firstName + ' ' + user.lastName}</Styled.Credentials>

            <Styled.Email>{user.email}</Styled.Email>
          </Styled.Info>
        </Styled.Item>
      ))}
    </Styled.List>
  );
};
