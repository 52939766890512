import * as THREE from 'three';
import { ESnackbarStyle, ResponseModel } from 'shared/types';
import { LoadingManager as Manager } from 'three';
import { createLoadingManager } from 'utils/file-processing-utils';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';
import { openNotification } from 'utils/notification-utils';
import { allowed3dModelAssetsTypes } from 'shared/constants/allowed-3d-model-assets-types';

export abstract class BaseLoadModelStrategy {
  private loadingPriorityAssetError: boolean = false;

  protected loadingManager: Manager;
  protected setLoadingContent: (content: ResponseModel[]) => void;

  protected constructor(
    protected renderer: THREE.WebGLRenderer,
    protected onModelResourcesLoaded?: () => void
  ) {
    const { loadingManager, setLoadingContent } = createLoadingManager();

    this.loadingManager = loadingManager;
    this.loadingManager.onLoad = this.onLoad.bind(this);
    this.loadingManager.onError = this.onError.bind(this);
    this.setLoadingContent = setLoadingContent;
  }

  private onLoad(): void {
    if (this.onModelResourcesLoaded) {
      requestAnimationFrame(this.onModelResourcesLoaded);
    }
  }

  private onError(url: string): void {
    if (this.loadingPriorityAssetError) return;

    const isExternal = url.startsWith('https://');
    const isPriority = allowed3dModelAssetsTypes.priorityAssets.some((ext): boolean => url.endsWith(ext));

    if (isExternal && isPriority) {
      this.loadingPriorityAssetError = true;
      openNotification(ESnackbarStyle.HOLD_UP, 'Sorry, an error occurred. Can you refresh?');
    }
  }

  public abstract loadModel(data: LoadModelData): Promise<LoadModelResult>;
}
