import * as FBXParser from 'fbx-parser';
import { FBXData, FBXNode, FBXReader } from 'fbx-parser';

import { IFBXMetaData } from '../interfaces/model-parse/fbx-types';

export class FPXParser {
  constructor(buffer: Buffer) {
    this._buffer = buffer;
  }

  private _buffer: Buffer;

  private _fbxMetaData: IFBXMetaData = {
    textures: [],
    vertices: 0,
    animationsCount: 0,
    materialsCount: 0,
    texturesCount: 0,
    animations: [],
    unlitFlag: 0 // ? because of complexity and unpredicted accuracy of parsing, this value stays 0 fow now
  };

  public parse(): IFBXMetaData {
    let fbx: FBXData;
    try {
      fbx = FBXParser.parseBinary(this._buffer);
    } catch (e) {
      fbx = FBXParser.parseText(new TextDecoder().decode(this._buffer));
    }

    const root: any = new FBXReader(fbx);

    if (root.fbx) {
      const objectNodes: FBXNode[] = root.fbx.find(
        (n: FBXNode): boolean => n.name === 'Objects'
      ).nodes;

      this._parseVertices(objectNodes.filter((n): boolean => n.name === 'Geometry'));
      this._parseTextures(objectNodes.filter((n): boolean => n.name === 'Texture'));
      this._parseAnimations(objectNodes.filter((n): boolean => n.name === 'AnimationStack'));
      this._parseMaterials(objectNodes.filter((n): boolean => n.name === 'Material'));
    }

    return this._fbxMetaData;
  }

  private _parseVertices(geometryNodes: FBXNode[]): void {
    geometryNodes.forEach((geometryNode: any): void => {
      const vertices = geometryNode.nodes.find((n: FBXNode): boolean => n.name === 'Vertices');
      if (vertices) {
        this._fbxMetaData.vertices += vertices?.props[0].length / 3;
      }
    });
  }

  private _parseMaterials(materialNodes: FBXNode[]): void {
    if (materialNodes) {
      materialNodes.forEach((): void => {
        this._fbxMetaData.materialsCount += 1;
      });
    }
  }

  private _parseTextures(textureNodes: FBXNode[]): void {
    this._fbxMetaData.texturesCount = textureNodes.length;
    textureNodes.forEach((textureNode): void => {
      const fileData: any = textureNode.nodes.filter((n): boolean => n.name === 'RelativeFilename');
      if (fileData[0].props) {
        const file = fileData[0].props[0].replaceAll('\\', '/');
        if (!this._fbxMetaData.textures.includes(file)) {
          this._fbxMetaData.textures.push(file);
        }
      }
    });
  }

  private _parseAnimations(animationNodes: FBXNode[]): void {
    if (animationNodes) {
      animationNodes.forEach((animation: any): void => {
        if (animation?.props[1]) {
          this._fbxMetaData.animations.push({
            name: animation.props[1].slice(animation.props[1].lastIndexOf(':')).trim()
          });
        }
        this._fbxMetaData.animationsCount += 1;
      });
    }
  }
}
