import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  width: 70vw;
  min-width: 310px;
  max-width: 1000px;
  overflow-y: scroll;
  display: flex;
  padding: 30px 0;
  color: ${COLORS.mischka};
  overflow-x: hidden;
  max-height: 90vh;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
    border-radius: 2px;
  }

  @media ${DEVICE.tabletLarge} {
    flex-direction: column;
    width: 60vw;
    padding: 30px;
  }

  @media ${DEVICE.tablet} {
    width: 95vw;
  }
`;

export const LeftSide = styled.div`
  width: 55%;
  padding: 0 15px 0 40px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    padding: 0;
  }
`;

export const BillingFrequencyBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CheckboxField = styled.div<{ isDisabled?: boolean }>`
  width: 100%;
  height: 46px;
  background: #363636;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-top: 20px;
  cursor: pointer;
  opacity: ${({ isDisabled }): string => (isDisabled ? '0.6' : '1')};
  pointer-events: ${({ isDisabled }): string => (isDisabled ? 'none' : 'initial')};

  span {
    margin-left: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
  }
`;

export const Checkbox = styled.div<{ isActive: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${({ isActive }): string => (isActive ? COLORS.turbo : COLORS.white)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 8px;
    height: 8px;
  }
`;

export const PaymentInfoBlock = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${DEVICE.tabletLarge} {
    padding: 30px 0 20px;
  }
`;

export const CardFormContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 8px;
  padding: 25px 15px;
`;

export const CardSavedText = styled.div`
  margin-top: 20px;
  padding: 0 13px;
  height: 46px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  background: ${COLORS.mineShaft};
`;

export const CardForm = styled.div`
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 4px;
`;

export const CardFormFooter = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StripeLabel = styled.img`
  height: 30px;
  margin-left: auto;

  @media ${DEVICE.mobile} {
    height: 22px;
  }
`;

export const RightSide = styled.div`
  width: 45%;
  padding: 0 40px 0 15px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    padding: 0;
    margin-top: 50px;
  }
`;

export const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
`;

export const RightSideHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PromoCodeButton = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const PayBlock = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 300px;
  background: #363636;
  border-radius: 7px 7px 0 0;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
`;

export const AccountPlan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
`;

export const PriceField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  margin-top: 20px;
`;

export const PayBlockFooter = styled.div`
  margin-top: auto;
`;

export const TermsConditions = styled.a`
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  cursor: pointer;

  &:hover {
    color: ${COLORS.white};
    text-decoration: underline;
  }
`;

export const SaveCardButton = styled.button`
  width: 100px;
  height: 33px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  border-radius: 4px;
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const PayButton = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 33px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  border-radius: 4px;
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const PromoCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const PromoCodeLabel = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
`;

export const PromoCodeField = styled.div`
  display: flex;
  margin-top: 13px;
`;

export const PromoCodeInput = styled.input`
  height: 33px;
  width: 100%;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: 1px solid ${COLORS.white};
  border-radius: 4px;
  color: ${COLORS.white};
  margin-right: 6px;
  padding: 0 5px;
`;

export const ApplyButton = styled.button`
  height: 33px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  border: none;
  border-radius: 4px;
  background: ${COLORS.corn};
  color: ${COLORS.black};
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.turbo};
  }
`;
