import React from 'react';

const TeamIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 19 14'
        fill='currentColor'
        preserveAspectRatio='xMaxYMax meet'
      >
        <path d='M12.5307 7.13707C12.9471 7.13707 13.301 6.99113 13.5922 6.69926C13.8841 6.40805 14.03 6.05421 14.03 5.63772C14.03 5.20458 13.8841 4.8464 13.5922 4.56319C13.301 4.27998 12.9471 4.13838 12.5307 4.13838C12.1142 4.13838 11.7603 4.27998 11.4691 4.56319C11.1772 4.8464 11.0313 5.20458 11.0313 5.63772C11.0313 6.05421 11.1772 6.40805 11.4691 6.69926C11.7603 6.99113 12.1142 7.13707 12.5307 7.13707ZM9.23209 10.7355H15.8292V10.5856C15.8292 10.0025 15.5377 9.54835 14.9546 9.22316C14.3715 8.89863 13.5635 8.73637 12.5307 8.73637C11.4978 8.73637 10.6898 8.89863 10.1067 9.22316C9.52363 9.54835 9.23209 10.0025 9.23209 10.5856V10.7355ZM2.16018 13.4343C1.69372 13.4343 1.30655 13.2804 0.998687 12.9725C0.690155 12.664 0.535889 12.2765 0.535889 11.81V1.81439C0.535889 1.36459 0.690155 0.985418 0.998687 0.676886C1.30655 0.369021 1.69372 0.215088 2.16018 0.215088H7.63279C7.84936 0.215088 8.05761 0.256736 8.25752 0.340033C8.45743 0.42333 8.63235 0.539946 8.78229 0.689881L9.53196 1.43955H16.9037C17.3702 1.43955 17.7577 1.59382 18.0662 1.90235C18.3741 2.21022 18.528 2.59738 18.528 3.06384V11.81C18.528 12.2765 18.3741 12.664 18.0662 12.9725C17.7577 13.2804 17.3702 13.4343 16.9037 13.4343H2.16018ZM1.53545 1.81439V11.81C1.53545 11.9933 1.59376 12.1432 1.71038 12.2598C1.82699 12.3764 1.97693 12.4348 2.16018 12.4348H16.9037C17.087 12.4348 17.2369 12.3764 17.3535 12.2598C17.4702 12.1432 17.5285 11.9933 17.5285 11.81V3.06384C17.5285 2.88059 17.4702 2.73066 17.3535 2.61404C17.2369 2.49743 17.087 2.43912 16.9037 2.43912H9.13214L7.90767 1.21465H2.16018C1.97693 1.21465 1.82699 1.27296 1.71038 1.38958C1.59376 1.50619 1.53545 1.6478 1.53545 1.81439ZM1.53545 1.81439V1.21465V12.4348V11.81V1.81439Z' />
      </svg>
    </div>
  );
};

export default TeamIcon;
