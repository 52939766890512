import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';

export const TabContent = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  color: ${COLORS.white};
  position: relative;
`;