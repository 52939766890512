import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModalWindowContainer = styled.div`
  width: 30vw;
  min-width: 500px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  color: ${COLORS.mischka};

  @media ${DEVICE.mobile} {
    min-width: 300px;
  }
`;

export const Notification = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
`;

export const ActionButtons = styled.div`
  margin-top: 30px;
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  padding: 8px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s;

  &.cancel-button {
    background: #484848;
    color: ${COLORS.white};
  }

  &.invite-button {
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }

  &:hover {
    background: ${COLORS.gorse};
    color: ${COLORS.black};
  }
`;
