import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';

export enum DimensionUnits {
  cm = 'CM',
  m = 'M'
}

export enum DimensionTextPlacement {
  up = 'up',
  center = 'center',
  down = 'down'
}

export type SetupFaceData = Omit<UpdateFaceData, 'face'> & {
  element: HTMLElement;
}

export interface UpdateFaceData {
  face: CSS3DObject;
  size: number;
  position: THREE.Vector3;
  rotation?: THREE.Vector3;
  text: string;
}

export interface GetFacesResult {
  xFaceEl: HTMLDivElement;
  yFaceEl: HTMLDivElement;
  zFaceEl: HTMLDivElement;
}

export interface CalcBoundingBoxNDataResult {
  BBSize: THREE.Vector3;
  xFacePosition: THREE.Vector3;
  yFacePosition: THREE.Vector3;
  zFacePosition: THREE.Vector3;
  xFaceText: string;
  yFaceText: string;
  zFaceText: string;
}

export interface CreateFacesData {
  parent: HTMLElement | null;
  factor: number;
  scale: number;
}
