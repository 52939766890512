import { createAction, createReducer } from '@reduxjs/toolkit';
import { AxisValues, CubemapImagePaths, CustomizationState, ThemeSettings } from 'shared/types';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import {
  DEFAULT_CUBEMAP_IMAGE_PATHS,
  DEFAULT_ENV_SETTINGS,
  DEFAULT_THEME_SETTINGS
} from 'shared/constants/customization';

const initialState: CustomizationState = {
  siteTitle: DEFAULT_THEME_SETTINGS.siteTitle,
  siteSubtitle: DEFAULT_THEME_SETTINGS.siteSubtitle,
  brandLogoUrl: DEFAULT_THEME_SETTINGS.brandLogoUrl,
  brandColors: DEFAULT_THEME_SETTINGS.brandColors,
  environmentPreviewURL: null,
  envScale: DEFAULT_ENV_SETTINGS.presets.scale,
  envType: E3DModelFileTypes.gltf,
  envThumbnail: DEFAULT_ENV_SETTINGS.thumbnailUrl,
  cubemapImagePaths: DEFAULT_CUBEMAP_IMAGE_PATHS
};

// Actions

export const setThemeSettings = createAction<ThemeSettings>('customization/setThemeSettings');
export const setSiteTitle = createAction<string>('customization/setSiteTitle');
export const setSiteSubtitle = createAction<string>('customization/setSiteSubtitle');
export const setButtonsColor = createAction<string>('customization/setButtonsColor');
export const setHeaderColor = createAction<string>('customization/setHeaderColor');
export const setTextColor = createAction<string>('customization/setTextColor');
export const setButtonTextColor = createAction<string>('customization/setButtonTextColor');
export const setLogoPath = createAction<string | undefined>('customization/setLogoPath');
export const setEnvType = createAction<E3DModelFileTypes>('customization/setEnvType');
export const setEnvironmentPreviewURL = createAction<string | null>(
  'customization/setEnvironmentPreviewURL'
);
export const changeEnvScale = createAction<AxisValues>('customization/changeEnvScale');
export const setEnvThumbnail = createAction<string>('customization/setEnvThumbnail');
export const setCubemapImagePaths = createAction<CubemapImagePaths>(
  'customization/setCubemapImagePaths'
);
export const clearCustomizationData = createAction('customization/clearCustomizationData');

// Reducer

export const customizationReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setThemeSettings, (state, { payload }): void => {
      state.siteTitle = payload.siteTitle;
      state.siteSubtitle = payload.siteSubtitle;
      state.brandLogoUrl = payload.brandLogoUrl;
      state.brandColors = payload.brandColors;
    })
    .addCase(setSiteTitle, (state, { payload }): void => {
      state.siteTitle = payload;
    })
    .addCase(setSiteSubtitle, (state, { payload }): void => {
      state.siteSubtitle = payload;
    })
    .addCase(setButtonsColor, (state, { payload }): void => {
      state.brandColors.buttonBackgroundColor = payload;
    })
    .addCase(setHeaderColor, (state, { payload }): void => {
      state.brandColors.headerTextColor = payload;
    })
    .addCase(setTextColor, (state, { payload }): void => {
      state.brandColors.normalTextColor = payload;
    })
    .addCase(setButtonTextColor, (state, { payload }): void => {
      state.brandColors.buttonTextColor = payload;
    })
    .addCase(setLogoPath, (state, { payload }): void => {
      state.brandLogoUrl = payload;
    })
    .addCase(setEnvType, (state, { payload }): void => {
      state.envType = payload;
    })
    .addCase(setEnvironmentPreviewURL, (state, { payload }): void => {
      state.environmentPreviewURL = payload;
    })
    .addCase(changeEnvScale, (state, { payload }): void => {
      state.envScale = payload;
    })
    .addCase(setEnvThumbnail, (state, { payload }): void => {
      state.envThumbnail = payload;
    })
    .addCase(setCubemapImagePaths, (state, { payload }): void => {
      state.cubemapImagePaths = payload;
    })
    .addCase(clearCustomizationData, (): CustomizationState => initialState);
});
