import { ETransformFiledType } from 'shared/types';
import {
  THREE_DIGIT_FRACTIONAL_NUMBER,
  THREE_DIGIT_NUMBER,
  TWO_DIGIT_FRACTIONAL_NUMBER,
  TWO_DIGIT_FRACTIONAL_NUMBER_WITH_MINUS,
  TWO_DIGIT_NUMBER,
  TWO_DIGIT_NUMBER_WITH_MINUS
} from './regexps';

export const TRANSFORM_FIELD_TYPES = {
  [ETransformFiledType.SCALE]: {
    title: 'Scale',
    maxValue: 50,
    minValue: 0.001,
    defaultValue: 1,
    regex: TWO_DIGIT_NUMBER,
    fractionalRegex: TWO_DIGIT_FRACTIONAL_NUMBER,
    isLockedByDefault: true
  },
  [ETransformFiledType.OFFSET]: {
    title: 'Offset',
    maxValue: 50,
    minValue: -50,
    defaultValue: 0,
    regex: TWO_DIGIT_NUMBER_WITH_MINUS,
    fractionalRegex: TWO_DIGIT_FRACTIONAL_NUMBER_WITH_MINUS,
    isLockedByDefault: false
  },
  [ETransformFiledType.ROTATION]: {
    title: 'Rotation',
    maxValue: 360,
    minValue: 0,
    defaultValue: 0,
    regex: THREE_DIGIT_NUMBER,
    fractionalRegex: THREE_DIGIT_FRACTIONAL_NUMBER,
    isLockedByDefault: false
  }
};
