import { ESnackbarStyle } from 'shared/types';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  QuestionCircleFilled
} from '@ant-design/icons';

export const SNACKBAR_TYPES = {
  [ESnackbarStyle.ERROR]: {
    message: 'Hold Up!',
    icon: <ExclamationCircleFilled style={{ color: '#FF0202' }} />,
    className: 'error-message'
  },
  [ESnackbarStyle.HOLD_UP]: {
    message: 'Hold Up!',
    icon: <QuestionCircleFilled style={{ color: '#FFE406' }} />,
    className: 'warning-message'
  },
  [ESnackbarStyle.SUCCESS]: {
    message: 'Success!',
    icon: <CheckCircleFilled style={{ color: '#0CD085' }} />,
    className: 'success-message'
  }
};
