import Tooltip from 'antd/es/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import { TooltipPlacement } from 'antd/lib/tooltip';

type Props = {
  children: React.ReactElement;
  content: string | JSX.Element;
  maxWidth?: number;
  placement?: TooltipPlacement;
  showArrow?: boolean;
  showTooltip?: boolean;
};

const CustomTooltip: React.FC<Props> = ({
  children,
  content,
  maxWidth,
  placement,
  showArrow,
  showTooltip = true
}): JSX.Element => {
  const childrenRef = useRef<HTMLDivElement | null>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(!maxWidth);
  const overlayInnerStyle = { backdropFilter: 'blur(10px)' };

  useEffect((): void => {
    const childrenWidth = childrenRef.current?.clientWidth || 0;
    if (!!maxWidth && childrenWidth > maxWidth) {
      setIsTooltipOpen(true);
    }
  }, [maxWidth]);

  return (
    <>
      {showTooltip ? (
        <Tooltip
          open={isTooltipOpen ? undefined : false}
          overlayInnerStyle={overlayInnerStyle}
          placement={placement}
          showArrow={showArrow}
          title={<Styled.TooltipContainer>{content}</Styled.TooltipContainer>}
        >
          {React.cloneElement(children, { ref: childrenRef })}
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
};

export default CustomTooltip;
