import { useCallback, useEffect, useRef, useState } from 'react';
import { CameraData, ModelSettings } from 'shared/types';
import { setScreenshot, uploadModelScreenshot } from 'services/store/reducers/viewerDataReducer';
import { useAppDispatch, useAppSelector } from 'shared/hooks/store-hooks';
import { RootState } from 'services/store';
import { useDebounce } from 'shared/hooks';
import { MODEL_SCREENSHOT_TYPES, SCENE_SCREENSHOT_TYPES } from 'shared/constants/screenshot';
import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';

type Result = {
  setIsSceneFullyLoaded: (value: boolean) => void;
  downloadScreenshot: (
    size: '540px' | '1080px' | '2160px',
    isTransparent: boolean
  ) => Promise<void>;
};

const useScreenshot = (
  getCameraData: (() => Promise<CameraData | undefined>) | null,
  createScreenshot: ((type: EScreenshotTypes) => Promise<string>) | null,
): Result => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { isArMode } = store.arMode;
  const { model, modelSettings } = store.viewerData;
  const { hasEditAccess } = store.modelFeatureAccess;
  const [isSceneFullyLoaded, setIsSceneFullyLoaded] = useState<boolean>(false);
  const [isEnvironmentChanged, setIsEnvironmentChanged] = useState<boolean>(false);
  const [isWithoutCoverImage, setIsWithoutCoverImage] = useState<boolean>(false);
  const debouncedSettings: ModelSettings = useDebounce(modelSettings, 2000);
  const prevSettings = useRef<ModelSettings>(modelSettings);

  const getCurrentCamera = useCallback(async (): Promise<CameraData> => {
    if (!!getCameraData) {
      const cameraData = await getCameraData();
      if (!!cameraData) return cameraData;
    }
    return { screenshot: '' };
  }, [getCameraData]);

  const handleCreateScreenshot = useCallback(async (): Promise<void> => {
    setIsEnvironmentChanged(false);

    const { screenshot } = await getCurrentCamera();

    dispatch(setScreenshot(screenshot));
  }, [dispatch, getCurrentCamera]);

  const updateModelScreenshot = useCallback(async (): Promise<void> => {
    if (!!model) {
      await handleCreateScreenshot();
      await dispatch(uploadModelScreenshot({ modelId: model.id }));
      setIsWithoutCoverImage(false);
    }
  }, [handleCreateScreenshot, dispatch, model]);

  useEffect((): void => {
    setIsWithoutCoverImage(!model?.coverImageUrl);
  }, [model?.coverImageUrl]);

  useEffect((): void => {
    if (isSceneFullyLoaded) {
      handleCreateScreenshot();
    }
  }, [isSceneFullyLoaded, handleCreateScreenshot, model]);

  const checkIsEnvironmentChanged = useCallback((): boolean => {
    const {
      lighting: prevLighting,
      environment: prevEnvironment,
      cubemap: prevCubemap
    } = prevSettings.current;
    prevSettings.current = debouncedSettings;
    const { lighting, environment, cubemap } = debouncedSettings;
    return prevLighting !== lighting || prevEnvironment !== environment || prevCubemap !== cubemap;
  }, [debouncedSettings]);

  useEffect((): void => {
    if (!isEnvironmentChanged) {
      setIsEnvironmentChanged(checkIsEnvironmentChanged());
    }

    const isUpdateAvailable = hasEditAccess && isSceneFullyLoaded && !isArMode;
    const needToUpdate = isEnvironmentChanged || isWithoutCoverImage;

    if (isUpdateAvailable && needToUpdate) {
      updateModelScreenshot();
    }
  }, [
    checkIsEnvironmentChanged,
    isArMode,
    hasEditAccess,
    isSceneFullyLoaded,
    updateModelScreenshot,
    model,
    debouncedSettings,
    isEnvironmentChanged,
    isWithoutCoverImage
  ]);

  const downloadScreenshot = async (
    size: '540px' | '1080px' | '2160px',
    isTransparent: boolean
  ): Promise<void> => {
    if (!createScreenshot) return;

    const type = isTransparent ? MODEL_SCREENSHOT_TYPES[size] : SCENE_SCREENSHOT_TYPES[size];
    const screenshot = await createScreenshot(type);
    const a = document.createElement('a');
    a.href = screenshot;
    a.download = `${model?.modelName || 'model'}-screenshot.jpg`;
    a.click();
  };

  return { setIsSceneFullyLoaded, downloadScreenshot };
};

export default useScreenshot;
