export const allowed3dModelAssetsTypes = {
  _gltf: 'gltf',
  glb: 'glb',
  _fbx: 'fbx',
  _obj: 'obj',
  _mtl: 'mtl',
  _stl: 'stl',
  usdz: 'usdz',
  _usd: 'usd',
  _usda: 'usda',
  _usdc: 'usdc',
  bin: 'bin',
  text: ['txt', 'md'],
  audio: ['m4a', 'mp3', 'wav'],
  materials: ['mat', 'meta', 'prefab', 'ma'],
  other: ['pdf'],
  textures: ['jpg', 'jpeg', 'png', 'tiff', 'tga', 'dds', 'gif', 'bmp', 'vtf', 'ktx2'],
  get gltf(): string[] {
    return [
      this._gltf,
      this.bin,
      ...this.text,
      ...this.textures
    ];
  },
  get fbx(): string[] {
    return [
      this._fbx,
      ...this.text,
      ...this.textures,
      ...this.materials,
      ...this.other
    ];
  },
  get obj(): string[] {
    return [
      this._obj,
      ...this.text,
      this._mtl,
      ...this.textures,
      ...this.materials,
      ...this.other
    ];
  },
  get stl(): string[] {
    return [
      this._stl,
      ...this.text,
      ...this.textures,
      ...this.other
    ];
  },
  get usd(): string[] {
    return [
      this._usd,
      ...this.textures,
      ...this.audio,
      ...this.materials,
      ...this.text,
      ...this.other
    ];
  },
  get usda(): string[] {
    return [
      this._usda,
      ...this.textures,
      ...this.text,
    ];
  },
  get usdc(): string[] {
    return [
      this._usdc,
      ...this.textures,
      ...this.text,
    ];
  },
  get priorityAssets(): string[] {
    return [
      this._gltf,
      this.glb,
      this._fbx,
      this._obj,
      this._stl,
      this.bin,
      this.usdz,
      this._usd,
      this._usda,
      this._usdc
    ];
  },
  get allModels(): string[] {
    return [
      this._gltf,
      this.glb,
      this._fbx,
      this._mtl,
      this._obj,
      this._stl,
      this.usdz,
      this._usd,
      this._usda,
      this._usdc,
    ];
  },
  get allResources(): string[] {
    return [
      this._gltf,
      this.glb,
      this._fbx,
      this._obj,
      this._stl,
      this.usdz,
      this._usd,
      this._usda,
      this._usdc,
      this._mtl,
      this.bin,
      ...this.materials,
      ...this.textures,
      ...this.text,
      ...this.other
    ];
  }
};
