import * as Pages from 'pages';
import { ERouteType, RouteItem } from 'shared/types';

export const ROUTES: RouteItem[] = [
  {
    type: ERouteType.NOT_GUARDED,
    path: [
      '/',
      '/home',
      '/signup',
      '/sign-up',
      '/register',
      '/log-in',
      '/login',
      '/sign-in',
      '/signin',
      '/quick-view',
      '/upload'
    ],
    component: Pages.HomePage
  },
  {
    type: ERouteType.NOT_GUARDED,
    path: '/pricing',
    component: Pages.PricingPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/create-team',
    component: Pages.CreateTeamPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/recents',
    component: Pages.RecentsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/shared-with-me',
    component: Pages.SharedWithMePage
  },
  {
    type: ERouteType.GUARDED,
    path: '/my-workspace',
    component: Pages.MyWorkspacePage
  },
  {
    type: ERouteType.GUARDED,
    path: '/account-settings',
    component: Pages.AccountSettingsPage
  },
  {
    type: ERouteType.DEFAULT,
    path: '/not-found',
    component: Pages.NotFoundPage
  },
  {
    type: ERouteType.NOT_GUARDED,
    path: '/contact-us',
    component: Pages.ContactUsPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/admin-panel',
    component: Pages.AdminPanelPage
  },
  {
    type: ERouteType.GUARDED,
    path: '/workspace/:teamId',
    component: Pages.TeamPage
  },
  {
    type: ERouteType.NOT_GUARDED,
    path: '/:modelId',
    component: Pages.ModelPage
  }
];
