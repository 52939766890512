import {
  EightWallSceneEventDetail,
  EightWallSceneTouchState, IPlacegroundScenePipelineModuleResult, XrSceneResult
} from 'shared/interfaces';
import * as THREE from 'three';
import { EArScalingTypes } from 'shared/enums/EArScalingTypes';
import { AR_SCENE_CANVAS_ID, MAIN_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';
import { getCameraAngleRelativeToObject, getObjectScaleFactor } from 'utils/scenes-utils';
import { AxisValues, BrandingSettings, ThemeSettings } from 'shared/types';
import { DEFAULT_THEME_SETTINGS } from 'shared/constants/customization';
import { DisposeSceneService } from 'services/strategy-services';
import { TapToPlaceBlock, CustomLoader8W, BlackBackgroundBeforeEightWall } from './HTMLElements';
import { BaseScene } from 'shared/webgl/scenes';
import {
  EW_MODEL_MAX_SCALE_FACTOR,
  EW_MODEL_MIN_SCALE_FACTOR
} from 'shared/constants/scene-settings-constants';
import { SRGBToLinearConvertorShader } from 'shared/webgl/gl-shaders';
import { PartialObjectOptions } from 'shared/interfaces';

export class EighthWallScene extends DisposeSceneService implements BaseScene {
  private readonly ARSceneCanvas: HTMLCanvasElement;

  private initialized: boolean = false;
  private launched: boolean = false;
  private allowPlacing: boolean = false;
  private placed: boolean = false;
  private viewerCanvas: HTMLElement | null = null;
  private initialScale: THREE.Vector3 | undefined;
  private scaleFactor: number = 1;
  private desiredScaleFactor: number = 4;
  private minScaleFactor: number = this.desiredScaleFactor / 5;
  private maxScaleFactor: number = this.desiredScaleFactor * 5;
  private placedPosition: AxisValues = { x: 0, y: 0, z: 0 };
  private placedRotation: AxisValues = { x: 0, y: 0, z: 0 };
  private receivedPosition: AxisValues = { x: 0, y: 0, z: 0 };
  private receivedRotation: AxisValues = { x: 0, y: 0, z: 0 };
  private internalState: { previousState: null | EightWallSceneTouchState } = {
    previousState: null
  };
  private cameraFeedRenderer: any = null;
  private canvasWidth: any = null;
  private canvasHeight: any = null;
  private videoWidth: any = null;
  private videoHeight: any = null;
  private texProps: any = null;
  private activeBranding: Pick<ThemeSettings, 'brandLogoUrl' | 'brandColors'> =
    DEFAULT_THEME_SETTINGS;

  public objectWrapper: THREE.Object3D = new THREE.Object3D();
  public externalObjectWrapper: THREE.Object3D = new THREE.Object3D();
  public lightWrapper: THREE.Object3D = new THREE.Object3D();
  public camera: THREE.PerspectiveCamera | undefined;
  public renderer: THREE.WebGLRenderer | undefined;
  public scene: THREE.Scene | undefined;
  public scaleInMeters: boolean = false;
  public refreshLight: ((scale: AxisValues) => void) | undefined;
  public setUpCubemapControllerScene: ((scene: THREE.Scene) => void) | undefined;
  public onLoaded: (() => void) | undefined;
  public rotateRimLight: ((angle: number) => void) | undefined;
  public renderCallbackStack: (() => void)[] = [];

  constructor() {
    super();

    this.ARSceneCanvas = document.createElement('canvas');
    this.ARSceneCanvas.id = AR_SCENE_CANVAS_ID;

    document.body.appendChild(this.ARSceneCanvas);
    this.externalObjectWrapper.add(this.objectWrapper);
  }

  private setInitialScale(): void {
    this.scaleFactor = 1;
    this.internalState = {
      previousState: null
    };

    if (!this.objectWrapper.children[0] || !this.objectWrapper.children[0].children[0]) return;

    this.objectWrapper.children[0].scale.set(1, 1, 1);

    const objectScaleFactor = getObjectScaleFactor(
      this.objectWrapper.children[0].children[0],
      this.desiredScaleFactor
    );

    if (
      !this.scaleInMeters &&
      (objectScaleFactor < this.minScaleFactor || objectScaleFactor > this.maxScaleFactor)
    ) {
      this.objectWrapper.children[0].scale.setScalar(objectScaleFactor);
    }

    this.initialScale = this.objectWrapper.scale.clone();

    if (this.refreshLight) {
      this.refreshLight(this.objectWrapper.scale);
    }
  }

  public setup(scaleInMeters: boolean): void {
    this.scaleInMeters = scaleInMeters;
    this.allowPlacing = !scaleInMeters;

    this.updateRendererSettings();
  }

  private updateRendererSettings(): void {
    if (!this.renderer) return;

    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
  }

  private updateCoachingOverlayColors({
    headerTextColor,
    buttonTextColor
  }: ThemeSettings['brandColors']): void {
    if (
      !(window as any).CoachingOverlay ||
      !(window as any).XR8 ||
      !(window as any).XR8.XrController
    )
      return;

    (window as any).CoachingOverlay.configure({
      animationColor: headerTextColor,
      promptColor: buttonTextColor
    });
  }

  public setActiveBranding(brandingSettings: BrandingSettings): void {
    this.activeBranding = brandingSettings.id ? brandingSettings : DEFAULT_THEME_SETTINGS;

    this.updateCoachingOverlayColors(this.activeBranding.brandColors);

    TapToPlaceBlock.update(this.activeBranding.brandColors);

    CustomLoader8W.init(this.activeBranding!);
  }

  private changeXrScaleType(isAbsoluteScale: boolean): void {
    if (!(window as any).XR8 || !(window as any).XR8.XrController) return;

    isAbsoluteScale
      ? (window as any).XR8.XrController.configure({ scale: EArScalingTypes.absolute })
      : (window as any).XR8.XrController.configure({ scale: EArScalingTypes.responsive });
  }

  private handleShowTTPBlock(): void {
    if (!this.allowPlacing) {
      this.allowPlacing = true;

      (window as any).CoachingOverlay.configure({
        disablePrompt: true
      });
    }

    TapToPlaceBlock.show(this.activeBranding.brandColors);
  }

  public updateByOptions(options: PartialObjectOptions): void {
    if (!this.placedPosition || !this.objectWrapper || !this.lightWrapper || !options) return;

    if (
      options.offset &&
      (options.offset.x !== this.receivedPosition.x ||
        options.offset.y !== this.receivedPosition.y ||
        options.offset.z !== this.receivedPosition.z)
    ) {
      this.receivedPosition.x = options.offset.x;
      this.receivedPosition.y = options.offset.y;
      this.receivedPosition.z = options.offset.z;
    }

    if (
      options.rotation &&
      (options.rotation.x !== this.receivedRotation.x ||
        options.rotation.y !== this.receivedRotation.y ||
        options.rotation.z !== this.receivedRotation.z)
    ) {
      this.receivedRotation.x = THREE.MathUtils.degToRad(options.rotation.x);
      this.receivedRotation.y = THREE.MathUtils.degToRad(options.rotation.y);
      this.receivedRotation.z = THREE.MathUtils.degToRad(options.rotation.z);
    }

    this.updatePosition();
    this.updateRotation();
  }

  private updatePosition(): void {
    if (!this.objectWrapper || !this.lightWrapper) return;

    this.objectWrapper.position.set(
      this.placedPosition.x + this.receivedPosition.x,
      this.placedPosition.y + this.receivedPosition.y,
      this.placedPosition.z + this.receivedPosition.z
    );
    this.lightWrapper.position.set(
      this.placedPosition.x + this.receivedPosition.x,
      this.placedPosition.y + this.receivedPosition.y,
      this.placedPosition.z + this.receivedPosition.z
    );
  }

  private updateRotation(): void {
    if (!this.objectWrapper || !this.lightWrapper) return;

    this.objectWrapper.rotation.set(
      this.placedRotation.x + this.receivedRotation.x,
      this.placedRotation.y + this.receivedRotation.y,
      this.placedRotation.z + this.receivedRotation.z
    );
  }

  private updateScale(): void {
    if (!this.initialScale || !this.objectWrapper) return;

    this.objectWrapper.scale.x = this.scaleFactor * this.initialScale.x;
    this.objectWrapper.scale.y = this.scaleFactor * this.initialScale.y;
    this.objectWrapper.scale.z = this.scaleFactor * this.initialScale.z;

    if (this.refreshLight) {
      this.refreshLight(this.objectWrapper.scale);
    }
  }

  private placegroundScenePipelineModule = (): IPlacegroundScenePipelineModuleResult => {
    const raycaster = new THREE.Raycaster();
    let surface: any;

    const camTexture = new THREE.Texture();

    const initXrScene = ({ scene, camera, renderer }: XrSceneResult): void => {
      surface = new THREE.Mesh(
        new THREE.PlaneGeometry(100, 100, 1, 1),
        new THREE.ShadowMaterial({
          opacity: 0.5
        })
      );

      surface.rotateX(-Math.PI / 2);
      surface.position.set(0, 0, 0);
      scene.add(surface);

      const baseLight = new THREE.AmbientLight(0xffffff, 3);
      baseLight.position.set(0, 100, 0);
      scene.add(baseLight);

      camera.position.set(0, 3, 0);

      if (this.setUpCubemapControllerScene) {
        this.setUpCubemapControllerScene(scene);
      }

      this.setInitialScale();

      scene.background = camTexture;

      scene.add(this.lightWrapper);

      requestAnimationFrame((): void => {
        if (this.onLoaded) this.onLoaded();
      });
    };

    const updateSize = ({
      videoWidth,
      videoHeight,
      canvasWidth,
      canvasHeight,
      GLctx
    }: any): void => {
      this.cameraFeedRenderer = (window as any).XR8.GlTextureRenderer.create({
        GLctx,
        toTexture: { width: canvasWidth, height: canvasHeight },
        flipY: false,
        fragmentSource: SRGBToLinearConvertorShader.fragmentSource
      });
      this.canvasWidth = canvasWidth;
      this.canvasHeight = canvasHeight;
      this.videoWidth = videoWidth;
      this.videoHeight = videoHeight;
    };

    const placeObject = (pointX: number, pointZ: number): void => {
      this.placedPosition.x = pointX;
      this.placedPosition.y = 0;
      this.placedPosition.z = pointZ;
      this.updatePosition();

      if (!this.placed) {
        (window as any).XR8.Threejs.xrScene().scene.add(this.objectWrapper);

        this.placed = true;
      }
    };

    const getTouchState = (event: any): EightWallSceneTouchState | null => {
      if (event.touches.length === 0) {
        return null;
      }

      const touchList = [];
      touchList.push(...event.touches);

      const touchState: any = {
        eventType: event.type,
        touchCount: touchList.length
      };

      const centerPositionRawX =
        touchList.reduce((sum, touch): number => sum + touch.clientX, 0) / touchList.length;
      const centerPositionRawY =
        touchList.reduce((sum, touch): number => sum + touch.clientY, 0) / touchList.length;

      touchState.positionRaw = { x: centerPositionRawX, y: centerPositionRawY };

      const screenScale = 2 / (window.innerWidth + window.innerHeight);

      touchState.position = {
        x: centerPositionRawX * screenScale,
        y: centerPositionRawY * screenScale
      };
      touchState.positionAbs = {
        x: (centerPositionRawX / window.innerWidth) * 2 - 1,
        y: -(centerPositionRawY / window.innerHeight) * 2 + 1
      };

      if (touchList.length >= 2) {
        const spread =
          touchList.reduce(
            (sum, touch): number =>
              sum +
              Math.sqrt(
                Math.pow(centerPositionRawX - touch.clientX, 2) +
                  Math.pow(centerPositionRawY - touch.clientY, 2)
              ),
            0
          ) / touchList.length;

        touchState.spread = spread * screenScale;
      }

      return touchState;
    };

    const emitGestureEvent = (event: any): void => {
      event.preventDefault();
      const currentState = getTouchState(event);
      const { previousState } = this.internalState;

      const gestureContinues =
        previousState && currentState && currentState.touchCount === previousState.touchCount;

      const gestureEnded = previousState && !gestureContinues;
      const gestureStarted = currentState && !gestureContinues;

      if (gestureEnded) {
        const endTime = performance.now();

        if (
          previousState &&
          previousState.startTime &&
          endTime - previousState.startTime < 300 &&
          event.type === 'touchend' &&
          previousState.startEventType === 'touchstart' &&
          previousState.touchCount === 1 &&
          this.allowPlacing
        ) {
          handlePlaceObject(previousState);
        }

        this.internalState.previousState = null;
      }

      if (gestureStarted) {
        currentState.startTime = performance.now();
        currentState.startPosition = currentState.position;
        currentState.startPositionAbs = currentState.positionAbs;
        currentState.startEventType = currentState.eventType;
        currentState.startSpread = currentState.spread;

        this.internalState.previousState = currentState;
      }

      if (gestureContinues) {
        const eventDetail: EightWallSceneEventDetail = {
          positionChange: {
            x: currentState.position.x - previousState.position.x,
            y: currentState.position.y - previousState.position.y
          }
        };

        if (currentState.spread) {
          eventDetail.spreadChange = currentState.spread - previousState.spread;
        }

        Object.assign(previousState, currentState);

        Object.assign(eventDetail, previousState);

        if (currentState?.touchCount === 1) {
          handleFingerRotate(eventDetail);
        }

        if (currentState?.touchCount === 2) {
          handlePinchScale(eventDetail);
        }
      }
    };

    const handlePlaceObject = ({
      positionAbs: { x, y }
    }: {
      positionAbs: EightWallSceneTouchState['positionAbs'];
    }): void => {
      const { camera } = (window as any).XR8.Threejs.xrScene();

      if (!camera) return;

      raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

      const intersects = raycaster.intersectObject(surface);

      if (intersects.length === 1 && intersects[0].object === surface) {
        placeObject(intersects[0].point.x, intersects[0].point.z);
      }

      TapToPlaceBlock.remove();
    };

    const handleFingerRotate = (detail: EightWallSceneEventDetail): void => {
      const factor = 5;

      if (!detail.positionChange) return;

      this.placedRotation.y += detail.positionChange.x * factor;
      this.updateRotation();
    };

    const handlePinchScale = (detail: EightWallSceneEventDetail): void => {
      if (!detail.spreadChange || !detail.startSpread) return;

      this.scaleFactor *= 1 + detail.spreadChange / detail.startSpread;
      this.scaleFactor = Math.min(
        Math.max(this.scaleFactor, EW_MODEL_MIN_SCALE_FACTOR),
        EW_MODEL_MAX_SCALE_FACTOR
      );

      this.updateScale();
    };

    return {
      name: 'placeground',

      listeners: [{ event: 'coaching-overlay.hide', process: this.handleShowTTPBlock.bind(this) }],

      onAttach: ({ videoWidth, videoHeight, canvasWidth, canvasHeight, GLctx }): void => {
        updateSize({ videoWidth, videoHeight, canvasWidth, canvasHeight, GLctx });
      },

      onStart: ({ canvas }: any): void => {
        const { scene, camera, renderer }: XrSceneResult = (window as any).XR8.Threejs.xrScene();

        this.initialized = true;
        this.scene = scene;
        this.renderer = renderer;
        this.camera = camera;

        this.updateRendererSettings();
        this.startAnimationLoop();

        initXrScene({ scene, camera, renderer });

        CustomLoader8W.remove();

        if (!this.scaleInMeters) {
          this.handleShowTTPBlock();
        }

        canvas.addEventListener('touchstart', emitGestureEvent, true);
        canvas.addEventListener('touchend', emitGestureEvent, true);
        canvas.addEventListener('touchmove', emitGestureEvent, true);

        (window as any).XR8.XrController.updateCameraProjectionMatrix({
          origin: camera.position,
          facing: camera.quaternion
        });
      },

      onDeviceOrientationChange: ({ videoWidth, videoHeight, GLctx }): void => {
        updateSize({
          videoWidth,
          videoHeight,
          canvasWidth_: this.canvasWidth,
          canvasHeight_: this.canvasHeight,
          GLctx
        });
      },

      onVideoSizeChange: ({ videoWidth, videoHeight, canvasWidth, canvasHeight, GLctx }): void => {
        updateSize({ videoWidth, videoHeight, canvasWidth, canvasHeight, GLctx });
      },

      onCanvasSizeChange: ({ GLctx, videoWidth, videoHeight, canvasWidth, canvasHeight }): void => {
        updateSize({ videoWidth, videoHeight, canvasWidth, canvasHeight, GLctx });
      },

      onUpdate: ({ processCpuResult }): void => {
        const { reality } = processCpuResult;

        if (!reality) {
          return;
        }

        this.texProps.__webglTexture = this.cameraFeedRenderer.render({
          renderTexture: reality.realityTexture,
          viewport: (window as any).XR8.GlTextureRenderer.fillTextureViewport(
            this.videoWidth,
            this.videoHeight,
            this.canvasWidth,
            this.canvasHeight
          )
        });
      },

      onProcessCpu: ({ frameStartResult }): void => {
        const { cameraTexture } = frameStartResult;
        const { renderer, camera } = (window as any).XR8.Threejs.xrScene();

        this.texProps = renderer.properties.get(camTexture);
        this.texProps.__webglTexture = cameraTexture;

        if (this.rotateRimLight) {
          getCameraAngleRelativeToObject(camera, this.objectWrapper, this.rotateRimLight);
        }
      }
    };
  };

  private onxrloaded = (): void => {
    if (!this.ARSceneCanvas) return;

    this.changeXrScaleType(this.scaleInMeters);
    this.updateCoachingOverlayColors(this.activeBranding.brandColors);

    (window as any).XR8.addCameraPipelineModules([
      (window as any).XR8.GlTextureRenderer.pipelineModule(),
      (window as any).XR8.Threejs.pipelineModule(),
      (window as any).XR8.XrController.pipelineModule(),
      (window as any).XRExtras.AlmostThere.pipelineModule(),
      (window as any).XRExtras.FullWindowCanvas.pipelineModule(),
      (window as any).XRExtras.Loading.pipelineModule(),
      (window as any).XRExtras.RuntimeError.pipelineModule(),
      (window as any).CoachingOverlay.pipelineModule(),

      this.placegroundScenePipelineModule()
    ]);

    (window as any).XR8.run({ canvas: this.ARSceneCanvas });
  };

  public run(): void {
    this.viewerCanvas = document.getElementById(MAIN_SCENE_CANVAS_ID);

    if (this.viewerCanvas) {
      BlackBackgroundBeforeEightWall.init(this.ARSceneCanvas);

      this.ARSceneCanvas.style.display = 'block';
      this.viewerCanvas.style.display = 'none';
    }

    if (this.launched) {
      return;
    }

    this.setUp();

    this.launched = true;
  }

  public stop(): void {
    if (this.viewerCanvas) {
      BlackBackgroundBeforeEightWall.remove();

      this.ARSceneCanvas.style.display = 'none';
      this.viewerCanvas.style.display = 'block';
    }

    this.stopAnimationLoop();
    this.resetWebGLState();
    (window as any).XR8.stop();
    (window as any).XR8.clearCameraPipelineModules();

    TapToPlaceBlock.remove();

    this.placed = false;
    this.launched = false;
  }

  private load(): void {
    (window as any).XRExtras.Loading.showLoading({
      onxrloaded: (): void => {
        this.onxrloaded();
      }
    });
  }

  private setUp(): void {
    (window as any).XRExtras
      ? this.load()
      : window.addEventListener('xrextrasloaded', this.load.bind(this), { once: true });

    if (!this.initialized) {
      window.onload = (): void => {
        (window as any).XRExtras
          ? this.load()
          : window.addEventListener('xrextrasloaded', this.load.bind(this), { once: true });
      };
    }

    CustomLoader8W.init(this.activeBranding!);
  }

  protected animate(): void {
    this.renderCallbackStack.forEach((cb): void => {
      cb();
    });
  }
}
