import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PlanIcon = styled.img`
  height: 60px;
  margin-top: 30px;
`;

export const PlanWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  height: max-content;
  padding: 5px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.mobile} {
    width: 100%;
    max-width: initial;
    padding-top: 0;
  }
`;

export const PlanContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(352.13deg, #1b1a1a 3.17%, #464646 103.41%);
  border-radius: 8px;
  color: ${COLORS.white};
  position: relative;

  @media ${DEVICE.desktop} {
    margin-top: 0;
  }

  @media ${DEVICE.mobile} {
    border-radius: 0 0 8px 8px;
  }
`;

export const PlanIconContainer = styled.div`
  height: 60px;
`;

export const PlanIconShadow = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background: rgba(117, 117, 117, 0.59);
  opacity: 0.8;
  filter: blur(60px);
  border-radius: 50%;
`;

export const PlanTitle = styled.span`
  margin-top: 50px;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
`;

export const PlanSubtitle = styled.span`
  margin-top: 6px;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
`;

export const PaymentDescription = styled.span`
  margin-top: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
`;

export const PriceSection = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 46px;
`;

export const CustomPrice = styled.span`
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  padding: 0 30px;
`;

export const Currency = styled.span`
  margin-top: -10px;
  font-size: 14px;
  line-height: 16px;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: 50px;
  line-height: 50px;
`;

export const Duration = styled.span`
  font-size: 15px;
  line-height: 16px;
  margin-top: auto;
`;

export const PlanDescription = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 19px;
`;

export const SelectButton = styled.button`
  margin-top: 30px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
  background: ${COLORS.corn};
  font-family: ${FONTS.interRegular};
  color: ${COLORS.black};
  cursor: pointer;

  &:hover {
    background: ${COLORS.turbo};
  }
`;

export const AvailableFeatures = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const FeatureItemContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);

  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.19);
  }
`;

export const FeatureItem = styled.div<{ isAvailable: boolean }>`
  width: 210px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  color: ${({ isAvailable }): string => (isAvailable ? COLORS.white : '#504E4E')};

  img {
    width: 10px;
    height: 8px;
    margin-right: 10px;
  }

  span {
    word-break: break-word;
  }
`;
