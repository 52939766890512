import React, { useState } from 'react';
import * as Styled from './styles';
import { ChangeTeamName } from './components/change-team-name';
import { SettingsList } from './components/settings-list';
import DeleteTeam from './components/delete-team/DeleteTeam';
import { useAppSelector } from 'shared/hooks';
import { ETeamRole, TeamsState } from 'shared/types';

enum ESettingsTab {
  CHANGE_TEAM_NAME,
  DELETE_TEAM
}

const SettingsTab = (): React.ReactElement => {
  const { activeTeam, teamPlan, teamRole } = useAppSelector((store): TeamsState => store.teams);
  const [activeSettingsTab, setActiveSettingsTab] = useState<ESettingsTab | null>(null);

  const closeTab = async (): Promise<void> => {
    setActiveSettingsTab(null);
  };

  const getSettingsContent = (): React.ReactElement => {
    switch (activeSettingsTab) {
      case ESettingsTab.CHANGE_TEAM_NAME:
        return <ChangeTeamName teamName={activeTeam?.teamName || ''} closeTab={closeTab} />;
      case ESettingsTab.DELETE_TEAM:
        return <DeleteTeam closeTab={closeTab} />;
      default:
        return <SettingsList setActiveSettingsTab={setActiveSettingsTab} />;
    }
  };

  const isAdminRole = teamRole === ETeamRole.OWNER || teamRole === ETeamRole.ADMIN;

  return (
    <Styled.PageContainer>
      <Styled.PageHeader>
        <Styled.WorkspaceName>
          <span>Workspace Name:</span>
          <span className='workspace-name'>{activeTeam?.teamName || ''}</span>
        </Styled.WorkspaceName>
        <Styled.WorkspaceData>
          <Styled.DataItem>
            <span>Workspace ID:</span>
            <span className='data-value'>{activeTeam?.id || ''}</span>
          </Styled.DataItem>
          {isAdminRole && (
            <>
              <Styled.DataItem>
                <span>Seats:</span>
                <span className='data-value'>{`${activeTeam?.seatsCount}/${activeTeam?.totalSeats}`}</span>
              </Styled.DataItem>
              <Styled.DataItem>
                <span>Subscription Tier:</span>
                <span className='data-value'>{teamPlan?.productName}</span>
              </Styled.DataItem>
            </>
          )}
        </Styled.WorkspaceData>
      </Styled.PageHeader>
      <Styled.PageContent>{getSettingsContent()}</Styled.PageContent>
    </Styled.PageContainer>
  );
};

export default SettingsTab;
