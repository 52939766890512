import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  max-width: 1200px;
  max-height: 930px;
  width: 90vw;
  height: 95vh;
  color: ${COLORS.white};
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
    border-radius: 2px;
  }

  @media${DEVICE.tablet} {
    flex-direction: column;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 55px 80px;

  @media${DEVICE.mobile} {
    padding: 40px 10px;
  }
`;
