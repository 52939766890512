import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './styles';
import Copy from 'assets/images/copy.svg';
import Code from 'assets/images/code.svg';
import GlbeeQrCode from 'assets/images/glbee-qr-code.png';
import LinkImage from 'assets/images/link.svg';
import Hint from 'assets/images/hint.svg';
import SettingsIcon from 'assets/images/settings.svg';
import LockIcon from 'assets/images/lock-icon.svg';
import LogoAR from 'assets/images/logo-ar.png';
import {
  EModelAccessLevel,
  EModelType,
  EPremiumFeature,
  ESnackbarStyle,
  Model
} from 'shared/types';
import { useCopyToClipboard } from 'shared/hooks/helper-hooks/copy-to-clipboard';
import { useQrCodeGenerator } from 'shared/hooks/ui-hooks/qr-code-generator';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { showModal } from 'services/store/reducers/modalReducer';
import {
  CustomTooltip,
  ModalEmbedModel,
  ModalFeatureSignedIn,
  ModalLogin,
  ModalSharing,
  ModalSharingPermissions
} from 'shared/components';
import {
  changePublicAccessLevel,
  fetchModelPermissions
} from 'services/store/reducers/modelPermissionsReducer';
import { openNotification } from 'utils/notification-utils';
import { NEED_TO_LOGIN, RESTRICT_AR_VIEWING } from 'shared/constants/notifications';
import { RootState } from 'services/store';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import Spinner from 'assets/images/spinner.svg';
import { openAlert } from 'utils/alert-utils';
import { CubeOrbitControllerScene, MainScene } from 'shared/webgl/scenes';
import { EMBED_REQUIRED_PUBLIC_ACCESS } from 'shared/constants/styled-notifications';

type Props = {
  model: Model;
  isSharePanelActive: boolean;
  mainScene: MainScene | null;
  cubeOCScene: CubeOrbitControllerScene | null;
  isCollapsed?: boolean;
  isARTabActive: boolean;
  setIsShareARTabActive: (isActive: boolean) => void;
};

const SharePanel: React.FC<Props> = ({
  mainScene,
  cubeOCScene,
  model,
  isARTabActive,
  setIsShareARTabActive,
  isSharePanelActive
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const { copyToClipboard } = useCopyToClipboard();
  const store = useAppSelector((store): RootState => store);
  const { isAuth } = store.auth;
  const { isPublishedModel, isArViewShared } = store.modelPermissions;
  const { hasEditAccess, hasArViewAccess } = store.modelFeatureAccess;
  const { isArMode } = store.arMode;
  const { isEmbeddedModelMode, modelType, modelName } = store.viewerData;
  const [isShareTab, setIsShareTab] = useState<boolean>(true);
  const isSampleModel = modelType === EModelType.SAMPLE;
  const isModelOwner = modelType === EModelType.OWNER;
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;
  const sampleModelShareLink = `${process.env.REACT_APP_URL}?sample-model=${modelName}`;
  const shareModelLink = isSampleModel ? sampleModelShareLink : model.shortLinkUrl;
  const shareModelLinkWithARMode = isSampleModel
    ? `${sampleModelShareLink}&ar=1`
    : `${model.shortLinkUrl}?ar=1`;
  const {
    isQrCodeLoading: isArQrLoading,
    createQRCode: createArQRCode,
    downloadQrCode: downloadArQrCode,
    qrCodeURL: arQrCodeURL
  } = useQrCodeGenerator(shareModelLinkWithARMode, LogoAR);
  const {
    isQrCodeLoading: isShareQrLoading,
    createQRCode: createShareQRCode,
    downloadQrCode: downloadShareQrCode,
    qrCodeURL: shareQrCodeURL
  } = useQrCodeGenerator(shareModelLink);
  const shareLink = (isShareTab ? shareModelLink : shareModelLinkWithARMode).replace(
    'https://',
    ''
  );
  const isDownloadDisabled = isShareTab
    ? isShareQrLoading || (!isSampleModel && !isPublishedModel)
    : isArQrLoading || (!isSampleModel && !isArViewShared);
  const isArQrCodeCreated = useRef<boolean>(false);

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  useEffect((): void => {
    if (!isSharePanelActive) {
      setIsShareARTabActive(false);
      setIsShareTab(true);
    }
  }, [setIsShareARTabActive, isSharePanelActive]);

  useEffect((): void => {
    if (isARTabActive) {
      setIsShareTab(false);
    }
  }, [isARTabActive]);

  useEffect((): void => {
    if (window.innerWidth < DEVICE_SIZES.tabletLarge) {
      setIsShareTab(!isArMode);
    }
  }, [isArMode]);

  useEffect((): void => {
    if (!isSampleModel) {
      dispatch(fetchModelPermissions({ model, signal }));
    }
  }, [dispatch, model, signal, isSampleModel]);

  useEffect((): void => {
    if (!hasArViewAccess) {
      setIsShareTab(true);
    }
  }, [hasArViewAccess]);

  useEffect((): void => {
    if (isShareTab) {
      if (isPublishedModel || isSampleModel) {
        createShareQRCode();
      }
      isArQrCodeCreated.current = false;
    } else {
      if (!isArQrCodeCreated.current) {
        createArQRCode();
        isArQrCodeCreated.current = true;
      }
    }
  }, [createArQRCode, createShareQRCode, isPublishedModel, isShareTab, isSampleModel]);

  const handleCopyLinkButtonClick = async (): Promise<void> => {
    if (isAnonymousModel) {
      dispatch(showModal(<ModalSharing />));
    } else {
      isShareTab
        ? await copyToClipboard(shareModelLink)
        : await copyToClipboard(shareModelLinkWithARMode);
    }
  };

  const handleDownloadQrCodeClick =
    (format: 'svg' | 'png'): (() => Promise<void>) =>
    async (): Promise<void> => {
      if (isAnonymousModel) {
        dispatch(showModal(<ModalSharing />));
      } else {
        isShareTab ? await downloadShareQrCode(format) : await downloadArQrCode(format);
      }
    };

  const handleSharingPermissionsClick = (): void => {
    dispatch(showModal(isAnonymousModel ? <ModalSharing /> : <ModalSharingPermissions />));
  };

  const handleEmbedModelClick = (): void => {
    if (isPublishedModel || isSampleModel) {
      dispatch(
        showModal(
          <ModalEmbedModel
            mainScene={mainScene}
            cubeOCScene={cubeOCScene}
            hasEditAccess={hasEditAccess}
          />
        )
      );
    }
  };

  const handleShareTabClick = (): void => {
    if (isAnonymousModel) {
      dispatch(showModal(<ModalSharing />));
    } else {
      setIsShareTab(true);
    }
  };

  const handleArViewTabClick = (): void => {
    if (isAnonymousModel) {
      dispatch(showModal(<ModalSharing />));
    } else {
      if (isEmbeddedModelMode && !hasArViewAccess) return;
      if (hasArViewAccess) {
        setIsShareTab(false);
      } else {
        isModelOwner
          ? dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.AR} />))
          : isAuth
          ? openAlert(RESTRICT_AR_VIEWING)
          : openNotification(ESnackbarStyle.HOLD_UP, NEED_TO_LOGIN);
        if (!isAuth) {
          dispatch(showModal(<ModalLogin />));
        }
      }
    }
  };

  const handleCreateLinkClick = async (): Promise<void> => {
    if (isAnonymousModel) {
      dispatch(showModal(<ModalSharing />));
    } else {
      if (!isEmbeddedModelMode) {
        await handleCopyLinkButtonClick();
      }
      const accessLevel = isShareTab
        ? [EModelAccessLevel.VIEW_ACCESS]
        : [EModelAccessLevel.VIEW_ACCESS, EModelAccessLevel.VIEW_AR_ACCESS];
      await dispatch(changePublicAccessLevel({ accessLevel, model }));
    }
  };

  const getShareLinkTooltip = (): JSX.Element =>
    isShareTab ? (
      <div>
        This sharing link is set to public by default. To change this to <i>invite-only</i>, change
        the Sharing Permissions settings below.
      </div>
    ) : (
      <div>
        The <i>View in AR sharing link</i> is set to public by default. To change this to{' '}
        <i>invite-only</i>, change the Sharing Permissions settings below.
      </div>
    );

  return (
    <Styled.ShareModelContainer>
      <Styled.ShareTabsContainer>
        <Styled.ShareTab isShareTab={isShareTab} onClick={handleShareTabClick}>
          <span>Share</span>
        </Styled.ShareTab>
        <Styled.ArViewTab
          isArViewTab={!isShareTab}
          hasArViewAccess={hasArViewAccess}
          onClick={handleArViewTabClick}
        >
          {!hasArViewAccess && (
            <Styled.Lock>
              <img src={LockIcon} alt='Lock' />
            </Styled.Lock>
          )}
          <span>AR View</span>
        </Styled.ArViewTab>
      </Styled.ShareTabsContainer>
      <Styled.ShareModelContent>
        <Styled.FieldLabel>{isShareTab ? 'Share QR Code' : 'View in AR QR Code'}</Styled.FieldLabel>
        <Styled.QrCodeContainer>
          {isShareTab ? (
            <>
              {isPublishedModel || isSampleModel ? (
                <Styled.ShareQrCode>
                  {isShareQrLoading && <Styled.Spinner src={Spinner} alt='Loading' />}
                  {shareQrCodeURL && <img className='qr-code' src={shareQrCodeURL} alt='qr-code' />}
                </Styled.ShareQrCode>
              ) : (
                <Styled.InactiveQrCode>
                  <img src={GlbeeQrCode} alt='Inactive QR code' />
                  <Styled.CoverLayer>Create & Copy Link to activate QR code</Styled.CoverLayer>
                </Styled.InactiveQrCode>
              )}
            </>
          ) : (
            <>
              {isArViewShared || isSampleModel ? (
                <Styled.ArQrCode>
                  {isArQrLoading && <Styled.Spinner src={Spinner} alt='Loading' />}
                  {arQrCodeURL && <img className='qr-code' src={arQrCodeURL} alt='qr-code' />}
                </Styled.ArQrCode>
              ) : (
                <Styled.InactiveQrCode>
                  <img src={GlbeeQrCode} alt='Inactive QR code' />
                  <Styled.CoverLayer>Create & Copy AR Link to activate QR code</Styled.CoverLayer>
                </Styled.InactiveQrCode>
              )}
            </>
          )}
        </Styled.QrCodeContainer>
        {!isEmbeddedModelMode && (
          <Styled.InnerBlock>
            <Styled.FieldLabel>Download QR Code</Styled.FieldLabel>
            <Styled.DownloadButtons>
              <Styled.DownloadQrCodeButton
                onClick={handleDownloadQrCodeClick('png')}
                disabled={isDownloadDisabled}
              >
                PNG
              </Styled.DownloadQrCodeButton>
              <Styled.DownloadQrCodeButton
                onClick={handleDownloadQrCodeClick('svg')}
                disabled={isDownloadDisabled}
              >
                SVG
              </Styled.DownloadQrCodeButton>
            </Styled.DownloadButtons>
          </Styled.InnerBlock>
        )}

        <Styled.InnerBlock>
          <Styled.ShareLinkBlockHeader>
            <Styled.FieldLabel>Share Link</Styled.FieldLabel>
            <CustomTooltip content={getShareLinkTooltip()} placement={'topLeft'}>
              <img src={Hint} alt='Share link hint' />
            </CustomTooltip>
          </Styled.ShareLinkBlockHeader>
          {(isShareTab && isPublishedModel) || (!isShareTab && isArViewShared) || isSampleModel ? (
            <Styled.ShareLinkField>
              <Styled.LinkIcon src={LinkImage} alt='Share link' />
              <Styled.Link
                value={shareLink}
                readOnly={true}
                onFocus={(e): void => e.target.select()}
              />
              <Styled.CopyLinkButton onClick={handleCopyLinkButtonClick} id='copy-link-button'>
                <img src={Copy} alt='Copy' />
              </Styled.CopyLinkButton>
            </Styled.ShareLinkField>
          ) : (
            <>
              {!isEmbeddedModelMode && hasEditAccess && (
                <Styled.CreateLinkButton onClick={handleCreateLinkClick}>
                  {isShareTab ? 'Create & Copy Link' : 'Create & Copy AR Link'}
                </Styled.CreateLinkButton>
              )}
            </>
          )}
        </Styled.InnerBlock>
        {!isEmbeddedModelMode && hasEditAccess && (
          <Styled.SharingButton onClick={handleSharingPermissionsClick}>
            <img src={SettingsIcon} alt='Sharing Permissions' />
            <span>Sharing Permissions</span>
          </Styled.SharingButton>
        )}
        {!isEmbeddedModelMode && (hasEditAccess || isSampleModel) && (
          <CustomTooltip content={EMBED_REQUIRED_PUBLIC_ACCESS} showTooltip={!isPublishedModel && !isSampleModel}>
            <Styled.SharingButton onClick={handleEmbedModelClick} isDisabled={!isPublishedModel && !isSampleModel}>
              <img src={Code} alt='Embed Model' />
              <span>Embed Model</span>
            </Styled.SharingButton>
          </CustomTooltip>
        )}
      </Styled.ShareModelContent>
    </Styled.ShareModelContainer>
  );
};

export default SharePanel;
