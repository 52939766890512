import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 214px 170px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media ${DEVICE.tablet} {
    padding: 100px 20px;
  }

  @media ${DEVICE.mobile} {
    padding: 30px 20px;
  }
`;

export const PageContent = styled.section`
  display: flex;
  width: 100%;
  max-width: 1098px;
  height: 546px;
  background: linear-gradient(259.95deg, #525356 -61.87%, #0a0a0a 130.29%);
  border-radius: 7px;
  color: ${COLORS.white};
  position: relative;

  @media (max-width: 1320px) {
    flex-direction: column;
    height: fit-content;
  }
`;

export const ContentLeftSide = styled.section`
  width: 40%;
  max-width: 453px;
  height: calc(100% + 13px);
  background: linear-gradient(
    150.59deg,
    rgba(255, 255, 255, 0.45) 17.37%,
    rgba(255, 255, 255, 0) 101.21%,
    rgba(255, 255, 255, 0) 101.21%
  );
  border: 1px solid ${COLORS.doveGray};
  backdrop-filter: blur(15px);
  border-radius: 7px;
  margin-top: -66px;
  padding: 0 35px 32px 58px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 1320px) {
    width: 100%;
    max-width: initial;
    height: fit-content;
  }

  @media ${DEVICE.mobile} {
    padding: 30px 20px;
    margin-top: 0;
  }
`;

export const PageTitle = styled.h1`
  margin: 87px 0 0;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: ${COLORS.white};

  @media ${DEVICE.mobile} {
    margin-top: 0;
  }
`;

export const PageSubtitle = styled.h2`
  margin-top: 31px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${COLORS.white};
`;

export const SocialNetworks = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`;

export const FollowUsText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
`;

export const SocialNetworksList = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const SocialNetworkItem = styled.a`
  width: 33px;
  height: 33px;

  &:not(:first-child) {
    margin-left: 21px;
  }
`;

export const CubeImage = styled.img`
  position: absolute;

  &.left {
    width: 270px;
    bottom: 100px;
    transform: translateX(-55%) rotate(15.42deg);

    @media (max-width: 1320px) {
      top: 50%;
      transform: translate(-75%, -50%) rotate(15.42deg);
      bottom: initial;
      width: 180px;
    }
  }

  &.top {
    width: 125px;
    top: 0;
    left: calc(40% - 110px);
    transform: translateY(-100%) rotate(-15deg);
  }

  &.bottom {
    width: 200px;
    bottom: 0;
    right: 0;
    transform: translate(30%, 37%) rotate(15.42deg);
  }

  @media ${DEVICE.mobile} {
    display: none;
  }
`;

export const ContentRightSide = styled.section`
  width: 60%;
  padding: 51px 63px 60px;

  @media (max-width: 1320px) {
    width: 100%;
    max-width: initial;
  }

  @media ${DEVICE.mobile} {
    padding: 30px 20px;
  }
`;

export const ContactUsForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopFormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FieldContainer = styled.div`
  width: 100%;
  height: 72px;
  margin-top: 9px;
  display: flex;
  flex-direction: column;

  &.top-row {
    width: 48%;
    margin-top: 0;
  }
`;

export const FormInput = styled.input`
  width: 100%;
  height: 49px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  backdrop-filter: blur(5px);
  border-radius: 7px;
  padding: 0 33px;
  border: none;
  font-size: 16px;
  line-height: 18px;
  color: ${COLORS.white};

  &::-webkit-input-placeholder {
    color: ${COLORS.white};
  }

  &::-moz-placeholder {
    color: ${COLORS.white};
  }

  @media ${DEVICE.mobile} {
    padding: 0 15px;
  }
`;

export const SelectContainer = styled.div`
  height: 72px;
  margin-top: 9px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MessageFieldContainer = styled.div`
  height: 145px;
  margin-top: 9px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MessageField = styled.textarea`
  width: 100%;
  height: 122px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  backdrop-filter: blur(5px);
  border-radius: 7px;
  padding: 15px 33px;
  border: none;
  font-size: 16px;
  line-height: 18px;
  color: ${COLORS.white};
  resize: none;

  &::-webkit-input-placeholder {
    color: ${COLORS.white};
  }

  &::-moz-placeholder {
    color: ${COLORS.white};
  }

  @media ${DEVICE.mobile} {
    padding: 15px;
  }
`;

export const SendMessageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding: 15px 41px;
  width: fit-content;
  border-radius: 7px;
  margin-top: 10px;

  &:hover {
    background: ${COLORS.corn};
  }
`;
