import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SettingsField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media ${DEVICE.mobile} {
    justify-content: flex-start;
  }
`;

export const BillingTypeSwitcher = styled.div<{ withCounter?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 0;

  @media ${DEVICE.mobile} {
    margin-top: 5px;
  }
`;

export const SettingsLabel = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.white};
  white-space: nowrap;
`;

export const BillingTypeButton = styled.button<{ isActive: boolean }>`
  margin-left: 10px;
  width: 90px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive }): string => (isActive ? COLORS.turbo : '#7E7E7E')};
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: ${COLORS.black};
  border-radius: 4px;
`;

export const MembersCounterContainer = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

export const CounterField = styled.div`
  display: flex;
  align-items: center;
`;

export const MembersCounter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const CounterInput = styled.input`
  width: 80px;
  height: 22px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: none;
  border-radius: 4px;
  text-align: center;
  color: ${COLORS.white};
  font-size: 12px;
  font-weight: 600;
  margin: 0 6px;
`;

export const CounterButton = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const PlansSection = styled.div`
  display: flex;
  margin: 0 -5px;
`;

export const PlanContainer = styled.div<{ planId: string; activePlanId: string }>`
  width: 100%;

  @media ${DEVICE.tablet} {
    display: ${({ planId, activePlanId }): string => (planId === activePlanId ? 'flex' : 'none')};
    justify-content: center;
  }
`;

export const PlanTabsContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: flex-end;
  margin-top: 30px;

  @media ${DEVICE.tablet} {
    display: flex;
  }
`;

export const PlanTab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  background: ${({ isActive }): string =>
    isActive ? COLORS.turbo : 'linear-gradient(352.13deg, #1b1a1a 3.17%, #464646 103.41%)'};
  color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.white)};
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 6px;
  width: 50%;
  cursor: pointer;

  &:hover {
    transition: all 0.1s;
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }

  @media ${DEVICE.tablet} {
    height: 33px;
    font-size: 12px;
    border-radius: 6px 6px 0 0;
  }
`;
