import { AxiosResponse } from 'axios';
import ApiService from './api';
import { KeyBindingsMap, UserKeyBinding } from 'shared/types/key-binding';

export const getKeyBindings = async (userId?: string): Promise<AxiosResponse<UserKeyBinding>> =>
  await ApiService.get(`/users/${userId || 'me'}/keybindings`);

export const uploadKeyBindings = async (
  bindings: KeyBindingsMap,
  userId?: string
): Promise<AxiosResponse<UserKeyBinding>> =>
  await ApiService.post(`/users/${userId || 'me'}/keybindings`, bindings);

export const updateKeyBindings = async (
  bindings: KeyBindingsMap,
  userId?: string
): Promise<AxiosResponse<UserKeyBinding>> =>
  await ApiService.patch(`/users/${userId || 'me'}/keybindings`, bindings);

export const deleteKeyBindings = async (userId?: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(`/users/${userId || 'me'}/keybindings`);
