import { Options } from 'qr-code-styling';

export const getQRCodeOptions = (link: string, iconPath: string): Partial<Options> => ({
  width: 320,
  height: 320,
  data: link,
  image: iconPath,
  margin: 10,
  qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'Q' },
  imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0, crossOrigin: 'anonymous' },
  dotsOptions: { color: '#000000', type: 'rounded' },
  backgroundOptions: { color: '#ffffff' },
  cornersSquareOptions: { color: '#000000', type: 'extra-rounded' },
  cornersDotOptions: { color: '#000000', type: 'dot' },
  type: 'svg'
});
