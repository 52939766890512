import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const CardFormContainer = styled.div`
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 8px;
  padding: 25px;

  @media ${DEVICE.mobile} {
    padding: 20px 10px;
  }
`;

export const FormTitle = styled.span`
  font-family: ${FONTS.interRegular};
  font-size: 18px;
  color: ${COLORS.mischka};
  font-weight: 700;
`;

export const CardContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 4px;
`;

export const CardFormFooter = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StripeLabel = styled.img`
  height: 30px;
  align-self: flex-end;

  @media ${DEVICE.mobile} {
    height: 22px;
  }
`;

export const ActionButton = styled.button`
  width: 118px;
  height: 33px;
  background: ${COLORS.corn};
  border: none;
  border-radius: 4px;
  color: ${COLORS.black};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  font-family: ${FONTS.interRegular};
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.turbo};
  }
`;
