import * as THREE from 'three';
import { BaseLoadEnvironmentStrategy } from './base-load-environment.strategy';
import {
  LoadEnvironmentData,
  LoadEnvironmentResult
} from 'shared/interfaces';
import { getDeviceMaxTextureSize } from 'utils/scenes-utils';
import { LoadModelService } from 'services/strategy-services';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { ETextureSizes } from 'shared/enums/ETextureSizes';

export class FileLoadEnvironmentStrategy extends BaseLoadEnvironmentStrategy {
  private modelLoadingService: LoadModelService;

  constructor(
    protected renderer: THREE.WebGLRenderer,
    protected onModelResourcesLoaded?: () => void
  ) {
    super();

    this.modelLoadingService = new LoadModelService({ renderer, onModelResourcesLoaded });
  }

  public async changeEnvironment({
    canvasId,
    environmentWrapper,
    data
  }: LoadEnvironmentData): Promise<LoadEnvironmentResult> {
    const maxTextureSize = getDeviceMaxTextureSize(canvasId);
    const deviceSize = Math.min(window.innerWidth, window.innerWidth);
    const isMobile = deviceSize <= DEVICE_SIZES.mobile;
    const isTablet = deviceSize <= DEVICE_SIZES.tabletLarge;
    const textureSize = isMobile
      ? ETextureSizes.d1k
      : isTablet
      ? maxTextureSize === ETextureSizes.d1k
        ? maxTextureSize
        : ETextureSizes.d2k
      : maxTextureSize;

    if (data && 'fileType' in data) {
      const path = typeof data.path === 'string' ? data.path : data.path[textureSize];
      const { model } = await this.modelLoadingService.loadModel(data.fileType, {
        path,
        mtls: [],
        assets: []
      });

      model.scale.set(data.scale.x, data.scale.y, data.scale.z);
      this.clearEnvironmentWrapper(environmentWrapper);
      environmentWrapper.add(model);

      return model;
    }

    return null;
  }
}
