import { EKeyBindingsKeys } from 'shared/enums/EKeyBindingsKeys';
import { KeyBindingsMap } from 'shared/types/key-binding';
import { DEFAULT_KEY_BINDINGS_MAP } from 'shared/constants/default-key-bindings';
import { getKeyCodeMarker, getViceVersaKeyMap } from 'utils/key-bindings-utils';

export type IActions = Record<EKeyBindingsKeys, boolean>;

export class KeyboardController {
  private onModelCameraChangedFn: (() => void) | undefined;
  private _actions: IActions = {
    [EKeyBindingsKeys.forward]: false,
    [EKeyBindingsKeys.back]: false,
    [EKeyBindingsKeys.left]: false,
    [EKeyBindingsKeys.right]: false,
    [EKeyBindingsKeys.up]: false,
    [EKeyBindingsKeys.down]: false,
    [EKeyBindingsKeys.fastSpeed]: false,
    [EKeyBindingsKeys.speedControl]: false,
    [EKeyBindingsKeys.increaseFov]: false,
    [EKeyBindingsKeys.decreaseFov]: false,
    [EKeyBindingsKeys.pan]: false,
    [EKeyBindingsKeys.quickZoom]: false,
    [EKeyBindingsKeys.rotate]: false
  };
  private keyMap: KeyBindingsMap = DEFAULT_KEY_BINDINGS_MAP;

  get actions(): IActions {
    return this._actions;
  }

  public setKeyMap(map: KeyBindingsMap): void {
    this.keyMap = map;
  }

  public clearActions(): void {
    const actionsKeys = Object.keys(this._actions) as EKeyBindingsKeys[];

    actionsKeys.forEach((actionKey): void => {
      this._actions[actionKey] = false;
    });
  }

  public setOnModelCameraChangedFn(onModelCameraChangedFn: () => void): void {
    this.onModelCameraChangedFn = onModelCameraChangedFn;
  }

  private handleOnModelCameraChangedFn(): void {
    if (this.onModelCameraChangedFn) {
      this.onModelCameraChangedFn();
    }
  }

  private handleButtonEvent(event: KeyboardEvent | MouseEvent, pressed: boolean): void {
    const keys = getViceVersaKeyMap(this.keyMap);
    const key = keys[getKeyCodeMarker(event)];

    if (key) {
      this._actions[key] = pressed;
      this.handleOnModelCameraChangedFn();
    }
  }

  public onButtonDown(event: KeyboardEvent | MouseEvent): void {
    this.handleButtonEvent(event, true);
  }

  public onButtonUp(event: KeyboardEvent | MouseEvent): void {
    this.handleButtonEvent(event, false);
  }
}
