import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './styles';
import CheckYellow from 'assets/images/check-yellow.svg';
import {
  AuthState,
  ESnackbarStyle,
  PlanPrice,
  SubscriptionProduct,
  TeamsState
} from 'shared/types';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { withElements } from 'shared/hocs/withElements';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  checkPromoCode,
  fetchTeamPlanPrice,
  getCurrentTeamSubscription,
  payTeamSubscription
} from 'services/api/subscriptionsService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { TeamCardForm } from 'shared/components';
import { SUBSCRIPTION_UPDATED } from 'shared/constants/notifications';
import { setActiveTeamPlan } from 'services/store/reducers/teamsReducer';
import { throttle } from 'utils/delay-utils';
import { checkIsStripeTestCardError } from 'utils/error-utils';

type Props = {
  plan: SubscriptionProduct;
  isMonthlyType: boolean;
};

const ModalTeamCheckout: React.FC<Props> = ({ plan, isMonthlyType }): JSX.Element => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const { teamPlan, teamCard } = useAppSelector((store): TeamsState => store.teams);
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const [isMonth, setIsMonth] = useState(isMonthlyType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardFormActive, setIsCardFormActive] = useState<boolean>(false);
  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>('');
  const promoCodeValue = useRef<string>('');
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState<boolean>(false);
  const [isPromoCodeFieldActive, setIsPromoCodeFieldActive] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const isValidPromoCode = useRef<boolean>(false);
  const ONE_USD = 100;
  const LAST_4 = !!teamCard?.card ? teamCard?.card.last4 : teamCard?.sepa_debit?.last4;
  const isCurrentPlanInterval = teamPlan?.recurringInterval === (isMonth ? 'month' : 'year');
  const isCurrentPlan = plan?.id === teamPlan?.productId && isCurrentPlanInterval;

  const priceObj = useMemo(
    (): PlanPrice | undefined =>
      plan.prices.find((i): boolean => i.recurring.interval === (isMonth ? 'month' : 'year')),
    [isMonth, plan]
  );

  const planPrice = (priceObj?.unit_amount || 0) / ONE_USD;

  const calculatePlanPrice = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    dispatch(startLoader());
    const promo = promoCodeValue.current;

    if (!!priceObj && !!teamCard) {
      try {
        if (isPromoCodeApplied && !!promo && !isValidPromoCode.current) {
          const { valid } = (await checkPromoCode(promo)).data;
          isValidPromoCode.current = valid;
          valid
            ? openNotification(ESnackbarStyle.SUCCESS, 'Promo code has been successfully accepted')
            : openNotification(ESnackbarStyle.ERROR, 'Incorrect promo code');
        }

        const price = priceObj.id;
        const qty = activeTeam!.totalSeats;
        const card = teamCard.id;
        const payload =
          isPromoCodeApplied && isValidPromoCode.current
            ? { price, qty, card, promo }
            : { price, card, qty };
        const { amount_due, tax } = (await fetchTeamPlanPrice(activeTeam!.id, payload)).data;
        setTotalPrice(amount_due / ONE_USD);
        setTax(tax / ONE_USD);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    }
    dispatch(stopLoader());
    setIsLoading(false);
  }, [activeTeam, dispatch, isPromoCodeApplied, priceObj, teamCard]);

  useEffect((): void => {
    calculatePlanPrice();
  }, [calculatePlanPrice]);

  const clearPromoCodeForm = (): void => {
    setPromoCodeInputValue('');
    promoCodeValue.current = '';
    isValidPromoCode.current = false;
    setIsPromoCodeApplied(false);
  };

  const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (isPromoCodeApplied) {
      clearPromoCodeForm();
    } else {
      setIsPromoCodeApplied(true);
    }
  };

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    if (isPromoCodeApplied && !value) {
      clearPromoCodeForm();
    } else {
      setPromoCodeInputValue(value);
      promoCodeValue.current = value;
    }
  };

  const handlePromoCodeButtonClick = (): void => {
    setIsPromoCodeFieldActive((prev): boolean => !prev);
  };

  const handleEditCardClick = (): void => {
    setIsCardFormActive((prevState): boolean => !prevState);
  };

  const getCardBrand = (): string => {
    if (!!teamCard?.card) {
      const { brand } = teamCard.card;
      return brand[0].toUpperCase() + brand.slice(1);
    } else {
      return 'Card';
    }
  };

  const handlePayButtonClick = async (): Promise<void> => {
    if (!!teamCard && !!stripe && !!elements && !!priceObj) {
      setIsLoading(true);
      dispatch(startLoader());
      try {
        const promo = promoCodeValue.current;
        const card = teamCard.id;
        const price = priceObj.id;
        const qty = activeTeam!.totalSeats;
        const payload =
          isPromoCodeApplied && isValidPromoCode.current
            ? { card, price, qty, promo }
            : { card, price, qty };
        const { payment_intent } = (await payTeamSubscription(activeTeam!.id, payload)).data;
        const isFail = payment_intent?.status === 'requires_payment_method';
        const isRequiresAction =
          payment_intent?.status === 'requires_action' ||
          payment_intent?.status === 'requires_confirmation';
        if (isFail) {
          throw new Error('Requires payment method');
        }
        if (!!payment_intent?.client_secret && isRequiresAction) {
          const { error } = await stripe.confirmCardPayment(payment_intent.client_secret, {
            payment_method: card
          });
          if (!!error) throw new Error(checkIsStripeTestCardError(error));
        }

        await throttle(5000);
        const currentPlan = (await getCurrentTeamSubscription(activeTeam!.id)).data;
        dispatch(setActiveTeamPlan(currentPlan));
        dispatch(closeConfirmModal());
        openNotification(ESnackbarStyle.SUCCESS, SUBSCRIPTION_UPDATED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
        setIsLoading(false);
      }
    }
  };

  const { title, subtitle } = extractPlanData(plan);

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.BillingFrequencyBlock>
          <Styled.FieldLabel>Billing frequency</Styled.FieldLabel>
          <Styled.BillingFrequencyField onClick={(): void => setIsMonth(false)}>
            <Styled.Checkbox isActive={!isMonth}>
              {!isMonth && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>Yearly</span>
          </Styled.BillingFrequencyField>
          <Styled.BillingFrequencyField onClick={(): void => setIsMonth(true)}>
            <Styled.Checkbox isActive={isMonth}>
              {isMonth && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>Monthly</span>
          </Styled.BillingFrequencyField>
        </Styled.BillingFrequencyBlock>
        <Styled.PaymentInfoBlock>
          <Styled.FieldLabel>Payment Info</Styled.FieldLabel>
          <Styled.PaymentInfoField>
            {!!teamCard ? (
              <>
                {teamCard.type === 'paypal' ? (
                  <Styled.CardInfo>Paypal</Styled.CardInfo>
                ) : (
                  <Styled.CardInfo>{`${getCardBrand()} ending in ${LAST_4}`}</Styled.CardInfo>
                )}
              </>
            ) : (
              <Styled.CardInfo>No saved card</Styled.CardInfo>
            )}
            <Styled.EditCardButton onClick={handleEditCardClick} id='edit-credit-card-button'>
              {!!teamCard ? 'Edit Credit Card' : 'Add card'}
            </Styled.EditCardButton>
          </Styled.PaymentInfoField>
        </Styled.PaymentInfoBlock>
        {isCardFormActive && (
          <Styled.CardFormContainer>
            <TeamCardForm teamId={activeTeam!.id} plan={plan} isMonthlyType={isMonth} isCheckout />
          </Styled.CardFormContainer>
        )}
      </Styled.LeftSide>
      <Styled.RightSide>
        <Styled.RightSideHeader>
          <Styled.FieldLabel>Order summary</Styled.FieldLabel>
          <Styled.PromoCodeButton onClick={handlePromoCodeButtonClick} id='add-promo-code-button'>
            + Add Promo Code
          </Styled.PromoCodeButton>
        </Styled.RightSideHeader>
        {isPromoCodeFieldActive && (
          <Styled.PromoCodeForm>
            <Styled.PromoCodeLabel>Promo code:</Styled.PromoCodeLabel>
            <Styled.PromoCodeField>
              <Styled.PromoCodeInput value={promoCodeInputValue} onChange={handlePromoCodeChange} />
              <Styled.ApplyButton onClick={handleApplyClick}>
                {isPromoCodeApplied ? 'Clear' : 'Apply'}
              </Styled.ApplyButton>
            </Styled.PromoCodeField>
          </Styled.PromoCodeForm>
        )}
        <Styled.PayBlock>
          <Styled.AccountPlan>
            <div>
              <div>Account Plan:</div>
              <div>{`${title} ${subtitle} (${activeTeam!.totalSeats} Seats)`}</div>
            </div>
            <span>{`$${planPrice * (activeTeam?.totalSeats || 1)}`}</span>
          </Styled.AccountPlan>
          <Styled.PriceField>
            <span>Tax</span>
            <span>{`$${tax}`}</span>
          </Styled.PriceField>
          <Styled.PriceField>
            <span>Total due today</span>
            <span>{`$${totalPrice}`}</span>
          </Styled.PriceField>
          <Styled.PayBlockFooter>
            <Styled.TermsConditions
              href={`${process.env.REACT_APP_URL}/terms-of-use?auth=${isAuth ? '1' : '0'}`}
              target='_blank'
              rel='noreferrer'
              id='term-of-use-button'
            >
              Terms of Use
            </Styled.TermsConditions>
            <Styled.PayButton
              onClick={handlePayButtonClick}
              isCardExist={!!teamCard}
              disabled={isCurrentPlan || isLoading}
              id='pay-now-button'
            >
              Pay now
            </Styled.PayButton>
          </Styled.PayBlockFooter>
        </Styled.PayBlock>
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default withElements(ModalTeamCheckout);
