import React, { JSX, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import CubeIcon from 'assets/images/cube.svg';
import moment from 'moment/moment';
import { formatToHTMLLayout, transformMentionFromDefaultFormat } from 'utils/comments-utils';
import { IViewerCommentFromServer } from 'shared/interfaces';
import { useAppSelector } from 'shared/hooks';
import { RootState } from 'services/store';
import { checkItemVisibilityToUser } from 'utils/dom-utils';
import { markCommentAsSeen } from 'services/api/notesService';
import { useCommentsData } from 'shared/hooks/data-hooks/comments-data-hook/useCommentsData';

interface Props {
  comment: IViewerCommentFromServer;
  onEditButtonClick: (comment: IViewerCommentFromServer) => void;
  onDeleteButtonClick: (comment: IViewerCommentFromServer) => Promise<void>;
}

export const CommentReplyItem: React.FC<Props> = ({
  comment,
  onEditButtonClick,
  onDeleteButtonClick
}): JSX.Element => {
  const commentRef = useRef<HTMLLIElement>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const store = useAppSelector((store): RootState => store);
  const { user } = store.auth;
  const { model, isEmbeddedModelMode } = store.viewerData;
  const { activeCommentId } = store.comments;

  const [isBlink, setIsBlink] = useState<boolean>(false);
  const [isCurrentUserAuthor, setIsCurrentUserAuthor] = useState<boolean>(false);

  const { fetchComments } = useCommentsData(model, user);

  useEffect(
    (): (() => void) => (): void => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    },
    []
  );

  useEffect((): void => {
    if (activeCommentId === comment.id) {
      handleShowSelectedReply();
      handleMarkAsSeen();
    } else {
      setIsBlink(false);
    }
  }, [comment.id, activeCommentId]);

  useEffect((): void => {
    if (comment.authorId === user?.id) {
      setIsCurrentUserAuthor(true);
    } else {
      setIsCurrentUserAuthor(false);
    }
  }, [comment.authorId, user?.id]);

  const handleShowSelectedReply = (): void => {
    setIsBlink(true);
    commentRef.current?.scrollIntoView({ block: 'center' });
    timeoutIdRef.current = setTimeout((): void => {
      setIsBlink(false);
    }, 2000);
  };

  const handleMarkAsSeen = (): void => {
    if (!commentRef.current || !model || !model.id) return;

    const isItemVisibleToUser = checkItemVisibilityToUser(
      commentRef.current,
      commentRef.current.parentElement!
    );

    if (isItemVisibleToUser && comment.mentions) {
      comment.mentions.forEach((mention): void => {
        if (mention.person?.id === user?.id && !mention.seenComment) {
          markCommentAsSeen(model?.id!, `${comment.id}`).then((response): any => {
            fetchComments({});
          });
        }
      });
    }
  };

  const getAuthor = (): string => {
    if (comment.author) {
      if (comment.author.firstName.trim().length > 0 && comment.author.lastName.trim().length > 0) {
        return `${comment.author?.firstName} ${comment.author?.lastName}`;
      }

      return comment.author.email;
    }

    return '';
  };

  return (
    <Styled.Comment
      ref={commentRef}
      isBlink={isBlink}
      isHighlighted={isCurrentUserAuthor}
      onMouseOver={handleMarkAsSeen}
      onMouseOut={handleMarkAsSeen}
    >
      <Styled.OptionsBlock>
        <Styled.InfoBlock>
          <Styled.infoItem hasMargin={true}>{`#${comment.commentNum}`}</Styled.infoItem>

          <Styled.infoItem>{moment(comment.updatedAt).format('DD MMM YYYY')}</Styled.infoItem>
        </Styled.InfoBlock>

        {!isEmbeddedModelMode && isCurrentUserAuthor && (
          <Styled.CommentButtonsWrapper>
            <Styled.CommentsButton
              isEditButton={true}
              onClick={(): void => {
                onEditButtonClick(comment);
              }}
            >
              Edit
            </Styled.CommentsButton>

            <Styled.CommentsButton
              onClick={(): void => {
                onDeleteButtonClick(comment);
              }}
            >
              Delete
            </Styled.CommentsButton>
          </Styled.CommentButtonsWrapper>
        )}
      </Styled.OptionsBlock>

      <Styled.CommentHeader>
        <Styled.CommentHeaderImage alt={'Author'} src={CubeIcon}></Styled.CommentHeaderImage>

        <Styled.CommentAuthor title={getAuthor()}>{getAuthor()}</Styled.CommentAuthor>

        <Styled.CommentPassed title={moment(comment.updatedAt).format('DD MMM YYYY, HH:MM')}>
          {moment(comment.updatedAt).fromNow()}
        </Styled.CommentPassed>
      </Styled.CommentHeader>

      <Styled.CommentText
        dangerouslySetInnerHTML={{
          __html: transformMentionFromDefaultFormat(comment.message, formatToHTMLLayout, comment)
        }}
      />
    </Styled.Comment>
  );
};
