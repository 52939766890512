import { EQuotaName, PlanLimit, SubscriptionProduct } from 'shared/types';
import { CUSTOM_PLAN } from 'shared/constants/subscription-plans';
import { MEGABYTE } from 'shared/constants/limits';

type ModifyPlanResult = {
  title: string;
  subtitle: string;
  monthPrice: number;
  yearPrice: number;
  limits: string[];
  features: { title: string; isAvailable: boolean }[];
  description: string;
  icon: string;
  isMostPopular: boolean;
};

export const extractPlanData = (
  plan?: SubscriptionProduct,
  isCustomPlan?: boolean
): ModifyPlanResult => {
  if (!isCustomPlan && !!plan) {
    const { name, prices, metadata, description, images } = plan;
    const icon = images[0];
    const titleArr = name.split('/');
    const title = titleArr[0];
    const subtitle = titleArr[1];
    const findPriceType = (type: 'month' | 'year'): number => {
      const ONE_USD = 100;
      const priceObj = prices.find((i): boolean => i.recurring.interval === type);
      return !!priceObj ? priceObj.unit_amount / ONE_USD : 0;
    };
    const monthPrice = findPriceType('month');
    const yearPrice = findPriceType('year');
    const limits = metadata.product_limits.map(({ title, value }): string => `${value} ${title}`);
    const availableFeatures = metadata.product_features.filter(({ enabled }): boolean => enabled);
    const disabledFeatures = metadata.product_features.filter(({ enabled }): boolean => !enabled);
    const featuresArr = [...availableFeatures, ...disabledFeatures];
    const features = featuresArr.map(
      ({ title, enabled }): { title: string; isAvailable: boolean } => ({
        title,
        isAvailable: enabled
      })
    );
    const isMostPopular = !!plan.metadata.most_popular;

    return {
      title,
      subtitle,
      monthPrice,
      yearPrice,
      limits,
      features,
      description,
      icon,
      isMostPopular
    };
  } else {
    const { title, subtitle, limits, features, description, icon } = CUSTOM_PLAN;
    return {
      title,
      subtitle,
      monthPrice: 0,
      yearPrice: 0,
      limits,
      features,
      description,
      icon,
      isMostPopular: false
    };
  }
};

export const getSubscriptionLimits = (
  plan: SubscriptionProduct
): { modelSizePlanLimit: number; modelsPlanLimit: number } => {
  const findLimit = (limitId: EQuotaName): PlanLimit =>
    plan.metadata.product_limits.find(({ id }): boolean => id === limitId)!;
  const sizeLimit = findLimit(EQuotaName.MODEL_SIZE_LIMIT).value;
  const modelsLimit = findLimit(EQuotaName.ACTIVE_MODELS_LIMIT).value;
  const sizeInBytes: number = !!sizeLimit ? +`${sizeLimit}`.replace('MB', '') * MEGABYTE : 0;
  return { modelSizePlanLimit: sizeInBytes, modelsPlanLimit: +modelsLimit };
};
