import { AxiosResponse } from 'axios';
import ApiService from './api';
import {
  CustomCubemap,
  CustomEnvironment,
  CustomTheme,
  EFeatureId,
  EQuotaName,
  Model,
  PlanFeature,
  ShortLink,
  UpdateModelRequest
} from 'shared/types';

type LimitResponse = {
  quota: EQuotaName;
  title: string;
  limit: number;
  used: number;
  success: boolean;
};

export const uploadModel = async (
  model: FormData,
  signal?: AbortSignal
): Promise<AxiosResponse<Model>> =>
  await ApiService.post<FormData, Model>('/models', model, { signal });

export const updateModel = async (
  data: UpdateModelRequest,
  modelId: string
): Promise<AxiosResponse<Model>> =>
  await ApiService.patch<UpdateModelRequest, Model>(`/models/${modelId}`, data);

export const getModel = async (
  modelId: string,
  signal?: AbortSignal
): Promise<AxiosResponse<Model>> =>
  await ApiService.get<Model>(
    `/models/${modelId}?withRelated[]=content&withRelated[]=owner&withRelated[]=metadata`,
    { signal }
  );

export const getARModel = async (
  modelId: string,
  signal?: AbortSignal
): Promise<AxiosResponse<Model>> =>
  await ApiService.get<Model>(
    `/models/${modelId}/view-in-ar?withRelated[]=content&withRelated[]=owner&withRelated[]=metadata`,
    { signal }
  );

export const uploadModelCoverImage = async (
  modelId: string,
  image: FormData
): Promise<AxiosResponse<Model>> =>
  await ApiService.post<FormData, Model>(`/models/${modelId}/cover-image`, image);

export const downloadModel = async (modelId: string): Promise<AxiosResponse<Blob>> =>
  await ApiService.get(`models/${modelId}/download`, { responseType: 'blob' });

export const getModelShortLink = async (modelId: string): Promise<AxiosResponse<ShortLink>> =>
  await ApiService.get<ShortLink>(`models/${modelId}/short-url`);

export const deleteModel = async (modelId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete(`/models/${modelId}`);

export const checkModelQuota = async (
  modelId: string,
  quotaName: EQuotaName
): Promise<AxiosResponse<LimitResponse>> =>
  await ApiService.get<LimitResponse>(`/models/${modelId}/usage-limits/${quotaName}/validate`);

export const checkModelFeature = async (
  modelId: string,
  featureId: EFeatureId,
  signal?: AbortSignal
): Promise<AxiosResponse<PlanFeature>> =>
  await ApiService.get<PlanFeature>(`/models/${modelId}/paid-features/${featureId}/validate`, {
    signal
  });

export const checkAllModelFeatures = async (
  modelId: string,
  signal?: AbortSignal
): Promise<AxiosResponse<PlanFeature[]>> =>
  await ApiService.get<PlanFeature[]>(`/models/${modelId}/paid-features/all/validate`, {
    signal
  });

export const getModelCustomBrands = async (
  modelId: string,
  signal: AbortSignal
): Promise<AxiosResponse<CustomTheme[]>> =>
  await ApiService.get<CustomTheme[]>(`models/${modelId}/branding-options`, { signal });

export const getCustomBrand = async (
  brandingId: number,
  signal: AbortSignal
): Promise<AxiosResponse<CustomTheme>> =>
  await ApiService.get<CustomTheme>(`branding/${brandingId}`, { signal });

export const getModelCustomEnvironments = async (
  modelId: string,
  signal: AbortSignal
): Promise<AxiosResponse<CustomEnvironment[]>> =>
  await ApiService.get<CustomEnvironment[]>(`models/${modelId}/environment-options`, { signal });

export const getModelCustomCubemaps = async (
  modelId: string,
  signal: AbortSignal
): Promise<AxiosResponse<CustomCubemap[]>> =>
  await ApiService.get<CustomCubemap[]>(`models/${modelId}/cubemap-options`, { signal });
