import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './styles';
import { withElements } from 'shared/hocs/withElements';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Stripe from 'assets/images/stripe-label-white.svg';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { AuthState, ESnackbarStyle, SubscriptionProduct, TeamsState } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import {
  getCurrentTeamCard,
  getTeamCardPaymentIntent,
  updateTeamCard
} from 'services/api/subscriptionsService';
import { setActiveTeamCard } from 'services/store/reducers/teamsReducer';
import { getLocationStateFromSessionStorage } from 'utils/storage-utils';
import { checkIsStripeTestCardError } from 'utils/error-utils';

type Props = {
  teamId: number;
  isCheckout?: boolean;
  plan?: SubscriptionProduct;
  isMonthlyType?: boolean;
};

const TeamCardForm: React.FC<Props> = ({
  teamId,
  isCheckout,
  isMonthlyType,
  plan
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { teamCard } = useAppSelector((store): TeamsState => store.teams);
  const { user } = useAppSelector((store): AuthState => store.auth);
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');

  const getClientSecret = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      const { client_secret } = (await getTeamCardPaymentIntent(teamId)).data;
      setClientSecret(client_secret);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch, teamId]);

  const updateCurrentTeamCard = async (): Promise<void> => {
    try {
      dispatch(startLoader());
      const currentCard = (await getCurrentTeamCard(teamId)).data;
      dispatch(setActiveTeamCard(currentCard));
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  useEffect((): void => {
    getClientSecret();
  }, [getClientSecret]);

  const handleSaveClick = async (): Promise<void> => {
    if (!!stripe && !!elements) {
      try {
        dispatch(startLoader());

        const { error: submitError } = await elements.submit();
        if (!!submitError) throw new Error(submitError.message);

        const { current } = getLocationStateFromSessionStorage();

        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${process.env.REACT_APP_URL}${current || ''}${
              isCheckout ? `?checkout=1&plan=${plan?.id}&monthly=${isMonthlyType ? 1 : 0}` : ''
            }`
          },
          redirect: 'if_required'
        });
        if (!!error) throw new Error(checkIsStripeTestCardError(error));

        if (!!setupIntent) await updateTeamCard(teamId, setupIntent.id);
        elements.getElement('payment')?.clear();
        elements.getElement(AddressElement)?.clear();
        openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully added');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        await updateCurrentTeamCard();
        await getClientSecret();
        dispatch(stopLoader());
      }
    }
  };

  return (
    <Styled.CardFormContainer>
      <Styled.FormTitle>{!!teamCard ? 'Change current card' : 'Add new card'}</Styled.FormTitle>
      <Styled.CardContainer>
        <PaymentElement
          options={{ layout: 'tabs', wallets: { applePay: 'auto', googlePay: 'auto' } }}
        />
        <AddressElement
          options={{
            mode: 'billing',
            defaultValues: { name: `${user?.firstName} ${user?.lastName}` }
          }}
        />
      </Styled.CardContainer>
      <Styled.CardFormFooter>
        <Styled.ActionButton onClick={handleSaveClick} id='save-card-button'>
          Save
        </Styled.ActionButton>
        <Styled.StripeLabel src={Stripe} />
      </Styled.CardFormFooter>
    </Styled.CardFormContainer>
  );
};

export default withElements(TeamCardForm);
