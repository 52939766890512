import { EFeatureId, EModelAccessLevel, EModelType, ETeamRole, Model } from 'shared/types';
import { useAppSelector } from 'shared/hooks';
import { checkModelFeature } from 'services/api/modelService';
import { useCallback, useEffect, useMemo } from 'react';
import { getModelType } from 'utils/model-utils';
import { getModelPermissions } from 'services/api/permissionsService';
import { getTeamMember } from 'services/api/teamService';
import { RootState } from 'services/store';

type Result = {
  checkArViewAccess: () => Promise<boolean>;
  checkCommentAccess: () => Promise<boolean>;
  checkEditAccess: () => Promise<boolean>;
};

const useFeatureAccess = (model: Model, withCurrentData?: boolean): Result => {
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const store = useAppSelector((store): RootState => store);
  const { user, features } = store.auth;
  const { teamRole, teamPlan } = store.teams;
  const modelType: EModelType = getModelType(model);
  const isModelOwner: boolean = model.ownerId === user?.id;

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  const getUserTeamRole = useCallback(
    async (): Promise<ETeamRole> =>
      !!model.teamId ? (await getTeamMember(model.teamId)).data.role : ETeamRole.VIEWER,
    [model]
  );

  const checkUserPlanHasFeature = useCallback(
    (featureId: EFeatureId): boolean =>
      !!features?.find((item): boolean => item.id === featureId)?.enabled,
    [features]
  );

  const checkTeamPlanHasFeature = useCallback(
    (featureId: EFeatureId): boolean =>
      !!teamPlan?.metadata.product_features?.find((item): boolean => item.id === featureId)
        ?.enabled,
    [teamPlan]
  );

  const checkModelHasFeature = useCallback(
    async (featureId: EFeatureId): Promise<boolean> =>
      withCurrentData && !!teamPlan
        ? checkTeamPlanHasFeature(featureId)
        : (await checkModelFeature(model.id, featureId, signal)).data.enabled,
    [checkTeamPlanHasFeature, model, signal, teamPlan, withCurrentData]
  );

  const checkUserAccessLevelToModel = useCallback(
    async (accessLevelOption: EModelAccessLevel): Promise<boolean> => {
      const { accessLevel } = withCurrentData
        ? model
        : (await getModelPermissions(model.id, signal)).data;
      return accessLevel.includes(accessLevelOption);
    },
    [model, signal, withCurrentData]
  );

  const checkUserHasAccessToModelFeature = useCallback(
    async (featureId: EFeatureId, accessLevelOption: EModelAccessLevel): Promise<boolean> =>
      (await checkModelHasFeature(featureId)) &&
      (await checkUserAccessLevelToModel(accessLevelOption)),
    [checkModelHasFeature, checkUserAccessLevelToModel]
  );

  const checkArViewAccess = useCallback(async (): Promise<boolean> => {
    switch (modelType) {
      case EModelType.OWNER:
        return checkUserPlanHasFeature(EFeatureId.WEB_AR_VIEWING_MODE);
      case EModelType.ANONYMOUS:
        return false;
      case EModelType.SAMPLE:
        return true;
      case EModelType.QUICK_VIEW:
        return checkUserPlanHasFeature(EFeatureId.WEB_AR_VIEWING_MODE);
      case EModelType.TEAM:
        return isModelOwner
          ? await checkModelHasFeature(EFeatureId.WEB_AR_VIEWING_MODE)
          : await checkUserHasAccessToModelFeature(
              EFeatureId.WEB_AR_VIEWING_MODE,
              EModelAccessLevel.VIEW_AR_ACCESS
            );
      case EModelType.SHARED:
        return checkUserHasAccessToModelFeature(
          EFeatureId.WEB_AR_VIEWING_MODE,
          EModelAccessLevel.VIEW_AR_ACCESS
        );
      default:
        return false;
    }
  }, [
    checkModelHasFeature,
    checkUserHasAccessToModelFeature,
    checkUserPlanHasFeature,
    isModelOwner,
    modelType
  ]);

  const checkCommentAccess = useCallback(async (): Promise<boolean> => {
    switch (modelType) {
      case EModelType.OWNER:
        return checkUserPlanHasFeature(EFeatureId.MAKE_3D_NOTES);
      case EModelType.ANONYMOUS:
        return false;
      case EModelType.SAMPLE:
        return true;
      case EModelType.QUICK_VIEW:
        return false;
      case EModelType.TEAM:
        return isModelOwner
          ? await checkModelHasFeature(EFeatureId.MAKE_3D_NOTES)
          : await checkUserHasAccessToModelFeature(
              EFeatureId.MAKE_3D_NOTES,
              EModelAccessLevel.COMMENT_ACCESS
            );
      case EModelType.SHARED:
        return checkUserHasAccessToModelFeature(
          EFeatureId.MAKE_3D_NOTES,
          EModelAccessLevel.COMMENT_ACCESS
        );
      default:
        return false;
    }
  }, [
    checkModelHasFeature,
    checkUserHasAccessToModelFeature,
    checkUserPlanHasFeature,
    isModelOwner,
    modelType
  ]);

  const checkEditAccess = useCallback(async (): Promise<boolean> => {
    switch (modelType) {
      case EModelType.OWNER:
        return true;
      case EModelType.ANONYMOUS:
        return false;
      case EModelType.SAMPLE:
        return false;
      case EModelType.QUICK_VIEW:
        return false;
      case EModelType.TEAM:
        const userTeamRole = withCurrentData && !!teamRole ? teamRole : await getUserTeamRole();
        return (
          userTeamRole !== ETeamRole.VIEWER &&
          (isModelOwner || checkUserAccessLevelToModel(EModelAccessLevel.EDIT_ACCESS))
        );
      case EModelType.SHARED:
        return checkUserAccessLevelToModel(EModelAccessLevel.EDIT_ACCESS);
      default:
        return false;
    }
  }, [
    checkUserAccessLevelToModel,
    getUserTeamRole,
    isModelOwner,
    modelType,
    teamRole,
    withCurrentData
  ]);

  return {
    checkArViewAccess,
    checkCommentAccess,
    checkEditAccess
  };
};

export default useFeatureAccess;
