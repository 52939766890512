import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import {
  RecenterIcon,
  ZenModeIcon,
  ViewInARIcon,
  IosShareIcon,
  CameraIcon
} from 'assets/dynamic-icons';
import LockIcon from 'assets/images/lock-icon.svg';
import { EFeatureId, EModelType } from 'shared/types';
import { Timer } from 'shared/components';
import { useLocation } from 'react-router-dom';
import LinkIcon from 'assets/dynamic-icons/link-icon/LinkIcon';
import { closeMessage, openMessage } from 'utils/message-utils';
import { checkModelFeature } from 'services/api/modelService';
import { URL_PATTERN } from 'shared/constants/regexps';

type EmbeddedModelButton = {
  key: string;
  name: string;
  icon: JSX.Element;
  action: () => void;
  isActive: boolean;
  isDefaultButton: boolean;
  isDisabled?: boolean;
};

type Props = {
  footerRef: React.MutableRefObject<HTMLDivElement | null>;
  modelId: string;
  setInitialCameraPosition: () => void;
  handleZenModeClick: () => void;
  handleArButtonClick: () => Promise<void>;
  handleShareButtonClick: () => void;
  handleScreenshotClick: () => void;
  hasArViewAccess: boolean;
  isZenMode: boolean;
  isScreenshotMenuActive: boolean;
  isArMode: boolean;
  isArModeLoading: boolean;
  isSharePanelActive: boolean;
  modelType: EModelType;
  isEmbeddedModelMode: boolean;
  isColumn: boolean;
};

const ViewerFooter: React.FC<Props> = ({
  footerRef,
  modelId,
  setInitialCameraPosition,
  handleZenModeClick,
  handleArButtonClick,
  handleShareButtonClick,
  handleScreenshotClick,
  hasArViewAccess,
  isZenMode,
  isScreenshotMenuActive,
  isArMode,
  isArModeLoading,
  isSharePanelActive,
  modelType,
  isEmbeddedModelMode,
  isColumn
}): JSX.Element => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isQuickView = modelType === EModelType.QUICK_VIEW;
  const isSampleModel = modelType === EModelType.SAMPLE;
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;
  const MAX_BUTTON_NAME_LENGTH = 16;
  const isRecenterButton = params.get('recenter');
  const isARButton = params.get('ar-button');
  const isShareButton = params.get('share');
  const [isCustomButtonsAvailable, setIsCustomButtonsAvailable] = useState<boolean>(isSampleModel);
  const [isCustomButtonClicked, setIsCustomButtonClicked] = useState<boolean>(false);

  useEffect((): void => {
    (async (): Promise<void> => {
      if (isEmbeddedModelMode && !isSampleModel) {
        const isCustomButtonsAvailable = (
          await checkModelFeature(modelId, EFeatureId.IFRAME_CUSTOM_BUTTONS)
        ).data.enabled;
        setIsCustomButtonsAvailable(isCustomButtonsAvailable);
      }
    })();
  }, [modelId, isEmbeddedModelMode, isSampleModel]);

  const getButtonName = (param: string): string => {
    const name = decodeURIComponent(params.get(param) || '');
    return name.slice(0, MAX_BUTTON_NAME_LENGTH);
  };

  const getButtonLink = (param: string): string => {
    const link = decodeURIComponent(params.get(param) || '');
    return !!link && URL_PATTERN.test(link) ? link : '';
  };

  const customButtons = [
    {
      key: 'first-cb',
      name: getButtonName('first-cb-name'),
      link: getButtonLink('first-cb-link')
    },
    {
      key: 'second-cb',
      name: getButtonName('second-cb-name'),
      link: getButtonLink('second-cb-link')
    },
    {
      key: 'third-cb',
      name: getButtonName('third-cb-name'),
      link: getButtonLink('third-cb-link')
    }
  ];

  const handleCustomButtonClick =
    (link: string): (() => void) =>
    (): void => {
      if (!isCustomButtonClicked) {
        setIsCustomButtonClicked(true);

        const timeoutHandler = setTimeout((): void => {
          window.open(link, '_blank');
          closeMessage();
          setIsCustomButtonClicked(false);
        }, 11000);

        const handleCancelClick = (): void => {
          clearTimeout(timeoutHandler);
          closeMessage();
          setIsCustomButtonClicked(false);
        };

        const handleOpenClick = (): void => {
          clearTimeout(timeoutHandler);
          closeMessage();
          setIsCustomButtonClicked(false);
          window.open(link, '_blank');
        };

        openMessage(
          <Styled.LinkWarningContainer>
            <p className='warning'>You are about to open the following webpage</p>
            <p className='link' onClick={handleOpenClick}>
              {link}
            </p>
            <div className='timer-warning'>
              <span>The link will automatically open after </span>
              <Timer startValue={10} />
              <span>s...</span>
            </div>
            <Styled.LinkWarningButtons>
              <Styled.ActionButton
                className='cancel-button'
                type='button'
                onClick={handleCancelClick}
              >
                Cancel
              </Styled.ActionButton>
              <Styled.ActionButton className='open-button' type='button' onClick={handleOpenClick}>
                Open link
              </Styled.ActionButton>
            </Styled.LinkWarningButtons>
          </Styled.LinkWarningContainer>,
          1100
        );
      }
    };

  const CUSTOM_BUTTONS: EmbeddedModelButton[] = customButtons
    .filter(({ name, link }): boolean => !!name && !!link)
    .map(
      ({ name, link, key }): EmbeddedModelButton => ({
        key,
        name,
        icon: <LinkIcon />,
        action: handleCustomButtonClick(link),
        isActive: isCustomButtonsAvailable,
        isDefaultButton: false
      })
    );

  const EMBEDDED_MODEL_FOOTER_BUTTONS: EmbeddedModelButton[] = [
    {
      key: 'recenter',
      name: 'Recenter',
      icon: <RecenterIcon />,
      action: setInitialCameraPosition,
      isActive: !!+(isRecenterButton || ''),
      isDefaultButton: true
    },
    {
      key: 'ar',
      name: isArMode ? 'View in 3D' : 'View in AR',
      icon: <ViewInARIcon />,
      action: handleArButtonClick,
      isActive: !!+(isARButton || '') && hasArViewAccess,
      isDisabled: isArModeLoading,
      isDefaultButton: true
    },
    {
      key: 'share',
      name: 'Share',
      icon: <IosShareIcon />,
      action: handleShareButtonClick,
      isActive: !!+(isShareButton || ''),
      isDefaultButton: true
    }
  ];

  const FOOTER_BUTTONS = [...EMBEDDED_MODEL_FOOTER_BUTTONS, ...CUSTOM_BUTTONS].filter(
    ({ isActive }): boolean => isActive
  );

  return (
    <>
      {isEmbeddedModelMode ? (
        <Styled.EmbeddedSceneFooterContainer>
          <Styled.EmbeddedSceneFooter ref={footerRef} isColumn={isColumn}>
            {FOOTER_BUTTONS.map(
              ({ name, icon, action, key, isDisabled, isDefaultButton }): JSX.Element => (
                <Styled.FooterButton
                  key={key}
                  isColumn={isColumn}
                  isDefaultButton={isDefaultButton}
                  onClick={action}
                  disabled={isDisabled}
                  type='button'
                >
                  <Styled.IconContainer className={key}>{icon}</Styled.IconContainer>
                  <span>{name}</span>
                </Styled.FooterButton>
              )
            )}
          </Styled.EmbeddedSceneFooter>
        </Styled.EmbeddedSceneFooterContainer>
      ) : (
        <Styled.FooterContainer>
          <Styled.ControlButton className='recenter' onClick={setInitialCameraPosition}>
            <Styled.IconContainer>
              <RecenterIcon />
            </Styled.IconContainer>
            <span id='recenter-button'>Recenter</span>
          </Styled.ControlButton>

          <Styled.ControlButton isActive={isZenMode} className='zen' onClick={handleZenModeClick}>
            <Styled.IconContainer className='zen-icon'>
              <ZenModeIcon />
            </Styled.IconContainer>
            <span id='zen-mode-button'>Zen mode</span>
          </Styled.ControlButton>

          {!isArMode && (
            <Styled.ControlButton
              isActive={isScreenshotMenuActive}
              className='screenshot'
              onClick={handleScreenshotClick}
            >
              <Styled.IconContainer className='camera-icon'>
                <CameraIcon />
              </Styled.IconContainer>
              <span>Screenshot</span>
            </Styled.ControlButton>
          )}

          <Styled.ControlButton className='ar' onClick={handleArButtonClick}>
            <Styled.IconContainer className='ar-icon'>
              <ViewInARIcon />
            </Styled.IconContainer>
            <span id='ar-button'>{isArMode ? '3D View' : 'AR View'}</span>
            {!hasArViewAccess && (
              <Styled.Lock>
                <img src={LockIcon} alt='Lock' />
              </Styled.Lock>
            )}
          </Styled.ControlButton>

          <Styled.ControlButton
            isActive={isSharePanelActive}
            className='share'
            onClick={handleShareButtonClick}
            disabled={isQuickView || isAnonymousModel || isSampleModel}
          >
            <Styled.IconContainer className='share-icon'>
              <IosShareIcon />
            </Styled.IconContainer>
            <span id='share-button'>Share</span>
            {(isQuickView || isAnonymousModel || isSampleModel) && (
              <Styled.Lock>
                <img src={LockIcon} alt='Lock' />
              </Styled.Lock>
            )}
          </Styled.ControlButton>
        </Styled.FooterContainer>
      )}
    </>
  );
};

export default ViewerFooter;
