import React from 'react';

const CommentIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        viewBox='0 0 27 24'
        preserveAspectRatio='xMidYMax meet'
      >
        <path
          d='M8.9 7.41667L17.6 1L26 7.41667V18.7222L17.6 23H2L8.9 18.7222V7.41667Z'
          fill='black'
        />
        <path
          d='M17.6 1L8.9 7.41667V18.7222M17.6 1V12.3056M17.6 1L26 7.41667V18.7222M8.9 18.7222L17.6 12.3056M8.9 18.7222L17.6 23M8.9 18.7222L2 23H17.6M17.6 12.3056L26 18.7222M26 18.7222L17.6 23'
          stroke='currentColor'
        />
      </svg>
    </div>
  );
};

export default CommentIcon;
