import * as Styled from './styles';
import HintIcon from 'assets/images/hint.svg';
import UploadFileIcon from 'assets/images/upload-file.svg';
import TrashIcon from 'assets/images/remove.svg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModalEnvironmentFileLimits, TransformField } from 'shared/components';
import { useAppDispatch, useAppSelector, useCustomizationData } from 'shared/hooks';
import {
  changeEnvScale,
  setEnvironmentPreviewURL,
  setEnvType
} from 'services/store/reducers/customizationReducer';
import {
  AxisValues,
  CustomEnvironment,
  CustomizationState,
  ESnackbarStyle,
  ETransformFiledType
} from 'shared/types';
import { Select } from '../select';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { MAX_ENV_FILE_SIZE, MAX_ENV_TEXTURES_COUNT } from 'shared/constants/limits';
import { openNotification } from 'utils/notification-utils';
import {
  checkGltfFileSecurity,
  createZipFromFiles,
  getFileType,
  getFileUrls,
  handleInputFiles
} from 'utils/file-processing-utils';
import {
  GLB_GLTF_MODEL_TYPE_REGEXP,
  IMAGE_FILE_FORMATS,
  MTL_FORMAT_REGEXP
} from 'shared/constants/regexps';
import { INCORRECT_ENV_FILE_FORMAT } from 'shared/constants/notifications';
import { CREATE_NEW_ID, DEFAULT_ENV_SETTINGS } from 'shared/constants/customization';
import {
  deleteCustomEnvironment,
  updateCustomEnvironment,
  uploadEnvironmentThumbnail
} from 'services/api/customizationService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { convertDataToFormData, convertDataURLtoFormData } from 'utils/convert-file-utils';
import { CustomTooltip } from 'shared/components';

type EnvSelectOption = {
  title: string;
  value: CustomEnvironment;
};

const EnvironmentSettings = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { environmentPreviewURL, envScale, envThumbnail } = useAppSelector(
    (store): CustomizationState => store.customization
  );
  const { customEnvironments, fetchCustomEnvironments, uploadCustomEnvironment } =
    useCustomizationData();
  const [activeEnv, setActiveEnv] = useState<CustomEnvironment>(DEFAULT_ENV_SETTINGS);
  const [uploadedEnvFile, setUploadedEnvFile] = useState<File | null>(null);
  const MIN_ENV_FILE_NAME_LENGTH = 3;
  const MAX_ENV_FILE_NAME_LENGTH = 32;

  useEffect((): void => {
    fetchCustomEnvironments();
  }, [fetchCustomEnvironments]);

  useEffect((): void => {
    dispatch(changeEnvScale(activeEnv.presets.scale));
  }, [activeEnv, dispatch]);

  const handleSaveButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    dispatch(startLoader());
    try {
      const getZipFile = async (uploadedEnvFile: File): Promise<File> =>
        uploadedEnvFile.name.endsWith('zip')
          ? uploadedEnvFile
          : await createZipFromFiles([uploadedEnvFile], uploadedEnvFile.name.split('.')[0]);
      const data = !!uploadedEnvFile
        ? {
            file: await getZipFile(uploadedEnvFile),
            environmentName: activeEnv.environmentName,
            presets: JSON.stringify({ scale: envScale })
          }
        : { presets: JSON.stringify({ scale: envScale }) };
      const formData = convertDataToFormData(data);
      const newEnvironment =
        activeEnv.id === CREATE_NEW_ID
          ? await uploadCustomEnvironment(formData)
          : (await updateCustomEnvironment(activeEnv.id, formData)).data;
      if (!newEnvironment) return;
      const thumbnail: FormData = convertDataURLtoFormData(envThumbnail, 'environment-preview.png');
      await uploadEnvironmentThumbnail(newEnvironment.id, thumbnail);
      await fetchCustomEnvironments();
      setActiveEnv({ ...DEFAULT_ENV_SETTINGS, presets: { scale: { x: 1, y: 1, z: 1 } } });
      dispatch(setEnvironmentPreviewURL(DEFAULT_ENV_SETTINGS.publicUrl));
      setUploadedEnvFile(null);
      openNotification(
        ESnackbarStyle.SUCCESS,
        activeEnv.id === CREATE_NEW_ID
          ? 'Your environment has been successfully uploaded'
          : 'Your environment has been successfully updated'
      );
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleRemoveEnvClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      if (activeEnv.id !== CREATE_NEW_ID) {
        await deleteCustomEnvironment(activeEnv.id);
        await fetchCustomEnvironments();
        openNotification(ESnackbarStyle.SUCCESS, 'Your environment has been successfully removed');
      }
      setActiveEnv(DEFAULT_ENV_SETTINGS);
      dispatch(setEnvironmentPreviewURL(null));
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleEnvOptionRemove = async (envId: number): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteCustomEnvironment(envId);
      await fetchCustomEnvironments();
      openNotification(ESnackbarStyle.SUCCESS, 'Your environment has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleEnvSelectChange = ({ value }: EnvSelectOption): void => {
    setActiveEnv(value);
    dispatch(setEnvironmentPreviewURL(value.publicUrl));
    setUploadedEnvFile(null);
  };

  const handleEnvScaleChange = useCallback(
    (fieldType: ETransformFiledType, values: AxisValues): void => {
      dispatch(changeEnvScale(values));
    },
    [dispatch]
  );

  const handleEnvironmentsLimitsClick = (): void => {
    dispatch(showModal(<ModalEnvironmentFileLimits />));
  };

  const validateEnvForLimits = (files: FileWithPath[], targetFiles: File[]): boolean => {
    const totalEnvSize = files.reduce((total, { size }): number => total + size, 0);
    if (totalEnvSize > MAX_ENV_FILE_SIZE) {
      openNotification(
        ESnackbarStyle.HOLD_UP,
        `Environment size exceeds the allowed limit. 5MB maximum file size.`
      );
      return false;
    }

    const totalTextures = targetFiles.reduce(
      (total, { name }): number => (IMAGE_FILE_FORMATS.test(name) ? total + 1 : total),
      0
    );
    if (totalTextures > MAX_ENV_TEXTURES_COUNT) {
      openNotification(
        ESnackbarStyle.HOLD_UP,
        `Environment files must contain no more than 5 images.`
      );
      return false;
    }

    const fileNameLength = files[0].name.split('.')[0].length;
    const isFileNameValid =
      fileNameLength >= MIN_ENV_FILE_NAME_LENGTH && fileNameLength <= MAX_ENV_FILE_NAME_LENGTH;
    if (!isFileNameValid) {
      openNotification(
        ESnackbarStyle.HOLD_UP,
        `The file name must be more than 3 characters and less than 32 characters.`
      );
      return false;
    }

    return true;
  };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    try {
      const { jsZipObjects, unzippedFiles, nonZipFiles } = await handleInputFiles(files);
      const targetFiles: File[] = [...unzippedFiles, ...nonZipFiles];
      const isValid = validateEnvForLimits(files, targetFiles);
      if (!isValid) return;

      const envType = getFileType(targetFiles);
      const isEnvSecure =
        !!envType &&
        (!GLB_GLTF_MODEL_TYPE_REGEXP.test(envType) || (await checkGltfFileSecurity(targetFiles)));
      if (!isEnvSecure) throw new Error(INCORRECT_ENV_FILE_FORMAT);
      const urls = await getFileUrls(nonZipFiles, jsZipObjects);
      const fileNames = Object.keys(urls);
      const mainFileNamesWithPath = fileNames.filter(
        (item): boolean => !MTL_FORMAT_REGEXP.test(item)
      );
      const envPath = urls[mainFileNamesWithPath[0]];
      dispatch(setEnvType(envType));
      dispatch(setEnvironmentPreviewURL(envPath));
      setActiveEnv(
        (prev): CustomEnvironment => ({
          ...prev,
          environmentName: files[0].name.split('.')[0],
          publicUrl: envPath
        })
      );
      setUploadedEnvFile(files[0]);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  };

  const ENV_SELECT_OPTIONS = useMemo(
    (): EnvSelectOption[] => [
      { title: DEFAULT_ENV_SETTINGS.environmentName, value: DEFAULT_ENV_SETTINGS },
      ...customEnvironments.map(
        (env): EnvSelectOption => ({ title: env.environmentName.split('.')[0], value: env })
      )
    ],
    [customEnvironments]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noDrag: true });

  return (
    <Styled.SettingsSection>
      <Styled.SectionHeader>
        <Styled.SettingsSectionTitleContainer>
          <Styled.SettingsSectionTitle>Custom Environments</Styled.SettingsSectionTitle>
          <CustomTooltip content='Upload a custom model that you can use as in the environment dropdown'>
            <Styled.SettingsSectionTitleHint src={HintIcon} alt='Hint Icon' />
          </CustomTooltip>
        </Styled.SettingsSectionTitleContainer>
        <Styled.SettingsLimits onClick={handleEnvironmentsLimitsClick}>
          Environment File Limits
        </Styled.SettingsLimits>
      </Styled.SectionHeader>
      <Styled.EnvironmentsSettings>
        <Styled.SettingsField>
          <Styled.UploadEnvButtonContainer>
            {activeEnv.id === CREATE_NEW_ID && (
              <Styled.UploadEnvButton {...getRootProps()}>
                <input {...getInputProps()} />
                <img src={UploadFileIcon} alt='Upload environment button' />
              </Styled.UploadEnvButton>
            )}
          </Styled.UploadEnvButtonContainer>
          <Styled.FieldInputContainer>
            <Select
              activeOption={{ title: activeEnv.environmentName, value: activeEnv }}
              options={ENV_SELECT_OPTIONS}
              onChangeOption={handleEnvSelectChange}
              onRemoveOption={handleEnvOptionRemove}
              placeholder={'Default environment configuration'}
            />
            {!!environmentPreviewURL && (
              <Styled.RemoveButton type='button' onClick={handleRemoveEnvClick}>
                <img src={TrashIcon} alt='Remove environment' />
              </Styled.RemoveButton>
            )}
          </Styled.FieldInputContainer>
        </Styled.SettingsField>
        <Styled.SettingsField>
          <Styled.FieldLabel>Scale</Styled.FieldLabel>
          <Styled.TransformFieldContainer>
            <TransformField
              action={handleEnvScaleChange}
              initialValues={activeEnv.presets.scale}
              fieldType={ETransformFiledType.SCALE}
            />
          </Styled.TransformFieldContainer>
        </Styled.SettingsField>
        <Styled.SaveButton
          type='submit'
          onClick={handleSaveButtonClick}
          disabled={activeEnv.id === CREATE_NEW_ID && !uploadedEnvFile}
        >
          Save
        </Styled.SaveButton>
      </Styled.EnvironmentsSettings>
    </Styled.SettingsSection>
  );
};

export default EnvironmentSettings;
