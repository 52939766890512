import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { useFormik } from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar/dist';
import { validation } from 'services/validation';
import { getFieldError } from 'utils/form-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { showConfirmModal, showModal } from 'services/store/reducers/modalReducer';
import { ModalDeleteAccount, ModalForgotPassword } from 'shared/components';
import { EConfirmModalHeader, ESnackbarStyle, User } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import {
  changePassword,
  changeUserName,
  getCurrentUser,
  getUserTotalModels
} from 'services/api/authService';
import { loginUser } from 'services/store/reducers/authReducer';
import { openNotification } from 'utils/notification-utils';

type InitialValues = {
  newPassword: string;
  currentPassword: string;
};

const AccountTab = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state): User | null => state.auth.user);
  const [email, setEmail] = useState<string>(user?.email || '');
  const [firstName, setFirstName] = useState<string>(user?.firstName || '');
  const [lastName, setLastName] = useState<string>(user?.lastName || '');
  const [totalModels, setTotalModels] = useState<number>(0);
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(false);

  useEffect((): void => {
    const getTotalModels = async (): Promise<void> => {
      dispatch(startLoader());
      try {
        const totalModels = (await getUserTotalModels()).data;
        setTotalModels(totalModels.count);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };
    getTotalModels();
  }, [dispatch]);

  const initialValues: InitialValues = {
    newPassword: '',
    currentPassword: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ newPassword, currentPassword }): Promise<void> => {
      dispatch(startLoader());
      try {
        await changePassword({
          updatedPassword: newPassword,
          confirmPassword: newPassword,
          currentPassword
        });
        formik.resetForm();
        openNotification(ESnackbarStyle.SUCCESS, 'Your password has been successfully updated');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.ACCOUNT_SETTINGS
  });

  const saveUserName = async (): Promise<void> => {
    if (!firstName.trim() || !lastName.trim()) {
      openNotification(ESnackbarStyle.ERROR, `The name field cannot be empty`);
      return;
    }
    dispatch(startLoader());
    try {
      await changeUserName({ firstName: firstName.trim(), lastName: lastName.trim() });
      const response = await getCurrentUser();
      await dispatch(loginUser({ user: response.data }));
      openNotification(ESnackbarStyle.SUCCESS, 'Your name has been successfully updated');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLastName(event.target.value);
  };

  const handleHideButtonClick = (): void => {
    setIsPasswordHidden((prev): boolean => !prev);
  };

  const handleResetPasswordClick = (): void => {
    dispatch(showModal(<ModalForgotPassword />));
  };

  const handleDeleteAccountClick = (): void => {
    dispatch(
      showConfirmModal({ header: EConfirmModalHeader.DELETE, content: <ModalDeleteAccount /> })
    );
  };

  return (
    <Styled.TabContent>
      <Styled.FormContainer>
        <Styled.AccountInfoForm onSubmit={handleSubmit}>
          <Styled.FieldTitle>User Name</Styled.FieldTitle>
          <Styled.ChangeNameSection>
            <Styled.UserNameField>
              <Styled.InputLabel>First name</Styled.InputLabel>
              <Styled.NameInput
                value={firstName}
                onChange={handleFirstNameChange}
                className='user-name'
              />
            </Styled.UserNameField>
            <Styled.UserNameField>
              <Styled.InputLabel>Last name</Styled.InputLabel>
              <Styled.NameInput
                value={lastName}
                onChange={handleLastNameChange}
                className='user-name'
              />
            </Styled.UserNameField>
            <Styled.ActionButton
              onClick={saveUserName}
              className='name'
              id='change-user-name-button'
            >
              Change
            </Styled.ActionButton>
          </Styled.ChangeNameSection>

          <Styled.EmailFieldContainer>
            <Styled.FieldTitle>Email Address</Styled.FieldTitle>
            <Styled.EmailField>
              <Styled.EmailInput
                value={email}
                onChange={handleEmailChange}
                type='email'
                placeholder='Email'
              />
              <Styled.ActionButton id='change-user-email-button'>Change</Styled.ActionButton>
            </Styled.EmailField>
          </Styled.EmailFieldContainer>
          <Styled.PasswordField>
            <Styled.PasswordFieldHeader>
              <Styled.FieldTitle>Password</Styled.FieldTitle>
              <Styled.ActionButton onClick={handleHideButtonClick}>
                {isPasswordHidden ? 'Show' : 'Hide'}
              </Styled.ActionButton>
            </Styled.PasswordFieldHeader>
            <Styled.ChangePasswordSection>
              <Styled.PasswordFormField>
                <Styled.InputLabel>New Password</Styled.InputLabel>
                <Styled.PasswordInput
                  value={values.newPassword}
                  onChange={handleChange}
                  name={'newPassword'}
                  onBlur={handleBlur}
                  type={isPasswordHidden ? 'password' : 'text'}
                  autoComplete='new-password'
                />
                {getFieldError(formik, 'newPassword')}
                {!!values.newPassword.length && (
                  <PasswordStrengthBar password={values.newPassword} />
                )}
              </Styled.PasswordFormField>
              <Styled.PasswordFormField>
                <Styled.InputLabel>Current Password</Styled.InputLabel>
                <Styled.PasswordInput
                  value={values.currentPassword}
                  onChange={handleChange}
                  name={'currentPassword'}
                  onBlur={handleBlur}
                  type={isPasswordHidden ? 'password' : 'text'}
                  autoComplete='new-password'
                />
                {getFieldError(formik, 'currentPassword')}
              </Styled.PasswordFormField>
            </Styled.ChangePasswordSection>
          </Styled.PasswordField>
          <Styled.ForgotPasswordField>
            <span>Can’t remember your current password?</span>
            <span onClick={handleResetPasswordClick}>Reset your password</span>
          </Styled.ForgotPasswordField>
          <Styled.SaveButton type='submit' id='save-password-button'>
            Save Password
          </Styled.SaveButton>
        </Styled.AccountInfoForm>
        <Styled.DeleteAccountBlock>
          <Styled.FieldTitle>Delete Account</Styled.FieldTitle>
          <Styled.DeleteWarning>
            <span>Would you like to delete your account?</span>
            <span>
                {`This account contains ${totalModels} models. Deleting your account will remove all the content
                associated with it.`}
              </span>
          </Styled.DeleteWarning>
          <Styled.DeleteButton onClick={handleDeleteAccountClick} id='delete-my-account-button'>
            I want to delete my account
          </Styled.DeleteButton>
        </Styled.DeleteAccountBlock>
      </Styled.FormContainer>
    </Styled.TabContent>
  );
};

export default AccountTab;
