import { AxiosResponse } from 'axios';
import { EmbeddedCustomButton, EmbeddedModelSettings, WhitelistDomain } from 'shared/types';
import ApiService from './api';

export const getEmbeddedModelSettings = async (
  modelId: string
): Promise<AxiosResponse<EmbeddedModelSettings>> =>
  await ApiService.get<EmbeddedModelSettings>(`models/${modelId}/embedded-settings`);

export const postEmbeddedModelSettings = async (
  modelId: string,
  settings: EmbeddedModelSettings
): Promise<AxiosResponse<EmbeddedModelSettings>> =>
  await ApiService.post<EmbeddedModelSettings, EmbeddedModelSettings>(
    `models/${modelId}/embedded-settings`,
    settings
  );

export const updateEmbeddedModelSettings = async (
  modelId: string,
  settings: EmbeddedModelSettings
): Promise<AxiosResponse<void>> =>
  await ApiService.patch<EmbeddedModelSettings, void>(
    `models/${modelId}/embedded-settings`,
    settings
  );

export const getEmbeddedModelCustomButtons = async (
  modelId: string
): Promise<AxiosResponse<EmbeddedCustomButton[]>> =>
  await ApiService.get<EmbeddedCustomButton[]>(`models/${modelId}/custom-buttons`);

export const postEmbeddedModelCustomButton = async (
  modelId: string,
  button: EmbeddedCustomButton
): Promise<AxiosResponse<EmbeddedCustomButton>> =>
  await ApiService.post<EmbeddedCustomButton, EmbeddedCustomButton>(
    `models/${modelId}/custom-buttons`,
    button
  );

export const updateEmbeddedModelCustomButton = async (
  modelId: string,
  buttonId: number,
  button: EmbeddedCustomButton
): Promise<AxiosResponse<EmbeddedCustomButton>> =>
  await ApiService.patch<EmbeddedCustomButton, EmbeddedCustomButton>(
    `models/${modelId}/custom-buttons/${buttonId}`,
    button
  );

export const getDomainsWhitelist = async (modelId: string): Promise<AxiosResponse<WhitelistDomain[]>> =>
  await ApiService.get<WhitelistDomain[]>(`models/${modelId}/whitelist-domains`);

export const postWhitelistDomain = async (
  modelId: string,
  domainName: string
): Promise<AxiosResponse<WhitelistDomain>> =>
  await ApiService.post<{ domainName: string }, WhitelistDomain>(`models/${modelId}/whitelist-domains`, {
    domainName
  });

export const deleteWhitelistDomain = async (
  modelId: string,
  domainId: number
): Promise<AxiosResponse<void>> =>
  await ApiService.delete(`models/${modelId}/whitelist-domains/${domainId}`);
