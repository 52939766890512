import { useEffect, useState } from 'react';

type Result = {
  isDragActive: boolean;
};

const useScreenDragEvent = (): Result => {
  const [isDragActive, setIsDragActive] = useState<boolean>(false);

  useEffect((): (() => void) => {
    let dragEventCounter = 0;

    const handleOnDragEnter = (): void => {
      if (!dragEventCounter) setIsDragActive(true);
      dragEventCounter++;
    };

    const handleOnDragLeave = (): void => {
      dragEventCounter--;
      if (!dragEventCounter) setIsDragActive(false);
    };

    const handleOnDrop = (): void => {
      dragEventCounter = 0;
      setIsDragActive(false);
    };

    document.addEventListener('dragenter', handleOnDragEnter);
    document.addEventListener('dragleave', handleOnDragLeave);
    document.addEventListener('drop', handleOnDrop);

    return (): void => {
      document.removeEventListener('dragenter', handleOnDragEnter);
      document.removeEventListener('dragleave', handleOnDragLeave);
      document.removeEventListener('drop', handleOnDrop);
    };
  }, []);

  return { isDragActive };
};

export default useScreenDragEvent;
