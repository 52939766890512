import { ESnackbarStyle, EModelType } from 'shared/types';
import { notification } from 'antd';
import { DEFAULT_ERROR } from 'shared/constants/errors';
import { CloseOutlined } from '@ant-design/icons';
import { SNACKBAR_TYPES } from 'shared/constants/notification-types';
import { store } from 'services/store';

export const elements = (className: string): Element[] =>
  Array.from(document.getElementsByClassName(className));

export const openNotification = (type: ESnackbarStyle, text: string = DEFAULT_ERROR): void => {
  const { message, className, icon } = SNACKBAR_TYPES[type];
  const { model, isZenMode, modelType, isEmbeddedModelMode } = store.getState().viewerData;
  const isQuickView = modelType === EModelType.QUICK_VIEW;
  const isSampleModel = modelType === EModelType.SAMPLE;
  const isSceneWithoutSharePanel = isZenMode || isQuickView || isSampleModel || isEmbeddedModelMode;

  notification.open({
    message: (
      <div className='notification-message' style={{ fontWeight: '700', color: '#ffffff' }}>
        {message}
      </div>
    ),
    description: (
      <div className='notification-description' style={{ color: '#ffffff' }}>
        {text}
      </div>
    ),
    icon,
    closeIcon: <CloseOutlined style={{ color: '#ffffff' }} />,
    style: {
      color: '#ffffff',
      background: 'linear-gradient(92.44deg, rgba(10, 10, 10, 0.7) 8%, #111111 95.55%)',
      borderRadius: '4px',
      backdropFilter: 'blur(10px)'
    },
    className
  });

  setTimeout((): void => {
    const antNotifications = elements('ant-notification');
    if (!!antNotifications.length) {
      for (const element of antNotifications) {
        if (!!model) {
          element.classList.add('scene-notification');
          if (isSceneWithoutSharePanel) {
            element.classList.add('full-viewer-width');
          }
          if (isZenMode || isEmbeddedModelMode) {
            element.classList.add('zen-mode');
          }
        }
      }
    }

    const notificationsDescription = elements('notification-description');
    if (!!notificationsDescription) {
      for (const element of notificationsDescription) {
        const descriptionText = element.textContent;
        if (descriptionText?.includes('upgrade their account')) {
          element.innerHTML = descriptionText.replace(
            'upgrade their account',
            '<span style="color: #FFE406">upgrade their account</span>'
          );
        }
      }
    }
  }, 100);
};
