import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';

export const PageContent = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;