import { message } from 'antd';
import {
  CustomMessageContainer,
  CustomMessageContent,
  CustomMessageContentText
} from 'shared/styles';

export const closeMessage = (): void => {
  message.destroy();
};

export const openMessage = (content: any, duration: number = 5): void => {
  const millisecondsPerSecond = 1000;

  message.open({
    content: (
      <CustomMessageContainer>
        <CustomMessageContent>
          {typeof content === 'string' ? (
            <CustomMessageContentText>{content}</CustomMessageContentText>
          ) : (
            content
          )}
        </CustomMessageContent>
      </CustomMessageContainer>
    ),
    duration
  });

  setTimeout((): void => {
    closeMessage();
  }, duration * millisecondsPerSecond);
};
