import { BaseLoadEnvironmentStrategy } from './base-load-environment.strategy';
import {
  LoadEnvironmentData,
  LoadEnvironmentResult
} from 'shared/interfaces';
import { generateGriddedEnvironment } from 'utils/scenes-utils';

export class GriddedGeneratedLoadEnvironmentStrategy extends BaseLoadEnvironmentStrategy {
  public async changeEnvironment({
    environmentWrapper,
    renderer,
    data
  }: LoadEnvironmentData): Promise<LoadEnvironmentResult> {
    if (data && 'groundTexturePath' in data) {
      const environment = await generateGriddedEnvironment(
        renderer,
        data.groundTexturePath,
        data.groundMaskColor,
        data.sphereMaterialColor
      );

      this.clearEnvironmentWrapper(environmentWrapper);
      environmentWrapper.add(environment);

      return environment;
    }

    return null;
  }
}
