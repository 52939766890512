import React from 'react';
import * as Styled from './styles';
import {
  CustomizationPreview,
  ThemeSettings,
  EnvironmentSettings,
  CubemapSettings
} from './components';

type Props = {
  isEnvSettingsAvailable: boolean;
};

const Customization: React.FC<Props> = ({ isEnvSettingsAvailable }): JSX.Element => {
  return (
    <Styled.CustomizationContent>
      <Styled.LeftSideSection>
        <ThemeSettings />
        {isEnvSettingsAvailable && <EnvironmentSettings />}
        <CubemapSettings />
      </Styled.LeftSideSection>
      <Styled.CustomizationPreviewContainer>
        <CustomizationPreview />
      </Styled.CustomizationPreviewContainer>
    </Styled.CustomizationContent>
  );
};

export default Customization;
