import { BaseLoadModelStrategy } from './base-load-model.strategy';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import * as THREE from 'three';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';

export class StlLoadModelStrategy extends BaseLoadModelStrategy {
  private stlLoader: STLLoader;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    super(renderer, onModelResourcesLoaded);

    this.stlLoader = new STLLoader(this.loadingManager).setCrossOrigin('anonymous');
  }

  public async loadModel({ path }: LoadModelData): Promise<LoadModelResult> {
    const material = new THREE.MeshPhysicalMaterial({
      color: 0x202020,
      metalness: 0.5,
      roughness: 0.5,
      reflectivity: 0.5,
      // envMap: this.scene.environment,
      clearcoat: 1.0,
      clearcoatRoughness: 0.25
    });
    const geometry = await this.stlLoader.loadAsync(path);
    const mesh = new THREE.Mesh(geometry, material);

    const model = new THREE.Group();
    model.add(mesh);

    return {
      gltf: null,
      model
    };
  }
}
