import React, { useEffect } from 'react';
import * as Styled from './styles';
import { PageTitle } from 'shared/styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { clearCustomizationData } from 'services/store/reducers/customizationReducer';
import { Customization } from 'shared/components';
import { AuthState, EFeatureId } from 'shared/types';

const CustomizationTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { features } = useAppSelector((store): AuthState => store.auth);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(clearCustomizationData());
    },
    [dispatch]
  );

  const isEnvSettingsAvailable = !!features.find(
    (item): boolean => item.id === EFeatureId.CUSTOM_UI_BRANDING
  )?.enabled;

  return (
    <Styled.PageContainer>
      <Styled.PageHeader>
        <PageTitle>Customization</PageTitle>
      </Styled.PageHeader>
      <Styled.PageContent>
        <Customization isEnvSettingsAvailable={isEnvSettingsAvailable} />
      </Styled.PageContent>
    </Styled.PageContainer>
  );
};

export default CustomizationTab;
