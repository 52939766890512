import React, { useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import { PageContainer } from 'shared/styles';
import { AccountTab, CameraControlsTab, NotificationsTab } from './tabs';
import { useHistory, useLocation } from 'react-router-dom';

enum PageTab {
  ACCOUNT,
  NOTIFICATIONS,
  CAMERA_CONTROLS
}

type TabData = {
  id: PageTab;
  title: string;
  component: React.ReactElement;
};

const AccountSettingsPage = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo((): URLSearchParams => new URLSearchParams(location.search), [location]);
  const isCameraControlsTab = params.get('camera-controls');
  const [activeTab, setActiveTab] = useState(
    isCameraControlsTab ? PageTab.CAMERA_CONTROLS : PageTab.ACCOUNT
  );

  useEffect((): void => {
    if (isCameraControlsTab) {
      params.delete('camera-controls');
      history.replace({ search: params.toString() });
    }
  }, [history, isCameraControlsTab, params]);

  const TAB: Record<PageTab, TabData> = {
    [PageTab.ACCOUNT]: {
      id: PageTab.ACCOUNT,
      title: 'Account',
      component: <AccountTab />
    },
    [PageTab.NOTIFICATIONS]: {
      id: PageTab.NOTIFICATIONS,
      title: 'Notifications',
      component: <NotificationsTab />
    },
    [PageTab.CAMERA_CONTROLS]: {
      id: PageTab.CAMERA_CONTROLS,
      title: 'Camera controls',
      component: <CameraControlsTab />
    }
  };

  const handleTabClick =
    (tabId: PageTab): (() => void) =>
    (): void => {
      setActiveTab(tabId);
    };

  return (
    <PageContainer>
      <Styled.PageHeader>
        <Styled.TabsContainer>
          {Object.values(TAB).map(
            ({ id, title }): React.ReactElement => (
              <Styled.PageTab
                isActiveTab={id === activeTab}
                icon={''}
                onClick={handleTabClick(id)}
                key={id}
              >
                <span>{title}</span>
              </Styled.PageTab>
            )
          )}
        </Styled.TabsContainer>
      </Styled.PageHeader>
      <Styled.PageContent>{TAB[activeTab].component}</Styled.PageContent>
    </PageContainer>
  );
};

export default AccountSettingsPage;
