import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 740px;
  width: 90vw;
  max-height: 90vh;
  color: ${COLORS.white};
  position: relative;
  padding: 30px;
  border: 1px solid #ffe406;
  border-radius: 4px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media ${DEVICE.mobile} {
    padding: 20px;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

export const ModalSubTitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  color: rgba(214, 215, 227, 0.8);
  margin-bottom: 0;

  @media ${DEVICE.mobile} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
  color: ${COLORS.white};
  margin: 25px 0 0;

  @media${DEVICE.tablet} {
    font-size: 30px;
    line-height: 32px;
  }

  @media ${DEVICE.mobile} {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const ModalContent = styled.main`
  margin-top: 25px;
  display: flex;
  flex-direction: row-reverse;

  @media ${DEVICE.mobile} {
    display: block;
  }
`;

export const LeftSide = styled.article`
  width: 70%;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: ${COLORS.white};

    @media ${DEVICE.mobile} {
      font-size: 16px;
      line-height: 18px;
    }
  }

  @media ${DEVICE.mobile} {
    width: auto;
  }
`;

export const RightSide = styled.div`
  width: 30%;
  padding: 0 0 20px 20px;
  float: right;

  @media ${DEVICE.mobile} {
    padding: 0 0 10px 10px;
    width: 110px;
  }
`;

export const SecureIcon = styled.img`
  width: 100%;
  object-fit: contain;
  min-width: 100px;
`;
