import React from 'react';

const ProfileIcon = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 42 42'
        preserveAspectRatio='xMaxYMax meet'
      >
        <g>
          <circle cx='22' cy='21' r='18'></circle>
        </g>
        <path
          fill='currentColor'
          fillRule='evenodd'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.4'
          d='M21.66 9.31a5.237 5.237 0 100 10.474 5.237 5.237 0 000-10.473zm-6.113 5.237a6.113 6.113 0 1112.227 0 6.113 6.113 0 01-12.227 0zM15.53 24.345c1.926-1.676 4.822-3.08 8.741-1.873.237.073.363.306.283.52-.08.214-.337.33-.573.256-3.498-1.077-6.054.147-7.825 1.689-1.658 1.443-2.93 3.45-3.647 5.771h12.096c.25 0 .452.184.452.41 0 .227-.203.41-.452.41H11.924a.47.47 0 01-.356-.156.381.381 0 01-.083-.35c.715-2.665 2.126-5.006 4.045-6.677z'
          clipRule='evenodd'
        ></path>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M29.491 21.904h.002l.595.16a1.184 1.184 0 01.866 1.016l.08.734.677.675.739.082.007.001a1.183 1.183 0 01.992.866v.002l.154.582a1.183 1.183 0 01-.438 1.264l-.586.44-.248.923.302.681a1.183 1.183 0 01-.253 1.314l-.425.429a1.183 1.183 0 01-1.293.254l-.006-.003-.68-.298-.925.246-.436.596a1.184 1.184 0 01-1.259.446l-.609-.163h-.001a1.183 1.183 0 01-.867-1.016l-.08-.734-.677-.676-.738-.082h-.007a1.184 1.184 0 01-.993-.867v-.002l-.154-.582a1.183 1.183 0 01.437-1.263l.588-.44.247-.924-.301-.68a1.183 1.183 0 01.252-1.314l.425-.43a1.184 1.184 0 011.293-.253l.007.003.681.298.939-.244.435-.595a1.183 1.183 0 011.258-.446zm.37.997l-.592-.159a.315.315 0 00-.335.12l-.527.72a.434.434 0 01-.241.164l-1.234.32a.433.433 0 01-.283-.022l-.816-.357a.315.315 0 00-.341.068l-.424.428-.004.004a.315.315 0 00-.069.35l.362.817c.04.09.048.192.023.288l-.326 1.216a.434.434 0 01-.159.235l-.709.531-.002.002a.316.316 0 00-.117.338l.002.005.153.582a.316.316 0 00.262.23l.884.099c.098.01.19.054.259.123l.894.892c.07.07.114.162.125.26l.096.887a.315.315 0 00.23.271l.607.163a.316.316 0 00.335-.12l.527-.72a.434.434 0 01.238-.163l1.22-.325a.434.434 0 01.286.022l.815.357a.316.316 0 00.342-.068l.424-.428.004-.003a.316.316 0 00.068-.35v-.001l-.362-.817a.434.434 0 01-.022-.287l.325-1.216a.434.434 0 01.16-.235l.708-.532.002-.002a.315.315 0 00.117-.337l-.001-.006-.153-.582a.316.316 0 00-.262-.23l-.885-.098a.434.434 0 01-.259-.124l-.893-.891a.434.434 0 01-.125-.26l-.096-.888a.317.317 0 00-.231-.271zm-2.41 2.533a1.933 1.933 0 111.932 3.347 1.933 1.933 0 01-1.932-3.347zm1.241.645a1.065 1.065 0 10-.55 2.057 1.065 1.065 0 00.55-2.057z'
          clipRule='evenodd'
        ></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.4'
          d='M30.954 23.08a1.183 1.183 0 00-.867-1.016l-.594-.16h-.002a1.183 1.183 0 00-1.258.446s0 0 0 0l-.435.595-.939.244-.681-.298-.007-.003a1.183 1.183 0 00-1.293.253l-.425.43a1.183 1.183 0 00-.252 1.313l.301.681-.247.923-.588.441a1.183 1.183 0 00-.437 1.263l.154.582v.002a1.184 1.184 0 00.993.866l.007.001.738.082.678.676.08.734s0 0 0 0a1.183 1.183 0 00.866 1.016h.001l.608.163h.001a1.184 1.184 0 001.259-.446s0 0 0 0l.436-.596.925-.246.68.298.006.003a1.183 1.183 0 001.293-.254l.425-.43a1.183 1.183 0 00.253-1.313l-.302-.68.248-.924.586-.44h.001a1.183 1.183 0 00.437-1.264l-.153-.582v-.002a1.182 1.182 0 00-.993-.866h-.008l-.738-.083-.677-.675-.08-.734zm0 0s0 0 0 0zm-1.685-.338l.592.159a.316.316 0 01.23.27l.097.888c.01.099.055.19.125.26l.893.892c.07.07.161.113.259.124l.885.098a.316.316 0 01.261.23s0 0 0 0l.154.582.001.006a.315.315 0 01-.117.337l-.002.002-.709.532a.434.434 0 00-.159.235l-.325 1.216a.434.434 0 00.022.287l.361.817s0 0 0 0a.315.315 0 01-.067.35l-.004.004-.424.428s0 0 0 0a.315.315 0 01-.342.069l-.815-.358a.434.434 0 00-.286-.022l-1.22.325a.434.434 0 00-.238.163l-.527.72h0a.315.315 0 01-.335.12l-.607-.163a.316.316 0 01-.23-.27l-.096-.888a.433.433 0 00-.125-.26l-.894-.892a.434.434 0 00-.259-.123l-.884-.098a.315.315 0 01-.262-.23v-.001l-.153-.582-.002-.005a.316.316 0 01.117-.338l.002-.002.71-.531a.434.434 0 00.158-.235l.326-1.216a.434.434 0 00-.023-.288l-.361-.817a.315.315 0 01.068-.35l.004-.004.424-.428a.316.316 0 01.341-.068l.816.357c.089.04.189.047.283.023l1.234-.321a.434.434 0 00.24-.164l.528-.72a.316.316 0 01.335-.12zm-.352 2.499a1.933 1.933 0 10-1 3.733 1.933 1.933 0 001-3.733zm-1.033.944a1.065 1.065 0 111.065 1.845 1.065 1.065 0 01-1.065-1.845z'
        ></path>
        <defs>
          <filter
            id='filter0_d_3108_434'
            width='44'
            height='44'
            x='0'
            y='0'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1'></feOffset>
            <feGaussianBlur stdDeviation='2'></feGaussianBlur>
            <feComposite operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'></feColorMatrix>
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_3108_434'></feBlend>
            <feBlend in='SourceGraphic' in2='effect1_dropShadow_3108_434' result='shape'></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default ProfileIcon;
