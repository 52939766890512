import { useCallback, useState } from 'react';
import Logo from 'assets/images/logo.png';
import QRCodeStyling from 'qr-code-styling';
import { getQRCodeOptions } from 'utils/qr-code-utils';
import { ESnackbarStyle } from 'shared/types';
import { openNotification } from 'utils/notification-utils';

type Result = {
  downloadQrCode: (format?: 'svg' | 'png') => Promise<void>;
  isQrCodeLoading: boolean;
  createQRCode: () => void;
  qrCodeURL: string;
};

const useQrCodeGenerator = (link: string, logo?: string): Result => {
  const [isQrCodeLoading, setQrCodeIsLoading] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<QRCodeStyling | null>(null);
  const [qrCodeURL, setQrCodeURL] = useState<string>('');

  const createQRCode = useCallback((): void => {
    setQrCodeIsLoading(true);
    const qrCode = new QRCodeStyling(getQRCodeOptions(link, logo || Logo));
    setQrCode(qrCode);
    qrCode._svgDrawingPromise
      ?.then(async (): Promise<void> => {
        if (!!qrCode._svg) {
          const svg = qrCode._svg;
          svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
          const hrefData = svg.getElementsByTagName('image')[0].getAttribute('href');
          if (!!hrefData) {
            svg
              .getElementsByTagName('image')[0]
              .setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', hrefData);
          }
          const svgString = new XMLSerializer().serializeToString(svg);
          const blob = new Blob([svgString], { type: 'image/svg+xml' });
          const blobURL = URL.createObjectURL(blob);
          setQrCodeURL(blobURL);
        }
      })
      ?.catch((): void => {
        openNotification(ESnackbarStyle.ERROR, 'QR code generation failed');
      })
      .finally((): void => {
        setQrCodeIsLoading(false);
      });
  }, [link, logo]);

  const downloadQrCode = useCallback(
    async (format: 'svg' | 'png' = 'png'): Promise<void> => {
      await qrCode?.download({ extension: format, name: 'QR-code' });
    },
    [qrCode]
  );

  return { downloadQrCode, isQrCodeLoading, createQRCode, qrCodeURL };
};

export default useQrCodeGenerator;
