import React, { useState } from 'react';
import * as Styled from './styles';
import Checked from 'assets/images/check-black.svg';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { TeamsState } from 'shared/types';
import { closeModal } from 'services/store/reducers/modalReducer';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useUploadFile } from 'shared/hooks';

type SpaceOption = {
  spaceTitle: string;
  spaceId: number;
};

const ModalUploadToSpace = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTeam, teams } = useAppSelector((store): TeamsState => store.teams);
  const MY_MODELS_SPACE_ID = -1;
  const MY_MODELS_SPACE: SpaceOption = {
    spaceTitle: 'My models',
    spaceId: MY_MODELS_SPACE_ID
  };
  const INITIAL_ACTIVE_SPACE: SpaceOption = !!activeTeam
    ? { spaceTitle: activeTeam.teamName, spaceId: activeTeam.id }
    : MY_MODELS_SPACE;
  const [activeSpace, setActiveSpace] = useState<SpaceOption>(INITIAL_ACTIVE_SPACE);
  const { uploadModelToSpace } = useUploadFile();

  const teamSpaces: SpaceOption[] = teams
    .filter((team): boolean => team.status === 'active')
    .map(
      ({ teamName, id }): SpaceOption => ({
        spaceTitle: teamName,
        spaceId: id
      })
    );

  const SPACES: SpaceOption[] = [MY_MODELS_SPACE, ...teamSpaces];

  const handleSpaceClick =
    (space: SpaceOption): (() => void) =>
    (): void => {
      setActiveSpace(space);
    };

  const handleCancelButtonClick = (): void => {
    dispatch(closeModal());
  };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    const isMyModels = activeSpace.spaceId === MY_MODELS_SPACE_ID;
    isMyModels ? await uploadModelToSpace(files) : await uploadModelToSpace(files, activeSpace);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noDrag: true });

  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalContent>
        <Styled.ModalTitle>Which space would you like to upload this model to?</Styled.ModalTitle>
        <Styled.SpacesList>
          {SPACES.map(
            (space): JSX.Element => (
              <Styled.SpaceField
                isActive={activeSpace.spaceId === space.spaceId}
                onClick={handleSpaceClick(space)}
                key={space.spaceId}
              >
                <span>{space.spaceTitle}</span>
                {activeSpace.spaceId === space.spaceId && <img src={Checked} alt='Checked' />}
              </Styled.SpaceField>
            )
          )}
        </Styled.SpacesList>
        <Styled.ButtonsBlock>
          <Styled.CancelButton onClick={handleCancelButtonClick}>Cancel</Styled.CancelButton>
          <Styled.UploadButton {...getRootProps()}>
            <span>Upload</span>
            <input {...getInputProps()} />
          </Styled.UploadButton>
        </Styled.ButtonsBlock>
      </Styled.ModalContent>
    </Styled.ModalWindowContainer>
  );
};

export default ModalUploadToSpace;
