import styled from 'styled-components';

export const CollapsedModeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 21px 14px;
  backdrop-filter: blur(6px);
`;

export const SidebarItemIcon = styled.img`
  width: 16px;
  height: 16px;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;
