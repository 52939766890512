// @ts-ignore
import { validateBytes } from 'gltf-validator';
import { LoaderUtils } from 'three';
import { openNotification } from './notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { IGLTFValidationResult, IValidateBytes } from 'shared/interfaces';

interface ICustomErrorMessages {
  noGLTF: string;
  noFileMap: string;
  remoteResource: string;
  invalidResource: string;
  badResult: string;
}

export class GltfValidationController {
  private validateBytes: IValidateBytes = validateBytes;
  private _fileMap: Map<string, File> | undefined;
  private rootFile: File | string | undefined;
  private rootFileName: string | undefined;
  private rootPath: string | undefined;
  private majorErrors: string[] = [
    'IO_ERROR',
    'ARRAY_LENGTH_NOT_IN_LIST',
    'ARRAY_TYPE_MISMATCH',
    'INVALID_JSON',
    'INVALID_URI',
    'IMAGE_DATA_INVALID',
    'TEXTURE_INVALID_IMAGE_MIME_TYPE',
    // 'IMAGE_UNRECOGNIZED_FORMAT',
    'BUFFER_MISSING_GLB_DATA'
  ];
  private customErrorMessages: ICustomErrorMessages = {
    noGLTF: 'No .gltf or .glb asset found.',
    noFileMap: 'No fileMap.',
    remoteResource: 'Remote resources are not allowed.',
    invalidResource: 'Model or ZIP contains invalid resources.',
    badResult: 'File or ZIP is not valid'
  };

  public report: IGLTFValidationResult | undefined;

  public set fileMap(fileMap: Map<string, File> | undefined) {
    if (!fileMap) {
      openNotification(ESnackbarStyle.ERROR, this.customErrorMessages.noFileMap);
      throw new Error(this.customErrorMessages.noFileMap);
    }

    this._fileMap = fileMap;

    Array.from(fileMap).forEach(([path, file]): void => {
      if (file.name.toLowerCase().match(/\.(gltf|glb)$/)) {
        this.rootFile = file;
        this.rootFileName = file.name;

        const clearFileName = file.name.split('/').pop();

        this.rootPath = path.replace(clearFileName!, '');
      }
    });
  }

  public get fileMap(): Map<string, File> | undefined {
    return this._fileMap;
  }

  public getFileUrl(file: File | string): string {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  }

  public async getFileBytes(file: string | File): Promise<Uint8Array> {
    if (typeof file === 'string') {
      const response = await fetch(file);
      const responseBuffer = await response.arrayBuffer();

      return new Uint8Array(responseBuffer);
    }

    const responseBuffer = await file.arrayBuffer();

    return new Uint8Array(responseBuffer);
  }

  public async handleValidate(): Promise<IGLTFValidationResult> {
    if (!this.fileMap) {
      openNotification(ESnackbarStyle.ERROR, this.customErrorMessages.noFileMap);
      throw new Error(this.customErrorMessages.noFileMap);
    }

    if (!this.rootFile || this.rootPath === undefined || !this.rootFileName) {
      openNotification(ESnackbarStyle.ERROR, this.customErrorMessages.noGLTF);
      throw new Error(this.customErrorMessages.noGLTF);
    }

    const rootFileURL = this.getFileUrl(this.rootFile);
    const fileBytes = await this.getFileBytes(this.rootFile);

    this.report = await this.validateBytes(fileBytes, {
      uri: this.rootFileName,
      ignoredIssues: ['UNSUPPORTED_EXTENSION'],
      externalResourceFunction: (uri: string): Promise<Uint8Array> =>
        this.resolveExternalResourcesFunction(uri, rootFileURL, this.rootPath!, this.fileMap!)
    });

    return this.report;
  }

  private resolveExternalResourcesFunction(
    uri: string,
    rootFileUrl: string,
    rootPath: string,
    assetMap: Map<string, File>
  ): Promise<Uint8Array> {
    if (uri.startsWith('http')) {
      openNotification(ESnackbarStyle.ERROR, this.customErrorMessages.remoteResource);
      throw new Error(this.customErrorMessages.remoteResource);
    }

    let objectUrl: string | undefined;
    const baseURL = LoaderUtils.extractUrlBase(rootFileUrl);
    const normalizedURL =
      rootPath +
      decodeURI(uri)
        .replace(baseURL, '')
        .replace(/^(\.?\/)/, '');

    if (assetMap.has(normalizedURL)) {
      const object = assetMap.get(normalizedURL);

      if (object) {
        objectUrl = URL.createObjectURL(object);
      }
    }
    return fetch(objectUrl || baseURL + uri)
      .then((response): Promise<ArrayBuffer> => response.arrayBuffer())
      .then(
        (buffer): Uint8Array => {
          if (objectUrl) URL.revokeObjectURL(objectUrl);

          return new Uint8Array(buffer);
        },
        (err): any => {
          openNotification(ESnackbarStyle.ERROR, this.customErrorMessages.invalidResource);
          throw new Error(err);
        }
      );
  }

  public checkReport(report: IGLTFValidationResult): boolean {
    const errors = report.issues.messages.filter((error): boolean =>
      this.majorErrors.includes(error.code)
    );

    if (errors.length) {
      errors.forEach((error): void => {
        openNotification(ESnackbarStyle.ERROR, error.message);
      });

      return false;
    }

    return true;
  }

  public async fullValidate(fileMap: Map<string, File> | undefined): Promise<boolean> {
    this.fileMap = fileMap;

    const report = await this.handleValidate();

    return this.checkReport(report);
  }
}
