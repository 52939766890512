import React from 'react';
import * as Styled from './styles';
import Logo from 'assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import { clearViewerDataState } from 'services/store/reducers/viewerDataReducer';
import { useAppDispatch, useAppSelector, useBrowserStore } from 'shared/hooks';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { BrandingState } from 'shared/types';

const ProjectLogo = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { siteTitle, siteSubtitle, brandLogoUrl } = useAppSelector(
    (state): BrandingState => state.branding
  ).activeBranding;
  const { removeFilesFromBrowserStore } = useBrowserStore();
  const DEFAULT_SITE_TITLE = 'glbee';
  const DEFAULT_SITE_SUBTITLE = 'instant 3D model sharing';

  const handleLogoClick = (): void => {
    dispatch(clearViewerDataState());
    removeFilesFromBrowserStore();
    clearModelLocalStorage();
    history.push('/');
  };

  return (
    <Styled.LogoContainer onClick={handleLogoClick}>
      <Styled.LogoWrapper>
        <Styled.CompanyLogo
          src={brandLogoUrl || Logo}
          alt='glbee Free Online GLTF Viewer'
          id='app-logo-button'
        />
      </Styled.LogoWrapper>
      <Styled.CompanyTitle>
        <h1 className='title'>{siteTitle || DEFAULT_SITE_TITLE}</h1>
        <p className='subtitle'>{siteSubtitle || DEFAULT_SITE_SUBTITLE}</p>
      </Styled.CompanyTitle>
    </Styled.LogoContainer>
  );
};

export default ProjectLogo;
