import { EUserRole, User } from '../types';

enum EAuthor {
  anonymous = 'anonymous',
  JamesGlbee = 'JamesGlbee',
  HaroonGlbee = 'HaroonGlbee',
  JoGlbee = 'JoGlbee',
  JavierGlbee = 'JavierGlbee',
  PaulGlbee = 'PaulGlbee',
  JesseGlbee = 'JesseGlbee',
  GabrielaGlbee = 'GabrielaGlbee',
  AdrianGlbee = 'AdrianGlbee',
  LukeGlbee = 'LukeGlbee'
}

interface IAuthor extends User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: EUserRole;
}

export const sampleCommentsAuthors: Record<EAuthor, IAuthor> = {
  [EAuthor.anonymous]: {
    id: -1,
    email: 'anonymous@email.com',
    firstName: 'Anonymous',
    lastName: 'Anonymous',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.JamesGlbee]: {
    id: 1,
    email: 'james.glbee@glb.ee',
    firstName: 'James',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.HaroonGlbee]: {
    id: 2,
    email: 'haroon.glbee@glb.ee',
    firstName: 'Haroon',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.JoGlbee]: {
    id: 3,
    email: 'jo.glbee@glb.ee',
    firstName: 'Jo',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.JavierGlbee]: {
    id: 4,
    email: 'javier.glbee@glb.ee',
    firstName: 'Javier',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.PaulGlbee]: {
    id: 5,
    email: 'paul.glbee@glb.ee',
    firstName: 'Paul',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.JesseGlbee]: {
    id: 6,
    email: 'jesse.glbee@glb.ee',
    firstName: 'Jesse',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.GabrielaGlbee]: {
    id: 7,
    email: 'gabriela.glbee@glb.ee',
    firstName: 'Gabriela',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.AdrianGlbee]: {
    id: 8,
    email: 'adrian.glbee@glb.ee',
    firstName: 'Adrian',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  },
  [EAuthor.LukeGlbee]: {
    id: 9,
    email: 'luke.glbee@glb.ee',
    firstName: 'Luke',
    lastName: 'Glbee',
    role: EUserRole.VIEWER,
    lastActiveAt: null,
    createdAt: '',
    updatedAt: ''
  }
};
