import {
  MODEL_PAGE_PATHNAME,
  MODEL_PAGE_PATHNAME_NUMBERS,
  URL_PATTERN
} from 'shared/constants/regexps';
import { ROUTES } from 'routes/main-routes/routes';
import { Location } from 'history';
import { SampleModel } from 'shared/types';

export const checkIsIFrame = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const checkIsValidURL = (url: string): boolean => URL_PATTERN.test(url);

export const checkIsModelPathname = (location: Location): boolean => {
  const urlParams = new URLSearchParams(location.search);
  const sampleModelName = urlParams.get('sample-model');
  const isSampleModelPage = Object.values(SampleModel).some(
    (name): boolean => name === sampleModelName
  );

  return (
    isSampleModelPage ||
    (MODEL_PAGE_PATHNAME.test(location.pathname) &&
      MODEL_PAGE_PATHNAME_NUMBERS.test(location.pathname) &&
      ROUTES.every((route): boolean =>
        Array.isArray(route.path)
          ? route.path.every((r): boolean => r !== location.pathname)
          : route.path !== location.pathname
      ))
  );
};
