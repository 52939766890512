import * as THREE from 'three';
import {
  IViewerCommentFromServer
} from 'shared/interfaces';
import { convertDataURLtoFile } from './convert-file-utils';
import { addUserMention, getPeopleForMention } from 'services/api/notesService';
import { AxisValues, EModelAccessLevel, ESnackbarStyle, Model, User } from 'shared/types';
import { openNotification } from './notification-utils';
import { getModelPermissions } from 'services/api/permissionsService';
import {
  DEFAULT_MENTION_FORMAT_REGEXP,
  SIMPLIFIED_MENTION_FORMAT_REGEXP
} from 'shared/constants/regexps';
import { SimpleUser } from 'shared/types/user';
import { checkByEmailIfUserIsTeamMember } from './teams-utils';

interface GetUserListData {
  modelId: string;
  currentUser: SimpleUser | null;
  mentionRequestText: string;
}

// region Sync mentions
export const syncMention = async (
  email: string,
  screenshot: string,
  comment: IViewerCommentFromServer
): Promise<void> => {
  const file = convertDataURLtoFile(screenshot, 'screenshot.jpeg');
  const message = transformMentionFromDefaultFormat(
    comment.message,
    (input: string): string => input
  );
  const formData = new FormData();

  formData.append('file', file);
  formData.append('email', email);
  formData.append('commentId', comment.id + '');
  formData.append('commentMessage', message);

  await addUserMention(comment.modelId!, formData);
};

export const syncMentionsFromMessage = async (
  comment: IViewerCommentFromServer,
  pattern: RegExp,
  screenshot: string,
  isPublishedModel: boolean,
  isSampleModel: boolean,
  model: Pick<Model, 'id' | 'accessLevel' | 'owner' | 'teamId'>
): Promise<void> => {
  const matching = Array.from(comment.message.matchAll(new RegExp(pattern))) as unknown as string[];
  const emails = matching.reduce((accum, current): string[] => {
    const email = current[0].slice(2, -1);

    if (!accum.includes(email)) {
      accum.push(email);
    }

    return accum;
  }, [] as string[]);
  const throwWarn = ({
    email,
    isPrivate,
    isSampleModel
  }: {
    email: string;
    isPrivate?: boolean;
    isSampleModel?: boolean;
  }): void => {
    switch (true) {
      case isSampleModel:
        openNotification(
          ESnackbarStyle.HOLD_UP,
          `The user ${email} you mention will not receive a notification email, because it is the sample model.`
        );
        break;

      case isPrivate:
        openNotification(
          ESnackbarStyle.HOLD_UP,
          `The user ${email} you mention will not receive a notification email, because the model is not published.`
        );
        break;

      default:
        openNotification(
          ESnackbarStyle.HOLD_UP,
          `The user ${email} you mention will not receive a notification email, because it doesn't have the correct permissions to view this model.`
        );
        break;
    }
  };

  for (const email of emails) {
    const isModelOwnerEmail = model.owner.email === email;
    const isTeamMemberEmail =
      model.teamId && (await checkByEmailIfUserIsTeamMember(model.teamId, email));
    const existingCommentMentionEmail = comment.mentions?.find(
      (mention): boolean => mention.person.email === email
    );

    if (existingCommentMentionEmail) return;

    switch (true) {
      case (isModelOwnerEmail || isTeamMemberEmail) && !isSampleModel:
        break;

      case !isPublishedModel || isSampleModel:
        throwWarn({ email, isPrivate: !isPublishedModel, isSampleModel });
        break;

      case isPublishedModel:
        const modelPermissions = (await getModelPermissions(model.id)).data;
        const publicAccess = modelPermissions.publicAccessLevel.includes(
          EModelAccessLevel.VIEW_ACCESS
        );
        const userPermissions = modelPermissions.accessRules.items.find(
          (permission): boolean =>
            permission.invite?.userEmail === email || permission.user?.email === email
        );
        const userAccess =
          userPermissions &&
          (userPermissions.accessLevel.includes(EModelAccessLevel.VIEW_ACCESS) ||
            userPermissions.accessLevel.includes(EModelAccessLevel.COMMENT_ACCESS));

        if ((!publicAccess && !userAccess) || (publicAccess && userPermissions && !userAccess)) {
          throwWarn({ email });
        }

        break;

      default:
        break;
    }

    if (!isSampleModel) {
      await syncMention(email, screenshot, comment);
    }
  }
};
// endregion

// region get users list

export const getUsersList = async ({
  modelId,
  currentUser,
  mentionRequestText
}: GetUserListData): Promise<SimpleUser[] | undefined> => {
  try {
    const usersList = (await getPeopleForMention(modelId, mentionRequestText)).data;
    const startsAsCurrentUser =
      currentUser &&
      (currentUser.email.startsWith(mentionRequestText) ||
        currentUser.lastName.toLowerCase().startsWith(mentionRequestText) ||
        currentUser.firstName.toLowerCase().startsWith(mentionRequestText));

    if (usersList && currentUser && startsAsCurrentUser) {
      const currentUserExistsInList =
        0 <= usersList.findIndex((item): boolean => item.email === currentUser.email);

      if (!currentUserExistsInList && currentUser) {
        usersList.push({ ...currentUser });
      }
    }
    return usersList;
  } catch (e) {
    console.error(e);
  }
};
// endregion

// region transform mention
export const formatToSimple = (input: string): string => `${input}`;

export const formatToDefault = (input: string): string => `<${input}>`;

export const formatToHTMLLayout = (input: string): string => {
  return `<span class="mention">${input}</span>`;
};

export const transformMentionFromSimplifiedFormat = (
  text: string,
  formatter: (input: string) => string
): string => {
  return text.replaceAll(SIMPLIFIED_MENTION_FORMAT_REGEXP, (word): string => formatter(word));
};

export const transformMentionFromDefaultFormat = (
  text: string,
  formatter: (input: string) => string,
  comment?: {
    mentions?: IViewerCommentFromServer['mentions'];
  }
): string => {
  if (comment && comment.mentions && comment.mentions.length) {
    return text.replaceAll(DEFAULT_MENTION_FORMAT_REGEXP, (word): string => {
      const mentionEntry = comment.mentions?.find(
        (mention): boolean => mention.person?.email === word.slice(2, -1)
      );

      if (mentionEntry && mentionEntry.seenComment) {
        return formatter(`@${mentionEntry.person.firstName} ${mentionEntry.person.lastName}`);
      } else {
        return formatter(word.slice(1, -1));
      }
    });
  } else {
    return text.replaceAll(DEFAULT_MENTION_FORMAT_REGEXP, (word): string =>
      formatter(word.slice(1, -1))
    );
  }
};
// endregion

// region templates

interface IHandleNewCommentProps {
  user: User | null;
  isSampleModel: boolean;
  objectIndex: number;
  path: string;
  face: THREE.Face | null | undefined;
  localPosition: THREE.Vector3;
  cameraPosition: THREE.Vector3;
  cameraRotation: THREE.Euler;
  orbitControlsTarget: THREE.Vector3;
  scale: AxisValues;
}

type IHandleNewComment = (props?: IHandleNewCommentProps) => IViewerCommentFromServer;

export const handleNewComment: IHandleNewComment = (
  {
    user,
    isSampleModel,
    objectIndex,
    path,
    face,
    localPosition,
    cameraPosition,
    cameraRotation,
    orbitControlsTarget,
    scale
  } = {
    user: null,
    isSampleModel: false,
    objectIndex: 0,
    path: '',
    face: undefined,
    localPosition: new THREE.Vector3(),
    cameraPosition: new THREE.Vector3(),
    cameraRotation: new THREE.Euler(),
    orbitControlsTarget: new THREE.Vector3(),
    scale: new THREE.Vector3()
  }
): IViewerCommentFromServer => {
  return {
    author: user || undefined,
    authorId: user?.id,
    commentNum: 0,
    commentsCount: 0,
    createdAt: '',
    updatedAt: '',
    id: isSampleModel ? Math.floor(Math.random() * 1000) : 0,
    index: 1,
    threadId: null,
    title: '',
    message: '',
    comments: [],
    params: {
      parent_id: objectIndex,
      path,
      face,
      position: {
        x: localPosition.x,
        y: localPosition.y,
        z: localPosition.z
      },
      camera: {
        position: {
          x: cameraPosition.x / scale.x,
          y: cameraPosition.y / scale.y,
          z: cameraPosition.z / scale.z
        },
        rotation: {
          x: cameraRotation.x,
          y: cameraRotation.y,
          z: cameraRotation.z
        },
        target: {
          x: orbitControlsTarget.x / scale.x,
          y: orbitControlsTarget.y / scale.y,
          z: orbitControlsTarget.z / scale.z
        }
      }
    }
  };
};

interface IHandleCommentReplyProps {
  commentThread: IViewerCommentFromServer;
  user: User | null;
}

type IHandleCommentReply = (props: IHandleCommentReplyProps) => IViewerCommentFromServer;

export const handleCommentReply: IHandleCommentReply = ({
  commentThread,
  user
}): IViewerCommentFromServer => {
  return {
    author: user || undefined,
    authorId: user?.id,
    commentNum: 0,
    commentsCount: 0,
    createdAt: '',
    updatedAt: '',
    id: Date.now(),
    index: 1,
    threadId: commentThread.id,
    title: '',
    message: '',
    params: JSON.parse(JSON.stringify(commentThread.params))
  };
};
// endregion

export const getReplyFromThread = (
  thread: IViewerCommentFromServer,
  activeCommentId: number
): IViewerCommentFromServer | undefined => {
  return thread.comments && thread.comments.find((reply): boolean => reply.id === activeCommentId);
};
