export const SIDEBAR_MODEL_ELEMENT_ID = 'sidebar-model'
export const FOOTER_ELEMENT_ID = 'footer';
export const VIEWER_FOOTER_ELEMENT_ID = 'model-viewer-footer';

export const THREE_STATS_CONTAINER_ID = 'ThreeJSStatsContainer';
export const MAIN_SCENE_CANVAS_ID = '3DViewerCanvas';
export const EMBEDDING_MODAL_MAIN_SCENE_CANVAS_ID = 'embeddingModal3DViewerCanvas';
export const CUSTOMIZATION_MAIN_SCENE_CANVAS_ID = 'customization3DViewerCanvas';
export const MAIN_SCENE_CSS2D_CONTAINER_ID = '3DViewerCSS2DContainer';
export const PREVIEW_MAIN_SCENE_CSS2D_CONTAINER_ID = 'previewCSS2DContainer';
export const MAIN_SCENE_CSS3D_CONTAINER_ID = '3DViewerCSS3DContainer';
export const AR_SCENE_CANVAS_ID = 'ARSceneCanvas';
export const CUBE_ORBIT_CONTROLLER_CANVAS_ID = 'COCSCanvas';
export const EMBEDDING_MODAL_CUBE_ORBIT_CONTROLLER_CANVAS_ID =
  'embeddingModalCubeOrbitControllerCanvas';
export const CUBE_ORBIT_CONTROLLER_CSS3D_CONTAINER_ID = 'COCSCSS3DContainer';
export const CUBE_ORBIT_CONTROLLER_CUBE_FACE_ID = 'COCSCubeFace';
export const DIMENSIONS_CUBE_FACE_X_ID = 'dimensionsCubeFaceX';
export const DIMENSIONS_CUBE_FACE_Y_ID = 'dimensionsCubeFaceY';
export const DIMENSIONS_CUBE_FACE_Z_ID = 'dimensionsCubeFaceZ';

