import React, { useEffect } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { clearCustomizationData } from 'services/store/reducers/customizationReducer';
import { Customization } from 'shared/components';
import { EFeatureId, TeamsState } from 'shared/types';

const CustomizationTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { teamPlan } = useAppSelector((store): TeamsState => store.teams);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(clearCustomizationData());
    },
    [dispatch]
  );

  const isEnvSettingsAvailable = !!teamPlan?.metadata.product_features.find(
    (item): boolean => item.id === EFeatureId.CUSTOM_3D_ENVIRONMENT
  )?.enabled;

  return (
    <Styled.PageContainer>
      <Styled.PageContent>
        <Customization isEnvSettingsAvailable={isEnvSettingsAvailable} />
      </Styled.PageContent>
    </Styled.PageContainer>
  );
};

export default CustomizationTab;
