import { EKeyBindingsKeys } from '../enums/EKeyBindingsKeys';
import { KeyBindingsMap } from '../types/key-binding';

export const DEFAULT_KEY_BINDINGS_MAP: KeyBindingsMap = {
  [EKeyBindingsKeys.forward]: {
    primaryKey: 'KeyW',
    secondaryKey: 'ArrowUp'
  },
  [EKeyBindingsKeys.back]: {
    primaryKey: 'KeyS',
    secondaryKey: 'ArrowDown'
  },
  [EKeyBindingsKeys.left]: {
    primaryKey: 'KeyA',
    secondaryKey: 'ArrowLeft'
  },
  [EKeyBindingsKeys.right]: {
    primaryKey: 'KeyD',
    secondaryKey: 'ArrowRight'
  },
  [EKeyBindingsKeys.up]: {
    primaryKey: 'KeyE',
    secondaryKey: 'PageUp'
  },
  [EKeyBindingsKeys.down]: {
    primaryKey: 'KeyQ',
    secondaryKey: 'PageDown'
  },
  [EKeyBindingsKeys.fastSpeed]: {
    primaryKey: 'ShiftLeft',
    secondaryKey: 'ShiftRight'
  },
  [EKeyBindingsKeys.speedControl]: {
    primaryKey: 'ControlLeft',
    secondaryKey: 'ControlRight'
  },
  [EKeyBindingsKeys.increaseFov]: {
    primaryKey: 'Equal',
    secondaryKey: ''
  },
  [EKeyBindingsKeys.decreaseFov]: {
    primaryKey: 'Minus',
    secondaryKey: ''
  },
  [EKeyBindingsKeys.pan]: {
    primaryKey: '2',
    secondaryKey: ''
  },
  [EKeyBindingsKeys.quickZoom]: {
    primaryKey: '1',
    secondaryKey: ''
  },
  [EKeyBindingsKeys.rotate]: {
    primaryKey: '0',
    secondaryKey: ''
  }
};
