import React from 'react';

const CameraIcon: React.FC = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: '100%', height: '100%' }}
      viewBox='0 0 22 22'
      preserveAspectRatio='xMidYMax meet'
      fill='currentColor'
    >
      <path
        d='M12 16.7308C13.0449 16.7308 13.9279 16.3702 14.649 15.6491C15.3702 14.9279 15.7308 14.0449 15.7308 13C15.7308 11.9551 15.3702 11.0721 14.649 10.351C13.9279 9.6298 13.0449 9.26922 12 9.26922C10.9551 9.26922 10.0721 9.6298 9.35095 10.351C8.6298 11.0721 8.26923 11.9551 8.26923 13C8.26923 14.0449 8.6298 14.9279 9.35095 15.6491C10.0721 16.3702 10.9551 16.7308 12 16.7308ZM12 15.7308C11.2231 15.7308 10.5737 15.4699 10.0519 14.9481C9.53013 14.4263 9.26923 13.7769 9.26923 13C9.26923 12.2231 9.53013 11.5737 10.0519 11.0519C10.5737 10.5301 11.2231 10.2692 12 10.2692C12.7769 10.2692 13.4263 10.5301 13.9481 11.0519C14.4699 11.5737 14.7308 12.2231 14.7308 13C14.7308 13.7769 14.4699 14.4263 13.9481 14.9481C13.4263 15.4699 12.7769 15.7308 12 15.7308ZM4.61538 20C4.15513 20 3.77083 19.8458 3.4625 19.5375C3.15417 19.2292 3 18.8449 3 18.3846V7.61537C3 7.15512 3.15417 6.77083 3.4625 6.4625C3.77083 6.15417 4.15513 6 4.61538 6H7.57308L9.42308 4H14.5769L16.4269 6H19.3846C19.8449 6 20.2292 6.15417 20.5375 6.4625C20.8458 6.77083 21 7.15512 21 7.61537V18.3846C21 18.8449 20.8458 19.2292 20.5375 19.5375C20.2292 19.8458 19.8449 20 19.3846 20H4.61538ZM19.3846 19C19.5641 19 19.7115 18.9423 19.8269 18.8269C19.9423 18.7115 20 18.5641 20 18.3846V7.61537C20 7.43589 19.9423 7.28846 19.8269 7.17307C19.7115 7.05769 19.5641 7 19.3846 7H15.9885L14.1442 5H9.85577L8.01155 7H4.61538C4.43589 7 4.28846 7.05769 4.17308 7.17307C4.05769 7.28846 4 7.43589 4 7.61537V18.3846C4 18.5641 4.05769 18.7115 4.17308 18.8269C4.28846 18.9423 4.43589 19 4.61538 19H19.3846Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default CameraIcon;
