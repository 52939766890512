import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  width: 90vw;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  padding: 25px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  color: ${COLORS.mischka};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0 10px;
`;

export const ModalTitle = styled.h1`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${COLORS.mischka};
`;

export const SpacesList = styled.section`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
`;

export const SpaceField = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  min-height: 36px;
  width: 100%;
  padding: 0 10px;
  background: ${({ isActive }): string => (isActive ? COLORS.turbo : '#090909')};
  transition: all 0.15s;

  &:nth-child(odd) {
    background: ${({ isActive }): string => (isActive ? COLORS.turbo : 'rgba(71, 71, 71, 0.59)')};
  }

  span {
    color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.mischka)};
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonsBlock = styled.div`
  margin-top: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const UploadButton = styled.button`
  background: ${COLORS.turbo};
  border: none;
  border-radius: 4px;
  color: ${COLORS.black};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 26px;
  transition: all 0.2s;

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
