import TWEEN, { Easing, Tween } from '@tweenjs/tween.js';
import * as THREE from 'three';
import { ELightPresets } from 'shared/enums/ELightsPresets';
import { LightControllerData, LightWithInnerState } from 'shared/interfaces';
import { debounce } from 'utils/delay-utils';
import { AxisValues } from 'shared/types';
import { INITIAL_MODEL_SETTINGS } from 'shared/constants/model-settings';
import {
  BASIC_LIGHT_INTENSITY_RATIO,
  HEMISPHERE_LIGHT_INTENSITY_RATIO,
  LIGHT_POSITION_RATIO,
  POINT_LIGHT_INTENSITY_RATIO
} from 'shared/constants/scene-settings-constants';
import { BaseScene, MainScene } from 'shared/webgl/scenes';

const tweenLightControllerStore = {
  tween: [] as any,
  animations: {
    rotation: {
      x: 0,
      y: 0,
      z: 0
    },
    targetRotation: { x: 0, y: -360, z: 0 }
  },
  intensity: {
    vector: 0,
    value: 0
  }
};

export class LightController {
  public objectWrapper?: THREE.Object3D;

  private lightWrapper?: THREE.Object3D;
  private shadowStateChanged: boolean = false;
  private castShadow: boolean = false;
  private _lightPreset: ELightPresets | undefined = undefined;
  private scale: AxisValues = INITIAL_MODEL_SETTINGS.scale;
  private tweenDuration: number = 1000;
  private basicLightIntensityRatio: number = BASIC_LIGHT_INTENSITY_RATIO;
  private pointLightIntensityRatio: number = POINT_LIGHT_INTENSITY_RATIO;
  private hemisphereLightIntensityRatio: number = HEMISPHERE_LIGHT_INTENSITY_RATIO;
  private rotationTarget: THREE.Object3D | undefined;

  public setUp(lightWrapper: THREE.Object3D, objectWrapper: THREE.Object3D): void {
    this.lightWrapper = lightWrapper;
    this.objectWrapper = objectWrapper;

    this.updateLightScaleRatio();

    window.requestAnimationFrame((): void => {
      if (this._lightPreset)
        this.updateByOptions({
          lightPreset: this._lightPreset,
        });
    });
  }

  public updateLightScaleRatio(): void {
    if (!this.lightWrapper || !this.objectWrapper || !this.objectWrapper.children[0]) return;

    const scale = Math.max(this.scale.x, this.scale.y, this.scale.z);

    this.lightWrapper.scale.set(this.scale.x, this.scale.y, this.scale.z);
    this.pointLightIntensityRatio = POINT_LIGHT_INTENSITY_RATIO * Math.pow(scale, 2.2);
  }

  constructor(mainScene: MainScene, arScene?: BaseScene) {
    if (tweenLightControllerStore.tween.length === 0) {
      tweenLightControllerStore.tween[0] = new TWEEN.Tween(
        tweenLightControllerStore.animations.rotation
      )
        .to(tweenLightControllerStore.animations.targetRotation)
        .duration(500000)
        .easing(Easing.Linear.None)
        .repeat(Infinity);
    }

    tweenLightControllerStore.tween[0].start();

    mainScene.renderCallbackStack.push(TWEEN.update);
    arScene?.renderCallbackStack?.push(TWEEN.update);
  }

  public updateByOptions(options: LightControllerData, force?: boolean): void {
    if (options.castShadow !== undefined) {
      this.shadowStateChanged = false;
      this.castShadow = options.castShadow;
    }

    this.updateLightScaleRatio();
    this.setLightPreset(options.lightPreset, force);
    this.enableShadows(this.castShadow);
  }

  public refreshLight: (scale: AxisValues) => void = debounce((scale: AxisValues): void => {
    if (this._lightPreset === undefined) return;

    this.scale = scale;

    this.updateLightScaleRatio();
    this.setLightPreset(this._lightPreset, true);
  }, 100);

  public rotateRimLight(angle: number): void {
    if (!this.rotationTarget) return;

    this.rotationTarget.rotation.y = THREE.MathUtils.degToRad(angle);
  }

  private setUpLight(preset: ELightPresets, force?: boolean): void {
    if (!this.lightWrapper) return;

    this.lightWrapper.clear();
    this.rotationTarget = undefined;
    this.lightWrapper.add(this.lightMap[preset]());

    this.lightWrapper.traverse((light): void => {
      if (light instanceof THREE.Light && this.lightWrapper) {
        if (this.lightWithInnerState(light)._.toHide === false) {
          new Tween(light)
            .to(this.lightWithInnerState(light)._, force ? 0 : this.tweenDuration)
            .easing(Easing.Cubic.InOut)
            .start();
        }
      }
    });

    this.enableShadows(this.castShadow);
  }

  private enableShadows(value: boolean): void {
    if (!this.lightWrapper) return;

    this.shadowStateChanged = true;

    this.lightWrapper.traverse((light): void => {
      if (light instanceof THREE.Light) {
        light.castShadow = false;

        if (
          !this.lightWithInnerState(light)._.toHide &&
          value &&
          (light instanceof THREE.DirectionalLight ||
            light instanceof THREE.PointLight ||
            light instanceof THREE.SpotLight)
        ) {
          light.castShadow = value;
          light.shadow.mapSize.width = 2048;
          light.shadow.mapSize.height = 2048;
          light.shadow.camera.near = 0.5;
          light.shadow.camera.far = 500;

          if (light instanceof THREE.SpotLight) {
            light.shadow.focus = 1;
          }
        }
      }
    });
  }

  private setLightPreset(preset: ELightPresets, force?: boolean): void {
    if (this._lightPreset === preset && !force) return;

    this._lightPreset = preset;

    if (!this.lightWrapper) return;

    window.requestAnimationFrame((): void => {
      this.setUpLight(preset, force);
    });
  }

  private get lightWithInnerState(): (light: THREE.Light) => LightWithInnerState {
    return (light: THREE.Light): LightWithInnerState => {
      if ((light as LightWithInnerState)._ === undefined) {
        (light as LightWithInnerState)._ = {
          intensity: undefined,
          toHide: undefined
        };
      }

      if ((light as LightWithInnerState)._.intensity === undefined) {
        light.intensity = 0;
        (light as LightWithInnerState)._.intensity = 0.01 * this.basicLightIntensityRatio;
      }

      if ((light as LightWithInnerState)._.toHide === undefined) {
        light.intensity = 0;
        (light as LightWithInnerState)._.toHide = false;
      }

      return light as LightWithInnerState;
    };
  }

  private lightMap: Record<ELightPresets, () => THREE.Object3D> = {
    [ELightPresets.ModelViewer]: (): THREE.Object3D => {
      const lightWrapper = new THREE.Object3D();
      lightWrapper.name = 'ModelViewer';

      const LIGHT_1 = new THREE.DirectionalLight();
      this.lightWithInnerState(LIGHT_1)._.intensity = this.basicLightIntensityRatio;
      LIGHT_1.position.set(
        LIGHT_POSITION_RATIO * 1.9,
        LIGHT_POSITION_RATIO * 1.9,
        LIGHT_POSITION_RATIO * 1.9
      );
      LIGHT_1.lookAt(new THREE.Vector3());
      lightWrapper.add(LIGHT_1);
      LIGHT_1.name = 'LIGHT_1';

      const LIGHT_2 = new THREE.DirectionalLight();
      this.lightWithInnerState(LIGHT_2)._.intensity = this.basicLightIntensityRatio;
      LIGHT_2.position.set(
        -LIGHT_POSITION_RATIO * 1.9,
        LIGHT_POSITION_RATIO * 1.9,
        LIGHT_POSITION_RATIO * 1.9
      );
      LIGHT_2.lookAt(new THREE.Vector3());
      lightWrapper.add(LIGHT_2);
      LIGHT_2.name = 'LIGHT_2';

      const LIGHT_3 = new THREE.DirectionalLight();
      this.lightWithInnerState(LIGHT_3)._.intensity = this.basicLightIntensityRatio;
      LIGHT_3.position.set(
        LIGHT_POSITION_RATIO * 1.9,
        LIGHT_POSITION_RATIO * 1.9,
        -LIGHT_POSITION_RATIO * 1.9
      );
      LIGHT_3.lookAt(new THREE.Vector3());
      lightWrapper.add(LIGHT_3);
      LIGHT_3.name = 'LIGHT_3';

      const LIGHT_4 = new THREE.DirectionalLight();
      this.lightWithInnerState(LIGHT_4)._.intensity = this.basicLightIntensityRatio;
      LIGHT_4.position.set(
        -LIGHT_POSITION_RATIO * 1.9,
        LIGHT_POSITION_RATIO * 1.9,
        -LIGHT_POSITION_RATIO * 1.9
      );
      LIGHT_4.lookAt(new THREE.Vector3());
      lightWrapper.add(LIGHT_4);
      LIGHT_4.name = 'LIGHT_4';

      const LIGHT_11 = new THREE.AmbientLight();
      this.lightWithInnerState(LIGHT_11)._.intensity = this.basicLightIntensityRatio * 0.6;
      LIGHT_11.lookAt(new THREE.Vector3());
      lightWrapper.add(LIGHT_11);
      LIGHT_11.name = 'LIGHT_11';

      const LIGHT_12 = new THREE.HemisphereLight();
      this.lightWithInnerState(LIGHT_12)._.intensity = this.hemisphereLightIntensityRatio * 0.025;
      LIGHT_12.position.set(0, LIGHT_POSITION_RATIO * 5, 0);
      LIGHT_12.lookAt(new THREE.Vector3());
      lightWrapper.add(LIGHT_12);
      LIGHT_12.name = 'LIGHT_12';

      return lightWrapper;
    },
    [ELightPresets.RimLight]: (): THREE.Object3D => {
      const lightWrapper = new THREE.Object3D();
      lightWrapper.name = 'RimLight';

      const mainInnerWrapper = new THREE.Object3D();
      const light1InnerWrapper = new THREE.Object3D();
      const light2InnerWrapper = new THREE.Object3D();
      const light3InnerWrapper = new THREE.Object3D();

      light1InnerWrapper.rotation.y = THREE.MathUtils.degToRad(0);
      light2InnerWrapper.rotation.y = THREE.MathUtils.degToRad(135);
      light3InnerWrapper.rotation.y = THREE.MathUtils.degToRad(225);
      mainInnerWrapper.add(light1InnerWrapper, light2InnerWrapper, light3InnerWrapper);
      lightWrapper.add(mainInnerWrapper);
      this.rotationTarget = mainInnerWrapper;

      const LIGHT_1 = new THREE.AmbientLight();

      LIGHT_1.name = 'LIGHT_1';
      LIGHT_1.position.set(
        -2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        -3 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_1)._.intensity = this.basicLightIntensityRatio * 3;

      light1InnerWrapper.add(LIGHT_1);

      const LIGHT_2 = new THREE.DirectionalLight();

      LIGHT_2.name = 'LIGHT_2';
      LIGHT_2.position.set(
        -2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        -3 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_2)._.intensity = this.basicLightIntensityRatio * 5;

      light2InnerWrapper.add(LIGHT_2);

      const LIGHT_3 = new THREE.DirectionalLight();

      LIGHT_3.name = 'LIGHT_1';
      LIGHT_3.position.set(
        -2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        -3 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_3)._.intensity = this.basicLightIntensityRatio * 5;

      light3InnerWrapper.add(LIGHT_3);

      return lightWrapper;
    },
    [ELightPresets.Dramatic]: (): THREE.Object3D => {
      const lightWrapper = new THREE.Object3D();
      lightWrapper.name = 'Dramatic';

      const LIGHT_1 = new THREE.DirectionalLight(new THREE.Color(0xffb54d));
      LIGHT_1.position.set(
        2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        -0.7 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_1)._.intensity = this.basicLightIntensityRatio * 5;
      lightWrapper.add(LIGHT_1);
      LIGHT_1.name = 'LIGHT_1';

      const LIGHT_2 = new THREE.DirectionalLight(new THREE.Color(0x5c92ff));
      LIGHT_2.position.set(
        -2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        1.7 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_2)._.intensity = this.basicLightIntensityRatio * 5;
      lightWrapper.add(LIGHT_2);
      LIGHT_2.name = 'LIGHT_2';

      const LIGHT_3 = new THREE.SpotLight(new THREE.Color(0xff0000));
      LIGHT_3.position.set(0, 2.6 * LIGHT_POSITION_RATIO, 2.8 * LIGHT_POSITION_RATIO);
      this.lightWithInnerState(LIGHT_3)._.intensity = this.pointLightIntensityRatio * 2.68;
      LIGHT_3.angle = 58.11;
      LIGHT_3.penumbra = 1;
      lightWrapper.add(LIGHT_3);
      LIGHT_3.name = 'LIGHT_3';

      const LIGHT_4 = new THREE.HemisphereLight();
      LIGHT_4.position.set(
        2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        -3 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_4)._.intensity =
        (this.basicLightIntensityRatio * 5) / this.hemisphereLightIntensityRatio;
      lightWrapper.add(LIGHT_4);
      LIGHT_4.name = 'LIGHT_4';

      return lightWrapper;
    },
    [ELightPresets.Product]: (): THREE.Object3D => {
      const lightWrapper = new THREE.Object3D();
      lightWrapper.name = 'Product';

      const LIGHT_1 = new THREE.PointLight(new THREE.Color(0xffffff));
      LIGHT_1.position.set(
        2.6 * LIGHT_POSITION_RATIO,
        1.6 * LIGHT_POSITION_RATIO,
        -1.7 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_1)._.intensity = this.pointLightIntensityRatio * 5;
      lightWrapper.add(LIGHT_1);
      LIGHT_1.name = 'LIGHT_1';

      const LIGHT_2 = new THREE.DirectionalLight(new THREE.Color(0x5c92ff));
      LIGHT_2.position.set(-2.3 * LIGHT_POSITION_RATIO, 1.7 * LIGHT_POSITION_RATIO, 0);
      this.lightWithInnerState(LIGHT_2)._.intensity = this.basicLightIntensityRatio * 5;
      lightWrapper.add(LIGHT_2);
      LIGHT_2.name = 'LIGHT_2';

      const LIGHT_3 = new THREE.SpotLight(new THREE.Color(0xffffff));
      LIGHT_3.position.set(0, 2.6 * LIGHT_POSITION_RATIO, 2.8 * LIGHT_POSITION_RATIO);
      this.lightWithInnerState(LIGHT_3)._.intensity = this.pointLightIntensityRatio * 10;
      LIGHT_3.angle = 24;
      LIGHT_3.penumbra = 1;
      lightWrapper.add(LIGHT_3);
      LIGHT_3.name = 'LIGHT_3';

      const LIGHT_4 = new THREE.HemisphereLight();
      LIGHT_4.position.set(
        2.6 * LIGHT_POSITION_RATIO,
        1.2 * LIGHT_POSITION_RATIO,
        -3 * LIGHT_POSITION_RATIO
      );
      this.lightWithInnerState(LIGHT_4)._.intensity =
        (this.basicLightIntensityRatio * 5) / this.hemisphereLightIntensityRatio;
      lightWrapper.add(LIGHT_4);
      LIGHT_4.name = 'LIGHT_4';

      return lightWrapper;
    },
    [ELightPresets.PoisonOrbit]: (): THREE.Object3D => {
      const lightWrapper = new THREE.Object3D();
      lightWrapper.name = 'PoisonOrbit';

      const innerLightWrapper = new THREE.Object3D();
      innerLightWrapper.position.set(0, 1.5 * LIGHT_POSITION_RATIO, 0);

      tweenLightControllerStore.tween[0].onUpdate((): void => {
        innerLightWrapper.rotation.set(
          tweenLightControllerStore.animations.rotation.x,
          tweenLightControllerStore.animations.rotation.y,
          tweenLightControllerStore.animations.rotation.z
        );
      });

      const LIGHT_1 = new THREE.PointLight(new THREE.Color(0x27ff24));
      LIGHT_1.position.set(2 * LIGHT_POSITION_RATIO, 0, 0);
      this.lightWithInnerState(LIGHT_1)._.intensity = this.pointLightIntensityRatio * 5;
      innerLightWrapper.add(LIGHT_1);
      LIGHT_1.name = 'LIGHT_1';

      const LIGHT_2 = new THREE.PointLight(new THREE.Color(0x5cb8ff));
      LIGHT_2.position.set(-2 * LIGHT_POSITION_RATIO, 0, 0);
      this.lightWithInnerState(LIGHT_2)._.intensity = this.pointLightIntensityRatio * 5;
      innerLightWrapper.add(LIGHT_2);
      LIGHT_2.name = 'LIGHT_2';

      lightWrapper.add(innerLightWrapper);

      return lightWrapper;
    }
  };
}
