import React from 'react';
import * as Styled from './styles';
import { useHistory } from 'react-router-dom';
import Cube from 'assets/images/logo-cube.png';

const NotFoundPage = (): JSX.Element => {
  const history = useHistory();

  const navigateToHomePage = (): void => {
    history.push('/');
  };

  return (
    <Styled.PageContainer>
      <Styled.PageContent>
        <Styled.NotFoundBlockContainer>
          <Styled.LogoCube src={Cube} className='left-cube' />
          <Styled.LogoCube src={Cube} className='top-cube' />
          <Styled.LogoCube src={Cube} className='bottom-cube' />
          <Styled.NotFoundBlock>
            <Styled.NotFoundCode>404</Styled.NotFoundCode>
            <Styled.NotFoundText>Sorry, the page you visited does not exist.</Styled.NotFoundText>
          </Styled.NotFoundBlock>
        </Styled.NotFoundBlockContainer>
        <Styled.BackButton type='button' onClick={navigateToHomePage}>
          Back Home
        </Styled.BackButton>
      </Styled.PageContent>
    </Styled.PageContainer>
  );
};

export default NotFoundPage;
