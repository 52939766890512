import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 90vw;
  max-height: 90vh;
  color: ${COLORS.white};
  position: relative;
  padding: 27px;
  border-radius: 7px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  overflow: auto;

  @media ${DEVICE.mobile} {
    padding: 16px;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${COLORS.white};

  @media ${DEVICE.tablet} {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const ModalContent = styled.main`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .highlighted-text {
    color: ${COLORS.turbo};
  }
`;

export const TopContent = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 18px;

  @media ${DEVICE.tablet} {
    flex-direction: column;
    justify-content: initial;
  }
`;

export const InfoBlock = styled.article`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${COLORS.mineShaft};
  color: ${COLORS.white};
  width: 50%;
  border-radius: 6px;

  @media ${DEVICE.tablet} {
    width: 100%;
  }

  @media ${DEVICE.mobile} {
    padding: 10px;
  }
`;

export const BlockTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: ${COLORS.white};
  margin-bottom: 0;

  @media ${DEVICE.mobile} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const BlockSubtitle = styled.h6`
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  color: ${COLORS.white};
  margin: 8px 0 0;

  & > .highlighted-text {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${DEVICE.mobile} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const InfoBlockContent = styled.ul`
  margin-top: 16px;
  padding-left: 24px;
`;

export const ContentItem = styled.li`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  &:not(:first-child) {
    margin-top: 14px;
  }

  @media ${DEVICE.mobile} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const BottomContent = styled.section`
  margin-top: 18px;
  width: 100%;
  padding: 19px 24px;
  background-color: ${COLORS.mineShaft};
  border-radius: 6px;

  @media ${DEVICE.mobile} {
    padding: 10px 14px;
  }
`;

export const ControlExamples = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const KeyboardImage = styled.img`
  width: 85%;
`;

export const ExamplesRightSide = styled.div`
  width: 12%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MouseImage = styled.img`
  width: 100%;
`;

export const KeyboardArrowsImage = styled.img`
  width: 100%;
`;
