import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { verifyUserEmail } from 'services/api/authService';
import { openNotification } from 'utils/notification-utils';
import { AuthState, ESnackbarStyle } from 'shared/types';
import { EMAIL_VERIFIED } from 'shared/constants/notifications';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModalCreateAccount, ModalLogin, ModalResetPassword } from 'shared/components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { muteModelNotifications } from 'services/api/preferencesService';
import { logoutUser } from 'services/store/reducers/authReducer';

const useURLParamsHandling = (): void => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const params = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search]
  );
  const token = params.get('token');
  const resetCode = params.get('reset_code');
  const recoverAccount = params.get('recover_account');
  const email = params.get('email');
  const event = params.get('event');
  const modelId = params.get('modelId');
  const signup = params.get('signup');
  const login = params.get('login');
  const teamInvite = params.get('teamInvite');

  const clearParams = useCallback(
    (paramItems: string[]): void => {
      paramItems.forEach((item): void => params.delete(item));
      history.replace({ search: params.toString() });
    },
    [history, params]
  );

  useEffect((): void => {
    if (!!token && !!email && !recoverAccount) {
      clearParams(['token', 'email']);
      (async (): Promise<void> => {
        dispatch(startLoader());
        try {
          await dispatch(logoutUser());
          const { success } = (await verifyUserEmail({ token, email: decodeURIComponent(email) }))
            .data;
          if (success) {
            openNotification(ESnackbarStyle.SUCCESS, EMAIL_VERIFIED);
            dispatch(showModal(<ModalLogin email={decodeURIComponent(email)} />));
          }
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      })();
    }
  }, [email, token, recoverAccount, dispatch, clearParams]);

  useEffect((): void => {
    if (!!resetCode) dispatch(showModal(<ModalResetPassword resetCode={resetCode} />));
  }, [dispatch, resetCode]);

  useEffect((): void => {
    if (!!recoverAccount && !!token && !!email) {
      dispatch(
        showModal(
          <ModalCreateAccount
            isAccountRecovery={!!recoverAccount}
            email={decodeURIComponent(email)}
            token={token}
          />
        )
      );
      clearParams(['recover_account', 'token', 'email']);
    }
  }, [clearParams, dispatch, email, recoverAccount, token]);

  useEffect((): void => {
    if (event === 'mute-notifications' && !!modelId) {
      const turnOffModelNotifications = async (): Promise<void> => {
        try {
          await muteModelNotifications(modelId, true);
          openNotification(ESnackbarStyle.SUCCESS, 'Notifications have been successfully disabled');
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          clearParams(['event', 'modelId']);
        }
      };

      if (isAuth) {
        turnOffModelNotifications();
      } else {
        openNotification(ESnackbarStyle.HOLD_UP, 'Please sign in to disable notifications');
      }
    }
  }, [clearParams, event, isAuth, modelId]);

  useEffect((): void => {
    if (!!signup) dispatch(showModal(<ModalCreateAccount />));
    if (!!login) dispatch(showModal(<ModalLogin />));
  }, [signup, login, dispatch]);

  useEffect((): void => {
    if (!!teamInvite) {
      dispatch(showModal(<ModalCreateAccount />));
      clearParams(['teamInvite']);
    }
  }, [clearParams, dispatch, teamInvite]);
};

export default useURLParamsHandling;
