import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector, useBrowserStore, useCopyToClipboard } from 'shared/hooks';
import { openSidebar } from 'services/store/reducers/sidebarReducer';
import {
  ModalCreateAccount,
  ModalLogin,
  ModalQuickGuide,
  ModalSharing,
  ModalUploadToSpace,
  ProjectLogo
} from 'shared/components';
import { EModelAccessLevel, EModelType } from 'shared/types';
import { showModal } from 'services/store/reducers/modalReducer';
import { useHistory } from 'react-router-dom';
import { clearViewerDataState } from 'services/store/reducers/viewerDataReducer';
import {
  BurgerMenuIcon,
  LoginIcon,
  NewModelIcon,
  ProfileIcon,
  QuickViewIcon
} from 'assets/dynamic-icons';
import Logo from 'assets/images/logo.png';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { changePublicAccessLevel } from 'services/store/reducers/modelPermissionsReducer';
import { RootState } from 'services/store';
import { ScheduledMaintenancePanel } from './components/scheduled-maintenance-panel';
import { CustomTooltip } from 'shared/components';
import { NavButton } from './components/nav-button';

const Header = (): React.ReactElement => {
  const isScheduledMaintenance = false;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const store = useAppSelector((state): RootState => state);
  const { isAuth } = store.auth;
  const { isArMode } = store.arMode;
  const { model, modelType } = store.viewerData;
  const { hasEditAccess } = store.modelFeatureAccess;
  const { brandColors, brandLogoUrl } = store.branding.activeBranding;
  const { removeFilesFromBrowserStore } = useBrowserStore();
  const { copyToClipboard } = useCopyToClipboard();
  const { headerTextColor } = brandColors;
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;
  const quickViewTooltip = 'Use Quick View to view a model without auto-saving it to your account';
  const uploadModelTooltip = 'Use Upload for Sharing to add and save a new model to your account';

  const handleLogoClick = (): void => {
    dispatch(clearViewerDataState());
    removeFilesFromBrowserStore();
    clearModelLocalStorage();
    history.push('/');
  };

  const handleShareARViewClick = async (): Promise<void> => {
    if (isAnonymousModel) {
      dispatch(showModal(<ModalSharing />));
    } else if (!!model) {
      await copyToClipboard(`${model.shortLinkUrl}?ar=1`);
      const accessLevel = [EModelAccessLevel.VIEW_ACCESS, EModelAccessLevel.VIEW_AR_ACCESS];
      await dispatch(changePublicAccessLevel({ accessLevel, model }));
    }
  };

  const handleSidebarButtonClick = (): void => {
    dispatch(openSidebar());
  };

  const handleLoginButtonClick = (): void => {
    dispatch(showModal(<ModalLogin />));
  };

  const handlePseudoUploadButtonClick = (): void => {
    dispatch(showModal(<ModalCreateAccount />));
  };

  const handleMobileUploadModel = (): void => {
    dispatch(showModal(<ModalUploadToSpace />));
  };

  const handleQuickGuideClick = (): void => {
    dispatch(showModal(<ModalQuickGuide />));
  };

  const handleProfileClick = (): void => {
    history.push('/account-settings');
  };

  const handleQuickViewClick = (): void => {
    history.push('/quick-view');
    if (!!model) history.go(0);
  };

  const handleUploadClick = (): void => {
    history.push('/upload');
    if (!!model) history.go(0);
  };

  return (
    <Styled.HeaderContainer>
      {isScheduledMaintenance && <ScheduledMaintenancePanel />}
      <Styled.HeaderContent backgroundColor={headerTextColor}>
        {!isArMode ? (
          <>
            {isAuth && (
              <Styled.MenuButton onClick={handleSidebarButtonClick}>
                <BurgerMenuIcon />
              </Styled.MenuButton>
            )}
            <Styled.ProjectLogoContainer isAuth={isAuth}>
              <ProjectLogo />
            </Styled.ProjectLogoContainer>
          </>
        ) : (
          <div />
        )}
        <Styled.LogoMobileView
          src={brandLogoUrl || Logo}
          onClick={handleLogoClick}
          isAuth={isAuth}
          isArMode={isArMode}
          alt='glbee Free Online GLTF Viewer'
          id='app-logo-button'
        />
        {isArMode ? (
          <>
            {(hasEditAccess || isAnonymousModel) && (
              <Styled.ShareArViewButton type='button' onClick={handleShareARViewClick}>
                Share AR View
              </Styled.ShareArViewButton>
            )}
          </>
        ) : (
          <Styled.HeaderRightSide>
            <Styled.UploadButtons>
              <CustomTooltip content={quickViewTooltip} placement='bottom'>
                <>
                  <NavButton
                    clickAction={handleQuickViewClick}
                    name={'Quick View'}
                    icon={<QuickViewIcon />}
                  />
                </>
              </CustomTooltip>
            </Styled.UploadButtons>

            <Styled.NavButtons>
              {isAuth ? (
                <>
                  <CustomTooltip content={uploadModelTooltip} placement='bottom'>
                    <>
                      <NavButton
                        clickAction={handleUploadClick}
                        className={'upload-button-desktop'}
                        name={'Upload for Sharing'}
                        icon={<NewModelIcon />}
                      />
                      <NavButton
                        className={'upload-button-mobile'}
                        clickAction={handleMobileUploadModel}
                        name={'Upload for Sharing'}
                        icon={<NewModelIcon />}
                      />
                    </>
                  </CustomTooltip>
                  <NavButton clickAction={handleProfileClick} icon={<ProfileIcon />} />
                  <Styled.GuideButton type='button' onClick={handleQuickGuideClick}>
                    ?
                  </Styled.GuideButton>
                </>
              ) : (
                <>
                  <NavButton
                    clickAction={handlePseudoUploadButtonClick}
                    name={'Upload for Sharing'}
                    icon={<NewModelIcon />}
                  />
                  <NavButton
                    clickAction={handleLoginButtonClick}
                    name={'Login'}
                    icon={<LoginIcon />}
                  />
                </>
              )}
            </Styled.NavButtons>
          </Styled.HeaderRightSide>
        )}
      </Styled.HeaderContent>
    </Styled.HeaderContainer>
  );
};

export default Header;
