import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CUBE_ZONES } from 'shared/constants/scenes-constants';
import {
  CUBE_ORBIT_CONTROLLER_CUBE_FACE_ID,
  CUBE_ORBIT_CONTROLLER_CANVAS_ID,
  CUBE_ORBIT_CONTROLLER_CSS3D_CONTAINER_ID,
  EMBEDDING_MODAL_CUBE_ORBIT_CONTROLLER_CANVAS_ID
} from 'shared/constants/html-elements-ids';
import { DisposeSceneService, ShortClickHandlerService } from 'services/strategy-services';
import { CSS3DObject, CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { Easing, Tween } from '@tweenjs/tween.js';
import { setupRendererDomElement } from 'utils/scenes-utils';
import {
  COC_CAMERA_FAR,
  COC_CAMERA_FOV,
  COC_CAMERA_NEAR
} from 'shared/constants/scene-settings-constants';
import { ECubeZones } from 'shared/enums/ECubeZones';

type FacePresets = Record<
  Partial<ECubeZones>,
  { zoneName: ECubeZones; faces: string[]; camPos: THREE.Vector3 }
>;

const FACES_PRESETS: FacePresets = Object.entries(CUBE_ZONES).reduce(
  (acc, [name, options]): FacePresets => {
    if (name.includes('face')) {
      acc[name as ECubeZones] = options;
    }

    return acc;
  },
  {} as FacePresets
);

export class CubeOrbitControllerScene extends DisposeSceneService {
  public scene: THREE.Scene;
  public camera: THREE.PerspectiveCamera;
  public renderer: THREE.WebGLRenderer;
  public canvas: HTMLCanvasElement;
  public labelsRenderer: CSS3DRenderer;
  public labelsContainer: HTMLElement;
  public previewCamera: THREE.PerspectiveCamera;
  public previewRenderer: THREE.WebGLRenderer;
  public previewCanvas: HTMLCanvasElement;
  public previewLabelsRenderer: CSS3DRenderer;
  public previewLabelsContainer: HTMLElement;
  public orbitControls: OrbitControls;
  public cube: THREE.Mesh | undefined;

  private mounted: boolean = false;
  private isPreviewSceneActive: boolean = false;
  private allowUpdates: boolean = false;
  private defaultSceneWidth: number = 80;
  private defaultSceneHeight: number = 80;
  private readonly cubeSize: number = 1;
  private readonly sizeSegments: number = 5;
  private readonly axialDistToCamera: number = 2 * Math.sqrt(2 * Math.pow(0.5 * this.cubeSize, 2));
  private readonly absDistToCamera: number = 2 * Math.sqrt(4 * Math.pow(0.5 * this.cubeSize, 2));
  private canvasRect: DOMRect | undefined;
  private parent: HTMLDivElement | undefined;
  private targetCamera: THREE.Camera | undefined;
  private spherical: THREE.Spherical = new THREE.Spherical();
  private raycaster: THREE.Raycaster = new THREE.Raycaster();
  private pointer: THREE.Vector2 = new THREE.Vector2();
  private shortClickHandlerService: ShortClickHandlerService = new ShortClickHandlerService();
  private activeZone: ECubeZones | undefined;
  private readonly updateTargetCamera: (cameraFrom: THREE.Camera) => void;

  constructor(updateTargetCamera: (cameraFrom: THREE.Camera) => void) {
    super();

    this.updateTargetCamera = updateTargetCamera;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      COC_CAMERA_FOV,
      this.defaultSceneWidth / this.defaultSceneHeight,
      COC_CAMERA_NEAR,
      COC_CAMERA_FAR
    );
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true
    });
    this.labelsRenderer = new CSS3DRenderer();
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

    this.previewCamera = new THREE.PerspectiveCamera(
      COC_CAMERA_FOV,
      this.defaultSceneWidth / this.defaultSceneHeight,
      COC_CAMERA_NEAR,
      COC_CAMERA_FAR
    );
    this.previewRenderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true
    });
    this.previewLabelsRenderer = new CSS3DRenderer();

    this.canvas = this.renderer.domElement;
    this.labelsContainer = this.labelsRenderer.domElement;
    this.previewCanvas = this.previewRenderer.domElement;
    this.previewLabelsContainer = this.previewLabelsRenderer.domElement;

    this.camera.position.setScalar(this.axialDistToCamera);
    this.previewCamera.position.setScalar(this.axialDistToCamera);

    this.setupRendering();
    this.setupOrbitControls();
    this.setupCube();
    this.addListeners();
  }

  public mount(parent: HTMLDivElement, targetCamera: THREE.Camera): void {
    if (this.mounted) return;

    const { offsetWidth, offsetHeight } = parent;

    this.parent = parent;
    this.targetCamera = targetCamera;

    this.parent.appendChild(this.labelsContainer);
    this.parent.appendChild(this.canvas);

    this.camera.aspect = offsetWidth / offsetHeight;
    this.renderer.setSize(offsetWidth, offsetHeight);
    this.labelsRenderer.setSize(offsetWidth, offsetHeight);

    this.updateCanvasRect();
    this.setupLabels();

    this.mounted = true;
  }

  public mountPreview(parent: HTMLDivElement): void {
    const { offsetWidth, offsetHeight } = parent;

    this.togglePreviewScene(true);
    parent.appendChild(this.previewLabelsContainer);
    parent.appendChild(this.previewCanvas);

    this.previewCamera.aspect = offsetWidth / offsetHeight;
    this.previewRenderer.setSize(offsetWidth, offsetHeight);
    this.previewLabelsRenderer.setSize(offsetWidth, offsetHeight);

    this.previewCamera.position.copy(this.camera.position);
    this.previewCamera.rotation.copy(this.camera.rotation);

    this.previewRenderer.render(this.scene, this.previewCamera);
    this.previewLabelsRenderer.render(this.scene, this.previewCamera);
  }

  public togglePreviewScene(value: boolean): void {
    this.isPreviewSceneActive = value;

    this.animate();
  }

  public updateCamera(cameraFrom: THREE.Camera, targetPoint: THREE.Vector3): void {
    const offset = new THREE.Vector3();
    const quaternion = new THREE.Quaternion().setFromUnitVectors(
      cameraFrom.up,
      new THREE.Vector3(0, 1, 0)
    );

    offset.copy(cameraFrom.position).sub(targetPoint);
    offset.applyQuaternion(quaternion);

    this.spherical.setFromVector3(offset);
    this.spherical.radius = this.absDistToCamera;

    offset.setFromSpherical(this.spherical);
    offset.applyQuaternion(quaternion.invert());

    if (!offset.equals(this.camera.position)) {
      this.toggleClassNames('clicked');
    }

    this.camera.position.copy(this.orbitControls.target).add(offset);
    this.camera.updateProjectionMatrix();

    this.renderer.render(this.scene, this.camera);
  }

  private setupRendering(): void {
    this.renderer.setSize(this.defaultSceneWidth, this.defaultSceneHeight);
    this.labelsRenderer.setSize(this.defaultSceneWidth, this.defaultSceneHeight);
    this.previewRenderer.setSize(this.defaultSceneWidth, this.defaultSceneHeight);
    this.previewLabelsRenderer.setSize(this.defaultSceneWidth, this.defaultSceneHeight);

    setupRendererDomElement({
      element: this.canvas,
      id: CUBE_ORBIT_CONTROLLER_CANVAS_ID,
      left: 0,
      bottom: 0
    });
    setupRendererDomElement({
      element: this.labelsContainer,
      id: CUBE_ORBIT_CONTROLLER_CSS3D_CONTAINER_ID,
      left: 0,
      bottom: 0
    });
    setupRendererDomElement({
      element: this.previewCanvas,
      id: CUBE_ORBIT_CONTROLLER_CANVAS_ID,
      left: 0,
      bottom: 0
    });
    setupRendererDomElement({
      element: this.previewLabelsContainer,
      id: EMBEDDING_MODAL_CUBE_ORBIT_CONTROLLER_CANVAS_ID,
      left: 0,
      bottom: 0
    });

    this.startAnimationLoop();
  }

  protected animate(): void {
    if (this.isPreviewSceneActive) return;

    this.orbitControls.update();
    this.renderer.render(this.scene, this.camera);
    this.labelsRenderer.render(this.scene, this.camera);
  }

  private setupCube(): void {
    const geometry = new THREE.BoxGeometry(
      this.cubeSize,
      this.cubeSize,
      this.cubeSize,
      this.sizeSegments,
      this.sizeSegments,
      this.sizeSegments
    );
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0x3c3c3c),
      transparent: true,
      opacity: 0
    });
    this.cube = new THREE.Mesh(geometry, material);
    this.scene.add(this.cube);
  }

  private getFaceElementId(name: string): string {
    return `cocs-${name.toLowerCase().split(' ').join('-')}`;
  }

  private setupFace(element: HTMLElement, name: string, position: THREE.Vector3): CSS3DObject {
    element.innerText = name.split(' ')[0].toUpperCase();
    element.id = this.getFaceElementId(name);

    const objectCSS = new CSS3DObject(element);

    objectCSS.position.copy(position).multiplyScalar(100);
    objectCSS.lookAt(objectCSS.position.clone().multiplyScalar(2));

    return objectCSS;
  }

  private setupLabels(): void {
    if (!this.parent) return;

    const facesElements = this.parent.querySelectorAll('.' + CUBE_ORBIT_CONTROLLER_CUBE_FACE_ID);

    if (facesElements.length !== 6) return;

    const cubeObject = new THREE.Object3D();

    cubeObject.position.setScalar(0);
    cubeObject.scale.setScalar(0.0095);

    Object.keys(FACES_PRESETS).forEach((key, index): void => {
      const { zoneName, camPos } = FACES_PRESETS[key as ECubeZones];
      const element = facesElements[index] as HTMLElement;

      const face = this.setupFace(
        element,
        zoneName,
        camPos.clone().multiplyScalar(this.cubeSize / 2)
      );

      cubeObject.add(face);
    });

    this.scene.add(cubeObject);
  }

  private setupOrbitControls(): void {
    this.orbitControls.enableZoom = false;
    this.orbitControls.enablePan = false;
    this.orbitControls.enableRotate = true;

    this.orbitControls.addEventListener('change', (): void => {
      if (this.allowUpdates) {
        this.updateTargetCamera(this.camera);
      }
    });
    this.orbitControls.addEventListener('start', (): void => {
      this.allowUpdates = true;

      this.toggleClassNames('clicked');
    });
    this.orbitControls.addEventListener('end', (): void => {
      this.allowUpdates = false;
    });
  }

  private addListeners(): void {
    window.addEventListener('resize', this.updateCanvasRect.bind(this, 1000));
    window.addEventListener('sidebarStateChange', this.updateCanvasRect.bind(this, 1000));
    this.canvas.addEventListener('mouseup', this.getActiveZone.bind(this));
    this.canvas.addEventListener('mousedown', this.getActiveZone.bind(this));
    this.canvas.addEventListener('mousemove', this.getActiveZone.bind(this));
    this.canvas.addEventListener('mouseleave', this.getActiveZone.bind(this));
  }

  protected removeListeners = (): void => {
    window.removeEventListener('resize', this.updateCanvasRect.bind(this, null));
    window.removeEventListener('sidebarStateChange', this.updateCanvasRect.bind(this, null));
    this.canvas.removeEventListener('mouseup', this.getActiveZone.bind(this));
    this.canvas.removeEventListener('mousedown', this.getActiveZone.bind(this));
    this.canvas.removeEventListener('mousemove', this.getActiveZone.bind(this));
    this.canvas.removeEventListener('mouseleave', this.getActiveZone.bind(this));
  };

  private updateCanvasRect(timeout?: number | null): void {
    if (timeout) {
      setTimeout(this.updateCanvasRect.bind(this), timeout);
      return;
    } else {
      this.canvasRect = this.canvas.getBoundingClientRect();
    }
  }

  private setCameraRotation(position: THREE.Vector3): void {
    const newPosition = new THREE.Vector3();
    const spherical = new THREE.Spherical();

    spherical.setFromVector3(position);
    spherical.radius = this.absDistToCamera;
    newPosition.setFromSpherical(spherical);

    new Tween(this.camera.position)
      .to(newPosition, 500)
      .onUpdate(this.updateTargetCamera.bind(this, this.camera))
      .easing(Easing.Cubic.InOut)
      .start();

    this.updateTargetCamera(this.camera);
  }

  private getActiveZone(event: MouseEvent): void {
    if (!this.canvasRect || !this.parent || !this.cube) return;

    if (event.type === 'mousedown') {
      this.parent.style.cursor = 'grabbing';
    } else if (event.type === 'mouseup') {
      this.parent.style.cursor = 'grab';
    }

    const x = (event.clientX - this.canvasRect.left) / this.canvasRect.width;
    const y = (event.clientY - this.canvasRect.top) / this.canvasRect.height;

    this.pointer.x = x * 2 - 1;
    this.pointer.y = -y * 2 + 1;

    this.raycaster.setFromCamera(this.pointer, this.camera);

    const intersects = this.raycaster.intersectObject(this.cube, true);

    if (!intersects[0]) return;

    const fi = intersects[0].faceIndex;

    if (!fi) return;

    this.activeZone = Object.values(CUBE_ZONES).find((e): boolean =>
      e.faces.some((a): boolean => fi === +a)
    )?.zoneName;

    this.handleCubeSideHighlight('hovered');
    this.shortClickHandlerService.handleShortClick(event, this.handleZoneClick.bind(this));
  }

  private handleZoneClick(): void {
    if (!this.activeZone) return;

    this.setCameraRotation(CUBE_ZONES[this.activeZone].camPos);
    this.handleCubeSideHighlight('clicked');
  }

  private handleCubeSideHighlight(actionType: 'clicked' | 'hovered'): void {
    if (!this.activeZone) return;

    switch (this.activeZone) {
      case ECubeZones.faceFr:
        this.toggleClassNames(actionType, ECubeZones.faceFr);
        break;

      case ECubeZones.faceBk:
        this.toggleClassNames(actionType, ECubeZones.faceBk);
        break;

      case ECubeZones.faceLf:
        this.toggleClassNames(actionType, ECubeZones.faceLf);
        break;

      case ECubeZones.faceRg:
        this.toggleClassNames(actionType, ECubeZones.faceRg);
        break;

      case ECubeZones.faceTp:
        this.toggleClassNames(actionType, ECubeZones.faceTp);
        break;

      case ECubeZones.faceBt:
        this.toggleClassNames(actionType, ECubeZones.faceBt);
        break;

      default:
        this.toggleClassNames(actionType);
        break;
    }
  }

  private toggleClassNames(className: string, targetKey?: ECubeZones): void {
    Object.keys(FACES_PRESETS).forEach((key): void => {
      const face = document.getElementById(this.getFaceElementId(key));

      if (key !== targetKey) {
        face?.classList.toggle(className, false);
      } else {
        face?.classList.toggle(className, true);
      }
    });
  }
}
