import { ESnackbarStyle } from 'shared/types';
import { LINK_COPIED } from 'shared/constants/notifications';
import { openNotification } from 'utils/notification-utils';

type Result = {
  copyToClipboard: (text: string, notification?: string) => Promise<void>;
};

const useCopyToClipboard = (): Result => {
  const processCopyAction = (text: string): Promise<void> => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(text);
    } else {
      const textArea = document.createElement('textarea');
      // @ts-ignore
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      // @ts-ignore
      textArea.select();
      return new Promise((resolve, reject): void => {
        document.execCommand('copy') ? resolve() : reject();
        textArea.remove();
      });
    }
  };

  const copyToClipboard = async (text: string, notification = LINK_COPIED): Promise<void> => {
    try {
      await processCopyAction(text);
      openNotification(ESnackbarStyle.SUCCESS, notification);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  };

  return {
    copyToClipboard
  };
};

export default useCopyToClipboard;
