import { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { CubemapImagePaths, ESnackbarStyle } from 'shared/types';
import { DEFAULT_CUBEMAP_IMAGE_PATHS } from 'shared/constants/customization';
import { openNotification } from '../../../../../utils/notification-utils';

type Result = {
  setImagePaths: (paths: CubemapImagePaths) => void;
};

const useCubemapController = (scene: THREE.Scene): Result => {
  const cubeTextureLoader = useMemo((): THREE.CubeTextureLoader => new THREE.CubeTextureLoader(), []);
  const [imagePaths, setImagePaths] = useState<CubemapImagePaths>(DEFAULT_CUBEMAP_IMAGE_PATHS);

  useEffect((): void => {
    setEnvironment(scene, imagePaths);
  }, [imagePaths, scene]);

  const setEnvironment = async (scene: THREE.Scene, imagePaths: CubemapImagePaths): Promise<void> => {
    const { pxPlaneUrl, nxPlaneUrl, pyPlaneUrl, nyPlaneUrl, pzPlaneUrl, nzPlaneUrl } = imagePaths;

    try {
      scene.environment = await cubeTextureLoader.loadAsync([
        pxPlaneUrl,
        nxPlaneUrl,
        pyPlaneUrl,
        nyPlaneUrl,
        pzPlaneUrl,
        nzPlaneUrl
      ]);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  };

  return { setImagePaths };
};

export default useCubemapController;
