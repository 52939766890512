import type {
  IGeometry3DStats,
  IModelParserService,
  IResources3DStats,
  ITexture2DStats
} from '../interfaces/model-parse/model-stats';
import { IMTLMetaData, IOBJMetaData } from '../interfaces/model-parse/obj-types';

import { Texture2DStats } from '../texture-2d-stats';
import { imageMetadata } from '../texture-parser/texture-parser';

import { OBJParser } from './obj-parser';
import { MTLParser } from './mtl-parser';
import { Model3DFilePartStats } from '../interfaces/3d-models';
import { Model3DAnimationStats } from '../interfaces/3d-models/model-3d-resources.interface';
import { fetchBuffer } from '../utils/buffer';

export class OBJParserService implements IModelParserService {
  protected _textureStats: Texture2DStats = new Texture2DStats();
  protected _resourceStats: IResources3DStats = {
    materialsCount: 0,
    texturesCount: 0,
    animationsCount: 0,
    meshesCount: 0,
    scenesCount: 0
  };
  protected _geometryStats: IGeometry3DStats = {
    trianglesCount: 0,
    vertexesCount: 0,
    polygonsCount: 0
  };

  /** 0 - none, 1 - lit, 2 - unlit, 3 - mixed */
  protected _unlitFlag: number = 0;
  protected _basePath: string = '';
  protected _format: string = '';
  protected _objsize: number = 0;

  private _buffers: ArrayBuffer[] = [];

  public static canHandleFileType(ext: string): boolean {
    return this.supportedFileTypes.includes(ext);
  }

  public static get supportedFileTypes(): string[] {
    return ['obj'];
  }
  public get format(): string {
    return this._format;
  }

  public get unlitFlag(): number {
    return this._unlitFlag;
  }

  public get fileSize(): Model3DFilePartStats {
    return {
      totalSize: this.binSize,
      gltfSize: this._objsize,
      binSize: this.binSize
    };
  }

  public get vertexesCount(): number {
    return this._geometryStats.vertexesCount;
  }

  public get trianglesCount(): number {
    return this._geometryStats.trianglesCount;
  }

  public get quadsCount(): number {
    return 0;
  }

  public get polygonsCount(): number {
    return this._geometryStats.polygonsCount || 0;
  }

  public get meshCount(): number {
    return this._resourceStats.meshesCount;
  }

  public get materialsCount(): number {
    return this._resourceStats.materialsCount;
  }

  public get scenesCount(): number {
    return this._resourceStats.scenesCount;
  }

  public get animations(): Model3DAnimationStats {
    return {
      totalCount: this._resourceStats.animationsCount,
      animations: []
    };
  }

  public get textures(): ITexture2DStats {
    return this._textureStats.plainObject();
  }

  protected get binSize(): number {
    return this._buffers.reduce((acc: number, buffer): number => {
      return acc + buffer?.byteLength || 0;
    }, 0);
  }

  protected parseOBJSync(modelFile: Uint8Array): IOBJMetaData {
    this._buffers.push(modelFile);
    return new OBJParser(modelFile).parse();
  }

  protected parseMTLSync(mtlFile: Uint8Array): IMTLMetaData | null {
    this._buffers.push(mtlFile);
    return new MTLParser(mtlFile).parse();
  }

  public async parse(
    ext: string,
    formatFiles: Record<string, string>,
    assets: Record<string, string>
  ): Promise<boolean> {
    this._format = `.${ext}`;

    if (!formatFiles) {
      return false;
    }

    const formatFilesKeys = Object.keys(formatFiles);

    const mainObjKey = formatFilesKeys
      .filter((key): boolean => key.slice(key.lastIndexOf('.')).toLowerCase() === this._format)
      .join();
    const mainObj = await fetchBuffer(formatFiles[mainObjKey]);

    this._objsize = mainObj.byteLength;

    const obj = this.parseOBJSync(new Uint8Array(mainObj));

    this._geometryStats.vertexesCount = obj.vertices;
    this._geometryStats.polygonsCount = obj.polygons;

    // Assuming `mode = gl.TRIANGLES`
    this._geometryStats.trianglesCount = obj.vertices > 0 ? Math.round(obj.vertices / 3) : 0;

    if (obj.mtl) {
      const mtlPath = Object.keys(formatFiles).find((key): boolean => key.includes(obj.mtl));
      let mtl = null;
      if (mtlPath) {
        const mtlFile = await fetchBuffer(formatFiles[mtlPath]);
        mtl = this.parseMTLSync(new Uint8Array(mtlFile));
      }
      if (mtl) {
        this._resourceStats.materialsCount = mtl.materials;
        this._resourceStats.texturesCount = mtl.textures.length;
        this._unlitFlag = mtl.unlitFlag;
        for (const tex of mtl.textures) {
          try {
            const path = Object.keys(assets).find((key): boolean => key.includes(tex));
            if (path) {
              const buffer = new Uint8Array(await fetchBuffer(assets[path]));
              this._buffers.push(buffer);
              const texImage = imageMetadata(new Uint8Array(buffer));
              this._textureStats.updateTexture2DStats(texImage);
            }
          } catch (e) {
            /* ... just ignore parse errors */
          }
        }
      }
    }

    return true;
  }
}
