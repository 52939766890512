import { useCallback, useState } from 'react';
import { ESnackbarStyle, StatisticItem } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { getStatistics } from 'services/api/statisticsService';

type Result = {
  fetchStatistics: (startDate: string, endDate: string) => Promise<void>;
  statistics: StatisticItem[];
  statisticsIsLoading: boolean;
};

const useStatisticsData = (): Result => {
  const [statistics, setStatistics] = useState<StatisticItem[]>([]);
  const [statisticsIsLoading, setStatisticsIsLoading] = useState<boolean>(false);

  const fetchStatistics = useCallback(async (startDate: string, endDate: string): Promise<void> => {
    setStatisticsIsLoading(true);
    try {
      const statistics = (await getStatistics(startDate, endDate)).data;
      setStatistics(statistics);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      setStatisticsIsLoading(false);
    }
  }, []);

  return {
    fetchStatistics,
    statistics,
    statisticsIsLoading
  };
};

export default useStatisticsData;
