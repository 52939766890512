import React from 'react';

const BellFilledIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        viewBox='0 0 11 12'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11 9.71429V10.2857H0V9.71429L1.22222 8.57143V5.14286C1.22222 3.37143 2.46278 1.81143 4.27778 1.30857V1.14286C4.27778 0.839753 4.40655 0.549062 4.63576 0.334735C4.86497 0.120408 5.17585 0 5.5 0C5.82415 0 6.13503 0.120408 6.36424 0.334735C6.59345 0.549062 6.72222 0.839753 6.72222 1.14286V1.30857C8.53722 1.81143 9.77778 3.37143 9.77778 5.14286V8.57143L11 9.71429ZM6.72222 10.8571C6.72222 11.1602 6.59345 11.4509 6.36424 11.6653C6.13503 11.8796 5.82415 12 5.5 12C5.17585 12 4.86497 11.8796 4.63576 11.6653C4.40655 11.4509 4.27778 11.1602 4.27778 10.8571'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default BellFilledIcon;
