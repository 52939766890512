import type { GLTF } from '../interfaces/model-parse/gltf-types';
import { getBinaryImageMetadata } from '../glb-parser/extenstions/image-utils';

export function imageMetadata(image: Uint8Array): GLTF.ImageMetadata | null {
  const texDataView = new DataView(image.buffer, image.byteOffset, image.byteLength);

  if (!texDataView) {
    return null;
  }

  const texImageMeta = getBinaryImageMetadata(texDataView);
  if (texImageMeta) texImageMeta.byteLength = texDataView.byteLength;

  return texImageMeta;
}

