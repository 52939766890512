import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { EConfirmModalHeader, ESnackbarStyle, ETeamRole, TeamsState } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { deleteTeam } from 'services/api/teamService';
import { clearActiveTeam, fetchUserTeams } from 'services/store/reducers/teamsReducer';
import { openNotification } from 'utils/notification-utils';
import { TEAM_DELETED } from 'shared/constants/notifications';
import { useHistory } from 'react-router-dom';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { ModalDelete } from 'shared/components';

enum ESettingsTab {
  CHANGE_TEAM_NAME,
  DELETE_TEAM
}

type Props = {
  setActiveSettingsTab: (tab: ESettingsTab | null) => void;
};

const SettingsList: React.FC<Props> = ({ setActiveSettingsTab }): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { activeTeam, teamRole } = useAppSelector((store): TeamsState => store.teams);
  const handleTabClick =
    (tab: ESettingsTab): (() => void) =>
    (): void => {
      setActiveSettingsTab(tab);
    };

  const handleDeleteClick = async (): Promise<void> => {
    const deleteAction = async (): Promise<void> => {
      dispatch(startLoader());
      try {
        if (!!activeTeam) {
          history.push('/my-workspace');
          await deleteTeam(activeTeam.id);
          dispatch(fetchUserTeams());
          dispatch(clearActiveTeam());
          openNotification(ESnackbarStyle.SUCCESS, TEAM_DELETED);
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: (
          <ModalDelete
            deleteAction={deleteAction}
            text={`Are you sure you want to delete ${activeTeam?.teamName || 'Team'}?`}
          />
        )
      })
    );
  };

  return (
    <Styled.SettingsContainer>
      <Styled.SettingsField onClick={handleTabClick(ESettingsTab.CHANGE_TEAM_NAME)}>
        <Styled.FieldContent>
          <Styled.SettingName>Change team name</Styled.SettingName>
          <Styled.SettingValue>{activeTeam?.teamName || ''}</Styled.SettingValue>
        </Styled.FieldContent>
        <Styled.ArrowIcon />
      </Styled.SettingsField>
      {teamRole === ETeamRole.OWNER && (
        <Styled.SettingsField onClick={handleDeleteClick}>
          <Styled.FieldContent>
            <Styled.SettingName>Cancel plan</Styled.SettingName>
            <Styled.SettingValue>Delete</Styled.SettingValue>
          </Styled.FieldContent>
          <Styled.ArrowIcon />
        </Styled.SettingsField>
      )}
    </Styled.SettingsContainer>
  );
};

export default SettingsList;
