import React from 'react';
import * as Styled from './styles';
import { getFieldError } from 'utils/form-utils';
import { useFormik } from 'formik';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { USER_ADDED_TO_TEAM } from 'shared/constants/notifications';
import { validation } from 'services/validation';
import { useAppDispatch } from 'shared/hooks';
import { ESnackbarStyle } from 'shared/types';
import { addTeamMember } from 'services/api/teamService';
import { closeModal, showConfirmModal } from 'services/store/reducers/modalReducer';
import { fetchTeam } from 'services/store/reducers/teamsReducer';
import { ModalInviteToTeam } from 'shared/components';

type Props = {
  teamId: number;
  fetchTeamMembers: (teamId: number) => Promise<void>;
};

const ModalAddNewMembers: React.FC<Props> = ({ teamId, fetchTeamMembers }): JSX.Element => {
  const dispatch = useAppDispatch();

  const initialValues: { email: string } = {
    email: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ email }): Promise<void> => {
      dispatch(startLoader());
      try {
        await addTeamMember(teamId, email);
        await fetchTeamMembers(teamId);
        await dispatch(fetchTeam({ teamId }));
        formik.resetForm();
        dispatch(closeModal());
        openNotification(ESnackbarStyle.SUCCESS, USER_ADDED_TO_TEAM);
      } catch (e) {
        if (e.message === 'User not found') {
          dispatch(
            showConfirmModal({
              header: null,
              content: (
                <ModalInviteToTeam
                  teamId={teamId}
                  email={email}
                  fetchTeamMembers={fetchTeamMembers}
                />
              )
            })
          );
        } else {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        }
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.EMAIL_FORM
  });

  const handleEnterClick = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') formik.handleSubmit();
  };

  const handleAddUserButtonClick = async (): Promise<void> => {
    formik.handleSubmit();
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalTitle>Add new members</Styled.ModalTitle>
      <Styled.ModalSubtitle>
        To add new members to your team, enter their email address below
      </Styled.ModalSubtitle>
      <Styled.AddPeopleFieldContainer>
        <Styled.FieldLabel>Add people</Styled.FieldLabel>
        <Styled.AddPeopleField>
          <Styled.InputContainer>
            <Styled.AddPeopleInput
              value={formik.values.email}
              name='email'
              onChange={formik.handleChange}
              onKeyUp={handleEnterClick}
              onBlur={formik.handleBlur}
              type='email'
              placeholder='Enter email address'
            />
            <Styled.InputFooter>
              {getFieldError(formik, 'email') || (
                <>
                  {!!formik.values.email.length && (
                    <span className='hint'>Press enter to submit</span>
                  )}
                </>
              )}
            </Styled.InputFooter>
          </Styled.InputContainer>
          <Styled.AddUserButton
            onClick={handleAddUserButtonClick}
            disabled={!formik.values.email.length}
          >
            Add User
          </Styled.AddUserButton>
        </Styled.AddPeopleField>
      </Styled.AddPeopleFieldContainer>
    </Styled.ModalWindowContainer>
  );
};

export default ModalAddNewMembers;
