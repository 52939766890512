import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PageContent = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

export const PageHeader = styled.header`
  display: flex;
`;

export const TabsContainer = styled.nav`
  display: flex;
  width: 100%;
  max-width: 620px;

  @media ${DEVICE.tablet} {
    max-width: initial;
  }
`;

export const PageTab = styled.div<{ isActiveTab: boolean; icon: string }>`
  width: 100%;
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActiveTab }): string => (isActiveTab ? COLORS.turbo : COLORS.doveGray)};
  color: ${({ isActiveTab }): string => (isActiveTab ? COLORS.black : COLORS.white)};
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  transition: all 0.2s;

  &:not(:first-child) {
    margin-left: 9px;
  }

  @media ${DEVICE.mobile} {
    font-size: 12px;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }
`;
