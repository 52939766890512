import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const FooterContainer = styled.footer<{
  isAuth: boolean;
  isPageWithScene: boolean;
  isModelPage: boolean;
  isZenMode: boolean;
}>`
  height: 36px;
  width: 100%;
  background: var(--footer-background-color);
  border-top: 1px solid var(--theme-secondary-color);
  padding: ${({ isAuth, isPageWithScene, isZenMode }): string =>
    !isAuth || isZenMode ? '0 20px' : isPageWithScene ? '0 20px 0 80px' : '0 20px 0 220px'};
  display: flex;
  justify-content: space-between;
  position: relative;

  @media ${DEVICE.tabletLarge} {
    padding: 0 20px;
  }

  @media ${DEVICE.tablet} {
    display: ${({ isModelPage }): string => (isModelPage ? 'none' : 'flex')};
  }

  @media ${DEVICE.mobile} {
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-end;
    height: 72px;
  }
`;

export const FooterLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Copyright = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 34px;
  color: var(--text-color);
`;

export const FooterMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;

  a,
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 34px;
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--theme-secondary-color);
    }
  }

  span,
  a:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Version = styled.div`
  font-size: 10px;
  padding: 1px 6px;
  color: var(--theme-secondary-color);
  border: 1px solid var(--theme-secondary-color);
  border-radius: 5px;
  margin-left: 21px;

  @media${DEVICE.tablet} {
    margin-left: 10px;
  }
`;
