import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const SearchSelectContainer = styled.div`
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  width: 100%;
  display: flex;
  position: relative;
`;

export const MainField = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  background: ${COLORS.mineShaft};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  & img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
`;

export const SearchInput = styled.input`
  height: 100%;
  width: 100%;
  padding: 4px 8px 4px 12px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  color: ${COLORS.white};
  border: none;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  line-height: 18px;
`;

export const DropdownMenu = styled.div<{ isDropdownActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: ${({ isDropdownActive }): string => (isDropdownActive ? '200px' : '0px')};
  position: absolute;
  z-index: 11;
  transition: max-height 0.3s;
  border-radius: 4px;
  margin-top: 42px;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
  }
`;

export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 42px;
  padding: 0 24px;
  cursor: pointer;

  &:hover {
    background: ${COLORS.turbo};

    span {
      color: ${COLORS.black};
    }
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 98%;
    text-align: left;
    margin-right: 10px;
  }
`;
