import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAppDispatch } from 'shared/hooks';
import React, { useLayoutEffect, useState } from 'react';
import { getCardPaymentIntent } from 'services/api/subscriptionsService';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { COLORS } from 'shared/constants/colors';
import Spinner from 'assets/images/spinner.svg';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const withElements =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  ({ ...props }): JSX.Element => {
    const dispatch = useAppDispatch();
    const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY!);
    const [clientSecret, setClientSecret] = useState('');

    useLayoutEffect((): void => {
      (async (): Promise<void> => {
        try {
          const { client_secret } = (await getCardPaymentIntent()).data;
          setClientSecret(client_secret);
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        }
      })();
    }, [dispatch]);

    const appearance: Appearance = {
      variables: {
        colorBackground: DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor,
        colorText: COLORS.white,
        colorPrimary: COLORS.turbo
      }
    };

    return (
      <>
        {!!clientSecret ? (
          <Elements stripe={stripe} options={{ clientSecret, appearance }}>
            <Component {...(props as P)} />
          </Elements>
        ) : (
          <div
            style={{
              minWidth: '300px',
              width: '100%',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center'
            }}
          >
            <img style={{ width: '50px', height: '50px' }} src={Spinner} alt='Loading' />
          </div>
        )}
      </>
    );
  };
