import { BaseLoadEnvironmentStrategy } from './base-load-environment.strategy';
import { LoadEnvironmentData, LoadEnvironmentResult } from 'shared/interfaces';

export class EmptyLoadEnvironmentStrategy extends BaseLoadEnvironmentStrategy {
  public async changeEnvironment({ environmentWrapper }: LoadEnvironmentData): Promise<LoadEnvironmentResult> {
    this.clearEnvironmentWrapper(environmentWrapper);

    return null;
  }
}
