import React, { JSX, useEffect } from 'react';
import * as Styled from './styles';
import { THREE_STATS_CONTAINER_ID } from 'shared/constants/html-elements-ids';

export const ThreeJSStats: React.FC = (): JSX.Element => {
  const [visible, setVisible] = React.useState<boolean>(false);

  useEffect((): void => {
    setVisible(!!process.env.REACT_APP_ENVIRONMENT?.includes('dev'));
  }, []);

  return (
    <Styled.StatsBlock id={THREE_STATS_CONTAINER_ID} visible={visible} />
  );
};
