import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  EModelAccessLevel,
  EQuotaName,
  ESnackbarStyle,
  Model,
  ModelPermissionsResponse,
  ModelPermissionsState,
  TeamPermissions,
  UserPermission
} from 'shared/types';
import { AppDispatch, RootState } from '../index';
import { startLoader, stopLoader } from './loaderReducer';
import {
  deleteAllPermissions,
  getModelPermissions,
  updateModelPublicAccessLevel,
  updateUserPermission
} from 'services/api/permissionsService';
import { setModelAccessLevel } from './viewerDataReducer';
import { checkQuota } from 'utils/model-utils';
import { openNotification } from 'utils/notification-utils';
import { API_REQUEST_CANCELED_CODE } from 'shared/constants/errors';

const initialState: ModelPermissionsState = {
  publicPermissions: [],
  permissions: [],
  teamPermissions: null,
  isPublishedModel: false,
  isArViewShared: false
};

// Actions

export const setModelPublicPermissions = createAction<EModelAccessLevel[]>(
  'modelPermissions/setModelPublicPermissions'
);
export const setModelTeamPermissions = createAction<TeamPermissions>(
  'modelPermissions/setModelTeamPermissions'
);
export const setModelPermissions = createAction<UserPermission[]>(
  'modelPermissions/setModelPermissions'
);
export const setIsPublishedModel = createAction<boolean>('modelPermissions/setIsPublishedModel');
export const setIsArViewShared = createAction<boolean>('modelPermissions/setIsArViewShared');
export const setInitialPermissionsState = createAction(
  'modelPermissions/setInitialPermissionsState'
);

// Reducer

export const modelPermissionsReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setModelPublicPermissions, (state, { payload }): void => {
      state.publicPermissions = payload;
    })
    .addCase(setModelTeamPermissions, (state, { payload }): void => {
      state.teamPermissions = payload;
    })
    .addCase(setModelPermissions, (state, { payload }): void => {
      state.permissions = payload;
    })
    .addCase(setIsPublishedModel, (state, { payload }): void => {
      state.isPublishedModel = payload;
    })
    .addCase(setIsArViewShared, (state, { payload }): void => {
      state.isArViewShared = payload;
    })
    .addCase(setInitialPermissionsState, (): ModelPermissionsState => initialState);
});

// Thunks

const checkQuotes = async (modelId: string, accessLevel: EModelAccessLevel[]): Promise<void> => {
  if (!!accessLevel.length) {
    const hasArViewAccess = accessLevel.includes(EModelAccessLevel.VIEW_AR_ACCESS);
    if (hasArViewAccess) {
      await checkQuota(modelId, EQuotaName.MONTHLY_AR_VIEWS_LIMIT);
    }
    await checkQuota(modelId, EQuotaName.MODEL_VIEWS_LIMIT);
  }
};

const handleApiResponse = (response: ModelPermissionsResponse, dispatch: AppDispatch): void => {
  const { accessLevel, publicAccessLevel, accessRules, teamAccessLevel } = response;
  dispatch(setModelAccessLevel(accessLevel));
  dispatch(setModelPublicPermissions(publicAccessLevel));
  dispatch(setModelTeamPermissions(teamAccessLevel));
  const modelPermissions = accessRules.items.sort((a, b): number =>
    a.createdAt < b.createdAt ? 1 : -1
  );
  dispatch(setModelPermissions(modelPermissions));
  dispatch(setIsPublishedModel(!!publicAccessLevel.length));
  const isArViewShared = publicAccessLevel.includes(EModelAccessLevel.VIEW_AR_ACCESS);
  dispatch(setIsArViewShared(isArViewShared));
};

export const fetchModelPermissions = createAsyncThunk<
  Promise<void>,
  { model: Model; signal?: AbortSignal },
  { dispatch: AppDispatch; state: RootState }
>(
  'modelPermissions/fetchModelPermissions',
  async ({ model, signal }, { dispatch }): Promise<void> => {
    dispatch(startLoader());
    try {
      const response = (await getModelPermissions(model.id, signal)).data;
      handleApiResponse(response, dispatch);
    } catch (e) {
      if (e.code === API_REQUEST_CANCELED_CODE) return;
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }
);

export const changePublicAccessLevel = createAsyncThunk<
  Promise<void>,
  { accessLevel: EModelAccessLevel[]; model: Model },
  { dispatch: AppDispatch; state: RootState }
>(
  'modelPermissions/changePublicAccessLevel',
  async ({ accessLevel, model }, { dispatch }): Promise<void> => {
    dispatch(startLoader());
    try {
      const response = (await updateModelPublicAccessLevel(model.id, accessLevel)).data;
      handleApiResponse(response, dispatch);
      await checkQuotes(model.id, accessLevel);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }
);

export const changeUserAccessLevel = createAsyncThunk<
  Promise<void>,
  { model: Model; recordId: number; accessLevel: EModelAccessLevel[] },
  { dispatch: AppDispatch; state: RootState }
>(
  'modelPermissions/changeUserAccessLevel',
  async ({ model, recordId, accessLevel }, { dispatch }): Promise<void> => {
    dispatch(startLoader());
    try {
      const response = (await updateUserPermission(model.id, recordId, accessLevel)).data;
      handleApiResponse(response, dispatch);
      await checkQuotes(model.id, accessLevel);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }
);

export const removeAllPermissions = createAsyncThunk<
  Promise<void>,
  { model: Model },
  { dispatch: AppDispatch }
>('modelPermissions/fetchModelPermissions', async ({ model }, { dispatch }): Promise<void> => {
  dispatch(startLoader());
  try {
    const response = (await deleteAllPermissions(model.id)).data;
    handleApiResponse(response, dispatch);
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
  } finally {
    dispatch(stopLoader());
  }
});
