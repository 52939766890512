import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import Close from 'assets/images/close.svg';
import Warning from 'assets/images/error.svg';
import moment from 'moment';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { ESnackbarStyle, TeamsState } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { deleteTeam, getTeamModelsCount } from 'services/api/teamService';
import { useHistory } from 'react-router-dom';
import { clearActiveTeam, fetchUserTeams } from 'services/store/reducers/teamsReducer';
import { TEAM_DELETED } from 'shared/constants/notifications';

type Props = {
  closeTab: () => void;
};

const DeleteTeam: React.FC<Props> = ({ closeTab }): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { activeTeam, teamPlan } = useAppSelector((store): TeamsState => store.teams);
  const [totalModelsCount, setTotalModelsCount] = useState(0);
  const subscriptionExpirationDate = moment(teamPlan?.currentPeriodEnd).format('MMMM D, YYYY');
  const finishTeamActivityDate = moment().add(30, 'days').format('MMMM D, YYYY');

  useEffect((): void => {
    const fetchTotalModelsCount = async (): Promise<void> => {
      const { modelsCount } = (await getTeamModelsCount(activeTeam!.id)).data;
      setTotalModelsCount(modelsCount);
    };
    fetchTotalModelsCount();
  }, [activeTeam]);

  const handleCancelButtonClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      if (!!activeTeam) {
        await deleteTeam(activeTeam.id);
        dispatch(fetchUserTeams());
        dispatch(clearActiveTeam());
        history.push('/my-workspace');
        openNotification(ESnackbarStyle.SUCCESS, TEAM_DELETED);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  return (
    <Styled.ContentContainer>
      <Styled.FormTitle>We’re sorry to see you go!</Styled.FormTitle>
      <Styled.FormSubtitle>
        {`Your monthly subscription is paid until ${subscriptionExpirationDate}. If you would like to proceed with
        cancelling your subscription, please select “Cancel Subscription” below.`}
      </Styled.FormSubtitle>
      <Styled.NotificationContainer>
        <Styled.NotificationIcon>
          <img src={Warning} alt='Warning' />
        </Styled.NotificationIcon>
        <Styled.Notification>
          <p>
            {`Are you sure? After ${finishTeamActivityDate} you will not have access to the ${totalModelsCount} ${
              activeTeam?.teamName || 'Team'
            } Models.`}
          </p>
          <p>Not ready to lose your models?</p>
          <p>{`Download them within your account before ${finishTeamActivityDate}.`}</p>
        </Styled.Notification>
      </Styled.NotificationContainer>
      <Styled.FormFooter>
        <Styled.FormButton className='undo-button' onClick={closeTab}>
          Undo
        </Styled.FormButton>
        <Styled.FormButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel Subscription
        </Styled.FormButton>
      </Styled.FormFooter>
      <Styled.CloseButton src={Close} alt='Close' onClick={closeTab} />
    </Styled.ContentContainer>
  );
};

export default DeleteTeam;
