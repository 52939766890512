import * as THREE from 'three';
import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import { CreateScreenshotData, CSInitializationData } from 'shared/interfaces';

export class SceneEnvironmentCiStrategy extends BaseSsStrategy {
  private prevEnvPosition: THREE.Vector3 = new THREE.Vector3();
  private prevEnvRotation: THREE.Euler = new THREE.Euler();
  private prevEnvScale: THREE.Vector3 = new THREE.Vector3();

  constructor(private initializationData: CSInitializationData) {
    super(initializationData);
  }

  private savePrevSettings(): void {
    this.prevEnvPosition.copy(this.mainScene.environmentWrapper.children[0].position);
    this.prevEnvRotation.copy(this.mainScene.environmentWrapper.children[0].rotation);
    this.prevEnvScale.copy(this.mainScene.environmentWrapper.children[0].scale);
  }

  private resetSettings(): void {
    this.mainScene.environmentWrapper.children[0].position.set(0, 0, 0);
    this.mainScene.environmentWrapper.children[0].rotation.set(0, 0, 0);
    this.mainScene.environmentWrapper.children[0].scale.set(1, 1, 1);
    this.mainScene.objectWrapper.visible = false;
  }

  private restorePrevSettings(): void {
    this.mainScene.environmentWrapper.children[0].position.copy(this.prevEnvPosition);
    this.mainScene.environmentWrapper.children[0].rotation.copy(this.prevEnvRotation);
    this.mainScene.environmentWrapper.children[0].scale.copy(this.prevEnvScale);

    this.mainScene.objectWrapper.visible = true;
  }

  public async createScreenshot({ cubemapController }: CreateScreenshotData): Promise<string> {
    const { screenshotRenderer, screenshotCamera, environmentWrapper, scene } = this.mainScene;

    this.setSize(88, 68);
    this.setupRenderer();

    const restoreCubemap = this.cheatCubemap(cubemapController);
    const envBB = new THREE.Box3();

    this.savePrevSettings();
    this.resetSettings();
    envBB.setFromObject(environmentWrapper);

    const sphere = envBB.getBoundingSphere(new THREE.Sphere());
    const cameraPosition = new THREE.Vector3().set(1, 1, 1).multiplyScalar(sphere.radius);

    this.setupCamera({
      position: cameraPosition,
      target: new THREE.Vector3()
    });

    screenshotRenderer.render(scene, screenshotCamera);
    this.restorePrevSettings();
    restoreCubemap();

    return screenshotRenderer.domElement.toDataURL('image/png');
  }
}
