import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const PageContent = styled.section`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SearchSelectContainer = styled.div`
  margin-left: auto;
  width: 240px;
  min-width: 240px;

  @media ${DEVICE.mobile} {
    margin-left: 0;
    margin-top: 10px;
  }
`;
