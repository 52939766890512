import React from 'react';
import * as Styled from './styles';
import SecureIcon from 'assets/images/ip-secure-icon.svg';

const ModelsSecure = (): JSX.Element => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalHeader>
        <Styled.ModalSubTitle>Intellectual Property Security</Styled.ModalSubTitle>
        <Styled.ModalTitle>Intellectual property security is our #1 priority</Styled.ModalTitle>
      </Styled.ModalHeader>
      <Styled.ModalContent>
        <Styled.RightSide>
          <Styled.SecureIcon src={SecureIcon} />
        </Styled.RightSide>
        <Styled.LeftSide>
          <p>
            We’ve built this tool for Agencies, Developers, Creatives and Freelancers, which is why
            we take the security of your intellectual property very seriously.
          </p>
          <p>
            Our platform is regularly used by global agencies to share sensitive IP with
            high-profile clients.
          </p>
          <p>
            Your 3D models are never shared or made accessible to anyone other than you and the
            people you choose to share with.
          </p>
        </Styled.LeftSide>
      </Styled.ModalContent>
    </Styled.ModalWindowContainer>
  );
};

export default ModelsSecure;
