export const TABLE_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sortKey: 'name',
    render: (name: string): JSX.Element => <span data-label={'Name'}>{name}</span>
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sortKey: 'email',
    render: (email: string): JSX.Element => <span data-label={'Email'}>{email}</span>
  },
  {
    title: 'Models',
    dataIndex: 'uploads',
    key: 'uploads',
    sortKey: 'uploads',
    render: (uploads: number): JSX.Element => <span data-label={'Models'}>{uploads}</span>
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    sortKey: 'plan',
    render: (plan: string): JSX.Element => <span data-label={'Plan'}>{plan}</span>
  },
  {
    title: 'Account Create',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortKey: 'createdAt',
    render: (createdAt: string): JSX.Element => (
      <span data-label={'Account Create'}>{createdAt}</span>
    )
  },
  {
    title: 'Last activity',
    dataIndex: 'lastActivity',
    key: 'lastActivity',
    sortKey: 'lastActivity',
    render: (lastActivity: string): JSX.Element => (
      <span data-label={'Last activity'}>{lastActivity}</span>
    )
  },
  {
    title: 'Account Status',
    dataIndex: 'status',
    key: 'status',
    sortKey: 'status',
    render: (status: string): JSX.Element => <span data-label={'Account Status'}>{status}</span>
  }
];
