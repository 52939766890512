import { COLORS } from 'shared/constants/colors';

export const CHART_OPTIONS = {
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: COLORS.mineShaft
      }
    },
    x: {
      grid: {
        color: COLORS.mineShaft
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  },
  ticks: {
    precision: 0
  }
};

export const BORDER_COLORS = [
  COLORS.turbo,
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)'
];
