import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { ArModeState, EQuotaName, ESnackbarStyle, Model } from 'shared/types';
import { startLoader, stopLoader } from './loaderReducer';
import { getModelPlanLimit, incrementArModeView } from 'services/api/subscriptionsService';
import { AppDispatch, RootState } from '..';
import { loadArScripts } from 'utils/ar-utils';
import { AR_VIEWS_LIMIT } from 'shared/constants/notifications';
import { openNotification } from 'utils/notification-utils';
import { showEmbeddedModelPrompt } from './modalReducer';
import { AR_VIEWS_LIMIT_ALERT } from 'shared/constants/styled-notifications';
import { QUICK_VIEW_MODEL_ID, SAMPLE_MODEL_ID } from 'shared/constants/model-settings';

const initialState: ArModeState = {
  isArMode: false,
  isArModeLoading: false
};

// Actions

export const startArMode = createAction('arMode/startArMode');
export const stopArMode = createAction('arMode/stopArMode');
export const setIsArModeLoading = createAction<boolean>('arMode/setIsArModeLoading');

// Reducer

export const arModeReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(startArMode, (state): void => {
      state.isArMode = true;
    })
    .addCase(stopArMode, (state): void => {
      state.isArMode = false;
    })
    .addCase(setIsArModeLoading, (state, { payload }): void => {
      state.isArModeLoading = payload;
    });
});

// Thunks

export const runArMode = createAsyncThunk<
  Promise<void>,
  { model: Model; isARModeLink?: boolean },
  { dispatch: AppDispatch; state: RootState }
>('arMode/runArMode', async ({ model, isARModeLink }, { dispatch, getState }): Promise<void> => {
  dispatch(startLoader());

  try {
    const isLoaded = await loadArScripts(dispatch);
    if (!isLoaded) return;

    const user = getState().auth.user;
    const { teams } = getState().teams;
    const { isEmbeddedModelMode } = getState().viewerData;
    const isSampleModel = model.id === SAMPLE_MODEL_ID;
    const isQuickView = model.id === QUICK_VIEW_MODEL_ID;
    const isModelOwner = model.ownerId === user?.id;
    const isTeamMember = teams.some((team): boolean => team.id === model.teamId);

    if (isSampleModel || isQuickView || isModelOwner || isTeamMember) {
      dispatch(startArMode());
    } else {
      const { success } = (await getModelPlanLimit(model.id, EQuotaName.MONTHLY_AR_VIEWS_LIMIT))
        .data;

      if (success) {
        if (!isARModeLink) await incrementArModeView(model.id);

        dispatch(startArMode());
      } else {
        if (isEmbeddedModelMode) {
          dispatch(showEmbeddedModelPrompt({ promptContent: AR_VIEWS_LIMIT_ALERT }));
          return;
        }

        openNotification(ESnackbarStyle.HOLD_UP, AR_VIEWS_LIMIT);
      }
    }
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.message);
  } finally {
    dispatch(stopLoader());
    dispatch(setIsArModeLoading(false));
  }
});
