import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './styles';
import CheckYellow from 'assets/images/check-yellow.svg';
import {
  AuthState,
  ESnackbarStyle,
  PlanPrice,
  SubscriptionProduct,
  SubscriptionsState,
  ViewerDataState
} from 'shared/types';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { withElements } from 'shared/hocs/withElements';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  checkPromoCode,
  payUserSubscription,
  fetchPlanPrice,
  getCurrentUserSubscription
} from 'services/api/subscriptionsService';
import { setCurrentSubscriptionPlan } from 'services/store/reducers/subscriptionsReducer';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { fetchUserFeatures } from 'services/store/reducers/authReducer';
import { CardForm } from 'shared/components';
import { SUBSCRIPTION_UPDATED } from 'shared/constants/notifications';
import { throttle } from 'utils/delay-utils';
import { fetchModelFeatureAccess } from 'services/store/reducers/modelFeatureAccessReducer';
import { checkIsStripeTestCardError } from 'utils/error-utils';

type Props = {
  plan: SubscriptionProduct;
  isMonthlyType: boolean;
};

const ModalCheckout: React.FC<Props> = ({ plan, isMonthlyType }): JSX.Element => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const { model } = useAppSelector((store): ViewerDataState => store.viewerData);
  const { currentUserCard, currentSubscriptionPlan } = useAppSelector(
    (store): SubscriptionsState => store.subscriptions
  );
  const [isMonth, setIsMonth] = useState(isMonthlyType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardFormActive, setIsCardFormActive] = useState<boolean>(false);
  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>('');
  const promoCodeValue = useRef<string>('');
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState<boolean>(false);
  const [isPromoCodeFieldActive, setIsPromoCodeFieldActive] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [isPriceCalculated, setIsPriceCalculated] = useState<boolean>(false);
  const isValidPromoCode = useRef<boolean>(false);
  const ONE_USD = 100;
  const LAST_4 = !!currentUserCard?.card
    ? currentUserCard?.card.last4
    : currentUserCard?.sepa_debit?.last4;
  const isCurrentPlanInterval =
    currentSubscriptionPlan?.recurringInterval === (isMonth ? 'month' : 'year');
  const isCurrentPlan = plan?.id === currentSubscriptionPlan?.productId && isCurrentPlanInterval;

  const priceObj = useMemo(
    (): PlanPrice | undefined =>
      plan.prices.find((i): boolean => i.recurring.interval === (isMonth ? 'month' : 'year')),
    [isMonth, plan]
  );

  const planPrice = (priceObj?.unit_amount || 0) / ONE_USD;

  const calculatePlanPrice = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    dispatch(startLoader());
    const promo = promoCodeValue.current;

    if (!!priceObj && !!currentUserCard) {
      try {
        if (isPromoCodeApplied && !!promo && !isValidPromoCode.current) {
          const { valid } = (await checkPromoCode(promo)).data;
          isValidPromoCode.current = valid;
          valid
            ? openNotification(ESnackbarStyle.SUCCESS, 'Promo code has been successfully accepted')
            : openNotification(ESnackbarStyle.ERROR, 'Incorrect promo code');
        }
        const price = priceObj.id;
        const card = currentUserCard.id;
        const payload =
          isPromoCodeApplied && isValidPromoCode.current ? { price, promo, card } : { price, card };
        const { amount_due, tax } = (await fetchPlanPrice(payload)).data;
        setTotalPrice(amount_due / ONE_USD);
        setTax(tax / ONE_USD);
        setIsPriceCalculated(true);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    }
    dispatch(stopLoader());
    setIsLoading(false);
  }, [currentUserCard, dispatch, isPromoCodeApplied, priceObj]);

  useEffect((): void => {
    calculatePlanPrice();
  }, [calculatePlanPrice]);

  const clearPromoCodeForm = (): void => {
    setPromoCodeInputValue('');
    promoCodeValue.current = '';
    isValidPromoCode.current = false;
    setIsPromoCodeApplied(false);
  };

  const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (isPromoCodeApplied) {
      clearPromoCodeForm();
    } else {
      setIsPromoCodeApplied(true);
    }
  };

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    if (isPromoCodeApplied && !value) {
      clearPromoCodeForm();
    } else {
      setPromoCodeInputValue(value);
      promoCodeValue.current = value;
    }
  };

  const handlePromoCodeButtonClick = (): void => {
    setIsPromoCodeFieldActive((prev): boolean => !prev);
  };

  const handleEditCardClick = (): void => {
    setIsCardFormActive((prevState): boolean => !prevState);
  };

  const getCardBrand = (): string => {
    if (!!currentUserCard?.card) {
      const { brand } = currentUserCard.card;
      return brand[0].toUpperCase() + brand.slice(1);
    } else {
      return 'Card';
    }
  };

  const handlePayButtonClick = async (): Promise<void> => {
    if (!!currentUserCard && !!stripe && !!elements) {
      setIsLoading(true);
      dispatch(startLoader());
      try {
        if (!!priceObj) {
          const promo = promoCodeValue.current;
          const card = currentUserCard.id;
          const price = priceObj.id;
          const payload =
            isPromoCodeApplied && isValidPromoCode.current
              ? { card, price, promo }
              : { card, price };
          const { payment_intent } = (await payUserSubscription(payload)).data;
          const isFail = payment_intent?.status === 'requires_payment_method';
          const isRequiresAction =
            payment_intent?.status === 'requires_action' ||
            payment_intent?.status === 'requires_confirmation';
          if (isFail) throw new Error('Requires payment method');

          if (!!payment_intent?.client_secret && isRequiresAction) {
            const { error } = await stripe.confirmCardPayment(payment_intent.client_secret, {
              payment_method: card
            });
            if (!!error) throw new Error(checkIsStripeTestCardError(error));
          }

          await throttle(5000);
          const currentUserSubscription = (await getCurrentUserSubscription()).data;
          dispatch(setCurrentSubscriptionPlan(currentUserSubscription));
          await dispatch(fetchUserFeatures());
          if (!!model) {
            await dispatch(fetchModelFeatureAccess({ model }));
          }
          dispatch(closeConfirmModal());
          openNotification(ESnackbarStyle.SUCCESS, SUBSCRIPTION_UPDATED);
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
        setIsLoading(false);
      }
    }
  };

  const { title, subtitle } = extractPlanData(plan);

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.BillingFrequencyBlock>
          <Styled.FieldLabel>Billing frequency</Styled.FieldLabel>
          <Styled.BillingFrequencyField onClick={(): void => setIsMonth(false)}>
            <Styled.Checkbox isActive={!isMonth}>
              {!isMonth && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>Yearly</span>
          </Styled.BillingFrequencyField>
          <Styled.BillingFrequencyField onClick={(): void => setIsMonth(true)}>
            <Styled.Checkbox isActive={isMonth}>
              {isMonth && <img src={CheckYellow} alt='Check' />}
            </Styled.Checkbox>
            <span>Monthly</span>
          </Styled.BillingFrequencyField>
        </Styled.BillingFrequencyBlock>
        <Styled.PaymentInfoBlock>
          <Styled.FieldLabel>Payment Info</Styled.FieldLabel>
          <Styled.PaymentInfoField>
            {!!currentUserCard ? (
              <>
                {currentUserCard.type === 'paypal' ? (
                  <Styled.CardInfo>Paypal</Styled.CardInfo>
                ) : (
                  <Styled.CardInfo>{`${getCardBrand()} ending in ${LAST_4}`}</Styled.CardInfo>
                )}
              </>
            ) : (
              <Styled.CardInfo>No saved card</Styled.CardInfo>
            )}
            <Styled.EditCardButton onClick={handleEditCardClick} id='edit-credit-card-button'>
              {!!currentUserCard ? 'Edit Credit Card' : 'Add card'}
            </Styled.EditCardButton>
          </Styled.PaymentInfoField>
        </Styled.PaymentInfoBlock>
        {isCardFormActive && (
          <Styled.CardFormContainer>
            <CardForm plan={plan} isMonthlyType={isMonth} isCheckout />
          </Styled.CardFormContainer>
        )}
      </Styled.LeftSide>
      <Styled.RightSide>
        <Styled.RightSideHeader>
          <Styled.FieldLabel>Order summary</Styled.FieldLabel>
          <Styled.PromoCodeButton onClick={handlePromoCodeButtonClick} id='add-promo-code-button'>
            + Add Promo Code
          </Styled.PromoCodeButton>
        </Styled.RightSideHeader>
        {isPromoCodeFieldActive && (
          <Styled.PromoCodeForm>
            <Styled.PromoCodeLabel>Promo code:</Styled.PromoCodeLabel>
            <Styled.PromoCodeField>
              <Styled.PromoCodeInput value={promoCodeInputValue} onChange={handlePromoCodeChange} />
              <Styled.ApplyButton onClick={handleApplyClick}>
                {isPromoCodeApplied ? 'Clear' : 'Apply'}
              </Styled.ApplyButton>
            </Styled.PromoCodeField>
          </Styled.PromoCodeForm>
        )}
        <Styled.PayBlock>
          <Styled.AccountPlan>
            <div>
              <div>Account Plan:</div>
              <div>{`${title} ${subtitle}`}</div>
            </div>
            <span>{`$${planPrice}`}</span>
          </Styled.AccountPlan>
          {isPriceCalculated && (
            <>
              <Styled.PriceField>
                <span>Tax</span>
                <span>{`$${tax}`}</span>
              </Styled.PriceField>
              <Styled.PriceField>
                <span>Total due today</span>
                <span>{`$${totalPrice}`}</span>
              </Styled.PriceField>
            </>
          )}
          <Styled.PayBlockFooter>
            <Styled.TermsConditions
              href={`${process.env.REACT_APP_URL}/terms-of-use?auth=${isAuth ? '1' : '0'}`}
              target='_blank'
              rel='noreferrer'
              id='term-of-use-button'
            >
              Terms of Use
            </Styled.TermsConditions>
            <Styled.PayButton
              onClick={handlePayButtonClick}
              disabled={isCurrentPlan || isLoading || !currentUserCard}
              id='pay-now-button'
            >
              Pay now
            </Styled.PayButton>
          </Styled.PayBlockFooter>
        </Styled.PayBlock>
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default withElements(ModalCheckout);
