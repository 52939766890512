import React from 'react';
import * as Styled from './styles';
import LogoCubeIcon from 'assets/images/logo-cube.png';

const ModalLogoFileLimits = (): JSX.Element => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalTitle>Custom Logo File Limits</Styled.ModalTitle>
      <Styled.LimitsList>
        <li>No more than 0.25MB</li>
        <li>No larger than 256x256</li>
      </Styled.LimitsList>
      <Styled.ModalFooter>
        <Styled.LogoCube src={LogoCubeIcon} />
      </Styled.ModalFooter>
    </Styled.ModalWindowContainer>
  );
};

export default ModalLogoFileLimits;
