import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const SettingsSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 440px;
  padding: 30px 20px;
  background-color: ${COLORS.mineShaft};
  border-radius: 7px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media ${DEVICE.mobile} {
    width: 100%;
    padding: 20px 10px;
  }
`;

export const SettingsLimits = styled.span`
  width: max-content;
  margin: 16px 0 0 auto;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  color: ${COLORS.white};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const SettingsSectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SettingsSectionTitle = styled.h2`
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const SettingsSectionTitleHint = styled.img`
  margin: 2px 0 0 8px;
  width: 12px;
  height: 12px;
`;

export const SectionTitleTooltip = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: ${COLORS.white};
  text-align: center;
`;

export const ThemeSettingsForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const SettingsField = styled.div`
  display: flex;
`;

export const FieldLabel = styled.label`
  margin-top: 7px;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${COLORS.white};
  width: 60px;
  min-width: 60px;
  text-align: right;
`;

export const FieldInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 52px;
  width: 100%;
  max-width: calc(100% - 70px);
  margin-left: 10px;

  &.upload-logo-field {
    height: 30px;
  }
`;

export const FieldInput = styled.input`
  height: 30px;
  width: 100%;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  padding: 0 28px 0 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;

  &.select-input {
    padding: 0 28px 0 12px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 3px;
  background: transparent;

  img {
    width: 12px;
    height: 12px;
  }
`;

export const EditButton = styled.button<{ isNewThemeField: boolean }>`
  position: absolute;
  right: ${({ isNewThemeField }): string => (isNewThemeField ? '30px' : '52px')};
  top: 3px;
  background: transparent;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const RemoveButton = styled.button<{ isInputActive?: boolean }>`
  position: absolute;
  right: ${({ isInputActive }): string => (isInputActive ? '8px' : '30px')};
  top: 3px;
  background: transparent;

  &.remove-logo {
    right: 8px;
  }

  img {
    width: 15px;
    height: 15px;
  }
`;

export const UploadLogoFieldContainer = styled.label`
  display: flex;
  width: 100%;
  height: 30px;
  cursor: pointer;
`;

export const UploadLogoButtonContainer = styled.div`
  min-width: 60px;
  display: flex;
  justify-content: flex-end;
`;

export const UploadLogoButton = styled.div`
  margin-top: 4px;
  width: 21px;
  height: 21px;
  cursor: pointer;

  input {
    display: none;
  }

  img {
    width: 21px;
    height: 21px;
  }
`;

export const FileNameField = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  background: ${COLORS.black};
  padding: 0 30px 0 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;

  span {
    color: #8d8d8d;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ColorPaletteField = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;
`;

export const PaletteFieldLabel = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${COLORS.white};
  width: 60px;
  min-width: 60px;
  text-align: right;
`;

export const ColorSettingsList = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;

  @media ${DEVICE.mobile} {
    margin-left: 20px;
  }
`;

export const CustomColorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:first-child) {
    margin-left: 26px;
  }

  @media ${DEVICE.mobile} {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export const CustomColorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  @media ${DEVICE.mobile} {
    width: 28px;
    height: 28px;
  }
`;

export const CustomColor = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;

  @media ${DEVICE.mobile} {
    width: 22px;
    height: 22px;
  }
`;

export const CustomColorTitle = styled.span`
  margin-top: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  color: ${COLORS.white};
  text-align: center;

  @media ${DEVICE.mobile} {
    font-size: 10px;
    line-height: 11px;
  }
`;

export const ColorPickerContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 10;
  background: #101010;
  border: 1px solid #434343;
  border-radius: 4px;
  padding: 4px 4px 10px;

  .react-colorful {
    width: 190px;
    height: max-content;
    cursor: pointer;
  }

  .react-colorful__saturation {
    width: 190px;
    height: 150px;
    max-height: 150px;
    border-radius: 4px;
  }

  .react-colorful__last-control {
    margin-top: 5px;
    border-radius: 4px;
  }

  .react-colorful__alpha,
  .react-colorful__hue {
    height: 20px;
  }

  .react-colorful__pointer {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  @media ${DEVICE.mobile} {
    &.header-color {
      transform: translateX(-20%);
    }

    &.text-color {
      transform: translateX(-45%);
    }

    &.buttons-color {
      transform: translateX(-45%);
    }

    &.button-text-color {
      transform: translateX(-65%);
    }
  }
`;

export const ResultColorField = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`;

export const ResultColorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 114px;
  height: 32px;
  background-color: ${COLORS.mineShaft};
  border-radius: 4px;
  padding: 2px 4px;
`;

export const ResultColor = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 4px;
`;

export const ColorHexInput = styled.input`
  width: 60px;
  height: 26px;
  margin-left: 10px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: none;
  border-radius: 4px;
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
`;

export const ColorHexInputContainer = styled.div<{ isHexCodeError: boolean }>`
  display: flex;
  flex-direction: column;

  .bottom-line {
    margin-left: 10px;
    width: 60px;
    height: 1px;
    background-color: ${({ isHexCodeError }): string =>
      isHexCodeError ? COLORS.red : COLORS.white};
    transition: all 0.3s;
    transform: scaleX(0);
  }

  &:hover {
    .bottom-line {
      transform: scaleX(1);
    }
  }

  & input:focus + .bottom-line {
    transform: scaleX(1);
  }
`;

export const HexError = styled.img`
  width: 20px;
  height: 20px;
  margin: 4px 0 0 10px;
`;

export const DefaultColorsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
`;

export const DefaultColorsLabel = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: ${COLORS.white};
`;

export const DefaultColorsList = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

export const DefaultColor = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 4px;
  cursor: pointer;
`;

export const SaveButton = styled.button`
  margin: 0 0 0 auto;
  width: 118px;
  height: 33px;
  background-color: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: ${COLORS.black};
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.gorse};
  }
`;
