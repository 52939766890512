import React, { ReactNode, useRef, useContext, MutableRefObject } from 'react';

type Props = {
  children: ReactNode;
};

type ContextState = {
  pageTop: MutableRefObject<HTMLDivElement | null> | null;
};

const initialContextState: ContextState = {
  pageTop: null
};

export const PageTopPositionContext = React.createContext<ContextState>(initialContextState);
export const usePageTopPositionContext = (): ContextState => useContext(PageTopPositionContext);

export const PageTopPositionProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const pageTop = useRef<HTMLDivElement | null>(null);

  return (
    <PageTopPositionContext.Provider value={{ pageTop }}>
      {children}
    </PageTopPositionContext.Provider>
  );
};
