import React from 'react';

const LoginIcon = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 42 42'
        preserveAspectRatio='xMaxYMax meet'
      >
        <g>
          <circle cx='22' cy='21' r='18'></circle>
        </g>
        <path
          fill='currentColor'
          d='M19.012 17.738a.86.86 0 000 1.224l1.663 1.663h-8.05a.878.878 0 00-.875.875c0 .481.394.875.875.875h8.05l-1.663 1.663a.86.86 0 000 1.224.861.861 0 001.226 0l3.14-3.14a.871.871 0 000-1.235l-3.14-3.15a.86.86 0 00-1.226 0zm8.488 9.887h-6.125a.878.878 0 00-.875.875c0 .481.394.875.875.875H27.5c.962 0 1.75-.788 1.75-1.75v-12.25c0-.963-.788-1.75-1.75-1.75h-6.125a.878.878 0 00-.875.875c0 .481.394.875.875.875H27.5v12.25z'
        ></path>
        <defs>
          <filter
            id='filter0_d_2389_700'
            width='44'
            height='44'
            x='0'
            y='0'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1'></feOffset>
            <feGaussianBlur stdDeviation='2'></feGaussianBlur>
            <feComposite operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'></feColorMatrix>
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_2389_700'></feBlend>
            <feBlend in='SourceGraphic' in2='effect1_dropShadow_2389_700' result='shape'></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default LoginIcon;
