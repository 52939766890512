import { CustomThemeColors, ThemeCSSVariables } from 'shared/types';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const setThemeCSSVariables = ({
  buttonBackgroundColor,
  buttonTextColor,
  headerBackgroundColor,
  sidebarBackgroundColor,
  footerBackgroundColor,
  textColor,
  themeMainColor,
  themeSecondaryColor,
  pageBackgroundColor
}: ThemeCSSVariables): void => {
  document.documentElement.style.setProperty('--button-background-color', buttonBackgroundColor);
  document.documentElement.style.setProperty('--button-text-color', buttonTextColor);
  document.documentElement.style.setProperty('--header-background-color', headerBackgroundColor);
  document.documentElement.style.setProperty('--sidebar-background-color', sidebarBackgroundColor);
  document.documentElement.style.setProperty('--footer-background-color', footerBackgroundColor);
  document.documentElement.style.setProperty('--text-color', textColor);
  document.documentElement.style.setProperty('--theme-main-color', themeMainColor);
  document.documentElement.style.setProperty('--theme-secondary-color', themeSecondaryColor);
  document.documentElement.style.setProperty('--page-background-color', pageBackgroundColor);
};

export const convertBrandColorsToCSSVariables = ({
  buttonBackgroundColor,
  buttonTextColor,
  normalTextColor,
  headerTextColor
}: CustomThemeColors): void => {
  setThemeCSSVariables({
    ...DEFAULT_THEME_CSS_VARIABLES,
    buttonBackgroundColor,
    buttonTextColor,
    headerBackgroundColor: headerTextColor,
    sidebarBackgroundColor: headerTextColor,
    footerBackgroundColor: headerTextColor,
    textColor: normalTextColor,
    themeMainColor: headerTextColor,
    themeSecondaryColor: buttonBackgroundColor,
    pageBackgroundColor: headerTextColor
  });
};
