import React, { JSX, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './styles';
import GlbeeLogo from 'assets/images/logo.png';
import Plus from 'assets/images/plus-icon-black.svg';
import ModelLink from 'assets/images/new-tab-icon.svg';
import {
  CameraData,
  ELoadingState,
  ESnackbarStyle,
  EModelType,
  Model,
  SelectOption
} from 'shared/types';
import {
  useAppDispatch,
  useAppSelector,
  useBrowserStore,
  useFeatureAccess,
  useHandleClickOutside,
  useResizeListener,
  useScreenshot,
  useUploadFile
} from 'shared/hooks';
import { setIsViewerLoading, setLoadingState } from 'services/store/reducers/loaderReducer';
import { runArMode, setIsArModeLoading, stopArMode } from 'services/store/reducers/arModeReducer';
import { showModal } from 'services/store/reducers/modalReducer';
import { setIsPageWithScene } from 'services/store/reducers/sidebarReducer';
import { RootState } from 'services/store';
import {
  setCubeOCVisibility,
  setIsSidebarHidden,
  setIsViewerPage,
  setModelAnimations,
  startZenMode,
  stopZenMode
} from 'services/store/reducers/viewerDataReducer';
import {
  CommentsBar,
  CustomTooltip,
  FeaturesBlock,
  ModalLogin,
  SaveModelBlock,
  SharePanel,
  SidebarModel,
  ThreeJSStats,
  ViewController
} from 'shared/components';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { check8thWallCompatibility } from 'utils/ar-utils';
import { NEED_TO_LOGIN, NO_ACCESS_AR, RESTRICT_AR_VIEWING } from 'shared/constants/notifications';
import { setIsCommentsVisible } from 'services/store/reducers/commentsReducer';
import { elements, openNotification } from 'utils/notification-utils';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { openMessage } from 'utils/message-utils';
import { ViewerFooter, ScreenshotMenu } from './components';
import { openAlert } from 'utils/alert-utils';
import { CubeOrbitController } from 'shared/components/cube-orbit-controller';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { CubeOrbitControllerScene, MainScene } from 'shared/webgl/scenes';
import { checkIsIFrame } from 'utils/helper-utils';
import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';
import ModalUpgradeAccount from '../modals/modal-upgrade-account/ModalUpgradeAccount';

type Props = {
  model: Model;
  modelFileType: E3DModelFileTypes;
  modelMtls: string[] | undefined;
  isUploadMode?: boolean;
  isArModeClickAction?: boolean;
};

const ModelViewer: React.FC<Props> = ({
  model,
  modelFileType,
  modelMtls,
  isUploadMode,
  isArModeClickAction
}): JSX.Element => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isEmbeddedModelComments = params.get('comments');
  const isEmbeddedModelCubeOC = params.get('cubeoc');
  const cubeOrbitControllerBlockRef = useRef<HTMLDivElement>(null);
  const mainSceneRef = useRef<MainScene | null>(null);
  const cubeOCSceneRef = useRef<CubeOrbitControllerScene | null>(null);
  const createScreenshotFnRef = useRef<((type: EScreenshotTypes) => Promise<string>) | null>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { ref: viewerRef, size: viewerSize } = useResizeListener();
  const { ref: footerRef, size: footerSize, targetElement } = useResizeListener();
  const isMobile = useMemo((): boolean => window.innerWidth < DEVICE_SIZES.tablet, []);
  const store = useAppSelector((store): RootState => store);
  const { isArMode, isArModeLoading } = store.arMode;
  const { isCubeOCVisible, isSidebarHidden } = store.viewerData;
  const { isAuth, user } = store.auth;
  const { isZenMode, modelType, isSceneCompletelyLoaded, isEmbeddedModelMode, isModelInitialized } =
    store.viewerData;
  const { isPageWithScene } = store.sidebar;
  const { hasArViewAccess } = store.modelFeatureAccess;
  const { isCommentsBarActive } = store.comments;
  const { activeBranding } = store.branding;
  const [isRecenterAction, setIsRecenterAction] = useState<boolean>(false);
  const [isSharePanelActive, setIsSharePanelActive] = useState<boolean>(false);
  const [isScreenshotMenuActive, setIsScreenshotMenuActive] = useState<boolean>(false);
  const isModelInit = useRef<boolean>(false);
  const getCameraData = useRef<(() => Promise<CameraData | undefined>) | null>(null);
  const isQuickView = modelType === EModelType.QUICK_VIEW;
  const isSampleModel = modelType === EModelType.SAMPLE;
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;
  const isModelOwner = model.ownerId === user?.id;
  const isARModeLink = useRef<boolean>(!!isArModeClickAction);
  const { removeFilesFromBrowserStore } = useBrowserStore();
  const { checkArViewAccess } = useFeatureAccess(model);
  const { brandLogoUrl } = activeBranding;
  const { uploadModelToViewer } = useUploadFile();
  const { setIsSceneFullyLoaded, downloadScreenshot } = useScreenshot(
    getCameraData.current,
    createScreenshotFnRef.current
  );
  const [isColumnFooterButtonsView, setIsColumnFooterButtonsView] = useState<boolean>(false);
  const [isShareARTabActive, setIsShareARTabActive] = useState<boolean>(false);

  useEffect((): (() => void) => {
    const handleKeyClick = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') dispatch(stopZenMode());
    };
    document.addEventListener('keyup', handleKeyClick);

    return (): void => {
      document.removeEventListener('keyup', handleKeyClick);
    };
  }, [dispatch]);

  useEffect((): void => {
    const footerPadding = 40;
    if (isEmbeddedModelMode) {
      if (!!targetElement) {
        const elements = Array.from(targetElement.children);
        let footerWidth = 0;
        for (const element of elements) {
          footerWidth += element.clientWidth;
        }
        setIsColumnFooterButtonsView(footerWidth > viewerSize.width - footerPadding);
      }
    }
  }, [targetElement, isEmbeddedModelMode, viewerSize, footerSize]);

  useEffect((): void => {
    if (!!+(isEmbeddedModelComments || '')) {
      dispatch(setIsCommentsVisible(true));
    }
  }, [dispatch, isEmbeddedModelComments]);

  useEffect((): void => {
    if (!+(isEmbeddedModelCubeOC || '') && isEmbeddedModelMode) {
      dispatch(setCubeOCVisibility(false));
    }
  }, [dispatch, isEmbeddedModelCubeOC, isEmbeddedModelMode]);

  useEffect((): void => {
    dispatch(setIsViewerLoading(true));
    dispatch(setIsPageWithScene(true));
    dispatch(setIsViewerPage(true));
  }, [dispatch]);

  useEffect((): (() => void) => {
    return (): void => {
      removeFilesFromBrowserStore();
      clearModelLocalStorage();
      dispatch(setIsViewerLoading(false));
    };
  }, [dispatch, removeFilesFromBrowserStore]);

  useEffect((): (() => void) => {
    if (isZenMode || isQuickView || isSampleModel || isEmbeddedModelMode) {
      const antNotifications = elements('ant-notification');

      if (!!antNotifications.length) {
        for (const element of antNotifications) {
          element.classList.add('full-viewer-width');
          if (isZenMode || isEmbeddedModelMode) {
            element.classList.add('zen-mode');
          }
        }
      }
    }

    return (): void => {
      const antNotifications = elements('ant-notification');
      if (!!antNotifications.length) {
        for (const element of antNotifications) {
          element.classList.remove('full-viewer-width');
          element.classList.remove('zen-mode');
        }
      }
    };
  }, [isEmbeddedModelMode, isQuickView, isSampleModel, isZenMode, model]);

  useEffect((): void => {
    if (isMobile) {
      switch (true) {
        case isScreenshotMenuActive:
          dispatch(setIsSidebarHidden(true));
          setIsSharePanelActive(false);
          break;
        case isSharePanelActive:
          dispatch(setIsSidebarHidden(true));
          setIsScreenshotMenuActive(false);
          break;
        case !isSidebarHidden:
          setIsSharePanelActive(false);
          setIsScreenshotMenuActive(false);
          break;
      }
    }
  }, [dispatch, isMobile, isScreenshotMenuActive, isSharePanelActive, isSidebarHidden]);

  useEffect((): void => {
    if (isQuickView) {
      openMessage(
        <Styled.QuickViewModeMessage>
          <span className='highlighted-text'>Quick view</span> mode now active! Models will{' '}
          <span className='highlighted-text'>not be saved</span> to your account.{' '}
          <span className='highlighted-text'>Drag & drop</span> files into the window to instantly
          view a new model.
        </Styled.QuickViewModeMessage>,
        5
      );
    }
  }, [isQuickView]);

  useEffect((): void => {
    if (isZenMode) {
      dispatch(setIsCommentsVisible(false));
    }
  }, [dispatch, isZenMode]);

  useEffect((): void => {
    if (isModelInitialized) {
      handleIsModelInit();
    }
  }, [isModelInitialized]);

  useEffect((): void => {
    setIsSceneFullyLoaded(isSceneCompletelyLoaded);
  }, [isSceneCompletelyLoaded, setIsSceneFullyLoaded]);

  const setAnimations = async (animations: string[]): Promise<void> => {
    const animationsList = animations.reduce(
      (acc: SelectOption[], item: string, index): SelectOption[] => [
        ...acc,
        { title: item, value: index }
      ],
      [{ title: 'No animations', value: -1 }]
    );
    dispatch(setModelAnimations(animationsList));
  };

  const handleArButtonClick = async (): Promise<void> => {
    if (isArMode) {
      if (isARModeLink.current) {
        history.push(`/${model.shortCode}`);
        isARModeLink.current = false;
      }
      dispatch(stopArMode());
      return;
    }

    const hasArViewAccess = await checkArViewAccess();

    if (hasArViewAccess) {
      dispatch(setIsArModeLoading(true));
      const isDeviceBrowserCompatible = await check8thWallCompatibility();
      if (!isDeviceBrowserCompatible) {
        if (isEmbeddedModelMode) {
          setIsShareARTabActive(true);
          setIsSharePanelActive(true);
        }
        openNotification(ESnackbarStyle.HOLD_UP, 'Your device does not support AR mode');
        dispatch(setIsArModeLoading(false));
        return;
      }
      await dispatch(runArMode({ model }));
    } else {
      isModelOwner ? dispatch(showModal(<ModalUpgradeAccount />)) : openAlert(NO_ACCESS_AR);
    }
  };

  const handleIsModelInit = async (): Promise<void> => {
    dispatch(setLoadingState(ELoadingState.LOADED));

    if (!isModelInit.current) {
      if (!checkIsIFrame()) {
        localStorage.removeItem('blobUrls');
        localStorage.removeItem('fileUrls');
      }

      if (isArModeClickAction) {
        const hasArViewAccess = await checkArViewAccess();
        const isDeviceBrowserCompatible = await check8thWallCompatibility();

        if (hasArViewAccess) {
          if (isDeviceBrowserCompatible && !!model) {
            await dispatch(runArMode({ model, isARModeLink: true }));
          } else {
            openNotification(ESnackbarStyle.HOLD_UP, 'Your device does not support AR mode');
          }
        } else {
          if (!isAuth) {
            openNotification(ESnackbarStyle.HOLD_UP, NEED_TO_LOGIN);
            dispatch(showModal(<ModalLogin />));
          } else {
            openAlert(RESTRICT_AR_VIEWING);
          }
        }
      }

      isModelInit.current = true;
    }
    setTimeout((): void => {
      dispatch(setIsViewerLoading(false));
    }, 500);
  };

  const setInitialCameraPosition = (): void => {
    isArMode ? window.XR8?.XrController?.recenter() : setIsRecenterAction(true);
  };

  const handleZenModeClick = (): void => {
    if (!isZenMode) {
      setIsSharePanelActive(false);
    }
    dispatch(isZenMode ? stopZenMode() : startZenMode());
  };

  const handleShareButtonClick = (): void => {
    if (!isZenMode) {
      setIsSharePanelActive((prev): boolean => !prev);
    }
  };

  const handleScreenshotClick = (): void => {
    setIsScreenshotMenuActive((prev): boolean => !prev);
  };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    if (modelType === EModelType.QUICK_VIEW) {
      await uploadModelToViewer(files, EModelType.QUICK_VIEW);
    }
  };

  const hideSharePanel = (): void => {
    if ((isMobile || isEmbeddedModelMode) && isSharePanelActive) {
      setIsSharePanelActive(false);
      setIsShareARTabActive(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  const ref = useHandleClickOutside(hideSharePanel);

  return (
    <section ref={viewerRef}>
      <section {...getRootProps()} style={{ height: '100%' }}>
        <input {...getInputProps()} />
        {isPageWithScene && (
          <>
            {isQuickView && (
              <Styled.ViewerOverlay isDragActive={isDragActive}>
                <Styled.PlusIcon src={Plus} alt='Upload model' />
                <Styled.UploadDescription>
                  Release the file to upload it to the quick viewer
                </Styled.UploadDescription>
              </Styled.ViewerOverlay>
            )}

            {isCubeOCVisible && (
              <Styled.CubeOCContainer
                isArMode={isArMode}
                isSidebarHidden={isEmbeddedModelMode || isSidebarHidden}
              >
                <CubeOrbitController
                  cubeOrbitControllerBlockRef={cubeOrbitControllerBlockRef}
                  isEmbeddedModelMode={isEmbeddedModelMode}
                />
              </Styled.CubeOCContainer>
            )}

            {!isEmbeddedModelMode && (
              <>
                <Styled.SidebarContainer isArMode={isArMode} isSidebarHidden={isSidebarHidden}>
                  <SidebarModel model={model} />
                </Styled.SidebarContainer>

                {isCommentsBarActive && !isZenMode && !isQuickView && (
                  <Styled.CommentsBarContainer>
                    <CommentsBar modelId={model.id} />
                  </Styled.CommentsBarContainer>
                )}
                {!isZenMode && !isArMode && !isQuickView && (
                  <Styled.FeaturesBlockContainer>
                    <FeaturesBlock />
                  </Styled.FeaturesBlockContainer>
                )}
              </>
            )}

            {!isZenMode && !isQuickView && !isAnonymousModel && (
              <Styled.ShareModelContainer
                ref={ref}
                isSharePanelActive={isSharePanelActive}
                isEmbeddedModelMode={isEmbeddedModelMode}
                footerHeight={footerSize.height}
              >
                <SharePanel
                  model={model}
                  isSharePanelActive={isSharePanelActive}
                  mainScene={mainSceneRef.current}
                  cubeOCScene={cubeOCSceneRef.current}
                  isARTabActive={isShareARTabActive}
                  setIsShareARTabActive={setIsShareARTabActive}
                />
              </Styled.ShareModelContainer>
            )}

            <Styled.ThreeJSStatsContainer
              isSharePanelActive={isSharePanelActive}
              isEmbeddedModelMode={isEmbeddedModelMode}
            >
              <ThreeJSStats />
            </Styled.ThreeJSStatsContainer>

            <Styled.ViewerFooterContainer
              isArMode={isArMode}
              isEmbeddedModelMode={isEmbeddedModelMode}
              isColumn={isColumnFooterButtonsView}
            >
              {isScreenshotMenuActive && (
                <ScreenshotMenu
                  downloadScreenshot={downloadScreenshot}
                  setIsScreenshotMenuActive={setIsScreenshotMenuActive}
                />
              )}
              <ViewerFooter
                footerRef={footerRef}
                modelId={model.id}
                setInitialCameraPosition={setInitialCameraPosition}
                handleZenModeClick={handleZenModeClick}
                handleArButtonClick={handleArButtonClick}
                handleShareButtonClick={handleShareButtonClick}
                handleScreenshotClick={handleScreenshotClick}
                isZenMode={isZenMode}
                hasArViewAccess={hasArViewAccess}
                isArMode={isArMode}
                isArModeLoading={isArModeLoading}
                isSharePanelActive={isSharePanelActive}
                isScreenshotMenuActive={isScreenshotMenuActive}
                modelType={modelType}
                isEmbeddedModelMode={isEmbeddedModelMode}
                isColumn={isColumnFooterButtonsView}
              />
            </Styled.ViewerFooterContainer>

            {isZenMode && (
              <Styled.AppLogo>
                <NavLink to={'/'}>
                  <img src={brandLogoUrl || GlbeeLogo} alt='Glbee Logo' />
                </NavLink>
              </Styled.AppLogo>
            )}
            {isEmbeddedModelMode && (
              <>
                <Styled.EmbeddedModelAppLogo href={process.env.REACT_APP_URL} target='_blank'>
                  <img src={brandLogoUrl || GlbeeLogo} alt='Glbee Logo' />
                </Styled.EmbeddedModelAppLogo>
                <Styled.EmbeddedModelLink href={model.shortLinkUrl} target='_blank'>
                  <img src={ModelLink} alt='Model Link' />
                </Styled.EmbeddedModelLink>
              </>
            )}
            {isQuickView && isAuth && !isZenMode && (
              <Styled.SaveModelBlock>
                <Styled.SaveModelLabel>
                  <span>Quick View mode enabled</span>
                  <CustomTooltip
                    content='Use Quick View to view a model without auto-saving it to your account'
                    placement={'top'}
                    showArrow={false}
                  >
                    <Styled.QuickViewHint>?</Styled.QuickViewHint>
                  </CustomTooltip>
                </Styled.SaveModelLabel>
                <SaveModelBlock />
              </Styled.SaveModelBlock>
            )}
            <ViewController
              modelLink={model.publicUrl}
              modelFileType={modelFileType}
              modelMtls={modelMtls}
              modelContent={model.content}
              cubeOrbitControllerBlockRef={cubeOrbitControllerBlockRef}
              setScenesRefs={(
                mainScene: MainScene,
                cubeOCScene: CubeOrbitControllerScene
              ): void => {
                mainSceneRef.current = mainScene;
                cubeOCSceneRef.current = cubeOCScene;
              }}
              isUploadMode={!!isUploadMode}
              isRecenterAction={isRecenterAction}
              setAnimations={setAnimations}
              setIsRecenterAction={setIsRecenterAction}
              setCameraDataCallback={(callback: () => Promise<CameraData | undefined>): void => {
                getCameraData.current = callback;
              }}
              setCreateScreenshotFn={(
                callback: (type: EScreenshotTypes) => Promise<string>
              ): void => {
                createScreenshotFnRef.current = callback;
              }}
            />
          </>
        )}
      </section>
    </section>
  );
};

export default ModelViewer;
