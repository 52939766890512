import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { DEVICE, DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 0 200px;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PageTitle = styled.h1`
  font-size: 30px;
  font-weight: 500;
  color: ${COLORS.white};
  margin: 30px 0 0;
  text-align: center;
`;

export const PageContent = styled.main`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
  width: 100%;
  max-width: 1620px;
`;

export const SubscriptionsContainer = styled.section`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
  }
`;

export const GetStartedSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 30px;

  @media ${DEVICE.tabletLarge} {
    flex-direction: column;
    justify-content: initial;
  }

  @media ${DEVICE.tablet} {
    padding: 50px 20px;
  }
`;

export const GetStartedLeftSide = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
  }

  @media ${DEVICE.mobile} {
    padding: 0;
  }
`;

export const LeftSideContent = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.h1`
  font-size: 26px;
  line-height: 28px;
  font-weight: 500;
  color: ${COLORS.white};
  margin-bottom: 0;

  @media ${DEVICE.mobile} {
    font-size: 22px;
    line-height: 22px;
  }
`;

export const FormSubtitle = styled.h6`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${COLORS.white};
  margin: 20px 0 0;

  @media ${DEVICE.mobile} {
    font-size: 12px;
    line-height: 13px;
  }
`;

export const GetStartedForm = styled.form`
  margin-top: 20px;
  display: flex;
`;

export const FormInput = styled.input`
  width: 100%;
  max-width: 400px;
  height: 44px;
  padding: 0 16px;
  color: ${COLORS.white};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: 1px solid #8b8b8b;
  border-radius: 4px;

  &::-webkit-input-placeholder {
    font-style: italic;
  }

  &::-moz-placeholder {
    font-style: italic;
  }
`;

export const SubmitButton = styled.button`
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  width: 130px;
  min-width: 130px;
  height: 44px;
  border-radius: 4px;
  transform: translateX(-5px);

  &:hover {
    background: ${COLORS.gorse};
  }

  @media ${DEVICE.mobile} {
    width: 100px;
    min-width: 100px;
  }
`;

export const GetStartedRightSide = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    margin-top: 30px;
  }
`;

export const VideoContainer = styled.div`
  height: 335px;
  width: 600px;
  border-radius: 28px;
  overflow: hidden;

  & > iframe {
    border: none;
  }

  @media ${DEVICE.desktop} {
    width: 400px;
    height: 220px;
    border-radius: 14px;
  }

  @media ${DEVICE.tabletLarge} {
    width: 500px;
    height: 280px;
  }

  @media ${DEVICE.mobile} {
    width: 100%;
    height: 240px;
  }
`;

export const TestimonialsSection = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  padding: 50px 30px;
  background: #141414;

  @media ${DEVICE.mobile} {
    padding: 50px 20px;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;

  @media ${DEVICE.mobile} {
    margin-top: 20px;
  }
`;

export const Slider = styled.div`
  display: flex;
  width: 258px;
  overflow: hidden;

  @media (min-width: ${DEVICE_SIZES.desktop}px) {
    width: 546px;
  }

  @media (min-width: ${DEVICE_SIZES.desktopLarge}px) {
    width: 840px;
  }
`;

export const Slide = styled.div<{ activeSlide: number }>`
  display: flex;
  min-width: 258px;
  transition: all 1s;
  transform: translateX(
    ${({ activeSlide }): string => `calc(-${activeSlide * 100}% - ${30 * (activeSlide + 1)}px)`}
  );
  margin-left: 30px;
  z-index: 2;

  @media (min-width: ${DEVICE_SIZES.desktop}px) {
    min-width: 546px;
  }

  @media (min-width: ${DEVICE_SIZES.desktopLarge}px) {
    min-width: 840px;
  }
`;

export const SliderFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  transform: translateX(70px);

  @media ${DEVICE.mobile} {
    margin-top: 20px;
    justify-content: center;
    transform: translateX(0);
  }
`;

export const NavigationDots = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0 20px;
`;

export const Dot = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  background: ${({ isActive }): string => (isActive ? COLORS.turbo : COLORS.doveGray)};
  border-radius: 50%;
  transition: all 1s;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

export const ControllersContainer = styled.div`
  display: flex;
`;

export const SliderController = styled.div`
  width: 22px;
  height: 22px;
  background: ${COLORS.turbo};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  img {
    width: 8px;
    height: 8px;
  }

  &.arrow-left > img,
  &.arrow-left-mobile > img {
    transform: rotate(180deg);
  }

  &.arrow-left-mobile {
    display: none;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }

  &:hover {
    background: ${COLORS.gorse};
  }

  @media ${DEVICE.mobile} {
    &.arrow-left-mobile {
      display: flex;
    }

    &.arrow-left {
      display: none;
    }

    &:not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const TestimonialCard = styled.div`
  width: 258px;
  min-height: 340px;
  border-radius: 7px;
  position: relative;
  backdrop-filter: blur(20.5px);

  &:not(:first-child) {
    margin-left: 30px;
  }
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  background: #090909;
  border-bottom: 4px solid ${COLORS.turbo};
  border-radius: 7px 7px 0 0;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: ${COLORS.white};
`;

export const LogoWrapper = styled.div`
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  border-radius: 50%;
  position: absolute;
  left: 16px;
  z-index: 1;
  transform: translateY(-50%);
`;

export const LogoIcon = styled.img`
  width: 25px;
  height: 17px;
`;

export const CardContent = styled.div`
  height: calc(100% - 84px);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 38px 14px 22px;
  background: rgba(255, 255, 248, 0.4);
  backdrop-filter: blur(20.5px);
  border-radius: 0 0 7px 7px;
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${COLORS.black};
  margin-bottom: 30px;
`;

export const UserName = styled.span`
  margin-top: auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.black};
`;

export const UserPosition = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  font-style: italic;
  color: ${COLORS.black};
`;

export const BackgroundLine = styled.div`
  position: absolute;
  left: 0;
  bottom: 120px;
  width: 100%;
  height: 15px;
  background: ${COLORS.turbo};
`;

export const SectionRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 30px 30px 0 30px;

  @media ${DEVICE.tablet} {
    padding: 0 0 0 30px;
  }

  @media ${DEVICE.mobile} {
    padding: 0;
    align-items: flex-start;
  }
`;

export const TestimonialsTitle = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 31px;
  color: ${COLORS.white};
`;

export const TestimonialsDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: ${COLORS.white};
  text-align: right;

  @media ${DEVICE.mobile} {
    text-align: left;
  }
`;

export const FAQSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 200px;

  @media ${DEVICE.desktop} {
    padding: 50px 30px;
  }

  @media ${DEVICE.mobile} {
    padding: 50px 20px;
  }
`;

export const FAQSectionTitle = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 31px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const FAQSectionContent = styled.article`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Question = styled.p`
  margin: 0 20px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: ${COLORS.white};
`;

export const Answer = styled.p`
  margin: 0 40px 0 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  color: ${COLORS.white};
  pointer-events: none;
`;
