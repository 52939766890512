import { createAction, createReducer } from '@reduxjs/toolkit';
import { SidebarState } from 'shared/types';

// Actions

export const openSidebar = createAction('sidebar/openSidebar');
export const closeSidebar = createAction('sidebar/closeSidebar');
export const setIsPageWithScene = createAction<boolean>('sidebar/setIsPageWithScene');

const initialState: SidebarState = {
  isSidebarOpened: false,
  isPageWithScene: false
};

// Reducer

export const sidebarReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(openSidebar, (state): void => {
      state.isSidebarOpened = true;
    })
    .addCase(closeSidebar, (state): void => {
      state.isSidebarOpened = false;
    })
    .addCase(setIsPageWithScene, (state, action): void => {
      state.isPageWithScene = action.payload;
    });
});
