import { useCallback, useEffect, useMemo, useState } from 'react';
import { ENV_AXES_SCALE } from './constants';
import { CustomizationState } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { CreateScreenshotService, LoadEnvironmentService } from 'services/strategy-services';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';
import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { DEFAULT_ENVIRONMENTS_PRESETS } from 'shared/constants/default-environments-presets';
import * as THREE from 'three';
import { MainScene } from 'shared/webgl/scenes';
import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';
import { setEnvThumbnail } from 'services/store/reducers/customizationReducer';
import { CUSTOMIZATION_MAIN_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';

type Result = {
  setEnvironmentPath: (path: string | null) => void;
};

const useEnvironmentController = (mainScene: MainScene): Result => {
  const dispatch = useAppDispatch();
  const loadEnvironmentService = useMemo(
    (): LoadEnvironmentService => new LoadEnvironmentService(mainScene.renderer),
    []
  );
  const createScreenshotService = useMemo(
    (): CreateScreenshotService => new CreateScreenshotService({ mainScene }),
    []
  );
  const { envType, envScale } = useAppSelector((store): CustomizationState => store.customization);

  const [environmentPath, setEnvironmentPath] = useState<string | null>(null);
  const [envModel, setEnvModel] = useState<THREE.Group | null>(null);

  useEffect((): void => {
    mainScene.environmentWrapper.scale.setScalar(ENV_AXES_SCALE);
  }, [mainScene]);

  useEffect((): void => {
    if (envModel) {
      envModel.scale.set(envScale.x, envScale.y, envScale.z);
    }
  }, [envScale, envModel]);

  const createScreenshot = useCallback(async (): Promise<void> => {
    const screenshot = await createScreenshotService.createScreenShot(
      EScreenshotTypes.sceneEnvironmentCi,
      {}
    );
    dispatch(setEnvThumbnail(screenshot));
  }, [createScreenshotService, dispatch, mainScene]);

  const setDefaultEnvironment = useCallback(async (): Promise<void> => {
    const { type, data } = DEFAULT_ENVIRONMENTS_PRESETS[EEnvironmentPresets.DefaultDark];
    await loadEnvironmentService.changeEnvironment(type, {
      canvasId: CUSTOMIZATION_MAIN_SCENE_CANVAS_ID,
      environmentWrapper: mainScene.environmentWrapper,
      renderer: mainScene.renderer,
      data
    });
    await createScreenshot();
  }, [mainScene.environmentWrapper, loadEnvironmentService, mainScene.renderer]);

  const updateEnvironment = useCallback(
    async (envPath: string): Promise<void> => {
      setEnvModel(
        await loadEnvironmentService.changeEnvironment(EEnvironmentTypes.file, {
          canvasId: CUSTOMIZATION_MAIN_SCENE_CANVAS_ID,
          environmentWrapper: mainScene.environmentWrapper,
          renderer: mainScene.renderer,
          data: { fileType: envType, scale: envScale, path: envPath }
        })
      );
      await createScreenshot();
    },
    [loadEnvironmentService, mainScene, envType]
  );

  useEffect((): void => {
    !!environmentPath ? updateEnvironment(environmentPath) : setDefaultEnvironment();
  }, [environmentPath, setDefaultEnvironment, updateEnvironment]);

  return { setEnvironmentPath };
};

export default useEnvironmentController;
