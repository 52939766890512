import React from 'react';

const MyModelsIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        viewBox='0 0 10 12'
        preserveAspectRatio='xMidYMax meet'
        fill='currentColor'
      >
        <path d='M4.73538 11.8151L0.664616 9.4726C0.456923 9.36301 0.294369 9.20548 0.176954 9C0.0589845 8.79452 0 8.56849 0 8.32192V3.67808C0 3.43151 0.0589845 3.20548 0.176954 3C0.294369 2.79452 0.456923 2.63014 0.664616 2.50685L4.73538 0.184931C4.94308 0.0616437 5.16462 0 5.4 0C5.63538 0 5.85692 0.0616437 6.06462 0.184931L10.1354 2.50685C10.3431 2.63014 10.5059 2.79452 10.6239 3C10.7413 3.20548 10.8 3.43151 10.8 3.67808V8.32192C10.8 8.56849 10.7413 8.79452 10.6239 9C10.5059 9.20548 10.3431 9.36301 10.1354 9.4726L6.06462 11.8151C5.85692 11.9384 5.63538 12 5.4 12C5.16462 12 4.94308 11.9384 4.73538 11.8151ZM4.98462 11.0137V6.22603L0.830769 3.88356V8.32192C0.830769 8.41781 0.851538 8.5 0.893077 8.56849C0.934615 8.63698 0.996923 8.69863 1.08 8.75342L4.98462 11.0137ZM5.81538 11.0137L9.72 8.75342C9.80308 8.69863 9.86538 8.63698 9.90692 8.56849C9.94846 8.5 9.96923 8.41781 9.96923 8.32192V3.88356L5.81538 6.22603V11.0137ZM5.4 5.50685L9.55385 3.14384L5.64923 0.924657C5.56615 0.869863 5.48308 0.842466 5.4 0.842466C5.31692 0.842466 5.23385 0.869863 5.15077 0.924657L1.24615 3.14384L5.4 5.50685Z' />
      </svg>
    </div>
  );
};

export default MyModelsIcon;
