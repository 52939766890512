import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const FeaturesBlockContainer = styled.section`
  display: flex;

  @media${DEVICE.tabletLarge} {
    flex-direction: column;
    background: rgba(83, 83, 83, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 2px;
    padding: 23px 18px;
  }
`;

export const FeatureItem = styled.div`
  height: 36px;
  background: ${COLORS.deepBlack};
  border-radius: 4px 4px 0 0;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.15s;

  &.add-comment {
    border-radius: 4px 0 0 0;
    padding: 0 0 0 12px;
    width: 213px;

    @media${DEVICE.tabletLarge} {
      display: none;
    }
  }

  & > .comments-count {
    padding: 0 12px;
    margin-left: auto;
  }

  span {
    color: ${COLORS.white};
    font-size: 12px;
    line-height: 14px;
    font-family: ${FONTS.interRegular};
    margin-left: 10px;
  }

  &:hover {
    background: var(--button-background-color);

    span {
      color: var(--button-text-color);
    }
  }

  @media${DEVICE.tabletLarge} {
    margin: 5px 0;
    justify-content: center;
  }
`;

export const FeatureIconContainer = styled.div`
  width: 20px;
  height: 18px;
  color: var(--theme-secondary-color);
`;

export const OpenCommentsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-left: 1px solid rgba(255, 255, 255, 0.17);
  border-right: 1px solid rgba(255, 255, 255, 0.17);
`;

export const ChevronIconWrapper = styled.div<{ isActive: boolean }>`
  height: 26px;
  width: 26px;
  transition: rotate 300ms;
  rotate: ${({ isActive }): string => (isActive ? '0deg' : '180deg')};
  color: ${COLORS.white};

  ${FeatureItem}:hover & {
    color: var(--button-text-color);
  }
`;

export const Lock = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -8px;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
  }
`;
