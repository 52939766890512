import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import { THREE_STATS_CONTAINER_ID } from 'shared/constants/html-elements-ids';
import { LOCALHOST_URL_PATTERN } from 'shared/constants/regexps';

export abstract class DisposeSceneService {
  protected isLocalDevelopment: boolean = !!process.env.REACT_APP_URL?.match(LOCALHOST_URL_PATTERN);
  protected stats: Stats;

  public scene: THREE.Scene | undefined;
  public renderer: THREE.WebGLRenderer | undefined;
  public renderCallbackStack: (() => void)[] | undefined;

  constructor() {
    this.stats = new Stats();
  }

  private initStats(): void {
    [].forEach.call(this.stats.dom.children, (child: HTMLElement): void => {
      child.style.display = '';
    });
  }

  private disposeScene(): void {
    if (!this.scene) return;

    this.scene.traverse((element): void => {
      if (element instanceof THREE.Mesh<any>) {
        if (element.geometry) {
          element.geometry.dispose();
        }

        if (element.material) {
          if (!!element.material.length && !!element.material.foreach) {
            element.material.foreach((item: any): void => {
              if (item.texture) {
                item.texture.dispose();
              }

              if (!!item.dispose) {
                item.dispose();
              }
            });
          } else {
            if (!!element.material.texture && !!element.material.texture.dispose) {
              element.material.texture.dispose();
            }

            if (!!element.material.dispose) {
              element.material.dispose();
            }
          }
        }
      }
    });
  }

  private disposeRenderer(): void {
    if (!this.renderer) return;

    this.resetWebGLState();
    this.renderer.clear();
    this.renderer.dispose();
    this.renderCallbackStack = [];

    this.renderer.domElement.height = 0;
    this.renderer.domElement.width = 0;
    this.renderer.domElement.remove();
    this.renderer.domElement?.parentElement?.removeChild(this.renderer.domElement);
  }

  private disposeWebGLContext(): void {
    if (!this.renderer) return;

    const gl = this.renderer.domElement.getContext('webgl2');

    if (!gl) return;

    if (!gl.isContextLost()) {
      this.renderer.forceContextLoss();
    }

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.activeTexture(gl.TEXTURE0 + 0);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  }

  private disposeStats(): void {
    this.stats.dom.remove();
  }

  protected mountStats(): void {
    const statsContainer = document.getElementById(THREE_STATS_CONTAINER_ID);

    if (!statsContainer) return;

    statsContainer.appendChild(this.stats.dom);
    this.initStats();
  }

  protected enableRefreshOnCtxLost(): void {
    if (!this.renderer) return;

    this.renderer.domElement.addEventListener('webglcontextlost', this.refreshWebGL.bind(this));
  }

  protected removeListeners?: () => void;

  protected abstract animate(): void;

  protected handleAnimate(): void {
    this.animate();
    this.stats.update();
  }

  public resetWebGLState(): void {
    if (!this.renderer) return;

    this.renderer.resetState();
    this.renderer.state.reset();
  }

  public refreshWebGL(): void {
    if (!this.renderer) return;

    this.resetWebGLState();
    this.renderer.forceContextRestore();
  }

  public startAnimationLoop(): void {
    if (!this.renderer) return;

    this.renderer.setAnimationLoop(this.handleAnimate.bind(this));
    this.refreshWebGL();
  }

  public stopAnimationLoop(): void {
    if (!this.renderer) return;

    this.renderer.setAnimationLoop(null);
    this.resetWebGLState();
  }

  public dispose(): void {
    this.disposeStats();
    this.disposeRenderer();
    this.disposeWebGLContext();
    this.disposeScene();

    if (this.removeListeners) {
      this.removeListeners();
    }
  }
}
