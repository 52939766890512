import * as THREE from 'three';
import { BaseLoadModelStrategy } from './base-load-model.strategy';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
// @ts-ignore
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';

export class GltfLoadModelStrategy extends BaseLoadModelStrategy {
  private gltfLoader: GLTFLoader;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    super(renderer, onModelResourcesLoaded);
    const THREE_PATH = `https://unpkg.com/three@0.${THREE.REVISION}.x`;
    const DRACO_LOADER = new DRACOLoader(this.loadingManager).setDecoderPath(
      `${THREE_PATH}/examples/jsm/libs/draco/gltf/`
    );
    const KTX2_LOADER = new KTX2Loader(this.loadingManager).setTranscoderPath(
      `${THREE_PATH}/examples/jsm/libs/basis/`
    );

    this.gltfLoader = new GLTFLoader(this.loadingManager)
      .setCrossOrigin('anonymous')
      .setDRACOLoader(DRACO_LOADER)
      .setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer))
      .setMeshoptDecoder(MeshoptDecoder);
  }

  public async loadModel({ path, assets }: LoadModelData): Promise<LoadModelResult> {
    this.setLoadingContent(assets);

    const gltf = await this.gltfLoader.loadAsync(path);
    const model = gltf.scene || gltf.scenes[0];

    this.setLoadingContent([]);

    return {
      gltf,
      model
    };
  }
}
