import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import {
  EConfirmModalHeader,
  EPaymentType,
  ESnackbarStyle,
  Model,
  SubscriptionProduct
} from 'shared/types';
import { MEGABYTE } from 'shared/constants/limits';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { deleteModel } from 'services/api/modelService';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { ModalCheckout, ModalTeamCheckout } from 'shared/components';

type Props = {
  paymentType: EPaymentType.UPDATE_USER_PLAN | EPaymentType.UPDATE_TEAM_PLAN;
  plan: SubscriptionProduct;
  isMonthlyType: boolean;
  modelsOverLimit: number;
  modelsOverSizeLimit: Model[];
  checkExceededLimits: (
    plan: SubscriptionProduct
  ) => Promise<{ modelsOverSizeLimit: Model[]; modelsOverLimit: number }>;
};

const ModalExceedLimits: React.FC<Props> = ({
  paymentType,
  plan,
  isMonthlyType,
  modelsOverLimit,
  modelsOverSizeLimit,
  checkExceededLimits
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [quantityLimit, setQuantityLimit] = useState<number>(modelsOverLimit);
  const [sizeLimit, setSizeLimit] = useState<Model[]>(modelsOverSizeLimit);

  useEffect((): void => {
    if (!quantityLimit && !sizeLimit.length) {
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content:
            paymentType === EPaymentType.UPDATE_USER_PLAN ? (
              <ModalCheckout plan={plan} isMonthlyType={isMonthlyType} />
            ) : (
              <ModalTeamCheckout plan={plan} isMonthlyType={isMonthlyType} />
            )
        })
      );
    }
  }, [dispatch, isMonthlyType, paymentType, plan, quantityLimit, sizeLimit]);

  const handleDeleteClick = async (modelId: string): Promise<void> => {
    try {
      dispatch(startLoader());
      await deleteModel(modelId);
      const { modelsOverSizeLimit, modelsOverLimit } = await checkExceededLimits(plan);
      setSizeLimit(modelsOverSizeLimit);
      setQuantityLimit(modelsOverLimit);
      openNotification(ESnackbarStyle.SUCCESS, 'Your model has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  return (
    <Styled.ModalWindowContainer>
      {!!quantityLimit && (
        <Styled.Notification>
          Quantity of your models exceeds the limit of models in the selected plan. You need to
          remove
          <span className='quantity'>{quantityLimit}</span>
          model(s) to select this plan.
        </Styled.Notification>
      )}
      {!!sizeLimit.length && (
        <>
          <Styled.Notification>
            Some of your models are larger than the size limit of your selected subscription plan.
            Please review your models and remove large models to select this plan.
          </Styled.Notification>
          <Styled.ListTitle>Models over the size limit</Styled.ListTitle>
          <Styled.OverSizeModelsList>
            {sizeLimit.map(
              (model): JSX.Element => (
                <Styled.ModelItem key={model.id}>
                  <NavLink to={`/${model.shortCode}`}>{model.modelName}</NavLink>
                  <Styled.ModelSize>{(model.modelSize / MEGABYTE).toFixed(2)}MB</Styled.ModelSize>
                  <Styled.DeleteButton onClick={(): Promise<void> => handleDeleteClick(model.id)}>
                    Delete
                  </Styled.DeleteButton>
                </Styled.ModelItem>
              )
            )}
          </Styled.OverSizeModelsList>
        </>
      )}
    </Styled.ModalWindowContainer>
  );
};

export default ModalExceedLimits;
