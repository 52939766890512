import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import ReactGA from 'react-ga';
import { Userpilot } from 'userpilot';

const TAG_MANAGER_ARGUMENTS: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ``
};

TagManager.initialize(TAG_MANAGER_ARGUMENTS);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || ``);
Userpilot.initialize(process.env.REACT_APP_USER_PILOT_ID || ``);

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);
