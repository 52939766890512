import { Cubemap } from 'shared/types';
import { ECubemapPresets } from '../enums/ECubemapPresets';

export const DEFAULT_CUBEMAPS: Record<ECubemapPresets, Cubemap> = {
  [ECubemapPresets.BasicWarehouse]: {
    key: ECubemapPresets.BasicWarehouse,
    cubemapName: 'Basic warehouse',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/BasicWarehouse/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/BasicWarehouse/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/BasicWarehouse/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/BasicWarehouse/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/BasicWarehouse/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/BasicWarehouse/nz.jpg`
    }
  },
  [ECubemapPresets.EmptyRoom]: {
    key: ECubemapPresets.EmptyRoom,
    cubemapName: 'Empty room',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/EmptyRoom/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/EmptyRoom/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/EmptyRoom/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/EmptyRoom/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/EmptyRoom/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/EmptyRoom/nz.jpg`
    }
  },
  [ECubemapPresets.Warehouse]: {
    key: ECubemapPresets.Warehouse,
    cubemapName: 'Warehouse',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/Warehouse/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/Warehouse/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/Warehouse/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/Warehouse/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/Warehouse/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/Warehouse/nz.jpg`
    }
  },
  [ECubemapPresets.WoodSurface]: {
    key: ECubemapPresets.WoodSurface,
    cubemapName: 'Wood surface',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/WoodSurface/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/WoodSurface/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/WoodSurface/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/WoodSurface/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/WoodSurface/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/WoodSurface/nz.jpg`
    }
  },
  [ECubemapPresets.CityDay]: {
    key: ECubemapPresets.CityDay,
    cubemapName: 'City day',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityDay/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityDay/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityDay/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityDay/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityDay/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityDay/nz.jpg`
    }
  },
  [ECubemapPresets.CityEvening]: {
    key: ECubemapPresets.CityEvening,
    cubemapName: 'City evening',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityEvening/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityEvening/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityEvening/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityEvening/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityEvening/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/CityEvening/nz.jpg`
    }
  },
  [ECubemapPresets.LightBox]: {
    key: ECubemapPresets.LightBox,
    cubemapName: 'Light box',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightBox/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightBox/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightBox/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightBox/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightBox/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightBox/nz.jpg`
    }
  },
  [ECubemapPresets.LightRoom]: {
    key: ECubemapPresets.LightRoom,
    cubemapName: 'Light room',
    imagePaths: {
      pxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightRoom/px.jpg`,
      nxPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightRoom/nx.jpg`,
      pyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightRoom/py.jpg`,
      nyPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightRoom/ny.jpg`,
      pzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightRoom/pz.jpg`,
      nzPlaneUrl: `${process.env.PUBLIC_URL}/resources/viewer/cubemaps/LightRoom/nz.jpg`
    }
  },
  [ECubemapPresets.Neutral]: {
    key: ECubemapPresets.Neutral,
    cubemapName: 'Neutral',
    imagePaths: {
      pxPlaneUrl: '',
      nxPlaneUrl: '',
      pyPlaneUrl: '',
      nyPlaneUrl: '',
      pzPlaneUrl: '',
      nzPlaneUrl: ''
    }
  }
};
