import { configureStore } from '@reduxjs/toolkit';
import {
  arModeReducer,
  authReducer,
  commentsReducer,
  createTeamReducer,
  keyBindingsReducer,
  loaderReducer,
  modalReducer,
  modelFeatureAccessReducer,
  modelPermissionsReducer,
  sidebarReducer,
  subscriptionsReducer,
  teamsReducer,
  viewerDataReducer,
  customizationReducer,
  brandingReducer
} from './reducers';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebar: sidebarReducer,
    modal: modalReducer,
    arMode: arModeReducer,
    loader: loaderReducer,
    viewerData: viewerDataReducer,
    subscriptions: subscriptionsReducer,
    modelPermissions: modelPermissionsReducer,
    comments: commentsReducer,
    keyBindings: keyBindingsReducer,
    teams: teamsReducer,
    createTeam: createTeamReducer,
    modelFeatureAccess: modelFeatureAccessReducer,
    customization: customizationReducer,
    branding: brandingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
