import { createAction, createReducer } from '@reduxjs/toolkit';
import { EConfirmModalHeader, ModalState } from 'shared/types';
import { checkIsIFrame } from 'utils/helper-utils';

const initialState: ModalState = {
  isModalOpened: false,
  isConfirmModalOpened: false,
  modalContent: null,
  confirmModalContent: null,
  confirmModalHeader: null,
  isEmbeddedModelPromptActive: false,
  promptContent: ''
};

// Actions

export const showModal = createAction<JSX.Element | null>('modal/showModal');
export const closeModal = createAction('modal/closeModal');
export const showConfirmModal = createAction<{
  header: EConfirmModalHeader | string | null;
  content: JSX.Element | null;
}>('modal/showConfirmModal');
export const closeConfirmModal = createAction('modal/closeConfirmModal');
export const showEmbeddedModelPrompt = createAction<{ promptContent: any }>(
  'modal/showEmbeddedModelPrompt'
);

// Reducer

export const modalReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(showModal, (state, action): void => {
      if (!checkIsIFrame()) {
        state.isModalOpened = true;
        state.modalContent = action.payload;
      }
    })
    .addCase(closeModal, (state): void => {
      state.isModalOpened = false;
      state.modalContent = null;
    })
    .addCase(showConfirmModal, (state, action): void => {
      if (!checkIsIFrame()) {
        state.isConfirmModalOpened = true;
        state.confirmModalContent = action.payload.content;
        state.confirmModalHeader = action.payload.header;
      }
    })
    .addCase(closeConfirmModal, (state): void => {
      state.isConfirmModalOpened = false;
      state.confirmModalContent = null;
      state.confirmModalHeader = null;
    })
    .addCase(showEmbeddedModelPrompt, (state, { payload }): void => {
      state.isEmbeddedModelPromptActive = true;
      state.promptContent = payload.promptContent;
    });
});
