import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Model } from 'shared/types';
import * as Styled from './styles';
import Search from 'assets/images/search.svg';
import { useHandleClickOutside } from 'shared/hooks';
import { useDebounce } from 'shared/hooks';

type Props = {
  inputAction: (value: string) => void;
  listAction: (modelId: string) => void;
  options: Model[];
  setIsSearchMode: (value: boolean) => void;
};

const SearchSelect: React.FC<Props> = ({
  inputAction,
  listAction,
  options,
  setIsSearchMode
}): JSX.Element => {
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedValue = useDebounce(inputValue, 1000);
  const isItemSelected = useRef<boolean>(false);

  useEffect((): void => {
    if (!isItemSelected.current) {
      inputAction(debouncedValue.trim());
    }
  }, [inputAction, debouncedValue]);

  useEffect((): void => {
    setIsDropdownActive(!!inputValue && !!options.length && !isItemSelected.current);
  }, [inputValue, options]);

  const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    isItemSelected.current = false;
    setInputValue(value);
    if (!value) setIsSearchMode(false);
  };

  const handleOptionClick =
    (option: Model): (() => void) =>
    (): void => {
      setInputValue(option.modelName);
      isItemSelected.current = true;
      setIsDropdownActive(false);
      listAction(option.id);
    };

  const handleInputFocus = (): void => {
    if (!!inputValue && !!options.length) {
      setIsDropdownActive(true);
    }
  };

  const ref = useHandleClickOutside((): void => setIsDropdownActive(false));

  return (
    <Styled.SearchSelectContainer ref={ref}>
      <Styled.MainField>
        <Styled.SearchInput
          value={inputValue}
          onChange={handleInputValueChange}
          onFocus={handleInputFocus}
          placeholder='Search'
        />
        <img src={Search} alt='Search' />
      </Styled.MainField>
      <Styled.DropdownMenu isDropdownActive={isDropdownActive}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem onClick={handleOptionClick(option)} key={option.id}>
              <span>{option.modelName}</span>
            </Styled.OptionItem>
          )
        )}
      </Styled.DropdownMenu>
    </Styled.SearchSelectContainer>
  );
};

export default SearchSelect;
