import React from 'react';

const QuickViewIcon = (): React.ReactElement => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 42 42'
        preserveAspectRatio='xMaxYMax meet'
      >
        <g>
          <circle cx='22' cy='21' r='18'></circle>
        </g>
        <mask
          id='mask0_3108_591'
          style={{ maskType: 'alpha' }}
          width='24'
          height='24'
          x='10'
          y='10'
          maskUnits='userSpaceOnUse'
        >
          <rect x='10' y='10' width='24' height='24' fill='currentColor' />
        </mask>
        <g mask='url(#mask0_3108_591)'>
          <path
            fill='currentColor'
            d='M22 25.575c1.133 0 2.096-.396 2.887-1.188.792-.791 1.188-1.754 1.188-2.887s-.396-2.096-1.188-2.887c-.791-.792-1.754-1.188-2.887-1.188s-2.096.396-2.887 1.188c-.792.791-1.188 1.754-1.188 2.887s.396 2.096 1.188 2.887c.791.792 1.754 1.188 2.887 1.188zm0-1.375c-.75 0-1.387-.263-1.912-.788A2.601 2.601 0 0119.3 21.5c0-.75.263-1.388.788-1.913A2.603 2.603 0 0122 18.8c.75 0 1.388.262 1.913.787.525.525.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.605 2.605 0 0122 24.2zm0 4.3c-2.3 0-4.396-.633-6.287-1.9-1.892-1.267-3.28-2.967-4.163-5.1.883-2.133 2.271-3.833 4.163-5.1 1.891-1.267 3.987-1.9 6.287-1.9s4.396.633 6.288 1.9c1.891 1.267 3.279 2.967 4.162 5.1-.883 2.133-2.27 3.833-4.162 5.1-1.892 1.267-3.988 1.9-6.288 1.9zm0-1.5a9.545 9.545 0 005.188-1.488A9.77 9.77 0 0030.8 21.5a9.777 9.777 0 00-3.612-4.013A9.55 9.55 0 0022 16a9.55 9.55 0 00-5.188 1.487A9.777 9.777 0 0013.2 21.5a9.77 9.77 0 003.612 4.012A9.545 9.545 0 0022 27z'
          ></path>
        </g>
        <defs>
          <filter
            id='filter0_d_3108_591'
            width='44'
            height='44'
            x='0'
            y='0'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1'></feOffset>
            <feGaussianBlur stdDeviation='2'></feGaussianBlur>
            <feComposite operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'></feColorMatrix>
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_3108_591'></feBlend>
            <feBlend in='SourceGraphic' in2='effect1_dropShadow_3108_591' result='shape'></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default QuickViewIcon;
