import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const CustomizationContent = styled.div`
  display: flex;

  @media ${DEVICE.desktopLarge} {
    flex-direction: column;
  }
`;

export const LeftSideSection = styled.section`
  display: flex;
  flex-direction: column;
`;

export const CustomizationPreviewContainer = styled.section`
  margin-left: 20px;
  width: 100%;
  position: relative;

  @media ${DEVICE.desktopLarge} {
    margin: 30px 0 0;
  }
`;
