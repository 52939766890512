import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const Viewer = styled.div<{ isPreviewMode: boolean }>`
  width: 100%;
  height: ${({ isPreviewMode }): string => (isPreviewMode ? 'calc(100vw * 0.36)' : '100%')};
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  position: static;
  z-index: -1;

  @media ${DEVICE.desktopLarge} {
    height: ${({ isPreviewMode }): string => (isPreviewMode ? 'calc(100vw * 0.4)' : '100%')};
  }

  @media ${DEVICE.tabletLarge} {
    height: ${({ isPreviewMode }): string => (isPreviewMode ? 'calc(100vw * 0.5)' : '100%')};
  }

  @media ${DEVICE.tablet} {
    height: ${({ isPreviewMode }): string => (isPreviewMode ? 'calc(100vw * 0.6)' : '100%')};
  }

  @media ${DEVICE.mobile} {
    height: ${({ isPreviewMode }): string => (isPreviewMode ? '600px' : '100%')};
  }
`;
