export const VIEWS_LIMIT =
  'The model owner has reached their view limit for this month. They need to upgrade their account or wait until the view limit is reset. ';
export const AR_VIEWS_LIMIT =
  'The model owner has reached their AR view limit for this month. They need to upgrade their account or wait until the AR view limit is reset. ';
export const NEED_TO_LOGIN = 'You must sign in before viewing this model';
export const RESTRICT_VIEWING =
  'You don’t have the correct permissions to view this model, please check with the model owner.';
export const NO_ACCESS_AR = 'You don’t have access to view this model in AR';
export const RESTRICT_AR_VIEWING =
  'You don’t have the correct permissions to view this model in AR, please check with the model owner.';
export const RESTRICT_COMMENTS =
  'You don’t have the correct permissions to leave comments for this model, please check with the model owner.';
export const NO_MODEL =
  'The model you are trying to access does not exist. Please check with the model owner as they may have deleted it.';
export const INCORRECT_FILE_FORMAT =
  'Please make sure you’re uploading a valid filetype. This filetype will be supported in future updates.';
export const INCORRECT_ENV_FILE_FORMAT =
  'This file type is not currently supported! Try uploading a glb, fbx, obj, or stl.';
export const FILE_SIZE_LIMIT = 'Model size exceeds the allowed limit';
export const ANONYMOUS_FILE_SIZE_LIMIT =
  'Model size exceeds the allowed limit. 10MB maximum file size.';
export const RESTRICT_FEATURE =
  'The model owner must upgrade their account before you can use this feature.';
export const UPDATE_YOUR_ACCOUNT = 'Upgrade your account to unlock this feature.';
export const LINK_COPIED = 'Sharing link has been successfully copied!';
export const INVITE_SUCCESSFULLY_SENT = 'You have just sent an invite to view this model';
export const REVOKED_ACCESS = 'You have successfully revoked access to this model';
export const REVOKED_ALL_ACCESSES = 'You have successfully revoked all accesses to this model';
export const MODEL_DELETED = 'Model successfully deleted from your account';
export const MODEL_UPLOADED = 'Your model has been successfully uploaded';
export const SUBSCRIPTION_UPDATED = 'Your subscription has been modified';
export const MODEL_DOWNLOADING = 'Your model is being downloaded';
export const CHANGES_SAVED = 'Changes successfully saved';
export const EMAIL_VERIFIED =
  'Your email address has been verified! Please login to complete your account setup';
export const USER_ADDED_TO_TEAM = 'User added to the workspace';
export const USER_INVITED_TO_TEAM = 'User has been successfully invited to team';
export const TEAM_NAME_CHANGED = 'Team name has been successfully changed';
export const TEAM_CREATED = 'Your team has been successfully created';
export const TEAM_DELETED = 'Your team has been successfully removed';
export const UPGRADE_YOUR_TEAM_PLAN = 'Upgrade your team plan to unlock this feature.';
export const KEY_BINDINGS_CHANGED = 'Your key bindings have been successfully changed';
export const KEY_BINDINGS_DELETED = 'Your key bindings have been successfully reset to default';
