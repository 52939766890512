import type {
  IGeometry3DStats,
  IResources3DStats,
  ITexture2DStats
} from '../interfaces/model-parse/model-stats';
import { IFBXAnimation, IFBXMetaData } from '../interfaces/model-parse/fbx-types';

import { imageMetadata } from '../texture-parser/texture-parser';

import { Texture2DStats } from '../texture-2d-stats';
import { FPXParser } from './fbx-parser';
import { Model3DFilePartStats } from '../interfaces/3d-models';
import { Model3DAnimationStats } from '../interfaces/3d-models/model-3d-resources.interface';
import { fetchBuffer } from '../utils/buffer';
import { Buffer } from 'buffer';

export class FBXParserService {
  protected _textureStats: Texture2DStats = new Texture2DStats();
  protected _resourceStats: IResources3DStats = {
    materialsCount: 0,
    texturesCount: 0,
    animationsCount: 0,
    meshesCount: 0,
    scenesCount: 0
  };
  protected _geometryStats: IGeometry3DStats = {
    trianglesCount: 0,
    vertexesCount: 0,
    polygonsCount: 0
  };

  /** 0 - none, 1 - lit, 2 - unlit, 3 - mixed */
  protected _unlitFlag: number = 0;
  protected _basePath: string = '';
  protected _format: string = '';
  protected _fbxSize: number = 0;

  protected _animations: IFBXAnimation[] = [];
  private _buffers: ArrayBuffer[] = [];

  public static canHandleFileType(ext: string): boolean {
    return this.supportedFileTypes.includes(ext);
  }

  public static get supportedFileTypes(): string[] {
    return ['fbx'];
  }

  public get unlitFlag(): number {
    return this._unlitFlag;
  }

  public get format(): string {
    return this._format;
  }

  public get fileSize(): Model3DFilePartStats {
    return {
      totalSize: this.binSize,
      gltfSize: this._fbxSize,
      binSize: this.binSize
    };
  }

  public get vertexesCount(): number {
    return this._geometryStats.vertexesCount;
  }

  public get trianglesCount(): number {
    return this._geometryStats.trianglesCount;
  }

  public get quadsCount(): number {
    return 0;
  }

  public get polygonsCount(): number {
    return 0;
  }

  public get meshCount(): number {
    return this._resourceStats.meshesCount;
  }

  public get materialsCount(): number {
    return this._resourceStats.materialsCount;
  }

  public get scenesCount(): number {
    return this._resourceStats.scenesCount;
  }

  public get animations(): Model3DAnimationStats {
    return {
      totalCount: this._resourceStats.animationsCount,
      animations: []
    };
  }

  public get textures(): ITexture2DStats {
    return this._textureStats.plainObject();
  }

  protected get binSize(): number {
    return this._buffers.reduce((acc: number, buffer): number => {
      return acc + buffer.byteLength;
    }, 0);
  }

  private parseFBXSync(modelFile: Buffer): IFBXMetaData {
    // const file = fs.readFileSync(modelFile);
    this._buffers.push(modelFile);
    return new FPXParser(modelFile).parse();
  }

  public async parse(
    ext: string,
    formatFiles: Record<string, string>,
    assets: Record<string, string>
  ): Promise<boolean> {
    this._format = `.${ext}`;

    const formatFilesKeys = Object.keys(formatFiles);

    const mainFbxKey = formatFilesKeys
      .filter((key): boolean => key.slice(key.lastIndexOf('.')).toLowerCase() === this._format)
      .join();

    const mainFbx = await fetchBuffer(formatFiles[mainFbxKey]);
    this._fbxSize = mainFbx.byteLength;

    const fbx = this.parseFBXSync(Buffer.from(mainFbx));

    this._geometryStats.vertexesCount = fbx.vertices;

    // Assuming `mode = gl.TRIANGLES`
    this._geometryStats.trianglesCount = fbx.vertices > 0 ? Math.round(fbx.vertices / 3) : 0;

    this._resourceStats.animationsCount = fbx.animationsCount;
    this._resourceStats.materialsCount = fbx.materialsCount;

    // this._resourceStats.texturesCount = fbx.textures.length;

    this._animations = fbx.animations;
    this._unlitFlag = fbx.unlitFlag;

    for (const tex of fbx.textures) {
      try {
        const path = Object.keys(assets).find((key): boolean => key.includes(tex));
        if (path) {
          const buffer = await fetchBuffer(assets[path]);
          this._buffers.push(buffer);
          const texImage = imageMetadata(new Uint8Array(buffer));
          this._textureStats.updateTexture2DStats(texImage);
        }
      } catch (e) {
        /* ... just ignore parse errors */
      }
    }

    return true;
  }
}
