import React, { ReactNode, useCallback, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useAppSelector } from 'shared/hooks';
import { ViewerDataState } from 'shared/types';

type Props = {
  children: ReactNode;
};

const NavigationPrevent: React.FC<Props> = ({ children }): JSX.Element => {
  const { isModelLoading } = useAppSelector((store): ViewerDataState => store.viewerData);
  const ALERT_TEXT = 'Are you sure you want to leave the page?'

  const showConfirmPrompt = useCallback((event: BeforeUnloadEvent): string => {
    event.preventDefault();
    event.returnValue = ALERT_TEXT;
    return event.returnValue;
  }, []);

  useEffect((): (() => void) => {
    isModelLoading
      ? window.addEventListener('beforeunload', showConfirmPrompt, { capture: true })
      : window.removeEventListener('beforeunload', showConfirmPrompt, { capture: true });
    return (): void => {
      window.removeEventListener('beforeunload', showConfirmPrompt, { capture: true });
    };
  }, [isModelLoading, showConfirmPrompt]);

  return (
    <>
      <Prompt when={isModelLoading} message={ALERT_TEXT} />
      {children}
    </>
  );
};

export default NavigationPrevent;
