import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { removeAllPermissions } from 'services/store/reducers/modelPermissionsReducer';
import { REVOKED_ALL_ACCESSES } from 'shared/constants/notifications';
import { ESnackbarStyle, Model } from 'shared/types';
import { openNotification } from 'utils/notification-utils';

type Props = {
  model: Model;
};

const ModalRevokeAccess: React.FC<Props> = ({ model }): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleRevokeAllButtonClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await dispatch(removeAllPermissions({ model }));
      openNotification(ESnackbarStyle.SUCCESS, REVOKED_ALL_ACCESSES);
      dispatch(closeConfirmModal());
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.Notification>
        Revoking all access will remove access to this model for all users that you have shared it
        with. Would you like to proceed?
      </Styled.Notification>
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton
          className='revoke-button'
          onClick={handleRevokeAllButtonClick}
          id='modal-revoke-all-button'
        >
          Revoke All Access
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalRevokeAccess;
