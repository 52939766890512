import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';
import Comment from 'assets/images/comment.png';

export const ModelCardWrapper = styled.div`
  padding: 10px;
  width: 100%;
  min-width: 320px;
  max-width: 400px;

  @media ${DEVICE.mobile} {
    max-width: 100%;
  }
`;

export const ModelCardContainer = styled.div`
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;

  &:hover {
    border: 1px solid ${COLORS.mischka};
  }
`;

export const LabelStatus = styled.div<{ isPublic?: boolean }>`
  position: absolute;
  top: 10px;
  left: 0;
  padding: 4px 10px;
  background: ${({ isPublic }): string => (isPublic ? COLORS.turbo : COLORS.mineShaft)};
  font-family: ${FONTS.interRegular};
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ isPublic }): string => (isPublic ? COLORS.black : COLORS.white)};
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;

  &.my-models-label {
    top: 40px;
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }
`;

export const NewTabIconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 1px 4px 3px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.1s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const NewTabIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const NoteCounter = styled.div`
  position: absolute;
  top: 204px;
  left: 8px;
  height: 36px;
  max-height: 36px;
  min-width: 36px;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${Comment});
  background-color: ${COLORS.turbo};
  background-repeat: no-repeat;
  background-size: 28px 25px;
  background-position: center 8px;
  border-radius: 5px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: ${COLORS.black};
  }
`;

export const ModelImage = styled.img`
  height: 250px;
  min-height: 250px;
  width: 100%;
  object-fit: cover;
`;

export const DefaultImage = styled.img`
  height: 250px;
  width: 100%;
  object-fit: scale-down;
`;

export const ModelDescriptionContainer = styled.div`
  padding: 10px 10px 17px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.mischka};

  @media ${DEVICE.tabletLarge} {
    padding: 10px 4px 17px;
  }
`;

export const ModelTitle = styled.span`
  display: flex;

  span {
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ababab;
  }
`;

export const ModelDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;
  margin-top: 10px;
  background: ${COLORS.doveGray};
  border-radius: 4px;

  @media ${DEVICE.tabletLarge} {
    width: 32%;
  }
`;

export const DescriptionBlockTitle = styled.span`
  font-size: 13px;
  line-height: 26px;
  font-weight: 700;
  padding-left: 6px;
`;

export const DescriptionItem = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 0 6px;

  &:nth-child(even) {
    background: rgba(71, 71, 71, 0.59);
  }

  span:first-child {
    margin-right: 6px;
  }

  span:last-child {
    margin-left: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-right: 6px;
  }
`;

export const DescriptionItemLit = styled.div`
  height: 22px;
  width: 100%;
  display: flex;
`;

export const LitItem = styled.div<{ isActive: boolean }>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.mischka)};
  background: ${({ isActive }): string => (isActive ? COLORS.turbo : COLORS.doveGray)};
`;

export const DescriptionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ModelOwner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 31%;
  max-width: 45%;
  padding: 4px 9px;
  background: #404040;
  border-radius: 4px;
  margin-right: 6px;

  span {
    font-size: 11px;
    color: #d6d7e3;
  }

  span:first-child {
    font-weight: 600;
    margin-right: 4px;
  }

  span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ModelActions = styled.div`
  display: flex;
  margin-top: 13px;
  margin-left: auto;
`;

export const ActionButton = styled.button`
  background: ${COLORS.corn};
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: all 0.1s;

  &.quick-sharing img {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }

  &:first-child,
  &.ar-button {
    margin-left: 0;
  }

  img {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background: ${COLORS.turbo};
  }
`;

export const ArButtonContainer = styled.div`
  margin-left: 10px;
  position: relative;
`;

export const QrCodeContainer = styled.div`
  width: 200px;
  height: 220px;
  background: ${COLORS.black};
  position: absolute;
  transform: translate(-152px, -235px);
  border-radius: 2px;
`;

export const QrCodeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    font-family: ${FONTS.interRegular};
    color: ${COLORS.white};
  }
`;

export const QrCodeArrow = styled.div`
  position: absolute;
  background: ${COLORS.black};
  width: 35px;
  height: 35px;
  bottom: -5px;
  right: 12px;
  background: black;
  transform: rotate(45deg);
  z-index: -1;
`;

export const QrCode = styled.div`
  margin-top: 15px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${COLORS.white};
  padding: 6px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Spinner = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ShareQrCodeContainer = styled.div<{ isLoading: boolean }>`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 150px;
  right: 0;
  background: ${({ isLoading }): string => (isLoading ? COLORS.mineShaft : COLORS.white)};

  img {
    width: 100%;
    height: 100%;
  }
`;
