import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  AxisValues,
  Camera,
  ELoadingState,
  EModelAccessLevel,
  EModelType,
  ESnackbarStyle,
  Model,
  ModelSettings,
  ViewerDataState
} from 'shared/types';
import { INITIAL_MODEL_SETTINGS } from 'shared/constants/model-settings';
import { AppDispatch, RootState } from '../index';
import {
  checkIsViewerClosed,
  setIsViewerClosed,
  updateModelInSessionStorage
} from 'utils/storage-utils';
import { getModelType } from 'utils/model-utils';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { convertDataURLtoFormData } from 'utils/convert-file-utils';
import { uploadModelCoverImage } from 'services/api/modelService';
import { openNotification } from 'utils/notification-utils';
import { fetchModelFeatureAccess } from './modelFeatureAccessReducer';
import { setIsViewerLoading, setLoadingState } from './loaderReducer';
import { fetchModelPermissions } from './modelPermissionsReducer';

const initialState: ViewerDataState = {
  model: null,
  isEmbeddedModelMode: false,
  modelType: EModelType.ANONYMOUS,
  modelAccessLevel: [],
  modelName: '',
  modelFileType: undefined,
  modelMtls: undefined,
  modelSettings: INITIAL_MODEL_SETTINGS,
  animations: [{ title: 'No animations', value: -1 }],
  isZenMode: false,
  isModelLoading: false,
  hasSkeleton: false,
  isViewerPage: false,
  isModelInitialized: false,
  isSceneCompletelyLoaded: false,
  screenshot: '',
  isTransparentBackground: false,
  isCubeOCVisible: true,
  isSidebarHidden: false
};

// Actions

export const setViewerModel = createAction<Model>('viewerData/setModel');
export const setModelType = createAction<EModelType>('viewerData/setModelType');
export const setModelName = createAction<string>('viewerData/setModelName');
export const setModelAccessLevel = createAction<EModelAccessLevel[]>(
  'viewerData/setModelAccessLevel'
);
export const setModelFileType = createAction<E3DModelFileTypes | undefined>(
  'viewerData/setModelFileType'
);
export const setModelMtls = createAction<string[]>('viewerData/setModelMtls');
export const setModelSettings = createAction<ModelSettings>('viewerData/setModelSettings');
export const setModelAnimations = createAction<{ title: string; value: number }[]>(
  'viewerData/setModelAnimations'
);
export const changeModelScale = createAction<AxisValues>('viewerData/changeModelScale');
export const setScaleFactor = createAction<AxisValues>('viewerData/setScaleFactor');
export const changeModelOffset = createAction<AxisValues>('viewerData/changeModelOffset');
export const changeModelRotation = createAction<AxisValues>('viewerData/changeModelRotation');
export const changeModelCamera = createAction<Camera>('viewerData/changeModelCamera');
export const startZenMode = createAction('viewerData/startZenMode');
export const stopZenMode = createAction('viewerData/stopZenMode');
export const setIsEmbeddedModelMode = createAction<boolean>('viewerData/setIsEmbeddedModelMode');
export const setIsModelLoading = createAction<boolean>('viewerData/setIsModelLoading');
export const setIsViewerPage = createAction<boolean>('viewerData/setIsViewerPage');
export const setIsModelInitialized = createAction<boolean>('viewerData/setIsModelInitialized');
export const setIsSceneCompletelyLoaded = createAction<boolean>(
  'viewerData/setIsSceneCompletelyLoaded'
);
export const setScreenshot = createAction<string>('viewerData/setScreenshot');
export const setIsTransparentBackground = createAction<boolean>(
  'viewerData/setIsTransparentBackground'
);
export const setHasSkeleton = createAction<boolean>('viewerData/setHasSkeleton');
export const setCubeOCVisibility = createAction<boolean>('viewerData/setCubeOCVisibility');
export const setIsSidebarHidden = createAction<boolean>('viewerData/setIsSidebarHidden');
export const clearViewerDataState = createAction('viewerData/clearViewerDataState');

// Reducer

export const viewerDataReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setViewerModel, (state, { payload }): void => {
      state.model = payload;
    })
    .addCase(setModelType, (state, { payload }): void => {
      state.modelType = payload;
    })
    .addCase(setModelAccessLevel, (state, { payload }): void => {
      state.modelAccessLevel = payload;
      updateModelInSessionStorage({ accessLevel: payload });
    })
    .addCase(setModelFileType, (state, { payload }): void => {
      state.modelFileType = payload;
    })
    .addCase(setModelMtls, (state, { payload }): void => {
      state.modelMtls = payload;
    })
    .addCase(setModelSettings, (state, { payload }): void => {
      state.modelSettings = payload;
    })
    .addCase(setModelName, (state, { payload }): void => {
      state.modelName = payload;
    })
    .addCase(setModelAnimations, (state, { payload }): void => {
      state.animations = payload;
    })
    .addCase(changeModelScale, (state, { payload }): void => {
      state.modelSettings.scale = payload;
    })
    .addCase(setScaleFactor, (state, { payload }): void => {
      if (state.modelSettings.scaleFactor.x !== payload.x) {
        state.modelSettings.scaleFactor = payload;
      }
    })
    .addCase(changeModelOffset, (state, { payload }): void => {
      state.modelSettings.offset = payload;
    })
    .addCase(changeModelRotation, (state, { payload }): void => {
      state.modelSettings.rotation = payload;
    })
    .addCase(changeModelCamera, (state, { payload }): void => {
      state.modelSettings.camera = payload;
    })
    .addCase(setIsModelLoading, (state, { payload }): void => {
      state.isModelLoading = payload;
    })
    .addCase(startZenMode, (state): void => {
      state.isZenMode = true;
      state.isSidebarHidden = true;
    })
    .addCase(stopZenMode, (state): void => {
      state.isZenMode = false;
      state.isSidebarHidden = false;
    })
    .addCase(setIsViewerPage, (state, { payload }): void => {
      state.isViewerPage = payload;
    })
    .addCase(setIsModelInitialized, (state, { payload }): void => {
      state.isModelInitialized = payload;
    })
    .addCase(setIsSceneCompletelyLoaded, (state, { payload }): void => {
      state.isSceneCompletelyLoaded = payload;
    })
    .addCase(setScreenshot, (state, { payload }): void => {
      state.screenshot = payload;
    })
    .addCase(setIsEmbeddedModelMode, (state, { payload }): void => {
      state.isEmbeddedModelMode = payload;
    })
    .addCase(setIsTransparentBackground, (state, { payload }): void => {
      state.isTransparentBackground = payload;
    })
    .addCase(setCubeOCVisibility, (state, { payload }): void => {
      state.isCubeOCVisible = payload;
    })
    .addCase(setHasSkeleton, (state, { payload }): void => {
      if (state.hasSkeleton !== payload) {
        state.hasSkeleton = payload;
      }
    })
    .addCase(setIsSidebarHidden, (state, { payload }): void => {
      state.isSidebarHidden = payload;
    })
    .addCase(clearViewerDataState, (): ViewerDataState => initialState);
});

// Thunks

export const setModel = createAsyncThunk<
  Promise<void>,
  { model: Model; signal?: AbortSignal },
  { dispatch: AppDispatch; state: RootState }
>('viewerData/setModel', async ({ model, signal }, { dispatch, getState }): Promise<void> => {
  const { isSceneCompletelyLoaded, model: currentModel } = getState().viewerData;
  setIsViewerClosed(false);
  const modelType = getModelType(model);
  dispatch(setLoadingState(ELoadingState.CHECKING_PERMISSIONS));
  if (modelType === EModelType.TEAM || modelType === EModelType.SHARED) {
    await dispatch(fetchModelPermissions({ model, signal }));
  }
  await dispatch(fetchModelFeatureAccess({ model, signal }));
  if (checkIsViewerClosed()) return;
  dispatch(setModelType(modelType));
  dispatch(setModelAccessLevel(model.accessLevel));
  dispatch(setModelName(model.modelName));
  dispatch(setLoadingState(ELoadingState.PREPARING_SCENE));
  dispatch(setViewerModel(model));
  if (isSceneCompletelyLoaded || !!currentModel) {
    dispatch(setLoadingState(ELoadingState.LOADED));
    setTimeout((): void => {
      dispatch(setIsViewerLoading(false));
    }, 500);
  }
});

export const uploadModelScreenshot = createAsyncThunk<
  Promise<void>,
  { modelId: string },
  { dispatch: AppDispatch; state: RootState }
>('viewerData/uploadModelScreenshot', async ({ modelId }, { getState }): Promise<void> => {
  try {
    const { screenshot } = getState().viewerData;
    if (!!screenshot) {
      const imageFormData: FormData = convertDataURLtoFormData(screenshot, 'cover_image.jpeg');
      await uploadModelCoverImage(modelId, imageFormData);
    }
  } catch (e) {
    openNotification(ESnackbarStyle.HOLD_UP, e?.message);
  }
});
