import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { resetUserViews } from 'services/api/adminService';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';

type Props = {
  userId: number;
};

const ModalResetViews: React.FC<Props> = ({ userId }): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleResetButtonClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await resetUserViews(userId);
      openNotification(ESnackbarStyle.SUCCESS, 'Views counter successfully reset');
      dispatch(closeConfirmModal());
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.Notification>
        Are you sure that you want to reset the view count for this user?
      </Styled.Notification>
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton className='reset-button' onClick={handleResetButtonClick}>
          Reset
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalResetViews;
