import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';

export const SCENE_SCREENSHOT_TYPES = {
    '2160px': EScreenshotTypes.uScene2160pSs,
    '1080px': EScreenshotTypes.uScene1080pSs,
    '540px': EScreenshotTypes.uScene540pSs
};

export const MODEL_SCREENSHOT_TYPES = {
    '2160px': EScreenshotTypes.uModel2160pSs,
    '1080px': EScreenshotTypes.uModel1080pSs,
    '540px': EScreenshotTypes.uModel540pSs
};