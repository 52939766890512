export enum E3DModelFileTypes {
  gltf = 'gltf',
  glb = 'glb',
  fbx = 'fbx',
  obj = 'obj',
  stl = 'stl',
  usdz = 'usdz',
  usd = 'usd',
  usda = 'usda',
  usdc = 'usdc'
}
