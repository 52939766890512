import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const SubscriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1620px;
`;

export const BillingTypeSwitcher = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const BillingTypeText = styled.span`
  font-size: 14px;
  line-height: 17px;
`;

export const BillingTypeButton = styled.button<{ isActive: boolean }>`
  margin-left: 10px;
  width: 100px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive }): string => (isActive ? COLORS.turbo : '#7E7E7E')};
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: ${COLORS.black};
  border-radius: 4px;
`;

export const PlansContainer = styled.div`
  display: flex;

  @media ${DEVICE.desktop} {
    width: 100%;
    flex-direction: column;
  }
`;

export const LineWrapper = styled.div`
  margin-top: 20px;
  padding: 60px 15px 30px 20px;
  display: flex;
  justify-content: center;

  & > div {
    height: 100%;
    width: 2px;
    background: rgba(120, 120, 120, 0.32);
  }

  @media ${DEVICE.desktop} {
    display: none;
  }
`;

export const SubscriptionContainer = styled.div<{ planId: string; activePlanId: string }>`
  width: 100%;

  @media ${DEVICE.desktop} {
    display: ${({ planId, activePlanId }): string => (planId === activePlanId ? 'flex' : 'none')};
    justify-content: center;
  }
`;

export const PlanTabsContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: flex-end;
  margin-top: 30px;

  @media ${DEVICE.desktop} {
    display: flex;
  }
`;

export const PlanTabContainer = styled.div<{ withCustomPlan: boolean }>`
  display: none;
  flex-direction: column;
  width: ${({ withCustomPlan }): string => (withCustomPlan ? '25%' : '33%')};

  &:not(:first-child) {
    margin-left: 10px;
  }

  @media ${DEVICE.desktop} {
    display: flex;
  }

  @media ${DEVICE.mobile} {
    &:not(:first-child) {
      margin-left: 2px;
    }
  }
`;

export const MostPopularLabel = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 26px;
  color: ${COLORS.black};
  background: ${COLORS.turbo};
  opacity: ${({ isActive }): string => (isActive ? '0.6' : '1')};
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px 4px 0 0;
  padding-top: 2px;
  margin-bottom: -4px;

  @media ${DEVICE.mobile} {
    font-size: 8px;
    margin-bottom: -8px;
  }
`;

export const PlanTab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  background: ${({ isActive }): string =>
    isActive ? COLORS.turbo : 'linear-gradient(352.13deg, #1b1a1a 3.17%, #464646 103.41%)'};
  color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.white)};
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  z-index: 2;

  &:hover {
    transition: all 0.1s;
    background: ${COLORS.turbo};
    color: ${COLORS.black};
  }

  @media ${DEVICE.mobile} {
    height: 33px;
    font-size: 12px;
    border-radius: 6px 6px 0 0;
  }
`;
