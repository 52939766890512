import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const SettingsSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 440px;
  padding: 30px 20px;
  background-color: ${COLORS.mineShaft};
  border-radius: 7px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media ${DEVICE.mobile} {
    width: 100%;
    padding: 20px 10px;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SettingsSectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SettingsSectionTitle = styled.h2`
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const SettingsSectionTitleHint = styled.img`
  margin: 2px 0 0 8px;
  width: 12px;
  height: 12px;
`;

export const SectionTitleTooltip = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: ${COLORS.white};
  text-align: center;
`;

export const SettingsLimits = styled.span`
  width: max-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  color: ${COLORS.white};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const SettingsField = styled.div`
  display: flex;
`;

export const FieldLabel = styled.label`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${COLORS.white};
  width: 60px;
  min-width: 60px;
`;

export const FieldInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 52px;
  width: 100%;
  max-width: calc(100% - 70px);
  margin-left: 10px;

  .hint {
    margin-top: 6px;
    margin-left: auto;
    font-size: 10px;
    line-height: 11px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  right: 30px;
  top: 3px;
  background: transparent;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const UploadEnvButtonContainer = styled.div`
  min-width: 60px;
  display: flex;
  justify-content: flex-end;
`;

export const UploadEnvButton = styled.div`
  margin-top: 4px;
  width: 21px;
  height: 21px;
  cursor: pointer;

  input {
    display: none;
  }

  img {
    width: 21px;
    height: 21px;
  }
`;

export const EnvironmentsSettings = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const TransformFieldContainer = styled.div`
  height: 56px;
  margin-left: 10px;
  width: 100%;
`;

export const SaveButton = styled.button`
  margin: 20px 0 0 auto;
  width: 118px;
  height: 33px;
  background-color: ${COLORS.turbo};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: ${COLORS.black};
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.gorse};
  }
`;
