import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 75px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyLogo = styled.img`
  max-width: 75px;
  max-height: 50px;
  object-fit: contain;
`;

export const CompanyTitle = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  max-width: 600px;
  word-break: break-word;

  .title {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--text-color);
  }

  .subtitle {
    font-size: 12px;
    line-height: 13px;
    margin: 7px 0 0 0;
    color: var(--text-color);
  }

  @media ${DEVICE.desktop} {
    max-width: 400px;
  }

  @media ${DEVICE.tabletLarge} {
    max-width: 280px;
    margin-left: 10px;

    .title {
      font-size: 18px;
      line-height: 20px;
    }

    .subtitle {
      font-size: 10px;
      line-height: 11px;
      margin: 7px 0 0 0;
    }
  }

  @media ${DEVICE.tablet} {
    max-width: 330px;
  }
`;
