import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

export const PageSubtitle = styled.h6`
  margin: 5px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: ${COLORS.white};
`;

export const PageContent = styled.div`
  margin-top: 30px;
  display: flex;
`;

export const LeftSide = styled.section`
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-direction: column;
`;

export const WorkspaceNameField = styled.div`
  display: flex;
`;

export const NameFieldLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.white};
  margin-right: 30px;
  margin-top: 10px;
  white-space: nowrap;
`;

export const NameFieldInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 58px;
`;

export const NameFieldInput = styled.input<{isError: boolean}>`
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  color: ${COLORS.white};
  border: ${({isError}): string => isError ? `2px solid ${COLORS.red}` : 'none'};
  border-radius: 6px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  font-weight: 600;

  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
  }

  &::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
  }
`;
