import React from 'react';

const BurgerMenuIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%'
        }}
        viewBox='0 0 24 24'
        preserveAspectRatio='xMidYMax meet'
      >
        <g clipPath='url(#clip0_429_11066)'>
          <path
            d='M3 6.00092H21M3 12.0009H21M3 18.0009H21'
            stroke='currentColor'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_429_11066'>
            <rect width='24' height='24' fill='white' transform='translate(0 0.000915527)' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BurgerMenuIcon;
