import { Buffer } from 'buffer';
import type { GLB, GLTF } from '../interfaces/model-parse/gltf-types';
import { isGLB, parseGLBSync } from './glb-parser';
import { upgradeGltfV1 } from './gltf-v1-convertor';
import type {
  IGeometry3DStats,
  IModelParserService,
  IResources3DStats,
  ITexture2DStats
} from '../interfaces/model-parse/model-stats';
import type { Model3DAnimationStats } from '../interfaces/3d-models/model-3d-resources.interface';
import type { Model3DFilePartStats } from '../interfaces/3d-models';

import { Texture2DStats } from '../texture-2d-stats';
import { getBinaryImageMetadata } from './extenstions/image-utils';
import { fetchBuffer } from '../utils/buffer';
import { imageMetadata } from '../texture-parser/texture-parser';

const UNLIT_MATERIAL_FEATURE = 'KHR_materials_unlit';
const PRIMITIVE_TRIANGLES = [4 /* TRIANGLES */, 5 /* TRIANGLE_STRIP */, 6 /* TRIANGLE_FAN */];

export class GLBParserService implements IModelParserService {
  protected _textureStats: Texture2DStats = new Texture2DStats();
  protected _resourceStats: IResources3DStats = {
    materialsCount: 0,
    texturesCount: 0,
    animationsCount: 0,
    meshesCount: 0,
    scenesCount: 0
  };
  protected _geometryStats: IGeometry3DStats = {
    trianglesCount: 0,
    vertexesCount: 0,
    polygonsCount: 0
  };

  /** 0 - none, 1 - lit, 2 - unlit, 3 - mixed */
  protected _unlitFlag: number = 0;
  protected _gltfSize: number = 0;
  protected _format: string = '';

  private _accessors: GLTF.Accessor[] = [];
  private _animations: GLTF.Animation[] = [];
  private _bufferViews: GLTF.BufferView[] = [];
  private _buffers: GLTF.ByteBuffer[] = [];

  public static canHandleFileType(ext: string): boolean {
    return this.supportedFileTypes.includes(ext);
  }

  public static get supportedFileTypes(): string[] {
    return ['glb', 'gltf'];
  }

  public static dummyTextureStats(): ITexture2DStats {
    const dummyRecord = {
      totalCount: 0,
      png: {
        count: 0,
        maxSize: 0,
        minSize: 0
      },
      jpeg: {
        count: 0,
        maxSize: 0,
        minSize: 0
      }
    };

    return {
      totalCount: 0,
      '4096+': { ...dummyRecord },
      '4096': { ...dummyRecord },
      '2048': { ...dummyRecord },
      '1024': { ...dummyRecord },
      '512': { ...dummyRecord },
      '256': { ...dummyRecord },
      '128': { ...dummyRecord },
      NPOT: { ...dummyRecord }
    };
  }

  constructor() {
    this.resetParserState();
  }

  public get unlitFlag(): number {
    return this._unlitFlag;
  }

  public get format(): string {
    return this._format;
  }

  public get fileSize(): Model3DFilePartStats {
    return {
      totalSize: this.binSize + this.gltfSize,
      gltfSize: this.gltfSize,
      binSize: this.binSize
    };
  }

  public get vertexesCount(): number {
    return this._geometryStats.vertexesCount;
  }

  public get trianglesCount(): number {
    return this._geometryStats.trianglesCount;
  }

  public get quadsCount(): number {
    return 0;
  }

  public get polygonsCount(): number {
    return 0;
  }

  public get meshCount(): number {
    return this._resourceStats.meshesCount;
  }

  public get materialsCount(): number {
    return this._resourceStats.materialsCount;
  }

  public get scenesCount(): number {
    return this._resourceStats.scenesCount;
  }

  public get animations(): Model3DAnimationStats {
    return {
      totalCount: this._resourceStats.animationsCount,
      animations: this._animations.map((anim): string => anim.name).filter((el): boolean => !!el)
    };
  }

  public get textures(): ITexture2DStats {
    return this._textureStats.plainObject();
  }

  protected get gltfSize(): number {
    return this._gltfSize || 0;
  }

  protected get binSize(): number {
    return this._buffers.reduce((acc: number, buffer): number => {
      return acc + buffer.byteLength;
    }, 0);
  }

  protected resetParserState(): void {
    this._textureStats = new Texture2DStats();

    this._unlitFlag = 0;
    this._format = '';

    this._resourceStats = {
      materialsCount: 0,
      texturesCount: 0,
      animationsCount: 0,
      meshesCount: 0,
      scenesCount: 0
    };

    this._geometryStats = {
      trianglesCount: 0,
      vertexesCount: 0
    };
  }

  protected parseGLBSync(file: Uint8Array): GLB {
    const gltf: GLB = {} as GLB;
    parseGLBSync(gltf, file);

    return gltf;
  }

  protected parseGLTFSync(mainFile: Uint8Array): GLB | null {
    const gltfData = mainFile;
    const content = new TextDecoder().decode(gltfData);

    // If data is binary and starting with magic bytes, assume binary JSON text, convert to string
    if (gltfData?.buffer instanceof ArrayBuffer && isGLB(gltfData)) {
      const glb: GLB = {} as GLB;
      parseGLBSync(glb, gltfData);
      return glb;
    }

    let json: GLTF;
    try {
      json = JSON.parse(content);
      json.manifestSize = gltfData.byteLength;
    } catch (ex) {
      return null;
    }

    const gltf: GLB = {
      type: 'gltf',
      version: 2,
      json,
      binChunks: []
    };

    let version = json.asset && json.asset.version;

    if (version === '1.0' || version == null) {
      upgradeGltfV1(gltf);
      version = '2.0';
    }

    if (version === '2.0') {
      return gltf;
    }

    return null;
  }

  public async parse(
    ext: string,
    mainFile: Record<string, string>,
    assets?: Record<string, string>
  ): Promise<boolean> {
    let gltf: GLB | null = null;
    let assetsKeys: string[] = [];

    const mainFileKey = Object.keys(mainFile)[0];

    if (ext === 'glb' && mainFileKey) {
      const buffer = await fetchBuffer(mainFile[mainFileKey]);
      gltf = this.parseGLBSync(new Uint8Array(buffer));
    }

    if (ext === 'gltf') {
      const buffer = await fetchBuffer(mainFile[mainFileKey]);
      const gltfData = new Uint8Array(buffer);
      gltf = this.parseGLTFSync(gltfData);
      if (assets) assetsKeys = Object.keys(assets);

      const buffers = gltf?.json?.buffers || [];
      for (const buff of buffers) {
        if (buff.uri && gltf?.binChunks) {
          const path = assetsKeys.find((key): boolean => key.includes(buff.uri || ''));
          if (path && assets) {
            const buffer = await fetchBuffer(assets[path]);
            const data = new Uint8Array(buffer);
            gltf.binChunks.push({
              byteOffset: data.byteOffset,
              byteLength: data.byteLength,
              arrayBuffer: data.buffer
            });
          }
        } else {
          if (gltf?.binChunks) {
            gltf.binChunks.push({
              byteOffset: gltfData.byteOffset,
              byteLength: gltfData.byteLength,
              arrayBuffer: gltfData.buffer
            });
          }
        }
      }
    }

    if (!gltf || !gltf?.json) {
      return false;
    }

    this._format = `.${ext}`;
    this._gltfSize = gltf.json.manifestSize;

    this._buffers = gltf.binChunks || [];
    this._accessors = gltf.json.accessors || [];
    this._animations = gltf.json.animations || [];
    this._bufferViews = gltf.json.bufferViews || [];

    const textures = gltf.json.textures || [];
    const materials = gltf.json.materials || [];
    const meshes = gltf.json.meshes || [];

    for (const mat of materials) {
      if (!mat.extensions) {
        // tslint:disable-next-line:no-bitwise
        this._unlitFlag |= 1;
        continue;
      }

      const extensions = Array.isArray(mat.extensions)
        ? mat.extensions
        : Object.keys(mat.extensions);

      const unlit = extensions.includes(UNLIT_MATERIAL_FEATURE);
      // tslint:disable-next-line:no-bitwise
      this._unlitFlag |= unlit ? 2 : 1;
    }

    if (gltf.json.images) {
      const repeatedTexturesAssets: string[] = [];

      for (const tex of textures) {
        let texImage = null;
        let texSource: GLTF.Image;

        if (ext === 'gltf' && (tex.source || tex.source === 0)) {
          texSource = gltf.json.images[tex.source];
          const path = assetsKeys.find((key): boolean => key.includes(texSource?.uri || ''));
          if (path && assets) {
            const buffer = await fetchBuffer(assets[path]);
            const data = new Uint8Array(buffer);
            texImage = imageMetadata(data);

            if (texSource.uri && !repeatedTexturesAssets.includes(texSource.uri)) {
              gltf.binChunks?.push({
                byteOffset: data.byteOffset,
                byteLength: data.byteLength,
                arrayBuffer: data.buffer
              });
              repeatedTexturesAssets.push(texSource.uri);
            }
          }
        } else if (ext === 'glb' && (tex.source || tex.source === 0)) {
          texSource = gltf.json.images[tex.source];
          texImage = this.imageMetadata(texSource);
        }
        this._textureStats.updateTexture2DStats(texImage);
      }
    }

    this.updateResourceStats(gltf.json);
    this.updateMeshStats(meshes);
    return true;
  }

  private updateResourceStats(gltf: GLTF): void {
    this._resourceStats.materialsCount = (gltf.materials || []).length;
    this._resourceStats.texturesCount = (gltf.textures || []).length;
    this._resourceStats.animationsCount = (gltf.animations || []).length;
    this._resourceStats.meshesCount = (gltf.meshes || []).length;
    this._resourceStats.scenesCount = (gltf.scenes || []).length;
  }

  protected bufferView(index: number): DataView {
    const bufferView = this._bufferViews[index];
    const buffer = this._buffers[bufferView.buffer];
    let dataOffset = 0;

    if (bufferView.byteOffset || bufferView.byteOffset === 0) {
      dataOffset = bufferView.byteOffset + buffer.byteOffset;
    }
    return new DataView(buffer.arrayBuffer, dataOffset, bufferView.byteLength);
  }

  protected imageMetadata(image: GLTF.Image): GLTF.ImageMetadata | null {
    let texDataView: DataView | null = null;

    if (image.bufferView || image.bufferView === 0) texDataView = this.bufferView(image.bufferView);

    if (!texDataView) {
      return null;
    }

    const texImageMeta = getBinaryImageMetadata(texDataView);
    if (texImageMeta) texImageMeta.byteLength = texDataView.byteLength;

    return texImageMeta;
  }

  private updateMeshStats(meshes: GLTF.Mesh[]): void {
    for (const mesh of meshes) {
      const primitives = mesh.primitives || [];
      for (const prim of primitives) {
        // We interesting only in triangles (as polygons and quads are not supported as primitives)
        if (prim.mode == null || PRIMITIVE_TRIANGLES.includes(prim.mode)) {
          const vertAttribute = prim.attributes && prim.attributes.POSITION;

          const vertices = vertAttribute != null ? this._accessors[vertAttribute] : null;
          const indexes = prim.indices != null ? this._accessors[prim.indices] : null;

          if (vertices != null) {
            this.updateVertexStats(vertices, prim.mode, indexes);
          }
        }
      }
    }
  }

  private updateVertexStats(
    vertices: GLTF.Accessor,
    mode?: number,
    indexes?: GLTF.Accessor | null
  ): void {
    const idxCount = indexes?.count != null ? indexes.count : vertices.count;
    const vertCount = vertices.count;

    if (mode == null || mode === 4 /* gl.TRIANGLES */) {
      this._geometryStats.vertexesCount += vertCount;
      this._geometryStats.trianglesCount += idxCount / 3;
    }

    if (mode === 5 /* gl.TRIANGLE_STRIP */) {
      this._geometryStats.vertexesCount += vertCount;
      this._geometryStats.trianglesCount += idxCount - 2;
    }

    if (mode === 6 /* gl.TRIANGLE_FAN */) {
      this._geometryStats.vertexesCount += vertCount;
      this._geometryStats.trianglesCount += idxCount - 2;
    }
  }

  protected decodeUriData(uri: string): Buffer {
    let data = uri.substring(0);

    let enc0 = 'url';
    let char = 'ascii';

    const dataStart = data.indexOf(',');

    // Assuming mime-type followed by other props
    let haveMore = data.indexOf(';');
    let nextPart = haveMore >= 0 ? haveMore : dataStart;

    if (nextPart >= 0) {
      data = uri.substring(nextPart + 1);
    }

    haveMore = data.indexOf(';');
    nextPart = haveMore >= 0 ? haveMore : dataStart;

    if (data.startsWith('charset=')) {
      const charSet = data.substring(0, nextPart);
      char = charSet.split('=')[1] || char;
      if (haveMore >= 0) {
        data = uri.substring(haveMore + 1);
      }
    }

    if (data[0] === ';' || data.startsWith('base64')) {
      enc0 = 'base64';
    }

    data = uri.substring(dataStart + 1);

    if (enc0 !== 'base64') {
      data = decodeURIComponent(data);
      enc0 = char;
    }

    return Buffer.from(data, enc0 as BufferEncoding);
  }
}
