import React from 'react';

export const CommentCubeIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 43 37'
        fill='none'
        style={{ position: 'absolute', width: '100%', height: '100%', left: 0, bottom: '-3px' }}
      >
        <path d='M13.5 11.5L28 1L42 11.5V30L28 37H2L13.5 30V11.5Z' fill='black' />
        <path
          d='M28 1L13.5 11.5V30M28 1V19.5M28 1L42 11.5V30M13.5 30L28 19.5M13.5 30L28 37M13.5 30L2 37H28M28 19.5L42 30M42 30L28 37'
          stroke='currentColor'
        />
      </svg>
    </div>
  );
};
