import { AxiosResponse } from 'axios';
import ApiService from './api';

type ContactUsRequest = {
  firstName: string;
  lastName: string;
  email: string;
  reason: string;
  text: string;
};

export const sendContactUsRequest = async (payload: ContactUsRequest): Promise<AxiosResponse<void>> =>
  await ApiService.post(`support/contact-me`, payload);
