import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ModalState } from 'shared/types';
import Close from 'assets/images/close.svg';

const ModalWindow = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { modalContent } = useAppSelector((state): ModalState => state.modal);

  const handleCancelButtonClick = (): void => {
    dispatch(closeModal());
  };

  return (
    <>
      <Styled.ModalWindowContainer>
        <Styled.CloseButton onClick={handleCancelButtonClick} src={Close} alt='Close' />
        <Styled.ModalWindowContent>{modalContent}</Styled.ModalWindowContent>
      </Styled.ModalWindowContainer>
      <Styled.ModalWindowOverlay onClick={handleCancelButtonClick} />
    </>
  );
};

export default ModalWindow;
