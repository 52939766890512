import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';
import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import { SceneSimpleCiStrategy } from 'services/strategy-services/create-screenshot-service/strategies/scene-simple-ci.strategy';
import { SceneDefaultCiStrategy } from 'services/strategy-services/create-screenshot-service/strategies/scene-default-ci.strategy';
import { SceneWithCommentSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/scene-with-comment-ss.strategy';
import { CreateScreenshotData, CSInitializationData } from 'shared/interfaces';
import { SceneEnvironmentCiStrategy } from 'services/strategy-services/create-screenshot-service/strategies/scene-environment-ci.strategy';
import { UserSceneSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/user-scene-ss.strategy';
import { UserModelSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/user-model-ss.strategy';

export class CreateScreenshotService {
  private strategies: Map<EScreenshotTypes, BaseSsStrategy>;

  constructor(initializationData: CSInitializationData) {
    this.strategies = new Map<EScreenshotTypes, BaseSsStrategy>([
      [EScreenshotTypes.sceneSimpleCi, new SceneSimpleCiStrategy(initializationData)],
      [EScreenshotTypes.sceneDefaultCi, new SceneDefaultCiStrategy(initializationData)],
      [EScreenshotTypes.sceneWithCommentSs, new SceneWithCommentSsStrategy(initializationData)],
      [EScreenshotTypes.sceneEnvironmentCi, new SceneEnvironmentCiStrategy(initializationData)],
      [
        EScreenshotTypes.uScene540pSs,
        new UserSceneSsStrategy({ ...initializationData, width: 540, height: 304 })
      ],
      [
        EScreenshotTypes.uScene1080pSs,
        new UserSceneSsStrategy({ ...initializationData, width: 1080, height: 608 })
      ],
      [
        EScreenshotTypes.uScene2160pSs,
        new UserSceneSsStrategy({ ...initializationData, width: 2160, height: 1215 })
      ],
      [
        EScreenshotTypes.uModel540pSs,
        new UserModelSsStrategy({ ...initializationData, width: 540, height: 304 })
      ],
      [
        EScreenshotTypes.uModel1080pSs,
        new UserModelSsStrategy({ ...initializationData, width: 1080, height: 608 })
      ],
      [
        EScreenshotTypes.uModel2160pSs,
        new UserModelSsStrategy({ ...initializationData, width: 2160, height: 1215 })
      ]
    ]);
  }

  public async createScreenShot(
    screenshotType: EScreenshotTypes,
    createScreenshotData: CreateScreenshotData
  ): Promise<string> {
    return this.strategies.get(screenshotType)!.createScreenshot(createScreenshotData);
  }
}
