import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal, closeModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { addTeamMember } from 'services/api/teamService';
import { fetchTeam } from 'services/store/reducers/teamsReducer';
import { USER_INVITED_TO_TEAM } from 'shared/constants/notifications';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';

type Props = {
  teamId: number;
  email: string;
  fetchTeamMembers: (teamId: number) => Promise<void>;
};

const ModalInviteToTeam: React.FC<Props> = ({ email, teamId, fetchTeamMembers }): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleInviteButtonClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await addTeamMember(teamId, email, true);
      await fetchTeamMembers(teamId);
      await dispatch(fetchTeam({ teamId }));
      dispatch(closeModal());
      openNotification(ESnackbarStyle.SUCCESS, USER_INVITED_TO_TEAM);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(closeConfirmModal());
      dispatch(stopLoader());
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.Notification>
        {'This user has not registered yet! Would you like to invite them?'}
      </Styled.Notification>
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton className='invite-button' onClick={handleInviteButtonClick}>
          Invite
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalInviteToTeam;
