import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModelUploaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
`;

export const ModelUploaderContainer = styled.section`
  height: calc(100% - 20px);
  width: calc(100% - 54px);

  @media ${DEVICE.tabletLarge} {
    width: calc(100% - 22px);
  }
`;

export const SecureIcon = styled.img`
  width: 70px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.1s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const UploadField = styled.div<{ isDragActive: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${COLORS.turbo};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({ isDragActive }): string =>
    isDragActive ? 'rgba(255, 228, 6, 0.45)' : 'rgba(214, 215, 227, 0.25)'};
`;

export const UploadFieldContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PlusIcon = styled.img`
  height: 34px;
  width: 34px;
`;

export const UploadDescription = styled.div`
  margin-top: 19px;
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  span:last-child {
    font-weight: 300;
  }
`;

export const SampleModelsBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const SampleModelsTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.white};
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  img {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
`;

export const SampleModelsTooltip = styled.div`
  padding: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${COLORS.white};
`;

export const SampleModels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  margin-top: 10px;

  .dot {
    width: 5px;
    height: 5px;
    background: #d9d9d9;
    border-radius: 50%;
  }
`;

export const SampleModel = styled.img`
  transition: all 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  &.yellow-car {
    width: 45px;
    height: 36px;
  }

  &.yellow-bee {
    width: 32px;
    height: 36px;
  }

  &.example-room {
    width: 42px;
    height: 35px;
  }
`;

export const UploaderModeSwitcher = styled.div`
  margin-top: 40px;
  position: relative;
  width: 330px;
  height: 34px;
  background: #a3a3a3;
  border-radius: 8px;
  display: flex;
  align-items: center;

  @media ${DEVICE.mobile} {
    width: 280px;
  }
`;

export const UploaderMode = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const UploaderModeIconWrapper = styled.div<{ isActive: boolean }>`
  width: 22px;
  height: 22px;
  fill: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.turbo)};
  color: ${({ isActive }): string => (isActive ? COLORS.turbo : COLORS.black)};
`;

export const UploaderModeTitle = styled.span`
  margin-left: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${COLORS.black};
`;

export const SwitcherWheel = styled.div<{ isUploadMode: boolean }>`
  position: absolute;
  top: 2px;
  transform: translateX(
    ${({ isUploadMode }): string => (isUploadMode ? 'calc(100% - 2px)' : '2px')}
  );
  transition: all 0.3s;
  width: 50%;
  height: 30px;
  border-radius: 6px;
  background: ${COLORS.turbo};
`;
