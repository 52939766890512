import { message } from 'antd';
import {
  CloseAlertButton,
  CustomAlertContainer,
  CustomAlertContent,
  CustomAlertTitle
} from 'shared/styles';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';

export const closeAlert = (): void => {
  message.destroy();
};

export const openAlert = (content: any, duration: number = 5, title: string = 'Hold Up!'): void => {
  message.open({
    content: (
      <CustomAlertContainer>
        <CustomAlertTitle>
          <ExclamationCircleFilled style={{ color: '#FFE406', fontSize: '28px' }} />
          <h1>{title}</h1>
        </CustomAlertTitle>
        <CustomAlertContent>{content}</CustomAlertContent>
        <CloseAlertButton>
          <CloseOutlined
            onClick={closeAlert}
            style={{ color: '#FFFFFF', fontSize: '22px', cursor: 'pointer' }}
          />
        </CloseAlertButton>
      </CustomAlertContainer>
    ),
    duration
  });
};
