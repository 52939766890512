import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const ServerMaintenanceScreen = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: var(--viewport-height);
  width: 100%;
  background-color: #000000;
`;

export const ScreenHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 82px;
  min-height: 82px;
  padding-left: 16px;
`;

export const ScreenContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vh;
  width: 90%;
  max-width: 1355px;
  height: 100%;
  padding: 20px;

  p {
    color: #ffffff;
    font-size: 26px;
    font-weight: 300;
    line-height: 33px;
    text-align: center;
    margin: 0;
  }

  p.bold-text {
    font-weight: 700;
  }

  @media ${DEVICE.tabletLarge} {
    p {
      font-size: 22px;
      line-height: 29px;
    }
  }

  @media ${DEVICE.tablet} {
    gap: 2vh;

    p {
      font-size: 16px;
      line-height: 23px;
    }
  }
`;

export const EngineeringIcon = styled.img`
  width: 84px;
  height: 84px;

  @media ${DEVICE.tablet} {
    width: 60px;
    height: 60px;
  }
`;
