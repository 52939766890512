import { MAIN_SCENE_CSS2D_CONTAINER_ID } from 'shared/constants/html-elements-ids';

interface ICheckListItemVisibilityOptions {
  topDeviation: number;
  bottomDeviation: number;
  leftDeviation: number;
  rightDeviation: number;
}

interface GetCommentBBProps {
  bubbleElement: HTMLElement;
  pointerElement: HTMLElement;
  toggleElement: HTMLElement;
  isMobile: boolean;
  isPointerVisible: boolean;
  isNewComment?: boolean;
}

interface GetCommentBBResult {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
}

export const checkItemVisibilityToUser = (
  target: HTMLElement,
  boundingElement: HTMLElement,
  options: ICheckListItemVisibilityOptions = {
    topDeviation: 5,
    bottomDeviation: 5,
    rightDeviation: 0,
    leftDeviation: 0
  }
): boolean => {
  const boundingElementRect = boundingElement.getBoundingClientRect();
  const itemRect = target.getBoundingClientRect();

  return (
    itemRect.top >= boundingElementRect.top - options.topDeviation &&
    itemRect.right <= boundingElementRect.right + options.rightDeviation &&
    itemRect.bottom <= boundingElementRect.bottom + options.bottomDeviation &&
    itemRect.left >= boundingElementRect.left - options.leftDeviation
  );
};

export const moveCursorToEndOfContentEditable = (contentEditableElement: HTMLElement): void => {
  if (document.createRange) {
    const range = document.createRange();
    range.selectNodeContents(contentEditableElement);
    range.collapse(false);
    const selection = window.getSelection()!;
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const createMentionSpan = (content: string): HTMLSpanElement => {
  const mentionSpan = document.createElement('span');
  mentionSpan.contentEditable = 'false';
  mentionSpan.className = 'mention';
  mentionSpan.innerHTML = content;

  return mentionSpan;
};

export const pasteAsText = (div: HTMLElement, txt: string): void => {
  const selection = window.getSelection();

  if (!selection) return;

  const range = selection.getRangeAt(0);
  const fragment = range.createContextualFragment(txt);
  range.deleteContents();
  range.insertNode(fragment);
  range.collapse();
};

export const preventDefaults = (event: MouseEvent): boolean => {
  event.preventDefault();

  return false;
};

export const getCommentBB = ({
  bubbleElement,
  pointerElement,
  toggleElement,
  isMobile,
  isPointerVisible,
  isNewComment
}: GetCommentBBProps): GetCommentBBResult | undefined => {
  const parent = bubbleElement.parentElement;

  if (!parent) return;

  const labelRendererElement = document.getElementById(MAIN_SCENE_CSS2D_CONTAINER_ID);

  if (!labelRendererElement) return;

  const optionsSidebarWidth = 44; // fixme: replace by realtime getting
  const footerHeight = isMobile ? 72 : 36; // fixme: replace by realtime getting
  const toggleBoundingBox = toggleElement.getBoundingClientRect();
  const commentBoundingBoxRect = bubbleElement.getBoundingClientRect();
  const pointerBoundingBox = pointerElement.getBoundingClientRect();
  const parentBoundingBox = parent.getBoundingClientRect();
  const commentBoundingBox = {
    top:
      parentBoundingBox.top -
      commentBoundingBoxRect.height -
      (isPointerVisible ? pointerBoundingBox.bottom - commentBoundingBoxRect.bottom : 0),
    right:
      parentBoundingBox.right +
      (isNewComment ? commentBoundingBoxRect.width + toggleBoundingBox.width + 8 : 0),
    bottom: parentBoundingBox.bottom + footerHeight,
    left:
      parentBoundingBox.left -
      (isNewComment ? 0 : commentBoundingBoxRect.width) -
      optionsSidebarWidth
  };

  return {
    ...commentBoundingBox,
    width: commentBoundingBox.right - commentBoundingBox.left,
    height: commentBoundingBox.bottom - commentBoundingBox.top
  };
};

export const checkIfScrollable = (target: HTMLElement, bubbling?: boolean, topLimitElement?: HTMLElement | null): boolean => {
  if (bubbling) {
    let element = target;

    while (element) {
      if ((topLimitElement && element === topLimitElement) || element === document.body) return false;

      if (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) {
        return true;
      }

      if (!element.parentElement) return false;

      element = element.parentElement;
    }
  }

  return target.scrollHeight > target.clientHeight || target.scrollWidth > target.clientWidth;
}

export const checkIfCommentElement = (element: HTMLElement): boolean => {
  while (element) {
    if (element === document.body) return false;

    if (element.id === MAIN_SCENE_CSS2D_CONTAINER_ID) {
      return true;
    }

    if (!element.parentElement) return false;

    element = element.parentElement;
  }

  return false;
}
