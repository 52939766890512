import styled from 'styled-components';
import LogoSignup from 'assets/images/logo-icon-signup.png';
import { DEVICE } from 'shared/constants/deviceSizes';
import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  max-height: 850px;
  width: 90vw;
  height: 95vh;
  color: ${COLORS.white};
  position: relative;
  overflow-x: hidden;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.tablet} {
    flex-direction: column;
    width: 75vw;
  }

  @media${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const LeftSide = styled.section`
  width: 50%;
  padding: 6%;
  overflow-y: scroll;

  @media${DEVICE.tablet} {
    width: 100%;
    overflow-y: initial;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background: rgba(83, 83, 83, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.mischka};
  }
`;

export const LiftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const FormNameBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
`;

export const RightSide = styled.section`
  width: 50%;
  background-image: url(${LogoSignup});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media${DEVICE.tablet} {
    display: none;
  }
`;

export const SuccessfullySentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CheckboxesBlock = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const CheckboxField = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const Checkbox = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.white};
  border-radius: 4px;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const FieldText = styled.div`
  margin-left: 16px;
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  a {
    color: ${COLORS.turbo};
    margin: 0 6px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const RecaptchaContainer = styled.div`
  margin-top: 30px;
`;
