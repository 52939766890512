import React from 'react';

const CreateTeamIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        viewBox='0 0 16 18'
        preserveAspectRatio='xMidYMax meet'
        fill='currentColor'
      >
        <path d='M9.15033 18.1386C8.95118 18.1386 8.79052 18.0779 8.66838 17.9567C8.54711 17.8345 8.48648 17.6739 8.48648 17.4747V10.1723H1.18412C0.98496 10.1723 0.82475 10.106 0.703487 9.97318C0.581338 9.84041 0.520264 9.68551 0.520264 9.50849C0.520264 9.30933 0.581338 9.14868 0.703487 9.02653C0.82475 8.90527 0.98496 8.84463 1.18412 8.84463H8.48648V1.54227C8.48648 1.34311 8.55287 1.18246 8.68564 1.06031C8.81841 0.93905 8.9733 0.878418 9.15033 0.878418C9.34949 0.878418 9.51014 0.93905 9.63229 1.06031C9.75355 1.18246 9.81418 1.34311 9.81418 1.54227V8.84463H17.1165C17.3157 8.84463 17.4764 8.91102 17.5985 9.04379C17.7198 9.17656 17.7804 9.33146 17.7804 9.50849C17.7804 9.70764 17.7198 9.86829 17.5985 9.99044C17.4764 10.1117 17.3157 10.1723 17.1165 10.1723H9.81418V17.4747C9.81418 17.6739 9.7478 17.8345 9.61503 17.9567C9.48226 18.0779 9.32736 18.1386 9.15033 18.1386Z' />
      </svg>
    </div>
  );
};

export default CreateTeamIcon;
