import { EmbeddedModelSettings, EModelType, Model, ModelSettings } from 'shared/types';
import { checkIsIFrame } from './helper-utils';

type ModelDataLocalStorage = {
  lsModelName: string | null;
  lsModelSettings: ModelSettings | null;
  lsModelType: EModelType | null;
};

type ModelInfo = {
  modelName: string;
  modelSettings: ModelSettings;
  modelType: EModelType;
};

type TeamData = {
  teamName: string;
  membersCount: number;
  isMonth: boolean;
  planId: string;
  isBusiness: boolean;
};

enum LSKeys {
  modelName = 'modelName',
  modelSettings = 'modelSettings',
  modelType = 'modelType'
}

type LocationState = {
  from: string | null;
  current: string | null;
};

export const setModelDataLocalStorage = ({
  modelName,
  modelSettings,
  modelType
}: ModelInfo): void => {
  if (!checkIsIFrame()) {
    localStorage.setItem(LSKeys.modelName, JSON.stringify(modelName));
    localStorage.setItem(LSKeys.modelSettings, JSON.stringify(modelSettings));
    localStorage.setItem(LSKeys.modelType, JSON.stringify(modelType));
  }
};

export const getModelDataLocalStorage = (): ModelDataLocalStorage => {
  const modelName = localStorage.getItem(LSKeys.modelName);
  const settings = localStorage.getItem(LSKeys.modelSettings);
  const modelType = localStorage.getItem(LSKeys.modelType);

  return {
    lsModelName: !!modelName ? JSON.parse(modelName) : null,
    lsModelSettings: !!settings ? JSON.parse(settings) : null,
    lsModelType: !!modelType ? JSON.parse(modelType) : null
  };
};

export const clearModelLocalStorage = (): void => {
  if (!checkIsIFrame()) {
    localStorage.removeItem(LSKeys.modelName);
    localStorage.removeItem(LSKeys.modelSettings);
    localStorage.removeItem(LSKeys.modelType);
  }
};

export const setInviteToLocalStorage = (invite: { modelId: string; inviteCode: string }): void => {
  localStorage.setItem('invite', JSON.stringify(invite));
};

export const setTeamInviteToLocalStorage = (teamInviteLink: string): void => {
  localStorage.setItem('teamInviteLink', JSON.stringify(teamInviteLink));
};

export const getTeamInviteFromLocalStorage = (): string | null => {
  const teamInviteLink = localStorage.getItem('teamInviteLink');
  return !!teamInviteLink ? JSON.parse(teamInviteLink) : null;
};

export const removeTeamInviteFromLocalStorage = (): void => {
  localStorage.removeItem('teamInviteLink');
};

export const getInviteFromLocalStorage = (): { modelId: string; inviteCode: string } | null => {
  const invite = localStorage.getItem('invite');
  return !!invite ? JSON.parse(invite) : null;
};

export const removeInviteFromLocalStorage = (): void => {
  localStorage.removeItem('invite');
};

export const getModelFromSessionStorage = (): Model | null | undefined => {
  if (!checkIsIFrame()) {
    const storageData: string | null = sessionStorage.getItem('viewedModel');
    return storageData && JSON.parse(storageData);
  }
};

export const setModelToSessionStorage = (model: Model): void => {
  if (!checkIsIFrame()) {
    sessionStorage.setItem('viewedModel', JSON.stringify(model));
  }
};

export const updateModelInSessionStorage = (updatedData: any): void => {
  if (!checkIsIFrame()) {
    const model = getModelFromSessionStorage();
    if (!!model) {
      sessionStorage.setItem('viewedModel', JSON.stringify({ ...model, ...updatedData }));
    }
  }
};

export const removeModelDataFromSessionStorage = (): void => {
  if (!checkIsIFrame()) {
    sessionStorage.removeItem('viewedModel');
  }
};

export const getLocationStateFromSessionStorage = (): LocationState => {
  if (!checkIsIFrame()) {
    const locationState = sessionStorage.getItem('locationState');
    return !!locationState ? JSON.parse(locationState) : { current: null, from: null };
  }
  return { current: null, from: null };
};

export const setIsViewerClosed = (isViewerClosed: boolean): void => {
  if (!checkIsIFrame()) {
    sessionStorage.setItem('isViewerClosed', JSON.stringify(isViewerClosed));
  }
};

export const checkIsViewerClosed = (): boolean => {
  if (!checkIsIFrame()) {
    const storageData: string | null = sessionStorage.getItem('isViewerClosed');
    return !!(storageData && JSON.parse(storageData));
  }
  return false;
};

export const setTeamDataToLocalStorage = (teamData: TeamData): void => {
  localStorage.setItem('teamData', JSON.stringify(teamData));
};

export const getTeamDataFromLocalStorage = (): TeamData => {
  const teamData = localStorage.getItem('teamData');
  return !!teamData ? JSON.parse(teamData) : null;
};

export const clearTeamDataLocalStorage = (): void => {
  localStorage.removeItem('teamData');
};

export const setIsEmbeddedModelSettingsActive = (isActive: boolean): void => {
  if (!checkIsIFrame()) {
    localStorage.setItem('isEmbeddedModelSettingsActive', JSON.stringify(isActive));
  }
};

export const getEmbeddedModelSettingsStatus = (): boolean => {
  if (!checkIsIFrame()) {
    const isEmbeddedModelSettingsActive = localStorage.getItem('isEmbeddedModelSettingsActive');
    return !!isEmbeddedModelSettingsActive ? JSON.parse(isEmbeddedModelSettingsActive) : null;
  }
  return false;
};

export const saveEmbeddedModelSettings = (settings: EmbeddedModelSettings): void => {
  if (!checkIsIFrame()) {
    localStorage.setItem('embeddedModelSettings', JSON.stringify(settings));
  }
};

export const getEmbeddedModelSettings = (): EmbeddedModelSettings | null => {
  const embeddedModelSettings = localStorage.getItem('embeddedModelSettings');
  return !!embeddedModelSettings ? JSON.parse(embeddedModelSettings) : null;
};

export const clearEmbeddedModelSettings = (): void => {
  if (!checkIsIFrame()) {
    localStorage.removeItem('embeddedModelSettings');
  }
};
