import React, { useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import { SubscriptionPlan } from 'shared/components';
import { SubscriptionsState } from 'shared/types';
import { useAppSelector } from 'shared/hooks';
import { extractPlanData } from 'utils/subscription-plans-utils';

type Props = {
  isModal?: boolean;
  withCustomPlan?: boolean;
};

const Subscriptions: React.FC<Props> = ({ isModal, withCustomPlan }): JSX.Element => {
  const { subscriptionPlans } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const [isMonthlyType, setIsMonthlyType] = useState<boolean>(true);
  const [activePlanId, setActivePlanId] = useState<string>('');
  const customPlanId = useMemo((): string => (Date.now() * Math.random()).toString(), []);

  useEffect((): void => {
    setActivePlanId(subscriptionPlans[0]?.id || '');
  }, [subscriptionPlans]);

  const handlePlanTabClick =
    (id: string): (() => void) =>
    (): void => {
      setActivePlanId(id);
    };

  return (
    <Styled.SubscriptionsContainer>
      <Styled.BillingTypeSwitcher>
        <Styled.BillingTypeText>Billing Type:</Styled.BillingTypeText>
        <Styled.BillingTypeButton
          onClick={(): void => setIsMonthlyType(true)}
          isActive={isMonthlyType}
        >
          Monthly
        </Styled.BillingTypeButton>
        <Styled.BillingTypeButton
          onClick={(): void => setIsMonthlyType(false)}
          isActive={!isMonthlyType}
        >
          Yearly
        </Styled.BillingTypeButton>
      </Styled.BillingTypeSwitcher>
      <Styled.PlanTabsContainer>
        {subscriptionPlans.map((plan): JSX.Element => {
          const { title, isMostPopular } = extractPlanData(plan);
          return (
            <Styled.PlanTabContainer withCustomPlan={!!withCustomPlan} key={plan.id}>
              {isMostPopular && (
                <Styled.MostPopularLabel isActive={activePlanId === plan.id}>
                  MOST POPULAR
                </Styled.MostPopularLabel>
              )}
              <Styled.PlanTab
                isActive={activePlanId === plan.id}
                onClick={handlePlanTabClick(plan.id)}
              >
                {title}
              </Styled.PlanTab>
            </Styled.PlanTabContainer>
          );
        })}
        {withCustomPlan && (
          <Styled.PlanTabContainer withCustomPlan={withCustomPlan}>
            <Styled.PlanTab
              isActive={activePlanId === customPlanId}
              onClick={handlePlanTabClick(customPlanId)}
            >
              Hive
            </Styled.PlanTab>
          </Styled.PlanTabContainer>
        )}
      </Styled.PlanTabsContainer>
      <Styled.PlansContainer>
        {subscriptionPlans.map(
          (plan): JSX.Element => (
            <Styled.SubscriptionContainer
              planId={plan.id}
              activePlanId={activePlanId}
              key={plan.id}
            >
              <SubscriptionPlan plan={plan} isMonthlyType={isMonthlyType} isModal={isModal} />
            </Styled.SubscriptionContainer>
          )
        )}
        {withCustomPlan && (
          <>
            <Styled.LineWrapper>
              <div />
            </Styled.LineWrapper>
            <Styled.SubscriptionContainer planId={customPlanId} activePlanId={activePlanId}>
              <SubscriptionPlan isMonthlyType={isMonthlyType} isModal={isModal} isCustomPlan />
            </Styled.SubscriptionContainer>
          </>
        )}
      </Styled.PlansContainer>
    </Styled.SubscriptionsContainer>
  );
};

export default Subscriptions;
