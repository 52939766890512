import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  height: 95vh;
  max-height: 1000px;
  max-width: 1300px;
  width: 90vw;
  color: ${COLORS.white};
  position: relative;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.tablet} {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const LeftSide = styled.section`
  position: relative;
  width: 40%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  padding: 30px 2% 30px 4%;

  @media${DEVICE.desktop} {
    width: 50%;
  }

  @media${DEVICE.tablet} {
    order: 1;
    width: 100%;
    padding: 30px;
  }
`;

export const RightSideContainer = styled.section`
  width: 60%;
  overflow-y: scroll;

  @media${DEVICE.desktop} {
    width: 50%;
  }

  @media${DEVICE.tablet} {
    width: 100%;
    overflow-y: initial;
  }
`;

export const RightSide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 4% 4% 2%;

  @media${DEVICE.tablet} {
    width: 100%;
    padding: 30px;
  }
`;

export const SectionIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 100px;
  height: 140px;
  z-index: -1;

  @media${DEVICE.tablet} {
    bottom: initial;
    top: 50%;
    transform: translate(40%, -60%);
  }
`;

export const ModelTitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Lock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-left: 30px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const FeatureGifContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  img {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 2px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${COLORS.white};
`;

export const EnterprisePlanLink = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  span {
    font-size: 13px;
    line-height: 15px;
  }

  span:first-child {
    margin-right: 5px;
  }

  span:last-child {
    color: ${COLORS.turbo};
    cursor: pointer;
  }
`;
