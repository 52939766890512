export const FONTS = {
  rubikLight: 'Rubik Light',
  rubikBlack: 'Rubik Black',
  rubikMedium: 'Rubik Medium',
  rubikRegular: 'Rubik Regular',
  rubikBold: 'Rubik Bold',
  rubikSemiBold: 'Rubik SemiBold',
  rubikExtraBold: 'Rubik ExtraBold',
  interThin: 'Inter Thin',
  interRegular: 'Inter Regular, sans-serif',
  interLight: 'Inter Light',
  interExtraLight: 'Inter ExtraLight',
  interExtraBold: 'Inter ExtraBold',
  interSemiBold: 'Inter SemiBold',
  interBlack: 'Inter Black',
  interBold: 'Inter Bold',
  interMedium: 'Inter Medium',
  robotoMonoRegular: 'Roboto Mono Regular'
};
