import React from 'react';
import * as Styled from './styles';
import Close from 'assets/images/close.svg';
import { getFieldError } from 'utils/form-utils';
import { useFormik } from 'formik';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { TEAM_NAME_CHANGED } from 'shared/constants/notifications';
import { validation } from 'services/validation';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { ESnackbarStyle, TeamsState } from 'shared/types';
import { updateTeamName } from 'services/api/teamService';
import { fetchTeam, fetchUserTeams } from 'services/store/reducers/teamsReducer';

type Props = {
  teamName: string;
  closeTab: () => void;
};

const ChangeTeamName: React.FC<Props> = ({ teamName, closeTab }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);

  const initialValues: { teamName: string } = {
    teamName
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ teamName }): Promise<void> => {
      dispatch(startLoader());
      try {
        if (!!activeTeam) {
          await updateTeamName(activeTeam.id, teamName.trim());
          await dispatch(fetchTeam({ teamId: activeTeam.id }));
          await dispatch(fetchUserTeams());
          openNotification(ESnackbarStyle.SUCCESS, TEAM_NAME_CHANGED);
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.TEAM_NAME
  });

  const handleEnterClick = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') formik.handleSubmit();
  };

  const handleSaveButtonClick = async (): Promise<void> => {
    formik.handleSubmit();
  };

  const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    formik.setFieldValue('teamName', value.trimStart());
  };

  return (
    <Styled.ContentContainer>
      <Styled.FormTitle>Change Team Name</Styled.FormTitle>
      <Styled.ChangeNameField>
        <Styled.InputContainer>
          <Styled.ChangeNameInput
            value={formik.values.teamName}
            name='teamName'
            onChange={handleTeamNameChange}
            onKeyUp={handleEnterClick}
            onBlur={formik.handleBlur}
            placeholder='Enter team name'
          />
          <Styled.InputFooter>
            {getFieldError(formik, 'teamName') || (
              <>
                {!!formik.values.teamName.length && <span className='hint'>Press enter to submit</span>}
              </>
            )}
          </Styled.InputFooter>
        </Styled.InputContainer>
      </Styled.ChangeNameField>
      <Styled.FormFooter>
        <Styled.FormButton className='cancel-button' onClick={closeTab}>
          Cancel
        </Styled.FormButton>
        <Styled.FormButton
          className='save-button'
          onClick={handleSaveButtonClick}
          disabled={!formik.values.teamName.length}
        >
          Save
        </Styled.FormButton>
      </Styled.FormFooter>
      <Styled.CloseButton src={Close} alt='Close' onClick={closeTab} />
    </Styled.ContentContainer>
  );
};

export default ChangeTeamName;
