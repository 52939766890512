export class BlackBackgroundBeforeEightWall {
  public static init(eightWallCanvas: HTMLCanvasElement): void {
    const blackBackground = document.createElement('div');

    blackBackground.id = 'blackBackgroundBeforeEighthWall';
    blackBackground.style.zIndex = eightWallCanvas.style.zIndex;

    eightWallCanvas.before(blackBackground);
  }

  public static remove(): void {
    document.getElementById('blackBackgroundBeforeEighthWall')?.remove();
  }
}
