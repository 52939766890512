import { useCallback, useEffect, useRef, useState } from 'react';
import { ESnackbarStyle, Model, TeamsState } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getModel } from 'services/api/modelService';
import { getTeamModels } from 'services/api/teamService';
import { useLocation } from 'react-router';

type FetchParams = { page: number; limit?: number; search?: string };

type Result = {
  modelsData: Model[];
  fetchModel: (modelId: string) => Promise<void>;
  isSearchMode: boolean;
  setSearch: (value: string) => void;
  setIsSearchMode: (value: boolean) => void;
  resetModelList: () => Promise<void>;
  handlePageChange: () => Promise<void>;
  updateModelList: () => Promise<void>;
  clearTeamModelDataState: () => void;
};

const useTeamModelsData = (): Result => {
  const LIMIT = 12;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const [modelsData, setModelsData] = useState<Model[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const isFirstPageVisit = useRef<boolean>(true);

  useEffect((): void => {
    isFirstPageVisit.current = true;
  }, [location]);

  const fetchModels = useCallback(
    async ({ page, limit, search }: FetchParams): Promise<Model[] | undefined> => {
      dispatch(startLoader());
      try {
        if (!!activeTeam) {
          return (await getTeamModels(activeTeam.id, page, limit || LIMIT, search)).data.items;
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, activeTeam]
  );

  const handleSearchChange = useCallback(async (): Promise<void> => {
    setModelsData([]);
    const models = await fetchModels({ page: 1, search });
    if (!!models?.length) {
      setModelsData(models);
      setCurrentPage(1);
      setIsSearchMode(!!search);
    }
  }, [fetchModels, search]);

  useEffect((): void => {
    if (!isFirstPageVisit.current) {
      handleSearchChange();
    }
    isFirstPageVisit.current = false;
  }, [handleSearchChange]);

  const handlePageChange = useCallback(async (): Promise<void> => {
    const models = await fetchModels({ page: currentPage + 1, search });
    if (!!models?.length) {
      setModelsData((prev): Model[] => [...prev, ...models]);
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, search, fetchModels]);

  const updateModelList = useCallback(async (): Promise<void> => {
    const models = await fetchModels({ page: 1, search, limit: modelsData.length });
    if (!!models) {
      setModelsData(models);
    }
  }, [modelsData, search, fetchModels]);

  const resetModelList = useCallback(async (): Promise<void> => {
    setModelsData([]);
    setCurrentPage(1);
    const models = await fetchModels({ page: 1, search });
    if (!!models?.length) {
      setModelsData(models);
    }
  }, [fetchModels, search]);

  const fetchModel = useCallback(
    async (modelId: string): Promise<void> => {
      dispatch(startLoader());
      try {
        const model = (await getModel(modelId)).data;
        setModelsData([model]);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  const clearTeamModelDataState = useCallback((): void => {
    setModelsData([]);
    setCurrentPage(1);
    setSearch('');
    setIsSearchMode(false);
  }, []);

  return {
    modelsData,
    fetchModel,
    isSearchMode,
    setSearch,
    setIsSearchMode,
    resetModelList,
    handlePageChange,
    updateModelList,
    clearTeamModelDataState
  };
};

export default useTeamModelsData;
