import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ScheduledMaintenance = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 58px;
  padding: 10px 20px;
  background-color: ${COLORS.turbo};
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;

  .bold-text {
    font-weight: 700;
    margin-right: 6px;
  }

  @media ${DEVICE.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
`;
