import { ISTLMetaData } from '../interfaces/model-parse/stl-types';

export class STLParser {
  constructor(buffer: Buffer) {
    this._buffer = buffer;
  }

  private _buffer: Buffer;

  private _stlMetaData: ISTLMetaData = {
    triangles: 0,
    vertices: 0
  };

  public parse(): ISTLMetaData {
    if (!/\ufffd/.test(this._buffer.toString())) {
      // https://nodejs.org/api/buffer.html if buffer contains not
      // valid utf-8 data ufffd will replace chars and can be used to represent error
      this.parseASCII();
    } else {
      this.parseBinary();
    }
    return this._stlMetaData;
  }

  private parseBinary(): boolean {
    const offset = 80; // skip header

    const triangleCount = this._buffer.readUInt32LE(offset);
    this._stlMetaData.triangles = triangleCount;

    this._stlMetaData.vertices = triangleCount * 3;

    return true;
  }

  private parseASCII(): void {
    const lines = this._buffer.toString().split('\n');

    lines.forEach((line): void => {
      const parts = line
        .trim()
        .split(' ')
        .filter((part): boolean => {
          return part !== '';
        });

      switch (parts[0]) {
        case 'facet':
          this._stlMetaData.triangles += 1;
          break;
        case 'vertex':
          this._stlMetaData.vertices += 1;
          break;
      }
    });
  }
}
