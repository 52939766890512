import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { BaseLoadModelStrategy } from './strategies/base-load-model.strategy';
import { GltfLoadModelStrategy } from './strategies/gltf-load-model.strategy';
import { FbxLoadModelStrategy } from './strategies/fbx-load-model.strategy';
import { ObjLoadModelStrategy } from './strategies/obj-load-model.strategy';
import { StlLoadModelStrategy } from './strategies/stl-load-model.strategy';
import { LoadModelData, LoadModelResult, LoadModelServiceData } from 'shared/interfaces';
import { UsdzLoadModelStrategy } from './strategies/usdz-load-model.strategy';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { INCORRECT_FILE_FORMAT } from 'shared/constants/notifications';



export class LoadModelService {
  private strategies: Map<E3DModelFileTypes, BaseLoadModelStrategy>;

  constructor({renderer, onModelResourcesLoaded}: LoadModelServiceData) {
    this.strategies = new Map<E3DModelFileTypes, BaseLoadModelStrategy>([
      [E3DModelFileTypes.gltf, new GltfLoadModelStrategy(renderer, onModelResourcesLoaded)],
      [E3DModelFileTypes.glb, new GltfLoadModelStrategy(renderer, onModelResourcesLoaded)],
      [E3DModelFileTypes.fbx, new FbxLoadModelStrategy(renderer, onModelResourcesLoaded)],
      [E3DModelFileTypes.obj, new ObjLoadModelStrategy(renderer, onModelResourcesLoaded)],
      [E3DModelFileTypes.stl, new StlLoadModelStrategy(renderer, onModelResourcesLoaded)],
      [E3DModelFileTypes.usdz, new UsdzLoadModelStrategy(renderer, onModelResourcesLoaded)],
    ]);
  }

  public loadModel(type: E3DModelFileTypes, data: LoadModelData): Promise<LoadModelResult> {
    if (!this.strategies.get(type)) {
      openNotification(ESnackbarStyle.HOLD_UP, INCORRECT_FILE_FORMAT);
      throw new Error(INCORRECT_FILE_FORMAT);
    }

    return this.strategies.get(type)!.loadModel(data);
  }
}
