import React from 'react';

const LinkIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 29 23'
        preserveAspectRatio='xMidYMax meet'
        fill='transparent'
      >
        <rect
          y='0.62574'
          width='9.44343'
          height='15.4111'
          rx='4.72172'
          transform='matrix(0.780031 0.62574 -0.780031 0.62574 13.2894 6.39915)'
          stroke='currentColor'
        />
        <rect
          y='0.62574'
          width='9.44343'
          height='15.4111'
          rx='4.72172'
          transform='matrix(0.780031 0.62574 -0.780031 0.62574 21.3419 0.234189)'
          stroke='currentColor'
        />
      </svg>
    </div>
  );
};

export default LinkIcon;
