import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { checkIsIFrame } from 'utils/helper-utils';

type Props = {
  children: ReactNode;
};

type LocationState = {
  from: string | null;
  current: string | null;
};

type ContextState = {
  locationState: LocationState;
};

const initialLocationState = {
  from: null,
  current: null
};

const initialContextState: ContextState = {
  locationState: initialLocationState
};

export const LocationStateContext = React.createContext<ContextState>(initialContextState);
export const useLocationStateContext = (): ContextState => useContext(LocationStateContext);

export const LocationStateProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const location = useLocation();
  const [locationState, setLocationState] = useState<LocationState>(initialLocationState);

  useEffect((): void => {
    setLocationState((prev): LocationState => ({ current: location.pathname, from: prev.current }));
  }, [location]);

  useEffect((): void => {
    if (!checkIsIFrame()) {
      sessionStorage.setItem('locationState', JSON.stringify(locationState));
    }
  }, [locationState]);

  return (
    <LocationStateContext.Provider value={{ locationState }}>
      {children}
    </LocationStateContext.Provider>
  );
};
