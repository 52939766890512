import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Styled from './styles';
import HintIcon from 'assets/images/hint.svg';
import UploadFileIcon from 'assets/images/upload-file.svg';
import TrashIcon from 'assets/images/remove.svg';
import EditIcon from 'assets/images/edit-theme-name.svg';
import CloseIcon from 'assets/images/close.svg';
import { useAppDispatch, useAppSelector, useCustomizationData } from 'shared/hooks';
import {
  CubemapFiles,
  CubemapImagePaths,
  CustomCubemap,
  CustomizationState,
  ESnackbarStyle
} from 'shared/types';
import { Select } from '../select';
import { getFieldError } from 'utils/form-utils';
import { validation } from 'services/validation';
import { setCubemapImagePaths } from 'services/store/reducers/customizationReducer';
import {
  CREATE_NEW_ID,
  CUBEMAP_POSITION_KEYS,
  DEFAULT_CUBEMAP_IMAGE_PATHS,
  DEFAULT_CUBEMAP_SETTINGS,
  INITIAL_CUBEMAP_FILES
} from 'shared/constants/customization';
import { convertDataToFormData } from 'utils/convert-file-utils';
import { openNotification } from 'utils/notification-utils';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { deleteCustomCubemap, updateCustomCubemap } from 'services/api/customizationService';
import { CUBEMAP_FILE_FORMATS_REGEXP } from 'shared/constants/regexps';
import { CustomTooltip } from 'shared/components';

type CubemapPositionKeys = {
  position: string;
  pathKey: keyof CubemapImagePaths;
  fileKey: keyof CubemapFiles;
};

type CubemapSelectOption = {
  title: string;
  value: CustomCubemap;
};

const CubemapSettings = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { cubemapImagePaths } = useAppSelector((store): CustomizationState => store.customization);
  const { customCubemaps, fetchCustomCubemaps, uploadCustomCubemap } = useCustomizationData();
  const [activeCubemap, setActiveCubemap] = useState<CustomCubemap>(DEFAULT_CUBEMAP_SETTINGS);
  const [uploadedFiles, setUploadedFiles] = useState<CubemapFiles>(INITIAL_CUBEMAP_FILES);
  const [isEditNameInputActive, setIsEditNameInputActive] = useState<boolean>(false);

  const CUBEMAPS = useMemo(
    (): CustomCubemap[] => [DEFAULT_CUBEMAP_SETTINGS, ...customCubemaps],
    [customCubemaps]
  );

  useEffect((): void => {
    fetchCustomCubemaps();
  }, [fetchCustomCubemaps]);

  const initialValues: { cubemapName: string } = {
    cubemapName: DEFAULT_CUBEMAP_SETTINGS.cubemapName
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ cubemapName }): Promise<void> => {
      dispatch(startLoader());
      try {
        const updatedImages = Object.entries(uploadedFiles).reduce(
          (acc, [key, file]): Record<string, File> => (!!file ? { ...acc, [key]: file } : acc),
          {}
        );
        const formData = convertDataToFormData({ cubemapName, ...updatedImages });
        const newCubemap =
          activeCubemap.id === CREATE_NEW_ID
            ? await uploadCustomCubemap(formData)
            : (await updateCustomCubemap(activeCubemap.id, formData)).data;
        if (!newCubemap) return;
        setIsEditNameInputActive(false);
        setActiveCubemap(DEFAULT_CUBEMAP_SETTINGS);
        dispatch(setCubemapImagePaths(DEFAULT_CUBEMAP_IMAGE_PATHS));
        setUploadedFiles(INITIAL_CUBEMAP_FILES);
        await formik.setFieldValue('cubemapName', DEFAULT_CUBEMAP_SETTINGS.cubemapName);
        await fetchCustomCubemaps();
        openNotification(
          ESnackbarStyle.SUCCESS,
          activeCubemap.id === CREATE_NEW_ID
            ? 'Your cubemap has been successfully uploaded'
            : 'Your cubemap has been successfully updated'
        );
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.CUBEMAP_CUSTOMIZATION
  });

  const { values, handleChange, handleSubmit, setFieldValue, handleBlur } = formik;

  const handleCubemapSelectChange = ({ value, title }: CubemapSelectOption): void => {
    const { nxPlaneUrl, nzPlaneUrl, pyPlaneUrl, nyPlaneUrl, pxPlaneUrl, pzPlaneUrl } = value;
    setActiveCubemap(value);
    dispatch(
      setCubemapImagePaths({
        nxPlaneUrl,
        nzPlaneUrl,
        pyPlaneUrl,
        nyPlaneUrl,
        pxPlaneUrl,
        pzPlaneUrl
      })
    );
    setFieldValue('cubemapName', title);
    setUploadedFiles(INITIAL_CUBEMAP_FILES);
  };

  const handleRemoveCubemapClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteCustomCubemap(activeCubemap.id);
      setIsEditNameInputActive(false);
      setActiveCubemap(DEFAULT_CUBEMAP_SETTINGS);
      dispatch(setCubemapImagePaths(DEFAULT_CUBEMAP_IMAGE_PATHS));
      setUploadedFiles(INITIAL_CUBEMAP_FILES);
      await setFieldValue('cubemapName', DEFAULT_CUBEMAP_SETTINGS.cubemapName);
      await fetchCustomCubemaps();
      openNotification(ESnackbarStyle.SUCCESS, 'Your cubemap has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleCubemapOptionRemove = async (cubemapId: number): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteCustomCubemap(cubemapId);
      await fetchCustomCubemaps();
      openNotification(ESnackbarStyle.SUCCESS, 'Your cubemap has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleEditCubemapNameClick = (): void => {
    setIsEditNameInputActive(true);
  };

  const handleCubemapImgChange =
    (keys: CubemapPositionKeys): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
    (event): void => {
      const file = event.target.files![0];
      if (!CUBEMAP_FILE_FORMATS_REGEXP.test(file.name)) {
        openNotification(
          ESnackbarStyle.HOLD_UP,
          'Please make sure you’re uploading a valid filetype.'
        );
        return;
      }
      const imgBlobUrl = URL.createObjectURL(file);
      event.target.value = '';
      dispatch(setCubemapImagePaths({ ...cubemapImagePaths, [keys.pathKey]: imgBlobUrl }));
      setUploadedFiles((prev): CubemapFiles => ({ ...prev, [keys.fileKey]: file }));
    };

  const handleRemoveCubemapImgClick =
    ({ pathKey, fileKey }: CubemapPositionKeys): (() => void) =>
    (): void => {
      dispatch(setCubemapImagePaths({ ...cubemapImagePaths, [pathKey]: '' }));
      setUploadedFiles((prev): CubemapFiles => ({ ...prev, [fileKey]: null }));
    };

  const handleCloseEditModeClick = (): void => {
    setIsEditNameInputActive(false);
  };

  const handleESCKeyOnCubemapNameInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Escape') {
      setIsEditNameInputActive(false);
    }
  };

  const getFileName = ({ fileKey, pathKey }: CubemapPositionKeys): string | undefined =>
    activeCubemap.id === CREATE_NEW_ID
      ? uploadedFiles[fileKey]?.name.split('.')[0]
      : uploadedFiles[fileKey]?.name.split('.')[0] ||
        cubemapImagePaths[pathKey].split('/').reverse()[0].split('.')[0];

  const checkIsRemoveButtonVisible = ({ fileKey, pathKey }: CubemapPositionKeys): boolean =>
    activeCubemap.id === CREATE_NEW_ID ? !!uploadedFiles[fileKey] : !!cubemapImagePaths[pathKey];

  const checkIsSaveButtonDisabled = (): boolean =>
    activeCubemap.id === CREATE_NEW_ID
      ? Object.values(uploadedFiles).some((file): boolean => !file)
      : Object.values(cubemapImagePaths).some((path): boolean => !path);

  const CUBEMAP_SELECT_OPTIONS = useMemo(
    (): CubemapSelectOption[] =>
      CUBEMAPS.map(
        (cubemap): CubemapSelectOption => ({ title: cubemap.cubemapName, value: cubemap })
      ),
    [CUBEMAPS]
  );

  return (
    <Styled.SettingsSection>
      <Styled.SettingsSectionTitleContainer>
        <Styled.SettingsSectionTitle>Custom Cubemaps</Styled.SettingsSectionTitle>
        <CustomTooltip content='Upload images that represent each side of a cube that you can use in the cubemap dropdown'>
          <Styled.SettingsSectionTitleHint src={HintIcon} alt='Hint Icon' />
        </CustomTooltip>
      </Styled.SettingsSectionTitleContainer>
      <Styled.CubemapsSettings onSubmit={handleSubmit}>
        <Styled.SettingsField>
          <Styled.FieldInputContainer>
            {isEditNameInputActive ? (
              <Styled.FieldInput
                name={'cubemapName'}
                value={values.cubemapName}
                onChange={handleChange}
                onKeyDown={handleESCKeyOnCubemapNameInput}
                onBlur={handleBlur}
                autoFocus={true}
                placeholder='Create new cubemap'
              />
            ) : (
              <Select
                activeOption={{ title: values.cubemapName, value: activeCubemap }}
                options={CUBEMAP_SELECT_OPTIONS}
                onRemoveOption={handleCubemapOptionRemove}
                onChangeOption={handleCubemapSelectChange}
                placeholder={'Cubemap name'}
              />
            )}
            {isEditNameInputActive ? (
              <Styled.CloseButton type='button' onClick={handleCloseEditModeClick}>
                <img src={CloseIcon} alt='Close edit mode' />
              </Styled.CloseButton>
            ) : (
              <>
                {activeCubemap.id === CREATE_NEW_ID && (
                  <Styled.EditButton
                    type='button'
                    isNewCubemapField={activeCubemap?.id === CREATE_NEW_ID}
                    onClick={handleEditCubemapNameClick}
                  >
                    <img src={EditIcon} alt='Edit cubemap name' />
                  </Styled.EditButton>
                )}
                {activeCubemap.id !== CREATE_NEW_ID && (
                  <Styled.RemoveButton
                    type='button'
                    onClick={handleRemoveCubemapClick}
                    isInputActive={isEditNameInputActive}
                  >
                    <img src={TrashIcon} alt='Remove cubemap' />
                  </Styled.RemoveButton>
                )}
              </>
            )}
            {getFieldError(formik, 'cubemapName')}
          </Styled.FieldInputContainer>
        </Styled.SettingsField>
        <Styled.ImagesContainer>
          {CUBEMAP_POSITION_KEYS.map(
            (keys): JSX.Element => (
              <Styled.UploadImageField key={keys.position}>
                <Styled.UploadImageButton htmlFor={`upload-${keys.fileKey}-img-button`}>
                  <input
                    type='file'
                    accept='image/*'
                    id={`upload-${keys.fileKey}-img-button`}
                    onChange={handleCubemapImgChange(keys)}
                  />
                  <img src={UploadFileIcon} alt='Upload button' />
                </Styled.UploadImageButton>
                <Styled.FieldInputContainer>
                  <Styled.FileNameField>
                    {!!getFileName(keys) ? (
                      <span>{getFileName(keys)}</span>
                    ) : (
                      <span className='placeholder'>{keys.position}</span>
                    )}
                  </Styled.FileNameField>
                  {checkIsRemoveButtonVisible(keys) && (
                    <Styled.RemoveButton
                      type='button'
                      onClick={handleRemoveCubemapImgClick(keys)}
                      isInputActive
                    >
                      <img src={TrashIcon} alt='Remove' />
                    </Styled.RemoveButton>
                  )}
                </Styled.FieldInputContainer>
              </Styled.UploadImageField>
            )
          )}
        </Styled.ImagesContainer>
        <Styled.SaveButton type='submit' disabled={checkIsSaveButtonDisabled()}>
          Save
        </Styled.SaveButton>
      </Styled.CubemapsSettings>
    </Styled.SettingsSection>
  );
};

export default CubemapSettings;
