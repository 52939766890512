import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ShareModelContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ShareTabsContainer = styled.div`
  width: 100%;
  display: flex;

  @media ${DEVICE.tabletLarge} {
    display: none;
  }
`;

export const ShareTab = styled.div<{ isShareTab: boolean }>`
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isShareTab }): string =>
    isShareTab ? 'var(--button-background-color)' : 'rgba(83, 83, 83, 0.75)'};
  color: ${({ isShareTab }): string => (isShareTab ? 'var(--button-text-color)' : COLORS.white)};
  backdrop-filter: blur(2px);
  border-radius: 4px 4px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
`;

export const ArViewTab = styled.div<{ isArViewTab: boolean; hasArViewAccess: boolean }>`
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isArViewTab }): string =>
    isArViewTab ? 'var(--button-background-color)' : 'rgba(83, 83, 83, 0.75)'};
  color: ${({ isArViewTab, hasArViewAccess }): string =>
    hasArViewAccess ? (isArViewTab ? 'var(--button-text-color)' : COLORS.white) : '#949494'};
  backdrop-filter: blur(2px);
  border-radius: 4px 4px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  position: relative;
`;

export const Lock = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  right: 0;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 14px;
    height: 14px;
  }
`;

export const ShareModelContent = styled.div`
  background: rgba(83, 83, 83, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 2px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  width: 100%;
  max-height: calc(100vh - 164px);

  &::-webkit-scrollbar {
    width: 3px;
    background: rgba(83, 83, 83, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.mischka};
  }

  @media (max-height: 750px) {
    overflow-y: auto;
  }

  @media ${DEVICE.tabletLarge} {
    max-height: initial;
  }
`;

export const ShareLinkContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`;

export const ArPermissionsContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.white};
`;

export const CreateLinkButton = styled.button`
  margin-top: 6px;
  height: 36px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--button-text-color);
  background: var(--button-background-color);
  font-size: 12px;
  line-height: 15px;
  opacity: 0.85;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const ShareLinkField = styled.div`
  margin-top: 6px;
  height: 36px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  background: var(--button-background-color);
  padding: 8px;
`;

export const LinkIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const Link = styled.input`
  margin-left: 6px;
  font-family: ${FONTS.interRegular};
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--button-text-color);
  border: none;
  background: var(--button-background-color);
  padding-right: 6px;
  width: 100%;
`;

export const CopyLinkButton = styled.button`
  border: none;
  background: ${COLORS.mineShaftGradient};
  border-radius: 2px;
  width: 28px;
  min-width: 28px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  img {
    width: 11px;
    height: 11px;
  }
`;

export const InnerBlock = styled.div`
  margin-top: 9px;
  display: flex;
  flex-direction: column;
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  min-height: 175px;
`;

export const InactiveQrCode = styled.div`
  margin-top: 6px;
  width: 100%;
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  padding: 6px 6px 2px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const CoverLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.96);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.white};
  padding: 0 5px;
`;

export const ShareQrCode = styled.div`
  margin-top: 6px;
  width: 100%;
  height: 173px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${COLORS.white};
  padding: 6px;

  img.qr-code {
    width: 100%;
    height: 100%;
  }
`;

export const ArQrCode = styled.div`
  margin-top: 6px;
  width: 100%;
  height: 173px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${COLORS.white};
  padding: 6px;

  img.qr-code {
    width: 100%;
    height: 100%;
  }
`;

export const DownloadButtons = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
`;

export const DownloadQrCodeButton = styled.button`
  width: 45%;
  height: 40px;
  background: var(--button-background-color);
  border: none;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.interRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--button-text-color);
  opacity: 0.85;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const Spinner = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ShareLinkBlockHeader = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
`;

export const SharingButton = styled.button<{ isDisabled?: boolean }>`
  margin: 15px 0 0 10px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  opacity: ${({ isDisabled }): string => (isDisabled ? '0.3' : '1')};

  img {
    width: 15px;
    height: 15px;
  }

  span {
    margin-left: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.white};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SelectContainer = styled.div`
  margin-top: 10px;
`;
