import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import { COLORS } from 'shared/constants/colors';

export const PageContainer = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
`;

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const WorkspaceName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: ${COLORS.white};

  & > span:first-child {
    margin-right: 6px;
  }

  .workspace-name {
    color: ${COLORS.doveGray};
    word-break: break-word;
  }
`;

export const WorkspaceData = styled.div`
  margin-top: 13px;
  display: flex;

  @media ${DEVICE.tablet} {
    flex-direction: column;
  }
`;

export const DataItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.white};

  & > span {
    margin-right: 6px;
  }

  .data-value {
    color: ${COLORS.doveGray};
    font-style: italic;
    word-break: break-word;
  }

  &:not(:last-child) {
    margin-right: 5%;
  }

  @media ${DEVICE.tablet} {
    margin: 10px 0 0;
  }
`;

export const PageContent = styled.section`
  margin-top: 20px;
`;
