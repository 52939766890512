export const TESTIMONIAL_LIST = [
  {
    id: 1,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 2,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 3,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 4,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 5,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 6,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 7,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  },
  {
    id: 8,
    quote: `“A Platform Like No Other”`,
    text: 'Lorem ipsum dolor sit amet consectetur. Blandit faucibus gravida at aliquet. Facilisis hendrerit aliquet id orci ut arcu. Tellus magnis consequat sagittis justo adipiscing non commodo leo.Mauris tellus quam mauris congue. In urna a nisi praesent imperdiet lacus rhoncus eget.',
    userName: 'Michael L',
    userPosition: 'Head of 3D Aircards'
  }
];

export const FAQS_LIST = [
  {
    id: 1,
    question: 'What filetypes do you support?',
    answer:
      'We currently have full support for .glb, .gltf, .stl, .fbx & .obj, with more file format compatibility being added all the time.'
  },
  {
    id: 2,
    question: 'How do I upgrade or downgrade my account?',
    answer:
      'You can upgrade or downgrade between different plans at any time – directly from your account. If you choose to pay monthly, you can cancel your subscription at any time by downgrading to the free Hex plan.'
  },
  {
    id: 3,
    question: 'Do you offer team accounts?',
    answer:
      'Yes we do! Our tool is often used by freelancers, agencies and organizations to share and collaborate with clients on their 3D work.'
  },
  {
    id: 4,
    question: 'Do you offer subscription discounts for academic institutions?',
    answer:
      'Yes, we’re always open to volume discounts for schools, colleges and universities. Reach out to our team for further information. '
  }
];
