import { ITexture2DImageStats } from '../interfaces/model-parse/model-stats';

export class Texture2DImageStats implements ITexture2DImageStats {
  public count: number = 0;
  public maxSize: number = NaN;
  public minSize: number = NaN;

  public updateImage2DStats(fileSize: number): void {
    if (this.minSize > fileSize || isNaN(this.minSize)) {
      this.minSize = fileSize;
    }

    if (this.maxSize < fileSize || isNaN(this.maxSize)) {
      this.maxSize = fileSize;
    }

    this.count++;
  }

  public plainObject(): ITexture2DImageStats {
    return {
      count: this.count,
      maxSize: isNaN(this.maxSize) ? 0 : this.maxSize,
      minSize: isNaN(this.minSize) ? 0 : this.minSize
    };
  }
}
