import { EKeyBindingsKeys } from 'shared/enums/EKeyBindingsKeys';
import { KeyBindingsMap } from 'shared/types/key-binding';

export const getViceVersaKeyMap = (
  keyBindingsMap: KeyBindingsMap
): Record<string, EKeyBindingsKeys> =>
  (Object.keys(keyBindingsMap) as EKeyBindingsKeys[]).reduce(
    (previousValue, currentValue): Record<string, EKeyBindingsKeys> => ({
      ...previousValue,
      [keyBindingsMap[currentValue].primaryKey]: currentValue,
      [keyBindingsMap[currentValue].secondaryKey]: currentValue
    }),
    {} as Record<string, EKeyBindingsKeys>
  );

export const getKeyCodeMarker = (event: KeyboardEvent | MouseEvent): string => {
  if (event instanceof KeyboardEvent) {
    return event.code;
  } else {
    return event.button + '';
  }
};

export const shortenKeyName = (name: string): string => {
  return name !== 'Equal'
    ? name !== '0'
      ? name !== '1'
        ? name !== '2'
          ? !name.startsWith('Page')
            ? !name.startsWith('Numpad')
              ? !name.startsWith('Key')
                ? !name.endsWith('Key')
                  ? !name.startsWith('Arrow')
                    ? !name.endsWith('Left')
                      ? !name.endsWith('Right')
                        ? isNaN(+name) || !name.length
                          ? name
                          : 'Mouse ' + name
                        : 'R' + name.slice(0, -5)
                      : 'L' + name.slice(0, -4)
                    : name.slice(5)
                  : name.slice(0, -3)
                : name.slice(3)
              : 'Num' + name.slice(6)
            : 'Pg' + name.slice(4)
          : 'RMouse'
        : 'Wheel'
      : 'LMouse'
    : 'Plus';
};

export const transformActionKeyName = (name: string): string => {
  if (name === name.toLowerCase()) {
    return name.slice(0, 1).toUpperCase() + name.slice(1);
  } else {
    return name
      .split('')
      .map((item, index, array): string =>
        index !== 0
          ? item !== item.toUpperCase() &&
            array[index + 1] &&
            array[index + 1] === array[index + 1].toUpperCase()
            ? item + ' '
            : item
          : item.toUpperCase()
      )
      .join('');
  }
};
