import React from 'react';
import * as Styled from './styles';
import LogoCube from 'assets/images/logo-cube.png';
import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled';
import { useAppSelector } from 'shared/hooks';
import { ModalState } from 'shared/types';

const EmbeddedModelPrompt = (): JSX.Element => {
  const { promptContent } = useAppSelector((store): ModalState => store.modal);

  return (
    <Styled.PromptWrapper>
      <Styled.PromptContainer>
        <Styled.PromptHeader>
          <Styled.PromptTitle>
            <ExclamationCircleFilled className='prompt-icon' />
            <h1 className='title'>Oh no!</h1>
          </Styled.PromptTitle>
        </Styled.PromptHeader>
        <Styled.PromptContent>{promptContent}</Styled.PromptContent>
      </Styled.PromptContainer>
      <Styled.PromptCubeIcon src={LogoCube} />
    </Styled.PromptWrapper>
  );
};

export default EmbeddedModelPrompt;
