import HivePlanIcon from 'assets/images/plan-icon-4.png';

export const CUSTOM_PLAN = {
  isMostPopular: false,
  icon: HivePlanIcon,
  title: 'Hive',
  subtitle: 'Enterprise Plan',
  description: 'Paid plan',
  price: 'Custom Enterprise',
  limits: [
    'Unlimited models',
    '100MB Upload Model Size Limit',
    'Custom model view limit',
    'Custom AR view limit',
    '3 Custom Embedded Model Buttons'
  ],
  features: [
    { title: 'Instant 3D file viewing', isAvailable: true },
    { title: 'Shortlink creation', isAvailable: true },
    { title: 'QR Code Generation', isAvailable: true },
    { title: 'Model Scale Controls', isAvailable: true },
    { title: 'Model Shadows', isAvailable: true },
    { title: 'Download Model Functionality', isAvailable: true },
    { title: 'View Model Animations', isAvailable: true },
    { title: 'Material Breakdown', isAvailable: true },
    { title: 'Geometry Breakdown', isAvailable: true },
    { title: 'Texture Breakdown', isAvailable: true },
    { title: 'Embed Models', isAvailable: true },
    { title: 'Simple Customization', isAvailable: true },
    { title: 'All Lighting Options', isAvailable: true },
    { title: 'All Environment Options', isAvailable: true },
    { title: 'All Cubemap Options', isAvailable: true },
    { title: 'WebAR viewing mode', isAvailable: true },
    { title: '3D Comments', isAvailable: true },
    { title: 'Whitelist Embedded Models', isAvailable: true },
    { title: 'Custom Embedded Model Buttons', isAvailable: true },
    { title: 'Custom UI branding', isAvailable: true },
    { title: 'Custom 3D environments', isAvailable: true }
  ]
};
