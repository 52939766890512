import { GlShaderData } from 'shared/interfaces';

export const GroundWithFadeShader: GlShaderData = {
  vertexSource: `
    #include <common>
    #include <fog_pars_vertex>
    #include <shadowmap_pars_vertex>

    varying vec2 vUv;

    void main() {
      vUv = uv;

      #include <begin_vertex>
      #include <beginnormal_vertex>
      #include <defaultnormal_vertex>
      #include <project_vertex>
      #include <worldpos_vertex>
      #include <shadowmap_vertex>
      #include <fog_vertex>
    }
  `,
  fragmentSource: `
    #include <common>
    #include <packing>
    #include <fog_pars_fragment>
    #include <bsdfs>
    #include <lights_pars_begin>
    #include <shadowmap_pars_fragment>
    #include <shadowmask_pars_fragment>
    #include <dithering_pars_fragment>

    varying vec2 vUv;
    uniform sampler2D ground;
    uniform vec4 maskColor; // Use vec4 to represent the mask color with transparency

    void main() {
      float scale = 320.0;
      vec2 uv = vec2(vUv.x * scale, vUv.y * scale);
      vec4 texColor = texture2D(ground, uv);
      vec3 color = texColor.rgb;
      float alpha = texColor.a;

      if ((uv.x - (scale / 2.)) * (uv.x - (scale / 2.)) + (uv.y - (scale / 2.)) * (uv.y - (scale / 2.)) > (scale / 50.) * (scale / 50.) - 0.01) {
        color = maskColor.rgb;
        alpha = maskColor.a;
    } else if ((uv.x - (scale / 2.)) * (uv.x - (scale / 2.)) + (uv.y - (scale / 2.)) * (uv.y - (scale / 2.)) <= (scale / 50.) * (scale / 50.)) {
      float length = ((uv.x - (scale / 2.)) * (uv.x - (scale / 2.)) + (uv.y - (scale / 2.)) * (uv.y - (scale / 2.))) / ((scale / 50.) * (scale / 50.));

      if (length < 1.) {
        color = mix(texColor.rgb, maskColor.rgb, pow(length + 0.01, 0.5));
        alpha = texColor.a + pow(length + 0.01, 0.5) - 0.5;
      }
    }

      vec3 finalColor = color;
      vec3 shadowColor = vec3(0, 0, 0);
      float shadowPower = 0.15;

      gl_FragColor = vec4(mix(finalColor, shadowColor, (1.0 - getShadowMask()) * shadowPower), alpha);
      #include <fog_fragment>
      #include <dithering_fragment>
    }
  `
}
