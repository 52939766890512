import { BaseSsStrategy } from 'services/strategy-services/create-screenshot-service/strategies/base-ss.strategy';
import { CreateScreenshotData, CSInitializationExtendedData } from 'shared/interfaces';

export class UserSceneSsStrategy extends BaseSsStrategy {
  constructor({ mainScene, width, height }: CSInitializationExtendedData) {
    super({ mainScene });

    this.setSize(width, height);
  }

  public async createScreenshot({cubemapController}:CreateScreenshotData): Promise<string> {
    const { screenshotRenderer, scene, screenshotCamera } = this.mainScene;

    this.setupRenderer();
    this.setupCamera();
    const restoreCubemap = this.cheatCubemap(cubemapController);

    screenshotRenderer.render(scene, screenshotCamera);

    restoreCubemap();

    return screenshotRenderer.domElement.toDataURL('image/jpeg');
  }
}
