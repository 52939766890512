import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModalWindowContainer = styled.div`
  width: 90vw;
  max-height: 90vh;
  min-width: 310px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  color: ${COLORS.mischka};
`;

export const Notification = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;

  .quantity {
    padding: 0 5px;
    color: ${COLORS.turbo};
  }

  &:not(:first-child) {
    margin-top: 30px;
  }
`;

export const ListTitle = styled.h2`
  margin-top: 40px;
  color: ${COLORS.white};
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
`;

export const OverSizeModelsList = styled.div`
  margin-top: 10px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const DeleteButton = styled.button`
  opacity: 0;
  background: ${COLORS.turbo};
  border: none;
  color: ${COLORS.black};
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  padding: 4px 10px;
  border-radius: 4px;
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.corn};
  }

  @media ${DEVICE.tablet} {
    opacity: 1;
  }
`;

export const ModelSize = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin-left: auto;
  padding: 0 20px;

  @media ${DEVICE.tablet} {
    margin-left: 0;
    padding: 0;
  }
`;

export const ModelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 4px;

  &:nth-child(odd) {
    background: rgba(71, 71, 71, 0.59);
  }

  &:hover ${DeleteButton} {
    opacity: 1;
  }

  a {
    text-decoration: none;
    color: ${COLORS.white};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: ${COLORS.turbo};
      text-decoration: underline;
    }
  }

  @media ${DEVICE.tablet} {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 15px;

    & > ${ModelSize}, ${DeleteButton} {
      margin-top: 16px;
    }
  }
`;
