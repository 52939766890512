import { AxiosResponse } from 'axios';
import ApiService from './api';
import { EModelAccessLevel, ModelPermissionsResponse } from 'shared/types';

type RequestInvite = {
  email: string;
  accessLevel: EModelAccessLevel;
};

export const acceptInvite = async (
  modelId: string,
  inviteCode: string
): Promise<AxiosResponse<void>> =>
  await ApiService.post<{ inviteCode: string }, void>(`/models/${modelId}/accept-invite`, {
    inviteCode
  });

export const inviteUser = async (
  modelId: string,
  invite: RequestInvite
): Promise<AxiosResponse<void>> =>
  await ApiService.post<RequestInvite, void>(`/models/${modelId}/invite-user`, invite);

export const updateModelPublicAccessLevel = async (
  modelId: string,
  accessLevel: EModelAccessLevel[]
): Promise<AxiosResponse<ModelPermissionsResponse>> =>
  await ApiService.patch<{ accessLevel: EModelAccessLevel[] }, ModelPermissionsResponse>(
    `/models/${modelId}/permissions`,
    { accessLevel }
  );

export const updateTeamAccessLevel = async (
  teamId: number,
  modelId: string,
  accessLevel: EModelAccessLevel[]
): Promise<AxiosResponse<ModelPermissionsResponse>> =>
  await ApiService.patch<{ accessLevel: EModelAccessLevel[] }, ModelPermissionsResponse>(
    `teams/${teamId}/models/${modelId}/permissions`,
    { accessLevel }
  );

export const getModelPermissions = async (
  modelId: string,
  signal?: AbortSignal
): Promise<AxiosResponse<ModelPermissionsResponse>> => {
  const limit: number = 1000;
  const page: number = 1;
  const pageValue = !!page ? `&page=${page}` : '';
  const limitValue = !!limit ? `&limit=${limit}` : '';

  return await ApiService.get<ModelPermissionsResponse>(
    `/models/${modelId}/permissions?orderBy=createdAt${limitValue}${pageValue}`,
    { signal }
  );
};

export const updateUserPermission = async (
  modelId: string,
  recordId: number,
  accessLevel: EModelAccessLevel[]
): Promise<AxiosResponse<ModelPermissionsResponse>> =>
  await ApiService.patch<{ accessLevel: EModelAccessLevel[] }, ModelPermissionsResponse>(
    `/models/${modelId}/permissions/${recordId}`,
    { accessLevel }
  );

export const deleteUserPermission = async (
  modelId: string,
  recordId: number
): Promise<AxiosResponse<ModelPermissionsResponse>> =>
  await ApiService.delete(`/models/${modelId}/permissions/${recordId}`);

export const deleteAllPermissions = async (
  modelId: string
): Promise<AxiosResponse<ModelPermissionsResponse>> =>
  await ApiService.delete(`/models/${modelId}/permissions/all`);
