import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1150px;
  max-height: 700px;
  width: 90vw;
  height: 90vh;
  color: ${COLORS.white};
  position: relative;
  overflow-x: hidden;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.tablet} {
    flex-direction: column;
    width: 75vw;
  }

  @media${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const LeftSide = styled.section`
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  padding: 6% 4% 6% 5%;

  @media${DEVICE.tablet} {
    order: 1;
    padding-top: 30px;
    width: 100%;
  }
`;

export const LoginFormContainer = styled.div``;

export const ModalTitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ModalTitle = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
  padding: 15px 0;
`;

export const RightSide = styled.section`
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  padding: 6% 5% 6% 4%;

  @media${DEVICE.tablet} {
    width: 100%;
    padding: 4%;
  }
`;

export const SectionIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 137px;
  height: 203px;
  z-index: -1;

  @media${DEVICE.tablet} {
    bottom: initial;
    top: 50%;
    transform: translate(40%, -40%);
  }

  @media${DEVICE.mobile} {
    width: 120px;
    height: 160px;
  }
`;

export const Lock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-left: 30px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const FeaturePreview = styled.img`
  margin-top: 30px;
  width: 100%;
  height: 300px;
  object-fit: contain;
`;

export const SeparateLine = styled.div`
  height: 70%;
  width: 1px;
  background: #262626;
  align-self: center;
`;
