import React, { useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { AuthState, ESnackbarStyle } from 'shared/types';
import { useFormik } from 'formik';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { sendForgotPasswordRequest } from 'services/api/authService';
import { validation } from 'services/validation';
import { FormField, ModalLogin } from 'shared/components';
import {
  EmailNotificationSubtitle,
  EmailNotificationTitle,
  FormFieldLabel,
  IncorrectEmailText,
  ModalCopyright,
  ModalForm,
  ModalSubtitle,
  ModalTitle,
  ModalWindowContainer,
  NoEmailText,
  SubmitButton
} from 'shared/styles';
import moment from 'moment';
import { showModal } from 'services/store/reducers/modalReducer';

type InitialValues = {
  email: string;
};

const ModalForgotPassword: React.FC = (): JSX.Element => {
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const [isRequestSuccessfullySent, setIsRequestSuccessfullySent] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const initialValues: InitialValues = {
    email: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ email }): Promise<void> => {
      dispatch(startLoader());
      try {
        await sendForgotPasswordRequest(email);
        setIsRequestSuccessfullySent(true);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.FORGOT_PASSWORD
  });

  const handleLoginButtonClick = (): void => {
    dispatch(showModal(<ModalLogin />));
  };

  return (
    <ModalWindowContainer>
      <Styled.LeftSide>
        {isRequestSuccessfullySent ? (
          <Styled.SuccessfullySentContainer>
            <EmailNotificationTitle>Check your email</EmailNotificationTitle>
            <EmailNotificationSubtitle>
              A password reset link was sent to <span className='email'>{formik.values.email}</span>
            </EmailNotificationSubtitle>
            <NoEmailText>If you don’t see the email, please check your spam folder</NoEmailText>
            {!isAuth && (
              <IncorrectEmailText>
                <span>Email address incorrect?</span>
                <span className='highlighted-text' onClick={handleLoginButtonClick}>
                  Go back to login
                </span>
              </IncorrectEmailText>
            )}
          </Styled.SuccessfullySentContainer>
        ) : (
          <>
            <Styled.LiftSideContent>
              <ModalTitle>Forgot your password?</ModalTitle>
              <ModalSubtitle>
                Enter your registered email below and we will send password reset instructions
              </ModalSubtitle>
              <ModalForm onSubmit={formik.handleSubmit}>
                <FormFieldLabel>Email*</FormFieldLabel>
                <FormField formik={formik} name={'email'} type={'email'} />
                <SubmitButton
                  type='submit'
                  disabled={formik.isSubmitting}
                  id='send-password-reset-instructions-button'
                >
                  Send
                </SubmitButton>
              </ModalForm>
            </Styled.LiftSideContent>
            <ModalCopyright>
              <span>{moment().year()} © glbee</span>
            </ModalCopyright>
          </>
        )}
      </Styled.LeftSide>
      <Styled.RightSide></Styled.RightSide>
    </ModalWindowContainer>
  );
};

export default ModalForgotPassword;
