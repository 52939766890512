import { useCallback, useState } from 'react';
import { ENotificationPreferenceMode, ESnackbarStyle, NotificationPreferences } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { useAppDispatch } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import {
  getModelNotificationPreferences,
  getThreadNotificationPreferences,
  patchModelNotificationPreferences,
  patchThreadNotificationPreferences
} from 'services/api/notification-preferences';

type Result = {
  fetchModelNotificationPreferences: (modelId: string) => Promise<void>;
  updateModelNotificationPreferences: (
    modelId: string,
    mode: ENotificationPreferenceMode
  ) => Promise<void>;
  fetchThreadNotificationPreferences: (modelId: string, threadId: number) => Promise<void>;
  updateThreadNotificationPreferences: (
    modelId: string,
    threadId: number,
    mode: ENotificationPreferenceMode
  ) => Promise<void>;
  notificationPreferences: NotificationPreferences | null;
};

const useNotificationPreferencesData = (): Result => {
  const dispatch = useAppDispatch();
  const [notificationPreferences, setNotificationPreferences] =
    useState<NotificationPreferences | null>(null);

  const fetchModelNotificationPreferences = useCallback(
    async (modelId: string): Promise<void> => {
      dispatch(startLoader());
      try {
        const response = (await getModelNotificationPreferences(modelId)).data;
        setNotificationPreferences(response);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  const updateModelNotificationPreferences = useCallback(
    async (modelId: string, mode: ENotificationPreferenceMode): Promise<void> => {
      dispatch(startLoader());
      try {
        const response = (await patchModelNotificationPreferences(modelId, mode)).data;
        setNotificationPreferences(response);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  const fetchThreadNotificationPreferences = useCallback(
    async (modelId: string, threadId: number): Promise<void> => {
      dispatch(startLoader());
      try {
        const response = (await getThreadNotificationPreferences(modelId, threadId)).data;
        setNotificationPreferences(response);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  const updateThreadNotificationPreferences = useCallback(
    async (modelId: string, threadId: number, mode: ENotificationPreferenceMode): Promise<void> => {
      dispatch(startLoader());
      try {
        const response = (await patchThreadNotificationPreferences(modelId, threadId, mode)).data;
        setNotificationPreferences(response);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  return {
    fetchModelNotificationPreferences,
    updateModelNotificationPreferences,
    fetchThreadNotificationPreferences,
    updateThreadNotificationPreferences,
    notificationPreferences
  };
};

export default useNotificationPreferencesData;
