import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const FormContainer = styled.div`
  width: 100%;
`;

export const FormFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RememberMeContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
  }
`;

export const Checkbox = styled.div`
  width: 17px;
  height: 17px;
  border: 1px solid ${COLORS.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    height: 8px;
  }
`;

export const ForgotPassword = styled.div`
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const NotRegisteredBlock = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;

  span {
    font-size: 13px;
    line-height: 15px;
    white-space: nowrap;
  }

  span:first-child {
    margin-right: 5px;
  }

  .create-account {
    font-weight: 600;
    color: ${COLORS.corn};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
