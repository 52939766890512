import styled from 'styled-components';
import LogoSignup from 'assets/images/logo-icon-signup.png';
import { DEVICE } from 'shared/constants/deviceSizes';
import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  max-width: 1000px;
  max-height: 700px;
  height: 85vh;
  width: 90vw;
  color: ${COLORS.white};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};

  @media${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const LeftSide = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 6%;

  @media${DEVICE.tablet} {
    width: 100%;
  }
`;

export const LiftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const RightSide = styled.section`
  width: 50%;
  background-image: url(${LogoSignup});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media${DEVICE.tablet} {
    display: none;
  }
`;
