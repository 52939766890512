import { IOBJMetaData } from '../interfaces/model-parse/obj-types';

export class OBJParser {
  constructor(buffer: Uint8Array) {
    this._buffer = new TextDecoder().decode(buffer);
  }

  private _objMetaData: IOBJMetaData = {
    mtl: '',
    vertices: 0,
    polygons: 0
  };

  private _buffer: string;

  public parse(): IOBJMetaData {
    const lines = this._buffer.split('\n');

    lines.forEach((line): void => {
      const lineItem = this._stripComments(line);

      const lineItems = lineItem.replace(/\s\s+/g, ' ').trim().split(' ');

      switch (lineItems[0].toLowerCase()) {
        case 'v': // Define a vertex for the current model
          this._objMetaData.vertices += 1;
          break;
        case 'f': // Define a Face/Polygon
          this._objMetaData.polygons += 1;
          break;
        case 'mtllib': // Reference to a material library file (.mtl)
          this._parseMtlLib(lineItem, lineItems);
          break;
      }
    });

    return this._objMetaData;
  }

  private _stripComments(lineString: string): string {
    const commentIndex = lineString.indexOf('#');
    if (commentIndex > -1) {
      return lineString.substring(0, commentIndex);
    }
    return lineString;
  }

  private _parseMtlLib(line: string, lineItems: string[]): void {
    if (lineItems.length >= 2) {
      this._objMetaData.mtl = line.replace('mtllib', '').trim();
    }
  }
}
