import React from 'react';

export const CommentReplyIcon: React.FC = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 17 17'
        fill='none'
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <path
          d='M8.40169 11.3269C8.53171 11.3269 8.64005 11.2873 8.72673 11.2082C8.81341 11.1284 8.85675 11.0235 8.85675 10.8935V7.07964L10.3736 8.61819C10.4459 8.69042 10.5435 8.72654 10.6666 8.72654C10.7891 8.72654 10.8865 8.69042 10.9587 8.61819C11.0454 8.53151 11.0887 8.42663 11.0887 8.30354C11.0887 8.18104 11.0454 8.08367 10.9587 8.01143L8.92176 5.95281C8.83508 5.88058 8.75187 5.83002 8.67213 5.80112C8.59296 5.77223 8.50281 5.75778 8.40169 5.75778C8.31501 5.75778 8.23209 5.77223 8.15292 5.80112C8.07317 5.83002 7.98996 5.88058 7.90328 5.95281L5.84466 8.01143C5.75798 8.08367 5.71464 8.18104 5.71464 8.30354C5.71464 8.42663 5.75798 8.53151 5.84466 8.61819C5.91689 8.69042 6.01455 8.72654 6.13763 8.72654C6.26014 8.72654 6.35751 8.69042 6.42974 8.61819L7.96829 7.07964V10.8935C7.96829 11.0235 8.01163 11.1284 8.09831 11.2082C8.18499 11.2873 8.28612 11.3269 8.40169 11.3269ZM8.40169 16.2026C7.3182 16.2026 6.30348 15.9969 5.35752 15.5854C4.41099 15.1734 3.58754 14.6169 2.88718 13.916C2.18623 13.2156 1.62975 12.3922 1.21774 11.4457C0.806304 10.4997 0.600586 9.48498 0.600586 8.40149C0.600586 7.318 0.806304 6.30299 1.21774 5.35646C1.62975 4.4105 2.18623 3.58705 2.88718 2.88611C3.58754 2.18574 4.41099 1.62956 5.35752 1.21754C6.30348 0.806105 7.3182 0.600388 8.40169 0.600388C9.48517 0.600388 10.5002 0.806105 11.4467 1.21754C12.3927 1.62956 13.2161 2.18574 13.9171 2.88611C14.6174 3.58705 15.1736 4.4105 15.5856 5.35646C15.9971 6.30299 16.2028 7.318 16.2028 8.40149C16.2028 9.48498 15.9971 10.4997 15.5856 11.4457C15.1736 12.3922 14.6174 13.2156 13.9171 13.916C13.2161 14.6169 12.3927 15.1734 11.4467 15.5854C10.5002 15.9969 9.48517 16.2026 8.40169 16.2026Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};
