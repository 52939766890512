import { useCallback, useState } from 'react';
import {
  ESnackbarStyle,
  CustomTheme,
  CustomEnvironment,
  CustomCubemap,
  AuthState,
  TeamsState
} from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import {
  getTeamCustomCubemaps,
  getTeamCustomEnvironments,
  getTeamCustomThemes,
  getUserCustomCubemaps,
  getUserCustomEnvironments,
  getUserCustomThemes,
  uploadTeamCustomCubemap,
  uploadTeamCustomEnvironment,
  uploadTeamCustomTheme,
  uploadUserCustomCubemap,
  uploadUserCustomEnvironment,
  uploadUserCustomTheme
} from 'services/api/customizationService';

type Result = {
  customThemes: CustomTheme[];
  customEnvironments: CustomEnvironment[];
  customCubemaps: CustomCubemap[];
  fetchCustomThemes: () => Promise<void>;
  fetchCustomEnvironments: () => Promise<void>;
  fetchCustomCubemaps: () => Promise<void>;
  uploadCustomTheme: (formData: FormData) => Promise<CustomTheme | void>;
  uploadCustomEnvironment: (formData: FormData) => Promise<CustomEnvironment | void>;
  uploadCustomCubemap: (formData: FormData) => Promise<CustomCubemap | void>;
};

const useCustomizationData = (): Result => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((store): AuthState => store.auth);
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const [customThemes, setCustomThemes] = useState<CustomTheme[]>([]);
  const [customEnvironments, setCustomEnvironments] = useState<CustomEnvironment[]>([]);
  const [customCubemaps, setCustomCubemaps] = useState<CustomCubemap[]>([]);

  const fetchCustomThemes = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      if (!!user) {
        const response = !!activeTeam
          ? (await getTeamCustomThemes(activeTeam.id)).data
          : (await getUserCustomThemes(user.id)).data;
        setCustomThemes(response);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch, activeTeam, user]);

  const uploadCustomTheme = useCallback(
    async (formData: FormData): Promise<CustomTheme | void> => {
      dispatch(startLoader());
      try {
        if (!!user) {
          return !!activeTeam
            ? (await uploadTeamCustomTheme(activeTeam.id, formData)).data
            : (await uploadUserCustomTheme(user.id, formData)).data;
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, activeTeam, user]
  );

  const fetchCustomEnvironments = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      if (!!user) {
        const response = !!activeTeam
          ? (await getTeamCustomEnvironments(activeTeam.id)).data
          : (await getUserCustomEnvironments(user.id)).data;
        setCustomEnvironments(response);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [dispatch, activeTeam, user]);

  const uploadCustomEnvironment = useCallback(
    async (formData: FormData): Promise<CustomEnvironment | void> => {
      dispatch(startLoader());
      try {
        if (!!user) {
          return !!activeTeam
            ? (await uploadTeamCustomEnvironment(activeTeam.id, formData)).data
            : (await uploadUserCustomEnvironment(user.id, formData)).data;
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, activeTeam, user]
  );

  const fetchCustomCubemaps = useCallback(async (): Promise<void> => {
    dispatch(startLoader());
    try {
      if (!!user) {
        const response = !!activeTeam
          ? (await getTeamCustomCubemaps(activeTeam.id)).data
          : (await getUserCustomCubemaps(user.id)).data;
        setCustomCubemaps(response);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  }, [activeTeam, dispatch, user]);

  const uploadCustomCubemap = useCallback(
    async (formData: FormData): Promise<CustomCubemap | void> => {
      dispatch(startLoader());
      try {
        if (!!user) {
          return !!activeTeam
            ? (await uploadTeamCustomCubemap(activeTeam.id, formData)).data
            : (await uploadUserCustomCubemap(user.id, formData)).data;
        }
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, activeTeam, user]
  );

  return {
    customThemes,
    customEnvironments,
    customCubemaps,
    fetchCustomThemes,
    fetchCustomEnvironments,
    fetchCustomCubemaps,
    uploadCustomTheme,
    uploadCustomEnvironment,
    uploadCustomCubemap
  };
};

export default useCustomizationData;
