import styled from 'styled-components';
import { FONTS } from 'shared/constants/fonts';
import { COLORS } from 'shared/constants/colors';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
  height: 100%;
`;

export const NoDataBlock = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: ${COLORS.doveGray};
  padding: 0 20px;
`;

export const UploadModelAria = styled.section<{ isDragActive: boolean }>`
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: calc(100vh - 244px);
  border: 1px solid ${COLORS.turbo};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({ isDragActive }): string =>
    isDragActive ? 'rgba(255, 228, 6, 0.5)' : 'rgba(110, 110, 110, 0.7)'};
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 228, 6, 0.5);
  }
`;

export const UploadFieldContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PlusIcon = styled.img`
  height: 34px;
  width: 34px;
`;

export const UploadDescription = styled.div`
  margin-top: 19px;
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  span:last-child {
    font-weight: 300;
  }
`;

export const SecureIcon = styled.img`
  width: 70px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.1s;

  &:hover {
    transform: scale(1.05);
  }
`;
