import React, { useState } from 'react';
import { SelectOption } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down-plain.svg';
import Check from 'assets/images/check-black.svg';
import { useHandleClickOutside } from 'shared/hooks';

type Props = {
  activeValue: any;
  action: (value: SelectOption) => void;
  options: SelectOption[];
  disabled?: boolean;
};

const Select: React.FC<Props> = ({
  activeValue,
  action,
  options,
  disabled = false
}): JSX.Element => {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  const handleOptionClick =
    (option: SelectOption): (() => void) =>
    (): void => {
      setIsMenuActive(false);
      if (option.title !== activeValue?.title) {
        action(option);
      }
    };

  const toggleMenuActivity = (): void => {
    setIsMenuActive((state): boolean => !state);
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer isMenuActive={isMenuActive} disabled={disabled} ref={ref}>
      <Styled.MainField isMenuActive={isMenuActive} onClick={toggleMenuActivity}>
        <div>{!!activeValue ? activeValue.title : 'Reason for contacting'}</div>
        <img src={ArrowDownIcon} alt='Arrow' />
      </Styled.MainField>
      <Styled.SelectMenu isMenuActive={isMenuActive}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem
              onClick={handleOptionClick(option)}
              isActive={option.title === activeValue?.title}
              key={option.title}
            >
              <span>{option.title}</span>
              {option.title === activeValue?.title && <img src={Check} alt='Checked' />}
            </Styled.OptionItem>
          )
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default Select;
