import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';
import { useAppDispatch, useBrowserStore } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { logoutUser } from 'services/store/reducers/authReducer';

const ModalLogout: React.FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { removeFilesFromBrowserStore } = useBrowserStore();

  const handleLogoutButtonClick = (): void => {
    dispatch(logoutUser());
    removeFilesFromBrowserStore();
    dispatch(closeConfirmModal());
    history.push('/');
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.Notification>Are you sure you would like to log out?</Styled.Notification>
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton
          className='logout-button'
          onClick={handleLogoutButtonClick}
          id='logout-button'
        >
          Log Out
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalLogout;
