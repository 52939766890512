import React, { useEffect } from 'react';
import './App.css';
import { store } from 'services/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MainRoutes } from 'routes';
import { LocationStateProvider, PageTopPositionProvider } from 'shared/providers';
import { setThemeCSSVariables } from './utils/theme-utils';
import { DEFAULT_THEME_CSS_VARIABLES } from './shared/constants/customization';
import { ServerMaintenance } from './shared/components';

const App = (): React.ReactElement => {
  const isServerMaintenance = false;

  useEffect((): void => {
    setThemeCSSVariables(DEFAULT_THEME_CSS_VARIABLES);
  }, []);

  useEffect((): void => {
    if (!isServerMaintenance) {
      const urlParams = new URLSearchParams(window.location.search);
      const isEmbeddedModel = urlParams.get('embed');
      if (!isEmbeddedModel) {
        window.Userback.init('36084|70319|oyecjtIkX9hQUwCJwioPRET8q');
      }
    }
  }, [isServerMaintenance]);

  document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);

  window.addEventListener('resize', (): void => {
    document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <LocationStateProvider>
          <PageTopPositionProvider>
            {isServerMaintenance ? <ServerMaintenance /> : <MainRoutes />}
          </PageTopPositionProvider>
        </LocationStateProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
