import React from 'react';

const NewModelIcon = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }}
        viewBox='0 0 42 42'
        preserveAspectRatio='xMaxYMax meet'
      >
        <g>
          <circle cx='22' cy='21' r='18'></circle>
        </g>
        <path
          fill='currentColor'
          d='M20.089 31.703l-6.524-3.753a1.91 1.91 0 01-.781-.758 2.144 2.144 0 01-.284-1.087v-7.442c0-.395.095-.757.284-1.086.188-.33.448-.593.781-.79l6.524-3.721a2.053 2.053 0 011.065-.297c.377 0 .732.099 1.065.296l6.524 3.722a2.154 2.154 0 011.065 1.877v7.441c0 .396-.094.758-.283 1.087-.189.33-.45.582-.782.758l-6.524 3.753a2.052 2.052 0 01-1.065.297c-.377 0-.732-.099-1.065-.297zm.4-1.284v-7.672l-6.658-3.754v7.113c0 .153.034.285.1.395.067.11.167.208.3.296l6.257 3.622zm1.33 0l6.258-3.622a.957.957 0 00.3-.296c.066-.11.1-.242.1-.396v-7.112l-6.657 3.754v7.672zm-.665-8.825l6.657-3.787-6.258-3.556a.72.72 0 00-.4-.132.721.721 0 00-.399.132l-6.257 3.556 6.657 3.787z'
        ></path>
        <mask
          id='mask0_3108_446'
          style={{ maskType: 'alpha' }}
          width='20'
          height='20'
          x='19'
          y='6'
          maskUnits='userSpaceOnUse'
        >
          <path
            // fill={buttonsColor || COLORS.turbo}
            // stroke={buttonsColor || COLORS.turbo}
            d='M19.691 6.5H37.998999999999995V24.808H19.691z'
          ></path>
        </mask>
        <g mask='url(#mask0_3108_446)'>
          <path
            fill='currentColor'
            // stroke={buttonsColor || COLORS.turbo}
            d='M23.777 14.912l.354.354-.354-.354c-.204.204-.3.464-.3.742a1.023 1.023 0 001.041 1.04h3.286v3.287a1.025 1.025 0 001.041 1.04c.277 0 .537-.095.74-.298l.002-.002c.204-.204.299-.464.299-.74v-3.286h3.286a1.025 1.025 0 001.04-1.041c0-.277-.094-.537-.298-.74l-.001-.003a1.027 1.027 0 00-.74-.298h-3.287v-3.286a1.024 1.024 0 00-1.04-1.041 1.023 1.023 0 00-1.042 1.04v3.287h-3.286c-.276 0-.537.095-.74.3z'
          ></path>
        </g>
        <defs>
          <filter
            id='filter0_d_3108_446'
            width='44'
            height='44'
            x='0'
            y='0'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1'></feOffset>
            <feGaussianBlur stdDeviation='2'></feGaussianBlur>
            <feComposite operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'></feColorMatrix>
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_3108_446'></feBlend>
            <feBlend in='SourceGraphic' in2='effect1_dropShadow_3108_446' result='shape'></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default NewModelIcon;
