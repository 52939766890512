import { AxiosResponse } from 'axios';
import ApiService from './api';
import { IViewerComment } from 'shared/interfaces';
import { Note } from 'shared/types';
import { SimpleUser } from 'shared/types/user';

export const uploadNote = async (
  modelId: string,
  note: IViewerComment
): Promise<AxiosResponse<Note>> => await ApiService.post(`/models/${modelId}/notes`, note);

export const getNotes = async (modelId: string): Promise<AxiosResponse<Note[]>> =>
  await ApiService.get<Note[]>(
    `/models/${modelId}/notes?withRelated[]=author&withRelated[]=comments.author&withRelated[]=comments&withRelated[]=comments.mentions&withRelated[]=mentions.person&withRelated[]=comments.mentions.person`
  );

export const getPlainNotesList = async (modelId: string): Promise<AxiosResponse<Note[]>> =>
  await ApiService.get<Note[]>(
    `/models/${modelId}/notes?withRelated[]=author&format=plain&withRelated[]=author&withRelated[]=comments.author&withRelated[]=comments&withRelated[]=comments.mentions&withRelated[]=mentions.person&withRelated[]=comments.mentions.person`
  );

export const getNote = async (modelId: string, noteId: number): Promise<AxiosResponse<Note>> =>
  await ApiService.get<Note>(`/models/${modelId}/notes/${noteId}?withRelated[]=author&withRelated[]=comments.author&withRelated[]=comments&withRelated[]=comments.mentions&withRelated[]=mentions.person&withRelated[]=comments.mentions.person`);

export const updateNote = async (
  modelId: string,
  noteId: number | string,
  note: IViewerComment
): Promise<AxiosResponse<Note>> =>
  await ApiService.patch(`/models/${modelId}/notes/${noteId}`, note);

export const deleteNote = async (
  modelId: string,
  noteId: number | string
): Promise<AxiosResponse<void>> => await ApiService.delete(`/models/${modelId}/notes/${noteId}`);

export const addUserMention = async (
  modelId: string,
  payload: FormData
): Promise<AxiosResponse<{ success: boolean }>> =>
  await ApiService.post(`/models/${modelId}/comments-mention`, payload);

export const muteMentionsNotification = async (
  modelId: string,
  payload: { mute: boolean }
): Promise<AxiosResponse<{ mute: boolean; success: boolean }>> =>
  await ApiService.post(`/models/${modelId}/mute-notifications`, payload);

export const getPeopleForMention = async (
  modelId: string,
  search: string,
  limit?: number
): Promise<AxiosResponse<SimpleUser[]>> =>
  await ApiService.get<SimpleUser[]>(
    `/models/${modelId}/tagged-users?search=${search}${limit ? `&limit=${limit}` : ''}`
  );

export const markCommentAsSeen = async (
  modelId: string,
  commentId: string
): Promise<AxiosResponse<void>> =>
  await ApiService.post(`/models/${modelId}/comments/${commentId}/mark-as-seen`, {});
