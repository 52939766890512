import * as Styled from './styles';
import { convertStringToButtonId } from 'utils/form-utils';

type Props = {
  className?: string;
  clickAction?: () => void;
  name?: string;
  icon: React.ReactElement;
  [key: string]: any;
};

const NavButton: React.FC<Props> = ({
  clickAction,
  className,
  name,
  icon,
  children,
  ...rest
}): React.ReactElement => (
  <Styled.NavButtonContainer
    type='button'
    id={!!name ? convertStringToButtonId(name) : undefined}
    className={className}
    onClick={clickAction}
    {...rest}
  >
    <Styled.IconWrapper>{icon}</Styled.IconWrapper>
    {!!name && <span>{name}</span>}
    {children}
  </Styled.NavButtonContainer>
);

export default NavButton;
