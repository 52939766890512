import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomCubemap, CustomEnvironment, CustomTheme, ESnackbarStyle, Model } from 'shared/types';
import { useAppDispatch } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import {
  getCustomBrand,
  getModelCustomBrands,
  getModelCustomCubemaps,
  getModelCustomEnvironments
} from 'services/api/modelService';
import { API_REQUEST_CANCELED_CODE } from 'shared/constants/errors';

type Result = {
  brands: CustomTheme[];
  environments: CustomEnvironment[];
  cubemaps: CustomCubemap[];
  fetchCustomBranding: (model: Model, hasCustomBrandingAccess: boolean) => Promise<void>;
  fetchCustomEnvironments: (model: Model) => Promise<void>;
  fetchCustomCubemaps: (model: Model) => Promise<void>;
};

const useCustomBrandingData = (): Result => {
  const controller = useMemo((): AbortController => new AbortController(), []);
  const signal: AbortSignal = controller.signal;
  const dispatch = useAppDispatch();
  const [brands, setBrands] = useState<CustomTheme[]>([]);
  const [environments, setEnvironments] = useState<CustomEnvironment[]>([]);
  const [cubemaps, setCubemaps] = useState<CustomCubemap[]>([]);

  useEffect(
    (): (() => void) => (): void => {
      controller.abort();
    },
    [controller]
  );

  const fetchCustomBranding = useCallback(
    async (model: Model, hasCustomBrandingAccess: boolean): Promise<void> => {
      dispatch(startLoader());
      try {
        if (!!model.brandingId) {
          const activeBranding = (await getCustomBrand(model.brandingId, signal)).data;
          setBrands([activeBranding]);
        }
        if (hasCustomBrandingAccess) {
          const brands = (await getModelCustomBrands(model.id, signal)).data;
          setBrands(brands);
        }
      } catch (e) {
        if (e.code === API_REQUEST_CANCELED_CODE) return;
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, signal]
  );

  const fetchCustomEnvironments = useCallback(
    async (model: Model): Promise<void> => {
      dispatch(startLoader());
      try {
        const environments = (await getModelCustomEnvironments(model.id, signal)).data;
        setEnvironments(environments);
      } catch (e) {
        if (e.code === API_REQUEST_CANCELED_CODE) return;
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, signal]
  );

  const fetchCustomCubemaps = useCallback(
    async (model: Model): Promise<void> => {
      dispatch(startLoader());
      try {
        const cubemaps = (await getModelCustomCubemaps(model.id, signal)).data;
        setCubemaps(cubemaps);
      } catch (e) {
        if (e.code === API_REQUEST_CANCELED_CODE) return;
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch, signal]
  );

  return {
    brands,
    environments,
    cubemaps,
    fetchCustomBranding,
    fetchCustomEnvironments,
    fetchCustomCubemaps
  };
};

export default useCustomBrandingData;
