import React from 'react';

const RemoveIcon = (): JSX.Element => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        viewBox='0 0 13 13'
        preserveAspectRatio='xMaxYMax meet'
      >
        <path
          d='M3.79163 11.375C3.49371 11.375 3.23876 11.269 3.02679 11.057C2.81446 10.8447 2.70829 10.5896 2.70829 10.2917V3.25H2.16663V2.16667H4.87496V1.625H8.12496V2.16667H10.8333V3.25H10.2916V10.2917C10.2916 10.5896 10.1856 10.8447 9.97367 11.057C9.76133 11.269 9.50621 11.375 9.20829 11.375H3.79163ZM4.87496 9.20833H5.95829V4.33333H4.87496V9.20833ZM7.04163 9.20833H8.12496V4.33333H7.04163V9.20833Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default RemoveIcon;
