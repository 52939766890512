import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1051;
  border: 1px solid ${COLORS.mineShaft};
`;

export const CloseButton = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1052;
  cursor: pointer;
`;

export const ModalWindowContent = styled.div`
  width: 100%;
`;

export const ModalWindowOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1050;
`;
