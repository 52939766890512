import styled from 'styled-components';

export const Container = styled.div<{ isEmbeddedModelMode: boolean }>`
  position: relative;
  width: 120px;
  height: 120px;
  cursor: grab;
  user-select: none;

  ${({ isEmbeddedModelMode }): string | undefined => {
    if (isEmbeddedModelMode) {
      return 'width: 80px; height: 80px;';
    }
  }}
`;

export const Face = styled.div`
  position: absolute;
  width: 100px; //warn: don't change
  height: 100px; //warn: don't change
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: radial-gradient(
    circle,
    var(--theme-secondary-color) 0%,
    #948400 50%,
    var(--theme-secondary-color) 100%
  );
  line-height: 100px; //warn: don't change
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: var(--theme-secondary-color);
  text-align: center;
  background: var(--theme-main-color) no-repeat;
  background-size: 100%;
  user-select: none;
  pointer-events: none;
  filter: brightness(0.85);

  &.hovered {
    filter: brightness(1);
  }

  &.clicked {
    filter: brightness(1.15);
  }
`;
