export const REASON_OPTIONS = [
  {
    title: 'General Enquiry',
    value: 'General Enquiry'
  },
  {
    title: 'Tech Support',
    value: 'Tech Support'
  },
  {
    title: 'Feature Request',
    value: 'Feature Request'
  },
  {
    title: 'Membership Enquiry',
    value: 'Membership Enquiry'
  },
  {
    title: 'Enterprise Licensing',
    value: 'Enterprise Licensing'
  },
  {
    title: 'Academic Licensing',
    value: 'Academic Licensing'
  },
  {
    title: 'Report A Bug',
    value: 'Report A Bug'
  },
  {
    title: 'Other',
    value: 'Other'
  }
];
