import { Model, User } from 'shared/types';
import { useEffect, useState } from 'react';
import {
  ANONYMOUS_MODEL_ID,
  QUICK_VIEW_MODEL_ID,
  SAMPLE_MODEL_ID
} from 'shared/constants/model-settings';

interface Result {
  isSampleModel: boolean;
  isAnonymousModel: boolean;
  isTeamModel: boolean;
  isModelOwner: boolean;
  isQuickView: boolean;
  isUMIDInitialized: boolean;
}

export const useModelInfoData = (model: Model | null, user: User | null): Result => {
  const [isSampleModel, setIsSampleModel] = useState<boolean>(false);
  const [isAnonymousModel, setIsAnonymousModel] = useState<boolean>(false);
  const [isTeamModel, setIsTeamModel] = useState<boolean>(false);
  const [isModelOwner, setIsModelOwner] = useState<boolean>(false);
  const [isQuickView, setIsQuickView] = useState<boolean>(false);
  const [isUMIDInitialized, setIsUMIDInitialized] = useState<boolean>(false);

  useEffect((): void => {
    setIsSampleModel(model?.id === SAMPLE_MODEL_ID);
    setIsAnonymousModel(model?.ownerId === ANONYMOUS_MODEL_ID);
    setIsTeamModel(!!model?.teamId);
    setIsModelOwner(model?.ownerId === user?.id || model?.ownerId === ANONYMOUS_MODEL_ID);
    setIsQuickView(model?.id === QUICK_VIEW_MODEL_ID);
    setIsUMIDInitialized(true);
  }, [model, user]);

  return {
    isAnonymousModel,
    isModelOwner,
    isTeamModel,
    isSampleModel,
    isQuickView,
    isUMIDInitialized
  };
};
