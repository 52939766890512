import { AxiosResponse } from 'axios';
import { ETeamRole, Model, Team, TeamMember, TeamMembersApiResponse } from 'shared/types';
import ApiService from './api';

type CreateTeamRequest = {
  teamName: string;
  totalSeats: number;
};

type Pagination = {
  currentPage: number;
  pageSize: number;
  totalItemsCount: number;
  totalPagesCount: number;
};

type TeamModelsResponse = {
  items: Model[];
  pagination: Pagination;
};

type TeamMemberResponse = {
  id: number;
  teamId: number;
  userId: number;
  role: ETeamRole;
};

type ModelsCountResponse = {
  teamId: number;
  teamName: string;
  modelsCount: number;
};

export const createTeam = async (payload: CreateTeamRequest): Promise<AxiosResponse<Team>> =>
  await ApiService.post(`/teams`, payload);

export const updateTeamName = async (
  teamId: number,
  teamName: string
): Promise<AxiosResponse<void>> => await ApiService.patch(`/teams/${teamId}`, { teamName });

export const deleteTeam = async (teamId: number): Promise<AxiosResponse<void>> =>
  await ApiService.delete(`/teams/${teamId}`);

export const getTeams = async (): Promise<AxiosResponse<Team[]>> =>
  await ApiService.get(`users/me/teams`);

export const getTeam = async (teamId: number): Promise<AxiosResponse<Team>> =>
  await ApiService.get(`/teams/${teamId}`);

export const getTeamMember = async (teamId: number): Promise<AxiosResponse<TeamMemberResponse>> =>
  await ApiService.get(`/teams/${teamId}/members/me`);

export const getAllTeamMembers = async (teamId: number): Promise<AxiosResponse<TeamMember[]>> =>
  await ApiService.get(`/teams/${teamId}/members`);

export const getTeamMembers = async (
  teamId: number,
  limit: number,
  page: number,
  search: string,
  orderBy: string
): Promise<AxiosResponse<TeamMembersApiResponse>> => {
  const searchQueryParams = search ? `&search=${search}` : '';
  const orderByQueryParams = orderBy ? `&orderBy=${orderBy}` : '';
  return await ApiService.get(
    `/teams/${teamId}/members?page=${page}&limit=${limit}${searchQueryParams}${orderByQueryParams}`
  );
};

export const addTeamMember = async (
  teamId: number,
  email: string,
  isInvite?: boolean
): Promise<AxiosResponse<void>> => {
  const data = !!isInvite ? { email, invite: true } : { email };
  return await ApiService.post(`/teams/${teamId}/members`, data);
};

export const updateMemberPermission = async (
  teamId: number,
  memberId: number,
  role: ETeamRole
): Promise<AxiosResponse<void>> =>
  await ApiService.patch(`/teams/${teamId}/members/${memberId}`, { role });

export const deleteMember = async (
  teamId: number,
  memberId: number
): Promise<AxiosResponse<void>> => await ApiService.delete(`/teams/${teamId}/members/${memberId}`);

export const getMember = async (
  teamId: number,
  memberId: number
): Promise<AxiosResponse<Omit<TeamMember, 'user'>>> =>
  await ApiService.get(`/teams/${teamId}/members/${memberId}`);

export const getTeamModels = async (
  teamId: number,
  page: number,
  limit: number,
  search?: string
): Promise<AxiosResponse<TeamModelsResponse>> => {
  const searchQueryParams = !!search ? `&search=${search}` : '';
  return await ApiService.get<TeamModelsResponse>(
    `teams/${teamId}/models?page=${page}&limit=${limit}${searchQueryParams}&withRelated[]=content&withRelated[]=owner&orderBy=-createdAt&withRelated[]=metadata`
  );
};

export const getTeamModelsOverSizeLimit = async (
  teamId: number,
  modelSize: number
): Promise<AxiosResponse<Model[]>> =>
  await ApiService.get<Model[]>(
    `teams/${teamId}/models?limit=300&orderBy=-createdAt&filter.modelSize.operator=>&filter.modelSize.value=${modelSize}`
  );

export const getTeamModelsCount = async (
  teamId: number
): Promise<AxiosResponse<ModelsCountResponse>> =>
  await ApiService.get<ModelsCountResponse>(`teams/${teamId}/stats/models-count`);

export const uploadTeamModel = async (
  teamId: number,
  model: FormData
): Promise<AxiosResponse<Model>> =>
  await ApiService.post<FormData, Model>(`/teams/${teamId}/upload-model`, model);
