import React, { useCallback, useEffect } from 'react';
import * as Styled from './styles';
import { ContentHeader, ModelsList, PageContainer, PageTitle } from 'shared/styles';
import { ModelCard, SearchSelect } from 'shared/components';
import { useRecentViewedData } from 'shared/hooks';
import { Model } from 'shared/types';

const RecentsPage = (): JSX.Element => {
  const {
    fetchRecentViewedModels,
    modelsData,
    setSearch,
    fetchModel,
    isSearchMode,
    setIsSearchMode
  } = useRecentViewedData();

  useEffect((): void => {
    fetchRecentViewedModels();
  }, [fetchRecentViewedModels]);

  const handleSearchInputChange = useCallback(
    (value: string): void => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleSearchListItemClick = async (modelId: string): Promise<void> => {
    await fetchModel(modelId);
  };

  return (
    <PageContainer>
      <Styled.PageContent>
        <ContentHeader>
          <PageTitle>Recents</PageTitle>
          <Styled.SearchSelectContainer>
            <SearchSelect
              inputAction={handleSearchInputChange}
              listAction={handleSearchListItemClick}
              options={isSearchMode ? modelsData.slice(0, 5) : []}
              setIsSearchMode={setIsSearchMode}
            />
          </Styled.SearchSelectContainer>
        </ContentHeader>
        <ModelsList>
          {modelsData.map(
            (model: Model): JSX.Element => (
              <ModelCard
                item={model}
                fetchModelsList={fetchRecentViewedModels}
                showMyModelsLabel={true}
                key={model.id}
              />
            )
          )}
        </ModelsList>
      </Styled.PageContent>
    </PageContainer>
  );
};

export default RecentsPage;
