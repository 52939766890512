import { AxiosResponse } from 'axios';
import { StatisticItem } from 'shared/types';
import ApiService from './api';

export const getStatistics = async (
  startDate: string,
  endDate: string
): Promise<AxiosResponse<StatisticItem[]>> =>
  await ApiService.get<StatisticItem[]>(
    `/dashboard/me/statistic?filter.startDate=${startDate}&filter.endDate=${endDate}`
  );
