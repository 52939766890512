import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import ArrowWhite from 'assets/images/arrow-right.svg';
import ArrowBlack from 'assets/images/arrow-right-black.svg';

export const SettingsContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    max-width: initial;
  }
`;

export const SettingName = styled.div`
  margin-right: 30px;
  white-space: nowrap;

  @media ${DEVICE.tablet} {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
`;

export const SettingValue = styled.div`
  margin-left: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  @media ${DEVICE.tablet} {
    margin-left: initial;
    margin-top: 10px;
  }
`;

export const ArrowIcon = styled.div`
  background-image: url(${ArrowWhite});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 13px;
  min-width: 13px;
  height: 13px;
  margin-left: 20px;
`;

export const FieldContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 33px);

  @media ${DEVICE.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SettingsField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  color: ${COLORS.white};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    color: ${COLORS.black};
    background: ${COLORS.turbo};

    ${ArrowIcon} {
      background-image: url(${ArrowBlack});
    }
  }

  @media ${DEVICE.tablet} {
    padding: 12px 0;
  }
`;