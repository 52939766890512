import React, { JSX, RefObject } from 'react';
import * as Styled from './styles';
import {
  DIMENSIONS_CUBE_FACE_X_ID,
  DIMENSIONS_CUBE_FACE_Y_ID,
  DIMENSIONS_CUBE_FACE_Z_ID
} from 'shared/constants/html-elements-ids';
import { useAppSelector } from 'shared/hooks';
import { ViewerDataState } from 'shared/types';

interface Props {
  modelDimensionsBlockRef: RefObject<HTMLDivElement>;
}

export const ModelDimensionsBlock: React.FC<Props> = ({ modelDimensionsBlockRef }): JSX.Element => {
  const { isSceneCompletelyLoaded } = useAppSelector((store): ViewerDataState => store.viewerData);

  return (
    <Styled.Container ref={modelDimensionsBlockRef}>
      {[DIMENSIONS_CUBE_FACE_X_ID, DIMENSIONS_CUBE_FACE_Y_ID, DIMENSIONS_CUBE_FACE_Z_ID].map(
        (value, index): JSX.Element => (
          <Styled.Face key={value + index} id={value} className={!isSceneCompletelyLoaded ? 'hidden' : ''}>
            <Styled.Arrow />

            <Styled.DimensionValue>100 CM</Styled.DimensionValue>
          </Styled.Face>
        )
      )}
    </Styled.Container>
  );
};
