import DeviceDetector from 'device-detector-js';
import { AppDispatch } from 'services/store';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from './notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { DEFAULT_ERROR } from 'shared/constants/errors';

export const check8thWallCompatibility = async (): Promise<boolean> => {
  const checkWebGLSupport = (): boolean => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('webgl') || canvas.getContext('webgl2');

    return !!ctx;
  };
  const checkGetUserMediaSupport = async (): Promise<boolean> => {
    const mediaDeviceInfo = await navigator.mediaDevices.enumerateDevices();
    return !!mediaDeviceInfo.find((info): boolean => info.kind.includes('videoinput'));
  };
  const checkDeviceOrientationSupport = (): boolean => {
    return !!(window.DeviceOrientationEvent && 'ontouchstart' in window);
  };
  const checkWebAssemblySupport = (): boolean => {
    try {
      if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
        const module = new WebAssembly.Module(
          Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
        );
        if (module instanceof WebAssembly.Module)
          return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
      }
    } catch (e) {
      return false;
    }

    return false;
  };
  const checkDeviceBrowserSupport = (): boolean => {
    const deviceDetector = new DeviceDetector();
    const userAgentOutput = window.navigator.userAgent;
    const deviceInfo = deviceDetector.parse(userAgentOutput);

    if (!deviceInfo || !deviceInfo.os || !deviceInfo.client || !deviceInfo.device) return false;

    const isIOS = deviceInfo.os.name.toLowerCase().includes('ios');
    const isAndroid = deviceInfo.os.name.toLowerCase().includes('android');

    const osVersion = deviceInfo.os.version.split('.')[0];

    const isBrowser = deviceInfo.client.type === 'browser';
    const isSafariBrowser =
      isBrowser &&
      (deviceInfo.client.name.toLowerCase().includes('safari') ||
        userAgentOutput.toLowerCase().includes('safari'));
    const isChromeBrowser = isBrowser && deviceInfo.client.name.toLowerCase().includes('chrome');
    const isFirefoxBrowser = isBrowser && deviceInfo.client.name.toLowerCase().includes('firefox');
    const isMicrosoftEdgeBrowser =
      isBrowser && deviceInfo.client.name.toLowerCase().includes('microsoft edge');
    const isSamsungInternetBrowser =
      isBrowser && deviceInfo.client.name.toLowerCase().includes('samsung internet');
    const isWKWebView = !!((window as any).webkit && (window as any).webkit.messageHandlers);
    const browserVersion = deviceInfo.client.version.split('.')[0];

    switch (true) {
      case isIOS && +osVersion >= 11 && isSafariBrowser:
        return true;

      case isIOS && +osVersion >= 14 && isWKWebView:
        return true;

      case isAndroid && +osVersion >= 7 && isChromeBrowser && +browserVersion >= 105:
        return true;

      case isAndroid && +osVersion >= 7 && isFirefoxBrowser && +browserVersion >= 104:
        return true;

      case isAndroid && +osVersion >= 7 && isSamsungInternetBrowser && +browserVersion >= 12:
        return true;

      case isAndroid && +osVersion >= 7 && isMicrosoftEdgeBrowser:
        return true;

      default:
        return false;
    }
  };

  return (
    checkWebGLSupport() &&
    (await checkGetUserMediaSupport()) &&
    checkDeviceOrientationSupport() &&
    checkWebAssemblySupport() &&
    checkDeviceBrowserSupport()
  );
};

const loadScript = (src: string): Promise<unknown> => {
  return new Promise((resolve, reject): void => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};

export const loadArScripts = async (dispatch: AppDispatch): Promise<boolean> => {
  if ((window as any).XR8 && (window as any).XR) return true;

  try {
    dispatch(startLoader());
    if (!process.env.REACT_APP_EIGHTHWALL_DEV) throw new Error(DEFAULT_ERROR);

    if (!(window as any).XR) {
      await loadScript('https://cdn.8thwall.com/web/xrextras/xrextras.js');
    }

    if (!(window as any).XR8) {
      await loadScript(process.env.REACT_APP_EIGHTHWALL_DEV);
    }
    return true
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e?.messages);
    return false
  } finally {
    dispatch(stopLoader());
  }
};
