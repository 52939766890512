import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { ESnackbarStyle } from 'shared/types';
import { useFormik } from 'formik';
import { stopLoader, startLoader } from 'services/store/reducers/loaderReducer';
import { resetPassword } from 'services/api/authService';
import { validation } from 'services/validation';
import { FormField } from 'shared/components';
import { closeModal } from 'services/store/reducers/modalReducer';
import PasswordStrengthBar from 'react-password-strength-bar';
import { FormFieldLabel, ModalCopyright, ModalForm, ModalTitle, SubmitButton } from 'shared/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { openNotification } from 'utils/notification-utils';

type Props = {
  resetCode: string;
};

type InitialValues = {
  password: string;
  confirmPassword: string;
};

const ModalResetPassword: React.FC<Props> = ({ resetCode }): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const initialValues: InitialValues = {
    password: '',
    confirmPassword: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ password, confirmPassword }): Promise<void> => {
      dispatch(startLoader());
      try {
        await resetPassword({ password, confirmPassword, resetCode });
        openNotification(ESnackbarStyle.SUCCESS, 'Your password has been updated');
        dispatch(closeModal());
        history.push('./');
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.RESET_PASSWORD
  });

  const { handleSubmit, isSubmitting, values } = formik;

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.LiftSideContent>
          <ModalTitle>Create a new password</ModalTitle>
          <ModalForm onSubmit={handleSubmit}>
            <FormFieldLabel>Password*</FormFieldLabel>
            <FormField formik={formik} name={'password'} isPassword />
            {!!values.password.length && <PasswordStrengthBar password={values.password} />}
            <FormFieldLabel>Confirm Password*</FormFieldLabel>
            <FormField formik={formik} name={'confirmPassword'} isPassword />
            <SubmitButton type='submit' disabled={isSubmitting} id='create-password-button'>
              Create
            </SubmitButton>
          </ModalForm>
        </Styled.LiftSideContent>
        <ModalCopyright>
          <span>{moment().year()} © glbee</span>
        </ModalCopyright>
      </Styled.LeftSide>
      <Styled.RightSide />
    </Styled.ModalWindowContainer>
  );
};

export default ModalResetPassword;
