import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ViewerOverlay = styled.div<{ isDragActive: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(217, 217, 217, 0.8);
  z-index: 1019;
  display: ${({ isDragActive }): string => (isDragActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlusIcon = styled.img`
  width: 23px;
  height: 23px;
`;

export const UploadDescription = styled.span`
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const SidebarContainer = styled.div<{ isArMode: boolean; isSidebarHidden: boolean }>`
  position: absolute;
  top: ${({ isArMode }): string => (isArMode ? '-1px' : '0')};
  left: 0;
  width: min-content;
  z-index: ${({ isArMode }): string => (isArMode ? '1035' : '1010')};
  transition: all 0.5s;
  transform: ${({ isSidebarHidden }): string =>
    isSidebarHidden ? 'translateX(-100%)' : 'translateX(0)'};
`;

export const ThreeJSStatsContainer = styled.div<{
  isSharePanelActive: boolean;
  isEmbeddedModelMode: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translateX(
    ${({ isSharePanelActive, isEmbeddedModelMode }): string =>
      isEmbeddedModelMode ? '0' : '-213px'}
  );
  transition: transform 0.5s;

  @media ${DEVICE.tabletLarge} {
    transform: translateX(0);
    visibility: ${({ isSharePanelActive }): string => (isSharePanelActive ? 'hidden' : 'visible')};
  }
`;

export const CubeOCContainer = styled.div<{ isArMode: boolean; isSidebarHidden: boolean }>`
  position: absolute;
  bottom: 0;
  left: 324px;
  z-index: 10;
  transform: translateX(${({ isSidebarHidden }): string => (isSidebarHidden ? '-306px' : '18px')})
    translateY(-10px);
  transition: all 0.5s;
  cursor: grab;

  @media ${DEVICE.desktop} {
    transform: translateX(${({ isSidebarHidden }): string => (isSidebarHidden ? '-306px' : '18px')})
      translateY(-30px);
  }

  @media ${DEVICE.tabletLarge} {
    transform: translateX(${({ isSidebarHidden }): string => (isSidebarHidden ? '-306px' : '18px')})
      translateY(-60px);
  }

  @media ${DEVICE.tablet} {
    left: 300px;
    transform: translateX(${({ isSidebarHidden }): string => (isSidebarHidden ? '-282px' : '18px')})
      translateY(-50px);
    bottom: 10px;
  }

  @media ${DEVICE.mobile} {
    transition: none;
    visibility: ${({ isSidebarHidden }): string => (isSidebarHidden ? 'visible' : 'hidden')};
  }
`;

export const ShareModelContainer = styled.div<{
  isSharePanelActive: boolean;
  isEmbeddedModelMode: boolean;
  footerHeight: number;
}>`
  position: absolute;
  right: ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? '50%' : '0')};
  transform: translateX(
    ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? '50%' : '0')}
  );
  top: ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? 'initial' : '0')};
  bottom: ${({ isEmbeddedModelMode, footerHeight }): string =>
    isEmbeddedModelMode ? `${footerHeight + 10}px` : 'initial'};
  width: 213px;
  z-index: 1015;
  display: ${({ isSharePanelActive, isEmbeddedModelMode }): string =>
    isEmbeddedModelMode ? (isSharePanelActive ? 'block' : 'none') : 'block'};

  @media ${DEVICE.tabletLarge} {
    display: ${({ isSharePanelActive }): string => (isSharePanelActive ? 'block' : 'none')};
    top: initial;
    bottom: ${({ isEmbeddedModelMode, footerHeight }): string =>
      isEmbeddedModelMode ? `${footerHeight + 10}px` : '50px'};
  }
`;

export const FeaturesBlockContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1010;

  @media ${DEVICE.tabletLarge} {
    display: none;
  }
`;

export const ViewerFooterContainer = styled.div<{
  isArMode: boolean;
  isEmbeddedModelMode: boolean;
  isColumn: boolean;
}>`
  position: absolute;
  width: ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? '100%' : 'initial')};
  bottom: ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? '8px' : '0')};
  left: ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? 'initial' : '50%')};
  transform: ${({ isEmbeddedModelMode }): string =>
    isEmbeddedModelMode ? 'initial' : 'translate(-50%, 34px)'};
  display: flex;
  justify-content: center;
  z-index: ${({ isArMode }): string => (isArMode ? '1000' : '10')};

  @media ${DEVICE.tabletLarge} {
    transform: translate(
      ${({ isEmbeddedModelMode }): string => (isEmbeddedModelMode ? '0' : '-50%, 0')}
    );
  }
`;

export const CommentsBarContainer = styled.div`
  @media${DEVICE.tabletLarge} {
    display: none;
  }
`;

export const AppLogo = styled.div`
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 30px;

  img {
    max-width: 75px;
    max-height: 50px;
    object-fit: contain;
  }
`;

export const SaveModelBlock = styled.section`
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const SaveModelLabel = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: ${COLORS.white};
  padding: 16px 0;
`;

export const QuickViewHint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.51);
  font-size: 12px;
  color: ${COLORS.white};
  text-decoration: none;
`;

export const QuickViewModeMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${COLORS.white};

  & > .highlighted-text {
    color: ${COLORS.turbo};
  }
`;

export const EmbeddedModelAppLogo = styled.a`
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 10px;

  img {
    max-width: 52px;
    max-height: 37px;
    object-fit: contain;
  }
`;

export const EmbeddedModelLink = styled.a`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  z-index: 1;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;
