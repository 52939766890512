import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';
import Done from 'assets/images/done.svg';
import Close from 'assets/images/close.svg';
import {
  CreateTeamState,
  EConfirmModalHeader,
  EPaymentType,
  EQuotaName,
  ESnackbarStyle,
  Model,
  SubscriptionProduct,
  SubscriptionsState,
  TeamsState
} from 'shared/types';
import { extractPlanData, getSubscriptionLimits } from 'utils/subscription-plans-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { convertStringToButtonId, formatNumberToNumberWithCommas } from 'utils/form-utils';
import {
  ModalCreateTeamCheckout,
  ModalDowngradePlan,
  ModalExceedLimits,
  ModalTeamCheckout
} from 'shared/components';
import { openNotification } from 'utils/notification-utils';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { getUserPlanLimit } from 'services/api/subscriptionsService';
import { MAX_TEAM_NAME_LENGTH } from 'shared/constants/limits';
import { getTeamModelsOverSizeLimit } from 'services/api/teamService';
import { CUSTOM_PLAN } from 'shared/constants/subscription-plans';

type Props = {
  plan?: SubscriptionProduct;
  isMonthlyType: boolean;
  paymentType: EPaymentType.CREATE_TEAM_PLAN | EPaymentType.UPDATE_TEAM_PLAN;
  plansCount: number;
};

const TeamSubscriptionPlan: React.FC<Props> = ({
  plan,
  isMonthlyType,
  paymentType,
  plansCount
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { teamName } = useAppSelector((store): CreateTeamState => store.createTeam);
  const { teamPlan, activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const { subscriptionPlans, planLevels } = useAppSelector(
    (store): SubscriptionsState => store.subscriptions
  );

  const { title, subtitle, monthPrice, yearPrice, features, description, icon } = extractPlanData(
    plan,
    !plan
  );

  const isCurrentPlanInterval = teamPlan?.recurringInterval === (isMonthlyType ? 'month' : 'year');
  const isCurrentPlan = plan?.id === teamPlan?.productId && isCurrentPlanInterval && !!plan;

  const checkExceededLimits = async (
    plan: SubscriptionProduct
  ): Promise<{ modelsOverSizeLimit: Model[]; modelsOverLimit: number }> => {
    let modelsOverSizeLimit: Model[] = [];
    let modelsOverLimit: number = 0;
    dispatch(startLoader());
    try {
      if (!!activeTeam) {
        const { modelsPlanLimit, modelSizePlanLimit } = getSubscriptionLimits(plan);
        modelsOverSizeLimit = (await getTeamModelsOverSizeLimit(activeTeam.id, modelSizePlanLimit))
          .data;
        const { used } = (
          await getUserPlanLimit(EQuotaName.ACTIVE_MODELS_LIMIT, 'me', activeTeam.id)
        ).data;
        const modelsLimit = modelsPlanLimit * (activeTeam.totalSeats || 1);
        modelsOverLimit = used - modelsLimit > 0 ? used - modelsLimit : 0;
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
    return { modelsOverSizeLimit, modelsOverLimit };
  };

  const handleSelectPlanAction = async (plan: SubscriptionProduct): Promise<void> => {
    const { modelsOverSizeLimit, modelsOverLimit } = await checkExceededLimits(plan);
    if (!!modelsOverSizeLimit.length || !!modelsOverLimit) {
      dispatch(
        showConfirmModal({
          header: null,
          content: (
            <ModalExceedLimits
              paymentType={EPaymentType.UPDATE_TEAM_PLAN}
              plan={plan}
              isMonthlyType={isMonthlyType}
              modelsOverLimit={modelsOverLimit}
              modelsOverSizeLimit={modelsOverSizeLimit}
              checkExceededLimits={checkExceededLimits}
            />
          )
        })
      );
    } else {
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: <ModalTeamCheckout plan={plan} isMonthlyType={isMonthlyType} />
        })
      );
    }
  };

  const onSelectClick = async (): Promise<void> => {
    if (!plan) {
      history.push('/contact-us');
      return;
    }
    if (paymentType === EPaymentType.CREATE_TEAM_PLAN) {
      if (!teamName || teamName.length > MAX_TEAM_NAME_LENGTH) {
        return;
      }
      dispatch(
        showConfirmModal({
          header: EConfirmModalHeader.CHECKOUT,
          content: <ModalCreateTeamCheckout plan={plan} isMonthlyType={isMonthlyType} />
        })
      );
    } else {
      const currentPlan = subscriptionPlans.find(
        (plan): boolean => plan.id === teamPlan?.productId
      );
      if (!!currentPlan && !isCurrentPlan) {
        const currentPlanLevel = planLevels?.get(currentPlan) || 0;
        const selectedPlanLevel = planLevels?.get(plan) || 0;
        if (selectedPlanLevel < currentPlanLevel) {
          dispatch(
            showConfirmModal({
              header: 'Downgrade Plan',
              content: (
                <ModalDowngradePlan
                  currentPlan={currentPlan}
                  selectedPlan={plan}
                  handleSelectPlanAction={handleSelectPlanAction}
                />
              )
            })
          );
        } else {
          await handleSelectPlanAction(plan);
        }
      }
    }
  };

  const LIMITS = !plan
    ? CUSTOM_PLAN.limits
    : plan.metadata.product_limits.map(({ title, value }): string =>
        typeof value === 'string'
          ? `${value} ${title}`
          : `${formatNumberToNumberWithCommas(value * plansCount)} ${title}`
      );

  return (
    <Styled.PlanWrapper>
      <Styled.PlanContainer>
        <Styled.PlanIconShadow />
        <Styled.PlanIconContainer>
          <Styled.PlanIcon src={icon} />
        </Styled.PlanIconContainer>
        <Styled.PlanTitle>{title}</Styled.PlanTitle>
        <Styled.PlanSubtitle>{subtitle}</Styled.PlanSubtitle>
        <Styled.PaymentDescription>{description}</Styled.PaymentDescription>
        <Styled.PriceSection>
          {!plan ? (
            <Styled.CustomPrice>{CUSTOM_PLAN.price}</Styled.CustomPrice>
          ) : (
            <>
              <Styled.Currency>$</Styled.Currency>
              <Styled.Price>{(isMonthlyType ? monthPrice : yearPrice) * plansCount}</Styled.Price>
              <Styled.Duration>{`/ ${isMonthlyType ? 'month' : 'year'}`}</Styled.Duration>
            </>
          )}
        </Styled.PriceSection>
        <Styled.PlanDescription>
          {LIMITS.map(
            (item): JSX.Element => (
              <span key={item}>{item}</span>
            )
          )}
        </Styled.PlanDescription>
        <Styled.SelectButton
          onClick={onSelectClick}
          id={convertStringToButtonId(subtitle)}
          disabled={isCurrentPlan}
        >
          {isCurrentPlan ? 'Current' : 'Select'}
        </Styled.SelectButton>
        <Styled.AvailableFeatures>
          {features.map(
            ({ title, isAvailable }): JSX.Element => (
              <Styled.FeatureItemContainer key={title}>
                <Styled.FeatureItem isAvailable={isAvailable}>
                  <img src={isAvailable ? Done : Close} alt='Feature' />
                  <span>{title}</span>
                </Styled.FeatureItem>
              </Styled.FeatureItemContainer>
            )
          )}
        </Styled.AvailableFeatures>
      </Styled.PlanContainer>
    </Styled.PlanWrapper>
  );
};

export default TeamSubscriptionPlan;
