import { AxiosResponse } from 'axios';
import { User } from 'shared/types';
import ApiService from './api';

type LoginRequest = {
  email: string;
  password: string;
  token?: string;
};

type AuthResponse = User & {
  token: string;
};

type UserName = {
  firstName: string;
  lastName: string;
};

type RegisterRequest = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  recaptcha: string;
  companyName: string;
  vatRegistered?: boolean;
};

type ForgotPasswordData = {
  email: string;
};

type CheckEmailRequest = {
  email: string;
  token: string;
};

type ResetPasswordRequest = {
  resetCode: string;
  password: string;
  confirmPassword: string;
};

type ChangePasswordRequest = {
  updatedPassword: string;
  currentPassword: string;
  confirmPassword: string;
};

export const login = async (loginRequest: LoginRequest): Promise<AxiosResponse<AuthResponse>> =>
  await ApiService.post<LoginRequest, AuthResponse>('/auth/login', loginRequest);

export const getCurrentUser = async (): Promise<AxiosResponse<User>> =>
  await ApiService.get<User>('auth/me');

export const registerUser = async (
  newUser: RegisterRequest
): Promise<AxiosResponse<AuthResponse>> =>
  await ApiService.post<RegisterRequest, AuthResponse>('/auth/register', newUser);

export const recoverAccount = async (
  newUser: RegisterRequest,
  token: string
): Promise<AxiosResponse<AuthResponse>> =>
  await ApiService.post<RegisterRequest, AuthResponse>(
    `/auth/register?recover_account=true&token=${token}`,
    newUser
  );

export const sendForgotPasswordRequest = async (email: string): Promise<AxiosResponse<void>> =>
  await ApiService.post<ForgotPasswordData, void>('/auth/forgot-password', { email });

export const resetPassword = async (
  resetPasswordData: ResetPasswordRequest
): Promise<AxiosResponse<void>> =>
  await ApiService.post<ResetPasswordRequest, void>('/auth/reset-password', resetPasswordData);

export const verifyUserEmail = async (
  value: CheckEmailRequest
): Promise<AxiosResponse<{ success: boolean }>> =>
  await ApiService.post<CheckEmailRequest, { success: boolean }>('/auth/email-verify', value);

export const changePassword = async (
  changePasswordRequest: ChangePasswordRequest
): Promise<AxiosResponse<void>> =>
  await ApiService.post<ChangePasswordRequest, void>(
    '/users/me/change-password',
    changePasswordRequest
  );

export const getUserTotalModels = async (): Promise<AxiosResponse<{ count: number }>> =>
  await ApiService.get<{ count: number }>('users/me/count-models');

export const changeUserName = async (name: UserName): Promise<AxiosResponse<void>> =>
  await ApiService.patch<UserName, void>('users/me', name);

export const deleteAccount = async (): Promise<AxiosResponse<void>> =>
  await ApiService.delete<void>('users/me');
