import Logo from 'assets/images/logo.png';
import PoweredByLogos from 'assets/images/powered-by-logos.svg'
import { ThemeSettings } from 'shared/types';
import { FONTS } from 'shared/constants/fonts';

export class CustomLoader8W {
  public static init({
    brandLogoUrl
  }: Pick<ThemeSettings, 'brandLogoUrl' | 'brandColors'>): void {
    const loadImage = document.querySelector('#loadImage') as HTMLImageElement;

    if (loadImage) {
      loadImage.src = (!!brandLogoUrl && !!brandLogoUrl.length) ? brandLogoUrl : Logo;
      loadImage.classList.remove('spin');
    }

    const loadImageContainer = document.querySelector('#loadImageContainer');
    const poweredByImage = document.querySelector('.poweredby-img') as HTMLImageElement;

    if (
      loadImageContainer &&
      !loadImageContainer.contains(document.querySelector('.load-background-main-block'))
    ) {
      const loadMainBlock = document.createElement('div');
      loadMainBlock.className = 'load-background-main-block';

      poweredByImage.src = PoweredByLogos;
      poweredByImage.style.marginBottom = '50px';

      const loadText = document.createElement('div');
      loadText.className = 'load-text-8w';
      loadText.style.fontFamily = FONTS.interRegular;
      loadText.innerText = 'Loading...';

      loadMainBlock.appendChild(loadImage);
      loadMainBlock.appendChild(loadText);
      loadImageContainer.appendChild(loadMainBlock);
    }
  }

  // TODO: Remove next method and use global CCS variables
  public static defineCssVariables({brandColors: {headerTextColor, buttonBackgroundColor, buttonTextColor, normalTextColor}}: Pick<ThemeSettings, 'brandColors'>): void {
    document.documentElement.style.setProperty('--header-text-color', headerTextColor);
    document.documentElement.style.setProperty('--normal-text-color', normalTextColor);
    document.documentElement.style.setProperty('--button-text-color', buttonTextColor);
    document.documentElement.style.setProperty('--button-background-color', buttonBackgroundColor);
  }

  public static remove(): void {
    const loadingContainer = document.querySelector('#loadBackground');

    if (loadingContainer) {
      loadingContainer.remove();
    }
  }
}
