import { getAllTeamMembers } from 'services/api/teamService';

export const checkByEmailIfUserIsTeamMember = async (teamId: number, userEmail: string): Promise<boolean> => {
  try {
    const teamMembers = (await getAllTeamMembers(teamId!)).data;

    return !!teamMembers.find((member): any => member.user?.email === userEmail);
  } catch (e) {
    return false
  }
}
