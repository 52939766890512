export enum EEnvironmentPresets {
    DefaultDark = 0,
    DefaultLight = 1,
    Gazebo = 2,
    Prism = 3,
    PrismLight = 4,
    SciFiRoomGlb = 5,
    WarehouseGlbLight = 6,
    WarehouseGlbDark = 7,
    NoEnvironment = 8,
    MinimalDark = 9,
    MinimalLight = 10
  }
