import React from 'react';
import * as Styled from './styles';
import { LoginForm } from 'shared/components';
import LogoLogin from 'assets/images/logo-icon-login.png';
import QrCodeExample from 'assets/images/qr-code-example.png';
import { ModalCopyright, ModalSubtitle, ModalTitle } from 'shared/styles';
import moment from 'moment';
import LinkImage from 'assets/images/link.svg';
import Copy from 'assets/images/copy.svg';
import Download from 'assets/images/download.svg';

const ModalSharing: React.FC = (): JSX.Element => {
  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <ModalTitle>Instant model sharing</ModalTitle>
        <ModalSubtitle>
          Sharing is easy. With a glbee account, you can use either QR code, short link or download
          options to share instantly.
        </ModalSubtitle>
        <Styled.SharingOptionsContainer>
          <Styled.SharingOptionsTitle>Sharing options</Styled.SharingOptionsTitle>
          <Styled.SharingOptionsContent>
            <Styled.QrCodeBlock>
              <Styled.FeatureLabel>QR Code</Styled.FeatureLabel>
              <Styled.QrCodeContainer>
                <Styled.QrCode src={QrCodeExample} />
                <span>EXAMPLE</span>
              </Styled.QrCodeContainer>
              <Styled.FeatureLabel>Download QR Code</Styled.FeatureLabel>
              <Styled.DownloadButtonGroup>
                <Styled.DownloadButton>PNG</Styled.DownloadButton>
                <Styled.DownloadButton>SVG</Styled.DownloadButton>
              </Styled.DownloadButtonGroup>
            </Styled.QrCodeBlock>
            <Styled.FeaturesBlock>
              <Styled.FeatureLabel>Short Link</Styled.FeatureLabel>
              <Styled.ShortLinkField>
                <Styled.LinkIcon src={LinkImage} alt='Share link' />
                <Styled.ShortLink>glb.ee/your-url</Styled.ShortLink>
                <Styled.CopyLinkButton>
                  <img src={Copy} alt='Copy' />
                </Styled.CopyLinkButton>
              </Styled.ShortLinkField>
              <Styled.FeatureLabel>Download</Styled.FeatureLabel>
              <Styled.DownloadModelButton>
                <span>Download Model</span>
                <img src={Download} alt='Download Model' />
              </Styled.DownloadModelButton>
            </Styled.FeaturesBlock>
          </Styled.SharingOptionsContent>
          <Styled.Overlay>
            <span>Login or sign up to share</span>
          </Styled.Overlay>
        </Styled.SharingOptionsContainer>
        <Styled.ModalCopyrightContainer>
          <ModalCopyright>
            <span>{moment().year()} © glbee</span>
          </ModalCopyright>
        </Styled.ModalCopyrightContainer>
      </Styled.LeftSide>
      <Styled.SeparateLine />
      <Styled.RightSide>
        <Styled.LoginFormContainer>
          <LoginForm />
        </Styled.LoginFormContainer>
        <Styled.SectionIcon src={LogoLogin} alt='Logo' />
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default ModalSharing;
