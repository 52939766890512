import styled from 'styled-components';
import {
  COMMENT_ACTIVE_TOGGLE_SIZE,
  COMMENT_NEW_BUBBLE_WIDTH,
  COMMENT_SMALL_BUBBLE_WIDTH,
  COMMENT_THREAD_BUBBLE_WIDTH,
  COMMENT_TOGGLE_SIZE
} from 'shared/constants/comments-defaults';
import { COLORS } from 'shared/constants/colors';

export const Editor = styled.div<{ isEmbeddedMode: boolean }>`
  position: absolute;
  pointer-events: auto;
  user-select: none;
  transform: ${({ isEmbeddedMode }): string => (isEmbeddedMode ? 'scale(0.8)' : 'scale(1)')};
`;

export const Toggle = styled.button<{ isActive: boolean }>`
  display: flex;
  position: absolute;
  width: ${({ isActive }): string => (isActive ? COMMENT_ACTIVE_TOGGLE_SIZE : COMMENT_TOGGLE_SIZE)};
  height: ${({ isActive }): string =>
    isActive ? COMMENT_ACTIVE_TOGGLE_SIZE : COMMENT_TOGGLE_SIZE};
  left: 0;
  bottom: 0;
  z-index: 1;
  color: var(--button-background-color);
  cursor: pointer;

  transition: ease-in-out 0.3s;
  transition-property: width, height, opacity;
`;

export const Pointer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #0a0a0a transparent transparent;
  visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }): string => (isVisible ? '1' : '0')};
  border-width: ${({ isVisible }): string => (isVisible ? '0 40px 40px 0' : '0 0 40px 0')};
  z-index: -1;

  transition: opacity 0.5s, border-right-width ease-in 0.2s;
`;

export const Bubble = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  box-sizing: border-box;
  width: 0;
  height: 0;
  padding: 0;
  left: unset;
  right: 0;
  bottom: 38px;
  border-radius: 5px 5px 0 5px;
  background: #0a0a0a;
  opacity: 0;
  visibility: hidden;

  transition: width 0.5s, height 0.5s, opacity 0.5s;

  .isOpen & {
    width: ${COMMENT_THREAD_BUBBLE_WIDTH};
    height: auto;
    opacity: 1;
    visibility: visible;
  }

  .isCreated & {
    width: ${COMMENT_NEW_BUBBLE_WIDTH};
    left: calc(${COMMENT_ACTIVE_TOGGLE_SIZE} + 8px);
    right: unset;
    bottom: 0;
    border-radius: 5px;
    background-color: #000000;
  }

  @media (max-width: 1365px) {
    .isOpen &,
    .isCreated & {
      width: ${COMMENT_SMALL_BUBBLE_WIDTH};
    }
  }
`;

export const BubbleContainer = styled.div`
  width: ${COMMENT_THREAD_BUBBLE_WIDTH};
  height: auto;
  padding: 5px 0;
  opacity: 0;
  transform-origin: top left;
  transform: scaleX(0);

  transition: transform 0.5s, opacity 0.3s 0.3s;

  .isOpen & {
    opacity: 1;
    transform: scaleX(1);
  }

  .isCreated & {
    width: ${COMMENT_NEW_BUBBLE_WIDTH};
    height: auto;
    padding: 5px 10px;
  }

  @media (max-width: 1365px) {
    .isOpen &,
    .isCreated & {
      width: ${COMMENT_SMALL_BUBBLE_WIDTH};
    }
  }
`;

export const BubbleHeader = styled.div<{
  justifyToEnd: boolean;
}>`
  display: flex;
  justify-content: ${({ justifyToEnd }): string => (justifyToEnd ? 'flex-end' : 'space-between')};
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 2px 6px 7px 12px;

  .isCreated & {
    display: none;
  }
`;

export const DeleteThreadButton = styled.button`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;

  border: none;
  outline: none;
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ActionButton = styled.button<{ isActive?: boolean }>`
  width: 28px;
  color: ${({ isActive }): string => (isActive ? 'var(--theme-secondary-color)' : COLORS.white)};

  &.copy-button,
  &.notifications-button {
    height: 14px;
  }

  &.collapse-button {
    height: 26px;
  }

  &:hover {
    color: var(--theme-secondary-color);
  }
`;

export const NotificationSettings = styled.div`
  position: absolute;
  width: 210px;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;
  z-index: 10;
`;

export const CommentsList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 30vh;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .isCreated & {
    display: none;
  }
`;

export const InputBlock = styled.div`
  position: relative;
  padding: 24px 26px 17px 26px;

  .isCreated & {
    padding: 0;
  }
`;

export const InputWrapper = styled.div<{
  hasError: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: #303030;
  border: ${({ hasError }): string => (hasError ? '1px solid crimson' : '1px solid transparent')};
  outline: none;
  border-radius: 5px;

  .isCreated & {
    background-color: #000000;
  }
`;

export const LockWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -8px;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LockIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const InputPlaceholder = styled.div`
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 27px;
  padding: 4px 31px 4px 12px;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #b0b0b0;
  border: 1px solid transparent;

  .isCreated & {
    pointer-events: none;
    height: 42px;
    padding: 11px 37px 11px 4px;

    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }
`;

export const Input = styled.div`
  display: block;
  width: 100%;
  min-height: 27px;
  height: 27px;
  max-height: 20vh;
  padding: 4px 31px 4px 12px;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  overflow: auto;
  outline: none;
  white-space: pre-wrap;
  word-break: break-word;
  cursor: text;

  .isCreated & {
    min-height: 42px;
    height: 42px;
    padding: 11px 37px 11px 4px;

    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #b0b0b0;
  }

  & > .mention {
    font-family: inherit;
    color: ${COLORS.turbo};
    -webkit-user-modify: read-only;
    -webkit-user-select: text;
  }
`;

export const ReplyButton = styled.button`
  position: absolute;
  right: 38px;
  bottom: 23px;
  width: 16px;
  height: 16px;
  color: var(--theme-secondary-color);

  .isCreated & {
    right: 8px;
    bottom: 9px;
    width: 25px;
    height: 25px;
  }
`;
