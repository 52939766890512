import React, { useRef } from 'react';
import { InView } from 'react-intersection-observer';

type Props<T> = {
  items: T[];
  component: React.FC<any>;
  pointAction: () => void;
  step: number;
  [key: string]: any;
};

const InfinityList = <T,>({
  items,
  component: Component,
  pointAction,
  step,
  ...rest
}: Props<T>): JSX.Element => {
  const passedPoints = useRef<number[]>([]).current;

  const handleObserverAction = (entry: IntersectionObserverEntry, point: number): void => {
    const isPassedPoint = passedPoints.some((p): boolean => p === point);
    if (entry.isIntersecting && !isPassedPoint) {
      passedPoints.push(point);
      pointAction();
    }
  };

  return (
    <>
      {items.map(
        (item: T, index): JSX.Element => (
          <React.Fragment key={index}>
            {!((index + 1) % step) && (
              <InView onChange={(inView, entry): void => handleObserverAction(entry, index + 1)}>
                <div />
              </InView>
            )}
            <Component item={item} {...rest} />
          </React.Fragment>
        )
      )}
    </>
  );
};

export default InfinityList;
