export const BASIC_FILE_FORMATS_REGEXP = /\.(gltf|glb|fbx|obj|stl|mtl|usd|usda|usdc|usdz)$/;
export const MAIN_FILE_FORMAT_REGEXP = /\.(gltf|glb|fbx|obj|stl|usd|usda|usdc|usdz)$/;
export const DRAG_DROP_FILE_FORMAT_REGEXP = /\.(zip|gltf|glb|fbx|obj|stl|usd|usda|usdc|usdz)$/;
export const CUBEMAP_FILE_FORMATS_REGEXP = /\.(jpg|jpeg|png|tiff|dds|gif|bmp|vtf)$/;
export const GLB_GLTF_MODEL_TYPE_REGEXP = /^(glb|gltf)$/;
export const IMAGE_FILE_FORMATS = /\.(jpg|png|jpeg)$/;
export const MTL_FORMAT_REGEXP = /\.(mtl)$/;
export const NUMBER_REGEXP = /^[0-9]*$/;
export const NUMBER_AND_LETTERS_REGEXP = /^[A-Za-z0-9]*$/;
export const EXPIRY_MONTH_REGEXP = /^([0-1]|0[1-9]|1[0-2])$/;
export const TWO_DECIMAL_PLACE_NUMBER_WITH_MINUS = /^-?(\d+(\.)?(\d{1,2})?)?$/;
export const TWO_DECIMAL_PLACE_NUMBER_WITHOUT_MINUS = /^\d+(\.)?(\d{1,2})?$/;
export const DELETE_BRACKETS_REGEXP = / *<[^)]*> */g;
export const EXTRACT_FROM_BRACKETS_REGEXP = /\(([^)]+)\)/;
export const SIMPLIFIED_MENTION_FORMAT_REGEXP =
  /@[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
export const DEFAULT_MENTION_FORMAT_REGEXP =
  /<@[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?>/g;
const getYearRegex = (): RegExp => {
  const shortCurrentYear: string = new Date().getFullYear().toString().slice(2, 4);
  const regexString = `^([${shortCurrentYear[0]}-9]|${shortCurrentYear[0]}[${
    shortCurrentYear[1]
  }-9]|[${+shortCurrentYear[0] + 1}-9][0-9])$`;
  return new RegExp(regexString);
};
export const EXPIRY_YEAR_REGEXP = getYearRegex();
export const EMAIL_INTO_ANGLE_BRACKETS_REGEX = /<\S+@\S+>/g;
export const EMAIL_FROM_ANGLE_BRACKETS_REGEX = /[^@<\s]+@[^@\s>]+/g;
export const MODEL_ASSETS_FILE_FORMATS = /\.(jpg|bin|png|jpeg)$/;
export const LIKE_BASE64_REGEXP = /(data:[A-Za-z0-9]+\/[A-Za-z-0-9]+;base64)/gm;
export const COLOR_HEX_REGEXP = /^#([0-9a-f]{3}){1,2}$/i;
export const MODEL_PAGE_PATHNAME = /^\/([2-9a-fxw]{8})$/;
export const MODEL_PAGE_PATHNAME_NUMBERS = /[2-9]/;
export const EXTRACT_DOMAIN = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/;
export const URL_PATTERN = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3})|' + // OR ip (v4) address
    'localhost)' + // OR localhost
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);
export const LOCALHOST_URL_PATTERN = /^((https:\/\/)+((192.168.)|(localhost)))/gm;
