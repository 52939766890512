import { MutableRefObject, useEffect, useRef, useState } from 'react';

type Size = { width: number; height: number };

type Result = {
  ref: MutableRefObject<HTMLDivElement | null>;
  size: Size;
  targetElement: Element | null;
};

const useResizeListener = (): Result => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<Size>({
    width: ref.current?.clientWidth || 0,
    height: ref.current?.clientHeight || 0
  });
  const [targetElement, setTargetElement] = useState<Element | null>(null);
  const isObserved = useRef<boolean>(false);

  useEffect((): (() => void) => {
    const handleResize = (entries: ResizeObserverEntry[]): void => {
      requestAnimationFrame((): void => {
        if (!Array.isArray(entries) || !entries.length) return;
        const { clientWidth, clientHeight } = entries[0].target;
        setSize({ width: clientWidth, height: clientHeight });
        setTargetElement(entries[0].target);
      });
    };
    const observer = new ResizeObserver(handleResize);
    setTimeout((): void => {
      if (!!ref.current && !isObserved.current) {
        observer.observe(ref.current);
        isObserved.current = true;
      }
    }, 0);

    return (): void => {
      observer.disconnect();
    };
  }, []);

  return { ref, size, targetElement };
};

export default useResizeListener;
