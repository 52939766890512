import { LoadEnvironmentData, LoadEnvironmentResult } from 'shared/interfaces';
import * as THREE from 'three';
import { clearObject } from 'utils/scenes-utils';

export abstract class BaseLoadEnvironmentStrategy {
  protected clearEnvironmentWrapper(environmentWrapper: THREE.Object3D): void {
    environmentWrapper.traverse((object): void => {
      clearObject(object);
    });
    environmentWrapper.clear();
  }

  public abstract changeEnvironment(data: LoadEnvironmentData): Promise<LoadEnvironmentResult>;
}
