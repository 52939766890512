import { AR_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';
import { ThemeSettings } from 'shared/types';

export class TapToPlaceBlock {
  public static show({buttonBackgroundColor, buttonTextColor, headerTextColor}:ThemeSettings['brandColors']): void {
    if (document.querySelector('.tap-to-place-8w')) return;

    const eightWallCanvasParent = document.getElementById(AR_SCENE_CANVAS_ID)!.parentElement;

    const tapToPlaceBlock = document.createElement('div');
    tapToPlaceBlock.className = 'tap-to-place-8w';

    const tapToPlaceImage = document.createElement('div');
    tapToPlaceImage.className = 'tap-to-place-8w__image';
    tapToPlaceImage.innerHTML = `
      <svg width="227" height="116" viewBox="0 0 227 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_3528_459)">
      <ellipse cx="112.5" cy="75" rx="90.5" ry="20" fill="black" fill-opacity="0.25"/>
      </g>
      <path d="M103 59L114 70L125 59" stroke="${headerTextColor}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M225.462 74.5C225.462 78.387 222.786 82.4014 217.186 86.3097C211.63 90.1878 203.491 93.7409 193.3 96.7495C172.937 102.761 144.716 106.5 113.481 106.5C82.2464 106.5 54.0258 102.761 33.6626 96.7495C23.4712 93.7409 15.333 90.1878 9.77641 86.3097C4.17671 82.4014 1.5 78.387 1.5 74.5C1.5 70.613 4.17671 66.5986 9.77641 62.6903C15.333 58.8122 23.4712 55.2591 33.6626 52.2505C54.0258 46.2393 82.2464 42.5 113.481 42.5C144.716 42.5 172.937 46.2393 193.3 52.2505C203.491 55.2591 211.63 58.8122 217.186 62.6903C222.786 66.5986 225.462 70.613 225.462 74.5Z" stroke="${headerTextColor}" stroke-width="3"/>
      <path d="M225 74.5C225 91.897 174.58 106.5 113 106.5C51.4202 106.5 2 91.897 2 74.5C2 57.103 51.9202 42.5 113.5 42.5C175.08 42.5 225 57.103 225 74.5Z" stroke="${buttonBackgroundColor}" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="100 100"/>
      <path d="M114 2L114 70" stroke="${headerTextColor}" stroke-width="3" stroke-linecap="round"/>
      <defs>
      <filter id="filter0_f_3528_459" x="1" y="34" width="223" height="82" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="10.5" result="effect1_foregroundBlur_3528_459"/>
      </filter>
      </defs>
      </svg>
    `;

    const tapToPlaceText = document.createElement('div');
    tapToPlaceText.className = 'tap-to-place-8w__text';
    tapToPlaceText.style.backgroundColor = buttonBackgroundColor;
    tapToPlaceText.style.color = buttonTextColor;
    tapToPlaceText.innerText = 'Tap to place';

    if (!eightWallCanvasParent) return;

    tapToPlaceBlock.appendChild(tapToPlaceImage);
    tapToPlaceBlock.appendChild(tapToPlaceText);
    eightWallCanvasParent.appendChild(tapToPlaceBlock);
  }

  public static remove(): void {
    const tapToPlaceBlock = document.querySelector('.tap-to-place-8w') as HTMLElement;

    if (tapToPlaceBlock) {
      tapToPlaceBlock.remove();
    }
  }

  public static update(brandColors: ThemeSettings['brandColors']): void {
    const tapToPlaceBlock = document.querySelector('.tap-to-place-8w') as HTMLElement;

    if (tapToPlaceBlock) {
      TapToPlaceBlock.remove();
      TapToPlaceBlock.show(brandColors);
    }
  }
}
