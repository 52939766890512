import { DisposeSceneService } from 'services/strategy-services/dispose-scene-service/dispose-scene.service';

export class ScenesGlobalStateService {
  public scenes: DisposeSceneService[] = [];

  public addScenes(...scenes: DisposeSceneService[]): void {
    this.scenes.push(...scenes);
  }

  public clearScenes(): void {
    this.scenes.forEach((scene): void => scene.dispose());
  }
}
