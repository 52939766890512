export enum EScreenshotTypes {
  sceneSimpleCi = 'sceneSimpleCi',
  sceneDefaultCi = 'sceneDefaultCi',
  sceneWithCommentSs = 'sceneWithCommentSs',
  sceneEnvironmentCi = 'sceneEnvironmentCi',
  uScene540pSs = 'uScene540pSs',
  uScene1080pSs = 'uScene1080pSs',
  uScene2160pSs = 'uScene2160pSs',
  uModel540pSs = 'uModel540pSs',
  uModel1080pSs = 'uModel1080pSs',
  uModel2160pSs = 'uModel2160pSs',
}
