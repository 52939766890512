import React from 'react';
import moment from 'moment';
import * as Styled from './styles';
import { ModalCreateAccount } from 'shared/components';
import {
  EmailNotificationSubtitle,
  EmailNotificationTitle,
  IncorrectEmailText,
  ModalCopyright,
  ModalWindowContainer,
  NoEmailText
} from 'shared/styles';
import { useAppDispatch } from 'shared/hooks';
import { showModal } from 'services/store/reducers/modalReducer';

type Props = {
  email: string;
};

const ModalEmailNotVerified: React.FC<Props> = ({ email }): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleCreateAccountClick = (): void => {
    dispatch(showModal(<ModalCreateAccount />));
  };

  return (
    <ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.LiftSideContent>
          <EmailNotificationTitle>Your email is not yet verified!</EmailNotificationTitle>
          <EmailNotificationSubtitle>
            A new verification link was sent to <span className='email'>{email}</span>
          </EmailNotificationSubtitle>
          <NoEmailText>If you don’t see the email, please check your spam folder</NoEmailText>
          <IncorrectEmailText>
            <span>Email address incorrect?</span>
            <span className='highlighted-text' onClick={handleCreateAccountClick}>
              Create an Account
            </span>
          </IncorrectEmailText>
        </Styled.LiftSideContent>
        <ModalCopyright>
          <span>{moment().year()} © glbee</span>
        </ModalCopyright>
      </Styled.LeftSide>
      <Styled.RightSide></Styled.RightSide>
    </ModalWindowContainer>
  );
};

export default ModalEmailNotVerified;
