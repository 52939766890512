import { useCallback, useState } from 'react';
import { ESnackbarStyle, TeamMember, TeamMembersApiResponse } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { getTeamMembers } from 'services/api/teamService';

type Result = {
  fetchTeamMembers: (teamId: number) => Promise<void>;
  teamMembers: TeamMember[];
  isTeamDataLoading: boolean;
  setSearchValue: (value: string) => void;
  setOrderBy: (value: 'status' | 'created_at') => void;
  setCurrentPage: (page: number) => void;
  totalItems: number;
  currentPage: number;
};

const useTeamMembersData = (): Result => {
  const LIMIT = 10;
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isTeamDataLoading, setIsTeamDataLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchTeamMembers = useCallback(
    async (teamId: number): Promise<void> => {
      setIsTeamDataLoading(true);
      try {
        const { items, pagination }: TeamMembersApiResponse = (
          await getTeamMembers(teamId, LIMIT, currentPage, searchValue, orderBy)
        ).data;
        const sortedMembers = items.sort((a, b): number => {
          const firstName = (m: TeamMember): string => m.user?.firstName.toLowerCase() || '';
          const lastName = (m: TeamMember): string => m.user?.lastName.toLowerCase() || '';
          const isFirstNamesSame = firstName(a) === firstName(b);
          if (isFirstNamesSame) {
            return lastName(a) > lastName(b) ? 1 : -1;
          }
          return firstName(a) > firstName(b) ? 1 : -1;
        });
        setTeamMembers(sortedMembers);
        setTotalItems(pagination.totalItemsCount);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        setIsTeamDataLoading(false);
      }
    },
    [currentPage, searchValue, orderBy]
  );

  return {
    fetchTeamMembers,
    teamMembers,
    isTeamDataLoading,
    setSearchValue,
    setOrderBy,
    setCurrentPage,
    totalItems,
    currentPage
  };
};

export default useTeamMembersData;
