import React from 'react';

const ZenModeIcon: React.FC = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: '100%', height: '100%' }}
      viewBox='0 0 24 21'
      preserveAspectRatio='xMidYMax meet'
      fill='currentColor'
    >
      <path
        d='M5.28333 20.8333C4.6375 20.8333 4.08854 20.6073 3.63646 20.1552C3.18438 19.7031 2.95833 19.1542 2.95833 18.5083C2.95833 18.0562 3.0875 17.6308 3.34583 17.2322C3.60417 16.8343 3.94861 16.5493 4.37917 16.3771L9.41667 14.375V11.4687C8.25417 12.825 6.90351 13.8635 5.36471 14.5842C3.82504 15.3059 2.16181 15.6667 0.375 15.6667V13.0833C1.83889 13.0833 3.16844 12.7819 4.36367 12.1792C5.55803 11.5764 6.63958 10.7153 7.60833 9.59582L9.35208 7.52916C9.61042 7.22777 9.91181 7.00173 10.2562 6.85103C10.6007 6.70034 10.9667 6.62499 11.3542 6.62499H12.6458C13.0333 6.62499 13.3993 6.70034 13.7437 6.85103C14.0882 7.00173 14.3896 7.22777 14.6479 7.52916L16.3917 9.59582C17.3604 10.7153 18.4424 11.5764 19.6376 12.1792C20.832 12.7819 22.1611 13.0833 23.625 13.0833V15.6667C21.8382 15.6667 20.1754 15.3059 18.6366 14.5842C17.0969 13.8635 15.7458 12.825 14.5833 11.4687V14.375L19.6208 16.3771C20.0514 16.5493 20.3958 16.8343 20.6542 17.2322C20.9125 17.6308 21.0417 18.0562 21.0417 18.5083C21.0417 19.1542 20.8156 19.7031 20.3635 20.1552C19.9115 20.6073 19.3625 20.8333 18.7167 20.8333H9.41667V20.1875C9.41667 19.6278 9.59965 19.1649 9.96562 18.7989C10.3316 18.433 10.7944 18.25 11.3542 18.25H15.2292C15.4229 18.25 15.5792 18.191 15.698 18.073C15.816 17.9542 15.875 17.7979 15.875 17.6042C15.875 17.4104 15.816 17.2541 15.698 17.1353C15.5792 17.0173 15.4229 16.9583 15.2292 16.9583H11.3542C10.45 16.9583 9.68576 17.2705 9.06146 17.8948C8.43715 18.5191 8.125 19.2833 8.125 20.1875V20.8333H5.28333ZM12 5.33332C11.2896 5.33332 10.6816 5.08016 10.1762 4.57382C9.66983 4.06835 9.41667 3.46041 9.41667 2.74999C9.41667 2.03957 9.66983 1.43163 10.1762 0.926156C10.6816 0.419823 11.2896 0.166656 12 0.166656C12.7104 0.166656 13.3188 0.419823 13.8251 0.926156C14.3306 1.43163 14.5833 2.03957 14.5833 2.74999C14.5833 3.46041 14.3306 4.06835 13.8251 4.57382C13.3188 5.08016 12.7104 5.33332 12 5.33332Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ZenModeIcon;
