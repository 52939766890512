import React, { useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useHandleClickOutside } from 'shared/hooks';
import { Switcher } from 'shared/components';
import { openNotification } from 'utils/notification-utils';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';

type Size = '540px' | '1080px' | '2160px';

type Props = {
  downloadScreenshot: (size: Size, isTransparent: boolean) => Promise<void>;
  setIsScreenshotMenuActive: (isActive: boolean) => void;
};

const ScreenshotMenu: React.FC<Props> = ({
  downloadScreenshot,
  setIsScreenshotMenuActive
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isTransparent, setIsTransparent] = useState<boolean>(false);
  const [downloadedSizes, setDownloadedSizes] = useState<Size[]>([]);

  const handleSwitcherChange = (): void => {
    setIsTransparent((prev): boolean => !prev);
  };

  const handleDownloadButtonClick =
    (size: Size): (() => Promise<void>) =>
    async (): Promise<void> => {
      try {
        dispatch(startLoader());
        await downloadScreenshot(size, isTransparent);
        setDownloadedSizes((prevState): Size[] => [...prevState, size]);
      } catch (e) {
        openNotification(e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

  const ref = useHandleClickOutside((): void => setIsScreenshotMenuActive(false));

  const FIELDS = [
    {
      size: '540px',
      title: 'Small - 540px',
      isDownloaded: downloadedSizes.includes('540px')
    },
    {
      size: '1080px',
      title: 'Medium - 1080px',
      isDownloaded: downloadedSizes.includes('1080px')
    },
    {
      size: '2160px',
      title: 'Large - 2160px',
      isDownloaded: downloadedSizes.includes('2160px')
    }
  ];

  return (
    <Styled.ScreenshotMenuContainer ref={ref}>
      <Styled.ScreenshotMenuContent>
        <Styled.MenuHeader>Download in perfect resolution</Styled.MenuHeader>
        {FIELDS.map(
          ({ title, size, isDownloaded }): JSX.Element => (
            <Styled.MenuField key={title}>
              <Styled.FieldName>{title}</Styled.FieldName>
              <Styled.FieldAction>
                <Styled.DownloadButton
                  isDownloaded={isDownloaded}
                  onClick={handleDownloadButtonClick(size as Size)}
                  type='button'
                >
                  {isDownloaded ? 'Downloaded' : 'Download'}
                </Styled.DownloadButton>
              </Styled.FieldAction>
            </Styled.MenuField>
          )
        )}
        <Styled.MenuField>
          <Styled.FieldName>Remove Background</Styled.FieldName>
          <Styled.FieldAction>
            <Switcher
              action={handleSwitcherChange}
              isActive={isTransparent}
              switcherId='transparent-screenshot-switcher'
            />
          </Styled.FieldAction>
        </Styled.MenuField>
      </Styled.ScreenshotMenuContent>
      <Styled.MenuArrow />
    </Styled.ScreenshotMenuContainer>
  );
};

export default ScreenshotMenu;
