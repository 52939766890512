import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';

export const SelectContainer = styled.div<{
  isMenuActive: boolean;
  disabled?: boolean;
}>`
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  display: flex;
  position: relative;
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'initial')};
  opacity: ${({ disabled }): string => (disabled ? '0.5' : '1')};
`;

export const MainField = styled.div<{ isMenuActive: boolean }>`
  position: relative;
  width: 100%;
  height: 49px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;

  & div {
    width: 100%;
    padding: 0 33px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media ${DEVICE.mobile} {
      padding: 0 15px;
    }
  }

  & img {
    transform: ${({ isMenuActive }): string => (isMenuActive ? 'rotate(180deg)' : '0')};
    transition: all 0.3s;
    margin-right: 33px;
    width: 24px;

    @media ${DEVICE.mobile} {
      margin-right: 15px;
    }
  }
`;

export const SelectMenu = styled.div<{ isMenuActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 250px;
  max-height: ${({ isMenuActive }): string => (isMenuActive ? '340px' : '0px')};
  position: absolute;
  right: 0;
  z-index: 11;
  transition: max-height 0.3s;
  border-radius: 7px;
  margin-top: 50px;
  box-shadow: rgb(0 0 0 / 70%) -5px 10px 10px, rgb(0 0 0 / 30%) 0 1px 18px,
    rgb(0 0 0 / 60%) 0 30px 15px;
  background: rgba(0, 0, 0, 80%);
  backdrop-filter: blur(15px);
  border: ${({ isMenuActive }): string => (isMenuActive ? `1px solid ${COLORS.doveGray}` : 'none')};

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
  }
`;

export const OptionItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 30px;
  padding: 0 29px;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  background: ${({ isActive }): string => (isActive ? COLORS.turbo : 'transparent')};
  color: ${({ isActive }): string => (isActive ? COLORS.black : COLORS.white)};

  &:hover {
    background: ${COLORS.turbo};

    span {
      color: ${COLORS.black};
    }
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 98%;
    text-align: left;
    margin-right: 10px;
  }

  img {
    width: 15px;
    margin-left: auto;
  }
`;
