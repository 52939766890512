import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { openNotification } from 'utils/notification-utils';

type Props = {
  deleteAction: (() => Promise<void>) | (() => void);
  text?: string;
};

const ModalDelete: React.FC<Props> = ({ deleteAction, text }): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleDeleteButtonClick = async (): Promise<void> => {
    try {
      await deleteAction();
      dispatch(closeConfirmModal());
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.Notification>{text || 'Are you sure you want to delete?'}</Styled.Notification>
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton className='delete-button' onClick={handleDeleteButtonClick}>
          Delete
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalDelete;
