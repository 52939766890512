import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as Styled from './styles';
import { TeamSubscriptionPlan } from 'shared/components';
import { ErrorMessage } from 'shared/styles';
import { NUMBER_REGEXP } from 'shared/constants/regexps';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  CreateTeamState,
  EPaymentType,
  SubscriptionProduct,
  SubscriptionsState,
  TeamsState
} from 'shared/types';
import { setMembersCount } from 'services/store/reducers/createTeamReducer';
import { MAX_TEAM_MEMBER_COUNT, MIN_TEAM_MEMBER_COUNT } from 'shared/constants/limits';
import { extractPlanData } from 'utils/subscription-plans-utils';

type Props = {
  paymentType: EPaymentType.CREATE_TEAM_PLAN | EPaymentType.UPDATE_TEAM_PLAN;
  withCounter?: boolean;
};

const TeamSubscriptions: React.FC<Props> = ({ withCounter, paymentType }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { subscriptionPlans } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const { membersCount } = useAppSelector((store): CreateTeamState => store.createTeam);
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const customPlanId = useMemo((): string => (Date.now() * Math.random()).toString(), []);
  const SUBSCRIPTION_PLANS = useMemo(
    (): SubscriptionProduct[] => subscriptionPlans.slice(-2),
    [subscriptionPlans]
  );
  const [membersCountInputValue, setMembersCountInputValue] = useState<string>(
    MIN_TEAM_MEMBER_COUNT.toString()
  );
  const [isMonthlyType, setIsMonthlyType] = useState<boolean>(true);
  const [activePlanId, setActivePlanId] = useState<string>('');

  useEffect((): void => {
    setActivePlanId(SUBSCRIPTION_PLANS[0]?.id || '');
  }, [SUBSCRIPTION_PLANS]);

  useEffect((): void => {
    if (!!+membersCountInputValue) {
      dispatch(setMembersCount(+membersCountInputValue));
    }
  }, [dispatch, membersCountInputValue]);

  const handleMembersCounterButtonClick =
    (buttonType: 'minus' | 'plus'): (() => void) =>
    (): void => {
      const result =
        buttonType === 'minus' ? +membersCountInputValue - 1 : +membersCountInputValue + 1;

      if (!!result && result <= MAX_TEAM_MEMBER_COUNT && result >= MIN_TEAM_MEMBER_COUNT) {
        setMembersCountInputValue(result.toString());
        dispatch(setMembersCount(result));
      }
    };

  const handlePlanTabClick =
    (id: string): (() => void) =>
    (): void => {
      setActivePlanId(id);
    };

  const getCounterFieldError = (): JSX.Element | void => {
    if (+membersCountInputValue < MIN_TEAM_MEMBER_COUNT) {
      return (
        <ErrorMessage>{`${MIN_TEAM_MEMBER_COUNT} - minimum number of teammates`}</ErrorMessage>
      );
    }
    if (+membersCountInputValue > MAX_TEAM_MEMBER_COUNT) {
      return (
        <ErrorMessage>{`${MAX_TEAM_MEMBER_COUNT} - maximum number of teammates`}</ErrorMessage>
      );
    }
  };

  const handleMembersCounterBlur = (): void => {
    if (!+membersCountInputValue || +membersCountInputValue < MIN_TEAM_MEMBER_COUNT) {
      setMembersCountInputValue(MIN_TEAM_MEMBER_COUNT.toString());
      dispatch(setMembersCount(MIN_TEAM_MEMBER_COUNT));
    }
    if (+membersCountInputValue > MAX_TEAM_MEMBER_COUNT) {
      setMembersCountInputValue(MAX_TEAM_MEMBER_COUNT.toString());
      dispatch(setMembersCount(MAX_TEAM_MEMBER_COUNT));
    }
  };

  const handleMembersCounterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (NUMBER_REGEXP.test(value) || !value) {
      setMembersCountInputValue(value);
    }
  };

  return (
    <Styled.ContentContainer>
      <Styled.SettingsField>
        {withCounter && (
          <Styled.MembersCounterContainer>
            <Styled.CounterField>
              <Styled.SettingsLabel>Team members (seats)</Styled.SettingsLabel>
              <Styled.MembersCounter>
                <Styled.CounterButton onClick={handleMembersCounterButtonClick('minus')}>
                  -
                </Styled.CounterButton>
                <Styled.CounterInput
                  value={membersCountInputValue}
                  onChange={handleMembersCounterChange}
                  onBlur={handleMembersCounterBlur}
                  maxLength={MAX_TEAM_MEMBER_COUNT.toString().length}
                />
                <Styled.CounterButton onClick={handleMembersCounterButtonClick('plus')}>
                  +
                </Styled.CounterButton>
              </Styled.MembersCounter>
            </Styled.CounterField>
            {getCounterFieldError()}
          </Styled.MembersCounterContainer>
        )}
        <Styled.BillingTypeSwitcher withCounter={withCounter}>
          <Styled.SettingsLabel>Billing Type:</Styled.SettingsLabel>
          <Styled.BillingTypeButton
            onClick={(): void => setIsMonthlyType(true)}
            isActive={isMonthlyType}
          >
            Monthly
          </Styled.BillingTypeButton>
          <Styled.BillingTypeButton
            onClick={(): void => setIsMonthlyType(false)}
            isActive={!isMonthlyType}
          >
            Yearly
          </Styled.BillingTypeButton>
        </Styled.BillingTypeSwitcher>
      </Styled.SettingsField>
      <Styled.PlanTabsContainer>
        {SUBSCRIPTION_PLANS.map((plan): JSX.Element => {
          const { title } = extractPlanData(plan);
          return (
            <Styled.PlanTab
              isActive={activePlanId === plan.id}
              onClick={handlePlanTabClick(plan.id)}
              key={plan.id}
            >
              {title}
            </Styled.PlanTab>
          );
        })}
        <Styled.PlanTab
          isActive={activePlanId === customPlanId}
          onClick={handlePlanTabClick(customPlanId)}
          key={customPlanId}
        >
          Hive
        </Styled.PlanTab>
      </Styled.PlanTabsContainer>
      {!!SUBSCRIPTION_PLANS.length && (
        <Styled.PlansSection>
          {SUBSCRIPTION_PLANS.map(
            (plan): JSX.Element => (
              <Styled.PlanContainer planId={plan.id} activePlanId={activePlanId} key={plan.id}>
                <TeamSubscriptionPlan
                  plan={plan}
                  isMonthlyType={isMonthlyType}
                  paymentType={paymentType}
                  plansCount={
                    paymentType === EPaymentType.CREATE_TEAM_PLAN
                      ? membersCount
                      : activeTeam?.totalSeats || MIN_TEAM_MEMBER_COUNT
                  }
                />
              </Styled.PlanContainer>
            )
          )}
          <Styled.PlanContainer
            planId={customPlanId}
            activePlanId={activePlanId}
            key={customPlanId}
          >
            <TeamSubscriptionPlan
              isMonthlyType={isMonthlyType}
              paymentType={paymentType}
              plansCount={
                withCounter ? membersCount : activeTeam?.totalSeats || MIN_TEAM_MEMBER_COUNT
              }
            />
          </Styled.PlanContainer>
        </Styled.PlansSection>
      )}
    </Styled.ContentContainer>
  );
};

export default TeamSubscriptions;
