import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';
import {
  FileLoadEnvironmentData,
  GriddedGeneratedEnvironmentData,
  SimpleGeneratedEnvironmentData
} from 'shared/interfaces';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import {
  ENVIRONMENT_WHITE_GROUND_COLOR,
  ENVIRONMENT_WHITE_SPHERE_COLOR,
  ENVIRONMENT_GREY_GROUND_COLOR,
  ENVIRONMENT_GREY_SPHERE_COLOR
} from './scene-settings-constants';

export const DEFAULT_ENVIRONMENTS_PRESETS: Record<
  EEnvironmentPresets,
  {
    type: EEnvironmentTypes;
    data:
      | FileLoadEnvironmentData
      | GriddedGeneratedEnvironmentData
      | SimpleGeneratedEnvironmentData
      | null;
  }
> = {
  [EEnvironmentPresets.DefaultLight]: {
    type: EEnvironmentTypes.griddedGenerated,
    data: {
      groundTexturePath:
        process.env.PUBLIC_URL + '/resources/viewer/environments/default/background-grid-light.png',
      groundMaskColor: ENVIRONMENT_WHITE_GROUND_COLOR,
      sphereMaterialColor: ENVIRONMENT_WHITE_SPHERE_COLOR
    }
  },
  [EEnvironmentPresets.DefaultDark]: {
    type: EEnvironmentTypes.griddedGenerated,
    data: {
      groundTexturePath:
        process.env.PUBLIC_URL + '/resources/viewer/environments/default/background-grid-dark.png',
      groundMaskColor: ENVIRONMENT_GREY_GROUND_COLOR,
      sphereMaterialColor: ENVIRONMENT_GREY_SPHERE_COLOR
    }
  },
  [EEnvironmentPresets.MinimalLight]: {
    type: EEnvironmentTypes.simpleGenerated,
    data: {
      color: ENVIRONMENT_WHITE_SPHERE_COLOR
    }
  },
  [EEnvironmentPresets.MinimalDark]: {
    type: EEnvironmentTypes.simpleGenerated,
    data: {
      color: ENVIRONMENT_GREY_SPHERE_COLOR
    }
  },
  [EEnvironmentPresets.Gazebo]: {
    type: EEnvironmentTypes.file,
    data: {
      path: {
        d1k: process.env.PUBLIC_URL + '/resources/viewer/environments/gazebo-glb/gazebo-1k.glb',
        d2k: process.env.PUBLIC_URL + '/resources/viewer/environments/gazebo-glb/gazebo-2k.glb',
        d4k: process.env.PUBLIC_URL + '/resources/viewer/environments/gazebo-glb/gazebo-4k.glb'
      },
      fileType: E3DModelFileTypes.glb,
      scale: { x: 1, y: 1, z: 1 }
    }
  },
  [EEnvironmentPresets.Prism]: {
    type: EEnvironmentTypes.file,
    data: {
      path: {
        d1k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/prism-glb/crystal/prisms-crystal-1k.glb',
        d2k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/prism-glb/crystal/prisms-crystal-2k.glb',
        d4k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/prism-glb/crystal/prisms-crystal-4k.glb'
      },
      fileType: E3DModelFileTypes.glb,
      scale: { x: 1, y: 1, z: 1 }
    }
  },
  [EEnvironmentPresets.PrismLight]: {
    type: EEnvironmentTypes.file,
    data: {
      path: {
        d1k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/prism-glb/light/prisms-light-1k.glb',
        d2k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/prism-glb/light/prisms-light-2k.glb',
        d4k:
          process.env.PUBLIC_URL + '/resources/viewer/environments/prism-glb/light/prisms-light-4k.glb'
      },
      fileType: E3DModelFileTypes.glb,
      scale: { x: 1, y: 1, z: 1 }
    }
  },
  [EEnvironmentPresets.SciFiRoomGlb]: {
    type: EEnvironmentTypes.file,
    data: {
      path: {
        d1k:
          process.env.PUBLIC_URL + '/resources/viewer/environments/sci-fi-room-glb/sci-fi-room-1k.glb',
        d2k:
          process.env.PUBLIC_URL + '/resources/viewer/environments/sci-fi-room-glb/sci-fi-room-2k.glb',
        d4k:
          process.env.PUBLIC_URL + '/resources/viewer/environments/sci-fi-room-glb/sci-fi-room-4k.glb'
      },
      fileType: E3DModelFileTypes.glb,
      scale: { x: 0.01, y: 0.01, z: 0.01 }
    }
  },
  [EEnvironmentPresets.WarehouseGlbDark]: {
    type: EEnvironmentTypes.file,
    data: {
      path: {
        d1k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/warehouse-glb/optimised/dark/warehouse-1k.glb',
        d2k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/warehouse-glb/optimised/dark/warehouse-2k.glb',
        d4k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/warehouse-glb/optimised/dark/warehouse-4k.glb'
      },
      fileType: E3DModelFileTypes.glb,
      scale: { x: 1, y: 1, z: 1 }
    }
  },
  [EEnvironmentPresets.WarehouseGlbLight]: {
    type: EEnvironmentTypes.file,
    data: {
      path: {
        d1k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/warehouse-glb/optimised/light/warehouse-light-1k.glb',
        d2k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/warehouse-glb/optimised/light/warehouse-light-2k.glb',
        d4k:
          process.env.PUBLIC_URL +
          '/resources/viewer/environments/warehouse-glb/optimised/light/warehouse-light-4k.glb'
      },
      fileType: E3DModelFileTypes.glb,
      scale: { x: 1, y: 1, z: 1 }
    }
  },
  [EEnvironmentPresets.NoEnvironment]: {
    type: EEnvironmentTypes.noEnvironment,
    data: null
  }
};
