export const VIEWS_LIMIT_ALERT = (
  <div>
    The model owner has reached their view limit for this month. They need to{' '}
    <span style={{ color: '#FFE406' }}>upgrade their account</span> or wait until the view limit is
    reset.
  </div>
);

export const AR_VIEWS_LIMIT_ALERT = (
  <div>
    The model owner has reached their AR view limit for this month. They need to{' '}
    <span style={{ color: '#FFE406' }}>upgrade their account</span> or wait until the view limit is
    reset.
  </div>
);

export const EMBED_REQUIRED_PUBLIC_ACCESS = (
  <div>
    Embedding the model requires enabling public viewing permissions. Click{' '}
    <i>Create & Copy Link</i> above to enable public viewing and embedded models.
  </div>
);
