import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const PageContent = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const PlatformSummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const PlatformSummary = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  & ul {
    margin: 10px 0 0;
  }

  .item-value {
    margin-left: 6px;
    color: ${COLORS.doveGray};
    font-style: italic;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const SummaryItem = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 18px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const UserStatisticsContainer = styled.section`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

export const SearchField = styled.div`
  margin-top: 30px;
`;

export const SearchInput = styled.input`
  height: 30px;
  width: 220px;
  padding: 4px 8px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  color: ${COLORS.white};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 50px;

  @media ${DEVICE.tablet} {
    .ant-table table {
      display: flex;
      flex-direction: column;
    }

    .ant-table thead {
      display: none;
    }

    .ant-table-tbody > tr {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    .ant-table .ant-table-tbody > tr > td {
      padding: 4px 8px;
    }

    .ant-table-tbody > tr > td > span {
      display: flex;
      word-break: break-all;

      &:before {
        content: attr(data-label);
        font-weight: bold;
        min-width: 120px;
      }
    }
  }
`;

export const ResetViewsButton = styled.button`
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  font-size: 12px;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 4px;
  white-space: nowrap;

  &:hover {
    background: ${COLORS.gorse};
  }
`;
