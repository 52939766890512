import type { ITexture2DStats } from '../interfaces/model-parse/model-stats';
import type { GLTF } from '../interfaces/model-parse/gltf-types';
import { isPOT } from '../utils/stats-utils';
import { Texture2DTileStats } from './texture-2d-tile-stats';

export class Texture2DStats implements ITexture2DStats {
  public totalCount: number = 0;

  public '4096+': Texture2DTileStats = new Texture2DTileStats();
  public '4096': Texture2DTileStats = new Texture2DTileStats();
  public '2048': Texture2DTileStats = new Texture2DTileStats();
  public '1024': Texture2DTileStats = new Texture2DTileStats();
  public '512': Texture2DTileStats = new Texture2DTileStats();
  public '256': Texture2DTileStats = new Texture2DTileStats();
  public '128': Texture2DTileStats = new Texture2DTileStats();
  public NPOT: Texture2DTileStats = new Texture2DTileStats();

  protected texture2d4kPlus(dimensions: number): Texture2DTileStats | null {
    if (dimensions >= 4096 && isPOT(dimensions)) {
      return this['4096+'];
    }
    return null;
  }

  protected texture2dLowRes(dimensions: number): Texture2DTileStats | null {
    if (dimensions <= 128 && isPOT(dimensions)) {
      return this['128'];
    }
    return null;
  }

  protected textureNPOT(): Texture2DTileStats {
    return this.NPOT;
  }

  protected texture2d(dimensions: number): Texture2DTileStats | null {
    const tex4kPlus = this.texture2d4kPlus(dimensions);
    if (dimensions > 4096 && tex4kPlus) {
      return tex4kPlus;
    }

    if (dimensions === 4096) {
      return this['4096'];
    }

    if (dimensions === 2048) {
      return this['2048'];
    }

    if (dimensions === 1024) {
      return this['1024'];
    }

    if (dimensions === 512) {
      return this['512'];
    }

    if (dimensions === 256) {
      return this['256'];
    }

    const tex2dLowRes = this.texture2dLowRes(dimensions);
    if (tex2dLowRes) {
      return tex2dLowRes;
    }

    if (!isPOT(dimensions)) {
      return this.textureNPOT();
    }

    return null;
  }

  public updateTexture2DStats(image: GLTF.ImageMetadata | null): void {
    if (image) {
      const NPOT = image.width % 2 === 1 || image.height % 2 === 1;

      const mimeType = image.mimeType;
      let fileSize = 0;
      if (image.byteLength) fileSize = image.byteLength;

      const dimensions = image.width > image.height ? image.width : image.height;

      if (NPOT) {
        const texNPOT = this.textureNPOT();
        texNPOT.update2DTileStats(mimeType, fileSize);
        this.totalCount++;
        return;
      }

      const tex2d4kPlus = this.texture2d4kPlus(dimensions);
      const tex2dNormal = this.texture2d(dimensions);

      if (tex2dNormal && tex2dNormal === tex2d4kPlus) {
        tex2d4kPlus.update2DTileStats(mimeType, fileSize);
      } else if (tex2dNormal) {
        tex2dNormal.update2DTileStats(mimeType, fileSize);
        if (tex2d4kPlus) {
          tex2d4kPlus.update2DTileStats(mimeType, fileSize);
        }
      }

      if (!tex2dNormal) {
        return;
      }

      this.totalCount++;
    }
  }

  public plainObject(): ITexture2DStats {
    return {
      totalCount: this.totalCount,
      '4096+': this['4096+'].plainObject(),
      '4096': this['4096'].plainObject(),
      '2048': this['2048'].plainObject(),
      '1024': this['1024'].plainObject(),
      '512': this['512'].plainObject(),
      '256': this['256'].plainObject(),
      '128': this['128'].plainObject(),
      NPOT: this.NPOT.plainObject()
    };
  }
}
