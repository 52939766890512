import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1053;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  border: 1px solid ${COLORS.doveGray};
`;

export const ModalWindowHeader = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: #484848;
  border-radius: 7px 7px 0 0;
`;

export const ModalIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

export const ModalTitle = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${COLORS.mischka};
`;

export const CloseButton = styled.img`
  width: 16px;
  height: 16px;
  margin-left: auto;
  cursor: pointer;
`;

export const ModalWindowContent = styled.div`
  width: 100%;
`;

export const ModalWindowOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1052;
`;
